<div class="page-wrapper">
    <div class="content-wrapper">
        <nz-page-header class="site-page-header" nzTitle="Teach Copilot about your brand"></nz-page-header>
        <div class="custom-card">
            <div class="custom-card-body">
                <h3>Copilot will read your about page to learn about your brand  </h3>
                <h3>If this is your logo, click continue. Otherwise choose the correct logo from the images below.</h3>
                <br>
                <img class="image-big" src="{{this.imagesAndColorsObject.logo.src}}" />
                <br>
                <button *ngIf="!this.showImageSelector" (click)="showImageSelector = true" nz-button nzType="default">Choose a different image</button>
                <ng-container *ngIf="this.showImageSelector">
                    <nz-divider></nz-divider>
                    <div nz-row [nzGutter]="[16, 24]">
                        <ng-container *ngFor="let image of imagesAndColorsObject.images">
                            <ng-container *ngIf="image.src">
                                <div nz-col class="gutter-row" [nzSpan]="4">
                                    <img class="image-list" (click)="this.imagesAndColorsObject.logo = image"
                                        src="{{image.src}}">
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
            <div class="custom-card-footer">
                <button nz-button nzType="primary" routerLink="/signup/6">Continue</button>
            </div>
        </div>
    </div>
</div>