import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-opportunities-new',
  templateUrl: './opportunities-new.component.html',
  styleUrls: ['./opportunities-new.component.scss']
})
export class OpportunitiesNewComponent {
  opportunities: any = [];
  loading = true;

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    this.apiService.getNewOpportunities().subscribe(
      (data: any) => {
        console.log(data);
        this.opportunities = data;
        this.loading = false;
      },
      error => {
        console.log(error);
      });
  }
}
