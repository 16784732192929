import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-select-logo',
  templateUrl: './select-logo.component.html',
  styleUrls: ['./select-logo.component.scss']
})
export class SelectLogoComponent {
  workspaceId: any = '';
  workspaceObject: any = {};
  loading = true;
  newMessageBody = '';
  imagesAndColorsObject: any = {};
  showImageSelector = false;
  showColorSelector = false;

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    this.apiService.scrapeImagesAndColors().subscribe(
      (data: any) => {
        console.log(data);
        this.imagesAndColorsObject = data;
        this.imagesAndColorsObject.logo = this.imagesAndColorsObject.logos[0];
        this.loading = false;
      },
      error => {
        console.log(error);
      });
  }

  completeSignup() {
    this.apiService.completeSignup(this.workspaceObject).subscribe(
      data => {
        // login successful if there's a jwt token in the response
        console.log(data);
        if (data) {
          console.log(data);
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(data));
          this.router.navigate(['signup/3']);
        }
      },
      error => {
        console.log(error);
      });
  }

  saveBrandStyles() {
    let logoSrc = '';
    if (this.imagesAndColorsObject.logo) {
      logoSrc = this.imagesAndColorsObject.logo.src;
    } 
    
    this.apiService.saveBrandStyles({
      borderRadius: this.imagesAndColorsObject.borderRadius,
      background1: "#ffffff",
      background2: "#f2f2f2",
      bodyFont: this.imagesAndColorsObject.bodyFont,
      headerFont: this.imagesAndColorsObject.headerFont,
      primaryColor: this.imagesAndColorsObject.color,
      logo: logoSrc,
      about: this.imagesAndColorsObject.aboutRaw
    }).subscribe(
      data => {
        console.log(data);
        if (data) {
          console.log(data);
          this.router.navigate(['signup/5']);
        }
      },
      error => {
        console.log(error);
      });
  }
}
