import { Component } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { SharedService } from '../shared.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-fill-template',
  templateUrl: './fill-template.component.html',
  styleUrls: ['./fill-template.component.scss']
})
export class FillTemplateComponent {
  opportunityId: any;
  opportunityObject: any;
  prompt: any;
  duplicating: boolean = false;

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public notification: NzNotificationService,
    public sharedService: SharedService,
    private message: NzMessageService
  ) {

  }

  ngOnInit() {
    this.opportunityId = this.route.snapshot.paramMap.get("id");
    this.apiService.getOpportunity(this.opportunityId).subscribe(
      (data: any) => {
        console.log(data);
        this.opportunityObject = data;
      });
  }

  sendPrompt(prompt: any) {
    console.log(prompt);
    this.apiService.fillOutTemplate(this.opportunityObject.contentTemplate.template, prompt).subscribe(
      (data: any) => {
        console.log(data);

        let newTemplate = {
          children: data.template
        }

        let oldTemplate = this.opportunityObject.contentTemplate.template;

        for (let component in oldTemplate.children) {
          this.applyStyles(oldTemplate.children[component], newTemplate.children[component]);
        }

        let newTemplateObj = JSON.parse(JSON.stringify(this.opportunityObject));

        delete newTemplateObj._id;
        newTemplateObj.type = "CAMPAIGN";
        newTemplateObj.version = 2;

        this.apiService.saveOpportunity(newTemplateObj).subscribe(
          (data: any) => {
            console.log(data);
            this.router.navigate([`/content-editor/${data._id}`]);
          });
      });
  }

  async applyStyles(target: any, origin: any) {
    console.log("target", target);
    console.log("origin", origin);

    function generateRandomKey(length: any) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      const charactersLength = characters.length;

      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    }

    target.key = generateRandomKey(20);

    if (target && origin) {
      if (!target.props.dynamicMode) {
        target.value = origin.value;
      }

      if (origin.background) {
        target.props.backgroundColor = origin.background;
      }

      if (origin.color) {
        target.props.color = origin.color;

        // if (origin.type == "TEXT") {
        //   await this.delay(1000);
        //   console.log("TEXT", origin.color);
        //   console.log(target);
        //   // target.quillEditor.format('color', origin.color);
        //   let quill = target.quillEditor;

        //   // Get the entire length of the editor content
        //   let length = quill.getLength();
        //   console.log(length);

        //   // Apply the color format to the entire range
        //   quill.formatText(0, length, 'color', origin.color);

        // }
      }

      if (origin.type == "IMAGE") {
        target.imageUrl = origin.image;
      }

      if (origin.type == "CONTAINER") {
        if (origin.value) {
          target.imageUrl = origin.value;
        }
      }

      if (origin.children && target.children) {
        for (let i = 0; i < origin.children.length; i++) {
          this.applyStyles(target.children[i], origin.children[i]);
        }
      }

    }
  }

  delay(ms: any) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async duplicateOpportunity() {
    this.duplicating = true;

    this.apiService.duplicateOpportunity(this.opportunityObject._id).subscribe(
      (data: any) => {
        console.log(data);
        this.message.create("success", `Duplicated`);
        this.router.navigate([`content-editor/${data._id}`]);
        this.duplicating = false;
      },
      error => {
        console.log(error);
      });
  }

}
