import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DynamicCssLoaderService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  loadStyle(url: string, id?: string): void {
    const head = document.head;
    const styleLink = this.renderer.createElement('link');
    this.renderer.setAttribute(styleLink, 'rel', 'stylesheet');
    // this.renderer.setAttribute(styleLink, 'type', 'text/css');
    this.renderer.setAttribute(styleLink, 'href', url);
    if (id) {
      this.renderer.setAttribute(styleLink, 'id', id);
    }
    this.renderer.appendChild(head, styleLink);
  }

  injectCSS(css: string): void {
    const head = document.head;  // Access the <head> element directly
    let styleElement: HTMLStyleElement;

    // Otherwise, create a new <style> element
    styleElement = this.renderer.createElement('style');
    this.renderer.setAttribute(styleElement, 'type', 'text/css');
    this.renderer.appendChild(head, styleElement);

    // Append the CSS text to the style element
    styleElement.appendChild(document.createTextNode(css));
  }
}