import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from '../api.service';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-view-html',
  templateUrl: './view-html.component.html',
  styleUrls: ['./view-html.component.scss']
})
export class ViewHtmlComponent {

  opportunities: any = [];
  loading = true;
  loadingNewEmail = false;
  emailType: any = {}

  prompt: any = "";

  options = true;

  email: any = {};

  emailTypes: any = [];

  promotion = false;
  promotionValue = "";

  sourceUrl = false;

  products = false;

  // emailTypes = [
  //   {
  //     name: "Featured product",
  //     value: "FEATUREDPRODUCT",
  //     properties: [
  //       {
  //         type: "PRODUCTLIST",
  //         name: "Product",
  //         value: []
  //       }
  //     ]
  //   },
  //   {
  //     name: "Featured category",
  //     value: "FEATUREDCATEGORY",
  //     properties: [
  //       {
  //         type: "INPUT",
  //         name: "Category",
  //         value: null
  //       }
  //     ]
  //   },
  //   {
  //     name: "Listicle",
  //     value: "LISTICLE",
  //     properties: [{
  //       type: "INPUT",
  //       name: "Source article URL",
  //       value: null
  //     }]
  //   }
  // ]

  emailObject: any = {
    sendTime: null,
    template: null,
    prompt: "",
    templateOptions: {
      properties: []
    },
    type: {
      properties: []
    },
    properties: [
      {
        type: "PRODUCTLIST",
        name: "Product",
        value: []
      }
    ],
    stage: "NEW"
  }

  PRODUCTFEATUREOPTIONS = [
    {
      type: "PRODUCTLIST",
      name: "Product",
      value: []
    }
  ];

  CATEGORYFEATUREOPTIONS = [
    {
      type: "INPUT",
      name: "Category",
      value: null
    }
  ];

  LISTICLEOPTIONS = [
    {
      type: "INPUT",
      name: "Source article URL",
      value: null
    }
  ];

  loadingChat = false;

  chatLog: any = [];

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public sharedService: SharedService,
    public notification: NzNotificationService,
    private message: NzMessageService
  ) { }

  ngOnInit() {
    this.apiService.getSuggestionOpportunities().subscribe(
      (data: any) => {
        console.log(data);
        this.opportunities = data;
        this.loading = false;
      },
      error => {
        console.log(error);
      });

    this.apiService.getEmailTypes().subscribe(
      (data: any) => {
        console.log(data);
        this.emailTypes = data;
        this.emailObject.templateOptions = this.emailTypes[0];
      },
      error => {
        console.log(error);
      });
  }

  newEmail() {
    this.loadingNewEmail = true;
    this.apiService.newEmail(this.prompt, this.sharedService.newDate).subscribe(
      (data: any) => {
        console.log(data);
        this.email = data;
        this.options = true;
        this.loadingNewEmail = false;
      }
    )
  }

  generateEmail() {
    this.emailObject.sendTime = this.sharedService.newDate;
    this.emailObject.template = this.emailObject.templateOptions.value;

    if (this.promotion) {
      this.emailObject.templateOptions.properties.push({
        type: "RICHTEXT",
        name: "Promotion",
        value: this.promotionValue
      });
    }

    this.apiService.saveOpportunity(this.emailObject).subscribe(
      (data: any) => {
        console.log(data);
        // this.apiService.generateTemplateContent(data._id).subscribe(
        this.apiService.generateTemplateContent2(data._id, "NEW", "").subscribe(
          (data2: any) => {
            console.log(data2);
            this.sharedService.fetchCalendar();
            this.sharedService.newOpen = false;
            // this.sharedService.checkForGeneratingEmails();
            this.message.create("success", `Your email is being generated`);

            // this.notification.create(
            //   'success',
            //   'Your email is being generated',
            //   ''
            // );
          },
          error => {
            console.log(error);
          });
      },
      error => {
        console.log(error);
      });
  }

  sendOpenAiChat() {
    this.loadingChat = true;
    this.apiService.sendOpenAiChat(this.emailObject.prompt).subscribe(
      (data2: any) => {
        console.log(data2);
        this.chatLog.push({ message: JSON.parse(data2.data[0].content[0].text.value).message, sender: "RIPPLE" });
        this.loadingChat = false;
      },
      error => {
        console.log(error);
      });
  }

  openPicker(property: any, mode: any) {
    console.log("Open Picker");
    this.sharedService.currentProperty = property;
    this.sharedService.pickerMode = mode;
    this.sharedService.pickerOpen = true;
  }

  removeItemFromArray(index: any, list: Array<any>) {
    list.splice(index, 1);
  }

  colorChosen(value: any) {
    console.log(value);

    // if (value.length == 7) {
    //   // Check if color already in palette 
    //   let index = this.opportunityObject.contentTemplate.colorPalette.findIndex((color: any) => color == value);
    //   console.log(index);

    //   if (index == -1) {
    //     this.opportunityObject.contentTemplate.colorPalette.push(value);
    //   }
    // }
  }

  generateSuggestion(suggestion: any) {
    this.prompt = suggestion.prompt;
    this.newEmail();
  }

  compareFn = (o1: any, o2: any): boolean => (o1 && o2 ? o1.value === o2.value : o1 === o2);

  close() {
    this.sharedService.htmlOpen = false;
  }

  sendInvite() {
    
  }

  addToClipboard() {
    navigator.clipboard.writeText(this.sharedService.htmlBody);
    this.message.create("success", `Copied to clipboard`);
  }
}
