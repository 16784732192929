<div class="page-wrapper">
    <div class="header-wrapper">
        <nz-page-header nzTitle="Flows">
            <nz-page-header-extra>
                <!-- <button nz-button nzType="primary" (click)="this.sharedService.openNew(null)"><span nz-icon
                        nzType="plus" nzTheme="outline"></span>New</button> -->
                <button nz-button nzType="primary" (click)="openNewFlow()"><span nz-icon nzType="plus"
                        nzTheme="outline"></span>New flow</button>
            </nz-page-header-extra>
        </nz-page-header>
    </div>
    <div class="content-wrapper">
        <!-- <nz-page-header style="padding-bottom: 6px !important" nzTitle="Analytics">
                <nz-page-header-extra>
                    <button nz-button nzType="link" [routerLink]="['/new']">See more</button>
                </nz-page-header-extra>
            </nz-page-header>
            <div class="custom-card">
                <div class="custom-card-body"></div>
            </div>
            <nz-page-header style="padding-bottom: 6px !important" nzTitle="Calendar">
                <nz-page-header-extra>
                    <button nz-button nzType="link" [routerLink]="['/new']">See more</button>
                </nz-page-header-extra>
            </nz-page-header>
            <div class="custom-card">
                <div class="custom-card-body"></div>
            </div> -->
        <!-- <nz-page-header style="padding-bottom: 6px !important" nzTitle="Drafts">
                <nz-page-header-extra>
                    <button nz-button nzType="link" [routerLink]="['/new']">See more</button>
                </nz-page-header-extra>
            </nz-page-header> -->
        <!-- <div class="custom-card table-card" style="height: auto !important;" *ngIf="sharedService.pendingOpps.length > 0">
            <div class="header-row" style="padding: 2px 8px 0px;">
                Generating
            </div>
            <div class="custom-card-body table-card" style="height: auto !important;">
                <ng-container *ngFor="let opportunity of sharedService.pendingOpps; index as index">
                    <div nz-row [nzGutter]="16" class="table-row">
                        <div nz-col class="gutter-row" [nzSpan]="4">
                            <div style="text-overflow: ellipsis; overflow: hidden;
                            white-space: nowrap;">



                            </div>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="20">
                            <div style="text-overflow: ellipsis; overflow: hidden;
                            white-space: nowrap;">
                                <ng-container *ngIf="opportunity.status == 'FAILED'">
                                    <span nz-icon nzType="stop" nzTheme="outline"></span>&nbsp;&nbsp;<nz-tag
                                        [nzColor]="'red'">Failed</nz-tag>
                                </ng-container>
                                <ng-container *ngIf="opportunity.status == 'INPROGRESS'">
                                    <span nz-icon nzType="loading" nzTheme="outline"
                                        [nzSpin]="true"></span>&nbsp;&nbsp;<nz-tag class="aqua">Generating</nz-tag>
                                </ng-container>
                                <ng-container *ngIf="opportunity.status == 'NEW'">
                                    <span nz-icon nzType="loading-3-quarters"
                                        nzTheme="outline"></span>&nbsp;&nbsp;<nz-tag>Waiting</nz-tag>
                                </ng-container>
                                {{opportunity.opportunity.title}}
                            </div>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="4" style="text-align: right">
                            <ng-container *ngIf="opportunity.status == 'FAILED'">
                                <button nz-button (click)="this.sharedService.tryJobAgain(opportunity)"><span nz-icon nzType="sync"
                                        nzTheme="outline"></span>Try again</button>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div> -->
        <!-- </div> -->
        <!-- <div class="header-row" style="height: 49px; padding: 0px 16px;">
            <nz-segmented [(ngModel)]="this.viewMode" [nzOptions]="options" style="float: right; margin-top: 6px;"
                (nzValueChange)="changedView($event)"></nz-segmented>
        </div> -->
        <!-- <div class="picker-wrapper">
            <div class="custom-card">
                <ng-container *ngIf="!emailOptions">

                    <div class="custom-card-body" style="padding: 16px;">
                        <div nz-row [nzGutter]="16">
                            <div nz-col class="gutter-row" [nzSpan]="6">
                                <div class="custom-card hover">
                                    <div class="custom-card-body" style="padding: 16px;">
                                        <div class="email-type-wrapper">
                                            <i class="lni lni-add-files"></i>
                                        </div>
                                        <div class="email-type-label">Custom prompt</div>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngFor="let flowType of flowTypes">
                                <div nz-col class="gutter-row" [nzSpan]="6">
                                    <div class="custom-card hover" (click)="chooseFlowType(flowType)">
                                        <div class="custom-card-body" style="padding: 16px;">
                                            <div class="email-type-wrapper">
                                                <i class="{{flowType.icon}}"></i>
                                            </div>
                                            <div class="email-type-label">{{flowType.name}}</div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <nz-divider nzText="or"></nz-divider>
                        <button nz-button nzBlock><span nz-icon nzType="edit" nzTheme="outline"></span>Create a custom
                            flow</button>
                    </div>
                </ng-container>

                <ng-container *ngIf="emailOptions">
                    <div class="custom-card-header" style="padding: 16px;">
                        <button (click)="this.emailOptions = false" nz-button><span nz-icon nzType="arrow-left"
                                nzTheme="outline"></span>Back</button>
                    </div>
                    <div class="custom-card-body" style="padding: 16px;">
                        <ng-container *ngFor="let property of currentEmailType.properties">
                            <ng-container *ngIf="property.type == 'INPUT'">
                                <nz-form-item>
                                    <nz-form-label [nzNoColon]="true">{{ property.name }}</nz-form-label>
                                    <nz-form-control [nzSpan]="24">
                                        <input nz-input [(ngModel)]="property.value" />
                                    </nz-form-control>
                                </nz-form-item>
                            </ng-container>
                            <ng-container *ngIf="property.type == 'RICHTEXT'">
                                <nz-form-item>
                                    <nz-form-label [nzNoColon]="true">{{ property.name }}</nz-form-label>
                                    <nz-form-control [nzSpan]="24">
                                        <textarea [nzAutosize]="{ minRows: 2, maxRows: 15 }" nz-input
                                            [(ngModel)]="property.value"></textarea>
                                    </nz-form-control>
                                </nz-form-item>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div class="custom-card-footer">
                        <button nz-button nzType="primary" (click)="generateFlow()">Generate</button>
                    </div>
                </ng-container>
            </div>
        </div> -->
        <ng-container *ngIf="this.loading">
            <div class="custom-card">
                <div class="loading-wrapper">
                    <div class="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
            <!-- <div nz-row [nzGutter]="16" class="table-row" style="padding-top: 16px;">
                <div nz-col class="gutter-row" [nzSpan]="8">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
            </div>
            <div nz-row [nzGutter]="16" class="table-row" style="padding-top: 16px;">
                <div nz-col class="gutter-row" [nzSpan]="8">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
            </div>
            <div nz-row [nzGutter]="16" class="table-row" style="padding-top: 16px;">
                <div nz-col class="gutter-row" [nzSpan]="8">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
            </div>
            <div nz-row [nzGutter]="16" class="table-row" style="padding-top: 16px;">
                <div nz-col class="gutter-row" [nzSpan]="8">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
            </div> -->

        </ng-container>
        <ng-container *ngIf="!this.loading">
            <div class="content-inner">
                <div class="custom-card">
                    <ng-container *ngIf="!this.opportunities || this.opportunities.length == 0">
                        <div class="custom-card-body">
                            <div style="text-align: center; padding-bottom: 16px;">
                                <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="null"></nz-empty>
                                <div style="font-weight: 500; margin-bottom: 24px;">You don't have any flows :/
                                </div>
                                <button nz-button nzType="primary" (click)="openNewFlow()"><span nz-icon nzType="plus"
                                        nzTheme="outline"></span>Create a flow</button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="this.opportunities && this.opportunities.length > 0">

                        <div class="custom-card-header"
                            style="margin: 0px 0px; border-bottom: none; padding: 0px !important;">
                            <div nz-row class="table-row" [nzGutter]="16" style="padding: 0px 24px !important">
                                <!-- <div nz-col class="gutter-row" [nzSpan]="2">
                    <div class="inner-box"></div>
                </div> -->
                                <div nz-col class="gutter-row" [nzSpan]="12">
                                    Title
                                </div>
                                <div nz-col class="gutter-row" [nzSpan]="6">
                                    Sendtime
                                </div>
                                <div nz-col class="gutter-row" [nzSpan]="6">
                                    Updated
                                </div>
                            </div>
                        </div>
                        <div class="custom-card-body" style="padding: 0px !important;">
                            <ng-container *ngFor="let opportunity of opportunities; index as index">
                                <ng-container
                                    *ngIf="index >= (this.currentPage - 1) * 50 && index < (this.currentPage * 50)">
                                    <div nz-row [nzGutter]="16" class="table-row"
                                        [routerLink]="['/flows',opportunity._id]" style="padding: 0px 24px !important">

                                        <div nz-col class="gutter-row" [nzSpan]="12"
                                            style="color: #374151; font-weight: 500;">
                                            <div style="text-overflow: ellipsis; overflow: hidden;
                            white-space: nowrap;">
                                                <!-- <ng-container
                                    *ngIf="opportunity.contentTemplate && opportunity.contentTemplate.subjectLine">
                                    {{opportunity.contentTemplate.subjectLine}}
                                </ng-container>
                                <ng-container
                                    *ngIf="!opportunity.contentTemplate || !opportunity.contentTemplate.subjectLine">
                                </ng-container> -->
                                                {{opportunity.name}}

                                            </div>
                                        </div>
                                        <!-- <div nz-col class="gutter-row" [nzSpan]="6">
                            <ng-container *ngIf="opportunity.stage == 'LIVE'">
                                <nz-tag class="aqua">Scheduled</nz-tag>
                            </ng-container>
                            <ng-container *ngIf="opportunity.stage == 'PENDING'">
                                <nz-tag>Draft</nz-tag>
                            </ng-container>
                            <ng-container *ngIf="opportunity.stage == 'FAILED'">
                                <nz-tag [nzColor]="'red'">Failed&nbsp;&nbsp;<span nz-icon nzType="stop"
                                        nzTheme="outline"></span></nz-tag><button nzType="text" nz-button
                                    (click)="this.sharedService.tryJobAgain(opportunity)"><span nz-icon nzType="sync"
                                        nzTheme="outline"></span>Try again</button>
                            </ng-container>
                            <ng-container *ngIf="opportunity.stage == 'INPROGRESS'">
                                <nz-tag class="aqua">Generating&nbsp;&nbsp;<span nz-icon nzType="loading"
                                        nzTheme="outline" [nzSpin]="true"></span></nz-tag>
                            </ng-container>
                            <ng-container *ngIf="opportunity.stage == 'NEW'">
                                <nz-tag>Waiting&nbsp;&nbsp;<span nz-icon nzType="pause-circle"
                                        nzTheme="outline"></span></nz-tag>
                            </ng-container>

                        </div> -->
                                        <div nz-col class="gutter-row" [nzSpan]="6">
                                            {{opportunity.createdAt | date}}
                                        </div>
                                        <div nz-col class="gutter-row" [nzSpan]="6">
                                            {{opportunity.updatedAt | date}}
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="custom-card-footer" style="border-top: none !important;">
                            <nz-pagination [nzTotal]="opportunities.length" [nzPageSize]="50"
                                (nzPageIndexChange)="this.pageIndexChange($event)"></nz-pagination>
                        </div>
                    </ng-container>
                </div>
            </div>
            <!-- <div class="footer-wrapper">
                <nz-pagination [nzTotal]="opportunities.length" [nzPageSize]="50"
                    (nzPageIndexChange)="this.pageIndexChange($event)"
                    style="float: right; margin: 8px"></nz-pagination>
            </div> -->
        </ng-container>

        <!-- <div class="column-right">
            <nz-page-header style="padding-bottom: 6px !important" class="site-page-header">
                <nz-page-header-title>Suggestions</nz-page-header-title>
                <nz-breadcrumb nz-page-header-breadcrumb>
                    <nz-breadcrumb-item>Opportunities</nz-breadcrumb-item>
                    <nz-breadcrumb-item>Pending</nz-breadcrumb-item>
                </nz-breadcrumb>
                <nz-page-header-extra>
                    <button nz-button nzType="link" [routerLink]="['/new']">See more</button>
                </nz-page-header-extra>
            </nz-page-header>
            <ng-container *ngIf="this.loadingNewOpps">
                <div class="custom-card">
                    <div class="custom-card-body mini">
                        <nz-skeleton [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                    </div>
                </div>
                <div class="custom-card">
                    <div class="custom-card-body mini">
                        <nz-skeleton [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                    </div>
                </div>
                <div class="custom-card">
                    <div class="custom-card-body mini">
                        <nz-skeleton [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngFor="let opp of this.newOpportunities">
                <div class="custom-card hover" [routerLink]="['/opportunities',opp._id]">
                    <div class="custom-card-body mini">
                        <div class="new-opp-title">{{opp.title}}</div>
                        <ng-container *ngIf="opp.template == 'FEATUREDPRODUCT'">
                            <nz-tag>Featured product</nz-tag>
                        </ng-container>
                        <ng-container *ngIf="opp.template == 'EDUCATIONAL'">
                            <nz-tag>Educational</nz-tag>
                        </ng-container>
                        <ng-container *ngIf="opp.template == 'FEATUREDCATEGORY'">
                            <nz-tag>Featured category</nz-tag>
                        </ng-container>
                        <div class="new-opp-body">{{opp.description}}</div>
                    </div>
                </div>
            </ng-container>
        </div> -->


    </div>
</div>