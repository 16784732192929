import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ApiService } from 'src/app/api.service';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.scss']
})
export class ProductViewComponent {

  loading = true;
  saving = false;
  workspace: any = null;

  logoObject = {
    value: "https://ripple-files-cdn.azureedge.net/images/664a856b38f95ddc3d293a46-68e609f0-a134-45a4-9e56-2a7eac9d4c46.png"
  }

  navbarLinksTemplate = {
    name: "Navbar links",
    type: "DATALIST",
    category: "CONTENT",
    value: [
    ],
    dataTemplate: [
      {
        name: "Title",
        type: "INPUT",
        category: "CONTENT",
        value: "",
      },
      {
        name: "Link",
        type: "INPUT",
        category: "CONTENT",
        value: "",
      },
    ]
  };

  footerLinksTemplate = {
    name: "Social links",
    type: "DATALIST",
    category: "CONTENT",
    value: [
    ],
    dataTemplate: [
      {
        name: "Platform",
        type: "SELECT",
        category: "CONTENT",
        value: {
          title: "Instagram",
          value: "fa-brands fa-instagram"
        },
        options: [
          {
            title: "Instagram",
            value: "fa-brands fa-instagram"
          },
          {
            title: "Facebook",
            value: "fa-brands fa-facebook"
          },
          {
            title: "Twitter",
            value: "fa-brands fa-x-twitter"
          },
          {
            title: "Pinterest",
            value: "fa-brands fa-pinterest"
          },
          {
            title: "Youtube",
            value: "fa-brands fa-youtube"
          },
          {
            title: "Linkedin",
            value: "fa-brands fa-linkedin"
          },
          {
            title: "Discord",
            value: "fa-brands fa-discord"
          }
        ]
      },
      {
        name: "Link",
        type: "INPUT",
        category: "CONTENT",
        value: "",
      },
    ]
  };

  brandImagesTemplate = {
    name: "Images",
    type: "DATALIST",
    category: "CONTENT",
    value: [
    ],
    dataTemplate: [
      {
        name: "Image",
        type: "IMAGESELECT",
        value: "",
      }
    ]
  };

  fontsTemplate = [
    {
      name: "Title",
      font: "",
      fontWeight: "",
      fontSize: ""
    },
    {
      name: "Subtitle",
      font: "",
      fontWeight: "",
      fontSize: ""
    },
    {
      name: "Body",
      font: "",
      fontWeight: "",
      fontSize: ""
    },
    {
      name: "Button",
      font: "",
      fontWeight: "",
      fontSize: ""
    },
  ];

  klaviyoListsAndSegments: any = [];
  loadingSegments = false;

  csvContent: any;
  convertedArray: any = [];
  properties: any = '';
  productId: any = "";
  productObject: any = {};

  constructor(
    public apiService: ApiService,
    public sharedService: SharedService,
    private message: NzMessageService,
    public route: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    this.getKlaviyoListsAndSegments();
    this.productId = this.route.snapshot.paramMap.get("id");

    this.apiService.getProduct(this.productId).subscribe(
      (data: any) => {
        console.log(data);
        this.productObject = data;
        // if (!data.brandStyles.navbar) {
        //   data.brandStyles.navbar = JSON.parse(JSON.stringify(this.navbarLinksTemplate));
        // }

        // if (!data.brandStyles.footerLinks) {
        //   data.brandStyles.footerLinks = JSON.parse(JSON.stringify(this.footerLinksTemplate));
        // } else {
        //   data.brandStyles.footerLinks.dataTemplate[0].options = JSON.parse(JSON.stringify(this.footerLinksTemplate.dataTemplate[0].options));
        // }

        // if (!data.brandStyles.brandImages) {
        //   data.brandStyles.brandImages = JSON.parse(JSON.stringify(this.brandImagesTemplate));
        // }

        // if (!data.brandStyles.headerTemplate) {
        //   this.apiService.createHeaderTemplate().subscribe(res => {

        //   });
        // }

        // if (!data.brandStyles.footerTemplate) {
        //   this.apiService.createFooterTemplate().subscribe(res => {

        //   });
        // }

        // if (!data.brandStyles.fonts) {
        //   data.brandStyles.fonts = JSON.parse(JSON.stringify(this.fontsTemplate));
        // }

        // this.workspace = data;
        // this.sharedService.brandLogo = this.workspace.brandStyles.logo;
        // this.sharedService.workspace = data;

        if (this.productObject.imageUrl) {
          this.logoObject.value = this.productObject.imageUrl;
        };

        if (!this.productObject.data) {
          this.productObject.data = {};  
        };

        if (!this.productObject.data.status) {
          this.productObject.data.status = "ACTIVE";
        };

        this.loading = false;
      },
      error => {
        console.log(error);
      });
  }

  openPicker(property: any, mode: any) {
    console.log("Open Picker");
    this.sharedService.currentProperty = property;
    this.sharedService.pickerMode = mode;
    this.sharedService.pickerOpen = true;
  }

  saveBrandStyles() {
    this.saving = true;
    this.workspace.brandStyles.logo = this.logoObject.value;
    this.apiService.saveBrandStyles(this.workspace.brandStyles).subscribe(
      (data: any) => {
        console.log(data);

        this.apiService.saveWorkspaceSettings(this.workspace).subscribe(
          (data: any) => {
            console.log(data);
            this.saving = false;
            this.message.create("success", `Settings saved`);
          },
          error => {
            console.log(error);
          });
      },
      error => {
        console.log(error);
      });
  }

  saveWorkspaceSettings() {
    this.saving = true;
    this.workspace.brandStyles.logo = this.logoObject.value;
    this.apiService.saveWorkspaceSettings(this.workspace).subscribe(
      (data: any) => {
        console.log(data);
        this.saving = false;
        this.message.create("success", `Settings saved`);
      },
      error => {
        console.log(error);
      });
  }

  compareFn = (o1: any, o2: any): boolean => (o1 && o2 ? o1 === o2 : o1 === o2);

  compareFnSocialLinks = (o1: any, o2: any): boolean => (o1 && o2 ? o1.value === o2.value : o1 === o2);


  pushDataListValue(property: any) {
    property.value.push(JSON.parse(JSON.stringify(property.dataTemplate)));
  }

  vectorDbProducts() {
    this.message.create("success", `Started sync`);
    this.apiService.vectorDbProducts().subscribe(
      data => {
        // login successful if there's a jwt token in the response
        console.log(data);
      },
      error => {
        console.log(error);
      });
    // this.syncToOpenAi();
    this.vectorDbCollections();
  }

  vectorDbCollections() {
    this.apiService.vectorDbCollections().subscribe(
      data => {
        // login successful if there's a jwt token in the response
        console.log(data);
      },
      error => {
        console.log(error);
      });
  }

  syncToOpenAi() {
    this.apiService.syncDataToOpenAi().subscribe(
      data => {
        // login successful if there's a jwt token in the response
        console.log(data);
      },
      error => {
        console.log(error);
      });
  }

  createReviewSnippets() {
    this.apiService.createReviewSnippets().subscribe(
      data => {
        // login successful if there's a jwt token in the response
        console.log(data);
      },
      error => {
        console.log(error);
      });
  }

  getKlaviyoListsAndSegments() {
    this.loadingSegments = true;
    this.apiService.getKlaviyoListsAndSegments().subscribe(
      (data: any) => {
        console.log(data);
        // this.loadingSegments = false;
        this.klaviyoListsAndSegments = data;

        // this.opportunityObject.audienceInclude = this.opportunityObject.audienceInclude.filter((ar: any) => this.klaviyoListsAndSegments.find((rm: any) => (rm.id === ar.id) ))
        // this.opportunityObject.audienceExclude = this.opportunityObject.audienceExclude.filter((ar: any) => this.klaviyoListsAndSegments.find((rm: any) => (rm.id === ar.id) ))

        // if (!this.opportunityObject.audienceInclude || this.opportunityObject.audienceInclude.length == 0) {
        //   this.opportunityObject.audienceInclude = [this.klaviyoListsAndSegments[0]];
        // }
      },
      error => {
        console.log(error);
      });

  }

  compareFn2 = (o1: any, o2: any): boolean => (o1 && o2 ? o1.id === o2.id : o1 === o2);

  onFileSelect(input: any) {
    const id = this.message.loading(`Uploading reviews`, { nzDuration: 0, nzAnimate: false }).messageId;
    const files = input.files;
    var fileTypes = ['csv']; //acceptable file types

    if (files && files.length) {
      var extension = input.files[0].name.split('.').pop().toLowerCase(), //file extension from input file
        //Validating type of File Uploaded
        isSuccess = fileTypes.indexOf(extension) > -1; //is extension in acceptable types
      //console.log(isSuccess);
      //console.log("Filename: " + files[0].name);
      // console.log("Type: " + files[0].type);
      //  console.log("Size: " + files[0].size + " bytes");
      var that = this;
      //Flag to check the Validation Result
      if (isSuccess) {
        const fileToRead = files[0];

        const fileReader = new FileReader();

        fileReader.onload = (fileLoadedEvent: any) => {
          const textFromFileLoaded = fileLoadedEvent.target.result;
          that.csvContent = textFromFileLoaded;

          //Flag is for extracting first line
          let flag = false;
          // Main Data
          let objarray: Array<any> = [];
          //Properties
          let prop: Array<any> = [];
          //Total Length
          let size: any = 0;

          for (const line of that.csvContent.split(/[\r\n]+/)) {
            if (flag) {
              let obj: any = {};
              for (let k: any = 0; k < size; k++) {
                //Dynamic Object Properties
                obj[prop[k]] = line.split(',')[k];
              }
              objarray.push(obj);
            } else {
              //First Line of CSV will be having Properties
              for (let k = 0; k < line.split(',').length; k++) {
                size = line.split(',').length;
                //Removing all the spaces to make them usefull
                prop.push(line.split(',')[k].replace(/ /g, ''));
              }
              flag = true;
            }
          }
          //All the values converted from CSV to JSON Array
          that.convertedArray = objarray;
          that.properties = [];
          //Object Keys of Converted JSON Array
          that.properties = prop;

          let finalResult = {
            properties: that.properties,
            result: that.convertedArray,
          };
          //On Convert Success
          console.log(finalResult);
          this.apiService.uploadReviews(finalResult.result).subscribe(
            (data: any) => {
              console.log(data);
              this.message.remove(id);
              this.message.create("success", `Reviews uploaded successfully and are now being processed. Allow 5 minutes for processing.`);
              // this.loadingSegments = false;
              // this.klaviyoListsAndSegments = data;

              // this.opportunityObject.audienceInclude = this.opportunityObject.audienceInclude.filter((ar: any) => this.klaviyoListsAndSegments.find((rm: any) => (rm.id === ar.id) ))
              // this.opportunityObject.audienceExclude = this.opportunityObject.audienceExclude.filter((ar: any) => this.klaviyoListsAndSegments.find((rm: any) => (rm.id === ar.id) ))

              // if (!this.opportunityObject.audienceInclude || this.opportunityObject.audienceInclude.length == 0) {
              //   this.opportunityObject.audienceInclude = [this.klaviyoListsAndSegments[0]];
              // }
            },
            error => {
              console.log(error);
            });
        };

        fileReader.readAsText(fileToRead, 'UTF-8');
      } else {
        //On Error
        console.error('Invalid File Format!');
      }
    }
  }

  saveProduct() {
    this.saving = true;
    this.productObject.imageUrl = this.logoObject.value;
    this.productObject.thumbnail = this.logoObject.value;
    this.apiService.saveProduct(this.productObject).subscribe(
      (data: any) => {
        console.log(data);
        this.saving = false;
        this.message.create("success", `Saved`);
      },
      error => {
        console.log(error);
      });
  }

  deleteProduct() {
    this.apiService.deleteProduct(this.productObject._id).subscribe(
      (data: any) => {
        console.log(data);
        this.router.navigate([`/content/products`]);
        this.message.create("success", `Deleted`);
      },
      error => {
        console.log(error);
      });
  }
}


