<div class="custom-modal-header">
    <ng-container *ngIf="this.sharedService.blockLibraryMode == 'NEWBLOCK'">
        <div class="custom-modal-title">Add a block</div>
    </ng-container>

    <ng-container *ngIf="this.sharedService.blockLibraryMode == 'CHANGEVARIANT'">
        <div class="custom-modal-title">Change block layout</div>
    </ng-container>

    <div class="custom-modal-close" (click)="close()"><span nz-icon nzType="close" nzTheme="outline"></span></div>
</div>
<ng-container *ngIf="this.loading">
    <div class="custom-modal-body">

        <div class="loading-wrapper">
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="!this.loading && this.blockList[0]">
    <ng-container *ngIf="this.mode == 'FILLBLOCK'">
        <div class="custom-modal-body">
            <nz-form-item style="margin-bottom: 8px;">
                <!-- <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">Fill with AI
                </nz-form-label> -->
                <nz-form-control nzSpan="24">
                    <textarea nz-input placeholder="Tell Ripple how you want this block filled..."
                        style="height: 100px;" [(ngModel)]="blockInstructions"
                        [disabled]="generatingBlockContent"></textarea>
                </nz-form-control>
            </nz-form-item>
            <button nz-button nzType="primary" (click)="fillWithAI()" nzBlock [nzLoading]="generatingBlockContent"><span
                    *ngIf="!generatingBlockContent">Fill with AI</span><span
                    *ngIf="generatingBlockContent">Generating...</span></button>
            <nz-divider nzText="or"></nz-divider>
            <button nz-button nzBlock (click)="addBlock()" [disabled]="generatingBlockContent">Add empty block</button>
        </div>
    </ng-container>
    <ng-container *ngIf="this.mode != 'FILLBLOCK'">
        <ng-container *ngIf="this.sharedService.blockLibraryMode == 'NEWBLOCK'">
            <div class="custom-modal-body" style="padding-bottom: 0px !important;">
                <ul nz-menu nzMode="horizontal" style="margin-bottom: 8px;">
                    <li nz-menu-item routerLinkActive="ant-menu-item-selected" (click)="this.blockType = 'RIPPLE'">
                        <span>Ripple blocks</span>
                    </li>
                    <li nz-menu-item routerLinkActive="ant-menu-item-selected" (click)="this.blockType = 'SAVEDBLOCKS'">
                        <span>Saved blocks</span>
                    </li>
                </ul>
                <div class="block-picker-category">
                    <ng-container *ngIf="this.blockType == 'RIPPLE'">
                        <div nz-row [nzGutter]="[12, 12]">
                            <ng-container *ngFor="let block of blockList">
                                <div nz-col class="gutter-row" [nzSpan]="12">
                                    <div class="custom-card hover mini" (click)="selectBlock(block, null)"
                                        [class.selected]="selectedBlock.type == block.type">
                                        <div class="custom-card-body mini">
                                            <div class="email-type-wrapper">
                                                <i class="lni lni-plus"></i>
                                            </div>
                                            <span class="picker-item-title">{{block.title}}</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="this.blockType == 'SAVEDBLOCKS'">
                        <div nz-row [nzGutter]="[12, 12]">
                            <ng-container *ngFor="let block of savedBlockList">
                                <div nz-col class="gutter-row" [nzSpan]="8">
                                    <div class="custom-card hover mini" (click)="selectBlock(block, null)"
                                        [class.selected]="selectedBlock._id == block._id" style="height: fit-content">
                                        <div class="custom-card-body mini">
                                            <!-- <div class="custom-circle-button delete-item" [routerLink]="['/editor',block._id,'e']"><span nz-icon nzType="edit"
                                                    nzTheme="outline"></span></div> -->
                                            <ng-container *ngIf="block.thumbnail">
                                                <img [src]="block.thumbnail">
                                            </ng-container>
                                            <ng-container *ngIf="!block.thumbnail">
                                                <div class="email-thumbnail"
                                                    style="background: #f9f9f9; text-align: center; font-size: 48px; padding-top: 75px; color: #d9d9d9">
                                                    <i class="lni lni-image"></i>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>

                </div>
            </div>
            <div class="custom-modal-footer">
                <ng-container *ngIf="this.blockType == 'RIPPLE'">
                    <ng-container *ngIf="this.selectedBlock.aiFill">
                        <button nz-button nzType="primary" (click)="chooseBlockType()">Confirm</button>
                    </ng-container>
                    <ng-container *ngIf="!this.selectedBlock.aiFill">
                        <button nz-button nzType="primary" (click)="addBlock()">Confirm</button>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="this.blockType == 'SAVEDBLOCKS'">
                    <button nz-button nzType="primary" (click)="addSavedBlock()">Confirm</button>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
</ng-container>