<div class="page-wrapper">
    <div class="header-wrapper">
        <nz-page-header class="site-page-header">
            <nz-page-header-title>Database</nz-page-header-title>
            <nz-page-header-extra>
                <button nz-button nzType="primary" style="float: right" (click)="this.newDatabaseObject()">Create</button>
            </nz-page-header-extra>
        </nz-page-header>
    </div>
    <div class="content-wrapper">
        <div class="column-left">
            <div class="navbar">

                <ul nz-menu nzMode="inline">
                    <ng-container *ngFor="let type of typesList">
                        <li nz-menu-item (click)="changeType(type)">
                            <span>{{type}}</span>
                        </li>
                    </ng-container>

                </ul>
            </div>
        </div>
        <div class="column-right">
            <ng-container *ngIf="this.loadingData">
                <div class="custom-card">
                    <div class="custom-card-body">
                        <nz-skeleton [nzParagraph]="{ rows: 2 }" [nzActive]="true"></nz-skeleton>
                    </div>
                </div>
                <div class="custom-card">
                    <div class="custom-card-body">
                        <nz-skeleton [nzParagraph]="{ rows: 2 }" [nzActive]="true"></nz-skeleton>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!this.loadingData">

                <ng-container *ngFor="let data of dataList; index as index">
                    <div class="custom-card">
                        <div class="custom-card-body">
                            <button nz-button style="float: right" (click)="this.sharedService.openDatabaseEditor(data, this.type)">Edit</button>
                            <ngx-json-viewer [json]="data['data']" [expanded]="false"></ngx-json-viewer>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <div class="footer">
                <nz-pagination [nzTotal]="this.objectCount" [nzPageSize]="100"
                    (nzPageIndexChange)="this.pageIndexChange($event)"></nz-pagination>
            </div>

        </div>
    </div>
</div>