import { Component } from '@angular/core';
import { SharedService } from '../shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { filter } from 'rxjs';

@Component({
  selector: 'app-objectives',
  templateUrl: './objectives.component.html',
  styleUrls: ['./objectives.component.scss']
})
export class ObjectivesComponent {
  loading: any = false;

  thisWeek: any = [];
  toDo: any = [];

  loadingThisWeek: any = true;
  toDoLoading: any = true;

  openRateMetric: any = {};
  loadingOpenRateMetric = true;

  clickRateMetric: any = {};
  loadingClickRateMetric = true;

  receivedEmailMetric: any = {};
  loadingReceivedEmailMetric = true;

  campaignsSentMetric: any = {};
  loadingCampaignsSentMetric = true;

  ordersMetric: any = {};
  loadingOrdersMetric = true;

  metricsFilter: any = "30DAYS";

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public sharedService: SharedService
  ) {

  }

  ngOnInit() {

    let filterDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
    this.apiService.getThisWeek().subscribe(
      (data: any) => {
        console.log(data);
        this.thisWeek = data;
        this.loadingThisWeek = false;
      },
      error => {
        console.log(error);
      });

    this.apiService.get14DayPlaceholders().subscribe(
      (data: any) => {
        console.log(data);
        this.toDo = data;
        this.toDoLoading = false;
      },
      error => {
        console.log(error);
      });

    if (this.sharedService.currentUser.adminLevel == 'SUPER') {

      this.apiService.getWorkspace(this.sharedService.currentUser.workspace._id).subscribe(
        (data: any) => {

          if (data.metrics?.WORKSPACECAMPAIGNSSENT) {
            this.campaignsSentMetric = data.metrics?.WORKSPACECAMPAIGNSSENT;
          }

          if (data.metrics?.WORKSPACEOPENRATE) {
            this.openRateMetric = data.metrics?.WORKSPACEOPENRATE;
          }

          if (data.metrics?.WORKSPACECLICKRATE) {
            this.clickRateMetric = data.metrics?.WORKSPACECLICKRATE;
          }

          if (data.metrics?.WORKSPACEATTRIBUTEDORDERS) {
            this.ordersMetric = data.metrics?.WORKSPACEATTRIBUTEDORDERS;
          }

          this.apiService.getMetric("WORKSPACECAMPAIGNSSENT", filterDate).subscribe(
            (data: any) => {
              console.log("CAMPAIGNSSENT", data);
              // this.loading = false;
              this.campaignsSentMetric = data;
              this.loadingCampaignsSentMetric = false;

              this.apiService.getMetric("WORKSPACEOPENRATE", filterDate).subscribe(
                (data: any) => {
                  console.log("OPENRATE", data);
                  // this.loading = false;
                  this.openRateMetric = data;
                  this.loadingOpenRateMetric = false;

                  this.apiService.getMetric("WORKSPACECLICKRATE", filterDate).subscribe(
                    (data: any) => {
                      console.log("CLICKRATE", data);
                      // this.loading = false;
                      this.clickRateMetric = data;
                      this.loadingClickRateMetric = false;

                      this.apiService.getMetric("WORKSPACEATTRIBUTEDORDERS", filterDate).subscribe(
                        (data: any) => {
                          console.log("ATTRIBUTEDORDERS", data);
                          // this.loading = false;
                          this.ordersMetric = data;
                          this.loadingOrdersMetric = false;
                        },
                        error => {
                          console.log(error);
                        });
                    },
                    error => {
                      console.log(error);
                    });
                },
                error => {
                  console.log(error);
                });
            },
            error => {
              console.log(error);
            });

        }, error => {
          console.log(error);
        });
    }
  }

  changeMetricFilter() {

    var filterDate: any = null;

    if (this.metricsFilter == "7DAYS") {
      filterDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    } else if (this.metricsFilter == "30DAYS") {
      filterDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
    } else if (this.metricsFilter == "24HOURS") {
      filterDate = new Date(Date.now() - 24 * 60 * 60 * 1000);
    }

    this.loadingCampaignsSentMetric = true;
    this.loadingOpenRateMetric = true;
    this.loadingClickRateMetric = true;
    this.loadingOrdersMetric = true;

    this.campaignsSentMetric = {};
    this.openRateMetric = {};
    this.clickRateMetric = {};
    this.ordersMetric = {};

    this.apiService.getMetric("WORKSPACECAMPAIGNSSENT", filterDate).subscribe(
      (data: any) => {
        console.log("CAMPAIGNSSENT", data);
        // this.loading = false;
        this.campaignsSentMetric = data;
        this.loadingCampaignsSentMetric = false;

        this.apiService.getMetric("WORKSPACEOPENRATE", filterDate).subscribe(
          (data: any) => {
            console.log("OPENRATE", data);
            // this.loading = false;
            this.openRateMetric = data;
            this.loadingOpenRateMetric = false;

            this.apiService.getMetric("WORKSPACECLICKRATE", filterDate).subscribe(
              (data: any) => {
                console.log("CLICKRATE", data);
                // this.loading = false;
                this.clickRateMetric = data;
                this.loadingClickRateMetric = false;

                this.apiService.getMetric("WORKSPACEATTRIBUTEDORDERS", filterDate).subscribe(
                  (data: any) => {
                    console.log("ATTRIBUTEDORDERS", data);
                    // this.loading = false;
                    this.ordersMetric = data;
                    this.loadingOrdersMetric = false;
                  },
                  error => {
                    console.log(error);
                  });
              },
              error => {
                console.log(error);
              });
          },
          error => {
            console.log(error);
          });
      },
      error => {
        console.log(error);
      });
  }

}
