<div class="page-wrapper">
    <div class="content-wrapper">
        <nz-page-header style="padding-bottom: 6px !important" class="site-page-header" nzTitle="Opportunities">
        </nz-page-header>
        <ul nz-menu nzMode="horizontal" style="margin-bottom: 16px !important;">
            <li nz-menu-item nzSelected>
                New
            </li>
            <li nz-menu-item [routerLink]="['/opportunities/pending']">
                Pending
            </li>
            <li nz-menu-item>
                Live
            </li>
        </ul>
        <div class="custom-alert aqua">Ripple's AI has identified the following opportunities for your brand.
        </div>
        <ng-container *ngIf="this.loading">
            <div class="custom-card">
                <div class="custom-card-body">
                    <nz-skeleton [nzParagraph]="{ rows: 2 }" [nzActive]="true"></nz-skeleton>
                </div>
            </div>
            <div class="custom-card">
                <div class="custom-card-body">
                    <nz-skeleton [nzParagraph]="{ rows: 2 }" [nzActive]="true"></nz-skeleton>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!this.loading">
            <ng-container *ngFor="let opportunity of opportunities">
                <div class="custom-card">
                    <div class="custom-card-header">
                        <h2 style="line-height: 36px; margin-bottom: 0px;">Schedule email campaign <nz-tag
                                class="aqua">$900 expected
                                revenue</nz-tag><button style="float: right" nz-button nzType="primary"
                                [routerLink]="['/opportunities',opportunity._id]">Explore</button></h2>
                    </div>
                    <div class="custom-card-body">
                        <div><span style="font-weight: 600">Topic:</span> {{opportunity.title}}</div>
                        <div><span style="font-weight: 600">Send time:</span> {{opportunity.sendTime | date}}</div>
                        <div><span style="font-weight: 600">Type:</span> {{opportunity.template}}</div>
                        <div style="margin-top: 8px;">{{opportunity.description}}</div>
                    </div>
                    <!-- <div class="custom-card-footer grey">
                        <button nz-button nzType="primary"
                            [routerLink]="['/opportunities',opportunity._id]">Explore</button>
                    </div> -->
                </div>
            </ng-container>
        </ng-container>

    </div>
</div>


<!-- <div class="page-wrapper">
    <div class="left-column">
        <div class="left-column-content">
            <nz-page-header class="site-page-header" nzTitle="New">
                <nz-breadcrumb nz-page-header-breadcrumb>
                    <nz-breadcrumb-item>Opportunities</nz-breadcrumb-item>
                    <nz-breadcrumb-item>New</nz-breadcrumb-item>
                </nz-breadcrumb>
            </nz-page-header>
            <ng-container *ngFor="let opportunity of opportunities">
                <nz-card nzHoverable>{{opportunity.title}}</nz-card>
            </ng-container>
        </div>
    </div>
    <div class="right-column">

    </div>
</div> -->