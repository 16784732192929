import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SharedService } from '../shared.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent {

  opportunities: any = [];
  loading = true;
  loadingNewEmail = false;
  prompt: any = "";
  emailObject: any = {
    sendTime: null,
    template: null,
    prompt: "",
    templateOptions: {
      properties: []
    },
    type: {
      properties: []
    },
    properties: [
      {
        type: "PRODUCTLIST",
        name: "Product",
        value: []
      }
    ],
    stage: "NEW"
  }
  showRenderer = false;
  creatingLayout = false;
  emailDone = false;
  promptHistory: any = [];
  emailHistory: any = [];
  currentEmail = 0;
  loadingStrings = [
    "Calculating the meaning of life...",
    "Summoning dragons...",
    "Searching for unicorns...",
    "Brewing a potion...",
    "Chasing rainbows...",
    "Plotting world domination...",
    "Juggling flaming torches...",
    "Solving the puzzle...",
    "Harnessing the power of the stars...",
    "Translating ancient runes...",
    "Harnessing the energy of thunderstorms...",
    "Deciphering hieroglyphics...",
    "Communing with spirits...",
    "Crafting a masterpiece...",
    "Sailing uncharted waters...",
    "Venturing into the unknown...",
    "Taming wild beasts...",
    "Building castles in the air...",
    "Exploring the cosmos...",
    "Unraveling mysteries of the universe...",
    "Whispering secrets to the wind...",
    "Dreaming of distant galaxies...",
    "Casting spells in the moonlight...",
    "Riding on the wings of imagination...",
    "Diving into the depths of the ocean...",
    "Dancing with fireflies...",
    "Wandering through enchanted forests...",
    "Wrestling with giants...",
    "Creating a symphony of stars...",
    "Painting with all the colors of the wind...",
    "Diving into the rabbit hole...",
    "Fishing for dreams in the sea of possibilities...",
    "Chasing after shooting stars...",
    "Trekking through the wilderness of ideas...",
    "Hunting for treasure in the depths of the mind...",
    "Wandering through the labyrinth of creativity...",
    "Climbing the mountain of inspiration...",
    "Exploring the caverns of imagination...",
    "Navigating the maze of innovation..."
  ];
  loadingText = "";
  chatLog: any = [];
  loadingChat: any = false;
  chatBox: any = "";
  delayMessage: any = false;
  showHeroOptions: any = false;
  heroOptions: any = false;
  suggestions: any = [];
  loadingSuggestions: any = true;
  promptEmailHistory: any = [];
  emailTypes: any = [
    {
      name: "Product feature",
      icon: "lni lni-bullhorn",
      prompt: "Make me a product feature email",
      properties: [
        {
          type: "PRODUCTLIST",
          name: "Product(s)",
          value: [],
          prefix: "Here are the products to feature"
        },
        {
          type: "RICHTEXT",
          name: "Angle (optional)",
          value: "",
          prefix: "Here is the angle of the email"
        },
        {
          type: "RICHTEXT",
          name: "Additional context (optional)",
          value: "",
          prefix: "Here is some additional info"
        }
      ]
    },
    {
      name: "Catalog overview",
      icon: "lni lni-grid-alt",
      prompt: "Make me a catalog overview email",
      properties: [
        {
          type: "RICHTEXT",
          name: "Products or collections to highlight",
          value: "",
          prefix: "Here are the products/collections to feature"
        },
        {
          type: "RICHTEXT",
          name: "Angle (optional)",
          value: "",
          prefix: "Here is the angle of the email"
        },
        {
          type: "RICHTEXT",
          name: "Additional context (optional)",
          value: "",
          prefix: "Here is some additional info"
        }
      ]
    },
    {
      name: "Review highlight",
      icon: "lni lni-comments-alt-2",
      prompt: "Make me a review highlight email",
      properties: [
        {
          type: "RICHTEXT",
          name: "Topic",
          value: "",
          prefix: "Here is the topic of the email"
        },
        {
          type: "RICHTEXT",
          name: "Additional context (optional)",
          value: "",
          prefix: "Here is some additional info"
        }
      ]
    },
    {
      name: "Blog / article",
      icon: "lni lni-rss-feed",
      prompt: "Make me an email based on a blog post / article",
      properties: [
        {
          type: "RICHTEXT",
          name: "Source content",
          value: "",
          prefix: "Here is the source content for the email"
        },
        {
          type: "RICHTEXT",
          name: "Additional context (optional)",
          value: "",
          prefix: "Here is some additional info"
        }
      ]
    },
    {
      name: "Educational",
      icon: "lni lni-graduation",
      prompt: "Make me an educational email",
      properties: [
        {
          type: "RICHTEXT",
          name: "Topic",
          value: "",
          prefix: "Here is the topic for the email"
        },
        {
          type: "RICHTEXT",
          name: "Additional context (optional)",
          value: ""
        }
      ]
    },
    {
      name: "Sale / promotion",
      icon: "lni lni-coin",
      prompt: "Make me an email for a sale / promotion",
      properties: [
        {
          type: "INPUT",
          name: "Sale / promotion name",
          value: "",
          prefix: "Here is the name of the sale / promotion"
        },
        {
          type: "RICHTEXT",
          name: "Offer details",
          value: "",
          prefix: "Here are the details of the offer"
        },
        {
          type: "INPUT",
          name: "Dates (optional)",
          value: "",
          prefix: "Here are the dates of the offer"
        },
        {
          type: "INPUT",
          name: "Promo code (optional)",
          value: "",
          prefix: "Here is the promo code to use for the offer"
        },
        {
          type: "RICHTEXT",
          name: "Additional context (optional)",
          value: "",
          prefix: "Here is some additional info"
        }
      ]
    },
    {
      name: "Founders note",
      icon: "lni lni-pencil",
      prompt: "Make me a founders note email",
      properties: [
        {
          type: "RICHTEXT",
          name: "Topic",
          value: "",
          prefix: "Here is the topic of the email"
        },
        {
          type: "RICHTEXT",
          name: "Additional context (optional)",
          value: "",
          prefix: "Here is some additional info"
        }
      ]
    }
  ];
  currentEmailType: any = {};
  emailOptions: any = false;
  customPromptEmailType = {
    name: "Custom prompt",
    icon: "lni lni-pencil",
    prompt: "Make me an email",
    properties: [
      {
        type: "RICHTEXT",
        name: "Prompt",
        value: "",
        prefix: "Here is the topic of the email"
      },
      {
        type: "RICHTEXT",
        name: "Additional context (optional)",
        value: "",
        prefix: "Here is some additional info"
      }
    ]
  }
  mode: any = null;
  workspaceObject: any = {};

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public sharedService: SharedService,
    private message: NzMessageService,
  ) { }

  ngOnInit() {
    this.generateSuggestions();
    this.sharedService.rendererOpportunityObject = null;
    this.apiService.getNewOpportunities().subscribe(
      (data: any) => {
        console.log(data);
        this.opportunities = data;
        this.loading = false;
      },
      error => {
        console.log(error);
      });

    this.apiService.getCurrentWorkspace().subscribe(
      (data: any) => {
        this.workspaceObject = data;
      });
  }

  async newEmail() {
    // this.loadingNewEmail = true;
    // this.apiService.newEmail(this.prompt, this.sharedService.newDate).subscribe(
    //   (data: any) => {
    //     console.log(data);
    //     this.router.navigate([`/opportunities/${data._id}`]);
    //   },
    //   error => {
    //     console.log(error);
    //   });
    let promptToUse = "";

    if (this.currentEmailType && this.promptHistory.length == 0) {
      promptToUse = this.currentEmailType.prompt;
      for (let property of this.currentEmailType.properties) {
        if (property.value) {
          if (property.type == "PRODUCTLIST") {
            let productStringList = "";
            for (let product of property.value) {
              productStringList += `${product.title}, `;
            }
            promptToUse += `, ${property.prefix}: ${productStringList}`;
          } else {
            promptToUse += `, ${property.prefix}: ${property.value}`;
          }
        }
      }
    } else {
      promptToUse = this.prompt;
    }

    this.delayMessage = false;
    this.emailObject.prompt = promptToUse;
    this.prompt = "";
    this.message.create("success", `Started generating your email`);
    this.chatLog.push({ message: this.emailObject.prompt, sender: "USER" });
    this.creatingLayout = true;
    this.emailDone = false;
    this.showRenderer = false;
    this.promptHistory.push(this.emailObject.prompt);
    this.updateString(); // Initial update

    setInterval(() => {
      this.updateString();
    }, 5000); // Update every 3 seconds

    setTimeout(() => {
      if (this.creatingLayout) {
        this.delayMessage = true;
      }
    }, 30000);

    if (this.promptHistory.length > 1) {
      this.emailObject.prompt = `The brand has already done several revisions of the email. I will provide you in order of oldest to newest the sets of instructions the brand has provided you. The last set of instructions I give you are the newest ones. Please factor in all feedback. Here are the instructions, in an array, from oldest to newest: ${JSON.stringify(this.promptHistory)}. Here is the corresponding list of emails you generated based on each piece of feedback, also from oldest to newest: ${JSON.stringify(this.promptEmailHistory)})}`;
    } else {

    }

    this.chatLog.push({ message: "Working on your email now!", sender: "RIPPLE" });

    let data: any = {};
    let data2: any = {};
    if (!this.sharedService.rendererOpportunityObject) {
      data = await this.apiService.saveOpportunity(this.emailObject).toPromise();
      console.log("SAVED NEW EMAIL", data);

      data2 = await this.apiService.generateTemplateContent2(data._id, "LAYOUTONLY", "").toPromise();
      console.log(data2);
    } else {
      this.sharedService.rendererOpportunityObject.prompt = this.emailObject.prompt;
      console.log("SAVED EXISTING EMAIL", this.sharedService.rendererOpportunityObject);
      data = await this.apiService.saveOpportunity(this.sharedService.rendererOpportunityObject).toPromise();
      data2 = await this.apiService.generateTemplateContent2(data._id, "LAYOUTONLY", "").toPromise();
      console.log(data2);
    }

    let data3: any = await this.apiService.getOpportunity(data._id).toPromise();
    console.log(data3);
    let emailNoBlocks = JSON.parse(JSON.stringify(data3));
    emailNoBlocks.contentTemplate.blocks = [];
    this.sharedService.rendererOpportunityObject = emailNoBlocks;
    // this.sharedService.rendererOpportunityObject.contentTemplate.blocks.push({ type: "SKELETON" });
    this.sharedService.loadingRenderer = false;
    this.creatingLayout = false;
    this.showRenderer = true;
    // this.message.create("success", `Layout complete, generating email content`);

    const id = this.message.loading(`Generating email content`, { nzDuration: 0, nzAnimate: false }).messageId;
    let numberGenerated = 0;

    this.promptEmailHistory.push(data3.contentTemplate.gptTemplate);

    for (let blockIndex in data3.contentTemplate.gptTemplate) {
      // const id = this.message.loading(`Generating block ${parseInt(blockIndex) + 1} of ${data3.contentTemplate.gptTemplate.length}`, { nzDuration: 0, nzAnimate: false }).messageId;
      let block = data3.contentTemplate.gptTemplate[blockIndex];
      this.sharedService.rendererOpportunityObject.contentTemplate.blocks.push({ type: "SKELETON" });
      // let data4: any = await this.apiService.generateTemplateContent2(data3._id, "ONEBLOCK", "", block).toPromise();
      this.apiService.generateTemplateContent2(data3._id, "ONEBLOCK", "", block).subscribe((data4: any) => {
        console.log(data4);
        // this.sharedService.rendererOpportunityObject.contentTemplate.blocks.splice(this.sharedService.rendererOpportunityObject.contentTemplate.blocks.length - 1, 0, data4.result);
        this.sharedService.rendererOpportunityObject.contentTemplate.blocks[blockIndex] = data4.result;
        console.log(this.sharedService.rendererOpportunityObject.contentTemplate.blocks);
        // this.sharedService.rendererOpportunityObject.contentTemplate.blocks.push(data4.result);
        // if (parseInt(blockIndex) == data3.contentTemplate.blocks.length - 1) {
        //   this.sharedService.rendererOpportunityObject.contentTemplate.blocks.pop();
        //   this.message.create("success", `Your email is complete!`);
        // }

        numberGenerated++;
        if (numberGenerated == data3.contentTemplate.gptTemplate.length) {
          this.message.remove(id);
          const id2 = this.message.loading(`Finalizing`, { nzDuration: 0, nzAnimate: false }).messageId;
          this.emailObject.prompt = "";
          this.emailHistory.push(this.sharedService.rendererOpportunityObject);
          this.currentEmail = this.emailHistory.length - 1;
          this.chatLog.push({ message: `Your email is complete! Enter any feedback below or click "Save & continue" to go to the editor!`, sender: "RIPPLE" });
          this.apiService.saveOpportunity(this.sharedService.rendererOpportunityObject).subscribe(value => {

          });

          console.log("GENERATING SUBJECLINE");
          this.apiService.generateSubjectLineObject(data._id).subscribe((subjectLineObject: any) => {
            console.log("GENERATING SUBJECLINE 2", subjectLineObject);
            this.sharedService.rendererOpportunityObject.contentTemplate.subjectLine = subjectLineObject.subjectLine;
            this.sharedService.rendererOpportunityObject.contentTemplate.previewText = subjectLineObject.previewText;
            this.sharedService.rendererOpportunityObject.title = subjectLineObject.emailTitle;

            this.apiService.saveOpportunity(this.sharedService.rendererOpportunityObject).subscribe(value => {
              console.log("GENERATING SUBJECLINE 3", value);
              // this.sharedService.rendererOpportunityObject = value;
              this.message.remove(id2);
              this.message.create("success", `Your email is complete!`);
              this.emailDone = true;
            });
          });

        }
      })
    }
    // this.sharedService.fetchCalendar();
    // this.message.create(
    //   'success',
    //   'Email generated'
    // );

    // this.notification.create(
    //   'success',
    //   'Your email is being generated',
    //   ''
    // );
  }

  nextEmail() {
    if (this.currentEmail < this.emailHistory.length - 1) {
      this.currentEmail++;
      this.sharedService.rendererOpportunityObject = this.emailHistory[this.currentEmail];
    }
  }

  previousEmail() {
    if (this.currentEmail > 0) {
      this.currentEmail--;
      this.sharedService.rendererOpportunityObject = this.emailHistory[this.currentEmail];
    }
  }

  async saveEmail() {
    let savedEmail: any = await this.apiService.saveOpportunity(this.sharedService.rendererOpportunityObject).toPromise();
    console.log(savedEmail);
    this.message.create("success", `Your email is saved`);
    this.router.navigate([`/editor/${this.sharedService.rendererOpportunityObject._id}/opp`]);
  }

  async updateString() {
    const randomIndex = Math.floor(Math.random() * this.loadingStrings.length);
    this.loadingText = this.loadingStrings[randomIndex];
    // console.log(this.loadingText);
  }

  async generateHeroOptions() {
    this.delayMessage = false;
    this.message.create("success", `Started generating your email`);
    this.chatLog.push({ message: this.emailObject.prompt, sender: "USER" });
    this.creatingLayout = true;
    this.emailDone = false;
    this.showRenderer = false;
    this.promptHistory.push(this.emailObject.prompt);
    this.updateString(); // Initial update

    setInterval(() => {
      this.updateString();
    }, 5000); // Update every 3 seconds

    setTimeout(() => {
      if (this.creatingLayout) {
        this.delayMessage = true;
      }
    }, 30000);

    // if (this.promptHistory.length > 1) {
    //   this.emailObject.prompt = `The brand has already done several revisions of the email. I will provide you in order of oldest to newest the sets of instructions the brand has provided you. The last set of instructions I give you are the newest ones. Please factor in all feedback. Here are the instructions, in an array, from oldest to newest: ${JSON.stringify(this.promptHistory)}`;
    // }

    let heroOptions = await this.apiService.generateHeroOptions(this.prompt).toPromise();
    console.log(heroOptions);
    this.heroOptions = heroOptions;

    this.creatingLayout = false;
    this.showHeroOptions = true;
  };

  async generateSuggestions() {
    this.loadingSuggestions = true;
    let suggestions: any = await this.apiService.generateSuggestions().toPromise();
    console.log(suggestions);
    this.suggestions = suggestions.emails;
    this.loadingSuggestions = false;
  }

  async generateSuggestion(suggestion: any) {
    this.creatingLayout = true;
    this.currentEmailType = null;
    this.prompt = suggestion.description;
    this.newEmail();
  }

  chooseEmailType(emailType: any) {
    this.currentEmailType = emailType;
    this.emailOptions = true;
  }

  removeItemFromArray(index: any, list: Array<any>) {
    list.splice(index, 1);
  }

  openPickerSingle(property: any, mode: any) {
    console.log("Open Picker");
    this.sharedService.currentProperty = property;
    this.sharedService.pickerMode = mode;
    this.sharedService.pickerOpen = true;
    this.sharedService.pickerNumber = "SINGLE";
  }

  openPicker(property: any, mode: any) {
    console.log("Open Picker");
    this.sharedService.currentProperty = property;
    this.sharedService.pickerMode = mode;
    this.sharedService.pickerOpen = true;
  }

  openNewFlow() {
    this.sharedService.newFlowFolderOpen = true;
  }

  createSMS() {
    this.apiService.createSMS(" ").subscribe(
      (data: any) => {
        this.router.navigate([`/sms/${data._id}`]);
      },
      error => {
        console.log(error);
      });
  }
}
