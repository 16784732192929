import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { SharedService } from '../shared.service';
import * as htmlToImage from 'html-to-image';
import { NzMessageService } from 'ng-zorro-antd/message';
import Quill from 'quill';
// import 'quill-emoji/dist/quill-emoji.js'

@Component({
  selector: 'app-email-renderer-v3',
  templateUrl: './email-renderer-v3.component.html',
  styleUrls: ['./email-renderer-v3.component.scss']
})
export class EmailRendererV3Component {
  @Input() mode: string;
  @ViewChild('scrollableDiv') scrollableDiv!: ElementRef;
  placeholder = "";
  testing123 = false;

  movies = [
    'Episode I - The Phantom Menace',
    'Episode II - Attack of the Clones',
    'Episode III - Revenge of the Sith',
    'Episode IV - A New Hope',
    'Episode V - The Empire Strikes Back',
    'Episode VI - Return of the Jedi',
    'Episode VII - The Force Awakens',
    'Episode VIII - The Last Jedi',
    'Episode IX – The Rise of Skywalker',
  ];

  modules: any = {
    // 'emoji-shortname': true,
    // 'emoji-textarea': true,
    // 'emoji-toolbar': true,
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'align': [] }],
      // ['emoji'],
      ['clean']                                      // remove formatting button
    ]
  };

  blockTab = "CONTENT";
  opportunityId: any = '';
  opportunityObject: any = {};
  loading = true;
  newMessageBody = '';

  activeBlock: any = {};

  fetchingSiteData = true;
  siteData: any = {};

  logoImage = "";
  primaryColor = "";

  blockFeedback: any = "";
  refreshBlockIndex: any = null;
  refreshBlockObject: any = null;
  refreshVisible: any = false;

  templateObject = {
    templateContentBackground: "#f7f5ef",
    templateBackground: "#fff",
    blocks: [
      {
        type: "LOGO",
        description: "shows the logo of the brand",
        properties: [
          {
            name: "Image URL",
            type: "INPUT",
            value: "https://cdn.shopify.com/s/files/1/0664/2103/1145/products/logo_scf.png?v=1671807225",
          },
        ],
      },
      {
        type: "TITLE",
        description: "Title & subtitle of the email",
        properties: [
          {
            name: "Title",
            type: "INPUT",
            value: "Don't let your cart slip away",
          },
          {
            name: "Subtitle",
            type: "INPUT",
            value: "Looks like you left some items in your cart",
          },
        ],
      },
      {
        type: "BUTTON",
        description: "Button the user can click to navigate to the brands website",
        properties: [
          {
            name: "Button text",
            type: "INPUT",
            value: "Go to my cart",
          },
          {
            name: "Button color",
            type: "INPUT",
            value: "#fff",
          },
        ],
      },
      {
        type: "IMAGEFULLWIDTH",
        description: "Bold image that covers the full width of the email",
        properties: [
          {
            name: "Image URL",
            type: "INPUT",
            value: "https://cdn.shopify.com/s/files/1/0664/2103/1145/products/ribeye.png?v=1664787645&width=533",
          },
        ],
      },
    ]
  }

  blockLibrary = [
    {
      title: "Hero",
      icon: "home",
      type: "HERO"
    },
    {
      title: "Image",
      icon: "file-image",
      type: "IMAGE",
      properties: [
        {
          name: "Image",
          type: "INPUT",
          value: "https://cdn.shopify.com/s/files/1/0122/2986/9664/products/Pebble-mainslider3_1080x.png?v=1666930457",
        },
        {
          name: "Background color",
          type: "INPUT",
          value: "",
        },
        {
          name: "Layout",
          type: "SELECT",
          value: {
            title: "Full width",
            value: "FULLWIDTH"
          },
          category: "STYLES",
          options: [
            {
              title: "Standard",
              value: "STANDARD"
            },
            {
              title: "Full width",
              value: "FULLWIDTH"
            }
          ]
        },
      ]
    },
    {
      title: "Article",
      icon: "book",
      type: "ARTICLE"
    },
    {
      title: "Features",
      icon: "appstore",
      type: "FEATURES"
    }
  ];

  newRefreshContentBlock: any = null;
  blockRefreshLoading: any = false;
  showRefreshPreview: any = false;

  savingBlock: any = false;

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    private cdr: ChangeDetectorRef,
    private scroll: ViewportScroller,
    public sharedService: SharedService,
    private message: NzMessageService
  ) {
    this.mode = "VIEW";
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
  }

  ngOnInit() {
    // this.sharedService.loadingRenderer = true;
    // this.sharedService.rendererOpportunityObject = {};
    this.opportunityId = this.route.snapshot.paramMap.get("id");
    const urlSegments = this.route.snapshot.pathFromRoot.flatMap(route => route.url).map(segment => segment.toString());
    const pathContainsExport = urlSegments.some(segment => segment.toLowerCase().includes('export'));

    if (pathContainsExport) {
      console.log("The path contains 'export'.");
      this.mode = "VIEW";
    }

    console.log(this.opportunityId);
    this.loading = true;
    this.sharedService.loadingRenderer = true;
    if (this.opportunityId) {
      this.apiService.getOpportunity(this.opportunityId).subscribe(
        (data: any) => {
          console.log(data);
          this.opportunityObject = data;
          this.sharedService.rendererOpportunityObject = data;
          this.sharedService.loadingRenderer = true;

          var Size = Quill.import('attributors/style/size');
          Size.whitelist = [false, '8px', '9px', '10px', '12px', '14px', '16px', '20px', '24px', '32px', '42px', '54px', '68px', '84px', '98px'];
          Quill.register(Size, true);

          var toolbarOptions = [
            [{ 'size': ['14px', '16px', '18px'] }],
          ];

          this.modules = {
            // 'emoji-shortname': true,
            // 'emoji-textarea': true,
            // 'emoji-toolbar': true,
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'color': this.opportunityObject.contentTemplate.colorPalette }, { 'background': this.opportunityObject.contentTemplate.colorPalette }],          // dropdown with defaults from theme
              [{ 'align': [] }],
              [{ 'size': [false, '8px', '9px', '10px', '12px', '14px', '16px', '20px', '24px', '32px', '42px', '54px', '68px', '84px', '98px'] }],
              // ['emoji'],
              ['clean']                                      // remove formatting button
            ]
          };

          for (let block of this.opportunityObject.contentTemplate.blocks) {
            if (block.type == "PRODUCT") {
              if (this.getArrayItem(block.properties, "Products") && this.getArrayItem(block.properties, "Products").value) {
                for (let product of this.getArrayItem(block.properties, "Products").value) {
                  if (!product.buttonText) {
                    product.buttonText = "Shop now";
                  }
                }
              }
            }

            if (block.type == "COLLECTION") {
              if (this.getArrayItem(block.properties, "Collections") && this.getArrayItem(block.properties, "Collections").value) {
                for (let product of this.getArrayItem(block.properties, "Collections").value) {
                  if (!product.buttonText) {
                    product.buttonText = "Shop now";
                  }
                }
              }
            }
          }

          let testvar = JSON.parse(JSON.stringify(this.sharedService.rendererOpportunityObject));

          for (let block of this.sharedService.rendererOpportunityObject.contentTemplate.blocks) {

            if (!block.props) {
              block.props = {};
            }

            if (this.getProperty(block, "Components")?.items) {
              for (let component of this.getProperty(block, "Components").items) {
                if (component.type == "IMAGE") {
                  if (!component.props) {
                    component.props = {
                      color: this.getArrayItem(block.properties, 'Button text color').value,
                      fontSize: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontSize,
                      fontWeight: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight,
                      textAlign: "center",
                      lineHeight: "1.5",
                      letterSpacing: "0",
                      paddingLeft: "40px",
                      paddingRight: "40px",
                      paddingTop: "14px",
                      paddingBottom: "14px",
                      backgroundColor: this.getArrayItem(block.properties, 'Button color').value,
                      fontFamily: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font,
                      justifyContent: "center",
                      backgroundMode: "solid"
                    }
                  }
                }

                if (component.type == "TEXT") {
                  if (!component.props) {
                    component.props = {
                      color: this.getArrayItem(block.properties, 'Title color').value,
                      fontSize: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px',
                      fontWeight: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight,
                      textAlign: "center",
                      lineHeight: "1.5",
                      letterSpacing: "0",
                      paddingLeft: "24px",
                      paddingRight: "24px",
                      paddingTop: "40px",
                      paddingBottom: "0px",
                      fontFamily: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font,
                    }
                  }
                }

                if (component.type == "BUTTON") {
                  if (!component.props) {
                    component.props = {
                      color: this.getArrayItem(block.properties, 'Button text color').value,
                      fontSize: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontSize,
                      fontWeight: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight,
                      textAlign: "center",
                      lineHeight: "1.5",
                      letterSpacing: "0",
                      paddingLeft: "40px",
                      paddingRight: "40px",
                      paddingTop: "14px",
                      paddingBottom: "14px",
                      backgroundColor: this.getArrayItem(block.properties, 'Button color').value,
                      fontFamily: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font,
                      justifyContent: "center",
                      backgroundMode: "solid"
                    }
                  }
                }
              }
            }

            if (block.type == "HERO") {
              let layout = this.getProperty(block, "Layout").value.value;
              console.log(layout);

              if (layout == "STANDARD" && block.rtlVersion != 2) {
                // let componentsObject = this.getProperty(block, "Components").items;
                // console.log(componentsObject);
                block.rtlVersion = 2;
                this.getProperty(block, "Components").items = [
                  {
                    name: "Title",
                    value: true,
                    content: "Title",
                    enum: "TITLE",
                    type: "TEXT",
                    props: {
                      color: this.getArrayItem(block.properties, 'Title color').value,
                      fontSize: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px',
                      fontWeight: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight,
                      textAlign: "center",
                      lineHeight: "1.5",
                      letterSpacing: "0",
                      paddingLeft: "24px",
                      paddingRight: "24px",
                      paddingTop: "40px",
                      paddingBottom: "0px",
                      fontFamily: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font,
                    }
                  },
                  {
                    name: "Subtitle",
                    value: true,
                    enum: "SUBTITLE",
                    content: "Subtitle",
                    type: "TEXT",
                    props: {
                      color: this.getArrayItem(block.properties, 'Subtitle color').value,
                      fontSize: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px',
                      fontWeight: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight,
                      textAlign: "center",
                      lineHeight: "1.5",
                      letterSpacing: "0",
                      paddingLeft: "24px",
                      paddingRight: "24px",
                      paddingTop: "16px",
                      paddingBottom: "40px",
                      fontFamily: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font,
                    }
                  },
                  {
                    name: "Button",
                    value: true,
                    enum: "BUTTON",
                    content: "Button",
                    type: "BUTTON",
                    props: {
                      color: this.getArrayItem(block.properties, 'Button text color').value,
                      fontSize: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontSize,
                      fontWeight: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight,
                      textAlign: "center",
                      lineHeight: "1.5",
                      letterSpacing: "0",
                      paddingLeft: "40px",
                      paddingRight: "40px",
                      paddingTop: "14px",
                      paddingBottom: "14px",
                      backgroundColor: this.getArrayItem(block.properties, 'Button color').value,
                      fontFamily: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font,
                      justifyContent: "center",
                      backgroundMode: "solid"
                    }
                  },
                  {
                    name: "Image",
                    value: true,
                    enum: "IMAGE",
                    type: "IMAGE",
                    content: "",
                    props: {
                      color: this.getArrayItem(block.properties, 'Button text color').value,
                      fontSize: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontSize,
                      fontWeight: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight,
                      textAlign: "center",
                      lineHeight: "1.5",
                      letterSpacing: "0",
                      paddingLeft: "40px",
                      paddingRight: "40px",
                      paddingTop: "14px",
                      paddingBottom: "14px",
                      backgroundColor: this.getArrayItem(block.properties, 'Button color').value,
                      fontFamily: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font,
                      justifyContent: "center",
                      backgroundMode: "solid"
                    }
                  },
                ];
              }
            }

            if (block.type == "PRODUCT") {
              let layout = this.getProperty(block, "Layout").value.value;
              console.log(layout);

              if (layout == "STANDARDLARGE") {
                // let componentsObject = this.getProperty(block, "Components").items;
                // console.log(componentsObject);
                block.rtlVersion = 2;
                this.getProperty(block, "Components").items = [
                  {
                    name: "Title",
                    value: true,
                    content: "Title",
                    enum: "TITLE",
                    type: "TEXT",
                    props: {
                      color: this.getArrayItem(block.properties, 'Title color').value,
                      fontSize: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px',
                      fontWeight: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight,
                      textAlign: "center",
                      lineHeight: "1.5",
                      letterSpacing: "0",
                      paddingLeft: "24px",
                      paddingRight: "24px",
                      paddingTop: "24px",
                      paddingBottom: "24px",
                      fontFamily: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font,
                    }
                  },
                  {
                    name: "Subtitle",
                    value: true,
                    enum: "SUBTITLE",
                    content: "Subtitle",
                    type: "TEXT",
                    props: {
                      color: this.getArrayItem(block.properties, 'Subtitle color').value,
                      fontSize: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px',
                      fontWeight: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight,
                      textAlign: "center",
                      lineHeight: "1.5",
                      letterSpacing: "0",
                      paddingLeft: "24px",
                      paddingRight: "24px",
                      paddingTop: "0px",
                      paddingBottom: "40px",
                      fontFamily: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font,
                    }
                  },
                  {
                    name: "Button",
                    value: true,
                    enum: "BUTTON",
                    content: "Button",
                    type: "BUTTON",
                    props: {
                      color: this.getArrayItem(block.properties, 'Button text color').value,
                      fontSize: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontSize,
                      fontWeight: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight,
                      textAlign: "center",
                      lineHeight: "1.5",
                      letterSpacing: "0",
                      paddingLeft: "40px",
                      paddingRight: "40px",
                      paddingTop: "14px",
                      paddingBottom: "14px",
                      backgroundColor: this.getArrayItem(block.properties, 'Button color').value,
                      fontFamily: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font,
                      justifyContent: "center",
                      backgroundMode: "solid"
                    }
                  },
                  {
                    name: "Product title",
                    value: true,
                    enum: "PRODUCTTITLE",
                    content: "Subtitle",
                    type: "TEXT",
                    props: {
                      color: this.getArrayItem(block.properties, 'Product title color').value,
                      fontSize: '20px',
                      fontWeight: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight,
                      textAlign: "center",
                      lineHeight: "1.5",
                      letterSpacing: "0",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      paddingTop: "0px",
                      paddingBottom: "16px",
                      fontFamily: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font,
                    }
                  },
                  {
                    name: "Product button",
                    value: true,
                    enum: "PRODUCTBUTTON",
                    content: "Button",
                    type: "BUTTON",
                    props: {
                      color: this.getArrayItem(block.properties, 'Button text color').value,
                      fontSize: "16px",
                      fontWeight: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight,
                      textAlign: "center",
                      lineHeight: "1.5",
                      letterSpacing: "0",
                      paddingLeft: "40px",
                      paddingRight: "40px",
                      paddingTop: "14px",
                      paddingBottom: "14px",
                      backgroundColor: this.getArrayItem(block.properties, 'Button color').value,
                      fontFamily: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font,
                      justifyContent: "center",
                      backgroundMode: "solid"
                    }
                  },
                  {
                    name: "Product description",
                    value: false,
                    enum: "PRODUCTDESCRIPTION",
                    content: "Subtitle",
                    type: "TEXT",
                    props: {
                      color: this.getArrayItem(block.properties, 'Subtitle color').value,
                      fontSize: "16px",
                      fontWeight: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight,
                      textAlign: "center",
                      lineHeight: "1.5",
                      letterSpacing: "0",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      paddingTop: "0px",
                      paddingBottom: "24px",
                      fontFamily: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font,
                    }
                  },
                  {
                    name: "Product price",
                    value: false,
                    enum: "PRODUCTPRICE",
                    content: "Subtitle",
                    type: "TEXT",
                    props: {
                      color: this.getArrayItem(block.properties, 'Subtitle color').value,
                      fontSize: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px',
                      fontWeight: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight,
                      textAlign: "center",
                      lineHeight: "1.5",
                      letterSpacing: "0",
                      paddingLeft: "24px",
                      paddingRight: "24px",
                      paddingTop: "16px",
                      paddingBottom: "40px",
                      fontFamily: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font,
                    }
                  },
                  {
                    name: "Product compare at price",
                    value: false,
                    enum: "PRODUCTCOMPAREATPRICE",
                    content: "Subtitle",
                    type: "TEXT",
                    props: {
                      color: this.getArrayItem(block.properties, 'Subtitle color').value,
                      fontSize: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px',
                      fontWeight: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight,
                      textAlign: "center",
                      lineHeight: "1.5",
                      letterSpacing: "0",
                      paddingLeft: "24px",
                      paddingRight: "24px",
                      paddingTop: "16px",
                      paddingBottom: "40px",
                      fontFamily: this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font,
                    }
                  }

                ];
              }
            }
          }

          console.log(testvar);
          // this.sharedService.rendererOpportunityObject = this.opportunityObject;
          this.loading = false;
          console.log(this.sharedService.rendererOpportunityObject);
          this.sharedService.loadingRenderer = false;
          // if (this.sharedService.currentUser.workspace._id == data.workspace) {
          //   this.opportunityObject = data;
          //   this.loading = false;
          // } else {
          //   this.router.navigate(['/']);
          // }

          // if (this.opportunityObject.stage == "NEW") {
          //   this.apiService.generateTemplateContent(this.opportunityId).subscribe(
          //     (data: any) => {
          //       console.log(data);
          //       this.opportunityObject = data;
          //       this.loading = false;
          //       this.fetchingSiteData = false;
          //       this.siteData = data;
          //     },
          //     error => {
          //       console.log(error);
          //     });
          // }
        },
        error => {
          console.log(error);
        });
    }

    this.apiService.getCurrentWorkspace().subscribe(
      (data: any) => {
        console.log(data);

        // this.workspace = data;
        this.sharedService.workspace = data;
        // this.loading = false;
      },
      error => {
        console.log(error);
      });
  }

  generateProposal() {
    this.opportunityObject.stage = "NEW";
    this.apiService.generateProposal(this.opportunityId).subscribe(
      (data: any) => {
        console.log(data);
        this.opportunityObject = data;
        this.loading = false;

      },
      error => {
        console.log(error);
      });
  }

  generateTemplateContent() {
    this.opportunityObject.stage = "NEW";
    this.fetchingSiteData = true;
    this.apiService.generateTemplateContent(this.opportunityId).subscribe(
      (data: any) => {
        console.log(data);
        this.opportunityObject = data;
        this.loading = false;
        this.fetchingSiteData = false;

      },
      error => {
        console.log(error);
      });
  }

  newMessage() {
    this.apiService.newMessage({
      body: this.newMessageBody,
      opportunity: this.opportunityId
    }).subscribe(
      (data: any) => {
        console.log(data);
      },
      error => {
        console.log(error);
      });
  }

  selectBlock(block: any) {
    console.log(this.mode);
    if (this.mode == "EDIT") {
      this.sharedService.activeComponent = null;
      this.activeBlock = block;
      this.sharedService.currentBlock = block;
    }
  }

  selectPrimaryColor(color: any) {
    this.primaryColor = color;
  }

  selectLogoImage(image: any) {
    this.logoImage = image;
  }

  saveTemplate() {
    this.apiService.saveTemplate(this.opportunityObject).subscribe(
      (data: any) => {
        console.log(data);
        this.router.navigate([`/opportunities/${this.opportunityObject._id}`]);
      },
      error => {
        console.log(error);
      });
  }

  getPropertyValue(block: any, propertyName: any) {
    let property = block.properties.filter((obj: any) => {
      return obj.name === propertyName;
    });

    if (property[0]) {
      return property[0].value;
    } else {
      return "";
    }
  }

  getProperty(block: any, propertyName: any) {
    let property = block.properties.filter((obj: any) => {
      return obj.name === propertyName;
    });

    return property[0];
  }

  drop(event: any) {
    console.log(event);
    moveItemInArray(this.opportunityObject.contentTemplate.blocks, event.previousIndex, event.currentIndex);
  };

  moveDown(block: any, index: any) {
    console.log(block, index);
    if (index != this.opportunityObject.contentTemplate.blocks.length - 1) {
      moveItemInArray(this.opportunityObject.contentTemplate.blocks, index, index + 1);
    }

    let el: any = document.getElementById('logo')
    el.scrollIntoView(true);
  }

  moveUp(block: any, index: any) {
    console.log(block, index);
    if (index != 0) {
      moveItemInArray(this.opportunityObject.contentTemplate.blocks, index, index - 1);
    }
    // let el: any = document.getElementById('logo')
    // el.scrollIntoView(true);
  }

  addBlock(block: any, oldBlock: any) {
    this.opportunityObject.contentTemplate.blocks.unshift(block);
    // oldBlock.showPalette = false;
    this.cdr.detectChanges();
    this.scroll.scrollToPosition([0, 0]);
  }

  removeItem(index: any) {
    this.opportunityObject.contentTemplate.blocks.splice(index, 1);
    this.message.create("success", `Block deleted`);
  }

  removeItemFromArray(index: any, list: Array<any>) {
    list.splice(index, 1);
  }

  openPicker(property: any, mode: any) {
    console.log("Open Picker");
    this.sharedService.currentProperty = property;
    this.sharedService.pickerMode = mode;
    this.sharedService.pickerOpen = true;
  }

  openBlockLibrary(index: any) {
    console.log("Open block library: ", index);
    this.sharedService.currentTemplate = this.opportunityObject.contentTemplate;
    this.sharedService.blockLibraryMode = "NEWBLOCK";
    this.sharedService.blockLibraryV2Open = true;
    this.sharedService.blockIndex = index;
  }

  openVariantLibrary(property: any) {
    this.sharedService.currentTemplate = this.opportunityObject.contentTemplate;
    this.sharedService.currentProperty = property;
    this.sharedService.currentBlock = this.activeBlock;
    this.sharedService.blockLibraryMode = "CHANGEVARIANT";
    this.sharedService.blockLibraryOpen = true;
  }

  pushDataListValue(property: any) {
    property.value.push(JSON.parse(JSON.stringify(property.dataTemplate)));
  }

  getArrayItem(array: any, propertyName: any) {
    let property = array.filter((obj: any) => {
      return obj.name === propertyName;
    });

    if (property[0]) {
      return property[0];
    } else {
      return "";
    }
  }

  nextVariant(property: any) {
    let index = property.options.findIndex((variant: any) => variant.value === property.value.value);

    if (index < property.options.length - 1) {
      property.value = property.options[index + 1];
    } else {
      property.value = property.options[0];
    }

    this.cdr.detectChanges();
  }

  previousVariant(property: any) {
    let index = property.options.findIndex((variant: any) => variant.value === property.value.value);

    if (index > 0) {
      property.value = property.options[index - 1];
    } else {
      property.value = property.options[property.options.length - 1];
    }

    this.cdr.detectChanges();
  }

  colorChosen(value: any) {
    console.log(value);

    if (value.length == 7) {
      // Check if color already in palette 
      let index = this.opportunityObject.contentTemplate.colorPalette.findIndex((color: any) => color.toLowerCase() == value.toLowerCase());
      console.log(index);

      if (index == -1) {
        this.opportunityObject.contentTemplate.colorPalette.push(value);
      }
    }
  }

  isEven(n: any) {
    return n % 2 == 0;
  }

  generateImage(id: any) {
    var node: any = document.getElementById(id);
    htmlToImage.toPng(node)
      .then(function (dataUrl) {
        var img = new Image();
        console.log(img);
        img.src = dataUrl;
        return img;
        // document.body.appendChild(img);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  generateEmailImages() {
    let imageArray: any = [];

    for (let block in this.opportunityObject.contentTemplate.blocks) {
      let currentBlock = this.opportunityObject.contentTemplate.blocks[block];

      var node: any = document.getElementById(`block-${block}`);
      htmlToImage.toPng(node)
        .then(function (dataUrl) {
          var img = new Image();
          console.log(img);
          img.src = dataUrl;
          imageArray.push(img);
          // document.body.appendChild(img);
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        });
    }
    console.log(imageArray);
  }

  compareFn = (o1: any, o2: any): boolean => (o1 && o2 ? o1 === o2 : o1 === o2);

  compareFn2 = (o1: any, o2: any): boolean => (o1 && o2 ? o1.title === o2.title : o1 === o2);

  refreshBlock(index: any, blockFeedback: any, block: any) {
    if (!this.newRefreshContentBlock) {
      this.newRefreshContentBlock = this.opportunityObject.contentTemplate.blocks[this.refreshBlockIndex];
    }
    this.blockRefreshLoading = true;
    // this.opportunityObject.contentTemplate.blocks[this.refreshBlockIndex].popoverVisible = false;
    // this.opportunityObject.contentTemplate.blocks[this.refreshBlockIndex].loading = true;
    // const id = this.message.loading('Generating content...', { nzDuration: 0 }).messageId;
    // this.sharedService.aiModalLoading = true;
    // this.sharedService.aiModalMessage = "Generating content...";
    this.apiService.generateTemplateContent2(this.opportunityId, "BLOCKFEEDBACK", blockFeedback, this.newRefreshContentBlock).subscribe(
      (data2: any) => {
        this.sharedService.loadingRenderer = false;
        this.loading = false;
        // this.opportunityObject.contentTemplate.blocks[this.refreshBlockIndex] = data2.result;
        // this.opportunityObject.contentTemplate.blocks[this.refreshBlockIndex].loading = false
        console.log(data2);
        this.newRefreshContentBlock = data2.result;
        this.blockRefreshLoading = false;
        this.showRefreshPreview = true;
        // this.message.remove(id);
        // this.sharedService.aiModalLoading = false;
        // this.message.create("success", `Content updated`);
      });
    this.blockFeedback = "";
  }



  onEditorCreated(quill: any, field: any, mode: any = "CENTER", firstOnly: any = true, fontType: any = "") {

    // if (!this.loaded) {
    //   this.loaded = true;
    // }

    if (fontType) {
      var fontObject: any = this.getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, fontType);
    } else {
      var fontObject: any = {};
    }

    if (!fontObject.lineHeight) {
      fontObject.lineHeight = 1.25;
    } else {
      fontObject.lineHeight = fontObject.lineHeight + "px";
    }

    quill.root.style.lineHeight = fontObject.lineHeight;

    if (mode == "CENTER") {
      if (firstOnly) {
        if (field.charAt(0) != '<') {
          // field = `<div>${field}</div>`;
          console.log("Field: ", field);
          // field = field.replace(/\n/g, '');
          // quill.clipboard.dangerouslyPasteHTML(modifiedText);
          // quill.setText(field);
          // this.setQuillContentWithLineBreaks(field, quill);
          quill.formatText(0, quill.getLength(), {
            'align': 'center' // Aligns text to the center
          });
          console.log("Quill: ", quill);
        }
      } else {
        quill.format('align', 'center');
      }
    }

    if (mode == "RIGHT") {
      if (firstOnly) {
        if (field.charAt(0) != '<') {
          // field = `<div>${field}</div>`;
          console.log("Field: ", field);
          // field = field.replace(/\n/g, '');
          // quill.clipboard.dangerouslyPasteHTML(modifiedText);
          // quill.setText(field);
          // this.setQuillContentWithLineBreaks(field, quill);
          quill.formatText(0, quill.getLength(), 'align', 'right');
          console.log("Quill: ", quill);
        }
      } else {
        quill.format('align', 'right');
      }
    }

    if (mode == "LEFT") {
      console.log(firstOnly);
      if (firstOnly) {
        if (field.charAt(0) != '<') {
          quill.format('align', 'left');
        }
      } else {
        console.log("align left");
        console.log(field);
        quill.formatText(0, quill.getLength(), 'align', 'left');
      }
    }

    setTimeout(() => quill.blur(), 0);
  }


  setQuillContentWithLineBreaks(text: any, quillEditor: any) {
    // Split text into lines
    const lines = text.split('\n');
    quillEditor.setText("");


    // Insert each line with a <br> to handle line breaks
    lines.forEach((line: any, index: any) => {
      quillEditor.insertText(quillEditor.getLength(), line);

      // Insert <br> after each line, except for the last line
      if (index < lines.length - 1) {
        quillEditor.insertEmbed(quillEditor.getLength(), 'break', true);
      }
    });
  }

  duplicateBlock(index: any) {
    let block = JSON.parse(JSON.stringify(this.opportunityObject.contentTemplate.blocks[index]));
    this.opportunityObject.contentTemplate.blocks.splice(index + 1, 0, block);
    this.cdr.detectChanges();
    this.message.create("success", `Block duplicated`);
  }

  setRefreshBlockDetails(index: any, block: any) {
    this.refreshBlockIndex = index;
    this.refreshBlockObject = block;
    block.controlsVisible = true;
  }

  keepNewContent(index: any, block: any) {
    this.opportunityObject.contentTemplate.blocks[index] = this.newRefreshContentBlock;
    this.message.create("success", `Content updated`);
    this.opportunityObject.contentTemplate.blocks[index].popoverVisible = false;
    block.controlsVisible = false;
    this.newRefreshContentBlock = null;
    this.showRefreshPreview = false;
  }

  popoverChange(event: any, block: any) {
    // console.log(event);
    if (event == false) {
      block.controlsVisible = false;
      this.newRefreshContentBlock = null;
      this.showRefreshPreview = false;
    }
  }

  createSavedBlock(block: any) {
    this.savingBlock = true;
    this.apiService.createSavedBlock(block, this.sharedService.rendererOpportunityObject._id).subscribe(
      (data2: any) => {
        console.log(data2);
        this.message.create("success", `Block saved`);
      },
      error => {
        console.log(error);
      });
  }

  handleModelChange(index: number, property: any): void {
    if (index == 0) {
      property.props.backgroundMode = "solid";
    }

    if (index == 1) {
      property.props.backgroundMode = "image";
    }
  };
}

