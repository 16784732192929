import { Component } from '@angular/core';

@Component({
  selector: 'app-staff-accounts',
  templateUrl: './staff-accounts.component.html',
  styleUrls: ['./staff-accounts.component.scss']
})
export class StaffAccountsComponent {

}
