<div class="page-wrapper">
    <div class="content-wrapper">
        <ng-container *ngIf="this.loading">
            <div class="custom-card" style="max-width: 800px; margin: auto; margin-top: 60px;">
                <div class="custom-card-body">
                    <nz-skeleton [nzParagraph]="{ rows: 2 }" [nzActive]="true"></nz-skeleton>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!this.loading">

            <ng-container *ngIf="this.noCampaigns">
                <ng-container *ngIf="!this.generatingCampaigns">
                    <nz-card style="text-align: center; padding: 8px; margin-top: 8px">
                        <h3>Get started with Copilot email campaigns</h3>
                        <p>Copilot will create a custom content calendar for your brand, all you do is approve!</p>
                        <br>
                        <button nz-button nzType="primary" routerLink="/signup/2">Get started</button>
                    </nz-card>
                </ng-container>

                <ng-container *ngIf="this.generatingCampaigns">
                    <nz-card style="text-align: center; padding: 8px; margin-top: 8px">
                        <h3>Get started with Copilot email campaigns</h3>
                        <p>Copilot will create a custom content calendar for your brand, all you do is approve!</p>
                        <br>
                        <button nz-button nzType="primary" routerLink="/signup/2">Get started</button>
                    </nz-card>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="!this.noCampaigns">
                <div class="fullscreen-header">
                    <nz-page-header nzTitle="Email campaigns">
                        <nz-page-header-extra>
                            <!-- <nz-select style="width: 90px; ">
                                <nz-option nzLabel="2023"></nz-option>
                            </nz-select> -->
                            <nz-select style="width: 200px; margin-left: 8px;">
                                <nz-option nzLabel="June"></nz-option>
                            </nz-select>
                        </nz-page-header-extra>
                    </nz-page-header>

                </div>
                <div class="left-column">

                    <div class="left-column-content">
                        <nz-timeline>
                            <ng-container *ngFor="let item of emailCampaigns">
                                <nz-timeline-item>
                                    <!-- <div class="custom-card">
                                        <div class="custom-card-body">
                                            <div class="timeline-item-date"><span nz-icon nzType="clock-circle" nzTheme="outline"></span>&nbsp;{{item.sendTime | date:'EEEE M/d/YY h:mm a'}}</div>
                                            <div class="timeline-item-title">{{item.title}}</div> 
                                        </div>
                                    </div> -->
                                    <div class="custom-card hover">
                                        <div class="custom-card-header small">{{item.sendTime |
                                            date:'EEEE
                                            M/d/YY h:mm a'}}</div>
                                        <div class="custom-card-body small">
                                            <div>{{item.title}}</div>
                                            <!-- <div style="margin-top: 8px;">{{item.description}}</div> -->
                                        </div>
                                        <!-- <div class="custom-card-footer grey">
                                            <button nz-button nzType="primary"
                                                [routerLink]="['/opportunities',item._id]">Explore</button>
                                        </div> -->
                                    </div>
                                </nz-timeline-item>
                            </ng-container>

                        </nz-timeline>
                    </div>
                </div>
                <div class="right-column">

                    <div class="calendar-block header">
                        <h3>Sunday</h3>
                    </div>
                    <div class="calendar-block header">
                        <h3>Monday</h3>
                    </div>
                    <div class="calendar-block header">
                        <h3>Tuesday</h3>
                    </div>
                    <div class="calendar-block header">
                        <h3>Wednesday</h3>
                    </div>
                    <div class="calendar-block header">
                        <h3>Thursday</h3>
                    </div>
                    <div class="calendar-block header">
                        <h3>Friday</h3>
                    </div>
                    <div class="calendar-block header">
                        <h3>Saturday</h3>
                    </div>
                    <ng-container *ngFor="let day of this.opportunities">
                        <div class="calendar-block">
                            <ng-container *ngIf="day.filler">
                                <p class="height-100">-</p>
                            </ng-container>
                            <ng-container *ngIf="!day.filler">
                                <p class="height-100">{{day.dayOfMonth}}</p>

                                <ng-container *ngIf="day.items.length > 0">
                                    <div class="calendar-item">
                                        <!-- <div class="calendar-item-title">{{day.items[0].title}}</div> -->
                                        <div class="calendar-item-time">
                                            <nz-tag>Draft</nz-tag>{{day.items[0].sendTime |
                                            date:'shortTime'}}
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>

            </ng-container>

        </ng-container>


    </div>
</div>


<!-- <div class="page-wrapper">
    <div class="left-column">
        <div class="left-column-content">
            <nz-page-header class="site-page-header" nzTitle="New">
                <nz-breadcrumb nz-page-header-breadcrumb>
                    <nz-breadcrumb-item>Opportunities</nz-breadcrumb-item>
                    <nz-breadcrumb-item>New</nz-breadcrumb-item>
                </nz-breadcrumb>
            </nz-page-header>
            <ng-container *ngFor="let opportunity of opportunities">
                <nz-card nzHoverable>{{opportunity.title}}</nz-card>
            </ng-container>
        </div>
    </div>
    <div class="right-column">

    </div>
</div> -->