<div class="custom-modal-header">
    <div class="custom-modal-title">New template</div>
    <div class="custom-modal-close" (click)="close()"><span nz-icon nzType="close" nzTheme="outline"></span></div>
</div>
<div class="custom-modal-body" style="overflow:hidden;">
    <nz-form-item>
        <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
            Template name
        </nz-form-label>
        <nz-form-control [nzSpan]="24">
            <input placeholder="Ex: Weekly roundup" nz-input [(ngModel)]="this.templateName" />
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
            Instructions
        </nz-form-label>
        <nz-form-control [nzSpan]="24">
            <textarea placeholder="Ex: Always include a listicle block with the weekly roundup items" nz-input
                [(ngModel)]="this.templateInstructions"></textarea>
        </nz-form-control>
    </nz-form-item>

</div>
<div class="custom-modal-footer">
    <button nz-button nzType="primary" (click)="createTemplate()" [nzLoading]="this.saving">Create</button>
</div>