<div class="page-wrapper">
    <div class="content-wrapper">
        <nz-page-header style="padding: 0px !important; margin-bottom: 16px;">
            <nz-page-header-title>Integrations</nz-page-header-title>
            <nz-page-header-extra>
                <button nzType="primary" nz-button nz-dropdown [nzDropdownMenu]="menu"
                    [nzPlacement]="'bottomRight'"><span nz-icon nzType="plus" nzTheme="outline"></span> New
                    integration</button>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item (click)="createIntegration(shopifyObject)">Shopify</li>
                        <li nz-menu-item (click)="createIntegration(klaviyoObject)">Klaviyo</li>
                        <li nz-menu-item (click)="createIntegration(metaObject)">Meta</li>
                    </ul>
                </nz-dropdown-menu>
            </nz-page-header-extra>
        </nz-page-header>
        <div class="custom-card">
            <div class="custom-card-body">


                <div class="header-row">
                    <div nz-row [nzGutter]="16">
                        <div nz-col class="gutter-row" [nzSpan]="16">
                            <div class="inner-box">Title</div>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="4">
                            <div class="inner-box">Updated</div>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="4">
                            <div class="inner-box">Created</div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="this.loading">
                    <div class="loading-wrapper">
                        <div class="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!loading">

                    <ng-container *ngFor="let integration of integrations; index as index">
                        <div nz-row [nzGutter]="16" class="table-row"
                            [routerLink]="['/settings/integrations',integration._id]">
                            <div nz-col class="gutter-row" [nzSpan]="16" style="color: #374151; font-weight: 500;">
                                {{integration.type | titlecase}}
                            </div>
                            <div nz-col class="gutter-row" [nzSpan]="4">
                                {{integration.updatedAt | date}}
                            </div>
                            <div nz-col class="gutter-row" [nzSpan]="4">
                                {{integration.createdAt | date}}
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>