import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from '../api.service';
import { SharedService } from '../shared.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { fabric } from 'fabric';

@Component({
  selector: 'app-edit-image',
  templateUrl: './edit-image.component.html',
  styleUrls: ['./edit-image.component.scss']
})
export class EditImageComponent {

  opportunities: any = [];
  loading = false;
  loadingNewEmail = false;
  emailType: any = {}

  prompt: any = "";

  options = true;

  email: any = {};

  emailTypes: any = [];

  promotion = false;
  promotionValue = "";

  sourceUrl = false;

  products = false;

  // emailTypes = [
  //   {
  //     name: "Featured product",
  //     value: "FEATUREDPRODUCT",
  //     properties: [
  //       {
  //         type: "PRODUCTLIST",
  //         name: "Product",
  //         value: []
  //       }
  //     ]
  //   },
  //   {
  //     name: "Featured category",
  //     value: "FEATUREDCATEGORY",
  //     properties: [
  //       {
  //         type: "INPUT",
  //         name: "Category",
  //         value: null
  //       }
  //     ]
  //   },
  //   {
  //     name: "Listicle",
  //     value: "LISTICLE",
  //     properties: [{
  //       type: "INPUT",
  //       name: "Source article URL",
  //       value: null
  //     }]
  //   }
  // ]

  emailObject: any = {
    sendTime: null,
    template: null,
    prompt: "",
    templateOptions: {
      properties: []
    },
    type: {
      properties: []
    },
    properties: [
      {
        type: "PRODUCTLIST",
        name: "Product",
        value: []
      }
    ],
    stage: "NEW"
  }

  PRODUCTFEATUREOPTIONS = [
    {
      type: "PRODUCTLIST",
      name: "Product",
      value: []
    }
  ];

  CATEGORYFEATUREOPTIONS = [
    {
      type: "INPUT",
      name: "Category",
      value: null
    }
  ];

  LISTICLEOPTIONS = [
    {
      type: "INPUT",
      name: "Source article URL",
      value: null
    }
  ];
  loadingChat = false;
  chatLog: any = [];
  imageChangedEvent: any = '';
  croppedImage: any = '';
  currentObj: any = {};

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public sharedService: SharedService,
    public notification: NzNotificationService,
    private message: NzMessageService,
    private sanitizer: DomSanitizer,
  ) { }

  async ngOnInit() {
    this.apiService.getSuggestionOpportunities().subscribe(
      (data: any) => {
        console.log(data);
        this.opportunities = data;
        this.loading = false;
      },
      error => {
        console.log(error);
      });

    this.apiService.getEmailTypes().subscribe(
      (data: any) => {
        console.log(data);
        this.emailTypes = data;
        this.emailObject.templateOptions = this.emailTypes[0];
      },
      error => {
        console.log(error);
      });

    // let base64Data = await this.urlToBase64(this.sharedService.editImageCurrentImage.value);
    // console.log(base64Data);
  }

  newEmail() {
    this.loadingNewEmail = true;
    this.apiService.newEmail(this.prompt, this.sharedService.newDate).subscribe(
      (data: any) => {
        console.log(data);
        this.email = data;
        this.options = true;
        this.loadingNewEmail = false;
      }
    )
  }

  generateEmail() {
    this.emailObject.sendTime = this.sharedService.newDate;
    this.emailObject.template = this.emailObject.templateOptions.value;

    if (this.promotion) {
      this.emailObject.templateOptions.properties.push({
        type: "RICHTEXT",
        name: "Promotion",
        value: this.promotionValue
      });
    }

    this.apiService.saveOpportunity(this.emailObject).subscribe(
      (data: any) => {
        console.log(data);
        // this.apiService.generateTemplateContent(data._id).subscribe(
        this.apiService.generateTemplateContent2(data._id, "NEW", "").subscribe(
          (data2: any) => {
            console.log(data2);
            this.sharedService.fetchCalendar();
            this.sharedService.newOpen = false;
            // this.sharedService.checkForGeneratingEmails();
            this.message.create("success", `Your email is being generated`);

            // this.notification.create(
            //   'success',
            //   'Your email is being generated',
            //   ''
            // );
          },
          error => {
            console.log(error);
          });
      },
      error => {
        console.log(error);
      });
  }

  sendOpenAiChat() {
    this.loadingChat = true;
    this.apiService.sendOpenAiChat(this.emailObject.prompt).subscribe(
      (data2: any) => {
        console.log(data2);
        this.chatLog.push({ message: JSON.parse(data2.data[0].content[0].text.value).message, sender: "RIPPLE" });
        this.loadingChat = false;
      },
      error => {
        console.log(error);
      });
  }

  openPicker(property: any, mode: any) {
    console.log("Open Picker");
    this.sharedService.currentProperty = property;
    this.sharedService.pickerMode = mode;
    this.sharedService.pickerOpen = true;
  }

  removeItemFromArray(index: any, list: Array<any>) {
    list.splice(index, 1);
  }

  colorChosen(value: any) {
    console.log(value);

    // if (value.length == 7) {
    //   // Check if color already in palette 
    //   let index = this.opportunityObject.contentTemplate.colorPalette.findIndex((color: any) => color == value);
    //   console.log(index);

    //   if (index == -1) {
    //     this.opportunityObject.contentTemplate.colorPalette.push(value);
    //   }
    // }
  }

  generateSuggestion(suggestion: any) {
    this.prompt = suggestion.prompt;
    this.newEmail();
  }

  compareFn = (o1: any, o2: any): boolean => (o1 && o2 ? o1.value === o2.value : o1 === o2);

  close() {
    this.sharedService.editImageOpen = false;
  }

  sendInvite() {

  }


  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    let objUrl: any = event.objectUrl;
    this.currentObj = event;
    console.log(this.currentObj);
    // this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(objUrl);
    this.croppedImage = event.base64;
    console.log(this.croppedImage);
    // event.blob can be used to upload the cropped image
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  uploadImage() {
    // console.log(base64FileArray);
    this.loading = true;
    this.apiService.uploadImages([this.currentObj.base64], "crop").subscribe((value: any) => {
      console.log(value);
      this.sharedService.editImageCurrentImage.value = value.file.url;
      if (this.sharedService.editImageCurrentImage.imageUrl) {
        this.sharedService.editImageCurrentImage.imageUrl = value.file.url;
      }
      this.sharedService.editImageOpen = false;
      this.message.create("success", `Image updated`);
    });
  }

  // Function to fetch content from a URL and encode it to Base64
  async urlToBase64(url: any) {
    try {
      // Fetch the content of the URL
      const response = await fetch(url);
      const data = await response.blob();
      console.log(response);

      // Convert the fetched data to a Base64 string
      const reader = new FileReader();
      reader.readAsDataURL(data);
      return new Promise((resolve, reject) => {
        reader.onloadend = () => {
          let itemToSplit: any = reader.result;
          const base64Data = itemToSplit.split(',')[1];
          resolve(base64Data);
        };
        reader.onerror = reject;
      });
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  }
}
