import { NgModule } from '@angular/core';
import { NavigationEnd, Router, RouterModule, Routes } from '@angular/router';
import { AboutScrapeComponent } from './account-creation/about-scrape/about-scrape.component';
import { AccountCreationComponent } from './account-creation/account-creation.component';
import { CompletingSetupComponent } from './account-creation/completing-setup/completing-setup.component';
import { ConfirmDetailsComponent } from './account-creation/confirm-details/confirm-details.component';
import { IntroComponent } from './account-creation/intro/intro.component';
import { KlaviyoSetupComponent } from './account-creation/klaviyo-setup/klaviyo-setup.component';
import { SelectColorsComponent } from './account-creation/select-colors/select-colors.component';
import { SelectLogoComponent } from './account-creation/select-logo/select-logo.component';
import { CalendarPlanningComponent } from './calendar-planning/calendar-planning.component';
import { CalendarComponent } from './calendar/calendar.component';
import { createOpportunityComponent } from './create-opportunity/create-opportunity.component';
import { EmailCampaignsComponent } from './email-campaigns/email-campaignscomponent';
import { EmailEditorComponent } from './email-editor/email-editor.component';
import { AuthGuard } from './guards/auth.guard';
import { ConnectionViewComponent } from './home/connection-view/connection-view.component';
import { ConnectionsComponent } from './home/connections/connections.component';
import { DatabaseComponent } from './home/database/database.component';
import { HomeComponent } from './home/home.component';
import { IntegrationViewComponent } from './home/integration-view/integration-view.component';
import { NewConnectionComponent } from './home/new-connection/new-connection.component';
import { SettingsBrandComponent } from './home/settings-brand/settings-brand.component';
import { SettingsIntegtationsComponent } from './home/settings-integrations/settings-integrations.component';
import { SettingsVoiceComponent } from './home/settings-voice/settings-voice.component';
import { SettingsComponent } from './home/settings/settings.component';
import { LoggedInComponent } from './logged-in/logged-in.component';
import { LoginComponent } from './login/login.component';
import { OpportunitiesAllComponent } from './opportunities-all/opportunities-all.component';
import { OpportunitiesLiveComponent } from './opportunities-live/opportunities-live.component';
import { OpportunitiesNewComponent } from './opportunities-new/opportunities-new.component';
import { OpportunitiesPendingComponent } from './opportunities-pending/opportunities-pending.component';
import { OpportunityViewComponent } from './opportunity-view/opportunity-view.component';
import { RegisterComponent } from './register/register.component';
import { SegmentsComponent } from './segments/segments.component';
import { SmsComponent } from './sms/sms.component';
import { StaffAccountsComponent } from './staff/staff-accounts/staff-accounts.component';
import { StaffInboxComponent } from './staff/staff-inbox/staff-inbox.component';
import { StaffOpportunitiesComponent } from './staff/staff-opportunities/staff-opportunities.component';
import { WorkspaceComponent } from './workspace/workspace.component';
import { SettingsAccountComponent } from './home/settings-account/settings-account.component';
import { SettingsTeamComponent } from './home/settings-team/settings-team.component';
import posthog from 'posthog-js';
import { ObjectivesComponent } from './objectives/objectives.component';
import { EmailRendererComponent } from './email-renderer/email-renderer.component';
import { EmailFlowsComponent } from './email-flows/email-flows.component';
import { FlowViewComponent } from './flow-view/flow-view.component';
import { CreateComponent } from './create/create.component';
import { CreateFlowComponent } from './create-flow/create-flow.component';
import { ContentSettingsComponent } from './home/content/content.component';
import { ContentTemplatesComponent } from './content-templates/content-templates.component';
import { AcceptWorkspaceInviteComponent } from './accept-workspace-invite/accept-workspace-invite.component';
import { CreateWorkspaceComponent } from './account-creation/create-workspace/create-workspace.component';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { ContentProductsComponent } from './content-products/content-products.component';
import { ProductViewComponent } from './home/product-view/product-view.component';
import { ContentMediaComponent } from './content-media/content-media.component';
import { ContentTemplatesViewComponent } from './content-templates-view/content-templates-view.component';
import { ContentBlocksComponent } from './content-blocks/content-blocks.component';
import { SMSViewComponent } from './sms-view/sms-view.component';
import { CreateSMSComponent } from './create-sms/create-sms.component';
import { SocialPostViewComponent } from './social-post-view/social-post-view.component';
import { CreateFromTemplateComponent } from './create-from-template/create-from-template.component';
import { BlockBuilderComponent } from './block-builder/block-builder.component';
import { ContentEditorComponent } from './content-editor/content-editor.component';
import { TemplateLibraryComponent } from './template-library/template-library.component';
import { FillTemplateComponent } from './fill-template/fill-template.component';
import { EmailEditorV2Component } from './email-editor-v2/email-editor-v2.component';
import { createOpportunityV2Component } from './create-opportunity-v2/create-opportunity-v2.component';
import { HomeV2Component } from './home-v2/home-v2.component';
import { EmailEditorV3Component } from './email-editor-v3/email-editor-v3.component';
import { EmailRendererV3Component } from './email-renderer-v3/email-renderer-v3.component';
import { createOpportunityV3Component } from './create-opportunity-v3/create-opportunity-v3.component';

const routes: Routes = [
  { path: 'register', component: RegisterComponent },
  { path: 'login', component: LoginComponent },
  { path: 'new-connection', component: NewConnectionComponent },
  { path: 'export-v3/:id', component: EmailRendererV3Component },
  { path: 'export/:id', component: EmailRendererComponent },
  { path: 'editor/:id', component: EmailEditorComponent, canActivate: [AuthGuard] },
  { path: 'editor-v3/:id', component: EmailEditorV3Component, canActivate: [AuthGuard] },
  { path: 'block-builder', component: BlockBuilderComponent, canActivate: [AuthGuard] },
  { path: 'editor/:id/:mode', component: EmailEditorComponent, canActivate: [AuthGuard] },
  { path: 'editor-v3/:id/:mode', component: EmailEditorV3Component, canActivate: [AuthGuard] },
  { path: 'new-editor/:id/:mode', component: EmailEditorV2Component, canActivate: [AuthGuard] },
  // { path: 'assistant/calendar', component: CalendarPlanningComponent, canActivate: [AuthGuard] },
  { path: 'opportunities/:id', canActivate: [AuthGuard], component: OpportunityViewComponent },
  { path: 'invite/:id', component: AcceptWorkspaceInviteComponent },
  { path: 'opportunities/:id/:mode', canActivate: [AuthGuard], component: OpportunityViewComponent },
  { path: 'sms/:id', canActivate: [AuthGuard], component: SMSViewComponent },
  { path: 'post/:id', canActivate: [AuthGuard], component: SocialPostViewComponent },
  { path: 'create/email', component: createOpportunityComponent, canActivate: [AuthGuard] },
  { path: 'create/email-test', component: CreateFromTemplateComponent, canActivate: [AuthGuard] },
  { path: 'create/email-template', component: createOpportunityV2Component, canActivate: [AuthGuard] },
  { path: 'create/email-v3', component: createOpportunityV3Component, canActivate: [AuthGuard] },
  { path: 'create/sms', component: CreateSMSComponent, canActivate: [AuthGuard] },
  { path: 'create/flow', component: createOpportunityComponent, canActivate: [AuthGuard] },
  { path: 'create/flow-template', component: createOpportunityV2Component, canActivate: [AuthGuard] },
  { path: 'create/social', component: createOpportunityComponent, canActivate: [AuthGuard] },
  { path: 'create/chat', component: createOpportunityComponent, canActivate: [AuthGuard] },
  { path: 'create-workspace', component: CreateWorkspaceComponent, canActivate: [AuthGuard] },
  { path: 'content-editor', component: ContentEditorComponent, canActivate: [AuthGuard] },
  { path: 'content-editor/:id', component: ContentEditorComponent },
  { path: 'template-library', component: TemplateLibraryComponent, canActivate: [AuthGuard] },
  { path: 'create/template/:id', component: FillTemplateComponent, canActivate: [AuthGuard] },
  // { path: 'create/flow', component: CreateFlowComponent, canActivate: [AuthGuard] },
  { path: 'create', component: CreateComponent, canActivate: [AuthGuard] },
  {
    path: 'new', redirectTo: '/create/email', pathMatch: 'full'
  },
  {
    path: 'staff', component: AccountCreationComponent, canActivate: [AuthGuard], children: [
      // { path: 'home', component: DashboardComponent},
      { path: 'inbox', canActivate: [AuthGuard], component: StaffInboxComponent },
      { path: 'opportunites', canActivate: [AuthGuard], component: StaffOpportunitiesComponent },
      { path: 'accounts', canActivate: [AuthGuard], component: StaffAccountsComponent },
    ]
  },
  {
    path: 'signup', component: AccountCreationComponent, children: [
      // { path: 'home', component: DashboardComponent},
      { path: '1', component: ConfirmDetailsComponent },
      { path: '2/:id', canActivate: [AuthGuard], component: IntroComponent },
      { path: '3', canActivate: [AuthGuard], component: KlaviyoSetupComponent },
      { path: '4', canActivate: [AuthGuard], component: SelectLogoComponent },
      { path: '5', canActivate: [AuthGuard], component: CompletingSetupComponent },
      { path: '6', canActivate: [AuthGuard], component: SelectColorsComponent },
      { path: '7', canActivate: [AuthGuard], component: AboutScrapeComponent },
    ]
  },
  // {
  //   path: '', component: LoggedInComponent, canActivate: [AuthGuard], children: [
  //     {
  //       path: ':workspace', component: WorkspaceComponent, canActivate: [AuthGuard], children: [
  //         {
  //           path: '', component: HomeComponent, canActivate: [AuthGuard], children: [
  //             // { path: 'home', component: DashboardComponent},
  //             { path: '', redirectTo: 'calendar', pathMatch: 'full' },
  //             { path: 'calendar', canActivate: [AuthGuard], component: CalendarComponent },
  //           ]
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    path: '', component: HomeComponent, canActivate: [AuthGuard], children: [
      // { path: 'home', component: DashboardComponent},
      { path: '', redirectTo: 'email', pathMatch: 'full' },
      { path: 'opportunities/new', canActivate: [AuthGuard], component: OpportunitiesNewComponent },
      { path: 'opportunities/pending', canActivate: [AuthGuard], component: OpportunitiesPendingComponent },
      { path: 'opportunities/live', canActivate: [AuthGuard], component: OpportunitiesLiveComponent },
      { path: 'home', canActivate: [AuthGuard], component: HomeV2Component },
      // { path: 'chat', canActivate: [AuthGuard], component: ChatbotComponent },
      { path: 'email', canActivate: [AuthGuard], component: OpportunitiesAllComponent },
      { path: 'flows', canActivate: [AuthGuard], component: EmailFlowsComponent },
      { path: 'assistant', canActivate: [AuthGuard], component: CalendarPlanningComponent },
      { path: 'sms', canActivate: [AuthGuard], component: SmsComponent },
      { path: 'segments', canActivate: [AuthGuard], component: SegmentsComponent },
      { path: 'connections', canActivate: [AuthGuard], component: ConnectionsComponent },
      { path: 'connection/:id', canActivate: [AuthGuard], component: ConnectionViewComponent },
      { path: 'flows/:id', canActivate: [AuthGuard], component: FlowViewComponent },
      { path: 'content/products', component: ContentProductsComponent, canActivate: [AuthGuard] },
      { path: 'content/products/:id', component: ProductViewComponent, canActivate: [AuthGuard] },
      { path: 'content/media', component: ContentMediaComponent, canActivate: [AuthGuard] },
      { path: 'content/templates', component: ContentTemplatesViewComponent, canActivate: [AuthGuard] },
      { path: 'content/blocks', component: ContentBlocksComponent, canActivate: [AuthGuard] },
      {
        path: 'settings', canActivate: [AuthGuard], component: SettingsComponent, children: [
          { path: '', redirectTo: 'account', pathMatch: 'full' },
          { path: 'styles', canActivate: [AuthGuard], component: SettingsBrandComponent },
          { path: 'account', canActivate: [AuthGuard], component: SettingsAccountComponent },
          { path: 'voice', canActivate: [AuthGuard], component: SettingsVoiceComponent },
          { path: 'integrations', canActivate: [AuthGuard], component: SettingsIntegtationsComponent },
          { path: 'integrations/:id', canActivate: [AuthGuard], component: IntegrationViewComponent },
          { path: 'team', canActivate: [AuthGuard], component: SettingsTeamComponent },
          { path: 'blocks', canActivate: [AuthGuard], component: ContentTemplatesComponent }
        ]
      },
      {
        path: 'content', canActivate: [AuthGuard], component: ContentSettingsComponent, children: [
          { path: '', redirectTo: 'templates', pathMatch: 'full' },
          { path: 'templates', canActivate: [AuthGuard], component: ContentTemplatesComponent },
          { path: 'blocks', canActivate: [AuthGuard], component: SettingsAccountComponent }
        ]
      },
      { path: 'database', canActivate: [AuthGuard], component: DatabaseComponent },
      { path: 'calendar', canActivate: [AuthGuard], component: CalendarComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        posthog.capture('$pageview');
      }
    });
  }
}
