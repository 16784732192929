import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ApiService } from 'src/app/api.service';
import { SharedService } from 'src/app/shared.service';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-integration-view',
  templateUrl: './integration-view.component.html',
  styleUrls: ['./integration-view.component.scss']
})
export class IntegrationViewComponent {

  loading = true;
  saving = false;
  workspace: any = null;

  startingSync: any = false;

  klaviyoObject: any = {
    accessToken: '',
    type: "KLAVIYO"
  };

  shopifyObject: any = {
    accessToken: '',
    type: "KLAVIYO"
  };

  navbarLinksTemplate = {
    name: "Navbar links",
    type: "DATALIST",
    category: "CONTENT",
    value: [
    ],
    dataTemplate: [
      {
        name: "Title",
        type: "INPUT",
        category: "CONTENT",
        value: "",
      },
      {
        name: "Link",
        type: "INPUT",
        category: "CONTENT",
        value: "",
      },
    ]
  };

  footerLinksTemplate = {
    name: "Social links",
    type: "DATALIST",
    category: "CONTENT",
    value: [
    ],
    dataTemplate: [
      {
        name: "Platform",
        type: "SELECT",
        category: "CONTENT",
        value: {
          title: "Instagram",
          value: "instagram"
        },
        options: [
          {
            title: "Instagram",
            value: "instagram"
          },
          {
            title: "Facebook",
            value: "facebook"
          },
          {
            title: "Twitter",
            value: "twitter"
          }
        ]
      },
      {
        name: "Link",
        type: "INPUT",
        category: "CONTENT",
        value: "",
      },
    ]
  };

  brandImagesTemplare = {
    name: "Images",
    type: "DATALIST",
    category: "CONTENT",
    value: [
    ],
    dataTemplate: [
      {
        name: "Image",
        type: "IMAGESELECT",
        value: "",
      }
    ]
  };

  integrationId: any = '';
  integration: any = {};
  facebookPages: any = []; 
  setupFbPage: any = false;

  environment = environment;

  constructor(
    public apiService: ApiService,
    public sharedService: SharedService,
    public router: Router,
    public route: ActivatedRoute,
    private message: NzMessageService
  ) { }

  ngOnInit() {
    this.integrationId = this.route.snapshot.paramMap.get("id");
    console.log(this.integrationId);
    this.apiService.getIntegration(this.integrationId).subscribe(
      (data: any) => {
        console.log(data);
        this.integration = data;

        if (this.integration.type == "META" && this.integration.accessToken) {
          if (!this.integration.data) {
            this.integration.data = {}; 
          }

          if (!this.integration.data?.facebookPage) {
            this.setupFbPage = true;  
          }
          this.getFacebookPages();
        }
        this.loading = false;

        // if (this.opportunityObject.stage == "NEW") {
        //   this.apiService.generateTemplateContent(this.opportunityId).subscribe(
        //     (data: any) => {

        //       // timer(0, this.INTERVAL).pipe(      // <-- start immediately and poll every `INTERVAL` seconds
        //       //   switchMap(() => this.apiService.getOpportunity(this.opportunityId)),  // <-- map to another observable
        //       //   takeUntil(this.closeTimer$)   // <-- close the subscription when `closeTimer$` emits
        //       // ).subscribe({
        //       //   next: (res: any) => {
        //       //     if (res["stage"] == "PENDING") {
        //       //       this.closeTimer$.next();  // <-- stop polling
        //       //       this.router.navigate([`/editor/${res._id}`]);
        //       //     }
        //       //   },
        //       //   error: (error: any) => {
        //       //     // handle errors
        //       //     // note that any errors would stop the polling here
        //       //   }
        //       // });
        //       // console.log(data);
        //       // this.opportunityObject = data;
        //       // this.router.navigate([`/editor/${this.opportunityId}`]);
        //       // this.loading = false;
        //       // this.fetchingSiteData = false;
        //       // this.siteData = data;
        //     },
        //     error => {
        //       console.log(error);
        //     });
        // }
      },
      error => {
        console.log(error);
      });
  }

  openPicker(property: any, mode: any) {
    console.log("Open Picker");
    this.sharedService.currentProperty = property;
    this.sharedService.pickerMode = mode;
    this.sharedService.pickerOpen = true;
  }

  saveBrandStyles() {
    this.saving = true;
    this.apiService.saveBrandStyles(this.workspace.brandStyles).subscribe(
      (data: any) => {
        console.log(data);
        this.saving = false;
      },
      error => {
        console.log(error);
      });
  }

  compareFn = (o1: any, o2: any): boolean => (o1 && o2 ? o1 === o2 : o1 === o2);

  compareFnSocialLinks = (o1: any, o2: any): boolean => (o1 && o2 ? o1.value === o2.value : o1 === o2);


  pushDataListValue(property: any) {
    property.value.push(JSON.parse(JSON.stringify(property.dataTemplate)));
  }

  saveIntegration() {
    this.saving = true;
    this.apiService.saveIntegration(this.integration).subscribe(
      data => {
        // login successful if there's a jwt token in the response
        console.log(data);
        this.saving = false;
        this.message.create("success", `Integration saved`);
      },
      error => {
        console.log(error);
      });
  }

  syncShopifyProducts(sync: any = {}) {
    sync.loading = true;
    this.apiService.syncShopifyProductsPeriodic(this.integration._id).subscribe(
      data => {
        // login successful if there's a jwt token in the response
        console.log(data);
        this.apiService.getIntegration(this.integrationId).subscribe(
          (data: any) => {
            console.log(data);
            this.integration = data;
            this.message.create("success", `Started sync`);
            sync.loading = false;
          });
      },
      error => {
        console.log(error);
      });
  }

  syncShopifyProductsFull(sync: any = {}) {
    sync.loading = true;
    this.apiService.syncShopifyProducts(this.integration._id).subscribe(
      data => {
        // login successful if there's a jwt token in the response
        console.log(data);
        this.apiService.getIntegration(this.integrationId).subscribe(
          (data: any) => {
            console.log(data);
            this.integration = data;
            this.message.create("success", `Started sync`);
            sync.loading = false;
          });
      },
      error => {
        console.log(error);
      });
  }

  syncShopifyImages(sync: any = {}) {
    sync.loading = true;
    this.apiService.syncShopifyImagesPeriodic(this.integration._id).subscribe(
      data => {
        console.log(data);
        this.apiService.getIntegration(this.integrationId).subscribe(
          (data: any) => {
            console.log(data);
            this.integration = data;
            this.message.create("success", `Started sync`);
            sync.loading = false;
          });
      },
      error => {
        console.log(error);
      });
  }

  syncShopifyCollections(sync: any = {}) {
    sync.loading = true;
    this.apiService.syncShopifyCollectionsPeriodic(this.integration._id).subscribe(
      data => {
        // login successful if there's a jwt token in the response
        console.log(data);
        this.apiService.getIntegration(this.integrationId).subscribe(
          (data: any) => {
            console.log(data);
            this.integration = data;
            this.message.create("success", `Started sync`);
            sync.loading = false;
          });
      },
      error => {
        console.log(error);
      });
  }

  syncShopifyImagesFull(sync: any = {}) {
    sync.loading = true;
    this.apiService.syncShopifyImages(this.integration._id).subscribe(
      data => {
        console.log(data);
        this.apiService.getIntegration(this.integrationId).subscribe(
          (data: any) => {
            console.log(data);
            this.integration = data;
            this.message.create("success", `Started sync`);
            sync.loading = false;
          });
      },
      error => {
        console.log(error);
      });
  }

  syncShopifyCollectionsFull(sync: any = {}) {
    sync.loading = true;
    this.apiService.syncShopifyCollections(this.integration._id).subscribe(
      data => {
        // login successful if there's a jwt token in the response
        console.log(data);
        this.apiService.getIntegration(this.integrationId).subscribe(
          (data: any) => {
            console.log(data);
            this.integration = data;
            this.message.create("success", `Started sync`);
            sync.loading = false;
          });
      },
      error => {
        console.log(error);
      });
  }

  vectorDbProducts() {
    this.apiService.vectorDbProducts().subscribe(
      data => {
        // login successful if there's a jwt token in the response
        console.log(data);
      },
      error => {
        console.log(error);
      });
  }

  syncToOpenAi() {
    this.apiService.syncDataToOpenAi().subscribe(
      data => {
        // login successful if there's a jwt token in the response
        console.log(data);
      },
      error => {
        console.log(error);
      });
  }

  syncKlaviyoEvents(sync: any) {
    sync.loading = true;
    this.apiService.syncKlaviyoEventsPeriodic(this.integration._id).subscribe(
      data => {
        console.log(data);
        this.apiService.getIntegration(this.integrationId).subscribe(
          (data: any) => {
            console.log(data);
            this.integration = data;
            this.message.create("success", `Started sync`);
            sync.loading = false;
          });
      },
      error => {
        console.log(error);
      });
  }

  syncKlaviyoEventsFull(sync: any) {
    sync.loading = true;
    this.apiService.syncKlaviyoEvents(this.integration._id).subscribe(
      data => {
        console.log(data);
        this.apiService.getIntegration(this.integrationId).subscribe(
          (data: any) => {
            console.log(data);
            this.integration = data;
            this.message.create("success", `Started sync`);
            sync.loading = false;
          });
      },
      error => {
        console.log(error);
      });
  }

  cancelSync(sync: any = {}) {
    sync.loading = true;
    this.apiService.cancelSync(sync.sync._id).subscribe(
      data => {
        console.log(data);
        this.apiService.getIntegration(this.integrationId).subscribe(
          (data: any) => {
            console.log(data);
            this.integration = data;
            this.message.create("success", `Cancelled sync`);
            sync.loading = false;
          });
      },
      error => {
        console.log(error);
      });
  }

  getFacebookPages() {
    this.apiService.getFacebookPages(this.integration._id).subscribe(
      (data: any) => {
        this.facebookPages = data.data;
        console.log(data);
      },
      error => {
        console.log(error);
      });
  }
}


