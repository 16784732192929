<div class="page-wrapper">
    <div class="content-wrapper">
        <ng-container *ngIf="loading">
            <div class="loading-wrapper">
                <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!loading">

            <nz-page-header style="padding: 0px !important; margin-bottom: 16px;">
                <nz-page-header-title>Brand settings</nz-page-header-title>
                <nz-page-header-extra>
                    <button [nzLoading]="saving" (click)="saveBrandStyles()" nz-button nzType="primary">Save</button>
                </nz-page-header-extra>
            </nz-page-header>
            <!-- <div class="custom-section">
                <div class="custom-section-header">Logo</div>
                <div class="custom-section-body">
                    <div class="image-select-box" style="width: 300px;">
                        <img class="image-select-chosen-image" src="{{sharedService.brandLogo}}">
                    </div>
                    <button style="margin-top: 8px" nz-button
                        (click)="this.sharedService.openPicker(sharedService.brandLogo, 'MEDIA', 'SINGLE')">Change</button>
                </div>
            </div> -->
            <div class="custom-card">
                <div class="custom-card-header">Styles</div>
                <div class="custom-card-body">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" [nzNoColon]="true">Colors</nz-form-label>
                        <nz-form-control [nzSpan]="12">
                            <ngx-colors ngx-colors-trigger [(ngModel)]="this.workspace.brandStyles.primaryColor"
                                style="float: left"></ngx-colors>
                            <div class="color-picker-label">Primary color</div>
                        </nz-form-control>
                        <nz-form-control [nzSpan]="12">
                            <ngx-colors ngx-colors-trigger [(ngModel)]="this.workspace.brandStyles.secondaryColor"
                                style="float: left"></ngx-colors>
                            <div class="color-picker-label">Secondary color</div>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" [nzNoColon]="true">Additional colors</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <div nz-row [nzGutter]="4">
                                <ng-container
                                    *ngFor="let color of this.workspace.brandStyles.additionalColors; let itemIndex=index">
                                    <div nz-col nzSpan="4">
                                        <div class="custom-card mini">
                                            <div class="custom-card-body mini">
                                                <div class="custom-modal-close"
                                                    style="line-height: 28px; float: right; margin-right: 4px;"><span
                                                        (click)="this.sharedService.removeItemFromArray(itemIndex, this.workspace.brandStyles.additionalColors)"
                                                        nz-icon nzType="close" nzTheme="outline"></span>
                                                </div>
                                                <ngx-colors ngx-colors-trigger [(ngModel)]="color.value"
                                                    style="float: left"></ngx-colors>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </nz-form-control>
                        <button nz-button (click)="this.addColor()"><span nz-icon nzType="plus"
                                nzTheme="outline"></span>Add
                            color</button>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzNoColon]="true">Button shape</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <nz-radio-group [(ngModel)]="this.workspace.brandStyles.borderRadius">
                                <label nz-radio-button nzValue="0px">Square</label>
                                <label nz-radio-button nzValue="6px">Curved</label>
                                <label nz-radio-button nzValue="1000px">Round</label>
                            </nz-radio-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item style="margin-bottom: 0px;">
                        <nz-form-label [nzNoColon]="true">Element shape</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <nz-radio-group [(ngModel)]="this.workspace.brandStyles.elementBorderRadius">
                                <label nz-radio-button nzValue="0px">Square</label>
                                <label nz-radio-button nzValue="6px">Curved</label>
                            </nz-radio-group>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>

            <div class="custom-card">
                <div class="custom-card-header">Header & footer</div>
                <div class="custom-card-body">
                    <nz-form-item>
                        <nz-form-label [nzNoColon]="true">Header template</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <button nz-button
                                [routerLink]="['/editor', this.workspace.brandStyles.headerTemplate._id]">Edit</button>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item style="margin-bottom: 0px;">
                        <nz-form-label [nzNoColon]="true">Footer template</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <button nz-button
                                [routerLink]="['/editor', this.workspace.brandStyles.footerTemplate._id]">Edit</button>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>

            <div class="custom-card">
                <div class="custom-card-header">Unsubscribe text</div>
                <div class="custom-card-body">
                    <nz-form-item>
                        <nz-form-control [nzSpan]="24">
                            <input [(ngModel)]="this.workspace.brandStyles.unsubscribeText" nz-input />
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>

            <div class="custom-card">
                <div class="custom-card-header">Brand images</div>
                <div class="custom-card-body">
                    <nz-form-item>
                        <nz-form-control [nzSpan]="24">
                            <div nz-row [nzGutter]="8">

                                <ng-container
                                    *ngFor="let item of this.workspace.brandStyles.brandImages.value; let itemIndex=index">
                                    <div nz-col nzSpan="4">
                                        <div class="custom-card mini">
                                            <div class="custom-card-body mini">
                                                <div class="list-title" style="float: right; margin-top: -11px;"><span
                                                        (click)="this.sharedService.removeItemFromArray(itemIndex, this.workspace.brandStyles.brandImages.value)"
                                                        nz-icon nzType="close" nzTheme="outline"></span>
                                                </div>
                                                <div nz-row [nzGutter]="8">
                                                    <div nz-col nzSpan="24">
                                                        <nz-form-item style="margin-bottom: 0px;">
                                                            <nz-form-control [nzSpan]="24">
                                                                <div class="image-select-box">
                                                                    <img class="image-select-chosen-image"
                                                                        src="{{item.url}}">
                                                                </div>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div class="custom-card-footer">
                    <button nz-button
                        (click)="this.sharedService.openPicker(this.workspace.brandStyles.brandImages, 'MEDIA', 'MULTI')"><span
                            nz-icon nzType="plus" nzTheme="outline"></span>Add images</button>
                </div>
            </div>

            <div class="custom-card">
                <div class="custom-card-header">Fonts</div>
                <div class="custom-card-body">
                    <nz-form-item>
                        <nz-form-control [nzSpan]="24">
                            <ng-container *ngFor="let font of this.workspace.brandStyles.fonts">
                                <div class="custom-card">
                                    <div class="custom-card-body">
                                        <nz-tag> {{font.name}}</nz-tag>
                                        <br>
                                        <br>
                                        <div nz-row [nzGutter]="8">
                                            <div nz-col nzSpan="10">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true">Font</nz-form-label>
                                                    <nz-form-control [nzSpan]="24">

                                                        <nz-select nzShowSearch [compareWith]="compareFn"
                                                            [(ngModel)]="font.font" [style.fontFamily]="font.font">
                                                            <ng-container
                                                                *ngIf="this.workspace.brandStyles.customFonts && this.workspace.brandStyles.customFonts[0] && this.workspace.brandStyles.customFonts[0].value">
                                                                <nz-option-group nzLabel="Custom Fonts">
                                                                    <ng-container
                                                                        *ngFor="let font of this.workspace.brandStyles.customFonts">
                                                                        <nz-option nzCustomContent
                                                                            [nzValue]="font.value"
                                                                            [nzLabel]="font.value"><span
                                                                                [style.fontFamily]="font.value">{{font.value}}</span></nz-option>
                                                                    </ng-container>
                                                                </nz-option-group>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="this.workspace.brandStyles.customFonts && this.workspace.brandStyles.customFonts[0] && this.workspace.brandStyles.customFonts[0].value">
                                                                <nz-option-group nzLabel="Ripple Fonts">
                                                                    <ng-container
                                                                        *ngFor="let font of sharedService.fonts">
                                                                        <nz-option nzCustomContent
                                                                            [nzValue]="font.value"
                                                                            [nzLabel]="font.value"><span
                                                                                [style.fontFamily]="font.value">{{font.value}}</span></nz-option>
                                                                    </ng-container>
                                                                </nz-option-group>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="!this.workspace.brandStyles.customFonts || !this.workspace.brandStyles.customFonts[0] || !this.workspace.brandStyles.customFonts[0].value">
                                                                <ng-container *ngFor="let font of sharedService.fonts">
                                                                    <nz-option nzCustomContent [nzValue]="font.value"
                                                                        [nzLabel]="font.value"><span
                                                                            [style.fontFamily]="font.value">{{font.value}}</span></nz-option>
                                                                </ng-container>
                                                            </ng-container>
                                                        </nz-select>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <!-- <div nz-col nzSpan="6">
                                <nz-form-item>
                                    <nz-form-label [nzNoColon]="true">Font size</nz-form-label>
                                    <nz-form-control [nzSpan]="24">
                                        <input nz-input [(ngModel)]="font.fontSize" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div> -->
                                            <div nz-col nzSpan="6">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true">Font weight</nz-form-label>
                                                    <nz-form-control [nzSpan]="24">
                                                        <nz-select [compareWith]="compareFn"
                                                            [(ngModel)]="font.fontWeight">
                                                            <nz-option nzValue="100" nzLabel="Thin (100)"></nz-option>
                                                            <nz-option nzValue="200"
                                                                nzLabel="Extra light (200)"></nz-option>
                                                            <nz-option nzValue="300" nzLabel="Light (300)"></nz-option>
                                                            <nz-option nzValue="400" nzLabel="Normal (400)"></nz-option>
                                                            <nz-option nzValue="500" nzLabel="Medium (500)"></nz-option>
                                                            <nz-option nzValue="600"
                                                                nzLabel="Semi bold (600)"></nz-option>
                                                            <nz-option nzValue="700" nzLabel="Bold (700)"></nz-option>
                                                            <nz-option nzValue="800"
                                                                nzLabel="Extra bold (800)"></nz-option>
                                                            <nz-option nzValue="900" nzLabel="Black (900)"></nz-option>
                                                        </nz-select>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <div nz-col nzSpan="4">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true">Font Size (px)</nz-form-label>
                                                    <nz-form-control [nzSpan]="24">
                                                        <!-- <nz-input-number-group nzAddOnAfter="px">
                                                    
                                                </nz-input-number-group> -->
                                                        <nz-input-number [(ngModel)]="font.fontSize" [nzStep]="1"
                                                            style="width: 100%;"></nz-input-number>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <div nz-col nzSpan="4">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true">Line height (px)</nz-form-label>
                                                    <nz-form-control [nzSpan]="24">
                                                        <!-- <nz-input-number-group nzAddOnAfter="px">
                                                    
                                                </nz-input-number-group> -->
                                                        <nz-input-number [(ngModel)]="font.lineHeight" [nzStep]="1"
                                                            style="width: 100%;"></nz-input-number>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </ng-container>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>

            <!-- <div class="custom-card">
                <div class="custom-card-header">Custom fonts</div>
                <div class="custom-card-body">
                    <ng-container *ngFor="let font of this.workspace.brandStyles.customFonts">
                        <div class="custom-card">
                            <div class="custom-card-body">
                                <nz-form-item>
                                    <nz-form-label [nzNoColon]="true">Name (ex: "Sedan", sans serif)</nz-form-label>
                                    <nz-form-control [nzSpan]="24">
                                        <input nz-input [(ngModel)]="font.value" />

                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-label [nzNoColon]="true">URL</nz-form-label>
                                    <nz-form-control [nzSpan]="24">
                                        <input nz-input [(ngModel)]="font.url" />

                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </ng-container>
                    <button nz-button (click)="this.addCustomFont()"><span nz-icon nzType="plus"
                            nzTheme="outline"></span>Add font</button>
                </div>
            </div> -->


            <!-- <div class="custom-section">
                <div class="custom-section-header">Colors</div>
                <div class="custom-section-body">

                    <nz-form-item>
                        <nz-form-label [nzNoColon]="true">Color strategy</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <nz-select [(ngModel)]="this.workspace.brandStyles.colorStrategy" style="width:300px">
                                <nz-option nzCustomContent nzValue="AUTO" nzLabel="Auto"><span>Auto</span></nz-option>
                                <nz-option nzCustomContent nzValue="SOLID"
                                    nzLabel="Solid"><span>Solid</span></nz-option>
                            </nz-select>

                        </nz-form-control>

                    </nz-form-item>
                    <nz-form-item *ngIf="this.workspace.brandStyles.colorStrategy == 'SOLID'">
                        <nz-form-control [nzSpan]="24">

                            <ngx-colors ngx-colors-trigger
                                [(ngModel)]="this.workspace.brandStyles.colorStrategyBackground"
                                style="float: left"></ngx-colors>
                            <div class="color-picker-label">Background color</div>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div> -->
            <!-- <div class="custom-section">
                <div class="custom-section-header">Fonts</div>

                <div class="custom-section-body">

                    <nz-form-item style="margin-bottom: 0px">
                        <nz-form-label [nzNoColon]="true">Body font</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <nz-select [compareWith]="compareFn" [(ngModel)]="this.workspace.brandStyles.bodyFont"
                                style="width:300px" [style.fontFamily]="this.workspace.brandStyles.bodyFont">
                                <ng-container *ngFor="let font of sharedService.fonts">
                                    <nz-option nzCustomContent [nzValue]="font.value" [nzLabel]="font.value"><span
                                            [style.fontFamily]="font.value">{{font.value}}</span></nz-option>
                                </ng-container>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div> -->



            <!-- <div class="custom-section">
                <div class="custom-section-body">


                    <nz-form-item>
                        <nz-form-control [nzSpan]="24">
                            <ng-container
                                *ngFor="let item of this.workspace.brandStyles.navbar.value; let itemIndex=index">
                                <div class="custom-section mini">
                                    <div class="custom-section-body mini">
                                        <div class="list-title" style="float: right; margin-top: -11px;"><span
                                                (click)="this.sharedService.removeItemFromArray(itemIndex, this.workspace.brandStyles.navbar.value)"
                                                nz-icon nzType="close" nzTheme="outline"></span>
                                        </div>
                                        <div nz-row [nzGutter]="8">
                                            <div nz-col nzSpan="24">
                                                <ng-container *ngFor="let field of item">
                                                    <ng-container *ngIf="field.type == 'INPUT'">
                                                        <nz-form-item style="margin-bottom: 4px !important;">
                                                            <nz-form-label
                                                                [nzNoColon]="true">{{field.name}}</nz-form-label>
                                                            <nz-form-control [nzSpan]="24">
                                                                <input nz-input [(ngModel)]="field.value" />
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </ng-container>

                                                    <ng-container *ngIf="field.type == 'SELECT'">
                                                        <nz-form-item>
                                                            <nz-form-label [nzNoColon]="true">{{
                                                                field.name }}</nz-form-label>
                                                            <nz-form-control [nzSpan]="24">
                                                                <nz-select [(ngModel)]="field.value"
                                                                    nzPlaceHolder="Choose">
                                                                    <nz-option *ngFor="let option of field.options"
                                                                        [nzValue]="option"
                                                                        [nzLabel]="option.title"></nz-option>
                                                                </nz-select>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </ng-container>

                                                    <ng-container *ngIf="field.type == 'RICHTEXT'">
                                                        <nz-form-item style="margin-bottom: 4px !important;">
                                                            <nz-form-label
                                                                [nzNoColon]="true">{{field.name}}</nz-form-label>
                                                            <nz-form-control [nzSpan]="24">
                                                                <textarea nz-input [(ngModel)]="field.value"
                                                                    [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </ng-container>
                                                    <ng-container *ngIf="field.type == 'IMAGESELECT'">
                                                        <nz-form-item>
                                                            <nz-form-label [nzNoColon]="true">{{
                                                                field.name }}</nz-form-label>
                                                            <nz-form-control [nzSpan]="24">
                                                                <div class="image-select-box">
                                                                    <img class="image-select-chosen-image"
                                                                        src="{{field.value}}">
                                                                </div>
                                                                <button nz-button
                                                                    (click)="openPicker(field, 'MEDIA')">Change</button>&nbsp;
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </ng-container>
                                                </ng-container>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </ng-container>
                            <button nz-button (click)="pushDataListValue(this.workspace.brandStyles.navbar)"
                                nzBlock><span nz-icon nzType="plus" nzTheme="outline"></span>Add item</button>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div> -->

            <!-- <div class="custom-section">
                <div class="custom-section-body">

                    <nz-form-item>
                        <nz-form-label [nzNoColon]="true">Footer disclaimer</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <textarea nz-input [(ngModel)]="this.workspace.brandStyles.footerDisclaimer"
                                [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzNoColon]="true">Unsubscribe text</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <textarea nz-input [(ngModel)]="this.workspace.brandStyles.footerUnsubscribeText"
                                [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>

                        <nz-form-label [nzNoColon]="true">Social links</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <ng-container
                                *ngFor="let item of this.workspace.brandStyles.footerLinks.value; let itemIndex=index">
                                <div class="custom-section mini">
                                    <div class="custom-section-body mini">
                                        <div class="list-title" style="float: right; margin-top: -11px;"><span
                                                (click)="this.sharedService.removeItemFromArray(itemIndex, this.workspace.brandStyles.footerLinks.value)"
                                                nz-icon nzType="close" nzTheme="outline"></span>
                                        </div>
                                        <div nz-row [nzGutter]="8">
                                            <div nz-col nzSpan="24">
                                                <ng-container *ngFor="let field of item">
                                                    <ng-container *ngIf="field.type == 'INPUT'">
                                                        <nz-form-item style="margin-bottom: 4px !important;">
                                                            <nz-form-label
                                                                [nzNoColon]="true">{{field.name}}</nz-form-label>
                                                            <nz-form-control [nzSpan]="24">
                                                                <input nz-input [(ngModel)]="field.value" />
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </ng-container>

                                                    <ng-container *ngIf="field.type == 'SELECT'">
                                                        <nz-form-item>
                                                            <nz-form-label [nzNoColon]="true">{{
                                                                field.name }}</nz-form-label>
                                                            <nz-form-control [nzSpan]="24">
                                                                <nz-select [(ngModel)]="field.value"
                                                                    nzPlaceHolder="Choose"
                                                                    [compareWith]="compareFnSocialLinks">
                                                                    <nz-option *ngFor="let option of field.options"
                                                                        [nzValue]="option"
                                                                        [nzLabel]="option.title"></nz-option>
                                                                </nz-select>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </ng-container>

                                                    <ng-container *ngIf="field.type == 'RICHTEXT'">
                                                        <nz-form-item style="margin-bottom: 4px !important;">
                                                            <nz-form-label
                                                                [nzNoColon]="true">{{field.name}}</nz-form-label>
                                                            <nz-form-control [nzSpan]="24">
                                                                <textarea nz-input [(ngModel)]="field.value"
                                                                    [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </ng-container>
                                                    <ng-container *ngIf="field.type == 'IMAGESELECT'">
                                                        <nz-form-item>
                                                            <nz-form-label [nzNoColon]="true">{{
                                                                field.name }}</nz-form-label>
                                                            <nz-form-control [nzSpan]="24">
                                                                <div class="image-select-box">
                                                                    <img class="image-select-chosen-image"
                                                                        src="{{field.value}}">
                                                                </div>
                                                                <button nz-button
                                                                    (click)="openPicker(field, 'MEDIA')">Change</button>&nbsp;
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </ng-container>
                                                </ng-container>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </ng-container>
                            <button nz-button (click)="pushDataListValue(this.workspace.brandStyles.footerLinks)"
                                nzBlock><span nz-icon nzType="plus" nzTheme="outline"></span>Add item</button>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div> -->



        </ng-container>
    </div>
</div>