<div class="custom-modal-header">
    <div class="custom-modal-title">Choose review</div>
    <div class="custom-modal-close" (click)="close()"><span nz-icon nzType="close" nzTheme="outline"></span></div>
</div>
<div class="custom-modal-body">
    <!-- <ng-container *ngIf="this.sharedService.pickerMode == 'MEDIA'">
        <nz-input-group [nzSuffix]="suffixIconSearch"
            style="border-radius: 5px; margin-bottom: 16px; width: 300px; padding-top: 0px; padding-bottom: 0px;">
            <input #input type="text" nz-input placeholder="Search" [(ngModel)]="imageSearchTerm"
                (ngModelChange)="onSearchChange($event)" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
            <span nz-icon nzType="search"></span>
        </ng-template>
    </ng-container> -->

    <nz-input-group [nzSuffix]="suffixIconSearch"
        style="border-radius: 5px; margin-bottom: 16px; width: 100%; padding-top: 0px; padding-bottom: 0px;">
        <input #input type="text" nz-input placeholder="Search" [(ngModel)]="imageSearchTerm"
            (ngModelChange)="onSearchChange($event)" />
    </nz-input-group>
    <ng-template #suffixIconSearch>
        <ng-container *ngIf="imageSearchTerm">
            <span nz-icon class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle"
                (click)="exitSearch()"></span>
        </ng-container>
        <ng-container *ngIf="!imageSearchTerm">
            <span nz-icon nzType="search"></span>
        </ng-container>
    </ng-template>
    <!-- <nz-select nzPlaceHolder="Status" style="width: 120px" [(ngModel)]="productStatus">
            <nz-option nzLabel="All" nzValue="ALL"></nz-option>
            <nz-option nzLabel="Active" nzValue="ACTIVE"></nz-option>
        </nz-select> -->
    <div class="menu-bar" *ngIf="this.mode != 'SEARCH'">
        <ng-container *ngIf="mode == 'TAG'">
            <button nz-button style="margin-right: 4px; margin-bottom: 8px; border-radius: 50px;" (click)="removeTag()"
                nzType="primary">
                <span nz-icon nzType="close-circle" nzTheme="outline"></span> Clear
            </button>
        </ng-container>
        <ng-container *ngFor="let tag of tagList | slice:0:15">
            <ng-container *ngIf="currentTag == tag">
                <button nz-button style="margin-right: 4px; margin-bottom: 8px; border-radius: 50px;"
                    (click)="filterReviews(tag)" nzType="primary">
                    {{tag}}
                </button>
            </ng-container>
            <ng-container *ngIf="currentTag != tag">
                <button nz-button style="margin-right: 4px; margin-bottom: 8px; border-radius: 50px;"
                    (click)="filterReviews(tag)">
                    {{tag}}
                </button>
            </ng-container>
        </ng-container>
    </div>
    <ng-container *ngIf="this.loading">
        <div nz-row [nzGutter]="[12, 12]">
            <!-- <div nz-col class="gutter-row" [nzSpan]="4">
                <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
            </div> -->
            <div nz-col class="gutter-row" [nzSpan]="4">
                <div class="custom-card">
                    <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                    <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                        <div class="picker-item-title"></div>
                    </div>
                </div>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <div class="custom-card">
                    <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                    <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                        <div class="picker-item-title"></div>
                    </div>
                </div>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <div class="custom-card">
                    <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                    <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                        <div class="picker-item-title"></div>
                    </div>
                </div>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <div class="custom-card">
                    <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                    <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                        <div class="picker-item-title"></div>
                    </div>
                </div>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <div class="custom-card">
                    <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                    <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                        <div class="picker-item-title"></div>
                    </div>
                </div>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <div class="custom-card">
                    <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                    <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                        <div class="picker-item-title"></div>
                    </div>
                </div>
            </div>
        </div>

    </ng-container>

    <div id="goUp"></div>
    <ng-container *ngIf="!this.loading">
        <div class="visible">
            <!-- <div class="sidebar">
                    <input #filePicker type="file" id="filePicker" multiple hidden (change)="handleFileSelect($event)">
                    <button nz-button [nzLoading]="uploading" (click)="filePicker.click()"><span nz-icon
                            nzType="cloud-upload" nzTheme="outline"></span>&nbsp;Upload
                        images</button>
                    <br>
                    <br>
                    <ul nz-menu nzMode="vertical">
                        <li nz-menu-item (click)="this.mediaCategory = 'BRAND'"
                            [class.ant-menu-item-selected]="this.mediaCategory == 'BRAND'">
                            <span>Brand images</span>
                        </li>
                        <li nz-menu-item (click)="this.mediaCategory = 'ALL'"
                            [class.ant-menu-item-selected]="this.mediaCategory == 'ALL'">
                            <span>All images</span>
                        </li>
                    </ul>
                </div> -->

            <div class="body">
                <div nz-row [nzGutter]="[12, 12]">
                    <ng-container *ngIf="this.mode =='DEFAULT'">
                        <ng-container *ngFor="let review of reviewSnippets; index as index">
                            <div nz-col class="gutter-row" [nzSpan]="12">
                                <div class="custom-card hover" (click)="setSelectedObject(review)"
                                    [class.selected]="this.sharedService.getArrayItem(this.selectedObjects, '_id', review._id)">
                                    <div class="custom-card-body mini">
                                        <div class="quote-box">
                                            <div class="quote-box-inner">
                                                {{review.data.snippet}}
                                                <div style="font-weight: 500; margin-top: 8px; color: #767676">
                                                    {{review.data.reviewerName}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="custom-card-footer" style="padding: 8px; text-align: left">
                                        <ng-container *ngFor="let tag of review.data.tags">
                                            <nz-tag class="aqua">{{tag}}</nz-tag>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="this.mode =='TAG'">
                        <ng-container *ngFor="let review of filteredReviews; index as index">
                            <div nz-col class="gutter-row" [nzSpan]="12">
                                <div class="custom-card hover" (click)="setSelectedObject(review)"
                                    [class.selected]="this.sharedService.getArrayItem(this.selectedObjects, '_id', review._id)">
                                    <div class="custom-card-body mini">
                                        <div class="quote-box">
                                            <div class="quote-box-inner">
                                                {{review.data.snippet}}
                                                <div style="font-weight: 500; margin-top: 8px; color: #767676">
                                                    {{review.data.reviewerName}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="custom-card-footer" style="padding: 8px; text-align: left">
                                        <ng-container *ngFor="let tag of review.data.tags">
                                            <nz-tag class="aqua">{{tag}}</nz-tag>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="this.mode =='SEARCH'">
                        <ng-container *ngFor="let review of searchedReviews; index as index">
                            <div nz-col class="gutter-row" [nzSpan]="12">
                                <div class="custom-card hover" (click)="setSelectedObject(review)"
                                    [class.selected]="this.sharedService.getArrayItem(this.selectedObjects, '_id', review._id)">
                                    <div class="custom-card-body mini">
                                        <div class="quote-box">
                                            <div class="quote-box-inner">
                                                {{review.data.snippet}}
                                                <div style="font-weight: 500; margin-top: 8px; color: #767676">
                                                    {{review.data.reviewerName}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="custom-card-footer" style="padding: 8px; text-align: left">
                                        <ng-container *ngFor="let tag of review.data.tags">
                                            <nz-tag class="aqua">{{tag}}</nz-tag>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<div class="custom-modal-footer">
    <ng-container
        *ngIf="this.sharedService.pickerMode == 'PRODUCTS' || (this.sharedService.pickerMode == 'MEDIA' && this.mediaCategory != 'BRAND')">
        <nz-pagination [nzTotal]="totalItems" [nzPageSize]="50" (nzPageIndexChange)="this.pageIndexChange($event)"
            style="float: left;"></nz-pagination>
    </ng-container>
    <button nz-button nzType="primary" (click)="setReview()">Confirm</button>
</div>