<div class="custom-modal-header">
    <ng-container *ngIf="this.sharedService.pickerMode == 'MEDIA'">
        <div class="custom-modal-title">Media</div>
    </ng-container>
    <ng-container *ngIf="this.sharedService.pickerMode == 'PRODUCTS'">
        <div class="custom-modal-title">Products</div>
    </ng-container>
    <div class="custom-modal-close" (click)="close()"><span nz-icon nzType="close" nzTheme="outline"></span></div>
</div>
<div class="custom-modal-body">
    <!-- <ng-container *ngIf="this.sharedService.pickerMode == 'MEDIA'">
        <nz-input-group [nzSuffix]="suffixIconSearch"
            style="border-radius: 5px; margin-bottom: 16px; width: 300px; padding-top: 0px; padding-bottom: 0px;">
            <input #input type="text" nz-input placeholder="Search" [(ngModel)]="imageSearchTerm"
                (ngModelChange)="onSearchChange($event)" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
            <span nz-icon nzType="search"></span>
        </ng-template>
    </ng-container> -->

    <nz-input-group [nzSuffix]="suffixIconSearch"
        style="border-radius: 5px; margin-bottom: 8px; padding-top: 0px; padding-bottom: 0px;">
        <input #input type="text" nz-input placeholder="Search" [(ngModel)]="imageSearchTerm"
            (ngModelChange)="onSearchChange($event)" />
    </nz-input-group>
    <ng-template #suffixIconSearch>
        <ng-container *ngIf="imageSearchTerm">
            <span nz-icon class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle"
                (click)="exitSearch()"></span>
        </ng-container>
        <ng-container *ngIf="!imageSearchTerm">
            <span nz-icon nzType="search"></span>
        </ng-container>
    </ng-template>
    <!-- <nz-select nzPlaceHolder="Status" style="width: 120px" [(ngModel)]="productStatus">
            <nz-option nzLabel="All" nzValue="ALL"></nz-option>
            <nz-option nzLabel="Active" nzValue="ACTIVE"></nz-option>
        </nz-select> -->
    <ng-container *ngIf="this.sharedService.pickerMode == 'MEDIA'">
        <div class="menu-bar" *ngIf="!this.searchMode">
            <input #filePicker type="file" id="filePicker" multiple hidden (change)="handleFileSelect($event)">
            <button style="float: right; margin-top: 3px;" nz-button [nzLoading]="uploading"
                (click)="filePicker.click()"><span nz-icon nzType="cloud-upload" nzTheme="outline"></span>&nbsp;Upload
                images</button>
            <ul nz-menu nzMode="horizontal">
                <li nz-menu-item (click)="this.mediaCategory = 'BRAND'"
                    [class.ant-menu-item-selected]="this.mediaCategory == 'BRAND'">
                    <span><span nz-icon nzType="star" nzTheme="outline"></span>&nbsp;&nbsp;Favorites</span>
                </li>
                <li nz-menu-item (click)="this.changeMediaCategory('ALL')"
                    [class.ant-menu-item-selected]="this.mediaCategory == 'ALL'">
                    <span>All</span>
                </li>
                <li nz-menu-item (click)="this.changeMediaCategory('MANUAL')"
                    [class.ant-menu-item-selected]="this.mediaCategory == 'MANUAL'">
                    <span>Uploaded</span>
                </li>
                <li nz-menu-item (click)="this.changeMediaCategory('SHOPIFY')"
                    [class.ant-menu-item-selected]="this.mediaCategory == 'SHOPIFY'">
                    <span>Shopify</span>
                </li>
            </ul>
        </div>
    </ng-container>
    <ng-container *ngIf="this.loading">
        <div nz-row [nzGutter]="[12, 12]">
            <!-- <div nz-col class="gutter-row" [nzSpan]="4">
                <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
            </div> -->
            <div nz-col class="gutter-row" [nzSpan]="4">
                <div class="custom-card">
                    <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                    <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                        <div class="picker-item-title"></div>
                    </div>
                </div>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <div class="custom-card">
                    <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                    <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                        <div class="picker-item-title"></div>
                    </div>
                </div>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <div class="custom-card">
                    <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                    <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                        <div class="picker-item-title"></div>
                    </div>
                </div>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <div class="custom-card">
                    <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                    <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                        <div class="picker-item-title"></div>
                    </div>
                </div>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <div class="custom-card">
                    <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                    <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                        <div class="picker-item-title"></div>
                    </div>
                </div>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <div class="custom-card">
                    <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                    <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                        <div class="picker-item-title"></div>
                    </div>
                </div>
            </div>
        </div>

    </ng-container>

    <div id="goUp"></div>
    <ng-container *ngIf="!this.loading">
        <div class="visible">
            <ng-container *ngIf="this.sharedService.pickerMode == 'MEDIA'">
                <!-- <div class="sidebar">
                    <input #filePicker type="file" id="filePicker" multiple hidden (change)="handleFileSelect($event)">
                    <button nz-button [nzLoading]="uploading" (click)="filePicker.click()"><span nz-icon
                            nzType="cloud-upload" nzTheme="outline"></span>&nbsp;Upload
                        images</button>
                    <br>
                    <br>
                    <ul nz-menu nzMode="vertical">
                        <li nz-menu-item (click)="this.mediaCategory = 'BRAND'"
                            [class.ant-menu-item-selected]="this.mediaCategory == 'BRAND'">
                            <span>Brand images</span>
                        </li>
                        <li nz-menu-item (click)="this.mediaCategory = 'ALL'"
                            [class.ant-menu-item-selected]="this.mediaCategory == 'ALL'">
                            <span>All images</span>
                        </li>
                    </ul>
                </div> -->

                <div class="body">
                    <div nz-row [nzGutter]="[12, 12]">
                        <ng-container *ngIf="this.mediaCategory != 'BRAND'">
                            <ng-container *ngIf="!opportunities || opportunities.length == 0">
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <div class="email-thumbnail"
                                        style="background: #f9f9f9; text-align: center; font-size: 48px; padding-top: 75px; color: #d9d9d9; width: 200px; margin: auto; margin-top: 60px;">
                                        <i class="lni lni-image"></i>
                                    </div>
                                    <div style="font-weight: 500; margin: auto; text-align: center; margin-top: 24px;">No images found :/</div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="opportunities && opportunities.length > 0">
                                <ng-container *ngFor="let opportunity of opportunities; index as index">
                                    <!-- <ng-container *ngIf="index >= (this.currentPage - 1) * 50 && index < (this.currentPage * 50)"> -->
                                    <!-- <div nz-col class="gutter-row" [nzSpan]="4">
                                    <div class="image-select-box" (click)="setSelectedObject(opportunity)"
                                        [class.selected]="this.sharedService.getArrayItem(this.selectedObjects, '_id', opportunity._id)">
                                        <img class="image-select-chosen-image" src="{{opportunity.thumbnail}}">
                                    </div>
                                </div> -->
                                    <div nz-col class="gutter-row" [nzSpan]="4">
                                        <div class="custom-card hover" (click)="setSelectedObject(opportunity)"
                                            [class.selected]="this.sharedService.getArrayItem(this.selectedObjects, '_id', opportunity._id)">
                                            <div class="custom-circle-button delete-item" style="color: red;"
                                                (click)="deleteFile(opportunity._id)"><span nz-icon nzType="delete"
                                                    nzTheme="outline"></span></div>
                                            <!-- <img  src="{{opportunity.thumbnail}}" /> -->
                                            <ng-container *ngIf="opportunity.loadedThumbnail">
                                                <img class="picker-item-image" [src]="opportunity.thumbnail">
                                            </ng-container>
                                            <ng-container *ngIf="!opportunity.loadedThumbnail">
                                                <nz-skeleton-element nzType="image" [nzActive]="true" class="image-preview"></nz-skeleton-element>
                                            </ng-container>
                                            <div class="custom-card-footer mini"
                                                style="padding: 8px; text-align: left;">
                                                <div class="picker-item-title">{{opportunity.name}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- </ng-container> -->
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="this.mediaCategory == 'BRAND'">
                            <ng-container *ngFor="let opportunity of brandImages; index as index">
                                <!-- <ng-container *ngIf="index >= (this.currentPage - 1) * 50 && index < (this.currentPage * 50)"> -->
                                <!-- <div nz-col class="gutter-row" [nzSpan]="4">
                                    <div class="image-select-box" (click)="setSelectedObject(opportunity)"
                                        [class.selected]="this.sharedService.getArrayItem(this.selectedObjects, '_id', opportunity._id)">
                                        <img class="image-select-chosen-image" src="{{opportunity.thumbnail}}">
                                    </div>
                                </div> -->
                                <div nz-col class="gutter-row" [nzSpan]="4">
                                    <div class="custom-card hover" (click)="setSelectedObject(opportunity)"
                                        [class.selected]="this.sharedService.getArrayItem(this.selectedObjects, '_id', opportunity._id)">
                                        <img class="picker-item-image" src="{{opportunity.thumbnail}}" />
                                        <div class="custom-card-footer mini"
                                            style="padding: 8px; text-align: left;">
                                            <div class="picker-item-title">{{opportunity.name}}</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- </ng-container> -->
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="this.sharedService.pickerMode == 'PRODUCTS'">
                <div nz-row [nzGutter]="[12, 12]">
                    <ng-container *ngFor="let opportunity of opportunities; index as index">
                        <!-- <ng-container *ngIf="index >= (this.currentPage - 1) * 50 && index < (this.currentPage * 50)"> -->
                        <div nz-col class="gutter-row" [nzSpan]="4">
                            <div class="custom-card hover" (click)="setSelectedObject(opportunity)"
                                [class.selected]="this.sharedService.getArrayItem(this.selectedObjects, '_id', opportunity._id)">
                                <!-- <img class="picker-item-image" src="{{opportunity.thumbnail}}" /> -->
                                <ng-container *ngIf="opportunity.loadedThumbnail">
                                    <img class="picker-item-image" [src]="opportunity.thumbnail">
                                </ng-container>
                                <ng-container *ngIf="!opportunity.loadedThumbnail">
                                    <nz-skeleton-element nzType="image" [nzActive]="true" class="image-preview"></nz-skeleton-element>
                                </ng-container>
                                <div class="custom-card-footer mini"
                                    style="padding: 8px; text-align: left;">
                                    <div class="picker-item-title">{{opportunity.title}}</div>
                                </div>
                            </div>
                        </div>
                        <!-- </ng-container> -->
                    </ng-container>
                </div>
                <!-- <nz-select nzPlaceHolder="Stock" style="width: 120px" [(ngModel)]="productStock">
                    <nz-option nzLabel="All" nzValue="ALL"></nz-option>
                    <nz-option nzLabel="In stock" nzValue="INSTOCK"></nz-option>
                </nz-select> -->
            </ng-container>
        </div>
    </ng-container>
</div>
<div class="custom-modal-footer">
    <ng-container
        *ngIf="this.sharedService.pickerMode == 'PRODUCTS' || (this.sharedService.pickerMode == 'MEDIA' && this.mediaCategory != 'BRAND') || !this.imageSearchTerm">
        <nz-pagination [nzTotal]="totalItems" [nzPageSize]="50" (nzPageIndexChange)="this.pageIndexChange($event)"
            style="float: left;"></nz-pagination>
    </ng-container>
    <button nz-button nzType="primary" (click)="setPropertyValue()">Confirm</button>
</div>