<div class="page-wrapper">
    <div class="content-wrapper">


        <!-- <ng-container *ngIf="this.noCampaigns">
                <ng-container *ngIf="!this.generatingCampaigns">
                    <nz-card style="text-align: center; padding: 8px; margin-top: 8px">
                        <h3>Get started with Copilot email campaigns</h3>
                        <p>Copilot will create a custom content calendar for your brand, all you do is approve!</p>
                        <br>
                        <button nz-button nzType="primary" routerLink="/signup/2">Get started</button>
                    </nz-card>
                </ng-container>

                <ng-container *ngIf="this.generatingCampaigns">
                    <nz-card style="text-align: center; padding: 8px; margin-top: 8px">
                        <h3>Get started with Copilot email campaigns</h3>
                        <p>Copilot will create a custom content calendar for your brand, all you do is approve!</p>
                        <br>
                        <button nz-button nzType="primary" routerLink="/signup/2">Get started</button>
                    </nz-card>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="!this.noCampaigns"> -->
        <div class="header-wrapper">
            <nz-page-header nzTitle="Calendar">
                <nz-page-header-extra>
                    <!-- <button nz-button nzType="primary" (click)="this.sharedService.openNew(null)"><span nz-icon
                            nzType="plus" nzTheme="outline"></span>New</button> -->
                    <!-- <button nz-button nzType="primary" routerLink="/assistant/calendar"><span nz-icon nzType="plus"
                            nzTheme="outline"></span>New</button> -->
                    <button nz-button nzType="primary" routerLink="/create"><span nz-icon nzType="plus"
                            nzTheme="outline"></span>New campaign</button>
                </nz-page-header-extra>
            </nz-page-header>
        </div>
        <!-- <div class="left-column">

                    <div class="left-column-content">
                        <nz-timeline>
                            <ng-container *ngFor="let item of emailCampaigns">
                                <nz-timeline-item>
                                    <div class="custom-card">
                                        <div class="custom-card-body">
                                            <div class="timeline-item-date"><span nz-icon nzType="clock-circle" nzTheme="outline"></span>&nbsp;{{item.sendTime | date:'EEEE M/d/YY h:mm a'}}</div>
                                            <div class="timeline-item-title">{{item.title}}</div> 
                                        </div>
                                    </div>
                                    <div class="custom-card hover">
                                        <div class="custom-card-header small">{{item.sendTime |
                                            date:'EEEE
                                            M/d/YY h:mm a'}}</div>
                                        <div class="custom-card-body small">
                                            <div>{{item.title}}</div>
                                            <div style="margin-top: 8px;">{{item.description}}</div>
                                        </div>
                                        <div class="custom-card-footer grey">
                                            <button nz-button nzType="primary"
                                                [routerLink]="['/opportunities',item._id]">Explore</button>
                                        </div>
                                    </div>
                                </nz-timeline-item>
                            </ng-container>

                        </nz-timeline>
                    </div>
                </div> -->
        <div class="content-inner">

            <div class="filter-bar">
                <div class="date-changer">
                    <nz-input-group nzCompact>
                        <button nz-button nzType="default" (click)="decreaseMonth()">
                            <span nz-icon nzType="left"></span>
                        </button>
                        <nz-select style="width: 140px;" [(ngModel)]="this.sharedService.calendarMonth"
                            (ngModelChange)="this.changeDate()" [compareWith]="compareFn">
                            <nz-option nzLabel="January" nzValue="0"></nz-option>
                            <nz-option nzLabel="February" nzValue="1"></nz-option>
                            <nz-option nzLabel="March" nzValue="2"></nz-option>
                            <nz-option nzLabel="April" nzValue="3"></nz-option>
                            <nz-option nzLabel="May" nzValue="4"></nz-option>
                            <nz-option nzLabel="June" nzValue="5"></nz-option>
                            <nz-option nzLabel="July" nzValue="6"></nz-option>
                            <nz-option nzLabel="August" nzValue="7"></nz-option>
                            <nz-option nzLabel="September" nzValue="8"></nz-option>
                            <nz-option nzLabel="October" nzValue="9"></nz-option>
                            <nz-option nzLabel="November" nzValue="10"></nz-option>
                            <nz-option nzLabel="December" nzValue="11"></nz-option>
                        </nz-select>
                        <nz-select style="width: 98px;" [(ngModel)]="this.sharedService.calendarYear"
                            (ngModelChange)="this.changeDate()" [compareWith]="compareFn">
                            <nz-option nzLabel="2023" nzValue="2023"></nz-option>
                            <nz-option nzLabel="2024" nzValue="2024"></nz-option>
                            <nz-option nzLabel="2025" nzValue="2025"></nz-option>
                            <nz-option nzLabel="2026" nzValue="2026"></nz-option>
                            <nz-option nzLabel="2027" nzValue="2027"></nz-option>
                            <nz-option nzLabel="2028" nzValue="2028"></nz-option>
                            <nz-option nzLabel="2029" nzValue="2029"></nz-option>
                            <nz-option nzLabel="2030" nzValue="2030"></nz-option>
                            <nz-option nzLabel="2031" nzValue="2031"></nz-option>
                            <nz-option nzLabel="2032" nzValue="2032"></nz-option>
                        </nz-select>
                        <button nz-button nzType="default" (click)="increaseMonth()">
                            <span nz-icon nzType="right"></span>
                        </button>
                    </nz-input-group>
                </div>
            </div>
            <div class="custom-card-body table-card">
                <div class="calendar-wrapper" style="height: 900px">

                    <div class="calendar-block header">
                        <h3>SUN</h3>
                    </div>
                    <div class="calendar-block header">
                        <h3>MON</h3>
                    </div>
                    <div class="calendar-block header">
                        <h3>TUE</h3>
                    </div>
                    <div class="calendar-block header">
                        <h3>WED</h3>
                    </div>
                    <div class="calendar-block header">
                        <h3>THU</h3>
                    </div>
                    <div class="calendar-block header">
                        <h3>FRI</h3>
                    </div>
                    <div class="calendar-block header">
                        <h3>SAT</h3>
                    </div>
                    <ng-container *ngIf="this.sharedService.loadingCalendar">
                        <div class="loading-wrapper">
                            <div class="lds-ellipsis">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!this.sharedService.loadingCalendar">
                        <ng-container *ngFor="let week of this.sharedService.calendarData">
                            <div class="calendar-row">
                                <ng-container *ngFor="let day of week">

                                    <ng-container *ngIf="day.filler">
                                        <div class="calendar-block" style="background: #f9f9f9;">
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!day.filler">
                                        <!-- <div class="calendar-block hover"> -->
                                        <div class="calendar-block hover" (click)="this.newCampaign.sendTime = day.date">
                                            <div class="calendar-date">{{day.dayOfMonth}}</div>
                                            <!-- <div class="calendar-new-button">
                                                +
                                                New
                                            </div> -->

                                            <ng-container *ngIf="day.items.length > 0">
                                                <ng-container *ngFor="let item of day.items">
                                                    <ng-container *ngIf="item.type == 'SOCIALPOST'">
                                                        <ng-container
                                                            *ngIf="item.stage == 'NEW' || item.stage == 'WAITING' || item.stage == 'INPROGRESS'">
                                                            <div class="calendar-item new">
                                                                <div class="calendar-item-time">
                                                                    <ng-template #indicatorTemplate><span nz-icon
                                                                            style="height: 10px !important; width: 10px !important;"
                                                                            nzType="loading"></span></ng-template>
                                                                    <div class="calendar-item-title">
                                                                        <nz-spin
                                                                            style="display: inline; margin-right: 24px;"
                                                                            [nzIndicator]="indicatorTemplate"></nz-spin>Generating
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="item.stage == 'PENDING'">
                                                            <div class="calendar-item" [routerLink]="['/post',item._id]">
                                                                <div class="calendar-item-title">
                                                                    <nz-tag style="margin-right: 4px;"><span nz-icon
                                                                            nzType="share-alt"
                                                                            nzTheme="outline"></span></nz-tag>
                                                                    <nz-tag>Draft</nz-tag>{{item.title}}
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="item.stage == 'PLACEHOLDER'">
                                                            <div class="calendar-item new"
                                                                [routerLink]="['/post',item._id]">
                                                                <div class="calendar-item-title">
                                                                    <nz-tag style="margin-right: 4px;"
                                                                        class="aqua"><span nz-icon nzType="share-alt"
                                                                            nzTheme="outline"></span></nz-tag>
                                                                    {{item.title}}
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="item.stage == 'LIVE' && sharedService.timeInFuture(item.sendTime)">
                                                            <div class="calendar-item" [routerLink]="['/post',item._id]">
                                                                <div class="calendar-item-title">
                                                                    <nz-tag style="margin-right: 4px;"
                                                                        class="aqua"><span nz-icon nzType="share-alt"
                                                                            nzTheme="outline"></span></nz-tag>
                                                                    <nz-tag
                                                                        class="aqua">Scheduled</nz-tag>{{item.title}}
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="item.stage == 'LIVE' && !sharedService.timeInFuture(item.sendTime)">
                                                            <div class="calendar-item" [routerLink]="['/post',item._id]">
                                                                <div class="calendar-item-title">
                                                                    <nz-tag style="margin-right: 4px;"
                                                                        class="aqua"><span nz-icon nzType="share-alt"
                                                                            nzTheme="outline"></span></nz-tag>
                                                                    <nz-tag class="aqua">Sent</nz-tag>{{item.title}}
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container *ngIf="item.type == 'SMS'">
                                                        <ng-container
                                                            *ngIf="item.stage == 'NEW' || item.stage == 'WAITING' || item.stage == 'INPROGRESS'">
                                                            <div class="calendar-item new">
                                                                <div class="calendar-item-time">
                                                                    <ng-template #indicatorTemplate><span nz-icon
                                                                            style="height: 10px !important; width: 10px !important;"
                                                                            nzType="loading"></span></ng-template>
                                                                    <div class="calendar-item-title">
                                                                        <nz-spin
                                                                            style="display: inline; margin-right: 24px;"
                                                                            [nzIndicator]="indicatorTemplate"></nz-spin>Generating
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="item.stage == 'PENDING'">
                                                            <div class="calendar-item" [routerLink]="['/sms',item._id]">
                                                                <div class="calendar-item-title">
                                                                    <nz-tag style="margin-right: 4px;"><span nz-icon
                                                                            nzType="message"
                                                                            nzTheme="outline"></span></nz-tag>
                                                                    <nz-tag>Draft</nz-tag>{{item.title}}
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="item.stage == 'PLACEHOLDER'">
                                                            <div class="calendar-item new"
                                                                [routerLink]="['/sms',item._id]">
                                                                <div class="calendar-item-title">
                                                                    <nz-tag style="margin-right: 4px;"
                                                                        class="aqua"><span nz-icon nzType="message"
                                                                            nzTheme="outline"></span></nz-tag>
                                                                    {{item.title}}
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="item.stage == 'LIVE' && sharedService.timeInFuture(item.sendTime)">
                                                            <div class="calendar-item" [routerLink]="['/sms',item._id]">
                                                                <div class="calendar-item-title">
                                                                    <nz-tag style="margin-right: 4px;"
                                                                        class="aqua"><span nz-icon nzType="message"
                                                                            nzTheme="outline"></span></nz-tag>
                                                                    <nz-tag
                                                                        class="aqua">Scheduled</nz-tag>{{item.title}}
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="item.stage == 'LIVE' && !sharedService.timeInFuture(item.sendTime)">
                                                            <div class="calendar-item" [routerLink]="['/sms',item._id]">
                                                                <div class="calendar-item-title">
                                                                    <nz-tag style="margin-right: 4px;"
                                                                        class="aqua"><span nz-icon nzType="message"
                                                                            nzTheme="outline"></span></nz-tag>
                                                                    <nz-tag class="aqua">Sent</nz-tag>{{item.title}}
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container *ngIf="item.type == 'CAMPAIGN'">
                                                        <ng-container
                                                            *ngIf="item.stage == 'NEW' || item.stage == 'WAITING' || item.stage == 'INPROGRESS'">
                                                            <div class="calendar-item new">
                                                                <div class="calendar-item-time">
                                                                    <ng-template #indicatorTemplate><span nz-icon
                                                                            style="height: 10px !important; width: 10px !important;"
                                                                            nzType="loading"></span></ng-template>
                                                                    <div class="calendar-item-title">
                                                                        <nz-spin
                                                                            style="display: inline; margin-right: 24px;"
                                                                            [nzIndicator]="indicatorTemplate"></nz-spin>Generating
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="item.stage == 'PENDING'">
                                                            <div class="calendar-item" [routerLink]="['/opportunities',item._id]">
                                                                <div class="calendar-item-title">
                                                                    <nz-tag style="margin-right: 4px;"><span nz-icon
                                                                            nzType="mail"
                                                                            nzTheme="outline"></span></nz-tag>
                                                                    <nz-tag>Draft</nz-tag>{{item.title}}
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="item.stage == 'PLACEHOLDER'">
                                                            <div class="calendar-item new"
                                                                [routerLink]="['/opportunities',item._id]">
                                                                <div class="calendar-item-title">
                                                                    <nz-tag style="margin-right: 4px;"
                                                                        class="aqua"><span nz-icon nzType="mail"
                                                                            nzTheme="outline"></span></nz-tag>
                                                                    {{item.title}}
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="item.stage == 'LIVE' && sharedService.timeInFuture(item.sendTime)">
                                                            <div class="calendar-item" [routerLink]="['/opportunities',item._id]">
                                                                <div class="calendar-item-title">
                                                                    <nz-tag style="margin-right: 4px;"
                                                                        class="aqua"><span nz-icon nzType="mail"
                                                                            nzTheme="outline"></span></nz-tag>
                                                                    <nz-tag
                                                                        class="aqua">Scheduled</nz-tag>{{item.title}}
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="item.stage == 'LIVE' && !sharedService.timeInFuture(item.sendTime)">
                                                            <div class="calendar-item" [routerLink]="['/opportunities',item._id]">
                                                                <div class="calendar-item-title">
                                                                    <nz-tag style="margin-right: 4px;"
                                                                        class="aqua"><span nz-icon nzType="mail"
                                                                            nzTheme="outline"></span></nz-tag>
                                                                    <nz-tag class="aqua">Sent</nz-tag>{{item.title}}
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-template #contentTemplate>
                                                        <div style="width:800px; height: 700px;">
                                                            <app-email-renderer [mode]="'VIEW'"></app-email-renderer>
                                                        </div>
                                                    </ng-template>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #contentTemplate>
    <div style="width: 250px">
        <button nz-button nzBlock style="text-align: left; height: 48px; margin-bottom: 8px;"><span
                class="lni lni-bubble" style="float: left; margin-top: 4px;"></span>&nbsp;&nbsp;Planning
            assistant</button>
        <button nz-button nzBlock style="text-align: left; height: 48px;"><span class="lni lni-envelope"
                style="float: left; margin-top: 4px;"></span>&nbsp;&nbsp;New email</button>
    </div>
</ng-template>

<ng-template #popoverTemplate>
    <div style="width: 225px;">
        {{this.sharedService.rendererOpportunityObject.contentTemplate.subjectLine}}
        <div style="margin-top: 8px;">
            <nz-tag class="aqua">Scheduled</nz-tag>
            <div style="float: right; line-height: 26px;">
                {{this.sharedService.rendererOpportunityObject.sendTime
                | date:'shortTime'}}
            </div>
        </div>
        <ng-container *ngIf="this.sharedService.rendererOpportunityObject.thumbnail">
            <div class="email-thumbnail" style="margin-top: 8px;"
                [ngStyle]="{'background-image': 'url(' + this.sharedService.rendererOpportunityObject.thumbnail + ')'}">
            </div>
        </ng-container>
        <ng-container *ngIf="!this.sharedService.rendererOpportunityObject.thumbnail">
            <div class="email-thumbnail"
                style="background: #f9f9f9; text-align: center; font-size: 48px; padding-top: 65px; color: #d9d9d9; margin-top: 8px;">
                <i class="lni lni-image"></i>
            </div>
        </ng-container>
        <!-- <div nz-row [nzGutter]="16" style="height: 100%;">
            <div nz-col class="gutter-row" [nzSpan]="10">

            </div>
            <div nz-col class="gutter-row" [nzSpan]="14" style="height: 100%; overflow-y:hidden">

                <nz-form-item style="margin-bottom: 8px !important">
                    <nz-form-label style="padding-bottom: 4px !important" [nzNoColon]="true" nzLabelAlign="left"
                        nzSpan="24">
                        Included segments
                    </nz-form-label>
                    <nz-form-control nzSpan="24">
                        <ng-container
                            *ngFor="let segment of this.sharedService.rendererOpportunityObject.audienceInclude">
                            <nz-tag>{{segment.name}}</nz-tag>
                        </ng-container>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item style="margin-bottom: 8px !important">
                    <nz-form-label style="padding-bottom: 4px !important" [nzNoColon]="true" nzLabelAlign="left"
                        nzSpan="24">
                        Included segments
                    </nz-form-label>
                    <nz-form-control nzSpan="24">
                        <ng-container
                            *ngFor="let segment of this.sharedService.rendererOpportunityObject.audienceExclude">
                            <nz-tag>{{segment.name}}</nz-tag>
                        </ng-container>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div> -->
    </div>
</ng-template>


<ng-template #contentTemplate2>
    <div style="width: 350px; margin: -12px -16px; padding: 12px 16px; border-bottom: 1px solid #E5E7EB;">
        New campaign
        <button nz-button nzType="primary" (click)="savePlaceholder()" style="float: right;
        margin-top: -4px;
        padding: 3px 8px;
        height: 30px;">Save</button>
    </div>
    <div style="width: 350px; margin: 12px -16px -12px; padding: 12px 16px;">
        <nz-form-item style="margin-bottom: 8px !important">
            <nz-form-label style="padding-bottom: 4px !important" [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                Type
            </nz-form-label>
            <nz-form-control nzSpan="24">
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a type" style="width: 100%;"
                    [(ngModel)]="newCampaign.template">
                    <ng-container *ngIf="this.emailTemplates && this.emailTemplates.length > 0">
                        <nz-option-group nzLabel="Your types">
                            <ng-container *ngFor="let template of this.emailTemplates">
                                <nz-option [nzLabel]="template.title" [nzValue]="template"></nz-option>
                            </ng-container>
                        </nz-option-group>
                        <nz-option-group nzLabel="Ripple types">
                            <ng-container *ngFor="let emailType of this.sharedService.emailTypes">
                                <nz-option [nzLabel]="emailType.name" [nzValue]="emailType"></nz-option>
                            </ng-container>
                        </nz-option-group>
                    </ng-container>
                    <ng-container *ngIf="!this.emailTemplates || this.emailTemplates.length == 0">
                        <ng-container *ngFor="let emailType of this.sharedService.emailTypes">
                            <nz-option [nzLabel]="emailType.name" [nzValue]="emailType"></nz-option>
                        </ng-container>
                    </ng-container>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item style="margin-bottom: 8px !important">
            <nz-form-label style="padding-bottom: 4px !important" [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                Segments
            </nz-form-label>
            <nz-form-control nzSpan="24">
                <nz-select nzPlaceHolder="Select segments" [compareWith]="compareFnSegments"
                    [(ngModel)]="newCampaign.audienceInclude" name="segment" nzShowSearch nzMode="multiple">
                    <ng-container *ngFor="let segment of klaviyoListsAndSegments">
                        <nz-option nzCustomContent [nzLabel]="segment.attributes.name" [nzValue]="segment">
                            {{segment.attributes.name}}
                            <nz-tag style="float: right" class="aqua">{{segment.type
                                |
                                titlecase}}</nz-tag>
                        </nz-option>
                    </ng-container>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item style="margin-bottom: 8px !important">
            <nz-form-label style="padding-bottom: 4px !important" [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                Topic
            </nz-form-label>
            <nz-form-control nzSpan="24">
                <textarea [nzAutosize]="{ minRows: 2, maxRows: 15 }" nz-input placeholder="Ex: Summer sale"
                    [(ngModel)]="newCampaign.title" name="title"></textarea>
            </nz-form-control>
        </nz-form-item>
        <!-- <div class="popover-footer">
            <button nz-button>Try again</button>
        </div> -->
        <!-- <div style="text-align: right">
            <button nzType="primary" nz-button nz-popconfirm
            nzPopconfirmTitle="This will overwrite any changes you've made in the editor"
            (nzOnConfirm)="sendSubjectLineFeedback()"
            nzPopconfirmPlacement="bottomRight">Send</button>
        </div> -->
    </div>
</ng-template>

<!-- <div class="page-wrapper">
    <div class="left-column">
        <div class="left-column-content">
            <nz-page-header class="site-page-header" nzTitle="New">
                <nz-breadcrumb nz-page-header-breadcrumb>
                    <nz-breadcrumb-item>Opportunities</nz-breadcrumb-item>
                    <nz-breadcrumb-item>New</nz-breadcrumb-item>
                </nz-breadcrumb>
            </nz-page-header>
            <ng-container *ngFor="let opportunity of opportunities">
                <nz-card nzHoverable>{{opportunity.title}}</nz-card>
            </ng-container>
        </div>
    </div>
    <div class="right-column">

    </div>
</div> -->