<div class="page-wrapper">
    <div class="header-wrapper">
        <nz-page-header style="padding-bottom: 6px !important" nzTitle="SMS">
            <nz-page-header-extra>
                <button nz-button nzType="primary" [routerLink]="['/new']"><span nz-icon nzType="plus"
                        nzTheme="outline"></span>New</button>
            </nz-page-header-extra>
        </nz-page-header>
    </div>
    <div class="content-wrapper">
        <div class="column-left">
            <nz-page-header style="padding-bottom: 6px !important" nzTitle="Analytics">
                <nz-page-header-extra>
                    <button nz-button nzType="link" [routerLink]="['/new']">See more</button>
                </nz-page-header-extra>
            </nz-page-header>
            <div class="custom-card">
                <div class="custom-card-body"></div>
            </div>
            <nz-page-header style="padding-bottom: 6px !important" nzTitle="Calendar">
                <nz-page-header-extra>
                    <button nz-button nzType="link" [routerLink]="['/new']">See more</button>
                </nz-page-header-extra>
            </nz-page-header>
            <div class="custom-card">
                <div class="custom-card-body"></div>
            </div>
            <nz-page-header style="padding-bottom: 6px !important" nzTitle="Drafts">
                <nz-page-header-extra>
                    <button nz-button nzType="link" [routerLink]="['/new']">See more</button>
                </nz-page-header-extra>
            </nz-page-header>
            <div class="custom-card">
                <div class="custom-card-body"></div>
            </div>

            <!-- <ul nz-menu nzMode="horizontal" style="margin-bottom: 16px !important;">
                <li nz-menu-item [routerLink]="['/opportunities/new']">
                    New
                </li>
                <li nz-menu-item nzSelected>
                    Pending
                </li>
                <li nz-menu-item>
                    Live
                </li>
            </ul> -->
            <!-- <div class="custom-card">
                <div class="header-row">
                    <div nz-row [nzGutter]="16">
                        <div nz-col class="gutter-row" [nzSpan]="15">
                            <div class="inner-box">Title</div>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="5">
                            <div class="inner-box">Type</div>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="4">
                            <div class="inner-box">Updated</div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="this.loading">
                    <div nz-row [nzGutter]="16" class="table-row" style="padding-top: 16px;">
                        <div nz-col class="gutter-row" [nzSpan]="15">
                            <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="5">
                            <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="4">
                            <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                        </div>
                    </div>
                    <div nz-row [nzGutter]="16" class="table-row" style="padding-top: 16px;">
                        <div nz-col class="gutter-row" [nzSpan]="15">
                            <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="5">
                            <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="4">
                            <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                        </div>
                    </div>
                    <div nz-row [nzGutter]="16" class="table-row" style="padding-top: 16px;">
                        <div nz-col class="gutter-row" [nzSpan]="15">
                            <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="5">
                            <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="4">
                            <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                        </div>
                    </div>
                    <div nz-row [nzGutter]="16" class="table-row" style="padding-top: 16px;">
                        <div nz-col class="gutter-row" [nzSpan]="15">
                            <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="5">
                            <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="4">
                            <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                        </div>
                    </div>

                </ng-container>
                <ng-container *ngFor="let opportunity of opportunities">
                    <div nz-row [nzGutter]="16" class="table-row" [routerLink]="['/editor',opportunity._id]">
                        <div nz-col class="gutter-row" [nzSpan]="15">
                            <div style="font-weight: 500; text-overflow: ellipsis; overflow: hidden;
                            white-space: nowrap;">{{opportunity.title}}</div>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="5">
                            <ng-container *ngIf="opportunity.template == 'FEATUREDPRODUCT'">
                                <nz-tag>Featured product</nz-tag>
                            </ng-container>
                            <ng-container *ngIf="opportunity.template == 'EDUCATIONAL'">
                                <nz-tag>Educational</nz-tag>
                            </ng-container>
                            <ng-container *ngIf="opportunity.template == 'FEATUREDCATEGORY'">
                                <nz-tag>Featured category</nz-tag>
                            </ng-container>

                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="4">
                            {{opportunity.updatedAt | date}}
                        </div>
                    </div>
                </ng-container>
            </div> -->

        </div>
        <div class="column-right">
            <nz-page-header style="padding-bottom: 6px !important" class="site-page-header">
                <nz-page-header-title>Suggestions</nz-page-header-title>
                <!-- <nz-breadcrumb nz-page-header-breadcrumb>
                    <nz-breadcrumb-item>Opportunities</nz-breadcrumb-item>
                    <nz-breadcrumb-item>Pending</nz-breadcrumb-item>
                </nz-breadcrumb> -->
                <nz-page-header-extra>
                    <button nz-button nzType="link" [routerLink]="['/new']">See more</button>
                </nz-page-header-extra>
            </nz-page-header>
            <ng-container *ngFor="let opp of this.newOpportunities">
                <div class="custom-card hover" [routerLink]="['/opportunities',opp._id]">
                    <div class="custom-card-body mini">
                        <div class="new-opp-title">{{opp.title}}</div>
                        <ng-container *ngIf="opp.template == 'FEATUREDPRODUCT'">
                            <nz-tag>Featured product</nz-tag>
                        </ng-container>
                        <ng-container *ngIf="opp.template == 'EDUCATIONAL'">
                            <nz-tag>Educational</nz-tag>
                        </ng-container>
                        <ng-container *ngIf="opp.template == 'FEATUREDCATEGORY'">
                            <nz-tag>Featured category</nz-tag>
                        </ng-container>
                        <div class="new-opp-body">{{opp.description}}</div>
                    </div>
                </div>
            </ng-container>
        </div>


    </div>
</div>