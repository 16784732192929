<div class="page-wrapper">
    <div class="content-wrapper">
        <nz-page-header style="padding-bottom: 6px !important" class="site-page-header" nzTitle="Opportunities">
            <!-- <nz-breadcrumb nz-page-header-breadcrumb>
                <nz-breadcrumb-item>Opportunities</nz-breadcrumb-item>
                <nz-breadcrumb-item>Pending</nz-breadcrumb-item>
            </nz-breadcrumb> -->
        </nz-page-header>
        <ul nz-menu nzMode="horizontal" style="margin-bottom: 16px !important;">
            <li nz-menu-item [routerLink]="['/opportunities/new']">
                New
            </li>
            <li nz-menu-item nzSelected>
                Pending
            </li>
            <li nz-menu-item>
                Live
            </li>
        </ul>
        <div class="header-row">
            <div nz-row [nzGutter]="16">
                <div nz-col class="gutter-row" [nzSpan]="12">
                    <div class="inner-box">Title</div>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="5">
                    <div class="inner-box">Status</div>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <div class="inner-box">Updated</div>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="3">
                    <div class="inner-box"></div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="this.loading">
            <div class="custom-card">
                <div class="custom-card-body">
                    <nz-skeleton [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
            </div>
        </ng-container>
        <ng-container *ngFor="let opportunity of opportunities">
            <div class="custom-card">
                <div class="custom-card-body" style="line-height: 32px;">
                    <div nz-row [nzGutter]="16">
                        <div nz-col class="gutter-row" [nzSpan]="12">
                            <div class="inner-box">{{opportunity.title}}</div>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="5">
                            <div class="inner-box"></div>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="4">
                            <div class="inner-box"></div>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="3">
                            <div class="inner-box"> <button nz-button nzType="default" style="float: right"
                                    [routerLink]="['/opportunities',opportunity._id]">View</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

    </div>
</div>