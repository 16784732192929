<router-outlet></router-outlet>

<div class="custom-modal-wrapper" *ngIf="sharedService.pickerOpen">
    <div class="custom-modal-inner" style="max-width: 1100px;">
        <app-picker></app-picker>
    </div>
</div>

<div class="custom-modal-wrapper" *ngIf="sharedService.collectionPickerOpen">
    <div class="custom-modal-inner" style="max-width: 1100px;">
        <app-collection-picker></app-collection-picker>
    </div>
</div>

<div class="custom-modal-wrapper" *ngIf="sharedService.blockLibraryOpen">
    <div class="custom-modal-inner" style="width: 600px; height: auto; overflow-y: hidden">
        <app-block-library></app-block-library>
    </div>
</div>

<div class="custom-modal-wrapper" *ngIf="sharedService.blockLibraryV2Open" style="padding: 0px !important;">
    <div class="custom-modal-inner" style="width: calc(100% - 48px); height: calc(100% - 48px); overflow-y: hidden; max-width: 1400px">
        <app-block-library-v2></app-block-library-v2>
    </div>
</div>

<div class="custom-modal-wrapper" *ngIf="sharedService.publishingEmailOpen">
    <div class="custom-modal-inner loading">
        <div class="dot"></div>
        <div class="loading-title">Publishing...</div>
    </div>
</div>

<div class="custom-modal-wrapper" *ngIf="sharedService.databaseEditorOpen">
    <!-- <div class="custom-modal-inner loading">
        <div class="dot"></div>
        <div class="loading-title">Publishing...</div>
    </div> -->
    <div class="custom-modal-inner">
        <app-database-editor></app-database-editor>
    </div>
</div>

<div class="custom-modal-wrapper" *ngIf="sharedService.inviteUserOpen">
    <!-- <div class="custom-modal-inner loading">
        <div class="dot"></div>
        <div class="loading-title">Publishing...</div>
    </div> -->
    <div class="custom-modal-inner" style="width: 600px; height: auto; margin-top: 100px;">
        <app-invite-user></app-invite-user>
    </div>
</div>

<!-- <div class="custom-modal-wrapper" *ngIf="sharedService.newOpen">
    <div class="custom-modal-inner">
        <app-create-opp-new></app-create-opp-new>
    </div>
</div> -->

<nz-drawer [nzClosable]="true" [nzVisible]="sharedService.newOpen" nzPlacement="right" nzTitle="New"
    (nzOnClose)="sharedService.newOpen = false" [nzWidth]="'600px'">
    <ng-container *nzDrawerContent>
        <app-create-opp-new></app-create-opp-new>
        <!-- <app-email-chat></app-email-chat> -->
    </ng-container>
</nz-drawer>

<div class="custom-modal-wrapper" *ngIf="sharedService.inviteUserOpen">
    <div class="custom-modal-inner" style="width: 600px; height: auto; margin-top: 100px;">
        <app-invite-user></app-invite-user>
    </div>
</div>

<div class="custom-modal-wrapper" *ngIf="sharedService.editImageOpen">
    <div class="custom-modal-inner" style="width: 600px; height: auto; margin-top: 100px;">
        <app-edit-image></app-edit-image>
    </div>
</div>

<div class="custom-modal-wrapper" *ngIf="sharedService.editImageBackgroundOpen">
    <div class="custom-modal-inner" style="width: 1100px; height: auto; margin-top: 100px; max-width: 1100px;">
        <app-edit-image-background></app-edit-image-background>
    </div>
</div>

<!-- <div class="custom-modal-wrapper" *ngIf="sharedService.htmlOpen">
    <div class="custom-modal-inner">
        <app-view-html></app-view-html>
    </div>
</div> -->

<nz-drawer [nzClosable]="true" [nzVisible]="sharedService.htmlOpen" nzPlacement="right" nzTitle="HTML"
    (nzOnClose)="sharedService.htmlOpen = false" [nzWidth]="'600px'">
    <ng-container *nzDrawerContent>
        <app-view-html></app-view-html>
    </ng-container>
</nz-drawer>

<div class="custom-modal-wrapper" *ngIf="sharedService.reviewPickerOpen">
    <div class="custom-modal-inner" style="max-width: 1000px;">
        <app-review-picker></app-review-picker>
    </div>
</div>

<div class="custom-modal-wrapper" *ngIf="sharedService.aiModalLoading">
    <div class="custom-modal-inner"
        style="max-width: 300px; text-align: center; font-weight: 500; color: #767676; height: 250px; padding-top: 32px; margin-top: 96px;">
        <img src="../../assets/magicWand.gif" style="width: 125px; height: 125px; margin: auto; margin-bottom: 16px;" />
        {{this.sharedService.aiModalMessage}}
    </div>
</div>

<div class="custom-modal-wrapper" *ngIf="sharedService.newFlowFolderOpen">
    <div class="custom-modal-inner" style="width: 500px; height: auto; margin-top: 100px;">
        <app-create-flow-folder></app-create-flow-folder>
    </div>
</div>

<div class="custom-modal-wrapper" *ngIf="sharedService.sendTestEmailOpen">
    <div class="custom-modal-inner" style="width: 500px; height: auto; margin-top: 100px;">
        <app-send-test-email></app-send-test-email>
    </div>
</div>

<div class="custom-modal-wrapper" *ngIf="sharedService.createTemplateOpen">
    <div class="custom-modal-inner" style="width: 500px; height: auto; margin-top: 100px;">
        <app-create-template></app-create-template>
    </div>
</div>