import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ApiService } from 'src/app/api.service';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-settings-team',
  templateUrl: './settings-team.component.html',
  styleUrls: ['./settings-team.component.scss']
})
export class SettingsTeamComponent {

  loading = true;
  saving = false;
  workspace: any = null;

  klaviyoObject: any = {
    accessToken: '',
    type: "KLAVIYO"
  };

  shopifyObject: any = {
    accessToken: '',
    type: "SHOPIFY"
  };

  navbarLinksTemplate = {
    name: "Navbar links",
    type: "DATALIST",
    category: "CONTENT",
    value: [
    ],
    dataTemplate: [
      {
        name: "Title",
        type: "INPUT",
        category: "CONTENT",
        value: "",
      },
      {
        name: "Link",
        type: "INPUT",
        category: "CONTENT",
        value: "",
      },
    ]
  };

  footerLinksTemplate = {
    name: "Social links",
    type: "DATALIST",
    category: "CONTENT",
    value: [
    ],
    dataTemplate: [
      {
        name: "Platform",
        type: "SELECT",
        category: "CONTENT",
        value: {
          title: "Instagram",
          value: "instagram"
        },
        options: [
          {
            title: "Instagram",
            value: "instagram"
          },
          {
            title: "Facebook",
            value: "facebook"
          },
          {
            title: "Twitter",
            value: "twitter"
          }
        ]
      },
      {
        name: "Link",
        type: "INPUT",
        category: "CONTENT",
        value: "",
      },
    ]
  };

  brandImagesTemplare = {
    name: "Images",
    type: "DATALIST",
    category: "CONTENT",
    value: [
    ],
    dataTemplate: [
      {
        name: "Image",
        type: "IMAGESELECT",
        value: "",
      }
    ]
  };

  integrations: any = [];

  constructor(
    public apiService: ApiService,
    public sharedService: SharedService,
    public router: Router,
    public message: NzMessageService
  ) { }

  ngOnInit() {
    this.apiService.getWorkspaceUsers().subscribe(
      (data: any) => {
        console.log(data);
        this.integrations = data;
        this.loading = false;
      },
      error => {
        console.log(error);
      });
  }

  openPicker(property: any, mode: any) {
    console.log("Open Picker");
    this.sharedService.currentProperty = property;
    this.sharedService.pickerMode = mode;
    this.sharedService.pickerOpen = true;
  }

  saveBrandStyles() {
    this.saving = true;
    this.apiService.saveBrandStyles(this.workspace.brandStyles).subscribe(
      (data: any) => {
        console.log(data);
        this.saving = false;
      },
      error => {
        console.log(error);
      });
  }

  compareFn = (o1: any, o2: any): boolean => (o1 && o2 ? o1 === o2 : o1 === o2);

  compareFnSocialLinks = (o1: any, o2: any): boolean => (o1 && o2 ? o1.value === o2.value : o1 === o2);


  pushDataListValue(property: any) {
    property.value.push(JSON.parse(JSON.stringify(property.dataTemplate)));
  }

  createIntegration(integrationObject: any) {
    this.apiService.newIntegration(integrationObject).subscribe(
      (data: any) => {
          // login successful if there's a jwt token in the response
          console.log(data);
          this.router.navigate([`brand/integrations/${data._id}`]);
      },
      error => {
          console.log(error);
      });
  }

  copyInviteUrl(user: any) {
    if (user?.status == 'PENDING') {
      let url = `http://app.getripple.ai/invite/${user._id}`;
      navigator.clipboard.writeText(url);
      this.message.create("success", `Invite link copied to clipboard`);
    }
  }
}


