<div *ngIf="data">
    <!-- <ng-container *ngIf="this.sharedService.activeComponent?.key">
        <ng-container *ngIf="this.sharedService.activeComponent?.key == data.key">
            <div class="custom-card">Test</div>
        </ng-container>
    </ng-container> -->
    <ng-template #contentTemplate>
        <button [nzDropdownMenu]="menu" nz-dropdown nzPlacement="bottomRight" nz-button style="margin-right: 4px;"
            nzType="text"><span nz-icon nzType="plus" nzTheme="outline"></span></button>
        <button nz-button style="margin-right: 4px;" nzType="text"
            (click)="this.duplicateComponent(data.key, data)"><span nz-icon nzType="copy"
                nzTheme="outline"></span></button>
        <button nz-button nzType="text" (click)="deleteComponent(data.key)"><span nz-icon nzType="delete"
                nzTheme="outline"></span></button>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
                <li nz-menu-item (click)="this.addText(data.key)">Text</li>
                <li nz-menu-item (click)="this.addImage(data.key)">Image</li>
                <li nz-menu-item (click)="this.addButton(data.key)">Button</li>
                <li nz-menu-item (click)="this.addContainer(data.key)">Container</li>
                <li nz-menu-item (click)="this.addProduct(data.key)">Product</li>
                <li nz-menu-item (click)="this.addReview(data.key)">Review</li>
            </ul>
        </nz-dropdown-menu>
    </ng-template>
    <!-- BLOCK Component -->
    <ng-container *ngIf="data.type == 'BLOCK'">
        <!-- <div [style.paddingTop]="sharedService.getProperty('padding', data.properties)?.paddingTop"
            [style.paddingBottom]="sharedService.getProperty('padding', data.properties)?.paddingBottom"
            [style.paddingLeft]="sharedService.getProperty('padding', data.properties)?.paddingLeft"
            [style.paddingRight]="sharedService.getProperty('padding', data.properties)?.paddingRight"
            [style.backgroundColor]="sharedService.getProperty('background', data.properties)?.mode === 'solid' ? sharedService.getProperty('background', data.properties)?.value : null"
            [style.backgroundImage]="sharedService.getProperty('background', data.properties)?.mode === 'image' ? 'url(' + sharedService.getProperty('background', data.properties)?.value + ')' : null"
            [style.background-size]="sharedService.getProperty('background', data.properties)?.mode === 'image' ? sharedService.getProperty('background', data.properties)?.backgroundSize : null"
            [style.background-position]="sharedService.getProperty('background', data.properties)?.mode === 'image' ? sharedService.getProperty('background', data.properties)?.backgroundPosition : null"
            [style.borderRadius]="sharedService.getProperty('borderRadius', data.properties)?.value"
            [style.borderColor]="sharedService.getProperty('border', data.properties)?.borderColor"
            [style.borderWidth]="sharedService.getProperty('border', data.properties)?.borderWidth"
            [style.borderStyle]="sharedService.getProperty('border', data.properties)?.borderStyle"
            [style.text-align]="sharedService.getProperty('textAlign', data.properties)?.value"
            [style.width]="sharedService.getProperty('width', data.properties)?.mode === 'fixed' ? sharedService.getProperty('width', data.properties)?.value : null"
            [style.float]="sharedService.getProperty('width', data.properties)?.mode === 'fixed' ? 'left' : null"
            [ngStyle]="sharedService.getProperty('styles', data.properties)?.value" style="overflow: hidden;"
            class="rtl-container" [ngStyle]="this.sharedService.activeComponent?.key === data.key ? activeStyles : {}"
            nz-popover [nzPopoverVisible]="checkIfActive()" nzPopoverTrigger="click"
            [nzPopoverContent]="contentTemplate">
            <div *ngIf="data.children && data.children.length > 0">
                <app-component-renderer *ngFor="let child of data.children" [data]="child" [childData]="childData"
                    (click)="this.sharedService.setActiveComponent($event, child)"></app-component-renderer>
            </div>
        </div> -->
        <div [style.paddingTop]="data.props?.paddingTop" [style.paddingBottom]="data.props?.paddingBottom"
            [style.paddingLeft]="data.props?.paddingLeft" [style.paddingRight]="data.props?.paddingRight"
            [style.backgroundColor]="data.props?.backgroundMode === 'solid' ? data.props?.backgroundColor : null"
            [style.backgroundImage]="data.props?.backgroundMode === 'image' ? 'url(' + data.imageUrl + ')' : null"
            [style.background-size]="data.props?.backgroundMode === 'image' ? data.props?.backgroundSize : null"
            [style.background-position]="data.props?.backgroundMode === 'image' ? data.props?.backgroundPosition : null"
            [style.borderRadius]="data.props?.borderRadius"
            [style.borderColor]="data.props?.border ? data.props?.borderColor : null"
            [style.borderWidth]="data.props?.border ? data.props?.borderWidth + 'px' : null"
            [style.borderStyle]="data.props?.border ? data.props?.borderStyle : null"
            [style.boxShadow]="data.props?.shadow ? data.props?.shadowDepth + 'px ' +  data.props?.shadowDepth + 'px ' + data.props?.shadowBlur + 'px ' + data.props?.shadowColor: null"
            [style.text-align]="data.props?.textAlign"
            [style.width]="data.props?.widthMode === 'fixed' ? data.props?.width : null"
            [style.float]="data.props?.widthMode === 'fixed' ? 'left' : null" [ngStyle]="data.props?.customCss"
            class="rtl-container" [ngStyle]="this.sharedService.activeComponent?.key === data.key ? activeStyles : {}"
            nz-popover [nzPopoverVisible]="checkIfActive()" nzPopoverTrigger="click"
            [nzPopoverContent]="contentTemplate">
            <div *ngIf="data.children && data.children.length > 0">
                <app-component-renderer *ngFor="let child of data.children" [data]="child" [childData]="childData"
                    (click)="this.sharedService.setActiveComponent($event, child)"></app-component-renderer>
            </div>
        </div>
    </ng-container>

    <!-- CONTAINER Component -->
    <ng-container *ngIf="data.type == 'CONTAINER'">
        <!-- <div [style.paddingTop]="sharedService.getProperty('padding', data.properties)?.paddingTop"
            [style.paddingBottom]="sharedService.getProperty('padding', data.properties)?.paddingBottom"
            [style.paddingLeft]="sharedService.getProperty('padding', data.properties)?.paddingLeft"
            [style.paddingRight]="sharedService.getProperty('padding', data.properties)?.paddingRight"
            [style.backgroundColor]="sharedService.getProperty('background', data.properties)?.mode === 'solid' ? sharedService.getProperty('background', data.properties)?.value : null"
            [style.backgroundImage]="sharedService.getProperty('background', data.properties)?.mode === 'image' ? 'url(' + sharedService.getProperty('background', data.properties)?.value + ')' : null"
            [style.background-size]="sharedService.getProperty('background', data.properties)?.mode === 'image' ? sharedService.getProperty('background', data.properties)?.backgroundSize : null"
            [style.background-position]="sharedService.getProperty('background', data.properties)?.mode === 'image' ? sharedService.getProperty('background', data.properties)?.backgroundPosition : null"
            [style.borderRadius]="sharedService.getProperty('borderRadius', data.properties)?.value"
            [style.borderColor]="sharedService.getProperty('border', data.properties)?.borderColor"
            [style.borderWidth]="sharedService.getProperty('border', data.properties)?.borderWidth"
            [style.borderStyle]="sharedService.getProperty('border', data.properties)?.borderStyle"
            [style.text-align]="sharedService.getProperty('textAlign', data.properties)?.value"
            [style.width]="sharedService.getProperty('width', data.properties)?.mode === 'fixed' ? sharedService.getProperty('width', data.properties)?.value : null"
            [style.float]="sharedService.getProperty('width', data.properties)?.mode === 'fixed' ? 'left' : null"
            [ngStyle]="sharedService.getProperty('styles', data.properties)?.value" style="overflow: hidden;"
            class="rtl-container" [ngStyle]="this.sharedService.activeComponent?.key === data.key ? activeStyles : {}"
            nz-popover [nzPopoverVisible]="checkIfActive()" nzPopoverTrigger="click"
            [nzPopoverContent]="contentTemplate">
            <div *ngIf="data.children && data.children.length > 0">
                <app-component-renderer *ngFor="let child of data.children" [data]="child" [childData]="childData"
                    (click)="this.sharedService.setActiveComponent($event, child)"></app-component-renderer>
            </div>
        </div> -->
        <div [style.paddingTop]="data.props?.paddingTop" [style.paddingBottom]="data.props?.paddingBottom"
            [style.paddingLeft]="data.props?.paddingLeft" [style.paddingRight]="data.props?.paddingRight"
            [style.backgroundColor]="data.props?.backgroundMode === 'solid' ? data.props?.backgroundColor : null"
            [style.backgroundImage]="data.props?.backgroundMode === 'image' ? 'url(' + data.imageUrl + ')' : null"
            [style.background-size]="data.props?.backgroundMode === 'image' ? data.props?.backgroundSize : null"
            [style.background-position]="data.props?.backgroundMode === 'image' ? data.props?.backgroundPosition : null"
            [style.border-radius]="data.props?.borderRadius + 'px'"
            [style.borderColor]="data.props?.border ? data.props?.borderColor : null"
            [style.borderWidth]="data.props?.border ? data.props?.borderWidth + 'px' : null"
            [style.borderStyle]="data.props?.border ? data.props?.borderStyle : null"
            [style.boxShadow]="data.props?.shadow ? data.props?.shadowDepth + 'px ' +  data.props?.shadowDepth + 'px ' + data.props?.shadowBlur + 'px ' + data.props?.shadowColor: null"
            [style.text-align]="data.props?.textAlign" [style.width]="data.props?.width + '%'"
            [ngStyle]="data.props?.customCss" class="rtl-container"
            [ngStyle]="this.sharedService.activeComponent?.key === data.key ? activeStyles : {}" nz-popover
            [nzPopoverVisible]="checkIfActive()" nzPopoverTrigger="click" [nzPopoverContent]="contentTemplate"
            style="display:inline-block; float: left;">
            <div *ngIf="data.children && data.children.length > 0">
                <app-component-renderer *ngFor="let child of data.children" [data]="child" [childData]="childData"
                    (click)="this.sharedService.setActiveComponent($event, child)"></app-component-renderer>
            </div>
        </div>
    </ng-container>

    <!-- PRODUCT Component -->
    <ng-container *ngIf="data.type == 'PRODUCT'">
        <!-- <div [style.paddingTop]="sharedService.getProperty('padding', data.properties)?.paddingTop"
            [style.paddingBottom]="sharedService.getProperty('padding', data.properties)?.paddingBottom"
            [style.paddingLeft]="sharedService.getProperty('padding', data.properties)?.paddingLeft"
            [style.paddingRight]="sharedService.getProperty('padding', data.properties)?.paddingRight"
            [style.backgroundColor]="sharedService.getProperty('background', data.properties)?.mode === 'solid' ? sharedService.getProperty('background', data.properties)?.value : null"
            [style.backgroundImage]="sharedService.getProperty('background', data.properties)?.mode === 'image' ? 'url(' + sharedService.getProperty('background', data.properties)?.value + ')' : null"
            [style.background-size]="sharedService.getProperty('background', data.properties)?.mode === 'image' ? sharedService.getProperty('background', data.properties)?.backgroundSize : null"
            [style.background-position]="sharedService.getProperty('background', data.properties)?.mode === 'image' ? sharedService.getProperty('background', data.properties)?.backgroundPosition : null"
            [style.borderRadius]="sharedService.getProperty('borderRadius', data.properties)?.value"
            [style.borderColor]="sharedService.getProperty('border', data.properties)?.borderColor"
            [style.borderWidth]="sharedService.getProperty('border', data.properties)?.borderWidth"
            [style.borderStyle]="sharedService.getProperty('border', data.properties)?.borderStyle"
            [style.text-align]="sharedService.getProperty('textAlign', data.properties)?.value"
            [style.width]="sharedService.getProperty('width', data.properties)?.mode === 'fixed' ? sharedService.getProperty('width', data.properties)?.value : null"
            [style.float]="sharedService.getProperty('width', data.properties)?.mode === 'fixed' ? 'left' : null"
            [ngStyle]="sharedService.getProperty('styles', data.properties)?.value" style="overflow: hidden;"
            class="rtl-container" [ngStyle]="this.sharedService.activeComponent?.key === data.key ? activeStyles : {}"
            nz-popover [nzPopoverVisible]="checkIfActive()" nzPopoverTrigger="click"
            [nzPopoverContent]="contentTemplate">
            <div *ngIf="data.children && data.children.length > 0">
                <app-component-renderer *ngFor="let child of data.children" [data]="child" [childData]="childData"
                    (click)="this.sharedService.setActiveComponent($event, child)"></app-component-renderer>
            </div>
        </div> -->
        <div [style.paddingTop]="data.props?.paddingTop" [style.paddingBottom]="data.props?.paddingBottom"
            [style.paddingLeft]="data.props?.paddingLeft" [style.paddingRight]="data.props?.paddingRight"
            [style.width]="data.props?.width + '%'" [ngStyle]="data.props?.customCss" class="rtl-container"
            [ngStyle]="this.sharedService.activeComponent?.key === data.key ? activeStyles : {}" nz-popover
            [nzPopoverVisible]="checkIfActive()" nzPopoverTrigger="click" [nzPopoverContent]="contentTemplate"
            style="display:inline-block; float: left;">
            <div *ngIf="data.children && data.children.length > 0">
                <app-component-renderer *ngFor="let child of data.children" [data]="child" [childData]="childData"
                    (click)="this.sharedService.setActiveComponent($event, child)"></app-component-renderer>
            </div>
        </div>
    </ng-container>

    <!-- REVIEW Component -->
    <ng-container *ngIf="data.type == 'REVIEW'">
        <!-- <div [style.paddingTop]="sharedService.getProperty('padding', data.properties)?.paddingTop"
            [style.paddingBottom]="sharedService.getProperty('padding', data.properties)?.paddingBottom"
            [style.paddingLeft]="sharedService.getProperty('padding', data.properties)?.paddingLeft"
            [style.paddingRight]="sharedService.getProperty('padding', data.properties)?.paddingRight"
            [style.backgroundColor]="sharedService.getProperty('background', data.properties)?.mode === 'solid' ? sharedService.getProperty('background', data.properties)?.value : null"
            [style.backgroundImage]="sharedService.getProperty('background', data.properties)?.mode === 'image' ? 'url(' + sharedService.getProperty('background', data.properties)?.value + ')' : null"
            [style.background-size]="sharedService.getProperty('background', data.properties)?.mode === 'image' ? sharedService.getProperty('background', data.properties)?.backgroundSize : null"
            [style.background-position]="sharedService.getProperty('background', data.properties)?.mode === 'image' ? sharedService.getProperty('background', data.properties)?.backgroundPosition : null"
            [style.borderRadius]="sharedService.getProperty('borderRadius', data.properties)?.value"
            [style.borderColor]="sharedService.getProperty('border', data.properties)?.borderColor"
            [style.borderWidth]="sharedService.getProperty('border', data.properties)?.borderWidth"
            [style.borderStyle]="sharedService.getProperty('border', data.properties)?.borderStyle"
            [style.text-align]="sharedService.getProperty('textAlign', data.properties)?.value"
            [style.width]="sharedService.getProperty('width', data.properties)?.mode === 'fixed' ? sharedService.getProperty('width', data.properties)?.value : null"
            [style.float]="sharedService.getProperty('width', data.properties)?.mode === 'fixed' ? 'left' : null"
            [ngStyle]="sharedService.getProperty('styles', data.properties)?.value" style="overflow: hidden;"
            class="rtl-container" [ngStyle]="this.sharedService.activeComponent?.key === data.key ? activeStyles : {}"
            nz-popover [nzPopoverVisible]="checkIfActive()" nzPopoverTrigger="click"
            [nzPopoverContent]="contentTemplate">
            <div *ngIf="data.children && data.children.length > 0">
                <app-component-renderer *ngFor="let child of data.children" [data]="child" [childData]="childData"
                    (click)="this.sharedService.setActiveComponent($event, child)"></app-component-renderer>
            </div>
        </div> -->
        <div [style.paddingTop]="data.props?.paddingTop" [style.paddingBottom]="data.props?.paddingBottom"
            [style.paddingLeft]="data.props?.paddingLeft" [style.paddingRight]="data.props?.paddingRight"
            [style.width]="data.props?.width + '%'" [ngStyle]="data.props?.customCss" class="rtl-container"
            [ngStyle]="this.sharedService.activeComponent?.key === data.key ? activeStyles : {}" nz-popover
            [nzPopoverVisible]="checkIfActive()" nzPopoverTrigger="click" [nzPopoverContent]="contentTemplate"
            style="display:inline-block; float: left;">
            <div *ngIf="data.children && data.children.length > 0">
                <app-component-renderer *ngFor="let child of data.children" [data]="child" [childData]="childData"
                    (click)="this.sharedService.setActiveComponent($event, child)"></app-component-renderer>
            </div>
        </div>
    </ng-container>

    <!-- DATACONTAINER Component -->
    <ng-container *ngIf="data.type == 'DATACONTAINER'">
        <div [style.paddingTop]="data.props?.paddingTop" [style.paddingBottom]="data.props?.paddingBottom"
            [style.paddingLeft]="data.props?.paddingLeft" [style.paddingRight]="data.props?.paddingRight"
            [style.backgroundColor]="data.props?.backgroundMode === 'solid' ? data.props?.backgroundColor : null"
            [style.backgroundImage]="data.props?.backgroundMode === 'image' ? 'url(' + data.props?.backgroundImage + ')' : null"
            [style.background-size]="data.props?.backgroundMode === 'image' ? data.props?.backgroundSize : null"
            [style.background-position]="data.props?.backgroundMode === 'image' ? data.props?.backgroundPosition : null"
            [style.borderRadius]="data.props?.borderRadius" [style.borderColor]="data.props?.borderColor"
            [style.borderWidth]="data.props?.borderWidth" [style.borderStyle]="data.props?.borderStyle"
            [style.text-align]="data.props?.textAlign"
            [style.width]="data.props?.widthMode === 'fixed' ? data.props?.width : null"
            [style.float]="data.props?.widthMode === 'fixed' ? 'left' : null" [ngStyle]="data.props?.customCss"
            [ngStyle]="sharedService.getProperty('styles', data.properties)?.value" class="rtl-container"
            [ngStyle]="this.sharedService.activeComponent?.key === data.key ? activeStyles : {}" nz-popover
            [nzPopoverVisible]="checkIfActive()" nzPopoverTrigger="click" [nzPopoverContent]="contentTemplate">
            <div *ngIf="data.value && data.value.length > 0">
                <ng-container *ngFor="let item of data.value">
                    <app-component-renderer *ngFor="let child of data.children" [data]="child" [childData]="item"
                        (click)="this.sharedService.setActiveComponent($event, child)"></app-component-renderer>
                </ng-container>
                <!-- <app-component-renderer *ngFor="let item of data.data" [data]="item"
                    (click)="this.sharedService.setActiveComponent($event, item)"></app-component-renderer> -->
            </div>
        </div>
    </ng-container>

    <!-- TEXT Component -->
    <ng-container *ngIf="data.type == 'TEXT'">
        <div nz-popover [nzPopoverVisible]="checkIfActive()" nzPopoverTrigger="click"
            [nzPopoverContent]="contentTemplate" style="width: 100%;">
            <!-- <div [style.fontSize]="sharedService.getProperty('fontSize', data.properties)?.value"
            [style.fontWeight]="sharedService.getProperty('fontWeight', data.properties)?.value"
            [style.fontFamily]="sharedService.getProperty('fontFamily', data.properties)?.value"
            [style.color]="sharedService.getProperty('color', data.properties)?.value"
            [style.text-align]="sharedService.getProperty('textAlign', data.properties)?.value"
            [style.paddingTop]="sharedService.getProperty('paddingTop', data.properties)?.value"
            [style.paddingBottom]="sharedService.getProperty('paddingBottom', data.properties)?.value"
            [style.paddingLeft]="sharedService.getProperty('paddingLeft', data.properties)?.value"
            [style.paddingRight]="sharedService.getProperty('paddingRight', data.properties)?.value"
            [ngStyle]="sharedService.getProperty('styles', data.properties)?.value">
            <span>{{data.value}}</span>

        </div> -->
            <!-- [style.color]="sharedService.getProperty('color', data.properties)?.value"
        [style.text-align]="sharedService.getProperty('textAlign', data.properties)?.value" -->
            <ng-container *ngIf="data.mode == 'STATIC' || !data.mode">
                <quill-editor class="email-article-title" theme="bubble" [modules]="modules2"
                    [placeholder]="placeholder" [(ngModel)]="data.value" (onEditorCreated)="onEditorCreated($event)"
                    [style.fontSize]="data.props?.fontSize" class="rtl-container"
                    [ngStyle]="this.sharedService.activeComponent?.key === data.key ? activeStyles : {}"
                    [style.fontWeight]="data.props?.fontWeight" [style.fontFamily]="data.props?.fontFamily"
                    [style.line-height]="data.props?.lineHeight" [style.letter-spacing]="data.props?.letterSpacing"
                    [style.paddingTop]="data.props?.paddingTop" [style.paddingBottom]="data.props?.paddingBottom"
                    [style.paddingLeft]="data.props?.paddingLeft" [style.paddingRight]="data.props?.paddingRight"
                    style="width: 100%;"></quill-editor>
            </ng-container>

            <ng-container *ngIf="data.mode == 'DYNAMIC'">
                <quill-editor class="email-article-title" theme="bubble" [modules]="modules2"
                    [placeholder]="placeholder" [(ngModel)]="childData[data.value]"
                    (onEditorCreated)="onEditorCreated($event)" class="rtl-container"
                    [ngStyle]="this.sharedService.activeComponent?.key === data.key ? activeStyles : {}"
                    [style.fontWeight]="data.props?.fontWeight" [style.fontFamily]="data.props?.fontFamily"
                    [style.line-height]="data.props?.lineHeight" [style.letter-spacing]="data.props?.letterSpacing"
                    [style.fontSize]="data.props?.fontSize" class="rtl-container"
                    [style.paddingTop]="data.props?.paddingTop" [style.paddingBottom]="data.props?.paddingBottom"
                    [style.paddingLeft]="data.props?.paddingLeft" [style.paddingRight]="data.props?.paddingRight"
                    style="width: 100%;"></quill-editor>
            </ng-container>
        </div>
    </ng-container>

    <!-- IMAGE Component -->
    <ng-container *ngIf="data.type == 'IMAGE'">
        <ng-container *ngIf="data.mode == 'STATIC' || !data.mode">
            <div class="flex-outer" style="display: flex" [style.justify-content]="data.props?.justifyContent">
                <img [src]="data.imageUrl" class="rtl-container"
                    [ngStyle]="this.sharedService.activeComponent?.key === data.key ? activeStyles : {}"
                    [style.text-align]="data.props?.textAlign" [style.paddingTop]="data.props?.paddingTop"
                    [style.paddingBottom]="data.props?.paddingBottom" [style.paddingLeft]="data.props?.paddingLeft"
                    [style.width]="data.props?.width + '%'" [style.paddingRight]="data.props?.paddingRight"
                    [ngStyle]="data.props?.customCSS" alt="Image" nz-popover [nzPopoverVisible]="checkIfActive()"
                    nzPopoverTrigger="click" [nzPopoverContent]="contentTemplate">
            </div>
        </ng-container>
        <ng-container *ngIf="data.mode == 'DYNAMIC'">
            <div class="flex-outer" style="display: flex" [style.justify-content]="data.props?.justifyContent">
                <img [src]="childData[data.value]" [style.text-align]="data.props?.textAlign" class="rtl-container"
                    [ngStyle]="this.sharedService.activeComponent?.key === data.key ? activeStyles : {}"
                    [style.paddingTop]="data.props?.paddingTop" [style.paddingBottom]="data.props?.paddingBottom"
                    [style.paddingLeft]="data.props?.paddingLeft" [style.paddingRight]="data.props?.paddingRight"
                    [style.width]="data.props?.width + '%'"
                    [ngStyle]="sharedService.getProperty('styles', data.properties)?.value" alt="Image" nz-popover
                    [nzPopoverVisible]="checkIfActive()" nzPopoverTrigger="click" [nzPopoverContent]="contentTemplate">
            </div>
        </ng-container>
    </ng-container>

    <!-- BUTTON Component -->
    <ng-container *ngIf="data.type == 'BUTTON'">
        <div class="flex-outer" style="display: flex" [style.justify-content]="data.props?.justifyContent">
            <div [style.fontSize]="data.props?.fontSize" class="rtl-container"
                [ngStyle]="this.sharedService.activeComponent?.key === data.key ? activeStyles : {}"
                [style.paddingTop]="data.props?.paddingTop" [style.paddingBottom]="data.props?.paddingBottom"
                [style.paddingLeft]="data.props?.paddingLeft" [style.paddingRight]="data.props?.paddingRight"
                [style.borderRadius]="data.props?.borderRadius + 'px'"
                [style.borderColor]="data.props?.border ? data.props?.borderColor : null"
                [style.borderWidth]="data.props?.border ? data.props?.borderWidth + 'px' : null"
                [style.borderStyle]="data.props?.border ? data.props?.borderStyle : null"
                [style.boxShadow]="data.props?.shadow ? data.props?.shadowDepth + 'px ' +  data.props?.shadowDepth + 'px ' + data.props?.shadowBlur + 'px ' + data.props?.shadowColor: null"
                [style.backgroundColor]="data.props?.backgroundMode === 'solid' ? data.props?.backgroundColor : null"
                [style.backgroundImage]="data.props?.backgroundMode === 'image' ? 'url(' + data.props?.backgroundImage + ')' : null"
                [style.background-size]="data.props?.backgroundMode === 'image' ? data.props?.backgroundSize : null"
                [style.background-position]="data.props?.backgroundMode === 'image' ? data.props?.backgroundPosition : null"
                style="width: fit-content" [ngStyle]="data.props?.customCSS" nz-popover
                [nzPopoverVisible]="checkIfActive()" nzPopoverTrigger="click" [nzPopoverContent]="contentTemplate">

                <quill-editor class="email-article-title" theme="bubble" [modules]="modules2"
                    [placeholder]="placeholder" [(ngModel)]="data.value" (onEditorCreated)="onEditorCreated($event)"
                    [style.fontSize]="data.props?.fontSize" class="rtl-container"
                    [ngStyle]="this.sharedService.activeComponent?.key === data.key ? activeStyles : {}"
                    [style.fontWeight]="data.props?.fontWeight" [style.fontFamily]="data.props?.fontFamily"
                    [style.line-height]="data.props?.lineHeight" [style.letter-spacing]="data.props?.letterSpacing"
                    style="width: 100%;"></quill-editor>

            </div>
        </div>

        <!-- <div [style.fontSize]="sharedService.getProperty('fontSize', data.properties)?.value"
            [style.fontWeight]="sharedService.getProperty('fontWeight', data.properties)?.value"
            [style.color]="sharedService.getProperty('color', data.properties)?.value"
            [style.text-align]="sharedService.getProperty('textAlign', data.properties)?.value"
            [style.paddingTop]="sharedService.getProperty('padding', data.properties)?.paddingTop"
            [style.paddingBottom]="sharedService.getProperty('padding', data.properties)?.paddingBottom"
            [style.paddingLeft]="sharedService.getProperty('padding', data.properties)?.paddingLeft"
            [style.paddingRight]="sharedService.getProperty('padding', data.properties)?.paddingRight"
            [style.borderRadius]="sharedService.getProperty('borderRadius', data.properties)?.value"
            [style.borderColor]="sharedService.getProperty('borderColor', data.properties)?.value"
            [style.borderWidth]="sharedService.getProperty('borderWidth', data.properties)?.value"
            [style.borderStyle]="sharedService.getProperty('borderStyle', data.properties)?.value"
            [ngStyle]="sharedService.getProperty('styles', data.properties)?.value">

            <button [style.fontSize]="sharedService.getProperty('fontSize', data.properties)?.value"
                [style.fontWeight]="sharedService.getProperty('fontWeight', data.properties)?.value"
                [style.color]="sharedService.getProperty('color', data.properties)?.value"
                [style.text-align]="sharedService.getProperty('textAlign', data.properties)?.value"
                [style.paddingTop]="sharedService.getProperty('buttonPadding', data.properties)?.paddingTop"
                [style.paddingBottom]="sharedService.getProperty('buttonPadding', data.properties)?.paddingBottom"
                [style.paddingLeft]="sharedService.getProperty('buttonPadding', data.properties)?.paddingLeft"
                [style.paddingRight]="sharedService.getProperty('buttonPadding', data.properties)?.paddingRight"
                [style.borderRadius]="sharedService.getProperty('borderRadius', data.properties)?.value"
                [style.borderColor]="sharedService.getProperty('borderColor', data.properties)?.value"
                [style.borderWidth]="sharedService.getProperty('borderWidth', data.properties)?.value"
                [style.borderStyle]="sharedService.getProperty('borderStyle', data.properties)?.value"
                [style.backgroundColor]="sharedService.getProperty('background', data.properties)?.mode === 'solid' ? sharedService.getProperty('background', data.properties)?.value : null"
                [style.backgroundImage]="sharedService.getProperty('background', data.properties)?.mode === 'image' ? 'url(' + sharedService.getProperty('background', data.properties)?.value + ')' : null"
                [style.background-size]="sharedService.getProperty('background', data.properties)?.mode === 'image' ? sharedService.getProperty('background', data.properties)?.backgroundSize : null"
                [style.background-position]="sharedService.getProperty('background', data.properties)?.mode === 'image' ? sharedService.getProperty('background', data.properties)?.backgroundPosition : null"
                [ngStyle]="sharedService.getProperty('styles', data.properties)?.value">
                {{data.value}}
            </button>
        </div> -->

    </ng-container>
</div>