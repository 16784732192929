import { Component, Input } from '@angular/core';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-property-renderer',
  templateUrl: './property-renderer.component.html',
  styleUrls: ['./property-renderer.component.scss']
})
export class PropertyRendererComponent {
  @Input() data: any;
  @Input() mode: any;
  selectedIndex: number = 0;

  constructor(
    public sharedService: SharedService
  ) { }

  handleModelChange(index: number, property: any): void {
    if (index == 0) {
      property.mode = "solid";
    }

    if (index == 1) {
      property.mode = "image";
    }
  }

  openPickerSingle(property: any, mode: any) {
    console.log("Open Picker");
    this.sharedService.currentProperty = property;
    this.sharedService.pickerMode = mode;
    this.sharedService.pickerOpen = true;
    this.sharedService.pickerNumber = "SINGLE";
  }

  compareFn = (o1: any, o2: any): boolean => (o1 && o2 ? o1 === o2 : o1 === o2);

  setQuillAlignment(direction: any) {
    this.sharedService.activeComponent.quillEditor.format('align', null);
    this.sharedService.activeComponent.quillEditor.format('align', direction);
  }

  setQuillColor(property: any) {
    if (this.sharedService.activeComponent.type == "TEXT") {
      this.sharedService.activeComponent.quillEditor.format('color', property.value);
      // const quill = this.sharedService.activeComponent.quillEditor;
      // const range = quill.getSelection();

      // // Check if there is a selection
      // if (range && range.length > 0) {
      //   // Apply the color format to the selected text
      //   quill.format('color', property.value);
      // } else {
      //   // Get the entire length of the editor content
      //   const length = quill.getLength();

      //   // Apply the color format to the entire range
      //   quill.formatText(0, length, 'color', property.value);
      // }
    }
  }

  setQuillBold() {
    // Get the current format
    const quill = this.sharedService.activeComponent.quillEditor;
    const currentFormat = quill.getFormat();

    // Toggle format
    quill.format('bold', !currentFormat.bold);
  }

  setQuillItalic() {
    // Get the current format
    const quill = this.sharedService.activeComponent.quillEditor;
    const currentFormat = quill.getFormat();

    // Toggle format
    quill.format('italic', !currentFormat.italic);
  }

  setQuillUnderline() {
    // Get the current format
    const quill = this.sharedService.activeComponent.quillEditor;
    const currentFormat = quill.getFormat();

    // Toggle format
    quill.format('underline', !currentFormat.underline);
  }

  setQuillStrikethrough() {
    // Get the current format
    const quill = this.sharedService.activeComponent.quillEditor;
    const currentFormat = quill.getFormat();

    // Toggle format
    quill.format('strike', !currentFormat.strike);
  }

  setQuillBullets() {
    const quill = this.sharedService.activeComponent.quillEditor;

    // Get the current format of the selection
    const currentFormat = quill.getFormat();

    // If the current format is 'bullet', remove the list formatting
    if (currentFormat.list === 'bullet') {
      quill.format('list', false);
    } else {
      // Otherwise, set the format to 'bullet'
      quill.format('list', 'bullet');
    }
  }

  setQuillOrderedList() {
    const quill = this.sharedService.activeComponent.quillEditor;

    // Get the current format of the selection
    const currentFormat = quill.getFormat();

    // If the current format is 'bullet', remove the list formatting
    if (currentFormat.list === 'ordered') {
      quill.format('list', false);
    } else {
      // Otherwise, set the format to 'bullet'
      quill.format('list', 'ordered');
    }
  }
}
