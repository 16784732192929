<div class="custom-modal-header">
    <div class="custom-modal-title">Invite user</div>
    <div class="custom-modal-close" (click)="close()"><span nz-icon nzType="close" nzTheme="outline"></span></div>
</div>
<div class="custom-modal-body" style="overflow:hidden;">
    <nz-form-control [nzSpan]="24">
        <input placeholder="Enter an email" nz-input [(ngModel)]="newUserEmail" />
    </nz-form-control>
</div>
<div class="custom-modal-footer">
    <button nz-button nzType="primary" (click)="sendInvite()">Send invitation</button>
</div>