import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { interval } from "rxjs/internal/observable/interval";
import { startWith, takeWhile } from 'rxjs/operators';
import { Observable, timer, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import * as htmlToImage from 'html-to-image';
import { SharedService } from '../shared.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-social-post-view',
  templateUrl: './social-post-view.component.html',
  styleUrls: ['./social-post-view.component.scss']
})
export class SocialPostViewComponent {
  loadingSegments = true;
  saving = false;
  opportunityId: any = '';
  opportunityObject: any = {};
  loading = true;
  newMessageBody = '';

  activeBlock: any = {};

  fetchingSiteData = true;
  siteData: any = {};

  logoImage = "";
  primaryColor = "";

  publishingOpportunity = false;

  editSubjectLine = false;
  subjectLineFeedback = "";
  loadingSubjectLineFeedback = false;

  chatDrawerVisible = false;
  chatLog: any = [];

  klaviyoListsAndSegments: any = [];

  audiences: any = [
    "90 day engaged",
    "180 day engaged",
    "Churn risk",
    "Recent churn"
  ]

  deleting: any = false;

  templateObject = {
    templateContentBackground: "#f7f5ef",
    templateBackground: "#fff",
    blocks: [
      {
        type: "LOGO",
        properties: [
          {
            name: "Image URL",
            type: "INPUT",
            value: "https://cdn.shopify.com/s/files/1/0664/2103/1145/products/logo_scf.png?v=1671807225",
          },
        ],
      },
      {
        type: "TITLE",
        properties: [
          {
            name: "Title",
            type: "INPUT",
            value: "Don't let your cart slip away",
          },
          {
            name: "Subtitle",
            type: "INPUT",
            value: "Looks like you left some items in your cart",
          },
        ],
      },
      {
        type: "BUTTON",
        properties: [
          {
            name: "Button text",
            type: "INPUT",
            value: "Go to my cart",
          },
          {
            name: "Button color",
            type: "INPUT",
            value: "#fff",
          },
        ],
      },
      {
        type: "IMAGEFULLWIDTH",
        properties: [
          {
            name: "Image URL",
            type: "INPUT",
            value: "https://cdn.shopify.com/s/files/1/0664/2103/1145/products/ribeye.png?v=1664787645&width=533",
          },
        ],
      },
    ]
  }

  INTERVAL = 5000;  // <-- poll every 5 seconds
  closeTimer$ = new Subject<void>();
  segmentIncludeStatus = "";
  duplicating = false;
  newFollowUpObject = {
    delay: 24,
    criteria: "OPENEDEMAIL",
    subjectLine: "",
    previewText: "",
    criteriaOperator: "DIDNOT",
    opportunity: null
  };
  newSegmentVersionObject = {
    segment: null,
    subjectLine: "",
    previewText: "",
    loading: false,
    opportunity: null,
    feedback: ""
  };
  addingSegmentVersion = false;
  backMode: any = "";
  flowList: any = [];
  subjectLinePopoverVisible: any = false;
  subjectLineLoading: any = false;

  subjectLines: any = [
    {},
    {},
    {}
  ];

  showSubjectLines: any = false;
  sendingPreview = false;

  openRateMetric: any = {};
  loadingOpenRateMetric = true;

  clickRateMetric: any = {};
  loadingClickRateMetric = true;

  receivedEmailMetric: any = {};
  loadingReceivedEmailMetric = true;

  ordersMetric: any = {};
  loadingOrdersMetric = true;

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public notification: NzNotificationService,
    public sharedService: SharedService,
    private message: NzMessageService
  ) {

  }

  ngOnInit() {
    this.sharedService.loadingRenderer = true;
    this.sharedService.rendererOpportunityObject = {};

    this.opportunityId = this.route.snapshot.paramMap.get("id");
    this.backMode = this.route.snapshot.paramMap.get("mode");

    console.log(this.opportunityId);
    this.apiService.getOpportunity(this.opportunityId).subscribe(
      (data: any) => {
        console.log(data);
        if (this.sharedService.currentUser.workspace._id == data.workspace) {
          this.opportunityObject = data;
          if (!this.opportunityObject.sendTimeHours) {
            this.opportunityObject.sendTimeHours = this.opportunityObject.sendTime;
          }
          this.loading = false;
        } else {
          this.router.navigate(['/']);
        }

        if (!this.opportunityObject.channels) {
          this.opportunityObject.channels = {};
        }

        if (this.opportunityObject.segmentVersions && this.opportunityObject.segmentVersions.length > 0) {
          this.opportunityObject.segmentVersions.forEach((segmentVersion: any) => {
            this.apiService.getOpportunity(segmentVersion.opportunity).subscribe((data: any) => {
              segmentVersion.opportunityObject = data;
            });
          });
        }

        if (this.opportunityObject.localTime == null) {
          this.opportunityObject.localTime = true;
        }

        if (this.opportunityObject.smartSending == null) {
          this.opportunityObject.smartSending = false;
        }

        this.getKlaviyoListsAndSegments();

        this.apiService.getFlows().subscribe(
          (data: any) => {
            console.log("Flows", data);
            this.flowList = data;
            // this.loading = false;
          },
          error => {
            console.log(error);
          });

        if (this.opportunityObject?.metrics?.CAMPAIGNRECIPIENTCOUNT) {
          this.receivedEmailMetric = this.opportunityObject?.metrics?.CAMPAIGNRECIPIENTCOUNT;
        }

        if (this.opportunityObject?.metrics?.CAMPAIGNOPENRATE) {
          this.openRateMetric = this.opportunityObject?.metrics?.CAMPAIGNOPENRATE;
        }

        if (this.opportunityObject?.metrics?.CAMPAIGNCLICKRATE) {
          this.clickRateMetric = this.opportunityObject?.metrics?.CAMPAIGNCLICKRATE;
        }

        if (this.opportunityObject?.metrics?.CAMPAIGNATTRIBUTEDORDERS) {
          this.ordersMetric = this.opportunityObject?.metrics?.CAMPAIGNATTRIBUTEDORDERS;
        }

        if (this.sharedService.currentUser.adminLevel == 'SUPER') {
          if (this.opportunityObject?.externalData?.klaviyoCampaign) {
            console.log("Getting campaign metric");

            this.receivedEmailMetric = this.opportunityObject?.metrics?.CAMPAIGNRECIPIENTCOUNT;
            this.openRateMetric = this.opportunityObject?.metrics?.CAMPAIGNOPENRATE;
            this.clickRateMetric = this.opportunityObject?.metrics?.CAMPAIGNCLICKRATE;
            this.ordersMetric = this.opportunityObject?.metrics?.CAMPAIGNATTRIBUTEDORDERS;

            this.apiService.getCampaignMetric("CAMPAIGNRECIPIENTCOUNT", this.opportunityObject?.externalData?.klaviyoCampaign, this.opportunityObject?._id).subscribe(
              (data: any) => {
                console.log("RECIPIENTS", data);
                // this.loading = false;
                this.receivedEmailMetric = data;
                this.loadingReceivedEmailMetric = false;

                this.apiService.getCampaignMetric("CAMPAIGNOPENRATE", this.opportunityObject?.externalData?.klaviyoCampaign, this.opportunityObject?._id).subscribe(
                  (data: any) => {
                    console.log("OPENRATE", data);
                    // this.loading = false;
                    this.openRateMetric = data;
                    this.loadingOpenRateMetric = false;

                    this.apiService.getCampaignMetric("CAMPAIGNCLICKRATE", this.opportunityObject?.externalData?.klaviyoCampaign, this.opportunityObject?._id).subscribe(
                      (data: any) => {
                        console.log("CLICKRATE", data);
                        // this.loading = false;
                        this.clickRateMetric = data;
                        this.loadingClickRateMetric = false;

                        this.apiService.getCampaignMetric("CAMPAIGNATTRIBUTEDORDERS", this.opportunityObject?.externalData?.klaviyoCampaign, this.opportunityObject?._id).subscribe(
                          (data: any) => {
                            console.log("ORDERS", data);
                            // this.loading = false;
                            this.ordersMetric = data;
                            this.loadingOrdersMetric = false;
                          },
                          error => {
                            console.log(error);
                          });
                      },
                      error => {
                        console.log(error);
                      });
                  },
                  error => {
                    console.log(error);
                  });
              },
              error => {
                console.log(error);
              });
          }
        }

        // if (this.opportunityObject.stage == "NEW") {
        //   this.apiService.generateTemplateContent(this.opportunityId).subscribe(
        //     (data: any) => {

        //       // timer(0, this.INTERVAL).pipe(      // <-- start immediately and poll every `INTERVAL` seconds
        //       //   switchMap(() => this.apiService.getOpportunity(this.opportunityId)),  // <-- map to another observable
        //       //   takeUntil(this.closeTimer$)   // <-- close the subscription when `closeTimer$` emits
        //       // ).subscribe({
        //       //   next: (res: any) => {
        //       //     if (res["stage"] == "PENDING") {
        //       //       this.closeTimer$.next();  // <-- stop polling
        //       //       this.router.navigate([`/editor/${res._id}`]);
        //       //     }
        //       //   },
        //       //   error: (error: any) => {
        //       //     // handle errors
        //       //     // note that any errors would stop the polling here
        //       //   }
        //       // });
        //       // console.log(data);
        //       // this.opportunityObject = data;
        //       // this.router.navigate([`/editor/${this.opportunityId}`]);
        //       // this.loading = false;
        //       // this.fetchingSiteData = false;
        //       // this.siteData = data;
        //     },
        //     error => {
        //       console.log(error);
        //     });
        // }
      },
      error => {
        console.log(error);
      });
  }

  generateProposal() {
    this.opportunityObject.stage = "NEW";
    this.apiService.generateProposal(this.opportunityId).subscribe(
      (data: any) => {
        console.log(data);
        this.opportunityObject = data;
        this.loading = false;

      },
      error => {
        console.log(error);
      });
  }

  generateTemplateContent() {
    // this.opportunityObject.stage = "NEW";
    // this.fetchingSiteData = true;
    this.apiService.generateTemplateContent(this.opportunityId).subscribe(
      (data: any) => {
        console.log(data);
        this.opportunityObject = data;
        this.loading = false;
        this.fetchingSiteData = false;

      },
      error => {
        console.log(error);
      });
  }

  newMessage() {
    this.apiService.newMessage({
      body: this.newMessageBody,
      opportunity: this.opportunityId
    }).subscribe(
      (data: any) => {
        console.log(data);
      },
      error => {
        console.log(error);
      });
  }

  selectBlock(block: any) {
    this.activeBlock = block;
  }

  selectPrimaryColor(color: any) {
    this.primaryColor = color;
  }

  selectLogoImage(image: any) {
    this.logoImage = image;
  }

  async publishOpportunity() {
    // console.log(new Date(this.opportunityObject.sendTime));
    // console.log(new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000));

    let newDate = new Date();
    let sendTime = new Date(this.opportunityObject.sendTime);
    console.log(sendTime);
    console.log(newDate);
    // console.log(sendTime.getUTCHours());
    sendTime.setMinutes(sendTime.getMinutes() + sendTime.getTimezoneOffset());
    console.log(sendTime);
    // newDate.setFullYear(this.opportunityObject.sendTime.getFullYear());
    // newDate.setMonth(this.opportunityObject.sendTime.getMonth());
    // newDate.setDate(this.opportunityObject.sendTime.getDate());
    // newDate.setHours(this.opportunityObject.sendTime.getHours());
    // newDate.setMinutes(this.opportunityObject.sendTime.getMinutes());
    // newDate.setHours(this.opportunityObject.sendTime.getMinutes());
    // console.log(newDate);

    if (this.opportunityObject.audienceInclude && this.opportunityObject.audienceInclude.length > 0) {
      if (sendTime > newDate) {
        const id = this.message.loading('Publishing...', { nzDuration: 0 }).messageId;
        this.publishingOpportunity = true;

        this.apiService.saveOpportunity(this.opportunityObject).subscribe(
          (data: any) => {
            console.log(data);

            this.apiService.publishOpportunity(this.opportunityObject._id, this.opportunityObject, "SCHEDULE").subscribe(
              (data: any) => {
                console.log(data);
                this.opportunityObject = data;
                this.publishingOpportunity = false;
                this.message.remove(id);
                this.message.create("success", `Changes have been published to Klaviyo`);
                // this.notification.create(
                //   'success',
                //   'Your changes have been published to Klaviyo!',
                //   ''
                // );
              },
              error => {
                console.log(error);
              });
            this.saving = false;
            return "";
          },
          error => {
            console.log(error);
          });
      } else {
        // this.segmentIncludeStatus = "error";
        this.message.create("error", `You can only publish an email in the future`);
      }
    } else {
      // this.segmentIncludeStatus = "error";
      this.message.create("error", `You must select a segment to publish an email`);
    }
  }

  async publishOpportunityTemplate() {
    const id = this.message.loading('Publishing...', { nzDuration: 0 }).messageId;
    this.publishingOpportunity = true;

    this.apiService.saveOpportunity(this.opportunityObject).subscribe(
      (data: any) => {
        console.log(data);

        this.apiService.publishOpportunity(this.opportunityObject._id, this.opportunityObject, "TEMPLATE").subscribe(
          (data: any) => {
            console.log(data);
            this.opportunityObject = data;
            this.publishingOpportunity = false;
            this.message.remove(id);
            this.message.create("success", `Changes have been published to Klaviyo`);
            // this.notification.create(
            //   'success',
            //   'Your changes have been published to Klaviyo!',
            //   ''
            // );
          },
          error => {
            console.log(error);
          });
      },
      error => {
        console.log(error);
      });

  }

  async publishOpportunityDraft() {
    let newDate = new Date();
    let sendTime = new Date(this.opportunityObject.sendTime);
    console.log(sendTime);
    console.log(newDate);
    // console.log(sendTime.getUTCHours());
    sendTime.setMinutes(sendTime.getMinutes() + sendTime.getTimezoneOffset());
    console.log(sendTime);
    if (this.opportunityObject.audienceInclude && this.opportunityObject.audienceInclude.length > 0) {
      if (sendTime > newDate) {
        const id = this.message.loading('Publishing...', { nzDuration: 0 }).messageId;
        this.publishingOpportunity = true;

        this.apiService.saveOpportunity(this.opportunityObject).subscribe(
          (data: any) => {
            console.log(data);
            this.apiService.publishOpportunity(this.opportunityObject._id, this.opportunityObject, "DRAFT").subscribe(
              (data: any) => {
                console.log(data);
                this.opportunityObject = data;
                this.publishingOpportunity = false;
                this.message.remove(id);
                this.message.create("success", `Changes have been published to Klaviyo`);
                // this.notification.create(
                //   'success',
                //   'Your changes have been published to Klaviyo!',
                //   ''
                // );
              },
              error => {
                console.log(error);
              });
          },
          error => {
            console.log(error);
          });
      } else {
        // this.segmentIncludeStatus = "error";
        this.message.create("error", `You can only publish an email in the future`);
      }
    } else {
      // this.segmentIncludeStatus = "error";
      this.message.create("error", `You must select a segment to publish an email`);
    }
  }

  async publishOpportunityHTML() {
    const id = this.message.loading('Generating HTML...', { nzDuration: 0 }).messageId;
    this.publishingOpportunity = true;
    this.apiService.saveOpportunity(this.opportunityObject).subscribe(
      (data: any) => {
        console.log(data);

        this.apiService.publishOpportunity(this.opportunityObject._id, this.opportunityObject, "HTML").subscribe(
          (data: any) => {
            console.log(data);
            this.opportunityObject = data;
            this.publishingOpportunity = false;
            this.message.remove(id);
            this.message.create("success", `HTML generated`);
            this.sharedService.htmlOpen = true;
            this.sharedService.htmlBody = data.contentTemplate.html;
            // this.notification.create(
            //   'success',
            //   'Your changes have been published to Klaviyo!',
            //   ''
            // );
          },
          error => {
            console.log(error);
          });
      },
      error => {
        console.log(error);
      });
  }

  async publishOpportunityShopify() {
    const id = this.message.loading('Exporting...', { nzDuration: 0 }).messageId;
    this.publishingOpportunity = true;
    this.apiService.saveOpportunity(this.opportunityObject).subscribe(
      (data: any) => {
        console.log(data);

        this.apiService.publishOpportunity(this.opportunityObject._id, this.opportunityObject, "SHOPIFY").subscribe(
          (data: any) => {
            console.log(data);
            this.opportunityObject = data;
            this.publishingOpportunity = false;
            this.message.remove(id);
            this.message.create("success", `Exported successfully`);
            this.sharedService.htmlOpen = true;
            this.sharedService.htmlBody = data.contentTemplate.html;
            // this.notification.create(
            //   'success',
            //   'Your changes have been published to Klaviyo!',
            //   ''
            // );
          },
          error => {
            console.log(error);
          });
      },
      error => {
        console.log(error);
      });
  }

  sendSubjectLineFeedback() {
    // this.chatLog.push({
    //   sender: "USER",
    //   message: this.subjectLineFeedback
    // });
    let subjectLineCount = 0;
    this.showSubjectLines = true;
    this.loadingSubjectLineFeedback = true;
    // this.opportunityObject.stage = "NEW";
    this.apiService.sendSubjectLineFeedback(this.opportunityObject, this.subjectLineFeedback).subscribe(
      (data2: any) => {
        console.log(data2);
        this.subjectLines[0] = data2;
        subjectLineCount++;

        if (subjectLineCount == 3) {
          this.loadingSubjectLineFeedback = false;
        }
      });
    this.apiService.sendSubjectLineFeedback(this.opportunityObject, this.subjectLineFeedback).subscribe(
      (data2: any) => {
        console.log(data2);
        this.subjectLines[1] = data2;
        subjectLineCount++;

        if (subjectLineCount == 3) {
          this.loadingSubjectLineFeedback = false;
        }
      });
    this.apiService.sendSubjectLineFeedback(this.opportunityObject, this.subjectLineFeedback).subscribe(
      (data2: any) => {
        console.log(data2);
        this.subjectLines[2] = data2;
        subjectLineCount++;

        if (subjectLineCount == 3) {
          this.loadingSubjectLineFeedback = false;
        }
      });
    this.subjectLineFeedback = "";
    // this.apiService.sendSubjectLineFeedback(this.opportunityObject, this.subjectLineFeedback).subscribe(
    //   (data: any) => {
    //     console.log(data);

    //     this.chatLog.push({
    //       sender: "RIPPLE",
    //       message: `Ok! Based on your feedback I came up with the following subject line:`
    //     });

    //     this.chatLog.push({
    //       sender: "RIPPLE",
    //       message: `"${data.text}"`
    //     });

    //     this.chatLog.push({
    //       sender: "RIPPLE",
    //       message: data.reason
    //     });

    //     // this.editSubjectLine = false;
    //     this.opportunityObject.contentTemplate.subjectLine = data.text;
    //     this.opportunityObject.contentTemplate.subjectLineReason = data.reason;

    //     this.loadingSubjectLineFeedback = false;

    //   },
    //   error => {
    //     console.log(error);
    //   });
  }

  showChatDrawer() {
    this.chatLog.push({
      sender: "RIPPLE",
      message: "Hey there!"
    });

    this.chatLog.push({
      sender: "RIPPLE",
      message: `I generated the following subject line: "${this.opportunityObject.contentTemplate.subjectLine}"`
    });

    this.chatLog.push({
      sender: "RIPPLE",
      message: `${this.opportunityObject.contentTemplate.subjectLineReason}`
    });

    this.chatLog.push({
      sender: "RIPPLE",
      message: `How would you like to change it?`
    });

    this.chatDrawerVisible = true;
  }

  hideChatDrawer() {
    this.chatDrawerVisible = false;
  }

  sendTimeInFuture() {
    let currentDate = new Date();
    let sendTime = new Date(this.opportunityObject.sendTime);

    // Transform currentDate to UTC
    currentDate.setMinutes(currentDate.getMinutes() - currentDate.getTimezoneOffset());
    currentDate.setHours(currentDate.getHours());

    // console.log("currentDate: ", currentDate);
    // console.log("sendTime: ", sendTime);

    // sendTime.setMinutes(sendTime.getMinutes() - sendTime.getTimezoneOffset());
    // sendTime.setHours(sendTime.getHours());

    // console.log("currentDate2: ", currentDate);
    // console.log("sendTime2: ", sendTime);

    if (currentDate < sendTime) {
      return true;
    } else {
      return false;
    }
  }

  getPropertyValue(block: any, propertyName: any) {
    // console.log("get property value");
    let property = block.properties.filter((obj: any) => {
      return obj.name === propertyName;
    });
    // console.log(property);

    return property[0].value;
  }

  getProperty(block: any, propertyName: any) {
    // console.log("get property value");
    let property = block.properties.filter((obj: any) => {
      return obj.name === propertyName;
    });
    // console.log(property);

    return property[0];
  }

  saveOpportunity() {
    this.saving = true;
    this.apiService.saveOpportunity(this.opportunityObject).subscribe(
      (data: any) => {
        console.log(data);
        this.message.create("success", `Changes saved`);
        this.saving = false;
        return "";
      },
      error => {
        console.log(error);
      });
  }

  getArrayItem(array: any, propertyName: any) {
    let property = array.filter((obj: any) => {
      return obj.name === propertyName;
    });

    if (property[0]) {
      return property[0];
    } else {
      return "";
    }
  }

  isEven(n: any) {
    return n % 2 == 0;
  }

  generateImage(id: any) {
    var node: any = document.getElementById(id);
    htmlToImage.toPng(node)
      .then(function (dataUrl) {
        var img = new Image();
        console.log(img);
        img.src = dataUrl;
        return img;
        // document.body.appendChild(img);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  generateEmailImages() {

  }

  changeSelect() {
    console.log(this.opportunityObject);
  }

  getKlaviyoListsAndSegments() {
    this.loadingSegments = true;
    this.apiService.getKlaviyoListsAndSegments().subscribe(
      (data: any) => {
        console.log("LISTS AND SEGMENTS", data);
        // this.loadingSegments = false;
        this.klaviyoListsAndSegments = data;

        // this.opportunityObject.audienceInclude = this.opportunityObject.audienceInclude.filter((ar: any) => this.klaviyoListsAndSegments.find((rm: any) => (rm.id === ar.id) ))
        // this.opportunityObject.audienceExclude = this.opportunityObject.audienceExclude.filter((ar: any) => this.klaviyoListsAndSegments.find((rm: any) => (rm.id === ar.id) ))

        // if (!this.opportunityObject.audienceInclude || this.opportunityObject.audienceInclude.length == 0) {
        //   this.opportunityObject.audienceInclude = [this.klaviyoListsAndSegments[0]];
        // }
      },
      error => {
        console.log(error);
      });
  }

  compareFn = (o1: any, o2: any): boolean => (o1 && o2 ? o1.id === o2.id : o1 === o2);

  compareFn2 = (o1: any, o2: any): boolean => (o1 && o2 ? o1 == o2 : o1 == o2);

  compareFn3 = (o1: any, o2: any): boolean => (o1 && o2 ? o1 === o2 : o1 === o2);

  async duplicateOpportunity() {
    this.duplicating = true;

    this.apiService.duplicateOpportunity(this.opportunityObject._id).subscribe(
      (data: any) => {
        console.log(data);
        this.message.create("success", `Duplicated your email`);
        this.duplicating = false;
      },
      error => {
        console.log(error);
      });
  }

  async deleteOpportunity() {
    this.deleting = true;

    this.apiService.deleteOpportunity(this.opportunityObject._id).subscribe(
      (data: any) => {
        console.log(data);
        this.message.create("success", `Deleted your email`);
        this.router.navigate(['calendar']);
        this.deleting = false;
      },
      error => {
        console.log(error);
      });
  }

  handleBackButton() {
    if (this.opportunityObject.flow) {
      this.router.navigate([`/flows/${this.opportunityObject.flow}`]);
    } else if (this.backMode == "e") {
      this.router.navigate([`/email`]);
    } else if (this.backMode == "flow") {
      this.router.navigate([`/flows`]);
    } else if (this.backMode == "create") {
      this.router.navigate([`/create/email`]);
    } else if (this.backMode == "o") {
      this.router.navigate([`/home`]);
    } else if (this.backMode == "templates") {
      this.router.navigate([`/content/templates`]);
    } else {
      this.router.navigate(['calendar']);
    }
  }

  changeTime($event: any) {
    console.log("newdate 1 ", $event);
    let newDate = new Date($event);
    console.log("newdate 2 ", newDate);
    let sendTime = new Date(this.opportunityObject.sendTime);
    console.log("Sendtime 1 ", sendTime);
    newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
    newDate.setHours(newDate.getHours());
    console.log("newdate 3 ", newDate);
    sendTime.setHours(newDate.getHours());
    sendTime.setMinutes(newDate.getMinutes());
    this.opportunityObject.sendTime = sendTime;
  }

  disablePastDates = (current: Date): boolean => {
    // Get the current date without the time component
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Get the selected date without the time component
    const selectedDate = new Date(current);
    selectedDate.setHours(0, 0, 0, 0);

    // Disable dates before today
    return selectedDate < today;
  };

  addFollowUp() {
    if (!this.opportunityObject.followUps) {
      this.opportunityObject.followUps = [];
    }

    this.opportunityObject.followUps.push(this.newFollowUpObject);
  }

  async addSegmentVersion() {
    this.addingSegmentVersion = true;
    if (!this.opportunityObject.segmentVersions) {
      this.opportunityObject.segmentVersions = [];
    }

    let duplicatedOpp: any = await this.apiService.duplicateOpportunityChild(this.opportunityObject._id).toPromise();
    console.log(duplicatedOpp);

    let newSegmentVersion = JSON.parse(JSON.stringify(this.newSegmentVersionObject));
    newSegmentVersion.opportunity = duplicatedOpp._id;

    this.opportunityObject.segmentVersions.push(JSON.parse(JSON.stringify(newSegmentVersion)));

    this.apiService.saveOpportunity(this.opportunityObject).subscribe(
      (data: any) => {
        console.log(data);
        this.addingSegmentVersion = false;
      },
      error => {
        console.log(error);
      });
  }

  async generateSegmentVersion(segmentVersion: any) {
    segmentVersion.loading = true;

    // let updatedOpp: any = await this.apiService.generateTemplateContent2(duplicatedOpp._id, "REMIXCOPY", segmentVersion.feedback).toPromise();
    // console.log(updatedOpp);

    // segmentVersion.opportunity = duplicatedOpp.result._id;
    segmentVersion.loading = false;
  }

  async toggleFollowUpUniqueContent(followUp: any) {
    if (!followUp.opportunity && followUp.uniqueContent) {
      followUp.addingUniqueContent = true;
      let duplicatedOpp: any = await this.apiService.duplicateOpportunityChild(this.opportunityObject._id).toPromise();
      followUp.opportunity = duplicatedOpp._id;
      followUp.addingUniqueContent = false;
      this.apiService.saveOpportunity(this.opportunityObject).subscribe(
        (data: any) => {
          console.log(data);
        },
        error => {
          console.log(error);
        });
    }
  }

  removeItemFromArray(index: any, list: Array<any>) {
    list.splice(index, 1);
  }

  popoverChange(event: any, block: any) {
    // console.log(event);
    if (event == false) {
      block.controlsVisible = false;
      // this.newRefreshContentBlock = null;
      // this.showRefreshPreview = false;
    }
  }

  selectSubjectLine(subjectLineL: any) {
    this.opportunityObject.contentTemplate.subjectLine = subjectLineL.text;
    this.subjectLinePopoverVisible = false;
  }

  sendTestEmail() {

    this.sharedService.sendTestEmailOpen = true;
    this.sharedService.sendTestEmailObject = this.opportunityObject;

    // let id = this.message.loading('Exporting email...', { nzDuration: 0 }).messageId;
    // this.sendingPreview = true;
    // this.apiService.saveOpportunity(this.opportunityObject).subscribe(
    //   (data: any) => {
    //     console.log(data);

    //     this.apiService.publishOpportunity(this.opportunityObject._id, this.opportunityObject, "HTML").subscribe(
    //       (data: any) => {
    //         console.log(data);
    //         this.opportunityObject = data;
    //         this.sendingPreview = false;
    //         this.message.remove(id);
    //         let id2 = this.message.loading('Sending preview...', { nzDuration: 0 }).messageId;
    //         this.message.remove(id2);
    //         this.apiService.sendTestEmail(this.opportunityObject._id).subscribe(
    //           (data: any) => {
    //             console.log(data);
    //             this.message.create("success", `Preview sent`);
    //           },
    //           error => {
    //             console.log(error);
    //           });
    //         // this.sharedService.htmlOpen = true;
    //         // this.sharedService.htmlBody = data.contentTemplate.html;
    //         // this.notification.create(
    //         //   'success',
    //         //   'Your changes have been published to Klaviyo!',
    //         //   ''
    //         // );
    //       },
    //       error => {
    //         console.log(error);
    //       });
    //   },
    //   error => {
    //     console.log(error);
    //   });
  }

  openNewTemplate() {
    this.sharedService.createTemplateOpen = true;
    this.sharedService.createTemplateOppId = this.opportunityObject._id;
  }

  createEmailFromPlaceholder() {
    this.sharedService.createEmailPlaceholderMode = true;
    this.sharedService.createEmailPlaceholderModeObject = this.opportunityObject;
    this.router.navigate([`/create/email`]);
  }

  generateSMSFromEmail() {
    let smsPrompt = `Make me an SMS message based on the following email: title: ${this.opportunityObject.contentTemplate.title}, subject line: ${this.opportunityObject.contentTemplate.subjectLine}, preview text: ${this.opportunityObject.contentTemplate.previewText}`
    this.sharedService.smsPrompt = smsPrompt;
    this.router.navigate([`/create/sms`]);
  }

  postToFacebook() {
    this.apiService.createFacebookPost(this.opportunityObject._id).subscribe(
      (data: any) => {
        console.log(data);
        this.opportunityObject = data;
        this.message.create("success", `Posted to Facebook`);
      },
      error => {
        console.log(error);
      });
  }

  publish() {
    const id = this.message.loading('Publishing...', { nzDuration: 0 }).messageId;
    this.apiService.publishSocialPost(this.opportunityObject._id).subscribe(
      (data: any) => {
        console.log(data);
        this.opportunityObject = data;
        this.message.remove(id);
        this.message.create("success", `Posted to Facebook`);
      },
      error => {
        console.log(error);
      });
  }
}
