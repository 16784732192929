<div class="page-wrapper">
    <div class="content-wrapper">


        <div class="fullscreen-header">
            <ng-container *ngIf="!this.loading">

                <nz-page-header nzBackIcon (nzBack)="handleBackButton()" class="site-page-header">

                    <nz-page-header-title>
                        {{opportunityObject.title}}
                    </nz-page-header-title>

                    <nz-page-header-tags>
                        <ng-container *ngIf="this.opportunityObject.stage == 'LIVE'">

                            <ng-container *ngIf="sendTimeInFuture()">
                                <nz-tag class="aqua">Published</nz-tag>
                            </ng-container>
                            <ng-container *ngIf="!sendTimeInFuture()">
                                <nz-tag class="aqua">Published</nz-tag>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="this.opportunityObject.stage == 'PENDING'">
                            <nz-tag>Draft</nz-tag>
                        </ng-container>

                    </nz-page-header-tags>
                    <nz-page-header-extra>
                        <ng-container *ngIf="this.opportunityObject.stage != 'PLACEHOLDER'">
                            <button nz-button (click)="saveOpportunity()" [nzLoading]="saving"
                                style="margin-right: 8px;">Save</button>
                            <nz-button-group>
                                <button nz-button nzType="primary" [nzLoading]="publishingOpportunity"
                                    (click)="publishOpportunity()"><span nz-icon nzType="upload"
                                        nzTheme="outline"></span>Publish</button>
                                <button nzType="primary" nz-button nz-dropdown [nzDropdownMenu]="menu1"
                                    nzPlacement="bottomRight">
                                    <span nz-icon nzType="ellipsis"></span>
                                </button>
                            </nz-button-group>
                        </ng-container>

                        <nz-dropdown-menu #menu1="nzDropdownMenu">
                            <ul nz-menu style="width: 225px;">
                                <li nz-menu-item (click)="publishOpportunityDraft()"><span nz-icon nzType="upload"
                                        nzTheme="outline"></span>&nbsp;&nbsp;Publish as Draft
                                </li>
                                <li nz-menu-divider></li>
                                <li nz-menu-item (click)="duplicateOpportunity()"><span nz-icon nzType="copy"
                                        nzTheme="outline"></span>&nbsp;&nbsp;Duplicate</li>
                                <li nz-menu-item (click)="deleteOpportunity()"><span nz-icon nzType="delete"
                                        nzTheme="outline"></span>&nbsp;&nbsp;Delete</li>
                            </ul>
                        </nz-dropdown-menu>

                        <!-- <nz-dropdown-menu #menu4="nzDropdownMenu">
                                <ul nz-menu>

                                </ul>
                            </nz-dropdown-menu> -->

                    </nz-page-header-extra>

                </nz-page-header>
            </ng-container>

            <ng-container *ngIf="this.loading">

            </ng-container>

        </div>
        <div class="content-outer">
            <ng-container *ngIf="this.loading">
                <div class="loading-wrapper">
                    <div class="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!this.loading">
                <div class="content-inner">
                    <div class="inner">
                        <ng-container
                            *ngIf="(opportunityObject.externalData && opportunityObject.externalData.klaviyoCampaign) || (opportunityObject.externalData && opportunityObject.externalData.klaviyoTemplate)">
                            <ng-container
                                *ngIf="(opportunityObject.externalData && opportunityObject.externalData.klaviyoTemplate) && (opportunityObject.externalData && !opportunityObject.externalData.klaviyoCampaign)">

                                <div class="custom-card">
                                    <div class="custom-card-body" style="line-height: 36px">
                                        Synced to Klaviyo template
                                        <nz-tag>{{opportunityObject.externalData.klaviyoTemplate}}</nz-tag>
                                        <a href="https://klaviyo.com/email-editor/{{opportunityObject.externalData.klaviyoTemplate}}/edit"
                                            target="_blank" style="float: right"><button nz-button><span nz-icon
                                                    nzType="link" nzTheme="outline"></span>View
                                                in Klaviyo</button></a>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container
                                *ngIf="(opportunityObject.externalData && opportunityObject.externalData.klaviyoCampaign)">

                                <div class="custom-card">
                                    <div class="custom-card-body" style="line-height: 36px">
                                        Synced to Klaviyo campaign
                                        <nz-tag>{{opportunityObject.externalData.klaviyoCampaign}}</nz-tag>
                                        <a href="https://klaviyo.com/campaign/{{opportunityObject.externalData.klaviyoCampaign}}/scheduled"
                                            target="_blank" style="float: right"><button nz-button><span nz-icon
                                                    nzType="link" nzTheme="outline"></span>View
                                                in Klaviyo</button></a>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <div class="custom-card">
                            <div class="custom-card-body">

                                <nz-form-item style="margin-bottom: 0px !important;">
                                    <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="20">
                                        Message
                                    </nz-form-label>

                                    <nz-form-control nzSpan="24">
                                        <ng-container
                                            *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                            <nz-row [nzGutter]="8">
                                                <nz-col [nzSpan]="24">
                                                    <nz-textarea-count [nzMaxCharacterCount]="140">
                                                        <textarea nz-input name="message"
                                                            [(ngModel)]="opportunityObject.message"
                                                            style="resize: none"></textarea>
                                                    </nz-textarea-count>
                                                </nz-col>
                                                <!-- <nz-col [nzSpan]="2">
                                            <button nz-button nzBlock nz-popover
                                                [nzPopoverContent]="contentTemplate2"
                                                nzPopoverPlacement="bottomRight"
                                                nzPopoverTrigger="click"
                                                [(nzPopoverVisible)]="subjectLinePopoverVisible"><span
                                                    nz-icon nzType="sync"
                                                    nzTheme="outline"></span></button>
                                            <ng-template #contentTemplate2>
                                                <div
                                                    style="margin: -12px -16px; overflow: hidden; border-radius: 10px; width: 500px;">
                                                    <div class="custom-modal-header">
                                                        Regenerate subject line
                                                    </div>
                                                    <div class="custom-modal-body">
                                                        <textarea [(ngModel)]="subjectLineFeedback"
                                                            nz-input
                                                            placeholder="Ex: Make the copy more fun"
                                                            style="height: 60px;"
                                                            name="subjectLineFeedback"></textarea>
                                                        <div class="loading-wrapper"
                                                            *ngIf="this.loadingSubjectLineFeedback"
                                                            style="text-align: center; padding: 24px !important;">
                                                            <img src="../../assets/magicWand.gif"
                                                                style="width: 125px; height: 125px; margin: auto; margin-bottom: 16px;" />
                                                            Generating content...
                                                        </div>
                                                        <ng-container
                                                            *ngIf="!this.loadingSubjectLineFeedback && showSubjectLines">
                                                            <div style="margin-top: 8px;">
                                                                <ng-container
                                                                    *ngFor="let subjectLine of this.subjectLines">
                                                                    <button nz-button
                                                                        (click)="selectSubjectLine(subjectLine)"
                                                                        nzBlock
                                                                        style="margin-top: 8px;">
                                                                        {{subjectLine.text}}
                                                                    </button>
                                                                </ng-container>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                    <div class="custom-modal-footer">
                                                        <div style="text-align: right">
                                                            <button nz-button nzType="primary"
                                                                (click)="sendSubjectLineFeedback()"
                                                                [nzLoading]="loadingSubjectLineFeedback">Send</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </nz-col> -->
                                            </nz-row>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                            {{opportunityObject.message}}
                                        </ng-container>


                                        <!-- <ng-container *ngIf="this.editSubjectLine">
                <div class="feedback-wrapper">
                    <textarea placeholder="Enter feedback for Copilot" rows="2" nz-input
                        [(ngModel)]="subjectLineFeedback"></textarea>
                    <button style="float: right" nz-button (click)="sendSubjectLineFeedback()"
                        [nzLoading]="loadingSubjectLineFeedback">Send</button>
                </div>
            </ng-container> -->
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                        <div class="custom-card">
                            <div class="custom-card-body">
                                <ng-container *ngIf="!this.loading">
                                    <form nz-form nzLayout="vertical">
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">Send
                                                time
                                            </nz-form-label>
                                            <nz-form-control nzSpan="24">
                                                <div nz-row [nzGutter]="16">
                                                    <div nz-col class="gutter-row" [nzSpan]="14">
                                                        <ng-container>
                                                            <nz-date-picker name="sendTime"
                                                                [(ngModel)]="opportunityObject.sendTime"
                                                                nzFormat="yyyy-MM-dd" style="width:100%;"
                                                                [nzDisabledDate]="disablePastDates"
                                                                [disabled]="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()"></nz-date-picker>
                                                        </ng-container>
                                                        <!-- <ng-container
                                                                *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                                {{opportunityObject.sendTime | date:'EEEE M/d/YY h:mm
                                                                a'}}
                                                            </ng-container> -->


                                                    </div>
                                                    <div nz-col class="gutter-row" [nzSpan]="10">
                                                        <ng-container>
                                                            <nz-time-picker name="sendTimeHours"
                                                                [(ngModel)]="opportunityObject.sendTimeHours"
                                                                [nzMinuteStep]="15" nzFormat="HH:mm" style="width:100%;"
                                                                (ngModelChange)="changeTime($event)"
                                                                [disabled]="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()"></nz-time-picker>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </nz-form-control>
                                        </nz-form-item>

                                        <div nz-row [nzGutter]="16">
                                            <div nz-col class="gutter-row" [nzSpan]="12">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="20">
                                                        <nz-switch [(ngModel)]="this.opportunityObject.localTime"
                                                            name="localTime"
                                                            [disabled]="this.opportunityObject.externalData?.klaviyoCampaign"></nz-switch>&nbsp;&nbsp;Send
                                                        in recipient's
                                                        local time
                                                    </nz-form-label>
                                                </nz-form-item>
                                            </div>
                                            <div nz-col class="gutter-row" [nzSpan]="12">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="20">
                                                        <nz-switch [(ngModel)]="this.opportunityObject.smartSending"
                                                            name="smartSending"
                                                            [disabled]="this.opportunityObject.externalData?.klaviyoCampaign"></nz-switch>&nbsp;&nbsp;Smart
                                                        sending
                                                    </nz-form-label>
                                                </nz-form-item>
                                            </div>
                                        </div>


                                        <!-- <nz-form-item>
                                            <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                Preview text
                                            </nz-form-label>
                                            <nz-form-control nzSpan="24">
                                                <ng-container
                                                    *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                    <textarea nz-input name="previewtext"
                                                        [(ngModel)]="opportunityObject.contentTemplate.previewText"
                                                        [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                    {{opportunityObject.contentTemplate.previewText}}
                                                </ng-container>
                                            </nz-form-control>

                                        </nz-form-item> -->
                                    </form>

                                    <form nz-form nzLayout="vertical">
                                        <div nz-row [nzGutter]="16">
                                            <div nz-col class="gutter-row" [nzSpan]="12">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true" nzLabelAlign="left"
                                                        nzSpan="24">Included
                                                        segments
                                                    </nz-form-label>
                                                    <nz-form-control nzSpan="24"
                                                        [nzValidateStatus]="segmentIncludeStatus"
                                                        nzErrorTip="You must select at least one segment to include">

                                                        <ng-container
                                                            *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                            <nz-select nzPlaceHolder="Choose" [compareWith]="compareFn"
                                                                [(ngModel)]="this.opportunityObject.audienceInclude"
                                                                (ngModelChange)="this.changeSelect()"
                                                                name="audienceInclude" nzMode="multiple">
                                                                <ng-container
                                                                    *ngFor="let segment of klaviyoListsAndSegments">
                                                                    <nz-option nzCustomContent
                                                                        [nzLabel]="segment.attributes.name"
                                                                        [nzValue]="segment">
                                                                        {{segment.attributes.name}} <nz-tag
                                                                            style="float: right"
                                                                            class="aqua">{{segment.type
                                                                            | titlecase}}</nz-tag>
                                                                    </nz-option>
                                                                </ng-container>

                                                                <!-- <nz-option nzLabel="Engaged non-purchasers" nzValue="2"></nz-option>
                                                        <nz-option nzLabel="One-time purchasers" nzValue="3"></nz-option>
                                                        <nz-option nzLabel="Frequent buyers" nzValue="4"></nz-option>
                                                        <nz-option nzLabel="Dormant purchasers" nzValue="5"></nz-option>
                                                        <nz-option nzLabel="Lapsed subscribers" nzValue="6"></nz-option>
                                                        <nz-option nzLabel="VIPs" nzValue="7"></nz-option>
                                                        <nz-option nzLabel="Seasonal shoppers" nzValue="8"></nz-option> -->
                                                            </nz-select>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                            <nz-select disabled nzPlaceHolder="Choose"
                                                                [compareWith]="compareFn"
                                                                [(ngModel)]="this.opportunityObject.audienceInclude"
                                                                (ngModelChange)="this.changeSelect()"
                                                                name="audienceInclude" nzMode="multiple">
                                                                <ng-container
                                                                    *ngFor="let segment of klaviyoListsAndSegments">
                                                                    <nz-option nzCustomContent
                                                                        [nzLabel]="segment.attributes.name"
                                                                        [nzValue]="segment">
                                                                        {{segment.attributes.name}} <nz-tag
                                                                            style="float: right"
                                                                            class="aqua">{{segment.type
                                                                            | titlecase}}</nz-tag>
                                                                    </nz-option>
                                                                </ng-container>

                                                                <!-- <nz-option nzLabel="Engaged non-purchasers" nzValue="2"></nz-option>
                                                        <nz-option nzLabel="One-time purchasers" nzValue="3"></nz-option>
                                                        <nz-option nzLabel="Frequent buyers" nzValue="4"></nz-option>
                                                        <nz-option nzLabel="Dormant purchasers" nzValue="5"></nz-option>
                                                        <nz-option nzLabel="Lapsed subscribers" nzValue="6"></nz-option>
                                                        <nz-option nzLabel="VIPs" nzValue="7"></nz-option>
                                                        <nz-option nzLabel="Seasonal shoppers" nzValue="8"></nz-option> -->
                                                            </nz-select>
                                                        </ng-container>
                                                        <!-- <ng-container *ngIf="this.opportunityObject.stage == 'PENDING'">
                                        <button style="float: right" nz-button><span nz-icon nzType="edit"
                                                nzTheme="outline"></span>Edit</button>
                                    </ng-container> -->
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <div nz-col class="gutter-row" [nzSpan]="12">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true" nzLabelAlign="left"
                                                        nzSpan="24">Excluded
                                                        segments
                                                    </nz-form-label>
                                                    <nz-form-control nzSpan="24">

                                                        <ng-container
                                                            *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                            <nz-select nzPlaceHolder="Choose" [compareWith]="compareFn"
                                                                [(ngModel)]="this.opportunityObject.audienceExclude"
                                                                (ngModelChange)="this.changeSelect()"
                                                                name="audienceExclude" nzMode="multiple">
                                                                <ng-container
                                                                    *ngFor="let segment of klaviyoListsAndSegments">
                                                                    <nz-option nzCustomContent
                                                                        [nzLabel]="segment.attributes.name"
                                                                        [nzValue]="segment">
                                                                        {{segment.attributes.name}} <nz-tag
                                                                            style="float: right"
                                                                            class="aqua">{{segment.type
                                                                            | titlecase}}</nz-tag>
                                                                    </nz-option>
                                                                </ng-container>

                                                            </nz-select>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                            <nz-select disabled nzPlaceHolder="Choose"
                                                                [compareWith]="compareFn"
                                                                [(ngModel)]="this.opportunityObject.audienceExclude"
                                                                (ngModelChange)="this.changeSelect()"
                                                                name="audienceExclude" nzMode="multiple">
                                                                <ng-container
                                                                    *ngFor="let segment of klaviyoListsAndSegments">
                                                                    <nz-option nzCustomContent
                                                                        [nzLabel]="segment.attributes.name"
                                                                        [nzValue]="segment">
                                                                        {{segment.attributes.name}} <nz-tag
                                                                            style="float: right"
                                                                            class="aqua">{{segment.type
                                                                            | titlecase}}</nz-tag>
                                                                    </nz-option>
                                                                </ng-container>
                                                            </nz-select>
                                                        </ng-container>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                        </div>



                                    </form>

                                    <!-- <nz-form-item>
                                                <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                    Follow ups
                                                </nz-form-label>
                                                <nz-form-control nzSpan="24">
                                                    <ng-container
                                                        *ngFor="let followUp of this.opportunityObject.followUps; let index=index">
                                                        <div class="custom-card">
                                                            <div class="custom-card-header" style="text-align: right">
                                                                <span nz-icon nzType="close" nzTheme="outline"
                                                                    class="remove"
                                                                    (click)="removeItemFromArray(index, this.opportunityObject.followUps)"></span>
                                                            </div>
                                                            <div class="custom-card-body" style="line-height: 36px">
                                                                <div nz-row [nzGutter]="16">
                                                                    <div nz-col class="gutter-row" [nzSpan]="6">
                                                                        <nz-form-item>
                                                                            <nz-form-label [nzNoColon]="true"
                                                                                nzLabelAlign="left" nzSpan="24">
                                                                                Delay (hours)
                                                                            </nz-form-label>
                                                                            <nz-form-control nzSpan="24">
                                                                                <nz-input-number style="width: 100%;"
                                                                                    [(ngModel)]="followUp.delay"></nz-input-number>
                                                                            </nz-form-control>
                                                                        </nz-form-item>
                                                                    </div>
                                                                    <div nz-col class="gutter-row" [nzSpan]="18">
                                                                        <nz-form-item>
                                                                            <nz-form-label [nzNoColon]="true"
                                                                                nzLabelAlign="left" nzSpan="24">
                                                                                Criteria
                                                                            </nz-form-label>
                                                                            <nz-form-control nzSpan="24">
                                                                                <div nz-row [nzGutter]="16">
                                                                                    <div nz-col class="gutter-row"
                                                                                        [nzSpan]="24">
                                                                                        <nz-select
                                                                                            [(ngModel)]="followUp.criteria"
                                                                                            [compareWith]="compareFn2">
                                                                                            <nz-option
                                                                                                [nzValue]="'OPENEDEMAIL'"
                                                                                                nzLabel="Did not open email"></nz-option>
                                                                                            <nz-option
                                                                                                [nzValue]="'CLICKEDEMAIL'"
                                                                                                nzLabel="Opened email but did not click"></nz-option>
                                                                                            <nz-option
                                                                                                [nzValue]="'PLACEDORDER'"
                                                                                                nzLabel="Clicked email but did not place order"></nz-option>
                                                                                        </nz-select>
                                                                                    </div>
                                                                                </div>
                                                                            </nz-form-control>
                                                                        </nz-form-item>
                                                                    </div>
                                                                </div>
                                                                <nz-form-item>
                                                                    <nz-form-label [nzNoColon]="true"
                                                                        nzLabelAlign="left" nzSpan="24">
                                                                        Subject line
                                                                    </nz-form-label>
                                                                    <nz-form-control nzSpan="24">
                                                                        <ng-container
                                                                            *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                                            <textarea nz-input name="subjectLine"
                                                                                [(ngModel)]="followUp.subjectLine"
                                                                                [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                                            {{followUp.subjectLine}}
                                                                        </ng-container>
                                                                    </nz-form-control>
                                                                </nz-form-item>
                                                                <nz-form-item>
                                                                    <nz-form-label [nzNoColon]="true"
                                                                        nzLabelAlign="left" nzSpan="24">
                                                                        Unique content
                                                                    </nz-form-label>
                                                                    <nz-form-control nzSpan="24">
                                                                        <div nz-row [nzGutter]="16">
                                                                            <div nz-col class="gutter-row" [nzSpan]="12"
                                                                                style="line-height: 36px">
                                                                                <nz-switch
                                                                                    [(ngModel)]="followUp.uniqueContent"
                                                                                    [nzLoading]="followUp.addingUniqueContent"
                                                                                    (ngModelChange)="toggleFollowUpUniqueContent(followUp)"></nz-switch>
                                                                            </div>
                                                                            <div nz-col class="gutter-row" [nzSpan]="12"
                                                                                style="text-align: right">
                                                                                <button *ngIf="followUp.uniqueContent"
                                                                                    nz-button
                                                                                    [routerLink]="['/editor',followUp.opportunity]"
                                                                                    nzBock> <span nz-icon nzType="edit"
                                                                                        nzTheme="outline"></span>Edit
                                                                                    content</button>
                                                                            </div>
                                                                        </div>
                                                                    </nz-form-control>
                                                                </nz-form-item>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <button nz-button nzBlock (click)="addFollowUp()"><span nz-icon
                                                            nzType="plus" nzTheme="outline"></span>Add
                                                        follow
                                                        up</button>
                                                </nz-form-control>

                                            </nz-form-item>
                                            <nz-divider></nz-divider> -->


                                </ng-container>
                            </div>
                        </div>
                        <div class="custom-card">
                            <div class="custom-card-body">
                                <nz-form-item>
                                    <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                        Title
                                    </nz-form-label>
                                    <nz-form-control nzSpan="24">
                                        <input nz-input name="title" [(ngModel)]="opportunityObject.title" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <!-- <div *ngIf="this.sharedService.publishingEmailOpen">Publishing...</div> -->


        <ng-template #tagPlaceHolder let-selectedList>...</ng-template>
        <ng-template #contentTemplate>
            <div>
                <nz-form-item style="width: 300px; margin-bottom: 8px !important;">
                    <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                        Feedback (optional)
                    </nz-form-label>
                    <nz-form-control nzSpan="24">
                        <textarea [(ngModel)]="subjectLineFeedback" nz-input placeholder="Ex: Make the copy more fun"
                            style="height: 100px;"></textarea>
                    </nz-form-control>
                </nz-form-item>
                <div style="text-align: right">
                    <button nzType="primary" nz-button (click)="sendSubjectLineFeedback()">Send</button>
                </div>
                <!-- <div style="text-align: right">
            <button nzType="primary" nz-button nz-popconfirm
            nzPopconfirmTitle="This will overwrite any changes you've made in the editor"
            (nzOnConfirm)="sendSubjectLineFeedback()"
            nzPopconfirmPlacement="bottomRight">Send</button>
        </div> -->
            </div>
        </ng-template>