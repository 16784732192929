<ng-container *ngIf="data">
    <!-- CONTAINER Component -->
    <ng-container *ngIf="data.type == 'PIXELS'">
        <nz-form-item>
            <nz-form-label [nzNoColon]="true">{{ data.name | titlecase}}</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <input nz-input [(ngModel)]="data.value" />
            </nz-form-control>
        </nz-form-item>
    </ng-container>

    <ng-container *ngIf="data.type == 'RICHTEXT'">
        <nz-form-item>
            <nz-form-label [nzNoColon]="true">{{ data.name | titlecase}}</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <textarea nz-input [(ngModel)]="data.value" rows="2"></textarea>
            </nz-form-control>
        </nz-form-item>
    </ng-container>

    <ng-container *ngIf="data.type == 'COLOR'">
        <ng-container *ngIf="mode=='sidebar'">
            <nz-form-item>
                <nz-form-label [nzNoColon]="true">{{ data.name | titlecase}}</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <div>
                        <ngx-colors ngx-colors-trigger [(ngModel)]="data.value" style="height: 30px;"
                            (ngModelChange)="setQuillColor(data)"></ngx-colors>
                    </div>
                </nz-form-control>
            </nz-form-item>
        </ng-container>
        <ng-container *ngIf="mode=='toolbar'">
            <ngx-colors ngx-colors-trigger [(ngModel)]="data.value" style="height: 30px;"
                (ngModelChange)="setQuillColor(data)"></ngx-colors>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="data.type == 'ALIGNMENT'">
        <ng-container *ngIf="mode=='sidebar'">
            <nz-form-item>
                <nz-form-label [nzNoColon]="true">{{ data.name | titlecase}}</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <div>
                        <nz-radio-group [(ngModel)]="data.value" style="width: 100%;">
                            <label nz-radio-button nzValue="left" (click)="setQuillAlignment('left')" style="width: 33.33%; text-align: center;"><span nz-icon
                                    nzType="align-left" nzTheme="outline"></span></label>
                            <label nz-radio-button nzValue="center" (click)="setQuillAlignment('center')" style="width: 33.33%; text-align: center;"><span nz-icon
                                    nzType="align-center" nzTheme="outline"></span></label>
                            <label nz-radio-button nzValue="right" (click)="setQuillAlignment('right')" style="width: 33.33%; text-align: center;"><span nz-icon
                                    nzType="align-right" nzTheme="outline"></span></label>
                        </nz-radio-group>
                    </div>
                </nz-form-control>
            </nz-form-item>
        </ng-container>
        <ng-container *ngIf="mode=='toolbar'">
            <nz-radio-group [(ngModel)]="data.value">
                <label nz-radio-button nzValue="left" (click)="setQuillAlignment('left')"><span nz-icon
                        nzType="align-left" nzTheme="outline"></span></label>
                <label nz-radio-button nzValue="center" (click)="setQuillAlignment('center')"><span nz-icon
                        nzType="align-center" nzTheme="outline"></span></label>
                <label nz-radio-button nzValue="right" (click)="setQuillAlignment('right')"><span nz-icon
                        nzType="align-right" nzTheme="outline"></span></label>
            </nz-radio-group>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="data.type == 'TEXTSTYLES'">
        <ng-container *ngIf="mode=='sidebar'">

            <nz-form-item>
                <nz-form-label [nzNoColon]="true">{{ data.name | titlecase }}</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <div>
                        <nz-button-group style="margin-right: 8px;">
                            <button nz-button (click)="setQuillBold()"><span nz-icon nzType="bold"
                                    nzTheme="outline"></span></button>
                            <button nz-button (click)="setQuillItalic()"><span nz-icon nzType="italic"
                                    nzTheme="outline"></span></button>
                            <button nz-button (click)="setQuillUnderline()"><span nz-icon nzType="underline"
                                    nzTheme="outline"></span></button>
                            <button nz-button (click)="setQuillStrikethrough()"><span nz-icon nzType="strikethrough"
                                    nzTheme="outline"></span></button>
                        </nz-button-group>

                        <nz-button-group>
                            <button nz-button (click)="setQuillBullets()"><span nz-icon nzType="unordered-list"
                                    nzTheme="outline"></span></button>
                            <button nz-button (click)="setQuillOrderedList()"><span nz-icon nzType="ordered-list"
                                    nzTheme="outline"></span></button>
                        </nz-button-group>
                    </div>
                </nz-form-control>
            </nz-form-item>
        </ng-container>
        <ng-container *ngIf="mode=='toolbar'">
            <nz-button-group style="margin-right: 8px;">
                <button nz-button (click)="setQuillBold()"><span nz-icon nzType="bold"
                        nzTheme="outline"></span></button>
                <button nz-button (click)="setQuillItalic()"><span nz-icon nzType="italic"
                        nzTheme="outline"></span></button>
                <button nz-button (click)="setQuillUnderline()"><span nz-icon nzType="underline"
                        nzTheme="outline"></span></button>
                <button nz-button (click)="setQuillStrikethrough()"><span nz-icon nzType="strikethrough"
                        nzTheme="outline"></span></button>
            </nz-button-group>

            <nz-button-group>
                <button nz-button (click)="setQuillBullets()"><span nz-icon nzType="unordered-list"
                        nzTheme="outline"></span></button>
                <button nz-button (click)="setQuillOrderedList()"><span nz-icon nzType="ordered-list"
                        nzTheme="outline"></span></button>
            </nz-button-group>
        </ng-container>
    </ng-container>

    <!-- <ng-container *ngIf="data.type == 'ALIGNMENT'">
        <nz-form-item>
            <nz-form-label [nzNoColon]="true">{{ data.name }}</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <div>
                    <nz-radio-group [(ngModel)]="data.value">
                        <label nz-radio-button nzValue="left"><span nz-icon nzType="align-left"
                                nzTheme="outline"></span></label>
                        <label nz-radio-button nzValue="center"><span nz-icon nzType="align-center"
                                nzTheme="outline"></span></label>
                        <label nz-radio-button nzValue="right"><span nz-icon nzType="align-right"
                                nzTheme="outline"></span></label>
                    </nz-radio-group>
                </div>
            </nz-form-control>
        </nz-form-item>
    </ng-container> -->

    <ng-container *ngIf="data.type == 'PADDING'">
        <nz-form-item>
            <nz-form-label [nzNoColon]="true">{{
                data.name | titlecase
                }}</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <nz-input-group>
                    <input nz-input [(ngModel)]="data.paddingTop" style="width: 25%" />
                    <input nz-input [(ngModel)]="data.paddingRight" style="width: 25%; border-left: none;" />
                    <input nz-input [(ngModel)]="data.paddingBottom" style="width: 25%; border-left: none;" />
                    <input nz-input [(ngModel)]="data.paddingLeft" style="width: 25%; border-left: none;" />
                </nz-input-group>
                <div nz-row>
                    <div nz-col nzSpan="6"
                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">T</div>
                    <div nz-col nzSpan="6"
                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">R</div>
                    <div nz-col nzSpan="6"
                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">B</div>
                    <div nz-col nzSpan="6"
                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">L</div>
                </div>
            </nz-form-control>
        </nz-form-item>
    </ng-container>

    <ng-container *ngIf="data.type == 'BACKGROUND'">
        <nz-form-item>
            <nz-form-label [nzNoColon]="true">{{
                data.name | titlecase
                }}</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <nz-segmented [nzOptions]="['Color', 'Image']" [(ngModel)]="selectedIndex"
                    (ngModelChange)="handleModelChange($event, data)"></nz-segmented>

                <ng-container *ngIf="data.mode == 'solid'">
                    <div>
                        <ngx-colors ngx-colors-trigger [(ngModel)]="data.value" style="height: 30px;"></ngx-colors>
                    </div>
                </ng-container>

                <ng-container *ngIf="data.mode == 'image'">
                    <div class="image-select-box">
                        <img class="image-select-chosen-image" src="{{data.value}}">
                    </div>
                    <button nz-button (click)="openPickerSingle(data, 'MEDIA')"><span nz-icon nzType="file-image"
                            nzTheme="outline"></span><span>Change</span></button>
                    <input nz-input [(ngModel)]="data.backgroundPosition" />
                    <input nz-input [(ngModel)]="data.backgroundSize" />
                </ng-container>
            </nz-form-control>
        </nz-form-item>
    </ng-container>

    <ng-container *ngIf="data.type == 'BORDER'">
        <nz-form-item>
            <nz-form-label [nzNoColon]="true">{{
                data.name | titlecase
                }}</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <div nz-row>
                    <div nz-col nzSpan="8" style="color: #919191;">
                        Color
                    </div>
                    <div nz-col nzSpan="16">
                        <ngx-colors ngx-colors-trigger [(ngModel)]="data.borderColor" style="height: 30px;"></ngx-colors>
                    </div>
                </div>
                <div nz-row>
                    <div nz-col nzSpan="8" style="color: #919191;">
                        Width
                    </div>
                    <div nz-col nzSpan="16">
                        <input nz-input [(ngModel)]="data.borderWidth" />
                    </div>
                </div>
            </nz-form-control>
        </nz-form-item>
    </ng-container>

    <ng-container *ngIf="data.type == 'FONT'">
        <ng-container *ngIf="mode=='sidebar'">
            <nz-form-item>
                <nz-form-label [nzNoColon]="true">{{
                    data.name | titlecase
                    }}</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <div nz-row style="margin-bottom: 6px;">
                        <nz-select nzShowSearch [(ngModel)]="data.fontFamily" [compareWith]="compareFn"
                            [style.fontFamily]="data.fontFamily">
                            <ng-container *ngFor="let font of sharedService.fonts">
                                <nz-option nzCustomContent [nzValue]="font.value" [nzLabel]="font.value"><span
                                        [style.fontFamily]="font.value">{{font.value}}</span></nz-option>
                            </ng-container>
                        </nz-select>
                    </div>
                    <div nz-row style="margin-bottom: 4px;">
                        <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                            Font size
                        </div>
                        <div nz-col nzSpan="14">
                            <nz-button-group>
                                <button nz-button>-</button>
                                <input nz-input [(ngModel)]="data.fontSize"
                                    style="border-radius: 0px; border-left: none; border-right: none;" />
                                <button nz-button>+</button>
                            </nz-button-group>
                        </div>
                    </div>
                    <div nz-row style="margin-bottom: 4px;">
                        <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                            Font weight
                        </div>
                        <div nz-col nzSpan="14">
                            <nz-select [(ngModel)]="data.fontWeight" [compareWith]="compareFn">
                                <nz-option nzValue="100" nzLabel="Thin (100)"></nz-option>
                                <nz-option nzValue="200" nzLabel="Extra light (200)"></nz-option>
                                <nz-option nzValue="300" nzLabel="Light (300)"></nz-option>
                                <nz-option nzValue="400" nzLabel="Normal (400)"></nz-option>
                                <nz-option nzValue="500" nzLabel="Medium (500)"></nz-option>
                                <nz-option nzValue="600" nzLabel="Semi bold (600)"></nz-option>
                                <nz-option nzValue="700" nzLabel="Bold (700)"></nz-option>
                                <nz-option nzValue="800" nzLabel="Extra bold (800)"></nz-option>
                                <nz-option nzValue="900" nzLabel="Black (900)"></nz-option>
                            </nz-select>
                        </div>
                    </div>
                    <div nz-row style="margin-bottom: 4px;">
                        <div nz-col nzSpan="16" style="color: #919191; line-height: 36px;">
                            Line height
                        </div>
                        <div nz-col nzSpan="8">
                            <input nz-input [(ngModel)]="data.lineHeight" />
                        </div>
                    </div>
                    <div nz-row>
                        <div nz-col nzSpan="16" style="color: #919191; line-height: 36px;">
                            Letter spacing
                        </div>
                        <div nz-col nzSpan="8">
                            <input nz-input [(ngModel)]="data.letterSpacing" />
                        </div>
                    </div>
                </nz-form-control>
            </nz-form-item>
        </ng-container>
        <ng-container *ngIf="mode=='toolbar'">
            <nz-select nzShowSearch [(ngModel)]="data.fontFamily" [compareWith]="compareFn"
                [style.fontFamily]="data.fontFamily" style="width: 225px; margin-right: 8px;">
                <ng-container *ngFor="let font of sharedService.fonts">
                    <nz-option nzCustomContent [nzValue]="font.value" [nzLabel]="font.value"><span
                            [style.fontFamily]="font.value">{{font.value}}</span></nz-option>
                </ng-container>
            </nz-select>
            <nz-button-group>
                <button nz-button>-</button>
                <input nz-input [(ngModel)]="data.fontSize"
                    style="border-radius: 0px; border-left: none; border-right: none; width: 60px;" />
                <button nz-button>+</button>
            </nz-button-group>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="data.type == 'IMAGE'">
        <nz-form-item>
            <nz-form-label [nzNoColon]="true">{{
                data.name | titlecase
                }}</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <div class="image-select-box">
                    <img class="image-select-chosen-image" src="{{data.value}}">
                </div>
                <button nz-button (click)="openPickerSingle(data, 'MEDIA')"><span nz-icon nzType="file-image"
                        nzTheme="outline"></span><span>Change</span></button>
                <input nz-input [(ngModel)]="data.backgroundPosition" />
                <input nz-input [(ngModel)]="data.backgroundSize" />
            </nz-form-control>
        </nz-form-item>
    </ng-container>
</ng-container>