<div class="page-wrapper">
    <div class="navbar">
        <div class="title"><img style="height: 40px" src="../../assets/rippleIcon.png" />
        </div>
        <!-- <ul nz-menu nzMode="horizontal">
            <li nz-menu-item nzSelected>
                <span nz-icon nzType="mail"></span>
                Navigation One
            </li>
        </ul> -->
        <!-- <button nz-button nzBlock >Create</button> -->
        <!-- <nz-button-group style="margin: 0px 16px 16px; width: calc(100% - 32px)">
            <button nz-button style="width: 225px">Create</button>
            <button nz-button nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomLeft">
                <span style="width: 14px; margin-left: -7px;" nz-icon nzType="down" nzTheme="outline"></span>
            </button>
        </nz-button-group> -->
        <!-- <button nz-button nz-popover [nzPopoverContent]="contentTemplate"
            nzPopoverPlacement="rightTop" nzPopoverTrigger="click" style="width: calc(100% - 16px); text-align: left; margin: 0px 8px 16px; "><i class="fa-solid fa-robot"></i>&nbsp;&nbsp;Planning assistant</button> -->
        <!-- <button (click)="this.openPlanningAssistant()" nz-button style="margin: 0px 8px 16px; "><i class="fa-solid fa-robot" style="font-size: 12px;margin-top: 4px;float: left;"></i>&nbsp;&nbsp;Planning assistant</button> -->
        <nz-dropdown-menu #menu1="nzDropdownMenu">
            <ul nz-menu style="width: 225px;">
                <li nz-menu-group nzTitle="Campaigns" style="font-size: 12px; font-weight: 500;"></li>
                <li nz-menu-item routerLink="/create/email"><i class="lni lni-add-files"></i>&nbsp;Single campaign</li>
                <li nz-menu-item routerLink="/assistant/calendar"><i class="lni lni-calendar"></i>&nbsp;Multiple
                    campaigns</li>
                <li nz-menu-group nzTitle="Flows" style="font-size: 12px; font-weight: 500; margin-top: 8px;"></li>
                <li nz-menu-item routerLink="/create/flow"><i class="lni lni-add-files"></i>&nbsp;Single flow email</li>
                <li nz-menu-item (click)="openNewFlow()"><i class="lni lni-bolt-alt"></i>&nbsp;Create flow</li>
            </ul>
        </nz-dropdown-menu>
        <ng-container *ngIf="this.workspaceObject?.brandStyles?.enableNewNavigation2">
            <ul nz-menu nzMode="inline" style="background: #FAFAFA;">
                <!-- <li nz-menu-item routerLinkActive="ant-menu-item-selected"><span nz-icon
                    nzType="home"></span><span>Home</span></li> -->
                <!-- <li nz-menu-item><span nz-icon nzType="line-chart" nzTheme="outline"></span><span>Analytics</span></li> -->
                <!-- <button nz-button nzType="text" routerLink="/assistant/calendar"
                style="margin: 8px;width: 233px;text-align: left; margin-bottom: 16px;"><i class="lni lni-comments"
                    style="float: left;margin-top: 4px;"></i>&nbsp;&nbsp;Planning assistant<nz-tag class="aqua"
                    style="position: absolute;margin-top: -2px;margin-left: 28px;">Beta</nz-tag></button> -->
                <!-- <button nz-button nzType="primary" routerLink="/create"
                style="margin: 8px;width: 233px;text-align: left; margin-bottom: 16px;"><i class="lni lni-circle-plus"
                    style="float: left;margin-top: 4px;" ></i>&nbsp;&nbsp;Create</button> -->
                <!-- <button nz-button nzType="primary" routerLink="/create" nzBlock
                style="margin: 8px; width: 233px; margin-bottom: 16px;"><span nz-icon nzType="plus"
                    nzTheme="outline"></span>Create</button> -->

                <!-- <nz-button-group style="margin: 8px;">
                <button nz-button nzType="primary" routerLink="/create" style="width: 201px;"><span nz-icon
                        nzType="plus" nzTheme="outline"></span>Create</button>
                <button nzType="primary" nz-button nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomLeft">
                    <span nz-icon nzType="ellipsis"></span>
                </button>
            </nz-button-group> -->
                <button nz-button nzType="primary" routerLink="/create" style="margin: 0px 8px 4px; width: 233px;"><span
                        nz-icon nzType="plus" nzTheme="outline"></span>Create</button>
                <!-- <button nz-button routerLink="/chat" style="margin: 0px 8px 16px; width: 233px;"><span nz-icon
                nzType="message" nzTheme="outline"></span>Ripple assistant</button> -->

                <!-- <button nz-button nzType="primary" routerLink="/create"
                style="margin: 8px; margin-bottom: 16px; float: right"><span nz-icon nzType="plus"
                    nzTheme="outline"></span>Create</button> -->
                <!-- <li nz-menu-item routerLink="/home" routerLinkActive="ant-menu-item-selected"><i class="lni lni-home"></i>
                &nbsp;&nbsp;<span>Home</span></li> -->
                <!-- <li nz-menu-item routerLink="/chat" routerLinkActive="ant-menu-item-selected"><i class="lni lni-bulb"></i>
                &nbsp;&nbsp;<span>Chat</span></li> -->
                <!-- <li nz-menu-item routerLink="/assistant/calendar" routerLinkActive="ant-menu-item-selected"><i class="lni lni-comments"></i>&nbsp;&nbsp;<span>Planning assistant<nz-tag class="aqua">Beta</nz-tag></span></li> -->


                <!-- <li nz-menu-group nzTitle="Channels" nzIcon="appstore"></li> -->
                <!-- <li nz-menu-item routerLink="/email" routerLinkActive="ant-menu-item-selected"><i
                    class="lni lni-envelope"></i>&nbsp;&nbsp;<span>Email</span></li> -->
                <li nz-menu-item routerLink="/email" routerLinkActive="ant-menu-item-selected"><i
                        class="lni lni-envelope"></i>&nbsp;&nbsp;<span>Campaigns</span></li>
                <li nz-menu-item routerLink="/calendar" routerLinkActive="ant-menu-item-selected"><i
                        class="lni lni-calendar"></i>&nbsp;&nbsp;<span>Calendar</span></li>
                <li nz-menu-item routerLink="/flows" routerLinkActive="ant-menu-item-selected"><i
                        class="lni lni-bolt-alt"></i>&nbsp;&nbsp;<span>Flows</span></li>
                <li nz-menu-item (click)="toggleContentSubmenu()"><i
                        class="lni lni-grid-alt"></i>&nbsp;&nbsp;<span>Content</span>
                    <i class="lni lni-chevron-down" [class.rotate]="showContentSubmenu"
                        style="position: absolute; right: 16px; top: 10px;"></i>
                </li>
                <li class="submenu" [class.visible]="showContentSubmenu">
                    <ul>
                        <li nz-menu-item routerLink="/content/media" routerLinkActive="ant-menu-item-selected">Media
                        </li>
                        <li nz-menu-item routerLink="/content/products" routerLinkActive="ant-menu-item-selected">
                            Products
                        </li>
                        <li nz-menu-item routerLink="/content/templates" routerLinkActive="ant-menu-item-selected">
                            Templates
                        </li>
                        <li nz-menu-item routerLink="/content/blocks" routerLinkActive="ant-menu-item-selected">Saved
                            blocks
                        </li>
                    </ul>
                </li>

                <!-- <li nz-menu-item routerLink="/content" routerLinkActive="ant-menu-item-selected"><i class="lni lni-archive"></i>&nbsp;&nbsp;<span>Content</span></li> -->
                <!-- <li nz-submenu nzTitle="Navigation Three" nzIcon="setting">
                <ul>
                    <li nz-menu-item>Option 11</li>
                    <li nz-menu-item>Option 12</li>
                    <li nz-menu-item>Option 13</li>
                </ul>
            </li> -->
                <!-- <li nz-menu-item nzDisabled routerLinkActive="ant-menu-item-selected"><i
                    class="lni lni-bubble"></i>&nbsp;&nbsp;<span>SMS</span><span style="position:absolute; right: 16px;"
                    class="lni lni-lock-alt"></span></li> -->
                <!-- <li nz-menu-group nzTitle="Data" nzIcon="appstore"></li> -->
                <!-- <li nz-menu-item routerLink="/segments" routerLinkActive="ant-menu-item-selected"><span nz-icon
                    nzType="user" nzTheme="outline"></span><span>Segments</span></li> -->
                <!-- <li nz-menu-item routerLink="/database" routerLinkActive="ant-menu-item-selected"><span nz-icon
                    nzType="database" nzTheme="outline"></span><span>Database</span></li> -->
                <!-- <li nz-menu-item routerLink="/opportunities" routerLinkActive="ant-menu-item-selected"><span nz-icon
                    nzType="bulb" nzTheme="outline"></span><span>Opportunities</span></li>
            <li nz-menu-group nzTitle="Opportunities" nzIcon="appstore"></li>
            <li nz-menu-item routerLink="/opportunities/new"><span nz-icon nzType="notification" nzTheme="outline"></span><span>New</span></li>
            <li nz-menu-item routerLink="/opportunities/pending"><span nz-icon nzType="clock-circle" nzTheme="outline"></span><span>Pending</span></li>
            <li nz-menu-item routerLink="/opportunities/live"><span nz-icon nzType="thunderbolt" nzTheme="outline"></span><span>Live</span></li>
            <li nz-menu-group nzTitle="Email" nzIcon="appstore"></li>
            <li nz-menu-item routerLink="/email/campaigns"><span nz-icon nzType="send"
                    nzTheme="outline"></span><span>Campaigns</span></li>
            <li nz-menu-item routerLink="/email/campaigns"><span nz-icon nzType="share-alt"
                    nzTheme="outline"></span><span>Flows</span></li> -->

                <!-- <li nz-menu-item routerLink="/opportunities/pending"><span nz-icon nzType="share-alt" nzTheme="outline"></span><span>Flows</span></li>
            <li nz-menu-group nzTitle="SMS" nzIcon="appstore"></li>
            <li nz-menu-item routerLink="/opportunities/new"><span nz-icon nzType="send" nzTheme="outline"></span><span>Campaigns</span></li>
            <li nz-menu-item routerLink="/opportunities/pending"><span nz-icon nzType="share-alt" nzTheme="outline"></span><span>Flows</span></li>
            <li nz-menu-group nzTitle="Ads" nzIcon="appstore"></li>
            <li nz-menu-item routerLink="/opportunities/new"><span nz-icon nzType="facebook" nzTheme="outline"></span><span>Facebook</span></li>
            <li nz-menu-item routerLink="/opportunities/pending"><span nz-icon nzType="google" nzTheme="outline"></span><span>Google</span></li> -->
            </ul>
        </ng-container>
        <ul nz-menu nzMode="inline" style="background: #FAFAFA;">
            <button nz-button nzType="primary" routerLink="/create" style="margin: 0px 8px 4px; width: 233px;" class="sidebarCreateButton"><span
                    nz-icon nzType="plus" nzTheme="outline"></span>Create</button>

            <li nz-menu-item routerLink="/home" routerLinkActive="ant-menu-item-selected"><i class="lni lni-home"></i>
                &nbsp;&nbsp;<span>Home</span></li>
            <li nz-menu-item routerLink="/calendar" routerLinkActive="ant-menu-item-selected"><i
                    class="lni lni-calendar"></i>&nbsp;&nbsp;<span>Calendar</span></li>
        </ul>
        <div style="padding: 0px 16px">
            <nz-divider style="margin: 8px 0px; border-top: 1px solid #E5E7EB;"></nz-divider>
        </div>
        <ul nz-menu nzMode="inline" style="background: #FAFAFA;">


            <li nz-menu-item routerLink="/email" routerLinkActive="ant-menu-item-selected"><i
                    class="lni lni-envelope"></i>&nbsp;&nbsp;<span>Campaigns</span></li>

            <li nz-menu-item routerLink="/flows" routerLinkActive="ant-menu-item-selected"><i
                    class="lni lni-bolt-alt"></i>&nbsp;&nbsp;<span>Flows</span></li>

        </ul>
        <div style="padding: 0px 16px">
            <nz-divider style="margin: 8px 0px; border-top: 1px solid #E5E7EB;"></nz-divider>
        </div>
        <ul nz-menu nzMode="inline" style="background: #FAFAFA;">
            <li nz-menu-item (click)="toggleContentSubmenu()"><i
                    class="lni lni-grid-alt"></i>&nbsp;&nbsp;<span>Content</span>
                <i class="lni lni-chevron-down" [class.rotate]="showContentSubmenu"
                    style="position: absolute; right: 16px; top: 10px;"></i>
            </li>
            <li class="submenu" [class.visible]="showContentSubmenu">
                <ul>
                    <li nz-menu-item routerLink="/content/media" routerLinkActive="ant-menu-item-selected">Media
                    </li>
                    <li nz-menu-item routerLink="/content/products" routerLinkActive="ant-menu-item-selected">
                        Products
                    </li>
                    <li nz-menu-item routerLink="/content/templates" routerLinkActive="ant-menu-item-selected">
                        Templates
                    </li>
                    <li nz-menu-item routerLink="/content/blocks" routerLinkActive="ant-menu-item-selected">Saved
                        blocks
                    </li>
                </ul>
            </li>
        </ul>


        <!-- <div class="navbar-right">
            <button nz-button nzType="text" routerLink="/settings">Settings</button>

            <a nz-dropdown [nzDropdownMenu]="menu">
                Account
                <span nz-icon nzType="down"></span>
            </a>
            <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu nzSelectable>
                    <li nz-menu-item (click)="logout()">Log out</li>
                </ul>
            </nz-dropdown-menu>
        </div> -->
        <div class="sidebar-footer-wrapper">
            <ul nz-menu nzMode="inline" style="background: #FAFAFA;">
                <li nz-menu-item routerLink="/settings" routerLinkActive="ant-menu-item-selected"><i
                        class="lni lni-cog"></i>&nbsp;&nbsp;<span>Settings</span></li>
            </ul>
            <div class="sidebar-footer" nz-popover nzPopoverTrigger="click" [nzPopoverContent]="accountMenu"
                nzPopoverPlacement="rightBottom" [(nzPopoverVisible)]="accountMenuOpen"
                (click)="accountMenuOpen = true">
                <img class="sidebar-footer-image" src="{{this.sharedService.currentUser.workspace.brandStyles.logo}}" />
                {{this.sharedService.currentUser.workspace.name}}
            </div>
            <!-- <ng-container *ngIf="this.sharedService.currentUser.adminLevel != 'SUPER'">
                <div class="sidebar-footer">
                    <img class="sidebar-footer-image"
                        src="{{this.sharedService.currentUser.workspace.brandStyles.logo}}" />
                    {{this.sharedService.currentUser.workspace.name}}
                </div>
            </ng-container> -->
        </div>
    </div>
    <div class="content-wrapper"><router-outlet></router-outlet></div>

    <ng-template #contentTemplate>
        <div style="width: 250px">
            <button nz-button nzBlock style="text-align: left; height: 48px; margin-bottom: 8px;"><span
                    class="lni lni-bubble" style="float: left; margin-top: 4px;"></span>&nbsp;&nbsp;Planning
                assistant</button>
            <button nz-button nzBlock style="text-align: left; height: 48px;"><span class="lni lni-envelope"
                    style="float: left; margin-top: 4px;"></span>&nbsp;&nbsp;New email</button>
        </div>
    </ng-template>

    <ng-template #accountMenu>
        <div class="account-menu-content">
            <div class="account-menu-header">
                <div style="line-height:48px">
                    <img class="sidebar-footer-image"
                        src="{{this.sharedService.currentUser.workspace.brandStyles.logo}}" />

                    {{this.sharedService.currentUser.workspace.name}}
                </div>
                <span style="color: #767676">{{this.sharedService.currentUser.email}}</span>
            </div>
            <ng-container *ngIf="this.workspaceList && this.workspaceList.length > 0">
                <div class="account-menu-account-list">
                    <ng-container *ngFor="let workspace of workspaceList">
                        <ng-container *ngIf="workspace.brandStyles">
                            <div class="custom-card mini hover" (click)="changeWorkspace(workspace._id)">
                                <div class="custom-card-body mini">
                                    <img class="sidebar-footer-image" src="{{workspace.brandStyles.logo}}" />
                                    {{workspace.name}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="this.loadingWorkspaces">
                <div class="account-menu-account-list">
                    <div class="loading-wrapper">
                        <div class="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="account-menu-footer" style="text-align: right">
                <button nz-button (click)="logout()">Logout</button>
            </div>
        </div>
    </ng-template>
</div>