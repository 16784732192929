<div class="page-wrapper">
    <div class="header-wrapper">
        <nz-page-header nzBackIcon class="site-page-header">

            <!-- <nz-breadcrumb nz-page-header-breadcrumb>
                <nz-breadcrumb-item>Opportunities</nz-breadcrumb-item>
                <nz-breadcrumb-item>{{this.opportunityObject.title}}</nz-breadcrumb-item>
            </nz-breadcrumb> -->
            <nz-page-header-title>Create</nz-page-header-title>
            <nz-page-header-extra>
                <ng-container *ngIf="this.showRenderer">
                    <!-- <button nz-button><i class="lni lni-trash-can"
                            style="float: left; margin-top: 3px;"></i>&nbsp;&nbsp;Clear</button>&nbsp; -->
                    <!-- <nz-input-group nzCompact style="float: left; width: 180px;">
                        <button nz-button nzType="default" (click)="previousEmail()" [disabled]="!this.emailDone">
                            <span nz-icon nzType="left"></span>
                        </button>
                        <button nz-button [disabled]="!this.emailDone">Version {{this.currentEmail +
                            1}}/{{this.emailHistory.length}}</button>
                        <button nz-button nzType="default" (click)="nextEmail()" [disabled]="!this.emailDone">
                            <span nz-icon nzType="right"></span>
                        </button>
                    </nz-input-group>
                    <button (click)="saveEmail()" nz-button nzType="primary" [disabled]="!this.emailDone">Save & continue</button> -->
                </ng-container>
            </nz-page-header-extra>
        </nz-page-header>
    </div>
    <div class="content-wrapper">
        <!-- <div class="left-sidebar">
            <div class="session-wrapper navbar">
                <ul nz-menu nzMode="inline">
                    <ng-container *ngFor="let sessionObject of sessionList">
                        <button (click)="changeSession(sessionObject._id)" nz-button
                            style="width: 100%; text-align: left; margin-bottom: 4px;"><i
                                class="lni lni-alarm-clock"
                                style="float: left; margin-top: 3px;"></i>&nbsp;&nbsp;{{sessionObject.updatedAt |
                            date:'M/d/YY
                            h:mm
                            a'}}</button>
                        <li nz-menu-item (click)="changeSession(sessionObject._id)"
                            routerLinkActive="ant-menu-item-selected"><i
                                class="lni lni-calendar"></i>&nbsp;&nbsp;<span>{{sessionObject.updatedAt |
                                date:'M/d/YY
                                h:mm
                                a'}}</span></li>
                    </ng-container>
                </ul>
            </div>
        </div> -->
        <div class="upper-inner" style="padding-top: 48px;">
            <!-- <nz-page-header class="site-page-header">
                    <nz-page-header-title>Suggestions</nz-page-header-title>
                    <nz-page-header-extra>
                        <ng-container *ngIf="!this.loadingSuggestions">
                            <button (click)="generateSuggestions()" nz-button><i class="lni lni-reload"
                                    style="float: left; margin-top: 3px;"></i>&nbsp;&nbsp;Refresh</button>
                        </ng-container>
                    </nz-page-header-extra>
                </nz-page-header>
                <ng-container *ngIf="this.loadingSuggestions">
                    <div class="loading-wrapper">
                        <div class="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!this.loadingSuggestions">
                    <ng-container *ngFor="let suggestion of suggestions">
                        <div class="custom-card">
                            <div class="custom-card-header">{{suggestion.title}}
                                <button nz-button nzType="primary" (click)="generateSuggestion(suggestion)"
                                    style="float: right">Generate</button>
                            </div>
                            <div class="custom-card-body">
                                {{suggestion.reason}}
                            </div>
                        </div>
                    </ng-container>
                </ng-container> -->
            <div>
                <!-- <ul nz-menu nzMode="horizontal" style="margin-bottom: 16px;">
                        <li nz-menu-item routerLinkActive="ant-menu-item-selected">
                            <i class="lni lni-bulb"
                                style="float: left; margin-top: 12px;"></i>&nbsp;&nbsp;<span>Generate an
                                email</span>
                        </li>
                        <li nz-menu-item routerLinkActive="ant-menu-item-selected">
                            <i class="lni lni-calendar"
                                style="float: left; margin-top: 12px;"></i><span>&nbsp;&nbsp;Plan my
                                calendar</span>
                        </li>
                    </ul> -->
                <h2 nz-typography style="margin-bottom: 24px;">What would you like to create?</h2>
                <ng-container *ngIf="mode == 'CAMPAIGN'">
                    <div class="custom-card">

                        <div class="custom-card-header" style="padding: 16px;">
                            <button (click)="this.mode = null" nz-button><span nz-icon nzType="arrow-left"
                                    nzTheme="outline"></span>Back</button>
                        </div>
                        <div class="custom-card-body" style="padding: 16px;">
                            <div nz-row [nzGutter]="16">
                                <!-- <div nz-col class="gutter-row" [nzSpan]="6">
                                    <div class="custom-card hover">
                                        <div class="custom-card-body" style="padding: 16px;">
                                            <div class="email-type-wrapper">
                                                <i class="lni lni-add-files"></i>
                                            </div>
                                            <div class="email-type-label">Custom prompt</div>
                                        </div>
                                    </div>
                                </div> -->
                                <div nz-col class="gutter-row" [nzSpan]="12">
                                    <div class="custom-card hover" style="margin-bottom: 0px;"
                                        routerLink="/create/email">
                                        <div class="custom-card-body" style="padding: 40px 16px;">
                                            <div class="email-type-wrapper">
                                                <i class="lni lni-add-files"></i>
                                            </div>
                                            <div class="email-type-label">Single campaign</div>
                                            <div class="email-type-description">Instantly generate a single campaign
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div nz-col class="gutter-row" [nzSpan]="12">
                                    <div class="custom-card hover" style="margin-bottom: 0px;"
                                        routerLink="/assistant/calendar">
                                        <div class="custom-card-body" style="padding: 29px 16px;">
                                            <div class="email-type-wrapper">
                                                <i class="lni lni-files"></i>
                                            </div>
                                            <div class="email-type-label">Multiple campaigns</div>
                                            <div class="email-type-description">Use the Ripple planning assistant to
                                                generate several campaigns</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="mode == 'FLOW'">
                    <div class="custom-card">

                        <div class="custom-card-header" style="padding: 16px;">
                            <button (click)="this.mode = null" nz-button><span nz-icon nzType="arrow-left"
                                    nzTheme="outline"></span>Back</button>
                        </div>
                        <div class="custom-card-body" style="padding: 16px;">
                            <div nz-row [nzGutter]="16">
                                <!-- <div nz-col class="gutter-row" [nzSpan]="6">
                                    <div class="custom-card hover">
                                        <div class="custom-card-body" style="padding: 16px;">
                                            <div class="email-type-wrapper">
                                                <i class="lni lni-add-files"></i>
                                            </div>
                                            <div class="email-type-label">Custom prompt</div>
                                        </div>
                                    </div>
                                </div> -->
                                <div nz-col class="gutter-row" [nzSpan]="12">
                                    <div class="custom-card hover" style="margin-bottom: 0px;"
                                        routerLink="/create/flow">
                                        <div class="custom-card-body" style="padding: 40px 16px;">
                                            <div class="email-type-wrapper">
                                                <i class="lni lni-add-files"></i>
                                            </div>
                                            <div class="email-type-label">Single flow email</div>
                                            <div class="email-type-description">Add an email to a flow</div>
                                        </div>
                                    </div>
                                </div>
                                <div nz-col class="gutter-row" [nzSpan]="12">
                                    <div class="custom-card hover" style="margin-bottom: 0px;" (click)="openNewFlow()">
                                        <div class="custom-card-body" style="padding: 40px 16px;">
                                            <div class="email-type-wrapper">
                                                <i class="lni lni-bolt-alt"></i>
                                            </div>
                                            <div class="email-type-label">Flow</div>
                                            <div class="email-type-description">Create a new flow sequence</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!mode">
                    <!-- <h4 nz-typography style="margin-bottom: 24px;">Email</h4>
                    <div nz-row [nzGutter]="16">
                        <div nz-col class="gutter-row" [nzSpan]="8">
                            <div class="custom-card hover" style="margin-bottom: 0px;" (click)="this.mode = 'CAMPAIGN'">
                                <div class="custom-card-body" style="padding: 40px 16px;">
                                    <div class="email-type-wrapper">
                                        <i class="lni lni-envelope"></i>
                                    </div>
                                    <div class="email-type-label">Email campaign</div>
                                </div>
                            </div>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="8">
                            <div class="custom-card hover" style="margin-bottom: 0px;" (click)="this.mode = 'FLOW'">
                                <div class="custom-card-body" style="padding: 40px 16px;">
                                    <div class="email-type-wrapper">
                                        <i class="lni lni-bolt-alt"></i>
                                    </div>
                                    <div class="email-type-label">Flow email</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4 nz-typography style="margin-bottom: 24px; margin-top: 40px;">SMS</h4>
                    <div nz-row [nzGutter]="16">
                        <div nz-col class="gutter-row" [nzSpan]="8">
                            <div class="custom-card hover" style="margin-bottom: 0px;" (click)="this.mode = 'CAMPAIGN'">
                                <div class="custom-card-body" style="padding: 40px 16px;">
                                    <div class="email-type-wrapper">
                                        <i class="lni lni-envelope"></i>
                                    </div>
                                    <div class="email-type-label">SMS campaign</div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div nz-row [nzGutter]="[16, 16]">

                        <!-- <ng-container *ngIf="!this.workspaceObject?.brandStyles?.enableTemplateGeneration">
                            <div nz-col class="gutter-row" [nzSpan]="8">
                                <div class="custom-card hover" style="margin-bottom: 0px;" routerLink="/create/email">
                                    <div class="custom-card-body" style="padding: 32px 16px;">
                                        <div class="email-type-wrapper">
                                            <i class="lni lni-envelope"></i>
                                        </div>
                                        <div class="email-type-label">Email</div>
                                    </div>
                                </div>
                            </div>
                        </ng-container> -->
                        <div nz-col class="gutter-row" [nzSpan]="8">
                            <div class="custom-card hover campaignEmailLinkCreatePage" style="margin-bottom: 0px;"
                                routerLink="/create/email-template">
                                <div class="custom-card-body" style="padding: 32px 16px;">
                                    <div class="email-type-wrapper">
                                        <i class="lni lni-envelope"></i>
                                    </div>
                                    <div class="email-type-label">Campaign email</div>
                                </div>
                            </div>
                        </div>
                        <!-- <ng-container *ngIf="!this.workspaceObject?.brandStyles?.enableTemplateGeneration">
                            <div nz-col class="gutter-row" [nzSpan]="8">
                                <div class="custom-card hover" style="margin-bottom: 0px;" (click)="openNewFlow()">
                                    <div class="custom-card-body" style="padding: 32px 16px;">
                                        <div class="email-type-wrapper">
                                            <i class="lni lni-bolt-alt"></i>
                                        </div>
                                        <div class="email-type-label">Flow</div>
                                    </div>
                                </div>
                            </div>
                        </ng-container> -->
                        <div nz-col class="gutter-row" [nzSpan]="8">
                            <div class="custom-card hover flowEmailLinkCreatePage" style="margin-bottom: 0px;"
                                routerLink="/create/flow-template">
                                <div class="custom-card-body" style="padding: 32px 16px;">
                                    <div class="email-type-wrapper">
                                        <i class="lni lni-bolt-alt"></i>
                                    </div>
                                    <div class="email-type-label">Flow email</div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="this.workspaceObject?.brandStyles?.enableSMS">
                            <div nz-col class="gutter-row" [nzSpan]="8">
                                <div class="custom-card hover" style="margin-bottom: 0px;" routerLink="/create/sms">
                                    <div class="custom-card-body" style="padding: 32px 16px;">
                                        <div class="email-type-wrapper">
                                            <i class="lni lni-popup"></i>
                                        </div>
                                        <div class="email-type-label">SMS</div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!this.workspaceObject?.brandStyles?.enableSMS">
                            <div nz-col class="gutter-row" [nzSpan]="8">
                                <div class="custom-card hover disabled" style="margin-bottom: 0px;">
                                    <div class="custom-card-body" style="padding: 32px 16px;">
                                        <div class="email-type-wrapper">
                                            <i class="lni lni-popup"></i>
                                        </div>
                                        <div class="email-type-label">SMS</div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="this.workspaceObject?.brandStyles?.enableSocialPosts">
                            <div nz-col class="gutter-row" [nzSpan]="8">
                                <div class="custom-card hover" style="margin-bottom: 0px;" routerLink="/create/social">
                                    <div class="custom-card-body" style="padding: 32px 16px;">
                                        <div class="email-type-wrapper">
                                            <i class="lni lni-bullhorn"></i>
                                        </div>
                                        <div class="email-type-label">Social post</div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <!-- <div nz-col class="gutter-row" [nzSpan]="12">
                                    <div class="custom-card hover" style="margin-bottom: 0px;"
                                        routerLink="/create/email">
                                        <div class="custom-card-body" style="padding: 40px 16px;">
                                            <div class="email-type-wrapper">
                                                <i class="lni lni-add-files"></i>
                                            </div>
                                            <div class="email-type-label">Single campaign</div>
                                            <div class="email-type-description">Instantly generate a single campaign</div>
                                        </div>
                                    </div>
                                </div>
                                <div nz-col class="gutter-row" [nzSpan]="12">
                                    <div class="custom-card hover" style="margin-bottom: 0px;"
                                        routerLink="/assistant/calendar">
                                        <div class="custom-card-body" style="padding: 29px 16px;">
                                            <div class="email-type-wrapper">
                                                <i class="lni lni-files"></i>
                                            </div>
                                            <div class="email-type-label">Multiple campaigns</div>
                                            <div class="email-type-description">Use the Ripple planning assistant to generate several campaigns</div>
                                        </div>
                                    </div>
                                </div> -->
                    </div>
                    <!-- <div class="custom-card" style="margin-top: 16px;">
                        <div class="custom-card-body" style="line-height: 36px;">
                            Need ideas? Chat with the Ripple
                            assistant
                            <button nz-button style="float: right" routerLink="/chat">Open</button>
                        </div>
                    </div> -->
                </ng-container>
            </div>
        </div>
    </div>
</div>