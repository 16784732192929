
<div class="page-wrapper">
    <!-- <ng-container *ngIf="this.this.sharedService.rendererOpportunityObject.stage == ''">
        <div class="content-wrapper">
            <nz-page-header class="site-page-header">

                <nz-breadcrumb nz-page-header-breadcrumb>
                    <nz-breadcrumb-item>Opportunities</nz-breadcrumb-item>
                    <nz-breadcrumb-item>{{this.this.sharedService.rendererOpportunityObject.title}}</nz-breadcrumb-item>
                </nz-breadcrumb>
                <nz-page-header-title>{{this.this.sharedService.rendererOpportunityObject.title}}</nz-page-header-title>
            </nz-page-header>
            <ng-container *ngIf="fetchingSiteData">
                <div class="custom-card" style="text-align: center">
                    <div class="custom-card-body">
                        <div class="meter purple">
                            <span style="width: 100%"></span>
                        </div>
                        <h3>Copilot is fetching data.</h3>
                        <h3>This may take a moment.</h3>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!fetchingSiteData">
                <div class="custom-card" style="text-align: center">
                    <div class="custom-card-body">
                        <h2>Choose a primary color</h2>
                        <div nz-row [nzGutter]="[16, 24]">
                            <ng-container *ngFor="let color of siteData.colors">
                                <div nz-col class="gutter-row" [nzSpan]="3">
                                    <div (click)="selectPrimaryColor(color)" class="color-list"
                                        [style.backgroundColor]="color"></div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="custom-card" style="text-align: center">
                    <div class="custom-card-body">
                        <h2>Choose a logo image</h2>
                        <div nz-row [nzGutter]="[16, 24]">
                            <ng-container *ngFor="let image of siteData.images">
                                <ng-container *ngIf="image.src">
                                    <div nz-col class="gutter-row" [nzSpan]="4">
                                        <img (click)="selectLogoImage(image.src)" class="image-list"
                                            src="{{image.src}}">
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container> -->

    <ng-container *ngIf="sharedService.loadingRenderer">
        <div class="loading-wrapper">
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!sharedService.loadingRenderer">
        <div class="left-column" cdkScrollable #scrollableDiv>

            <ng-container *ngIf="this.sharedService.rendererOpportunityObject.stage == 'NEW'">
                <div style="max-width: 600px; margin: auto; margin-top: 24px; text-align: center;">
                    <div class="meter purple">
                        <span style="width: 100%"></span>
                    </div>
                    <h3>Copilot is generating a proposal.</h3>
                    <h3>This may take a moment.</h3>
                </div>
            </ng-container>

            <ng-container *ngIf="this.sharedService.rendererOpportunityObject.stage != 'NEW'">
                <div class="email-background-wrapper" id="email-background-wrapper"
                    [style.backgroundColor]="this.sharedService.rendererOpportunityObject.contentTemplate.backgroundColor"
                    [style.pointer-events]="mode === 'VIEW' ? 'none' : 'auto'">

                    <div class="email-content-wrapper"
                        [style.backgroundColor]="this.sharedService.rendererOpportunityObject.contentTemplate.backgroundColor"
                        [style.max-width]="mode === 'VIEW' ? '600px' : '900px'">
                        <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                            <!-- <div class="example-box" *ngFor="let movie of movies" cdkDrag>{{movie}}</div> -->
                            <ng-container
                                *ngFor="let block of this.sharedService.rendererOpportunityObject.contentTemplate.blocks; let index=index">
                                <div class="email-block-outer" cdkDrag>
                                    <div nz-row>
                                        <div nz-col nzFlex="150px" *ngIf="this.mode == 'EDIT'">
                                            <div class="email-block-controls"
                                                style="text-align: right; padding-right: 10px;">
                                                <!-- <button nzShape="circle" nz-button nzType="default"
                                                    (click)="moveUp(block, index)"><span nz-icon nzType="up"
                                                        nzTheme="outline"></span></button><br>
                                                <button nz-button nzType="default" nzShape="circle"
                                                    (click)="moveDown(block, index)"><span nz-icon
                                                        nzType="down"></span></button> -->
                                                <div class="custom-circle-button" cdkDragHandle><span nz-icon
                                                        nzType="holder" nzTheme="outline"></span></div>
                                                <div class="custom-circle-button" (click)="openBlockLibrary(index)"
                                                    style="margin-left: 6px;">
                                                    <span nz-icon nzType="plus" nzTheme="outline"></span>
                                                </div>
                                                <div class="custom-circle-button" (click)="createSavedBlock(block)"
                                                    style="margin-left: 6px;"><span nz-icon nzType="save"
                                                        nzTheme="outline"></span></div>
                                                <!-- <div class="custom-circle-button" (click)="openBlockLibrary(index)"
                                                    style="margin-left: 6px;">
                                                    <span nz-icon nzType="save" nzTheme="outline"></span>
                                                </div> -->
                                                <!-- <button nzShape="circle" 
                                                    style="transition: none !important;" nz-button
                                                    nzType="default"></button>&nbsp; -->
                                                <!-- &nbsp;
                                                <button nz-button nzShape="circle" (click)="openBlockLibrary(index)"
                                                    style="transition: none !important;"><span nz-icon nzType="plus"
                                                        nzTheme="outline"></span></button> -->
                                            </div>
                                        </div>
                                        <div nz-col nzFlex="600px">

                                            <ng-container *ngIf="block.loading">
                                                <div class="loading-wrapper">
                                                    <div class="lds-ellipsis">
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                            </ng-container>

                                            <ng-container *ngIf="!block.loading">

                                                <ng-container *ngIf="block.type == 'SKELETON'">
                                                    <div class="email-block-wrapper"
                                                        style="padding: 40px; background: #f2f2f2">
                                                        <nz-skeleton [nzActive]="true"></nz-skeleton>
                                                    </div>
                                                </ng-container>


                                                <ng-container *ngIf="block.type == 'LOGO'">


                                                    <div id="{{'block-' + index}}" class="email-block-wrapper padding24"
                                                        (click)="selectBlock(block)"
                                                        [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                        <div class="email-logo-image-wrapper">
                                                            <img class="email-logo-image"
                                                                [style.width]="getPropertyValue(block, 'Width') + 'px'"
                                                                src="{{getPropertyValue(block, 'Image')}}" />
                                                        </div>

                                                    </div>

                                                    <div *ngIf="block.showPalette" class="block-palette">
                                                        <div nz-row [nzGutter]="16">
                                                            <ng-container *ngFor="let blockType of blockLibrary">
                                                                <div nz-col [nzSpan]="6">
                                                                    <div class="block-palette-block"
                                                                        (click)="addBlock(blockType, block)">
                                                                        <div class="block-palette-icon">
                                                                            <span nz-icon nzType="{{blockType.icon}}"
                                                                                nzTheme="outline"></span>
                                                                        </div>
                                                                        {{blockType.title}}
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'TITLE'">
                                                    <div id="{{'block-' + index}}" class="email-block-wrapper padding32"
                                                        (click)="selectBlock(block)">
                                                        <div class="email-hero-title">{{block.properties[0].value}}
                                                        </div>
                                                        <!-- <div class="email-hero-subtitle">{{block.properties[1].value}}</div> -->
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'BUTTON'">

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding40"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <div class="email-button"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                [style.color]="getPropertyValue(block, 'Button text color')"
                                                                [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                {{block.properties[0].value}}</div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'OVERIMAGE'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)">
                                                            <div class="email-cta-background"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Image')+')'">
                                                                <div class="email-button"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Button text')}}</div>
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'PROMOTION'">

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding40"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <!-- <div class="email-promotion-title"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}</div> -->
                                                            <quill-editor class="email-promotion-title" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'CENTER', false)"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                            <!-- <div class="email-promotion-subtitle"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Body')}}</div> -->
                                                            <quill-editor class="email-promotion-subtitle"
                                                                theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Body'), 'CENTER', false)"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Body').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Body').value"
                                                                [style.color]="getPropertyValue(block, 'Body text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                            <!-- <div class="email-promotion-code"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Promo background color')"
                                                                [style.color]="getPropertyValue(block, 'Promo text color')">
                                                                {{getPropertyValue(block, 'Promo code')}}</div> -->
                                                            <quill-editor class="email-promotion-code" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Promo code'), 'CENTER', false)"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Promo code').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Promo code').value"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Promo background color')"
                                                                [style.color]="getPropertyValue(block, 'Promo text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                            <!-- <div class="email-button"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                            [style.fontFamily]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.bodyFont">
                                                            {{getPropertyValue(block, 'Button')}}</div> -->
                                                        </div>
                                                    </ng-container>

                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'IMAGEFULLWIDTH'">
                                                    <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                        (click)="selectBlock(block)">
                                                        <div class="email-hero-image"
                                                            [style.backgroundImage]="'url('+block.properties[0].value+')'">
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'CUSTOM'">
                                                    <ng-container *ngFor="let element of block.code">
                                                        <ng-container *ngIf="element.type == 'div'">
                                                            <div [style]="element.style">{{getPropertyValue(block,
                                                                element.content)}}</div>
                                                        </ng-container>
                                                        <ng-container *ngIf="element.type == 'text'">
                                                            <quill-editor [style]="element.style" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, element.content), 'CENTER', false)"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, element.content).value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, element.content).value"
                                                                [style.color]="getPropertyValue(block, 'Title color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'KLAVIYO'">
                                                    <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                        (click)="selectBlock(block)">
                                                        <div class="email-klaviyo-block-wrapper">
                                                            <img class="email-klaviyo-logo"
                                                                src="../assets/klaviyo-logo.png" />
                                                            <div class="email-klaviyo-title">You can add content here in
                                                                the Klaviyo editor</div>
                                                            <div class="email-klaviyo-title">Learn more</div>
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'IMAGE'">
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            style="padding: 32px 48px;">
                                                            <img class="email-image"
                                                                src="{{getPropertyValue(block, 'Image')}}">
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'FULLWIDTH'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <img class="email-image-fullwidth"
                                                                src="{{getPropertyValue(block, 'Image')}}">
                                                        </div>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'GIF'">
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)">
                                                            <img class="email-image-fullwidth"
                                                                src="{{getPropertyValue(block, 'Image')}}">
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'PADDING'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)" style="padding: 40px 48px;">
                                                            <img class="email-image"
                                                                src="{{getPropertyValue(block, 'Image')}}">
                                                        </div>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'BLURB'">
                                                    <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                        (click)="selectBlock(block)"
                                                        [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                        [style.color]="getPropertyValue(block, 'Text color')">
                                                        <div class="email-body-text">{{getPropertyValue(block, 'Text')}}
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <!-- <ng-container *ngIf="block.type == 'GALLERY'">
                                                    <div id="{{'block-' + index}}" class="email-block-wrapper padding32"
                                                        [style.backgroundColor]="getProperty(block, 'Background color').value"
                                                        (click)="selectBlock(block)">
                                                        <div class="email-gallery-title">{{getProperty(block, 'Title').value}}
                                                        </div>
                                                        <table class="email-gallery-table">
                                                            <tr>
                                                                <td><img class="email-gallery-image"
                                                                        src="{{getArrayItem(getProperty(block, 'Images').value, 'Image')[0].value}}" />
                                                                </td>
                                                                <td><img class="email-gallery-image"
                                                                        src="{{getArrayItem(getProperty(block, 'Images').value, 'Image')[1].value}}" />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><img class="email-gallery-image"
                                                                        src="{{getArrayItem(getProperty(block, 'Images').value, 'Image')[2].value}}" />
                                                                </td>
                                                                <td><img class="email-gallery-image"
                                                                        src="{{getArrayItem(getProperty(block, 'Images').value, 'Image')[3].value}}" />
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </ng-container> -->

                                                <ng-container *ngIf="block.type == 'REVIEW'">
                                                    <ng-container *ngIf="!block.version">
                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                            <div id="{{'block-' + index}}"
                                                                class="email-block-wrapper padding32"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                (click)="selectBlock(block)">
                                                                <div class="email-review-wrapper ">
                                                                    <div class="email-review-stars"
                                                                        [style.color]="getPropertyValue(block, 'Decoration color')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Decoration').value">
                                                                        <span nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star" nzTheme="fill"></span>
                                                                    </div>
                                                                    <!-- <div class="email-review-title"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Title color')">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div> -->
                                                                    <quill-editor class="email-review-text"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'CENTER', false)"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                        [style.color]="getPropertyValue(block, 'Title color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                    <!-- <div class="email-review-text"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Text color')">
                                                                        "{{getPropertyValue(block, 'Quote')}}"
                                                                    </div> -->
                                                                    <quill-editor class="email-review-text"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Quote'), 'CENTER', false)"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Quote').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Quote').value"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                    <!-- <div class="email-review-name"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Name color')">
                                                                        -
                                                                        {{getPropertyValue(block, 'Name')}}
                                                                    </div> -->
                                                                    <quill-editor class="email-review-name"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Name'), 'CENTER', false)"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Name').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Name').value"
                                                                        [style.color]="getPropertyValue(block, 'Name color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                </div>
                                                            </div>
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'BUBBLE'">
                                                            <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                                (click)="selectBlock(block)"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                style="padding: 1px 0px;">
                                                                <div class="email-review-wrapper bubble"
                                                                    [style.borderColor]="getPropertyValue(block, 'Text color')">
                                                                    <div class="email-review-stars"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Decoration').value"
                                                                        [style.color]="getPropertyValue(block, 'Decoration color')">
                                                                        <span nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star" nzTheme="fill"></span>
                                                                    </div>
                                                                    <!-- <div class="email-review-title"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Title color')">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div> -->
                                                                    <quill-editor class="email-review-title"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'CENTER', false)"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                        [style.color]="getPropertyValue(block, 'Title color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                    <!-- <div class="email-review-text"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Text color')">
                                                                        "{{getPropertyValue(block, 'Quote')}}"
                                                                    </div> -->
                                                                    <quill-editor class="email-review-text"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Quote'), 'CENTER', false)"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Quote').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Quote').value"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                    <!-- <div class="email-review-name"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Name color')">
                                                                        -
                                                                        {{getPropertyValue(block, 'Name')}}
                                                                    </div> -->
                                                                    <quill-editor class="email-review-name"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Name'), 'CENTER', false)"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Name').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Name').value"
                                                                        [style.color]="getPropertyValue(block, 'Name color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                </div>
                                                            </div>
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'BIG'">
                                                            <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                                (click)="selectBlock(block)">
                                                                <div class="email-review-wrapper big"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <!-- <div class="email-review-title"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Title color')">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div> -->
                                                                    <quill-editor class="email-review-title"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'LEFT', false)"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                    <div class="email-review-big-quote"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Name color')">
                                                                        “”
                                                                    </div>
                                                                    <quill-editor class="email-review-text big"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Quote'), 'LEFT', false)"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Quote').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Quote').value"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                    <!-- <div class="email-review-text big"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Text color')">
                                                                        "{{getPropertyValue(block, 'Quote')}}"
                                                                    </div> -->
                                                                    <div class="email-review-stars"
                                                                        [style.color]="getPropertyValue(block, 'Decoration color')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Decoration').value">
                                                                        <span nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star" nzTheme="fill"></span>
                                                                    </div>
                                                                    <!-- <div class="email-review-name big"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Name color')">
                                                                        -
                                                                        {{getPropertyValue(block, 'Name')}}
                                                                    </div> -->
                                                                    <quill-editor class="email-review-name"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Name'), 'LEFT', false)"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Name').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Name').value"
                                                                        [style.color]="getPropertyValue(block, 'Name color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'CHATBUBBLE'">
                                                            <div id="{{'block-' + index}}"
                                                                class="email-block-wrapper padding32"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                (click)="selectBlock(block)">
                                                                <div class="email-review-wrapper ">
                                                                    <quill-editor class="email-review-text"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'CENTER', false)"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                        [style.color]="getPropertyValue(block, 'Title color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                    <div class="email-review-content-wrapper">
                                                                        <div class="talk-bubble tri-right btm-left-in"
                                                                            style="margin: 0px; width: 100%; padding: 32px 24px;"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Card background color')"
                                                                            [ngStyle]="{'--background-color': getPropertyValue(block, 'Card background color')}"
                                                                            [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius">

                                                                            <div class="email-review-stars"
                                                                                [style.color]="getPropertyValue(block, 'Decoration color')"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Decoration').value">
                                                                                <span nz-icon nzType="star"
                                                                                    nzTheme="fill"></span><span nz-icon
                                                                                    nzType="star"
                                                                                    nzTheme="fill"></span><span nz-icon
                                                                                    nzType="star"
                                                                                    nzTheme="fill"></span><span nz-icon
                                                                                    nzType="star"
                                                                                    nzTheme="fill"></span><span nz-icon
                                                                                    nzType="star" nzTheme="fill"></span>
                                                                            </div>
                                                                            <quill-editor class="email-review-text"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Quote'), 'CENTER', false)"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Quote').value"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="getArrayItem(block.properties, 'Quote').value"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                            <quill-editor class="email-review-name"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Name'), 'CENTER', false)"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Name').value"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="getArrayItem(block.properties, 'Name').value"
                                                                                [style.color]="getPropertyValue(block, 'Name color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'OVERIMAGE'">
                                                            <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                (click)="selectBlock(block)">
                                                                <div class="email-hero-background"
                                                                    [style.background-image]="'url('+getPropertyValue(block, 'Image')+')'">
                                                                    <div class="email-hero-opacity">
                                                                        <div class="email-review-wrapper"
                                                                            style="background: transparent">
                                                                            <quill-editor class="email-review-text"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'CENTER', false)"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                                [style.color]="getPropertyValue(block, 'Title color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                            <div class="email-review-content-wrapper"
                                                                                style="padding-left: 40px; padding-right: 40px;">


                                                                                <div class="email-review-stars"
                                                                                    [style.color]="getPropertyValue(block, 'Decoration color')"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Decoration').value">
                                                                                    <span nz-icon nzType="star"
                                                                                        nzTheme="fill"></span><span
                                                                                        nz-icon nzType="star"
                                                                                        nzTheme="fill"></span><span
                                                                                        nz-icon nzType="star"
                                                                                        nzTheme="fill"></span><span
                                                                                        nz-icon nzType="star"
                                                                                        nzTheme="fill"></span><span
                                                                                        nz-icon nzType="star"
                                                                                        nzTheme="fill"></span>
                                                                                </div>
                                                                                <quill-editor class="email-review-text"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Quote'), 'CENTER', false)"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Quote').value"
                                                                                    [modules]="modules"
                                                                                    [placeholder]="placeholder"
                                                                                    [(ngModel)]="getArrayItem(block.properties, 'Quote').value"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                                <quill-editor class="email-review-name"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Name'), 'CENTER', false)"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Name').value"
                                                                                    [modules]="modules"
                                                                                    [placeholder]="placeholder"
                                                                                    [(ngModel)]="getArrayItem(block.properties, 'Name').value"
                                                                                    [style.color]="getPropertyValue(block, 'Name color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'OVERIMAGEBORDER'">
                                                            <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                (click)="selectBlock(block)">
                                                                <div class="email-hero-background"
                                                                    [style.background-image]="'url('+getPropertyValue(block, 'Image')+')'">
                                                                    <div class="email-hero-opacity"
                                                                        style="padding-top: 1px; padding-bottom: 1px;">
                                                                        <div class="email-review-wrapper bubble"
                                                                            [style.borderColor]="getPropertyValue(block, 'Text color')">
                                                                            <div class="email-review-stars"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Decoration').value"
                                                                                [style.color]="getPropertyValue(block, 'Decoration color')">
                                                                                <span nz-icon nzType="star"
                                                                                    nzTheme="fill"></span><span nz-icon
                                                                                    nzType="star"
                                                                                    nzTheme="fill"></span><span nz-icon
                                                                                    nzType="star"
                                                                                    nzTheme="fill"></span><span nz-icon
                                                                                    nzType="star"
                                                                                    nzTheme="fill"></span><span nz-icon
                                                                                    nzType="star" nzTheme="fill"></span>
                                                                            </div>

                                                                            <quill-editor class="email-review-title"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'CENTER', false)"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                                            <quill-editor class="email-review-text"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Quote'), 'CENTER', false)"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Quote').value"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="getArrayItem(block.properties, 'Quote').value"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>

                                                                            <quill-editor class="email-review-name"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Name'), 'CENTER', false)"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Name').value"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="getArrayItem(block.properties, 'Name').value"
                                                                                [style.color]="getPropertyValue(block, 'Name color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'CHATBUBBLEOVERIMAGE'">
                                                            <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                (click)="selectBlock(block)">
                                                                <div class="email-hero-background"
                                                                    [style.background-image]="'url('+getPropertyValue(block, 'Image')+')'">
                                                                    <div class="email-hero-opacity">
                                                                        <div class="email-review-wrapper"
                                                                            style="background: transparent">
                                                                            <quill-editor class="email-review-text"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'CENTER', false)"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                                [style.color]="getPropertyValue(block, 'Title color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                            <div class="email-review-content-wrapper"
                                                                                style="padding-left: 40px; padding-right: 40px;">
                                                                                <div class="talk-bubble tri-right btm-left-in"
                                                                                    style="margin: 0px; width: 100%; padding: 32px 24px;"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Card background color')"
                                                                                    [ngStyle]="{'--background-color': getPropertyValue(block, 'Card background color')}"
                                                                                    [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius">

                                                                                    <div class="email-review-stars"
                                                                                        [style.color]="getPropertyValue(block, 'Decoration color')"
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Decoration').value">
                                                                                        <span nz-icon nzType="star"
                                                                                            nzTheme="fill"></span><span
                                                                                            nz-icon nzType="star"
                                                                                            nzTheme="fill"></span><span
                                                                                            nz-icon nzType="star"
                                                                                            nzTheme="fill"></span><span
                                                                                            nz-icon nzType="star"
                                                                                            nzTheme="fill"></span><span
                                                                                            nz-icon nzType="star"
                                                                                            nzTheme="fill"></span>
                                                                                    </div>
                                                                                    <quill-editor
                                                                                        class="email-review-text"
                                                                                        theme="bubble"
                                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Quote'), 'CENTER', false)"
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Quote').value"
                                                                                        [modules]="modules"
                                                                                        [placeholder]="placeholder"
                                                                                        [(ngModel)]="getArrayItem(block.properties, 'Quote').value"
                                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                                    <quill-editor
                                                                                        class="email-review-name"
                                                                                        theme="bubble"
                                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Name'), 'CENTER', false)"
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Name').value"
                                                                                        [modules]="modules"
                                                                                        [placeholder]="placeholder"
                                                                                        [(ngModel)]="getArrayItem(block.properties, 'Name').value"
                                                                                        [style.color]="getPropertyValue(block, 'Name color')"
                                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>

                                                    <ng-container *ngIf="block.version">
                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                            <div id="{{'block-' + index}}"
                                                                class="email-block-wrapper padding32"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                (click)="selectBlock(block)">
                                                                <div class="email-review-wrapper ">
                                                                    <div class="email-review-stars"
                                                                        *ngIf="getPropertyValue(block, 'Icons')"
                                                                        [style.color]="getPropertyValue(getArrayItem(block.properties, 'Icons'), 'Icon color')">
                                                                        <span nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star" nzTheme="fill"></span>
                                                                    </div>
                                                                    <div class="email-review-title"
                                                                        *ngIf="getPropertyValue(block, 'Title')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(getArrayItem(block.properties, 'Title'), 'Text color')">
                                                                        {{getPropertyValue(getArrayItem(block.properties,
                                                                        'Title'), 'Text')}}
                                                                    </div>
                                                                    <div class="email-review-text"
                                                                        *ngIf="getPropertyValue(block, 'Quote')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"
                                                                        [style.color]="getPropertyValue(getArrayItem(block.properties, 'Quote'), 'Text color')">
                                                                        "{{getPropertyValue(getArrayItem(block.properties,
                                                                        'Quote'), 'Text')}}"
                                                                    </div>
                                                                    <div class="email-review-name"
                                                                        *ngIf="getPropertyValue(block, 'Name')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(getArrayItem(block.properties, 'Name'), 'Text color')">
                                                                        -
                                                                        {{getPropertyValue(getArrayItem(block.properties,
                                                                        'Name'), 'Text')}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'BUBBLE'">
                                                            <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                                (click)="selectBlock(block)"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                <div class="email-review-wrapper bubble"
                                                                    [style.borderColor]="getPropertyValue(block, 'Text color')">
                                                                    <div class="email-review-stars"
                                                                        [style.color]="getPropertyValue(block, 'Decoration color')">
                                                                        <span nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star" nzTheme="fill"></span>
                                                                    </div>
                                                                    <div class="email-review-title"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Title color')">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div>
                                                                    <div class="email-review-text"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Text color')">
                                                                        "{{getPropertyValue(block, 'Quote')}}"
                                                                    </div>
                                                                    <div class="email-review-name"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Name color')">
                                                                        -
                                                                        {{getPropertyValue(block, 'Name')}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'BIG'">
                                                            <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                                (click)="selectBlock(block)">
                                                                <div class="email-review-wrapper big"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <div class="email-review-title"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Title color')">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div>
                                                                    <div class="email-review-big-quote"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Name color')">
                                                                        “”
                                                                    </div>
                                                                    <div class="email-review-text big"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Text color')">
                                                                        "{{getPropertyValue(block, 'Quote')}}"
                                                                    </div>
                                                                    <div class="email-review-stars"
                                                                        [style.color]="getPropertyValue(block, 'Decoration color')">
                                                                        <span nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star" nzTheme="fill"></span>
                                                                    </div>
                                                                    <div class="email-review-name big"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Name color')">
                                                                        -
                                                                        {{getPropertyValue(block, 'Name')}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'HERO'">
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            style="padding: 0px !important;">
                                                            <!-- <div class="on-block-hover">New block</div> -->
                                                            <div class="email-hero-background"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                                <div class="email-hero-opacity"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Image overlay')">
                                                                    <quill-editor class="email-hero-title"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'CENTER', true, 'Hero')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                        [style.color]="getPropertyValue(block, 'Title color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"></quill-editor>
                                                                    <quill-editor
                                                                        class="email-hero-subtitle middleimage"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                        [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                    <div class="email-hero-button-outer"
                                                                        *ngIf="getPropertyValue(block, 'Button')">

                                                                        <quill-editor class="email-hero-button"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'SEPARATEIMAGE'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <!-- <div class="email-hero-background"
                                                            [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                        </div> -->
                                                            <img [src]="getPropertyValue(block, 'Background image')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Image').value" style="width: 100%;" />
                                                            <!-- <div class="email-hero-title"
                                                                [style.color]="getPropertyValue(block, 'Title color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{block.properties[0].value}}
                                                            </div> -->
                                                            <quill-editor class="email-hero-title" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'CENTER', true, 'Hero')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'Title color')"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                            <!-- <div class="email-hero-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{block.properties[1].value}}
                                                            </div> -->
                                                            <quill-editor class="email-hero-subtitle" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                            <div class="email-hero-button-outer"
                                                                *ngIf="getPropertyValue(block, 'Button')">
                                                                <!-- <div class="email-hero-button"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Button')}}
                                                                </div> -->
                                                                <quill-editor class="email-hero-button" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'MIDDLEIMAGE'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <!-- <div class="email-hero-title"
                                                                [style.color]="getPropertyValue(block, 'Title color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{block.properties[0].value}}
                                                            </div> -->
                                                            <quill-editor class="email-hero-title" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'CENTER', true, 'Hero')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'Title color')"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                            <!-- <div class="email-hero-subtitle middleimage"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{block.properties[1].value}}
                                                            </div> -->
                                                            <quill-editor class="email-hero-subtitle middleimage"
                                                                theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                            <div class="email-hero-background" style="height: 350px"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Image').value"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                            </div>
                                                            <div class="email-hero-button-outer"
                                                                *ngIf="getPropertyValue(block, 'Button')">
                                                                <!-- <div class="email-hero-button"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Button')}}
                                                                </div> -->
                                                                <quill-editor class="email-hero-button" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'CONTAINEDIMAGESQUARE'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <!-- <div class="email-hero-title"
                                                                [style.color]="getPropertyValue(block, 'Title color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{block.properties[0].value}}
                                                            </div> -->
                                                            <quill-editor class="email-hero-title" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'CENTER', true, 'Hero')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'Title color')"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                            <!-- <div class="email-hero-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{block.properties[1].value}}
                                                            </div> -->
                                                            <quill-editor class="email-hero-subtitle" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                            <div class="email-hero-button-outer"
                                                                *ngIf="getPropertyValue(block, 'Button')">

                                                                <!-- <div class="email-hero-button"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Button')}}
                                                                </div> -->
                                                                <quill-editor class="email-hero-button" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                            </div>
                                                            <div class="email-hero-background contained-image-square"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Image').value"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'CONTAINEDIMAGEROUNDED'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <!-- <div class="email-hero-title"
                                                                [style.color]="getPropertyValue(block, 'Title color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{block.properties[0].value}}
                                                            </div> -->
                                                            <quill-editor class="email-hero-title" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'CENTER', true, 'Hero')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'Title color')"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                            <!-- <div class="email-hero-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{block.properties[1].value}}
                                                            </div> -->
                                                            <quill-editor class="email-hero-subtitle" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                            <div class="email-hero-button-outer"
                                                                *ngIf="getPropertyValue(block, 'Button')">

                                                                <!-- <div class="email-hero-button"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Button')}}
                                                                </div> -->
                                                                <quill-editor class="email-hero-button" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                            </div>
                                                            <div class="email-hero-background contained-image-rounded"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Image').value"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                            </div>

                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'CONTAINEDIMAGECIRCLE'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <!-- <div class="email-hero-title"
                                                                [style.color]="getPropertyValue(block, 'Title color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{block.properties[0].value}}
                                                            </div> -->
                                                            <quill-editor class="email-hero-title" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'CENTER', true, 'Hero')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'Title color')"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                            <!-- <div class="email-hero-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{block.properties[1].value}}
                                                            </div> -->
                                                            <quill-editor class="email-hero-subtitle" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                            <div class="email-hero-button-outer"
                                                                *ngIf="getPropertyValue(block, 'Button')">

                                                                <!-- <div class="email-hero-button"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Button')}}
                                                                </div> -->
                                                                <quill-editor class="email-hero-button" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                            </div>
                                                            <div class="email-hero-background contained-image-circle"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Image').value"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                            </div>

                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'TITLEONIMAGE'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <!-- <div class="on-block-hover">New block</div> -->
                                                            <div class="email-hero-background"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                                <div class="email-hero-opacity"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Image overlay')">
                                                                    <!-- <div class="email-hero-title"
                                                                        style="padding-bottom: 40px;"
                                                                        [style.color]="getPropertyValue(block, 'Title color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div> -->
                                                                    <quill-editor class="email-hero-title"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'CENTER', true, 'Hero')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                        style="padding-bottom: 40px;"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                        [style.color]="getPropertyValue(block, 'Title color')"
                                                                        [style.fontSize]="getArrayItem(getProperty(block, 'Components').items, 'Title').props.fontSize + 'px'"
                                                                        [style.fontFamily]="getArrayItem(getProperty(block, 'Components').items, 'Title').props.fontFamily"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="email-hero-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div> -->
                                                            <quill-editor class="email-hero-subtitle" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                            <!-- <div class="email-hero-body"
                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                    [style.fontFamily]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                    {{getPropertyValue(block, 'Body')}}
                                                                </div> -->
                                                            <div class="email-hero-button-outer"
                                                                *ngIf="getPropertyValue(block, 'Button')">

                                                                <!-- <div class="email-hero-button"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Button')}}
                                                                </div> -->
                                                                <quill-editor class="email-hero-button" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                            </div>

                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'BOXOVERIMAGE'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)">
                                                            <!-- <div class="on-block-hover">New block</div> -->
                                                            <div class="email-hero-background"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                                <div class="email-hero-opacity more-padding"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Image overlay')">
                                                                    <div class="email-box-over-image"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                        <!-- <div class="email-hero-title"
                                                                        style="padding-bottom: 40px;"
                                                                        [style.color]="getPropertyValue(block, 'Title color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div> -->
                                                                        <quill-editor class="email-hero-title small"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'CENTER', true, 'Hero')"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                            [style.color]="getPropertyValue(block, 'Title color')"
                                                                            [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                        <quill-editor class="email-hero-subtitle"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                            [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                        <!-- <div class="email-hero-body"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                {{getPropertyValue(block, 'Body')}}
                                                                            </div> -->
                                                                        <div class="email-hero-button-outer"
                                                                            *ngIf="getPropertyValue(block, 'Button')">

                                                                            <!-- <div class="email-hero-button"
                                                                                [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                                {{getPropertyValue(block, 'Button')}}
                                                                            </div> -->
                                                                            <quill-editor class="email-hero-button"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                                [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="email-hero-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div> -->


                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'BOXOVERIMAGELEFT'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)">
                                                            <!-- <div class="on-block-hover">New block</div> -->
                                                            <div class="email-hero-background"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                                <div class="email-hero-opacity more-padding"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Image overlay')">
                                                                    <div class="email-box-over-image left"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                        <!-- <div class="email-hero-title"
                                                                        style="padding-bottom: 40px;"
                                                                        [style.color]="getPropertyValue(block, 'Title color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div> -->
                                                                        <quill-editor class="email-hero-title small"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'LEFT', false, 'Hero')"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                            [style.color]="getPropertyValue(block, 'Title color')"
                                                                            [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                        <quill-editor class="email-hero-subtitle"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'LEFT', false, 'Subtitle')"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                            [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                        <!-- <div class="email-hero-body"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                {{getPropertyValue(block, 'Body')}}
                                                                            </div> -->
                                                                        <div class="email-hero-button-outer left"
                                                                            *ngIf="getPropertyValue(block, 'Button')">

                                                                            <!-- <div class="email-hero-button"
                                                                                [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                                {{getPropertyValue(block, 'Button')}}
                                                                            </div> -->
                                                                            <quill-editor
                                                                                class="email-product-button-small"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                                [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="email-hero-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div> -->


                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'BOXOVERTEXT'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)">
                                                            <!-- <div class="on-block-hover">New block</div> -->
                                                            <div class="email-hero-background-text">
                                                                SALE
                                                                SALE
                                                                SALE
                                                            </div>
                                                            <div class="email-hero-opacity"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Image overlay')">
                                                                <div class="email-box-over-image"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <quill-editor class="email-hero-title small"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'CENTER', true, 'Hero')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                        [style.color]="getPropertyValue(block, 'Title color')"
                                                                        [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                    <quill-editor class="email-hero-subtitle"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                        [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                    <div class="email-hero-button-outer"
                                                                        *ngIf="getPropertyValue(block, 'Button')">
                                                                        <quill-editor class="email-hero-button"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'SEPARATEIMAGERIGHT'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            style="display: flex" (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <div class="email-content-left">
                                                                <quill-editor class="email-hero-title small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'LEFT', true, 'Hero')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                    [style.color]="getPropertyValue(block, 'Title color')"
                                                                    [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                <quill-editor class="email-hero-subtitle" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'LEFT', false, 'Subtitle')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                    [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                <div class="email-hero-button-outer left"
                                                                    *ngIf="getPropertyValue(block, 'Button')">
                                                                    <quill-editor class="email-product-button-small"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                </div>
                                                            </div>
                                                            <div class="email-hero-background separate-image-right"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Image').value"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'BIGIMAGELEFT'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <quill-editor class="email-hero-title small" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'LEFT', true, 'Hero')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'Title color')"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                            <div class="email-hero-background separate-image-middle"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Image').value"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                            </div>
                                                            <quill-editor class="email-hero-subtitle small"
                                                                theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'LEFT', false, 'Subtitle')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                            <div class="email-hero-button-outer left-small"
                                                                *ngIf="getPropertyValue(block, 'Button')">
                                                                <quill-editor class="email-product-button-small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'TITLEONIMAGEVERTICAL'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <!-- <div class="on-block-hover">New block</div> -->
                                                            <div class="email-hero-background"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                                <div class="email-hero-opacity content-bottom"
                                                                    style="min-height: 500px;"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Image overlay')">
                                                                    <quill-editor class="email-hero-title"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'LEFT', true, 'Hero')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                        style="padding-bottom: 8px;" [modules]="modules"
                                                                        [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                        [style.color]="getPropertyValue(block, 'Title color')"
                                                                        [style.fontSize]="getArrayItem(getProperty(block, 'Components').items, 'Title').props.fontSize + 'px'"
                                                                        [style.fontFamily]="getArrayItem(getProperty(block, 'Components').items, 'Title').props.fontFamily"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                </div>
                                                            </div>
                                                            <quill-editor class="email-hero-subtitle small"
                                                                theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'LEFT', false, 'Subtitle')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>

                                                            <div class="email-hero-button-outer left-small"
                                                                *ngIf="getPropertyValue(block, 'Button')">
                                                                <quill-editor class="email-product-button-small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                            </div>

                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'SLANTEDBORDER'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <img [src]="getPropertyValue(block, 'Background image')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Image').value" style="width: 100%;" />
                                                            <div class="email-slanted-border-lower"
                                                                [ngStyle]="{'--background-color': getPropertyValue(block, 'Background color')}">
                                                                <quill-editor class="email-hero-title" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'RIGHT', true, 'Hero')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                    [style.color]="getPropertyValue(block, 'Title color')"
                                                                    [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                <quill-editor class="email-hero-subtitle" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'RIGHT')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                    [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                <div class="email-hero-button-outer right-small"
                                                                    *ngIf="getPropertyValue(block, 'Button')">
                                                                    <quill-editor class="email-product-button-small"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'MIDDLEIMAGESLANTED'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <div class="email-middle-image-slanted-top"
                                                                [ngStyle]="{'--background-color': getPropertyValue(block, 'Background color')}">
                                                                <quill-editor class="email-hero-title" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'CENTER', true, 'Hero')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                    [style.color]="getArrayItem(getProperty(block, 'Components').items, 'Title').props?.color"
                                                                    [style.fontSize]="getArrayItem(getProperty(block, 'Components').items, 'Title').props?.fontSize"
                                                                    [style.fontFamily]="getArrayItem(getProperty(block, 'Components').items, 'Title').props?.fontFamily"
                                                                    [style.fontWeight]="getArrayItem(getProperty(block, 'Components').items, 'Title').props?.fontWeight"
                                                                    [style.paddingTop]="getArrayItem(getProperty(block, 'Components').items, 'Title').props?.paddingTop"
                                                                    [style.paddingBottom]="getArrayItem(getProperty(block, 'Components').items, 'Title').props?.paddingBottom"
                                                                    [style.paddingLeft]="getArrayItem(getProperty(block, 'Components').items, 'Title').props?.paddingLeft"
                                                                    [style.paddingRight]="getArrayItem(getProperty(block, 'Components').items, 'Title').props?.paddingRight"></quill-editor>
                                                            </div>
                                                            <div class="email-hero-background" style="height: 350px"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Image').value"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                            </div>
                                                            <div class="email-middle-image-slanted-bottom"
                                                                [ngStyle]="{'--background-color': getPropertyValue(block, 'Background color')}">

                                                                <quill-editor class="email-hero-subtitle middleimage"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                    [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                <div class="email-hero-button-outer"
                                                                    *ngIf="getPropertyValue(block, 'Button')">
                                                                    <quill-editor class="email-hero-button"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARDLEFT'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            style="padding: 0px !important;">
                                                            <!-- <div class="on-block-hover">New block</div> -->
                                                            <div class="email-hero-background"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                                <div class="email-hero-opacity"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Image overlay')">
                                                                    <quill-editor class="email-hero-title"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'LEFT', true, 'Hero')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                        [style.color]="getPropertyValue(block, 'Title color')"
                                                                        [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                    <quill-editor
                                                                        class="email-hero-subtitle middleimage"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'LEFT', false, 'Subtitle')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                        [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                    <div class="email-hero-button-outer left"
                                                                        *ngIf="getPropertyValue(block, 'Button')">
                                                                        <quill-editor class="email-product-button-small"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'SLANTEDIMAGECORNERS'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            style="padding: 0px !important;">
                                                            <!-- <div class="on-block-hover">New block</div> -->
                                                            <div class="email-hero-background"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                                <div class="email-hero-opacity slanted-corners"
                                                                    [ngStyle]="{'--background-color': getPropertyValue(block, 'Background color')}">
                                                                    <quill-editor class="email-hero-title"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'CENTER', true, 'Hero')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                        [style.color]="getPropertyValue(block, 'Title color')"
                                                                        [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                    <quill-editor
                                                                        class="email-hero-subtitle middleimage"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                        [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                    <div class="email-hero-button-outer"
                                                                        *ngIf="getPropertyValue(block, 'Button')">
                                                                        <quill-editor class="email-hero-button"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'CONTAINEDIMAGEHORIZONTAL'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper flex"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <div class="email-content-left small">
                                                                <quill-editor class="email-hero-title small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'LEFT', true, 'Hero')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                    [style.color]="getPropertyValue(block, 'Title color')"
                                                                    [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                <quill-editor class="email-hero-subtitle" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'LEFT', false, 'Subtitle')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                    [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                <div class="email-hero-button-outer left"
                                                                    *ngIf="getPropertyValue(block, 'Button')">
                                                                    <quill-editor class="email-product-button-small"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                </div>
                                                            </div>
                                                            <div class="email-hero-background separate-image-right-small"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Image').value"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'CONTAINEDIMAGEHORIZONTALCIRCLE'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper flex"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <div class="email-content-left small">
                                                                <quill-editor class="email-hero-title small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'LEFT', true, 'Hero')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                    [style.color]="getPropertyValue(block, 'Title color')"
                                                                    [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                <quill-editor class="email-hero-subtitle" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'LEFT', false, 'Subtitle')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                    [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                <div class="email-hero-button-outer left"
                                                                    *ngIf="getPropertyValue(block, 'Button')">
                                                                    <quill-editor class="email-product-button-small"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                </div>
                                                            </div>
                                                            <div class="email-hero-background separate-image-right-small circle"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Image').value"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'CONTAINEDIMAGEHORIZONTALRIGHT'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper flex"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <div class="email-hero-background separate-image-left-small"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Image').value"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                            </div>
                                                            <div class="email-content-left small">
                                                                <quill-editor class="email-hero-title small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'RIGHT', true, 'Hero')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                    [style.color]="getPropertyValue(block, 'Title color')"
                                                                    [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                <quill-editor class="email-hero-subtitle" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'RIGHT')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                    [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                <div class="email-hero-button-outer right"
                                                                    *ngIf="getPropertyValue(block, 'Button')">
                                                                    <quill-editor class="email-product-button-small"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'CONTAINEDIMAGEHORIZONTALRIGHTCIRCLE'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper flex"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <div class="email-hero-background separate-image-left-small circle"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Image').value"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                            </div>
                                                            <div class="email-content-left small">
                                                                <quill-editor class="email-hero-title small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'RIGHT', true, 'Hero')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                    [style.color]="getPropertyValue(block, 'Title color')"
                                                                    [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                <quill-editor class="email-hero-subtitle" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'RIGHT')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                    [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                <div class="email-hero-button-outer right"
                                                                    *ngIf="getPropertyValue(block, 'Button')">
                                                                    <quill-editor class="email-product-button-small"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'WAVYBORDER'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <img [src]="getPropertyValue(block, 'Background image')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Image').value" style="width: 100%;" />
                                                            <div class="custom-shape-divider-bottom-1707408015"
                                                                [ngStyle]="{'--background-color': getPropertyValue(block, 'Background color')}">
                                                                <svg data-name="Layer 1"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 1200 120" preserveAspectRatio="none">
                                                                    <path
                                                                        d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                                                                        class="shape-fill"></path>
                                                                </svg>
                                                            </div>
                                                            <div
                                                                [ngStyle]="{'--background-color': getPropertyValue(block, 'Background color')}">
                                                                <quill-editor class="email-hero-title" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'CENTER', true, 'Hero')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                    [style.color]="getPropertyValue(block, 'Title color')"
                                                                    [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                <quill-editor class="email-hero-subtitle" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                    [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                <div class="email-hero-button-outer"
                                                                    *ngIf="getPropertyValue(block, 'Button')">
                                                                    <quill-editor class="email-hero-button"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'WAVYBORDERMIDDLEIMAGE'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <div
                                                                [ngStyle]="{'--background-color': getPropertyValue(block, 'Background color')}">
                                                                <quill-editor class="email-hero-title" theme="bubble"
                                                                    style="padding-bottom: 24px;"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'CENTER', true, 'Hero')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                    [style.color]="getPropertyValue(block, 'Title color')"
                                                                    [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                <div class="custom-shape-divider-top-1707418759">
                                                                    <svg data-name="Layer 1"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 1200 120"
                                                                        preserveAspectRatio="none">
                                                                        <path
                                                                            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                                                                            class="shape-fill"></path>
                                                                    </svg>
                                                                </div>
                                                                <img [src]="getPropertyValue(block, 'Background image')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Image').value"
                                                                    style="margin-top: -100px; width: 100%;" />
                                                                <div class="custom-shape-divider-bottom-1707408015"
                                                                    [ngStyle]="{'--background-color': getPropertyValue(block, 'Background color')}">
                                                                    <svg data-name="Layer 1"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 1200 120"
                                                                        preserveAspectRatio="none">
                                                                        <path
                                                                            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                                                                            class="shape-fill"></path>
                                                                    </svg>
                                                                </div>
                                                                <quill-editor class="email-hero-subtitle" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                    [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                <div class="email-hero-button-outer"
                                                                    *ngIf="getPropertyValue(block, 'Button')">
                                                                    <quill-editor class="email-hero-button"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'SLANTEDMIDDLETEXT'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)">
                                                            <div class="email-hero-background"
                                                                style="padding: 120px 0px"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                                <div class="email-box-over-image slanted-full-width"
                                                                    [ngStyle]="{'--background-color': getPropertyValue(block, 'Background color')}"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <quill-editor class="email-hero-title small"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'CENTER', true, 'Hero')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                        [style.color]="getPropertyValue(block, 'Title color')"
                                                                        [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                    <quill-editor class="email-hero-subtitle"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                        [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                    <div class="email-hero-button-outer"
                                                                        *ngIf="getPropertyValue(block, 'Button')">
                                                                        <quill-editor class="email-product-button-small"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'WAVYMIDDLETEXT'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)">
                                                            <div class="email-hero-background"
                                                                style="padding: 140px 0px 60px"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                                <div class="custom-shape-divider-bottom-1707408015"
                                                                    [ngStyle]="{'--background-color': getPropertyValue(block, 'Background color')}">
                                                                    <svg data-name="Layer 1"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 1200 120"
                                                                        preserveAspectRatio="none">
                                                                        <path
                                                                            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                                                                            class="shape-fill"></path>
                                                                    </svg>
                                                                </div>
                                                                <div class="email-box-over-image wavy"
                                                                    [ngStyle]="{'--background-color': getPropertyValue(block, 'Background color')}"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <quill-editor class="email-hero-title small"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'CENTER', true, 'Hero')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                        [style.color]="getPropertyValue(block, 'Title color')"
                                                                        [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                    <quill-editor class="email-hero-subtitle"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                        [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                    <div class="email-hero-button-outer"
                                                                        *ngIf="getPropertyValue(block, 'Button')">
                                                                        <quill-editor class="email-product-button-small"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                    </div>
                                                                </div>
                                                                <div class="custom-shape-divider-top-1707418759"
                                                                    [ngStyle]="{'--background-color': getPropertyValue(block, 'Background color')}">
                                                                    <svg data-name="Layer 1"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 1200 120"
                                                                        preserveAspectRatio="none">
                                                                        <path
                                                                            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                                                                            class="shape-fill"></path>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'OVERCONTAINEDIMAGE'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            style="padding: 40px !important;"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <div class="email-hero-background"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                                <div class="email-hero-opacity"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Image overlay')">
                                                                    <quill-editor class="email-hero-title small"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'CENTER', true, 'Hero')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                        [style.color]="getPropertyValue(block, 'Title color')"
                                                                        [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                    <quill-editor
                                                                        class="email-hero-subtitle middleimage"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                        [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                    <div class="email-hero-button-outer"
                                                                        *ngIf="getPropertyValue(block, 'Button')">
                                                                        <quill-editor class="email-product-button-small"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'OVERCONTAINEDIMAGELEFT'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            style="padding: 40px !important;"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <div class="email-hero-background"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                                <div class="email-hero-opacity"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Image overlay')">
                                                                    <quill-editor class="email-hero-title small"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'LEFT', true, 'Hero')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                        [style.color]="getPropertyValue(block, 'Title color')"
                                                                        [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                    <quill-editor
                                                                        class="email-hero-subtitle middleimage"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'LEFT', false, 'Subtitle')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                        [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                    <div class="email-hero-button-outer left"
                                                                        *ngIf="getPropertyValue(block, 'Button')">
                                                                        <quill-editor class="email-product-button-small"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STRAIGHTMIDDLETEXT'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)">
                                                            <div class="email-hero-background"
                                                                style="padding: 100px 0px"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                                <div class="email-box-over-image wavy"
                                                                    [ngStyle]="{'--background-color': getPropertyValue(block, 'Background color')}"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <quill-editor class="email-hero-title small"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'LEFT', true, 'Hero')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                        [style.color]="getPropertyValue(block, 'Title color')"
                                                                        [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                    <quill-editor class="email-hero-subtitle"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'LEFT', false, 'Subtitle')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                        [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                    <div class="email-hero-button-outer left"
                                                                        *ngIf="getPropertyValue(block, 'Button')">
                                                                        <quill-editor class="email-product-button-small"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'SEPARATEIMAGELEFT'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <!-- <div class="email-hero-background"
                                                            [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                        </div> -->
                                                            <div class="email-hero-background"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'"
                                                                style="height: 400px">
                                                            </div>
                                                            <quill-editor class="email-hero-title small" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'LEFT', true, 'Hero')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'Title color')"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                            <quill-editor class="email-hero-subtitle" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'LEFT', false, 'Subtitle')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                            <div class="email-hero-button-outer left"
                                                                *ngIf="getPropertyValue(block, 'Button')">
                                                                <quill-editor class="email-product-button-small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'RAINS'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <quill-editor class="email-hero-title" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'Title color')"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Hero').fontSize + 'px'"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                            <div class="email-hero-background contained-image-square"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Image').value"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'CATEGORY'">
                                                    <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                        [style.backgroundColor]="block.properties[3].value"
                                                        (click)="selectBlock(block)">
                                                        <table class="email-category-table">
                                                            <tr>
                                                                <ng-container *ngIf="block.layout == 'RIGHT'">
                                                                    <td style="width: 50%; text-align: center; ">
                                                                        <div class="email-category-title">
                                                                            {{block.properties[1].value}}</div>
                                                                        <div class="email-category-button"
                                                                            [style.background]="this.sharedService.rendererOpportunityObject.contentTemplate.buttonColor">
                                                                            {{block.properties[2].value}}</div>
                                                                    </td>
                                                                    <td style="width: 50%"><img
                                                                            class="email-gallery-image"
                                                                            src="{{block.properties[0].value}}" /></td>
                                                                </ng-container>
                                                                <ng-container *ngIf="block.layout != 'RIGHT'">
                                                                    <td style="width: 50%"><img
                                                                            class="email-gallery-image"
                                                                            src="{{block.properties[0].value}}" /></td>
                                                                    <td style="width: 50%; text-align: center;">
                                                                        <div class="email-category-title">
                                                                            {{block.properties[1].value}}</div>
                                                                        <div class="email-category-button"
                                                                            [style.background]="this.sharedService.rendererOpportunityObject.contentTemplate.buttonColor">
                                                                            {{getPropertyValue(block, 'Button')"}}
                                                                        </div>
                                                                    </td>
                                                                </ng-container>

                                                            </tr>
                                                        </table>

                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'FOOTER'">
                                                    <div id="{{'block-' + index}}" class="email-block-wrapper padding24"
                                                        [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                        (click)="selectBlock(block)">
                                                        <div class="email-logo-image-wrapper" id="footer-logo"
                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Logo').value">
                                                            <img class="email-logo-image" [style.width]="getPropertyValue(block, 'Logo width') + 'px'"
                                                                src="{{getPropertyValue(block, 'Logo')}}" />
                                                        </div>
                                                        <div class="social-links-wrapper" id="footer-social-links"
                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Social links').value">
                                                            <ng-container
                                                                *ngFor="let item of getArrayItem(block.properties, 'Social links').value; let itemIndex=index">
                                                                <div id="{{'block-' + index + '-' + itemIndex}}"
                                                                    [style.color]="getPropertyValue(block, 'Social icon color')"
                                                                    class="{{getArrayItem(item, 'Platform').value.value}}">
                                                                </div>
                                                            </ng-container>

                                                            <!-- <svg style="display: inline-block; margin-right: 16px; font-size: 24px;"
                                                            xmlns="http://www.w3.org/2000/svg" height="1em"
                                                            viewBox="0 0 512 512">
                                                            <path
                                                                d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
                                                        </svg>
                                                        <svg style="display: inline-block; font-size: 24px;"
                                                            xmlns="http://www.w3.org/2000/svg" height="1em"
                                                            viewBox="0 0 448 512">
                                                            <path
                                                                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                                                        </svg> -->
                                                        </div>
                                                        <!-- <div class="email-disclaimer-wrapper" id="footer-disclaimer"
                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Disclaimer').value"
                                                            [style.color]="getPropertyValue(block, 'Disclaimer text color')">
                                                            {{getPropertyValue(block, 'Disclaimer')}}
                                                        </div> -->
                                                        <quill-editor class="email-disclaimer-wrapper" theme="bubble"
                                                            id="footer-disclaimer"
                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Disclaimer'))"
                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Disclaimer').value"
                                                            [modules]="modules" [placeholder]="placeholder"
                                                            [(ngModel)]="getArrayItem(block.properties, 'Disclaimer').value"
                                                            [style.color]="getPropertyValue(block, 'Disclaimer text color')"
                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                        <!-- <quill-editor class="email-disclaimer-wrapper" theme="bubble"
                                                            id="footer-unsubscribe"
                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Unsubscribe text'))"
                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Unsubscribe link').value"
                                                            [modules]="modules" [placeholder]="placeholder"
                                                            [(ngModel)]="getArrayItem(block.properties, 'Unsubscribe text').value"
                                                            [style.color]="getPropertyValue(block, 'Disclaimer text color')"
                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor> -->
                                                        <!-- <div class="email-unsubscribe-wrapper" id="footer-unsubscribe"
                                                        [style.color]="getPropertyValue(block, 'Disclaimer text color')">
                                                        {{getPropertyValue(block, 'Unsubscribe text')}} Unsubscribe here
                                                    </div> -->
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'NAVBAR'">
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <table class="email-category-table"
                                                                style="width: 100%; font-weight: 600; table-layout: fixed;">
                                                                <tr>
                                                                    <ng-container
                                                                        *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">
                                                                        <td style="text-align: center; padding: 16px 0px;"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            id="{{'block-' + index + '-' + itemIndex}}"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                            {{getArrayItem(item, 'Title').value}}
                                                                        </td>
                                                                    </ng-container>

                                                                    <!-- <td style="width: 25%; text-align: center;"
                                                                    [style.color]="getPropertyValue(block, 'Text color')">
                                                                    {{block.properties[1].value}}
                                                                </td>
                                                                <td style="width: 25%; text-align: center;"
                                                                    [style.color]="getPropertyValue(block, 'Text color')">
                                                                    {{block.properties[2].value}}
                                                                </td>
                                                                <td style="width: 25%; text-align: center;"
                                                                    [style.color]="getPropertyValue(block, 'Text color')">
                                                                    {{block.properties[3].value}}
                                                                </td> -->
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'RAINS'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <div class="email-rains-navbar-logo">
                                                                <img src="https://bluebellgroup.com/wp-content/uploads/2021/01/RAI_2021_CVI_LOGO_STANDARD-1.png"
                                                                    style="width: 50px;" />
                                                            </div>
                                                            <div class="email-rains-navbar-bar">
                                                                <ng-container
                                                                    *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">
                                                                    <div class="email-rains-navbar-link"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        id="{{'block-' + index + '-' + itemIndex}}"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                        {{getArrayItem(item, 'Title').value}}
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'RAINS2'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <div class="email-rains-navbar-bar-2">
                                                                <ng-container
                                                                    *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">
                                                                    <div class="email-rains-navbar-link-2"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        id="{{'block-' + index + '-' + itemIndex}}"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                        {{getArrayItem(item, 'Title').value}}
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'GRID'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding32"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <table class="email-category-table"
                                                                style="width: 100%; font-weight: 600; margin: 0px 0px 0px;">
                                                                <ng-container
                                                                    *ngIf="getArrayItem(block.properties, 'Items').value[0] && getArrayItem(block.properties, 'Items').value[1] && getArrayItem(block.properties, 'Items').value[2] && getArrayItem(block.properties, 'Items').value[3]"></ng-container>
                                                                <tr>
                                                                    <td id="{{'block-' + index + '-0'}}"
                                                                        style="width: 50%; text-align: center; padding: 32px; border-bottom: 1px solid #d9d9d9; border-right: 1px solid #d9d9d9"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                        {{getArrayItem(getArrayItem(block.properties,
                                                                        'Items').value[0], 'Title').value}}
                                                                    </td>
                                                                    <td id="{{'block-' + index + '-1'}}"
                                                                        style="width: 50%; text-align: center; padding: 32px; border-bottom: 1px solid #d9d9d9"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                        {{getArrayItem(getArrayItem(block.properties,
                                                                        'Items').value[1], 'Title').value}}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="{{'block-' + index + '-2'}}"
                                                                        style="width: 50%; text-align: center; padding: 32px; border-right: 1px solid #d9d9d9"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                        {{getArrayItem(getArrayItem(block.properties,
                                                                        'Items').value[2], 'Title').value}}
                                                                    </td>
                                                                    <td id="{{'block-' + index + '-3'}}"
                                                                        style="width: 50%; text-align: center; padding: 32px;"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                        {{getArrayItem(getArrayItem(block.properties,
                                                                        'Items').value[3], 'Title').value}}
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'VERTICAL'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding16"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <table class="email-category-table"
                                                                style="width: 100%; font-weight: 600; margin: 0px 0px 0px;">
                                                                <ng-container
                                                                    *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">
                                                                    <tr>
                                                                        <td id="{{'block-' + index + '-' + itemIndex}}"
                                                                            style="text-align: center; padding: 16px; border-bottom: 1px solid #d9d9d9;"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                            {{getArrayItem(item, 'Title').value}}
                                                                        </td>
                                                                    </tr>
                                                                </ng-container>


                                                            </table>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'LISTICLE'">
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding40"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <!-- <div class="email-article-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                [style.color]="getPropertyValue(block, 'List title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div> -->
                                                            <quill-editor class="email-list-title" theme="bubble"
                                                                id="{{'list-title-' + index}}"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'List title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                            <ng-container
                                                                *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">
                                                                <div class="email-listicle-item"
                                                                    id="{{'block-' + index + '-' + itemIndex}}">
                                                                    <div nz-row>
                                                                        <div nz-col [nzSpan]="4"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item sticker').value">
                                                                            <div class="sticker"
                                                                                [style.backgroundColor]="getPropertyValue(block, 'Sticker color')"
                                                                                [style.color]="getPropertyValue(block, 'Sticker text color')">
                                                                                {{itemIndex+1}}</div>
                                                                        </div>
                                                                        <div nz-col [nzSpan]="20">

                                                                            <!-- <div class="email-listicle-title"
                                                                                [style.color]="getPropertyValue(block, 'Title text color')"
                                                                                [style.fontFamily]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.titleFont">
                                                                                {{getArrayItem(item, 'Title').value}}
                                                                            </div> -->
                                                                            <img style="width: 100%; margin-bottom: 24px;"
                                                                                src="{{getArrayItem(item, 'Image').value}}"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item image').value"
                                                                                [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius" />
                                                                            <quill-editor class="email-listicle-title"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getArrayItem(item, 'Title').value, 'LEFT', true)"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item title').value"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="getArrayItem(item, 'Title').value"
                                                                                [style.color]="getPropertyValue(block, 'Title text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                            <!-- <div class="email-listicle-body"
                                                                                [style.color]="getPropertyValue(block, 'Body text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                {{getArrayItem(item, 'Body').value}}
                                                                            </div> -->
                                                                            <quill-editor class="email-listicle-body"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getArrayItem(item, 'Body').value, 'LEFT', true)"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item body').value"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="getArrayItem(item, 'Body').value"
                                                                                [style.color]="getPropertyValue(block, 'Body text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                            <!-- <quill-editor
                                                                                class="email-product-button-small"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getArrayItem(item, 'Button').value)"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item button').value"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="getArrayItem(block.properties, 'Button').value"
                                                                                [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor> -->

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'ALTERNATING'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding16"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <div class="email-article-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'list-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'List title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div>
                                                            <ng-container
                                                                *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">

                                                                <ng-container *ngIf="!isEven(itemIndex)">
                                                                    <div class="email-listicle-item right"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Card color')"
                                                                        id="{{'block-' + index + '-' + itemIndex}}">
                                                                        <div class="email-listicle-title"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.titleFont">
                                                                            {{getArrayItem(item, 'Title').value}}
                                                                        </div>
                                                                        <div class="email-listicle-body"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                            {{getArrayItem(item, 'Body').value}}
                                                                        </div>
                                                                    </div>
                                                                </ng-container>

                                                                <ng-container *ngIf="isEven(itemIndex)">
                                                                    <div class="email-listicle-item left"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Card color')">
                                                                        <div class="email-listicle-title"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.titleFont">
                                                                            {{getArrayItem(item, 'Title').value}}
                                                                        </div>
                                                                        <div class="email-listicle-body"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                            {{getArrayItem(item, 'Body').value}}
                                                                        </div>
                                                                    </div>
                                                                </ng-container>

                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'ARTICLES'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding40"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <quill-editor class="email-list-title" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'list-title-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'List title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                            <ng-container
                                                                *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">
                                                                <!-- <div class="email-listicle-item"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Card color')"> -->
                                                                <div class="email-listicle-item-article-wrapper"
                                                                    id="{{'block-' + index + '-' + itemIndex}}">
                                                                    <div class="email-listicle-item-article"
                                                                        [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius">
                                                                        <div class="email-list-article-image"
                                                                            [style.background-image]="'url('+getArrayItem(item, 'Image').value+')'">
                                                                        </div>
                                                                        <div
                                                                            class="email-listicle-item-article-content">
                                                                            <quill-editor
                                                                                class="email-listicle-title large"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getArrayItem(item, 'Title').value)"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item title').value"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="getArrayItem(item, 'Title').value"
                                                                                [style.color]="getPropertyValue(block, 'Title text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                                            <quill-editor class="email-listicle-body"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getArrayItem(item, 'Body').value)"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item body').value"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="getArrayItem(item, 'Body').value"
                                                                                [style.color]="getPropertyValue(block, 'Body text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                            <div class="email-hero-button-outer"
                                                                                style="padding: 24px 0px 0px;"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item button').value">
                                                                                <quill-editor
                                                                                    class="email-product-button-small"
                                                                                    theme="bubble"
                                                                                    style="font-size: 14px;"
                                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                                    [modules]="modules"
                                                                                    [placeholder]="placeholder"
                                                                                    [(ngModel)]="getArrayItem(item, 'Button text').value"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'SMALLARTICLES'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding40"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <quill-editor class="email-list-title" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'list-title-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'List title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                            <ng-container
                                                                *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">
                                                                <!-- <div class="email-listicle-item"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Card color')"> -->
                                                                <div class="email-listicle-item-article-wrapper small"
                                                                    id="{{'block-' + index + '-' + itemIndex}}">
                                                                    <div class="email-listicle-item-article"
                                                                        [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius">
                                                                        <div nz-row>
                                                                            <div nz-col [nzSpan]="8">
                                                                                <div class="email-list-article-image horizontal"
                                                                                    [style.background-image]="'url('+getArrayItem(item, 'Image').value+')'">
                                                                                </div>
                                                                            </div>
                                                                            <div nz-col [nzSpan]="16">
                                                                                <div
                                                                                    class="email-listicle-item-article-content horizontal">
                                                                                    <quill-editor
                                                                                        class="email-listicle-title"
                                                                                        theme="bubble"
                                                                                        (onEditorCreated)="onEditorCreated($event, getArrayItem(item, 'Title').value, 'LEFT')"
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item title').value"
                                                                                        [modules]="modules"
                                                                                        [placeholder]="placeholder"
                                                                                        [(ngModel)]="getArrayItem(item, 'Title').value"
                                                                                        [style.color]="getPropertyValue(block, 'Title text color')"
                                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                                                    <quill-editor
                                                                                        class="email-listicle-body"
                                                                                        theme="bubble"
                                                                                        (onEditorCreated)="onEditorCreated($event, getArrayItem(item, 'Body').value, 'LEFT')"
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item body').value"
                                                                                        [modules]="modules"
                                                                                        [placeholder]="placeholder"
                                                                                        [(ngModel)]="getArrayItem(item, 'Body').value"
                                                                                        [style.color]="getPropertyValue(block, 'Body text color')"
                                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                                    <div class="email-hero-button-outer left"
                                                                                        style="padding: 24px 0px 0px;"
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item button').value">
                                                                                        <quill-editor
                                                                                            class="email-product-button-small"
                                                                                            theme="bubble"
                                                                                            style="font-size: 14px;"
                                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                                            [modules]="modules"
                                                                                            [placeholder]="placeholder"
                                                                                            [(ngModel)]="getArrayItem(item, 'Button text').value"
                                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STACKED'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding40"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <!-- <div class="email-article-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                [style.color]="getPropertyValue(block, 'List title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div> -->
                                                            <quill-editor class="email-list-title" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'list-title-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'List title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                            <ng-container
                                                                *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">
                                                                <!-- <ng-container *ngIf="!isEven(itemIndex)">
                                                                    <div
                                                                        class="email-listicle-item-article-wrapper stacked" [style.backgroundColor]="getPropertyValue(block, 'Card color')">

                                                                        <div class="sticker"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item sticker').value"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Sticker color')"
                                                                            [style.color]="getPropertyValue(block, 'Sticker text color')" style="margin-bottom: 16px;">
                                                                            {{itemIndex+1}}</div>
                                                                        <img class="email-product-image-large"
                                                                            src="{{getArrayItem(item, 'Image').value}}"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item image').value"
                                                                            [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius" />
                                                                        <quill-editor class="email-listicle-title large"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getArrayItem(item, 'Title').value, 'LEFT', true)"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item title').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(item, 'Title').value"
                                                                            [style.color]="getPropertyValue(block, 'Title text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                        <quill-editor class="email-listicle-body"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getArrayItem(item, 'Body').value, 'LEFT', true)"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item body').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(item, 'Body').value"
                                                                            [style.color]="getPropertyValue(block, 'Body text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                    </div>
                                                                </ng-container> -->
                                                                <div class="email-listicle-item-article-wrapper stacked"
                                                                    id="{{'block-' + index + '-' + itemIndex}}">
                                                                    <div class="sticker"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item sticker').value"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Sticker color')"
                                                                        [style.color]="getPropertyValue(block, 'Sticker text color')"
                                                                        style="margin-bottom: 24px;">
                                                                        {{itemIndex+1}}</div>
                                                                    <img style="width: 100%; margin-bottom: 24px;"
                                                                        src="{{getArrayItem(item, 'Image').value}}"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item image').value"
                                                                        [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius" />
                                                                    <quill-editor class="email-listicle-title large"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getArrayItem(item, 'Title').value, 'LEFT', true)"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item title').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(item, 'Title').value"
                                                                        [style.color]="getPropertyValue(block, 'Title text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                    <quill-editor class="email-listicle-body"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getArrayItem(item, 'Body').value, 'LEFT', true)"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item body').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(item, 'Body').value"
                                                                        [style.color]="getPropertyValue(block, 'Body text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'IMAGELEFT'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            style="display: flex">
                                                            <div class="email-hero-background separate-image-right"
                                                                style="min-height: auto;"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Image')+')'">
                                                            </div>
                                                            <div class="email-content-left"
                                                                style="padding: 24px 0px; min-height: auto;">
                                                                <quill-editor class="email-list-title small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'LEFT')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    id="{{'list-title-' + index}}"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                    [style.color]="getPropertyValue(block, 'List title text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                    [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                                <ng-container
                                                                    *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">
                                                                    <div class="email-listicle-item-article-wrapper imageleft"
                                                                        id="{{'block-' + index + '-' + itemIndex}}">
                                                                        <img style="width: 100%; margin-bottom: 24px;"
                                                                            src="{{getArrayItem(item, 'Image').value}}"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item image').value"
                                                                            [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius" />
                                                                        <quill-editor class="email-listicle-title"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getArrayItem(item, 'Title').value, 'LEFT', true)"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item title').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(item, 'Title').value"
                                                                            [style.color]="getPropertyValue(block, 'Title text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                        <quill-editor class="email-listicle-body"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getArrayItem(item, 'Body').value, 'LEFT', true)"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item body').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(item, 'Body').value"
                                                                            [style.color]="getPropertyValue(block, 'Body text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                    </div>
                                                                </ng-container>
                                                            </div>


                                                        </div>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'VALUEPROPS'">
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'IMAGELEFT' || getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            style="display: flex">
                                                            <div class="email-hero-background separate-image-right"
                                                                style="min-height: auto;"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Image')+')'">
                                                            </div>
                                                            <div class="email-content-left"
                                                                style="padding: 24px 0px; min-height: auto;">
                                                                <quill-editor class="email-list-title small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'LEFT')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                    [style.color]="getPropertyValue(block, 'List title text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                    [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                                <ng-container
                                                                    *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">
                                                                    <div
                                                                        class="email-listicle-item-article-wrapper imageleft">
                                                                        <i class="{{getArrayItem(item, 'Icon').value}}"
                                                                            style="font-size: 24px; float: left; margin-top: 2px"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item icon').value"
                                                                            [style.color]="getPropertyValue(block, 'Icon color')"></i>
                                                                        <quill-editor class="email-listicle-title"
                                                                            style="width: calc(100% - 42px)"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getArrayItem(item, 'Title').value, 'LEFT', true)"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item title').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(item, 'Title').value"
                                                                            [style.color]="getPropertyValue(block, 'Title text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                        <quill-editor class="email-listicle-body"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getArrayItem(item, 'Body').value, 'LEFT', true)"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item body').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(item, 'Body').value"
                                                                            [style.color]="getPropertyValue(block, 'Body text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                    </div>
                                                                </ng-container>
                                                            </div>


                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'VERTICAL'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding40"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <quill-editor class="email-list-title" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'List title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                            <div class="email-hero-background contained-image-square"
                                                                *ngIf="getPropertyValue(block, 'Image')"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Image')+')'">
                                                            </div>
                                                            <ng-container
                                                                *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">
                                                                <div
                                                                    class="email-listicle-item-article-wrapper stacked">
                                                                    <i class="{{getArrayItem(item, 'Icon').value}}"
                                                                        style="font-size: 32px; float: left; margin-bottom: 8px"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item icon').value"
                                                                        [style.color]="getPropertyValue(block, 'Icon color')"></i>
                                                                    <quill-editor class="email-listicle-title large"
                                                                        style="width: calc(100% - 54px)" theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getArrayItem(item, 'Title').value, 'LEFT', true)"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item title').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(item, 'Title').value"
                                                                        [style.color]="getPropertyValue(block, 'Title text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                    <quill-editor class="email-listicle-body"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getArrayItem(item, 'Body').value, 'LEFT', true)"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item body').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(item, 'Body').value"
                                                                        [style.color]="getPropertyValue(block, 'Body text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'BLOGS'">
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD' || getPropertyValue(block, 'Layout').value == 'ARTICLES'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding40"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <quill-editor class="email-list-title" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'blogs-title-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'List title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                            <ng-container
                                                                *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">
                                                                <!-- <div class="email-listicle-item"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Card color')"> -->
                                                                <div class="email-listicle-item-article-wrapper"
                                                                    id="{{'block-' + index + '-' + itemIndex}}">
                                                                    <div class="email-listicle-item-article"
                                                                        [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Card color')">
                                                                        <div class="email-list-article-image"
                                                                            [style.background-image]="'url('+getArrayItem(item, 'Image').value+')'">
                                                                        </div>
                                                                        <div
                                                                            class="email-listicle-item-article-content">
                                                                            <quill-editor
                                                                                class="email-listicle-title large"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getArrayItem(item, 'Title').value)"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item title').value"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="getArrayItem(item, 'Title').value"
                                                                                [style.color]="getPropertyValue(block, 'Title text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                                            <quill-editor class="email-listicle-body"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getArrayItem(item, 'Body').value)"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item body').value"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="getArrayItem(item, 'Body').value"
                                                                                [style.color]="getPropertyValue(block, 'Body text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                            <div class="email-hero-button-outer"
                                                                                style="padding: 24px 0px 0px;"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item button').value">
                                                                                <quill-editor
                                                                                    class="email-product-button-small"
                                                                                    theme="bubble"
                                                                                    style="font-size: 14px;"
                                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                                    [modules]="modules"
                                                                                    [placeholder]="placeholder"
                                                                                    [(ngModel)]="getArrayItem(item, 'Button text').value"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'SMALLARTICLES'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding40"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <quill-editor class="email-list-title" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'blogs-title-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'List title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                            <ng-container
                                                                *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">
                                                                <!-- <div class="email-listicle-item"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Card color')"> -->
                                                                <div class="email-listicle-item-article-wrapper small"
                                                                    id="{{'block-' + index + '-' + itemIndex}}">
                                                                    <div class="email-listicle-item-article"
                                                                        [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Card color')">
                                                                        <div nz-row>
                                                                            <div nz-col [nzSpan]="8">
                                                                                <div class="email-list-article-image horizontal"
                                                                                    [style.background-image]="'url('+getArrayItem(item, 'Image').value+')'">
                                                                                </div>
                                                                            </div>
                                                                            <div nz-col [nzSpan]="16">
                                                                                <div
                                                                                    class="email-listicle-item-article-content horizontal">
                                                                                    <quill-editor
                                                                                        class="email-listicle-title"
                                                                                        theme="bubble"
                                                                                        (onEditorCreated)="onEditorCreated($event, getArrayItem(item, 'Title').value, 'LEFT')"
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item title').value"
                                                                                        [modules]="modules"
                                                                                        [placeholder]="placeholder"
                                                                                        [(ngModel)]="getArrayItem(item, 'Title').value"
                                                                                        [style.color]="getPropertyValue(block, 'Title text color')"
                                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                                                    <quill-editor
                                                                                        class="email-listicle-body"
                                                                                        theme="bubble"
                                                                                        (onEditorCreated)="onEditorCreated($event, getArrayItem(item, 'Body').value, 'LEFT')"
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item body').value"
                                                                                        [modules]="modules"
                                                                                        [placeholder]="placeholder"
                                                                                        [(ngModel)]="getArrayItem(item, 'Body').value"
                                                                                        [style.color]="getPropertyValue(block, 'Body text color')"
                                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                                    <div class="email-hero-button-outer left"
                                                                                        style="padding: 24px 0px 0px;"
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item button').value">
                                                                                        <quill-editor
                                                                                            class="email-product-button-small"
                                                                                            theme="bubble"
                                                                                            style="font-size: 14px;"
                                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                                            [modules]="modules"
                                                                                            [placeholder]="placeholder"
                                                                                            [(ngModel)]="getArrayItem(item, 'Button text').value"
                                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'SMALLARTICLESRIGHTIMAGE'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding40"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <quill-editor class="email-list-title" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                id="{{'blogs-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'List title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                            <ng-container
                                                                *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">
                                                                <!-- <div class="email-listicle-item"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Card color')"> -->
                                                                <div class="email-listicle-item-article-wrapper small"
                                                                    id="{{'block-' + index + '-' + itemIndex}}">
                                                                    <div class="email-listicle-item-article"
                                                                        [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Card color')">
                                                                        <div nz-row>
                                                                            <div nz-col [nzSpan]="16">
                                                                                <div
                                                                                    class="email-listicle-item-article-content horizontal">
                                                                                    <quill-editor
                                                                                        class="email-listicle-title"
                                                                                        theme="bubble"
                                                                                        (onEditorCreated)="onEditorCreated($event, getArrayItem(item, 'Title').value, 'LEFT')"
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item title').value"
                                                                                        [modules]="modules"
                                                                                        [placeholder]="placeholder"
                                                                                        [(ngModel)]="getArrayItem(item, 'Title').value"
                                                                                        [style.color]="getPropertyValue(block, 'Title text color')"
                                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                                                    <quill-editor
                                                                                        class="email-listicle-body"
                                                                                        theme="bubble"
                                                                                        (onEditorCreated)="onEditorCreated($event, getArrayItem(item, 'Body').value, 'LEFT')"
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item body').value"
                                                                                        [modules]="modules"
                                                                                        [placeholder]="placeholder"
                                                                                        [(ngModel)]="getArrayItem(item, 'Body').value"
                                                                                        [style.color]="getPropertyValue(block, 'Body text color')"
                                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                                    <div class="email-hero-button-outer left"
                                                                                        style="padding: 24px 0px 0px;"
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Item button').value">
                                                                                        <quill-editor
                                                                                            class="email-product-button-small"
                                                                                            theme="bubble"
                                                                                            style="font-size: 14px;"
                                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button'))"
                                                                                            [modules]="modules"
                                                                                            [placeholder]="placeholder"
                                                                                            [(ngModel)]="getArrayItem(item, 'Button text').value"
                                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div nz-col [nzSpan]="8">
                                                                                <div class="email-list-article-image horizontal"
                                                                                    [style.background-image]="'url('+getArrayItem(item, 'Image').value+')'">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'ARTICLE'">
                                                    <ng-container *ngIf="block.version">
                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                            <div id="{{'block-' + index}}"
                                                                class="email-block-wrapper padding40"
                                                                (click)="selectBlock(block)"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                <div class="email-article-title"
                                                                    *ngIf="getPropertyValue(block, 'Title')"
                                                                    [style.color]="getPropertyValue(getArrayItem(block.properties, 'Title'), 'Text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                    {{getPropertyValue(getArrayItem(block.properties,
                                                                    'Title'), 'Text')}}
                                                                </div>
                                                                <div class="email-article-subtitle"
                                                                    *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                    [style.color]="getPropertyValue(getArrayItem(block.properties, 'Subtitle'), 'Text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                    {{getPropertyValue(getArrayItem(block.properties,
                                                                    'Subtitle'), 'Text')}}
                                                                </div>
                                                                <div class="email-article-image contained-image-square"
                                                                    *ngIf="getPropertyValue(block, 'Image')"
                                                                    [style.background-image]="'url('+getPropertyValue(getArrayItem(block.properties, 'Image'), 'Image')+')'"
                                                                    [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius">
                                                                </div>
                                                                <div class="email-article-body"
                                                                    *ngIf="getPropertyValue(block, 'Body')"
                                                                    [style.color]="getPropertyValue(getArrayItem(block.properties, 'Body'), 'Text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontSize"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                    {{getPropertyValue(getArrayItem(block.properties,
                                                                    'Body'), 'Text')}}
                                                                </div>
                                                                <div class="email-article-button-outer"
                                                                    *ngIf="getPropertyValue(block, 'Button')">

                                                                    <div class="email-hero-button"
                                                                        [style.backgroundColor]="getPropertyValue(getArrayItem(block.properties, 'Button'), 'Background color')"
                                                                        [style.color]="getPropertyValue(getArrayItem(block.properties, 'Button'), 'Text color')"
                                                                        [style.borderRadius]="getPropertyValue(getArrayItem(block.properties, 'Button'), 'Button shape')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontSize"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                        {{getPropertyValue(getArrayItem(block.properties,
                                                                        'Button'), 'Text')}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container *ngIf="!block.version">
                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                            <div id="{{'block-' + index}}"
                                                                class="email-block-wrapper padding40"
                                                                (click)="selectBlock(block)"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                style="padding-bottom: 16px; padding-left: 32px; padding-right: 32px;">
                                                                <!-- <div class="email-article-title"
                                                                    *ngIf="getPropertyValue(block, 'Title')"
                                                                    [style.color]="getPropertyValue(block, 'Title color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                    {{getPropertyValue(block, 'Title')}}
                                                                </div> -->
                                                                <quill-editor class="email-article-title" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                    [style.color]="getPropertyValue(block, 'Title color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                    [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                <!-- <div class="email-article-subtitle"
                                                                    *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                    [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                    {{getPropertyValue(block, 'Subtitle')}}
                                                                </div> -->
                                                                <quill-editor class="email-article-subtitle"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                    [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                    [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px'"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                <div class="email-article-image contained-image-square"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Image').value"
                                                                    [style.background-image]="'url('+getPropertyValue(block, 'Image')+')'"
                                                                    [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius">
                                                                </div>
                                                                <!-- <div class="email-article-body"
                                                                    *ngIf="getPropertyValue(block, 'Body')"
                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                    {{getPropertyValue(block, 'Body')}}
                                                                </div> -->
                                                                <quill-editor class="email-article-body" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Body'))"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Body').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Body').value"
                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                    [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontSize + 'px'"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                <div class="email-article-button-outer"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value">

                                                                    <!-- <div class="email-hero-button"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                        {{getPropertyValue(block, 'Button text')}}
                                                                    </div> -->

                                                                    <quill-editor class="email-hero-button"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Button text').value"
                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontSize + 'px'"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'LEFT'">
                                                            <div id="{{'block-' + index}}"
                                                                class="email-block-wrapper padding40"
                                                                (click)="selectBlock(block)"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                style="padding-bottom: 16px; padding-left: 32px; padding-right: 32px;">
                                                                <quill-editor class="email-article-title" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'LEFT')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                    [style.color]="getPropertyValue(block, 'Title color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                    [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                <quill-editor class="email-article-subtitle"
                                                                    style="padding-left: 0x;" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'LEFT', false, 'Subtitle')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                    [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                    [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px'"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                <div class="email-article-image contained-image-square"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Image').value"
                                                                    [style.background-image]="'url('+getPropertyValue(block, 'Image')+')'"
                                                                    [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius">
                                                                </div>
                                                                <quill-editor class="email-article-body" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Body'), 'LEFT')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Body').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Body').value"
                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                    [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontSize + 'px'"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                <div class="email-hero-button-outer left-small"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                    style="padding-left: 0px; padding-top: 0px;">
                                                                    <quill-editor class="email-product-button-small"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Button text').value"
                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontSize + 'px'"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'BORDER'">
                                                            <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                                (click)="selectBlock(block)"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                style="padding: 24px !important;">
                                                                <div class="email-article-border-inner"
                                                                    [style.borderColor]="getPropertyValue(block, 'Title color')">
                                                                    <quill-editor class="email-article-title"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                        [style.color]="getPropertyValue(block, 'Title color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                    <quill-editor class="email-article-subtitle"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                        [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                        [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px'"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                    <div class="email-article-image contained-image-square"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Image').value"
                                                                        [style.background-image]="'url('+getPropertyValue(block, 'Image')+')'"
                                                                        [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius">
                                                                    </div>
                                                                    <quill-editor class="email-article-body"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Body'))"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Body').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Body').value"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                        [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontSize + 'px'"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                    <div class="email-hero-button-outer"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                        style="padding-left: 0px; padding-top: 0px;">
                                                                        <quill-editor class="email-product-button-small"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(block.properties, 'Button text').value"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontSize + 'px'"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'BORDERSQUARE'">
                                                            <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                                (click)="selectBlock(block)"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                style="padding: 24px !important;">
                                                                <div class="email-article-border-inner"
                                                                    [style.borderColor]="getPropertyValue(block, 'Title color')"
                                                                    style="border-radius: 0px;">
                                                                    <quill-editor class="email-article-title"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                        [style.color]="getPropertyValue(block, 'Title color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                    <quill-editor class="email-article-subtitle"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                        [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                        [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px'"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                    <div class="email-article-image contained-image-square"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Image').value"
                                                                        [style.background-image]="'url('+getPropertyValue(block, 'Image')+')'"
                                                                        [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius">
                                                                    </div>
                                                                    <quill-editor class="email-article-body"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Body'))"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Body').value"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Body').value"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                        [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontSize + 'px'"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                    <div class="email-hero-button-outer"
                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                        style="padding-left: 0px; padding-top: 0px;">
                                                                        <quill-editor class="email-product-button-small"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(block.properties, 'Button text').value"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontSize + 'px'"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'OVERIMAGE'">
                                                            <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                                (click)="selectBlock(block)"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                <div class="email-hero-background"
                                                                    [style.background-image]="'url('+getPropertyValue(block, 'Image')+')'">
                                                                    <div class="email-hero-opacity more-padding"
                                                                        style="padding: 48px 24px 32px;"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Image overlay')">
                                                                        <quill-editor class="email-article-title"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                            [style.color]="getPropertyValue(block, 'Title color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                        <quill-editor class="email-article-subtitle"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                            [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                            [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px'"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                        <div class="email-article-image contained-image-square"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Image').value"
                                                                            [style.background-image]="'url('+getPropertyValue(block, 'Image')+')'"
                                                                            [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius">
                                                                        </div>
                                                                        <quill-editor class="email-article-body"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Body'))"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Body').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="getArrayItem(block.properties, 'Body').value"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontSize + 'px'"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                        <div class="email-article-button-outer"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value">
                                                                            <quill-editor class="email-hero-button"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="getArrayItem(block.properties, 'Button text').value"
                                                                                [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontSize + 'px'"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'BOXOVERIMAGE'">
                                                            <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                                (click)="selectBlock(block)"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                <div class="email-hero-background"
                                                                    [style.background-image]="'url('+getPropertyValue(block, 'Image')+')'">
                                                                    <div class="email-hero-opacity more-padding"
                                                                        style="padding-top: 32px; padding-bottom: 32px;"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Image overlay')">
                                                                        <div class="email-box-over-image"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                            style="padding: 32px 24px 0px">
                                                                            <quill-editor class="email-article-title"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                                [style.color]="getPropertyValue(block, 'Title color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                            <quill-editor class="email-article-subtitle"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px'"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>

                                                                            <quill-editor class="email-article-body"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Body'))"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Body').value"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="getArrayItem(block.properties, 'Body').value"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontSize + 'px'"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                            <div class="email-article-button-outer"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value">
                                                                                <quill-editor class="email-hero-button"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                                    [modules]="modules"
                                                                                    [placeholder]="placeholder"
                                                                                    [(ngModel)]="getArrayItem(block.properties, 'Button text').value"
                                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                    [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontSize + 'px'"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'RAINS'">
                                                            <div id="{{'block-' + index}}"
                                                                class="email-block-wrapper padding40"
                                                                (click)="selectBlock(block)"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                style="padding-bottom: 0px !important; padding-left: 0px; padding-right: 0px;">
                                                                <quill-editor class="email-article-title" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'), 'LEFT')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                    [style.color]="getPropertyValue(block, 'Title color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                    [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                <quill-editor class="email-article-subtitle"
                                                                    style="padding-left: 0x;" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'LEFT', false, 'Subtitle')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                    [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                    [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px'"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                                <div class="email-article-image contained-image-square"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Image').value"
                                                                    [style.background-image]="'url('+getPropertyValue(block, 'Image')+')'"
                                                                    [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius">
                                                                </div>
                                                                <quill-editor class="email-article-body" theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Body'), 'LEFT')"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Body').value"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Body').value"
                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                    [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontSize + 'px'"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                <div class="email-hero-button-outer left-small"
                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value"
                                                                    style="padding-left: 0px; padding-top: 0px;">
                                                                    <quill-editor class="email-hero-button"
                                                                        theme="bubble"
                                                                        (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                        [modules]="modules" [placeholder]="placeholder"
                                                                        [(ngModel)]="getArrayItem(block.properties, 'Button text').value"
                                                                        style="width: 100%;"
                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontSize + 'px'"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'FEATURES'">
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding32"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <table class="email-category-table"
                                                                style="width: 100%; font-weight: 600; table-layout: fixed;">
                                                                <tr>
                                                                    <ng-container
                                                                        *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">
                                                                        <td style="text-align: center; padding: 16px 0px;"
                                                                            id="{{'block-' + index + '-' + itemIndex}}"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                            <div class="email-features-icon"
                                                                                [style.color]="getPropertyValue(block, 'Icon color')">
                                                                                <i
                                                                                    class="{{getArrayItem(item, 'Icon').value}}"></i>
                                                                            </div>
                                                                            <div class="email-features-label"
                                                                                [style.color]="getPropertyValue(block, 'Text color')">
                                                                                {{getArrayItem(item, 'Label').value}}
                                                                            </div>

                                                                        </td>
                                                                    </ng-container>
                                                                </tr>
                                                            </table>
                                                            <!-- <table class="email-category-table"
                                                                style="width: 100%; font-weight: 600; margin: 0px 0px 16px;">
                                                                <tr>
                                                                    <td style="width: 25%; text-align: center;"
                                                                        [style.color]="getPropertyValue(block, 'Icon color')">
                                                                        <div class="email-features-icon"><span nz-icon
                                                                                [nzType]="getPropertyValue(block, 'Icon 1')"
                                                                                nzTheme="outline"></span></div>
                                                                    </td>
                                                                    <td style="width: 25%; text-align: center;"
                                                                        [style.color]="getPropertyValue(block, 'Icon color')">
                                                                        <div class="email-features-icon"><span nz-icon
                                                                                [nzType]="getPropertyValue(block, 'Icon 2')"
                                                                                nzTheme="outline"></span></div>
                                                                    </td>
                                                                    <td style="width: 25%; text-align: center;"
                                                                        [style.color]="getPropertyValue(block, 'Icon color')">
                                                                        <div class="email-features-icon"><span nz-icon
                                                                                [nzType]="getPropertyValue(block, 'Icon 3')"
                                                                                nzTheme="outline"></span></div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="width: 25%; text-align: center; vertical-align: top;"
                                                                        [style.color]="getPropertyValue(block, 'Text color')">
                                                                        <div class="email-features-label">
                                                                            {{getPropertyValue(block,
                                                                            'Text
                                                                            1')}}</div>
                                                                    </td>
                                                                    <td style="width: 25%; text-align: center; vertical-align: top;"
                                                                        [style.color]="getPropertyValue(block, 'Text color')">
                                                                        <div class="email-features-label">
                                                                            {{getPropertyValue(block,
                                                                            'Text
                                                                            2')}}</div>
                                                                    </td>
                                                                    <td style="width: 25%; text-align: center; vertical-align: top;"
                                                                        [style.color]="getPropertyValue(block, 'Text color')">
                                                                        <div class="email-features-label">
                                                                            {{getPropertyValue(block,
                                                                            'Text
                                                                            3')}}</div>
                                                                    </td>
                                                                </tr>
                                                            </table> -->
                                                        </div>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'PRODUCT'">
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <!-- <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div> -->
                                                            <quill-editor class="email-product-block-title"
                                                                theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-title-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'Title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                            <!-- <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div> -->
                                                            <quill-editor class="email-article-subtitle" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                [style.color]="getPropertyValue(block, 'Subtitle text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                            <div class="email-product-table" nzJustify="center">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                    <div id="{{'block-' + index + '-' + itemIndex}}"
                                                                        class="email-product-item-small"
                                                                        style="text-align: center">
                                                                        <img class="email-product-image-small"
                                                                            src="{{item.imageUrl}}"
                                                                            [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius" />
                                                                        <!-- <div class="email-product-title-small"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                            {{item.title}}</div> -->
                                                                        <quill-editor class="email-product-title-small"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, item.title)"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product title').value"
                                                                            [modules]="modules" [(ngModel)]="item.title"
                                                                            [placeholder]="placeholder"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                        <div class="email-product-description-small"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                            [style.color]="getPropertyValue(block, 'Price text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                            <span
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                class="compare-at-price"
                                                                                [style.color]="getPropertyValue(block, 'Compare at price text color')">${{item.compareAtPrice}}</span>${{item.price}}
                                                                        </div>
                                                                        <!-- <div class="email-product-description-small"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                            {{item.description}}
                                                                        </div> -->
                                                                        <quill-editor
                                                                            class="email-product-description-small"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, item.description)"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product description').value"
                                                                            [modules]="modules"
                                                                            [(ngModel)]="item.description"
                                                                            [placeholder]="placeholder"
                                                                            [style.color]="getPropertyValue(block, 'Description text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>
                                                                        <!-- <div class="email-product-button-small"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                            {{getPropertyValue(block, 'Button text')}}
                                                                        </div> -->
                                                                        <quill-editor class="email-product-button-small"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product button').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="item.buttonText"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Product button border radius')"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Product button color')"
                                                                            [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value">
                                                                <!-- <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div> -->
                                                                <quill-editor class="email-product-button-small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Bottom button text'))"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Bottom button text').value"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>

                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARDLARGE'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <!-- <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div> -->
                                                            <quill-editor class="email-product-block-title"
                                                                theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-title-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'Title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                            <!-- <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div> -->
                                                            <quill-editor class="email-article-subtitle" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                [style.color]="getPropertyValue(block, 'Subtitle text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>

                                                            <div class="email-product-table" nzJustify="center">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                    <div id="{{'block-' + index + '-' + itemIndex}}"
                                                                        class="email-product-item"
                                                                        style="text-align: center">
                                                                        <img class="email-product-image-large"
                                                                            src="{{item.imageUrl}}"
                                                                            [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius" />
                                                                        <!-- <div class="email-category-title"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                            {{item.title}}</div> -->
                                                                        <quill-editor class="email-category-title"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, item.title)"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product title').value"
                                                                            [modules]="modules" [(ngModel)]="item.title"
                                                                            [placeholder]="placeholder"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                                        <div class="email-product-description-small"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                            [style.color]="getPropertyValue(block, 'Price text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                            <span
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                class="compare-at-price"
                                                                                [style.color]="getPropertyValue(block, 'Compare at price text color')">${{item.compareAtPrice}}</span>${{item.price}}
                                                                        </div>
                                                                        <quill-editor
                                                                            class="email-product-description-small"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, item.description)"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product description').value"
                                                                            [modules]="modules"
                                                                            [(ngModel)]="item.description"
                                                                            [placeholder]="placeholder"
                                                                            [style.color]="getPropertyValue(block, 'Description text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>

                                                                        <!-- <div class="email-product-description-small"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                            {{item.description}}
                                                                        </div> -->
                                                                        <!-- <div class="email-product-button-large"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                            {{getPropertyValue(block, 'Button text')}}
                                                                        </div> -->
                                                                        <quill-editor class="email-product-button-large"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product button').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="item.buttonText"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Product button border radius')"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Product button color')"
                                                                            [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value">
                                                                <!-- <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div> -->
                                                                <quill-editor class="email-product-button-small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Bottom button text'))"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Bottom button text').value"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>


                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARDXL'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <!-- <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div> -->
                                                            <quill-editor class="email-product-block-title"
                                                                theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-title-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'Title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                            <!-- <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div> -->
                                                            <quill-editor class="email-article-subtitle" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                [style.color]="getPropertyValue(block, 'Subtitle text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>

                                                            <div class="email-product-table" nzJustify="center">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                    <div id="{{'block-' + index + '-' + itemIndex}}"
                                                                        class="email-product-item-xl"
                                                                        style="text-align: center">
                                                                        <img class="email-product-image-large"
                                                                            src="{{item.imageUrl}}"
                                                                            [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius" />
                                                                        <!-- <div class="email-product-title-xl"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                            {{item.title}}</div> -->
                                                                        <quill-editor class="email-product-title-xl"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, item.title)"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product title').value"
                                                                            [modules]="modules" [(ngModel)]="item.title"
                                                                            [placeholder]="placeholder"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                                        <div class="email-product-description-small"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                            [style.color]="getPropertyValue(block, 'Price text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                            <span
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                class="compare-at-price"
                                                                                [style.color]="getPropertyValue(block, 'Compare at price text color')">${{item.compareAtPrice}}</span>${{item.price}}
                                                                        </div>
                                                                        <quill-editor
                                                                            class="email-product-description-small"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, item.description)"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product description').value"
                                                                            [modules]="modules"
                                                                            [(ngModel)]="item.description"
                                                                            [placeholder]="placeholder"
                                                                            [style.color]="getPropertyValue(block, 'Description text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>

                                                                        <!-- <div class="email-product-description"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                            {{item.description}}</div> -->
                                                                        <!-- <div class="email-product-button-large"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                            {{getPropertyValue(block, 'Button text')}}
                                                                        </div> -->
                                                                        <quill-editor class="email-product-button-large"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product button').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="item.buttonText"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Product button border radius')"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Product button color')"
                                                                            [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>

                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value">
                                                                <!-- <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div> -->
                                                                <quill-editor class="email-product-button-small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Bottom button text'))"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Bottom button text').value"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>


                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'HORIZONTALBLOCK'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <!-- <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div> -->
                                                            <quill-editor class="email-product-block-title"
                                                                theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-title-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'Title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                            <!-- <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div> -->
                                                            <quill-editor class="email-article-subtitle" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                [style.color]="getPropertyValue(block, 'Subtitle text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>

                                                            <table class="email-category-table">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                    <tr id="{{'block-' + index + '-' + itemIndex}}">

                                                                        <td style="width: 50%"><img
                                                                                [src]="item.imageUrl" />
                                                                        </td>
                                                                        <td
                                                                            style="width: 50%; text-align: center; padding: 16px 0px;">
                                                                            <!-- <div class="email-category-title"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                                {{item.title}}</div> -->
                                                                            <quill-editor class="email-category-title"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, item.title)"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product title').value"
                                                                                [modules]="modules"
                                                                                [(ngModel)]="item.title"
                                                                                [placeholder]="placeholder"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                                            <div class="email-product-description-small"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                                [style.color]="getPropertyValue(block, 'Price text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                <span
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                    [style.color]="getPropertyValue(block, 'Compare at price text color')"
                                                                                    class="compare-at-price">${{item.compareAtPrice}}</span>${{item.price}}
                                                                            </div>
                                                                            <quill-editor
                                                                                class="email-product-description-small"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, item.description)"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product description').value"
                                                                                [modules]="modules"
                                                                                [(ngModel)]="item.description"
                                                                                [placeholder]="placeholder"
                                                                                [style.color]="getPropertyValue(block, 'Description text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>

                                                                            <!-- <div class="email-product-description-small"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                {{item.description}}</div> -->
                                                                            <!-- <div class="email-product-button-small"
                                                                                [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                                {{getPropertyValue(block, 'Button
                                                                                text')}}
                                                                            </div> -->
                                                                            <quill-editor
                                                                                class="email-product-button-small"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product button').value"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="item.buttonText"
                                                                                [style.borderRadius]="getPropertyValue(block, 'Product button border radius')"
                                                                                [style.backgroundColor]="getPropertyValue(block, 'Product button color')"
                                                                                [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>

                                                                        </td>

                                                                    </tr>
                                                                </ng-container>
                                                            </table>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value">
                                                                <!-- <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div> -->
                                                                <quill-editor class="email-product-button-small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Bottom button text'))"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Bottom button text').value"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>


                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'HORIZONTALBLOCKALTERNATING'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <!-- <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div> -->
                                                            <quill-editor class="email-product-block-title"
                                                                theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-title-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'Title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                            <!-- <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div> -->
                                                            <quill-editor class="email-article-subtitle" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                [style.color]="getPropertyValue(block, 'Subtitle text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>

                                                            <table class="email-category-table">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                    <ng-container *ngIf="itemIndex % 2 == 0">
                                                                        <tr id="{{'block-' + index + '-' + itemIndex}}">

                                                                            <td style="width: 50%"><img
                                                                                    [src]="item.imageUrl" /></td>
                                                                            <td
                                                                                style="width: 50%; text-align: center; padding: 16px 0px;">
                                                                                <!-- <div class="email-category-title"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                                    {{item.title}}</div> -->
                                                                                <quill-editor
                                                                                    class="email-category-title"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, item.title)"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product title').value"
                                                                                    [modules]="modules"
                                                                                    [(ngModel)]="item.title"
                                                                                    [placeholder]="placeholder"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                                                <div class="email-product-description-small"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                                    [style.color]="getPropertyValue(block, 'Price text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    <span
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                        [style.color]="getPropertyValue(block, 'Compare at price text color')"
                                                                                        class="compare-at-price">${{item.compareAtPrice}}</span>${{item.price}}
                                                                                </div>
                                                                                <!-- <div class="email-product-description-small"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    {{item.description}}</div> -->
                                                                                <quill-editor
                                                                                    class="email-product-description-small"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, item.description)"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product description').value"
                                                                                    [modules]="modules"
                                                                                    [(ngModel)]="item.description"
                                                                                    [placeholder]="placeholder"
                                                                                    [style.color]="getPropertyValue(block, 'Description text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>

                                                                                <!-- <div class="email-product-button-small"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                                    {{getPropertyValue(block, 'Button
                                                                                    text')}}
                                                                                </div> -->
                                                                                <quill-editor
                                                                                    class="email-product-button-small"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product button').value"
                                                                                    [modules]="modules"
                                                                                    [placeholder]="placeholder"
                                                                                    [(ngModel)]="item.buttonText"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Product button border radius')"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Product button color')"
                                                                                    [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>

                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="itemIndex % 2 != 0">
                                                                        <tr id="{{'block-' + index + '-' + itemIndex}}">
                                                                            <td
                                                                                style="width: 50%; text-align: center; padding: 16px 0px;">
                                                                                <!-- <div class="email-category-title"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                                    {{item.title}}</div> -->
                                                                                <quill-editor
                                                                                    class="email-category-title"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, item.title)"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product title').value"
                                                                                    [modules]="modules"
                                                                                    [(ngModel)]="item.title"
                                                                                    [placeholder]="placeholder"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                                <div class="email-product-description-small"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                                    [style.color]="getPropertyValue(block, 'Price text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    <span
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                        [style.color]="getPropertyValue(block, 'Compare at price text color')"
                                                                                        class="compare-at-price">${{item.compareAtPrice}}</span>${{item.price}}
                                                                                </div>
                                                                                <!-- <div class="email-product-description-small"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    {{item.description}}</div> -->
                                                                                <quill-editor
                                                                                    class="email-product-description-small"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, item.description)"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product description').value"
                                                                                    [modules]="modules"
                                                                                    [(ngModel)]="item.description"
                                                                                    [placeholder]="placeholder"
                                                                                    [style.color]="getPropertyValue(block, 'Description text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>

                                                                                <!-- <div class="email-product-button-small"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                                    {{getPropertyValue(block, 'Button
                                                                                    text')}}
                                                                                </div> -->
                                                                                <quill-editor
                                                                                    class="email-product-button-small"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product button').value"
                                                                                    [modules]="modules"
                                                                                    [placeholder]="placeholder"
                                                                                    [(ngModel)]="item.buttonText"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Product button border radius')"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Product button color')"
                                                                                    [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>

                                                                            </td>
                                                                            <td style="width: 50%"><img
                                                                                    [src]="item.imageUrl" /></td>
                                                                        </tr>

                                                                    </ng-container>



                                                                </ng-container>
                                                            </table>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value">
                                                                <!-- <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div> -->
                                                                <quill-editor class="email-product-button-small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Bottom button text'))"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Bottom button text').value"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>


                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'HORIZONTAL'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <!-- <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div> -->
                                                            <quill-editor class="email-product-block-title"
                                                                theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-title-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'Title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                            <!-- <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div> -->
                                                            <quill-editor class="email-article-subtitle" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                [style.color]="getPropertyValue(block, 'Subtitle text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>

                                                            <table class="email-category-table">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                    <tr id="{{'block-' + index + '-' + itemIndex}}">

                                                                        <td style="width: 50%;"><img
                                                                                class="email-gallery-image"
                                                                                style="margin: 32px 0px; width: calc(100% - 32px)"
                                                                                [src]="item.imageUrl"
                                                                                [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius" />
                                                                        </td>
                                                                        <td
                                                                            style="width: 50%; text-align: center; padding: 16px">
                                                                            <!-- <div class="email-category-title"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                                {{item.title}}</div> -->
                                                                            <quill-editor class="email-category-title"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, item.title)"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product title').value"
                                                                                [modules]="modules"
                                                                                [(ngModel)]="item.title"
                                                                                [placeholder]="placeholder"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                                            <div class="email-product-description-small"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                                [style.color]="getPropertyValue(block, 'Price text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                <span
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                    [style.color]="getPropertyValue(block, 'Compare at price text color')"
                                                                                    class="compare-at-price">${{item.compareAtPrice}}</span>${{item.price}}
                                                                            </div>
                                                                            <!-- <div class="email-product-description-small"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                {{item.description}}
                                                                            </div> -->
                                                                            <quill-editor
                                                                                class="email-product-description-small"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, item.description)"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product description').value"
                                                                                [modules]="modules"
                                                                                [(ngModel)]="item.description"
                                                                                [placeholder]="placeholder"
                                                                                [style.color]="getPropertyValue(block, 'Description text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>

                                                                            <!-- <div class="email-product-button-small"
                                                                                [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                                {{getPropertyValue(block, 'Button
                                                                                text')}}
                                                                            </div> -->
                                                                            <quill-editor
                                                                                class="email-product-button-small"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product button').value"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="item.buttonText"
                                                                                [style.borderRadius]="getPropertyValue(block, 'Product button border radius')"
                                                                                [style.backgroundColor]="getPropertyValue(block, 'Product button color')"
                                                                                [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>

                                                                        </td>

                                                                    </tr>
                                                                </ng-container>
                                                            </table>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value">
                                                                <!-- <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div> -->
                                                                <quill-editor class="email-product-button-small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Bottom button text'))"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Bottom button text').value"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>


                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'HORIZONTALALTERNATING'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <!-- <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div> -->
                                                            <quill-editor class="email-product-block-title"
                                                                theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-title-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'Title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                            <!-- <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div> -->
                                                            <quill-editor class="email-article-subtitle" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                [style.color]="getPropertyValue(block, 'Subtitle text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>

                                                            <table class="email-category-table">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                    <ng-container *ngIf="itemIndex % 2 == 0">
                                                                        <tr id="{{'block-' + index + '-' + itemIndex}}">

                                                                            <td style="width: 50%;"><img
                                                                                    style="margin: 32px 0px; width: calc(100% - 32px)"
                                                                                    class="email-gallery-image"
                                                                                    [src]="item.imageUrl"
                                                                                    [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius" />
                                                                            </td>
                                                                            <td
                                                                                style="width: 50%; text-align: center; padding: 16px">
                                                                                <!-- <div class="email-category-title"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                                    {{item.title}}</div> -->
                                                                                <quill-editor
                                                                                    class="email-category-title"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, item.title)"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product title').value"
                                                                                    [modules]="modules"
                                                                                    [(ngModel)]="item.title"
                                                                                    [placeholder]="placeholder"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                                <div class="email-product-description-small"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                                    [style.color]="getPropertyValue(block, 'Price text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    <span
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                        [style.color]="getPropertyValue(block, 'Compare at price text color')"
                                                                                        class="compare-at-price">${{item.compareAtPrice}}</span>${{item.price}}
                                                                                </div>
                                                                                <!-- <div class="email-product-description-small"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    {{item.description}}
                                                                                </div> -->
                                                                                <quill-editor
                                                                                    class="email-product-description-small"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, item.description)"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product description').value"
                                                                                    [modules]="modules"
                                                                                    [(ngModel)]="item.description"
                                                                                    [placeholder]="placeholder"
                                                                                    [style.color]="getPropertyValue(block, 'Description text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>

                                                                                <!-- <div class="email-product-button-small"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                                    {{getPropertyValue(block, 'Button
                                                                                    text')}}
                                                                                </div> -->
                                                                                <quill-editor
                                                                                    class="email-product-button-small"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product button').value"
                                                                                    [modules]="modules"
                                                                                    [placeholder]="placeholder"
                                                                                    [(ngModel)]="item.buttonText"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Product button border radius')"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Product button color')"
                                                                                    [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>

                                                                            </td>

                                                                        </tr>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="itemIndex % 2 != 0">
                                                                        <tr id="{{'block-' + index + '-' + itemIndex}}">


                                                                            <td
                                                                                style="width: 50%; text-align: center; padding: 16px">
                                                                                <!-- <div class="email-category-title"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                                    {{item.title}}</div> -->
                                                                                <quill-editor
                                                                                    class="email-category-title"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, item.title)"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product title').value"
                                                                                    [modules]="modules"
                                                                                    [(ngModel)]="item.title"
                                                                                    [placeholder]="placeholder"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                                <div class="email-product-description-small"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                                    [style.color]="getPropertyValue(block, 'Price text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    <span
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                        [style.color]="getPropertyValue(block, 'Compare at price text color')"
                                                                                        class="compare-at-price">${{item.compareAtPrice}}</span>${{item.price}}
                                                                                </div>
                                                                                <!-- <div class="email-product-description-small"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    {{item.description}}
                                                                                </div> -->
                                                                                <quill-editor
                                                                                    class="email-product-description-small"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, item.description)"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product description').value"
                                                                                    [modules]="modules"
                                                                                    [(ngModel)]="item.description"
                                                                                    [placeholder]="placeholder"
                                                                                    [style.color]="getPropertyValue(block, 'Description text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>

                                                                                <!-- <div class="email-product-button-small"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                                    {{getPropertyValue(block, 'Button
                                                                                    text')}}
                                                                                </div> -->
                                                                                <quill-editor
                                                                                    class="email-product-button-small"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product button').value"
                                                                                    [modules]="modules"
                                                                                    [placeholder]="placeholder"
                                                                                    [(ngModel)]="item.buttonText"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Product button border radius')"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Product button color')"
                                                                                    [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>

                                                                            </td>
                                                                            <td style="width: 50%;"><img
                                                                                    class="email-gallery-image"
                                                                                    style="margin: 32px 0px; width: calc(100% - 32px)"
                                                                                    [src]="item.imageUrl"
                                                                                    [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius" />
                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>



                                                                </ng-container>
                                                            </table>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value">
                                                                <!-- <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div> -->
                                                                <quill-editor class="email-product-button-small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Bottom button text'))"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Bottom button text').value"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>


                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARDXL2IMAGES'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div>
                                                            <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div>
                                                            <div class="email-product-table" nzJustify="center">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                    <div class="email-product-item-xl"
                                                                        style="text-align: center; max-width: unset; padding: 24px;"
                                                                        id="{{'block-' + index + '-' + itemIndex}}">
                                                                        <tr>
                                                                            <td style="width: 50%;"><img
                                                                                    class="email-product-image-large"
                                                                                    src="{{item.imageUrl}}" /></td>
                                                                            <td style="width: 50%;"><img
                                                                                    class="email-product-image-large"
                                                                                    src="{{item.imageUrl}}" /></td>
                                                                        </tr>

                                                                        <div class="email-product-title-xl"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                            style="text-align: center;">
                                                                            {{item.title}}</div>
                                                                        <div class="email-product-description"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                            {{item.description}}</div>
                                                                        <br>
                                                                        <div class="email-product-button-large"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                            {{getPropertyValue(block, 'Button text')}}
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value">
                                                                <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'COLLECTION'">
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <!-- <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div> -->
                                                            <quill-editor class="email-product-block-title"
                                                                theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-title-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'Title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                            <!-- <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div> -->
                                                            <quill-editor class="email-article-subtitle" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                [style.color]="getPropertyValue(block, 'Subtitle text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>
                                                            <div class="email-product-table" nzJustify="center">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Collections').value; let itemIndex=index">
                                                                    <div id="{{'block-' + index + '-' + itemIndex}}"
                                                                        class="email-product-item-small"
                                                                        style="text-align: center">
                                                                        <img class="email-product-image-small"
                                                                            src="{{item.data.imageUrl}}"
                                                                            [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius" />
                                                                        <!-- <div class="email-product-title-small"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                            {{item.title}}</div> -->
                                                                        <quill-editor class="email-product-title-small"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, item.data.title)"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product title').value"
                                                                            [modules]="modules"
                                                                            [(ngModel)]="item.data.title"
                                                                            [placeholder]="placeholder"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                        <div class="email-product-description-small"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                            [style.color]="getPropertyValue(block, 'Price text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                            <span
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                class="compare-at-price"
                                                                                [style.color]="getPropertyValue(block, 'Compare at price text color')">${{item.compareAtPrice}}</span>${{item.price}}
                                                                        </div>
                                                                        <!-- <div class="email-product-description-small"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                            {{item.description}}
                                                                        </div> -->
                                                                        <!-- <quill-editor
                                                                            class="email-product-description-small"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, item.data.description)"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product description').value"
                                                                            [modules]="modules"
                                                                            [(ngModel)]="item.description"
                                                                            [placeholder]="placeholder"
                                                                            [style.color]="getPropertyValue(block, 'Description text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor> -->
                                                                        <!-- <div class="email-product-button-small"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                            {{getPropertyValue(block, 'Button text')}}
                                                                        </div> -->
                                                                        <quill-editor class="email-product-button-small"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product button').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="item.buttonText"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Product button border radius')"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Product button color')"
                                                                            [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value">
                                                                <!-- <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div> -->
                                                                <quill-editor class="email-product-button-small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Bottom button text'))"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Bottom button text').value"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>

                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARDLARGE'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <!-- <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div> -->
                                                            <quill-editor class="email-product-block-title"
                                                                theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-title-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'Title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                            <!-- <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div> -->
                                                            <quill-editor class="email-article-subtitle" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                [style.color]="getPropertyValue(block, 'Subtitle text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>

                                                            <div class="email-product-table" nzJustify="center">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Collections').value; let itemIndex=index">
                                                                    <div id="{{'block-' + index + '-' + itemIndex}}"
                                                                        class="email-product-item"
                                                                        style="text-align: center">
                                                                        <img class="email-product-image-large"
                                                                            src="{{item.data.imageUrl}}"
                                                                            [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius" />
                                                                        <!-- <div class="email-category-title"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                            {{item.title}}</div> -->
                                                                        <quill-editor class="email-category-title"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, item.data.title)"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product title').value"
                                                                            [modules]="modules"
                                                                            [(ngModel)]="item.data.title"
                                                                            [placeholder]="placeholder"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                                        <div class="email-product-description-small"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                            [style.color]="getPropertyValue(block, 'Price text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                            <span
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                class="compare-at-price"
                                                                                [style.color]="getPropertyValue(block, 'Compare at price text color')">${{item.compareAtPrice}}</span>${{item.price}}
                                                                        </div>
                                                                        <!-- <quill-editor
                                                                            class="email-product-description-small"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, item.description)"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product description').value"
                                                                            [modules]="modules"
                                                                            [(ngModel)]="item.description"
                                                                            [placeholder]="placeholder"
                                                                            [style.color]="getPropertyValue(block, 'Description text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor> -->

                                                                        <!-- <div class="email-product-description-small"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                            {{item.description}}
                                                                        </div> -->
                                                                        <!-- <div class="email-product-button-large"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                            {{getPropertyValue(block, 'Button text')}}
                                                                        </div> -->
                                                                        <quill-editor class="email-product-button-large"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product button').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="item.buttonText"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Product button border radius')"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Product button color')"
                                                                            [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value">
                                                                <!-- <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div> -->
                                                                <quill-editor class="email-product-button-small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Bottom button text'))"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Bottom button text').value"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>


                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARDXL'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <!-- <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div> -->
                                                            <quill-editor class="email-product-block-title"
                                                                theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-title-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'Title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                            <!-- <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div> -->
                                                            <quill-editor class="email-article-subtitle" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                [style.color]="getPropertyValue(block, 'Subtitle text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>

                                                            <div class="email-product-table" nzJustify="center">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Collections').value; let itemIndex=index">
                                                                    <div id="{{'block-' + index + '-' + itemIndex}}"
                                                                        class="email-product-item-xl"
                                                                        style="text-align: center">
                                                                        <img class="email-product-image-large"
                                                                            src="{{item.data.imageUrl}}"
                                                                            [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius" />
                                                                        <!-- <div class="email-product-title-xl"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                            {{item.title}}</div> -->
                                                                        <quill-editor class="email-product-title-xl"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, item.data.title)"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product title').value"
                                                                            [modules]="modules"
                                                                            [(ngModel)]="item.data.title"
                                                                            [placeholder]="placeholder"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                                        <div class="email-product-description-small"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                            [style.color]="getPropertyValue(block, 'Price text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                            <span
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                class="compare-at-price"
                                                                                [style.color]="getPropertyValue(block, 'Compare at price text color')">${{item.compareAtPrice}}</span>${{item.price}}
                                                                        </div>
                                                                        <!-- <quill-editor
                                                                            class="email-product-description-small"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, item.description)"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product description').value"
                                                                            [modules]="modules"
                                                                            [(ngModel)]="item.description"
                                                                            [placeholder]="placeholder"
                                                                            [style.color]="getPropertyValue(block, 'Description text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor> -->

                                                                        <!-- <div class="email-product-description"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                            {{item.description}}</div> -->
                                                                        <!-- <div class="email-product-button-large"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                            {{getPropertyValue(block, 'Button text')}}
                                                                        </div> -->
                                                                        <quill-editor class="email-product-button-large"
                                                                            theme="bubble"
                                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product button').value"
                                                                            [modules]="modules"
                                                                            [placeholder]="placeholder"
                                                                            [(ngModel)]="item.buttonText"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Product button border radius')"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Product button color')"
                                                                            [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>

                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value">
                                                                <!-- <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div> -->
                                                                <quill-editor class="email-product-button-small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Bottom button text'))"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Bottom button text').value"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>


                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'HORIZONTALBLOCK'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <!-- <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div> -->
                                                            <quill-editor class="email-product-block-title"
                                                                theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-title-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'Title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                            <!-- <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div> -->
                                                            <quill-editor class="email-article-subtitle" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                [style.color]="getPropertyValue(block, 'Subtitle text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>

                                                            <table class="email-category-table">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Collections').value; let itemIndex=index">
                                                                    <tr id="{{'block-' + index + '-' + itemIndex}}">

                                                                        <td style="width: 50%"><img
                                                                                [src]="item.data.imageUrl" />
                                                                        </td>
                                                                        <td
                                                                            style="width: 50%; text-align: center; padding: 16px 0px;">
                                                                            <!-- <div class="email-category-title"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                                {{item.title}}</div> -->
                                                                            <quill-editor class="email-category-title"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, item.data.title)"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product title').value"
                                                                                [modules]="modules"
                                                                                [(ngModel)]="item.data.title"
                                                                                [placeholder]="placeholder"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                                            <div class="email-product-description-small"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                                [style.color]="getPropertyValue(block, 'Price text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                <span
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                    [style.color]="getPropertyValue(block, 'Compare at price text color')"
                                                                                    class="compare-at-price">${{item.compareAtPrice}}</span>${{item.price}}
                                                                            </div>
                                                                            <!-- <quill-editor
                                                                                class="email-product-description-small"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, item.description)"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product description').value"
                                                                                [modules]="modules"
                                                                                [(ngModel)]="item.description"
                                                                                [placeholder]="placeholder"
                                                                                [style.color]="getPropertyValue(block, 'Description text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor> -->

                                                                            <!-- <div class="email-product-description-small"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                {{item.description}}</div> -->
                                                                            <!-- <div class="email-product-button-small"
                                                                                [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                                {{getPropertyValue(block, 'Button
                                                                                text')}}
                                                                            </div> -->
                                                                            <quill-editor
                                                                                class="email-product-button-small"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product button').value"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="item.buttonText"
                                                                                [style.borderRadius]="getPropertyValue(block, 'Product button border radius')"
                                                                                [style.backgroundColor]="getPropertyValue(block, 'Product button color')"
                                                                                [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>

                                                                        </td>

                                                                    </tr>
                                                                </ng-container>
                                                            </table>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value">
                                                                <!-- <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div> -->
                                                                <quill-editor class="email-product-button-small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Bottom button text'))"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Bottom button text').value"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>


                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'HORIZONTALBLOCKALTERNATING'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <!-- <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div> -->
                                                            <quill-editor class="email-product-block-title"
                                                                theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-title-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'Title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                            <!-- <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div> -->
                                                            <quill-editor class="email-article-subtitle" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                [style.color]="getPropertyValue(block, 'Subtitle text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>

                                                            <table class="email-category-table">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Collections').value; let itemIndex=index">
                                                                    <ng-container *ngIf="itemIndex % 2 == 0">
                                                                        <tr id="{{'block-' + index + '-' + itemIndex}}">

                                                                            <td style="width: 50%"><img
                                                                                    [src]="item.data.imageUrl" /></td>
                                                                            <td
                                                                                style="width: 50%; text-align: center; padding: 16px 0px;">
                                                                                <!-- <div class="email-category-title"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                                    {{item.title}}</div> -->
                                                                                <quill-editor
                                                                                    class="email-category-title"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, item.data.title)"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product title').value"
                                                                                    [modules]="modules"
                                                                                    [(ngModel)]="item.data.title"
                                                                                    [placeholder]="placeholder"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                                                <div class="email-product-description-small"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                                    [style.color]="getPropertyValue(block, 'Price text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    <span
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                        [style.color]="getPropertyValue(block, 'Compare at price text color')"
                                                                                        class="compare-at-price">${{item.compareAtPrice}}</span>${{item.price}}
                                                                                </div>
                                                                                <!-- <div class="email-product-description-small"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    {{item.description}}</div> -->
                                                                                <!-- <quill-editor
                                                                                    class="email-product-description-small"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, item.description)"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product description').value"
                                                                                    [modules]="modules"
                                                                                    [(ngModel)]="item.description"
                                                                                    [placeholder]="placeholder"
                                                                                    [style.color]="getPropertyValue(block, 'Description text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor> -->

                                                                                <!-- <div class="email-product-button-small"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                                    {{getPropertyValue(block, 'Button
                                                                                    text')}}
                                                                                </div> -->
                                                                                <quill-editor
                                                                                    class="email-product-button-small"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product button').value"
                                                                                    [modules]="modules"
                                                                                    [placeholder]="placeholder"
                                                                                    [(ngModel)]="item.buttonText"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Product button border radius')"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Product button color')"
                                                                                    [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>

                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="itemIndex % 2 != 0">
                                                                        <tr id="{{'block-' + index + '-' + itemIndex}}">
                                                                            <td
                                                                                style="width: 50%; text-align: center; padding: 16px 0px;">
                                                                                <!-- <div class="email-category-title"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                                    {{item.title}}</div> -->
                                                                                <quill-editor
                                                                                    class="email-category-title"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, item.data.title)"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product title').value"
                                                                                    [modules]="modules"
                                                                                    [(ngModel)]="item.data.title"
                                                                                    [placeholder]="placeholder"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                                <div class="email-product-description-small"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                                    [style.color]="getPropertyValue(block, 'Price text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    <span
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                        [style.color]="getPropertyValue(block, 'Compare at price text color')"
                                                                                        class="compare-at-price">${{item.compareAtPrice}}</span>${{item.price}}
                                                                                </div>
                                                                                <!-- <div class="email-product-description-small"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    {{item.description}}</div> -->
                                                                                <!-- <quill-editor
                                                                                    class="email-product-description-small"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, item.description)"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product description').value"
                                                                                    [modules]="modules"
                                                                                    [(ngModel)]="item.description"
                                                                                    [placeholder]="placeholder"
                                                                                    [style.color]="getPropertyValue(block, 'Description text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor> -->

                                                                                <!-- <div class="email-product-button-small"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                                    {{getPropertyValue(block, 'Button
                                                                                    text')}}
                                                                                </div> -->
                                                                                <quill-editor
                                                                                    class="email-product-button-small"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product button').value"
                                                                                    [modules]="modules"
                                                                                    [placeholder]="placeholder"
                                                                                    [(ngModel)]="item.buttonText"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Product button border radius')"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Product button color')"
                                                                                    [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>

                                                                            </td>
                                                                            <td style="width: 50%"><img
                                                                                    [src]="item.data.imageUrl" /></td>
                                                                        </tr>

                                                                    </ng-container>



                                                                </ng-container>
                                                            </table>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value">
                                                                <!-- <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div> -->
                                                                <quill-editor class="email-product-button-small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Bottom button text'))"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Bottom button text').value"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>


                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'HORIZONTAL'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <!-- <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div> -->
                                                            <quill-editor class="email-product-block-title"
                                                                theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-title-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'Title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                            <!-- <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div> -->
                                                            <quill-editor class="email-article-subtitle" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                [style.color]="getPropertyValue(block, 'Subtitle text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>

                                                            <table class="email-category-table">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Collections').value; let itemIndex=index">
                                                                    <tr id="{{'block-' + index + '-' + itemIndex}}">

                                                                        <td style="width: 50%;"><img
                                                                                class="email-gallery-image"
                                                                                style="margin: 32px 0px; width: calc(100% - 32px)"
                                                                                [src]="item.data.imageUrl"
                                                                                [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius" />
                                                                        </td>
                                                                        <td
                                                                            style="width: 50%; text-align: center; padding: 16px">
                                                                            <!-- <div class="email-category-title"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                                {{item.title}}</div> -->
                                                                            <quill-editor class="email-category-title"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, item.data.title)"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product title').value"
                                                                                [modules]="modules"
                                                                                [(ngModel)]="item.data.title"
                                                                                [placeholder]="placeholder"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                                            <div class="email-product-description-small"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                                [style.color]="getPropertyValue(block, 'Price text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                <span
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                    [style.color]="getPropertyValue(block, 'Compare at price text color')"
                                                                                    class="compare-at-price">${{item.compareAtPrice}}</span>${{item.price}}
                                                                            </div>
                                                                            <!-- <div class="email-product-description-small"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                {{item.description}}
                                                                            </div> -->
                                                                            <!-- <quill-editor
                                                                                class="email-product-description-small"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, item.description)"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product description').value"
                                                                                [modules]="modules"
                                                                                [(ngModel)]="item.description"
                                                                                [placeholder]="placeholder"
                                                                                [style.color]="getPropertyValue(block, 'Description text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor> -->

                                                                            <!-- <div class="email-product-button-small"
                                                                                [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                                {{getPropertyValue(block, 'Button
                                                                                text')}}
                                                                            </div> -->
                                                                            <quill-editor
                                                                                class="email-product-button-small"
                                                                                theme="bubble"
                                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product button').value"
                                                                                [modules]="modules"
                                                                                [placeholder]="placeholder"
                                                                                [(ngModel)]="item.buttonText"
                                                                                [style.borderRadius]="getPropertyValue(block, 'Product button border radius')"
                                                                                [style.backgroundColor]="getPropertyValue(block, 'Product button color')"
                                                                                [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>

                                                                        </td>

                                                                    </tr>
                                                                </ng-container>
                                                            </table>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value">
                                                                <!-- <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div> -->
                                                                <quill-editor class="email-product-button-small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Bottom button text'))"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Bottom button text').value"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>


                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'HORIZONTALALTERNATING'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <!-- <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div> -->
                                                            <quill-editor class="email-product-block-title"
                                                                theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Title'))"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Title').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-title-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Title').value"
                                                                [style.color]="getPropertyValue(block, 'Title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                            <!-- <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div> -->
                                                            <quill-editor class="email-article-subtitle" theme="bubble"
                                                                (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Subtitle'), 'CENTER', true, 'Subtitle')"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Subtitle').value"
                                                                [modules]="modules" [placeholder]="placeholder"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [(ngModel)]="getArrayItem(block.properties, 'Subtitle').value"
                                                                [style.color]="getPropertyValue(block, 'Subtitle text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight"></quill-editor>

                                                            <table class="email-category-table">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Collections').value; let itemIndex=index">
                                                                    <ng-container *ngIf="itemIndex % 2 == 0">
                                                                        <tr id="{{'block-' + index + '-' + itemIndex}}">

                                                                            <td style="width: 50%;"><img
                                                                                    style="margin: 32px 0px; width: calc(100% - 32px)"
                                                                                    class="email-gallery-image"
                                                                                    [src]="item.data.imageUrl"
                                                                                    [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius" />
                                                                            </td>
                                                                            <td
                                                                                style="width: 50%; text-align: center; padding: 16px">
                                                                                <!-- <div class="email-category-title"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                                    {{item.title}}</div> -->
                                                                                <quill-editor
                                                                                    class="email-category-title"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, item.data.title)"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product title').value"
                                                                                    [modules]="modules"
                                                                                    [(ngModel)]="item.data.title"
                                                                                    [placeholder]="placeholder"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                                <div class="email-product-description-small"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                                    [style.color]="getPropertyValue(block, 'Price text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    <span
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                        [style.color]="getPropertyValue(block, 'Compare at price text color')"
                                                                                        class="compare-at-price">${{item.compareAtPrice}}</span>${{item.price}}
                                                                                </div>
                                                                                <!-- <div class="email-product-description-small"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    {{item.description}}
                                                                                </div> -->
                                                                                <quill-editor
                                                                                    class="email-product-description-small"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, item.description)"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product description').value"
                                                                                    [modules]="modules"
                                                                                    [(ngModel)]="item.description"
                                                                                    [placeholder]="placeholder"
                                                                                    [style.color]="getPropertyValue(block, 'Description text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>

                                                                                <!-- <div class="email-product-button-small"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                                    {{getPropertyValue(block, 'Button
                                                                                    text')}}
                                                                                </div> -->
                                                                                <quill-editor
                                                                                    class="email-product-button-small"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product button').value"
                                                                                    [modules]="modules"
                                                                                    [placeholder]="placeholder"
                                                                                    [(ngModel)]="item.buttonText"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Product button border radius')"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Product button color')"
                                                                                    [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>

                                                                            </td>

                                                                        </tr>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="itemIndex % 2 != 0">
                                                                        <tr id="{{'block-' + index + '-' + itemIndex}}">


                                                                            <td
                                                                                style="width: 50%; text-align: center; padding: 16px">
                                                                                <!-- <div class="email-category-title"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                                    {{item.title}}</div> -->
                                                                                <quill-editor
                                                                                    class="email-category-title"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, item.data.title)"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product title').value"
                                                                                    [modules]="modules"
                                                                                    [(ngModel)]="item.data.title"
                                                                                    [placeholder]="placeholder"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>
                                                                                <div class="email-product-description-small"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                                    [style.color]="getPropertyValue(block, 'Price text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    <span
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                        [style.color]="getPropertyValue(block, 'Compare at price text color')"
                                                                                        class="compare-at-price">${{item.compareAtPrice}}</span>${{item.price}}
                                                                                </div>
                                                                                <!-- <div class="email-product-description-small"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    {{item.description}}
                                                                                </div> -->
                                                                                <quill-editor
                                                                                    class="email-product-description-small"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, item.description)"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product description').value"
                                                                                    [modules]="modules"
                                                                                    [(ngModel)]="item.description"
                                                                                    [placeholder]="placeholder"
                                                                                    [style.color]="getPropertyValue(block, 'Description text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"></quill-editor>

                                                                                <!-- <div class="email-product-button-small"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                                    {{getPropertyValue(block, 'Button
                                                                                    text')}}
                                                                                </div> -->
                                                                                <quill-editor
                                                                                    class="email-product-button-small"
                                                                                    theme="bubble"
                                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Button text'))"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product button').value"
                                                                                    [modules]="modules"
                                                                                    [placeholder]="placeholder"
                                                                                    [(ngModel)]="item.buttonText"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Product button border radius')"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Product button color')"
                                                                                    [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>

                                                                            </td>
                                                                            <td style="width: 50%;"><img
                                                                                    class="email-gallery-image"
                                                                                    style="margin: 32px 0px; width: calc(100% - 32px)"
                                                                                    [src]="item.data.imageUrl"
                                                                                    [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius" />
                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>



                                                                </ng-container>
                                                            </table>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value">
                                                                <!-- <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div> -->
                                                                <quill-editor class="email-product-button-small"
                                                                    theme="bubble"
                                                                    (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Bottom button text'))"
                                                                    [modules]="modules" [placeholder]="placeholder"
                                                                    [(ngModel)]="getArrayItem(block.properties, 'Bottom button text').value"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Product button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight"></quill-editor>


                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARDXL2IMAGES'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div>
                                                            <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div>
                                                            <div class="email-product-table" nzJustify="center">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Collections').value; let itemIndex=index">
                                                                    <div class="email-product-item-xl"
                                                                        style="text-align: center; max-width: unset; padding: 24px;"
                                                                        id="{{'block-' + index + '-' + itemIndex}}">
                                                                        <tr>
                                                                            <td style="width: 50%;"><img
                                                                                    class="email-product-image-large"
                                                                                    src="{{item.data.imageUrl}}" /></td>
                                                                            <td style="width: 50%;"><img
                                                                                    class="email-product-image-large"
                                                                                    src="{{item.data.imageUrl}}" /></td>
                                                                        </tr>

                                                                        <div class="email-product-title-xl"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                            style="text-align: center;">
                                                                            {{item.title}}</div>
                                                                        <div class="email-product-description"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                            {{item.description}}</div>
                                                                        <br>
                                                                        <div class="email-product-button-large"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                            {{getPropertyValue(block, 'Button text')}}
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Button').value">
                                                                <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'BANNER'">

                                                    <div id="{{'block-' + index}}" class="email-block-wrapper padding16"
                                                        (click)="selectBlock(block)"
                                                        [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                        <!-- <div class="email-banner-wrapper"
                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                            {{getPropertyValue(block, 'Text')}}
                                                        </div> -->
                                                        <quill-editor class="email-banner-wrapper" theme="bubble"
                                                            (onEditorCreated)="onEditorCreated($event, getPropertyValue(block, 'Text'), 'CENTER', false)"
                                                            [modules]="modules" [placeholder]="placeholder"
                                                            [(ngModel)]="getArrayItem(block.properties, 'Text').value"
                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"></quill-editor>

                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'GALLERY'">
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding24 horizontal"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">

                                                            <ng-container
                                                                *ngFor="let item of getArrayItem(block.properties, 'Images').value; let imageIndex=index">

                                                                <ng-container
                                                                    *ngIf="imageIndex == 0 || imageIndex == 1">
                                                                    <div class="email-gallery-image w50">
                                                                        <ng-container
                                                                            *ngIf="!getArrayItem(item, 'Image').value">
                                                                            <div class="image-thumbnail"
                                                                                [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius">
                                                                                <i class="lni lni-image"></i>
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="getArrayItem(item, 'Image').value">
                                                                            <img class="email-gallery-image-img"
                                                                                [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius"
                                                                                src="{{getArrayItem(item, 'Image').value}}" />
                                                                        </ng-container>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container *ngIf="imageIndex == 2">
                                                                    <div class="email-gallery-image w100">
                                                                        <ng-container
                                                                            *ngIf="!getArrayItem(item, 'Image').value">
                                                                            <div class="image-thumbnail"
                                                                                [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius">
                                                                                <i class="lni lni-image"></i>
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="getArrayItem(item, 'Image').value">
                                                                            <img class="email-gallery-image-img"
                                                                                [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius"
                                                                                src="{{getArrayItem(item, 'Image').value}}" />
                                                                        </ng-container>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="imageIndex == 3 || imageIndex == 4 || imageIndex == 5">
                                                                    <div class="email-gallery-image w33">
                                                                        <ng-container
                                                                            *ngIf="!getArrayItem(item, 'Image').value">
                                                                            <div class="image-thumbnail"
                                                                                [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius">
                                                                                <i class="lni lni-image"></i>
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="getArrayItem(item, 'Image').value">
                                                                            <img class="email-gallery-image-img"
                                                                                [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius"
                                                                                src="{{getArrayItem(item, 'Image').value}}" />
                                                                        </ng-container>
                                                                    </div>
                                                                </ng-container>

                                                            </ng-container>

                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARDREVERSE'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding24 horizontal"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">

                                                            <ng-container
                                                                *ngFor="let item of getArrayItem(block.properties, 'Images').value; let imageIndex=index">

                                                                <ng-container
                                                                    *ngIf="imageIndex == 1 || imageIndex == 2">
                                                                    <div class="email-gallery-image w50">
                                                                        <ng-container
                                                                            *ngIf="!getArrayItem(item, 'Image').value">
                                                                            <div class="image-thumbnail"
                                                                                [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius">
                                                                                <i class="lni lni-image"></i>
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="getArrayItem(item, 'Image').value">
                                                                            <img class="email-gallery-image-img"
                                                                                [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius"
                                                                                src="{{getArrayItem(item, 'Image').value}}" />
                                                                        </ng-container>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container *ngIf="imageIndex == 0">
                                                                    <div class="email-gallery-image w100">
                                                                        <ng-container
                                                                            *ngIf="!getArrayItem(item, 'Image').value">
                                                                            <div class="image-thumbnail"
                                                                                [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius">
                                                                                <i class="lni lni-image"></i>
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="getArrayItem(item, 'Image').value">
                                                                            <img class="email-gallery-image-img"
                                                                                [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius"
                                                                                src="{{getArrayItem(item, 'Image').value}}" />
                                                                        </ng-container>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="imageIndex == 3 || imageIndex == 4 || imageIndex == 5">
                                                                    <div class="email-gallery-image w33">
                                                                        <ng-container
                                                                            *ngIf="!getArrayItem(item, 'Image').value">
                                                                            <div class="image-thumbnail"
                                                                                [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius">
                                                                                <i class="lni lni-image"></i>
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="getArrayItem(item, 'Image').value">
                                                                            <img class="email-gallery-image-img"
                                                                                [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius"
                                                                                src="{{getArrayItem(item, 'Image').value}}" />
                                                                        </ng-container>
                                                                    </div>
                                                                </ng-container>

                                                            </ng-container>

                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'FIVE'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding24 horizontal"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">

                                                            <ng-container
                                                                *ngFor="let item of getArrayItem(block.properties, 'Images').value; let imageIndex=index">

                                                                <ng-container
                                                                    *ngIf="imageIndex == 0 || imageIndex == 1">
                                                                    <div class="email-gallery-image w50">
                                                                        <ng-container
                                                                            *ngIf="!getArrayItem(item, 'Image').value">
                                                                            <div class="image-thumbnail"
                                                                                [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius">
                                                                                <i class="lni lni-image"></i>
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="getArrayItem(item, 'Image').value">
                                                                            <img class="email-gallery-image-img"
                                                                                [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius"
                                                                                src="{{getArrayItem(item, 'Image').value}}" />
                                                                        </ng-container>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="imageIndex == 2 || imageIndex == 3 || imageIndex == 4">
                                                                    <div class="email-gallery-image w33">
                                                                        <ng-container
                                                                            *ngIf="!getArrayItem(item, 'Image').value">
                                                                            <div class="image-thumbnail"
                                                                                [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius">
                                                                                <i class="lni lni-image"></i>
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="getArrayItem(item, 'Image').value">
                                                                            <img class="email-gallery-image-img"
                                                                                [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius"
                                                                                src="{{getArrayItem(item, 'Image').value}}" />
                                                                        </ng-container>
                                                                    </div>
                                                                </ng-container>

                                                            </ng-container>

                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'TWOCOLUMNS'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding24 horizontal"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">

                                                            <ng-container
                                                                *ngFor="let item of getArrayItem(block.properties, 'Images').value; let imageIndex=index">
                                                                <div class="email-gallery-image w50">
                                                                    <ng-container
                                                                        *ngIf="!getArrayItem(item, 'Image').value">
                                                                        <div class="image-thumbnail"
                                                                            [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius">
                                                                            <i class="lni lni-image"></i>
                                                                        </div>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="getArrayItem(item, 'Image').value">
                                                                        <img class="email-gallery-image-img"
                                                                            [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius"
                                                                            src="{{getArrayItem(item, 'Image').value}}" />
                                                                    </ng-container>
                                                                </div>
                                                            </ng-container>

                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'THREECOLUMNS'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding24 horizontal"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">

                                                            <ng-container
                                                                *ngFor="let item of getArrayItem(block.properties, 'Images').value; let imageIndex=index">
                                                                <div class="email-gallery-image w33">
                                                                    <ng-container
                                                                        *ngIf="!getArrayItem(item, 'Image').value">
                                                                        <div class="image-thumbnail"
                                                                            [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius">
                                                                            <i class="lni lni-image"></i>
                                                                        </div>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="getArrayItem(item, 'Image').value">
                                                                        <img class="email-gallery-image-img"
                                                                            [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius"
                                                                            src="{{getArrayItem(item, 'Image').value}}" />
                                                                    </ng-container>
                                                                </div>
                                                            </ng-container>

                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                                <!-- <button class="add-block-button" nz-button nzType="primary"
                                                    (click)="openBlockLibrary(index)" style=""><span nz-icon
                                                        nzType="plus" nzTheme="outline"></span></button> -->
                                            </ng-container>

                                        </div>
                                        <div nz-col nzFlex="150px" style="height: 24px;" *ngIf="this.mode == 'EDIT'">
                                            <div class="email-block-controls"
                                                style="text-align: left; padding-left: 10px;"
                                                [ngStyle]="{'visibility': block.controlsVisible ? 'visible' : 'hidden'}">
                                                <div class="custom-circle-button" (click)="removeItem(index)"
                                                    style="color: red"><span nz-icon nzType="delete"
                                                        nzTheme="outline"></span></div>
                                                <div class="custom-circle-button" (click)="duplicateBlock(index)"
                                                    style="margin-left: 6px;"><span nz-icon nzType="copy"
                                                        nzTheme="outline"></span></div>
                                                <ng-container
                                                    *ngIf="block.type == 'HERO' || block.type == 'ARTICLE' || block.type == 'BANNER'">
                                                    <!-- <button (click)="this.refreshBlockIndex = index" nzShape="circle"
                                                        nz-button nzType="default" nz-popover
                                                        [nzPopoverContent]="contentTemplate"
                                                        nzPopoverPlacement="bottomRight" nzPopoverTrigger="click"
                                                        style="transition: none !important;"
                                                        [(nzPopoverVisible)]="block.popoverVisible"><span nz-icon
                                                            nzType="sync" nzTheme="outline"></span></button> -->

                                                    <div class="custom-circle-button"
                                                        (click)="setRefreshBlockDetails(index, block)" nz-popover
                                                        [nzPopoverContent]="contentTemplate"
                                                        nzPopoverPlacement="bottomRight" nzPopoverTrigger="click"
                                                        [(nzPopoverVisible)]="block.popoverVisible"
                                                        (nzPopoverVisibleChange)="popoverChange($event, block)"
                                                        style="margin-left: 6px;"><span nz-icon nzType="sync"
                                                            nzTheme="outline"></span></div>
                                                    <ng-template #contentTemplate>
                                                        <div
                                                            style="margin: -12px -16px; overflow: hidden; border-radius: 10px; width: 500px;">
                                                            <div class="custom-modal-header">
                                                                Regenerate content
                                                            </div>
                                                            <div class="custom-modal-body">
                                                                <nz-form-control nzSpan="24">
                                                                    <textarea [(ngModel)]="blockFeedback" nz-input
                                                                        placeholder="Ex: Make the copy more fun"
                                                                        style="height: 60px;"></textarea>
                                                                </nz-form-control>
                                                                <div class="custom-card"
                                                                    style="margin-top: 8px; margin-bottom: 0px;"
                                                                    *ngIf="this.showRefreshPreview || this.blockRefreshLoading">
                                                                    <div class="custom-card-body"
                                                                        style="padding-bottom: 8px; max-height: 325px; overflow-y: auto;">
                                                                        <ng-container *ngIf="this.blockRefreshLoading">
                                                                            <!-- <div class="loading-wrapper">
                                                                                <div class="lds-ellipsis">
                                                                                    <div></div>
                                                                                    <div></div>
                                                                                    <div></div>
                                                                                    <div></div>
                                                                                </div>
                                                                            </div> -->
                                                                            <div class="loading-wrapper"
                                                                                style="text-align: center; padding: 50px !important;">
                                                                                <img src="../../assets/magicWand.gif"
                                                                                    style="width: 125px; height: 125px; margin: auto; margin-bottom: 16px;" />
                                                                                Generating content...
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="!this.blockRefreshLoading && this.newRefreshContentBlock">
                                                                            <ng-container
                                                                                *ngFor="let property of newRefreshContentBlock.properties">
                                                                                <ng-container
                                                                                    *ngIf="(property.type =='INPUT' || property.type == 'RICHTEXT') && property.name != 'Link'">
                                                                                    <nz-form-item>
                                                                                        <nz-form-label
                                                                                            [nzNoColon]="true"
                                                                                            nzLabelAlign="left"
                                                                                            nzSpan="24"
                                                                                            style="padding-bottom: 0px !important; min-height: 0px !important;">
                                                                                            {{property.name}}
                                                                                        </nz-form-label>
                                                                                        <nz-form-control nzSpan="24">
                                                                                            {{property.value}}
                                                                                        </nz-form-control>
                                                                                    </nz-form-item>
                                                                                </ng-container>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="custom-modal-footer">
                                                                <div style="text-align: right">
                                                                    <ng-container *ngIf="!this.showRefreshPreview">
                                                                        <button nzType="primary" nz-button
                                                                            (click)="refreshBlock(refreshBlockIndex, blockFeedback, refreshBlockObject)"
                                                                            [disabled]="this.blockRefreshLoading">Send</button>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="this.showRefreshPreview">
                                                                        <button nz-button
                                                                            (click)="refreshBlock(refreshBlockIndex, blockFeedback, refreshBlockObject)">Generate
                                                                            again</button>&nbsp;
                                                                        <button nzType="primary" nz-button
                                                                            (click)="keepNewContent(refreshBlockIndex, block)">Keep</button>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                            <!-- <nz-form-item
                                                                style="width: 300px; margin-bottom: 8px !important;">
                                                                <nz-form-label [nzNoColon]="true" nzLabelAlign="left"
                                                                    nzSpan="24">
                                                                    Feedback
                                                                </nz-form-label>   
                                                            </nz-form-item> -->

                                                        </div>
                                                    </ng-template>
                                                </ng-container>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <ng-container *ngIf="block.type == 'HERO'">
                    <div class="email-hero-block" [style.backgroundColor]="block.backgroundColor">
                        <div class="email-logo-image-wrapper">
                            <img class="email-logo-image" src="{{block.logoImage}}" />
                        </div>

                    </div>
                </ng-container> -->

                            </ng-container>
                        </div>
                    </div>
                </div>

            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="testing123">


        <div class="left-column" cdkScrollable>

            <ng-container *ngIf="this.sharedService.rendererOpportunityObject.stage == 'NEW'">
                <div style="max-width: 600px; margin: auto; margin-top: 24px; text-align: center;">
                    <div class="meter purple">
                        <span style="width: 100%"></span>
                    </div>
                    <h3>Copilot is generating a proposal.</h3>
                    <h3>This may take a moment.</h3>
                </div>
            </ng-container>

            <ng-container *ngIf="this.sharedService.rendererOpportunityObject.stage != 'NEW'">
                <div class="email-background-wrapper" id="email-background-wrapper"
                    [style.backgroundColor]="this.sharedService.rendererOpportunityObject.contentTemplate.backgroundColor"
                    [style.pointer-events]="mode === 'VIEW' ? 'none' : 'auto'">

                    <div class="email-content-wrapper" [style.max-width]="mode === 'VIEW' ? '600px' : '900px'"
                        [style.backgroundColor]="this.sharedService.rendererOpportunityObject.contentTemplate.backgroundColor">
                        <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                            <!-- <div class="example-box" *ngFor="let movie of movies" cdkDrag>{{movie}}</div> -->
                            <ng-container
                                *ngFor="let block of this.sharedService.rendererOpportunityObject.contentTemplate.blocks; let index=index">
                                <div class="email-block-outer" cdkDrag>
                                    <div nz-row>
                                        <div nz-col nzFlex="75px">
                                            <div class="email-block-controls">
                                                <!-- <button nzShape="circle" nz-button nzType="default"
                                                    (click)="moveUp(block, index)"><span nz-icon nzType="up"
                                                        nzTheme="outline"></span></button><br>
                                                <button nz-button nzType="default" nzShape="circle"
                                                    (click)="moveDown(block, index)"><span nz-icon
                                                        nzType="down"></span></button> -->
                                                <!-- <button nz-button nzType="default" nzShape="circle"><span nz-icon nzType="drag" nzTheme="outline"></span></button> -->
                                                <button nzShape="circle" nz-button nzType="default"
                                                    (click)="removeItem(index)"
                                                    style="transition: none !important;"><span nz-icon nzType="delete"
                                                        nzTheme="outline"></span></button>
                                            </div>
                                        </div>
                                        <div nz-col nzFlex="600px">

                                            <ng-container *ngIf="block.loading">
                                                <div class="loading-wrapper">
                                                    <div class="lds-ellipsis">
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                            </ng-container>

                                            <ng-container *ngIf="!block.loading">


                                                <ng-container *ngIf="block.type == 'LOGO'">


                                                    <div id="{{'block-' + index}}" class="email-block-wrapper padding24"
                                                        (click)="selectBlock(block)"
                                                        [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                        <div class="email-logo-image-wrapper">
                                                            <img class="email-logo-image"
                                                                [style.width]="getPropertyValue(block, 'Width') + 'px'"
                                                                src="{{block.properties[0].value}}" />
                                                        </div>

                                                    </div>

                                                    <div *ngIf="block.showPalette" class="block-palette">
                                                        <div nz-row [nzGutter]="16">
                                                            <ng-container *ngFor="let blockType of blockLibrary">
                                                                <div nz-col [nzSpan]="6">
                                                                    <div class="block-palette-block"
                                                                        (click)="addBlock(blockType, block)">
                                                                        <div class="block-palette-icon">
                                                                            <span nz-icon nzType="{{blockType.icon}}"
                                                                                nzTheme="outline"></span>
                                                                        </div>
                                                                        {{blockType.title}}
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'TITLE'">
                                                    <div id="{{'block-' + index}}" class="email-block-wrapper padding32"
                                                        (click)="selectBlock(block)">
                                                        <div class="email-hero-title">{{block.properties[0].value}}
                                                        </div>
                                                        <!-- <div class="email-hero-subtitle">{{block.properties[1].value}}</div> -->
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'BUTTON'">

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding40"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <div class="email-button"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                [style.color]="getPropertyValue(block, 'Button text color')"
                                                                [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                {{block.properties[0].value}}</div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'OVERIMAGE'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)">
                                                            <div class="email-cta-background"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Image')+')'">
                                                                <div class="email-button"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Button text')}}</div>
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'PROMOTION'">

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding40"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <div class="email-promotion-title"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}</div>
                                                            <div class="email-promotion-subtitle"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontSize]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontSize + 'px'"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Body')}}</div>
                                                            <div class="email-promotion-code"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Promo background color')"
                                                                [style.color]="getPropertyValue(block, 'Promo text color')">
                                                                {{getPropertyValue(block, 'Promo code')}}</div>
                                                            <!-- <div class="email-button"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                            [style.fontFamily]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.bodyFont">
                                                            {{getPropertyValue(block, 'Button')}}</div> -->
                                                        </div>
                                                    </ng-container>

                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'IMAGEFULLWIDTH'">
                                                    <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                        (click)="selectBlock(block)">
                                                        <div class="email-hero-image"
                                                            [style.backgroundImage]="'url('+block.properties[0].value+')'">
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'IMAGE'">
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <img class="email-image"
                                                                src="{{block.properties[0].value}}">
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'FULLWIDTH'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <img class="email-image-fullwidth"
                                                                src="{{block.properties[0].value}}">
                                                        </div>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'BLURB'">
                                                    <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                        (click)="selectBlock(block)"
                                                        [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                        [style.color]="getPropertyValue(block, 'Text color')">
                                                        <div class="email-body-text">{{getPropertyValue(block, 'Text')}}
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <!-- <ng-container *ngIf="block.type == 'GALLERY'">
                                                <div id="{{'block-' + index}}" class="email-block-wrapper padding32"
                                                    [style.backgroundColor]="block.properties[5].value"
                                                    (click)="selectBlock(block)">
                                                    <div class="email-gallery-title">{{block.properties[4].value}}</div>
                                                    <table class="email-gallery-table">
                                                        <tr>
                                                            <td><img class="email-gallery-image"
                                                                    src="{{block.properties[0].value}}" />
                                                            </td>
                                                            <td><img class="email-gallery-image"
                                                                    src="{{block.properties[1].value}}" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><img class="email-gallery-image"
                                                                    src="{{block.properties[2].value}}" />
                                                            </td>
                                                            <td><img class="email-gallery-image"
                                                                    src="{{block.properties[3].value}}" />
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </ng-container> -->

                                                <ng-container *ngIf="block.type == 'REVIEW'">
                                                    <ng-container *ngIf="!block.version">
                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                            <div id="{{'block-' + index}}"
                                                                class="email-block-wrapper padding32"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                (click)="selectBlock(block)">
                                                                <div class="email-review-wrapper ">
                                                                    <div class="email-review-stars"
                                                                        [style.color]="getPropertyValue(block, 'Decoration color')">
                                                                        <span nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star" nzTheme="fill"></span>
                                                                    </div>
                                                                    <div class="email-review-title"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Title color')">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div>
                                                                    <div class="email-review-text"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Text color')">
                                                                        "{{getPropertyValue(block, 'Quote')}}"
                                                                    </div>
                                                                    <div class="email-review-name"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Name color')">
                                                                        -
                                                                        {{getPropertyValue(block, 'Name')}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'BUBBLE'">
                                                            <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                                (click)="selectBlock(block)"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                <div class="email-review-wrapper bubble"
                                                                    [style.borderColor]="getPropertyValue(block, 'Text color')">
                                                                    <div class="email-review-stars"
                                                                        [style.color]="getPropertyValue(block, 'Decoration color')">
                                                                        <span nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star" nzTheme="fill"></span>
                                                                    </div>
                                                                    <div class="email-review-title"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Title color')">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div>
                                                                    <div class="email-review-text"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Text color')">
                                                                        "{{getPropertyValue(block, 'Quote')}}"
                                                                    </div>
                                                                    <div class="email-review-name"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Name color')">
                                                                        -
                                                                        {{getPropertyValue(block, 'Name')}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'BIG'">
                                                            <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                                (click)="selectBlock(block)">
                                                                <div class="email-review-wrapper big"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <div class="email-review-title"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Title color')">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div>
                                                                    <div class="email-review-big-quote"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Name color')">
                                                                        “”
                                                                    </div>
                                                                    <div class="email-review-text big"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Text color')">
                                                                        "{{getPropertyValue(block, 'Quote')}}"
                                                                    </div>
                                                                    <div class="email-review-stars"
                                                                        [style.color]="getPropertyValue(block, 'Decoration color')">
                                                                        <span nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star" nzTheme="fill"></span>
                                                                    </div>
                                                                    <div class="email-review-name big"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Name color')">
                                                                        -
                                                                        {{getPropertyValue(block, 'Name')}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>

                                                    <ng-container *ngIf="block.version">
                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                            <div id="{{'block-' + index}}"
                                                                class="email-block-wrapper padding32"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                (click)="selectBlock(block)">
                                                                <div class="email-review-wrapper ">
                                                                    <div class="email-review-stars"
                                                                        *ngIf="getPropertyValue(block, 'Icons')"
                                                                        [style.color]="getPropertyValue(getArrayItem(block.properties, 'Icons'), 'Icon color')">
                                                                        <span nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star" nzTheme="fill"></span>
                                                                    </div>
                                                                    <div class="email-review-title"
                                                                        *ngIf="getPropertyValue(block, 'Title')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(getArrayItem(block.properties, 'Title'), 'Text color')">
                                                                        {{getPropertyValue(getArrayItem(block.properties,
                                                                        'Title'), 'Text')}}
                                                                    </div>
                                                                    <div class="email-review-text"
                                                                        *ngIf="getPropertyValue(block, 'Quote')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"
                                                                        [style.color]="getPropertyValue(getArrayItem(block.properties, 'Quote'), 'Text color')">
                                                                        "{{getPropertyValue(getArrayItem(block.properties,
                                                                        'Quote'), 'Text')}}"
                                                                    </div>
                                                                    <div class="email-review-name"
                                                                        *ngIf="getPropertyValue(block, 'Name')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(getArrayItem(block.properties, 'Name'), 'Text color')">
                                                                        -
                                                                        {{getPropertyValue(getArrayItem(block.properties,
                                                                        'Name'), 'Text')}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'BUBBLE'">
                                                            <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                                (click)="selectBlock(block)"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                <div class="email-review-wrapper bubble"
                                                                    [style.borderColor]="getPropertyValue(block, 'Text color')">
                                                                    <div class="email-review-stars"
                                                                        [style.color]="getPropertyValue(block, 'Decoration color')">
                                                                        <span nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star" nzTheme="fill"></span>
                                                                    </div>
                                                                    <div class="email-review-title"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Title color')">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div>
                                                                    <div class="email-review-text"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Text color')">
                                                                        "{{getPropertyValue(block, 'Quote')}}"
                                                                    </div>
                                                                    <div class="email-review-name"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Name color')">
                                                                        -
                                                                        {{getPropertyValue(block, 'Name')}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'BIG'">
                                                            <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                                (click)="selectBlock(block)">
                                                                <div class="email-review-wrapper big"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <div class="email-review-title"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Title color')">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div>
                                                                    <div class="email-review-big-quote"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Name color')">
                                                                        “”
                                                                    </div>
                                                                    <div class="email-review-text big"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Text color')">
                                                                        "{{getPropertyValue(block, 'Quote')}}"
                                                                    </div>
                                                                    <div class="email-review-stars"
                                                                        [style.color]="getPropertyValue(block, 'Decoration color')">
                                                                        <span nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star"
                                                                            nzTheme="fill"></span><span nz-icon
                                                                            nzType="star" nzTheme="fill"></span><span
                                                                            nz-icon nzType="star" nzTheme="fill"></span>
                                                                    </div>
                                                                    <div class="email-review-name big"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight"
                                                                        [style.color]="getPropertyValue(block, 'Name color')">
                                                                        -
                                                                        {{getPropertyValue(block, 'Name')}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'HERO'">
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)">
                                                            <!-- <div class="on-block-hover">New block</div> -->
                                                            <div class="email-hero-background"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                                <div class="email-hero-opacity"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Image overlay')">
                                                                    <div class="email-hero-title"
                                                                        [style.color]="getPropertyValue(block, 'Title color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div>
                                                                    <div class="email-hero-subtitle middleimage"
                                                                        *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                        [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                        {{getPropertyValue(block, 'Subtitle')}}
                                                                    </div>
                                                                    <!-- <div class="email-hero-body"
                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                    [style.fontFamily]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                    {{getPropertyValue(block, 'Body')}}
                                                                </div> -->
                                                                    <div class="email-hero-button-outer"
                                                                        *ngIf="getPropertyValue(block, 'Button')">
                                                                        <div class="email-hero-button"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                            {{getPropertyValue(block, 'Button')}}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'SEPARATEIMAGE'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <!-- <div class="email-hero-background"
                                                            [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                        </div> -->
                                                            <img [src]="getPropertyValue(block, 'Background image')"
                                                                *ngIf="getPropertyValue(block, 'Background image')"
                                                                style="width: 100%;" />
                                                            <div class="email-hero-title"
                                                                [style.color]="getPropertyValue(block, 'Title color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{block.properties[0].value}}
                                                            </div>
                                                            <div class="email-hero-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{block.properties[1].value}}
                                                            </div>
                                                            <div class="email-hero-button-outer"
                                                                *ngIf="getPropertyValue(block, 'Button')">
                                                                <div class="email-hero-button"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Button')}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'MIDDLEIMAGE'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <div class="email-hero-title"
                                                                [style.color]="getPropertyValue(block, 'Title color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{block.properties[0].value}}
                                                            </div>
                                                            <div class="email-hero-subtitle middleimage"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{block.properties[1].value}}
                                                            </div>
                                                            <div class="email-hero-background" style="height: 350px"
                                                                *ngIf="getPropertyValue(block, 'Background image')"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                            </div>
                                                            <div class="email-hero-button-outer"
                                                                *ngIf="getPropertyValue(block, 'Button')">
                                                                <div class="email-hero-button"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Button')}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'CONTAINEDIMAGESQUARE'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <div class="email-hero-title"
                                                                [style.color]="getPropertyValue(block, 'Title color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{block.properties[0].value}}
                                                            </div>
                                                            <div class="email-hero-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{block.properties[1].value}}
                                                            </div>
                                                            <div class="email-hero-button-outer"
                                                                *ngIf="getPropertyValue(block, 'Button')">

                                                                <div class="email-hero-button"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Button')}}

                                                                </div>
                                                            </div>
                                                            <div class="email-hero-background contained-image-square"
                                                                *ngIf="getPropertyValue(block, 'Background image')"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'CONTAINEDIMAGEROUNDED'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <div class="email-hero-title"
                                                                [style.color]="getPropertyValue(block, 'Title color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{block.properties[0].value}}
                                                            </div>
                                                            <div class="email-hero-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{block.properties[1].value}}
                                                            </div>
                                                            <div class="email-hero-button-outer"
                                                                *ngIf="getPropertyValue(block, 'Button')">

                                                                <div class="email-hero-button"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Button')}}
                                                                </div>
                                                            </div>
                                                            <div class="email-hero-background contained-image-rounded"
                                                                *ngIf="getPropertyValue(block, 'Background image')"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                            </div>

                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'CONTAINEDIMAGECIRCLE'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <div class="email-hero-title"
                                                                [style.color]="getPropertyValue(block, 'Title color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{block.properties[0].value}}
                                                            </div>
                                                            <div class="email-hero-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{block.properties[1].value}}
                                                            </div>
                                                            <div class="email-hero-button-outer"
                                                                *ngIf="getPropertyValue(block, 'Button')">

                                                                <div class="email-hero-button"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Button')}}
                                                                </div>
                                                            </div>
                                                            <div class="email-hero-background contained-image-circle"
                                                                *ngIf="getPropertyValue(block, 'Background image')"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                            </div>

                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'TITLEONIMAGE'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <!-- <div class="on-block-hover">New block</div> -->
                                                            <div class="email-hero-background"
                                                                [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                                <div class="email-hero-opacity">
                                                                    <div class="email-hero-title"
                                                                        style="padding-bottom: 40px;"
                                                                        [style.color]="getPropertyValue(block, 'Title color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="email-hero-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div>
                                                            <!-- <div class="email-hero-body"
                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                    [style.fontFamily]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                    {{getPropertyValue(block, 'Body')}}
                                                                </div> -->
                                                            <div class="email-hero-button-outer"
                                                                *ngIf="getPropertyValue(block, 'Button')">

                                                                <div class="email-hero-button"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Button')}}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'CATEGORY'">
                                                    <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                        [style.backgroundColor]="block.properties[3].value"
                                                        (click)="selectBlock(block)">
                                                        <table class="email-category-table">
                                                            <tr>
                                                                <ng-container *ngIf="block.layout == 'RIGHT'">
                                                                    <td style="width: 50%; text-align: center; ">
                                                                        <div class="email-category-title">
                                                                            {{block.properties[1].value}}</div>
                                                                        <div class="email-category-button"
                                                                            [style.background]="this.sharedService.rendererOpportunityObject.contentTemplate.buttonColor">
                                                                            {{block.properties[2].value}}</div>
                                                                    </td>
                                                                    <td style="width: 50%"><img
                                                                            class="email-gallery-image"
                                                                            src="{{block.properties[0].value}}" /></td>
                                                                </ng-container>
                                                                <ng-container *ngIf="block.layout != 'RIGHT'">
                                                                    <td style="width: 50%"><img
                                                                            class="email-gallery-image"
                                                                            src="{{block.properties[0].value}}" /></td>
                                                                    <td style="width: 50%; text-align: center;">
                                                                        <div class="email-category-title">
                                                                            {{block.properties[1].value}}</div>
                                                                        <div class="email-category-button"
                                                                            [style.background]="this.sharedService.rendererOpportunityObject.contentTemplate.buttonColor">
                                                                            {{getPropertyValue(block, 'Button')"}}
                                                                        </div>
                                                                    </td>
                                                                </ng-container>

                                                            </tr>
                                                        </table>

                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'FOOTER'">
                                                    <div id="{{'block-' + index}}" class="email-block-wrapper padding24"
                                                        [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                        (click)="selectBlock(block)">
                                                        <div class="email-logo-image-wrapper" id="footer-logo">
                                                            <img class="email-logo-image"
                                                                src="{{block.properties[0].value}}" />
                                                        </div>
                                                        <div class="social-links-wrapper" id="footer-social-links"
                                                            *ngIf="getArrayItem(block.properties, 'Social links').value.length > 0">
                                                            <ng-container
                                                                *ngFor="let item of getArrayItem(block.properties, 'Social links').value; let itemIndex=index">
                                                                <div id="{{'block-' + index + '-' + itemIndex}}"
                                                                    [style.color]="getPropertyValue(block, 'Social icon color')"
                                                                    class="{{getArrayItem(item, 'Platform').value.value}}">
                                                                </div>
                                                            </ng-container>

                                                            <!-- <svg style="display: inline-block; margin-right: 16px; font-size: 24px;"
                                                            xmlns="http://www.w3.org/2000/svg" height="1em"
                                                            viewBox="0 0 512 512">
                                                            <path
                                                                d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
                                                        </svg>
                                                        <svg style="display: inline-block; font-size: 24px;"
                                                            xmlns="http://www.w3.org/2000/svg" height="1em"
                                                            viewBox="0 0 448 512">
                                                            <path
                                                                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                                                        </svg> -->
                                                        </div>
                                                        <div class="email-disclaimer-wrapper" id="footer-disclaimer"
                                                            *ngIf="getPropertyValue(block, 'Disclaimer')"
                                                            [style.color]="getPropertyValue(block, 'Disclaimer text color')">
                                                            {{getPropertyValue(block, 'Disclaimer')}}
                                                        </div>
                                                        <!-- <div class="email-unsubscribe-wrapper" id="footer-unsubscribe"
                                                        [style.color]="getPropertyValue(block, 'Disclaimer text color')">
                                                        {{getPropertyValue(block, 'Unsubscribe text')}} Unsubscribe here
                                                    </div> -->
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'NAVBAR'">
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <table class="email-category-table"
                                                                style="width: 100%; font-weight: 600; table-layout: fixed;">
                                                                <tr>
                                                                    <ng-container
                                                                        *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">
                                                                        <td style="text-align: center; padding: 16px 0px;"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            id="{{'block-' + index + '-' + itemIndex}}"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                            {{getArrayItem(item, 'Title').value}}
                                                                        </td>
                                                                    </ng-container>

                                                                    <!-- <td style="width: 25%; text-align: center;"
                                                                    [style.color]="getPropertyValue(block, 'Text color')">
                                                                    {{block.properties[1].value}}
                                                                </td>
                                                                <td style="width: 25%; text-align: center;"
                                                                    [style.color]="getPropertyValue(block, 'Text color')">
                                                                    {{block.properties[2].value}}
                                                                </td>
                                                                <td style="width: 25%; text-align: center;"
                                                                    [style.color]="getPropertyValue(block, 'Text color')">
                                                                    {{block.properties[3].value}}
                                                                </td> -->
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'GRID'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding32"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <table class="email-category-table"
                                                                style="width: 100%; font-weight: 600; margin: 0px 0px 0px;">
                                                                <ng-container
                                                                    *ngIf="getArrayItem(block.properties, 'Items').value[0] && getArrayItem(block.properties, 'Items').value[1] && getArrayItem(block.properties, 'Items').value[2] && getArrayItem(block.properties, 'Items').value[3]"></ng-container>
                                                                <tr>
                                                                    <td id="{{'block-' + index + '-0'}}"
                                                                        style="width: 50%; text-align: center; padding: 32px; border-bottom: 1px solid #d9d9d9; border-right: 1px solid #d9d9d9"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                        {{getArrayItem(getArrayItem(block.properties,
                                                                        'Items').value[0], 'Title').value}}
                                                                    </td>
                                                                    <td id="{{'block-' + index + '-1'}}"
                                                                        style="width: 50%; text-align: center; padding: 32px; border-bottom: 1px solid #d9d9d9"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                        {{getArrayItem(getArrayItem(block.properties,
                                                                        'Items').value[1], 'Title').value}}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="{{'block-' + index + '-2'}}"
                                                                        style="width: 50%; text-align: center; padding: 32px; border-right: 1px solid #d9d9d9"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                        {{getArrayItem(getArrayItem(block.properties,
                                                                        'Items').value[2], 'Title').value}}
                                                                    </td>
                                                                    <td id="{{'block-' + index + '-3'}}"
                                                                        style="width: 50%; text-align: center; padding: 32px;"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                        {{getArrayItem(getArrayItem(block.properties,
                                                                        'Items').value[3], 'Title').value}}
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'VERTICAL'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding16"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <table class="email-category-table"
                                                                style="width: 100%; font-weight: 600; margin: 0px 0px 0px;">
                                                                <ng-container
                                                                    *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">
                                                                    <tr>
                                                                        <td id="{{'block-' + index + '-' + itemIndex}}"
                                                                            style="text-align: center; padding: 16px; border-bottom: 1px solid #d9d9d9;"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                            {{getArrayItem(item, 'Title').value}}
                                                                        </td>
                                                                    </tr>
                                                                </ng-container>


                                                            </table>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'LISTICLE'">
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD' || getPropertyValue(block, 'Layout').value == 'ALTERNATING'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding40"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <div class="email-article-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                [style.color]="getPropertyValue(block, 'List title text color')"
                                                                id="{{'list-title-' + index}}"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div>
                                                            <ng-container
                                                                *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">
                                                                <!-- <div class="email-listicle-item"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Card color')"> -->
                                                                <div class="email-listicle-item"
                                                                    id="{{'block-' + index + '-' + itemIndex}}">
                                                                    <div nz-row>
                                                                        <div nz-col [nzSpan]="4">
                                                                            <div class="sticker"
                                                                                [style.backgroundColor]="getPropertyValue(block, 'Sticker color')">
                                                                                {{itemIndex+1}}</div>
                                                                        </div>
                                                                        <div nz-col [nzSpan]="20">

                                                                            <div class="email-listicle-title"
                                                                                [style.color]="getPropertyValue(block, 'Title text color')"
                                                                                [style.fontFamily]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.titleFont">
                                                                                {{getArrayItem(item, 'Title').value}}
                                                                            </div>
                                                                            <div class="email-listicle-body"
                                                                                [style.color]="getPropertyValue(block, 'Body text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                {{getArrayItem(item, 'Body').value}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'ALTERNATING2'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding16"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <div class="email-article-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                [style.color]="getPropertyValue(block, 'List title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div>
                                                            <ng-container
                                                                *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">

                                                                <ng-container *ngIf="!isEven(itemIndex)">
                                                                    <div class="email-listicle-item right"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Card color')">
                                                                        <div class="email-listicle-title"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.titleFont">
                                                                            {{getArrayItem(item, 'Title').value}}
                                                                        </div>
                                                                        <div class="email-listicle-body"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                            {{getArrayItem(item, 'Body').value}}
                                                                        </div>
                                                                    </div>
                                                                </ng-container>

                                                                <ng-container *ngIf="isEven(itemIndex)">
                                                                    <div class="email-listicle-item left"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Card color')">
                                                                        <div class="email-listicle-title"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.titleFont">
                                                                            {{getArrayItem(item, 'Title').value}}
                                                                        </div>
                                                                        <div class="email-listicle-body"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                            {{getArrayItem(item, 'Body').value}}
                                                                        </div>
                                                                    </div>
                                                                </ng-container>

                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'ARTICLES'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding40"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <div class="email-article-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                [style.color]="getPropertyValue(block, 'List title text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div>
                                                            <ng-container
                                                                *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">
                                                                <!-- <div class="email-listicle-item"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Card color')"> -->
                                                                <div class="email-listicle-item-article"
                                                                    id="{{'block-' + index + '-' + itemIndex}}"
                                                                    [style.borderRadius]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.elementBorderRadius">
                                                                    <div class="email-article-image"
                                                                        [style.background-image]="'url('+getArrayItem(item, 'Image').value+')'">
                                                                    </div>
                                                                    <div class="email-listicle-title"
                                                                        [style.color]="getPropertyValue(block, 'Title text color')"
                                                                        [style.fontFamily]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.titleFont">
                                                                        {{getArrayItem(item, 'Title').value}}
                                                                    </div>
                                                                    <div class="email-listicle-body"
                                                                        [style.color]="getPropertyValue(block, 'Body text color')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                        {{getArrayItem(item, 'Body').value}}
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'ARTICLE'">
                                                    <ng-container *ngIf="block.version">
                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                            <div id="{{'block-' + index}}"
                                                                class="email-block-wrapper padding40"
                                                                style="padding-bottom: 0px !important"
                                                                (click)="selectBlock(block)"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                <div class="email-article-title"
                                                                    *ngIf="getPropertyValue(block, 'Title')"
                                                                    [style.color]="getPropertyValue(getArrayItem(block.properties, 'Title'), 'Text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                    {{getPropertyValue(getArrayItem(block.properties,
                                                                    'Title'), 'Text')}}
                                                                </div>
                                                                <div class="email-article-subtitle"
                                                                    *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                    [style.color]="getPropertyValue(getArrayItem(block.properties, 'Subtitle'), 'Text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                    {{getPropertyValue(getArrayItem(block.properties,
                                                                    'Subtitle'), 'Text')}}
                                                                </div>
                                                                <div class="email-article-image contained-image-rounded"
                                                                    *ngIf="getPropertyValue(block, 'Image')"
                                                                    [style.background-image]="'url('+getPropertyValue(getArrayItem(block.properties, 'Image'), 'Image')+')'">
                                                                </div>
                                                                <div class="email-article-body"
                                                                    *ngIf="getPropertyValue(block, 'Body')"
                                                                    [style.color]="getPropertyValue(getArrayItem(block.properties, 'Body'), 'Text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                    {{getPropertyValue(getArrayItem(block.properties,
                                                                    'Body'), 'Text')}}
                                                                </div>
                                                                <div class="email-article-button-outer"
                                                                    *ngIf="getPropertyValue(block, 'Button')">

                                                                    <div class="email-hero-button"
                                                                        [style.backgroundColor]="getPropertyValue(getArrayItem(block.properties, 'Button'), 'Background color')"
                                                                        [style.color]="getPropertyValue(getArrayItem(block.properties, 'Button'), 'Text color')"
                                                                        [style.borderRadius]="getPropertyValue(getArrayItem(block.properties, 'Button'), 'Button shape')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                        {{getPropertyValue(getArrayItem(block.properties,
                                                                        'Button'), 'Text')}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container *ngIf="!block.version">
                                                        <ng-container
                                                            *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                            <div id="{{'block-' + index}}"
                                                                class="email-block-wrapper padding40"
                                                                (click)="selectBlock(block)"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                style="padding-bottom: 0px !important">
                                                                <div class="email-article-title"
                                                                    *ngIf="getPropertyValue(block, 'Title')"
                                                                    [style.color]="getPropertyValue(block, 'Title color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                    {{getPropertyValue(block, 'Title')}}
                                                                </div>
                                                                <div class="email-article-subtitle"
                                                                    *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                    [style.color]="getPropertyValue(block, 'Subtitle color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                    {{getPropertyValue(block, 'Subtitle')}}
                                                                </div>
                                                                <div class="email-article-image contained-image-rounded"
                                                                    *ngIf="getPropertyValue(block, 'Image')"
                                                                    [style.background-image]="'url('+getPropertyValue(block, 'Image')+')'">
                                                                </div>
                                                                <div class="email-article-body"
                                                                    *ngIf="getPropertyValue(block, 'Body')"
                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                    {{getPropertyValue(block, 'Body')}}
                                                                </div>
                                                                <div class="email-article-button-outer"
                                                                    *ngIf="getPropertyValue(block, 'Button text')">

                                                                    <div class="email-hero-button"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                        [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                        [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                        {{getPropertyValue(block, 'Button text')}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'FEATURES'">
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding32"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                            <table class="email-category-table"
                                                                style="width: 100%; font-weight: 600; table-layout: fixed;">
                                                                <tr>
                                                                    <ng-container
                                                                        *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">
                                                                        <td style="text-align: center; padding: 16px 0px;"
                                                                            id="{{'block-' + index + '-' + itemIndex}}"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                            <div class="email-features-icon"
                                                                                [style.color]="getPropertyValue(block, 'Icon color')">
                                                                                <i
                                                                                    class="{{getArrayItem(item, 'Icon').value}}"></i>
                                                                            </div>
                                                                            <div class="email-features-label"
                                                                                [style.color]="getPropertyValue(block, 'Text color')">
                                                                                {{getArrayItem(item, 'Label').value}}
                                                                            </div>

                                                                        </td>
                                                                    </ng-container>
                                                                </tr>
                                                            </table>
                                                            <!-- <table class="email-category-table"
                                                                style="width: 100%; font-weight: 600; margin: 0px 0px 16px;">
                                                                <tr>
                                                                    <td style="width: 25%; text-align: center;"
                                                                        [style.color]="getPropertyValue(block, 'Icon color')">
                                                                        <div class="email-features-icon"><span nz-icon
                                                                                [nzType]="getPropertyValue(block, 'Icon 1')"
                                                                                nzTheme="outline"></span></div>
                                                                    </td>
                                                                    <td style="width: 25%; text-align: center;"
                                                                        [style.color]="getPropertyValue(block, 'Icon color')">
                                                                        <div class="email-features-icon"><span nz-icon
                                                                                [nzType]="getPropertyValue(block, 'Icon 2')"
                                                                                nzTheme="outline"></span></div>
                                                                    </td>
                                                                    <td style="width: 25%; text-align: center;"
                                                                        [style.color]="getPropertyValue(block, 'Icon color')">
                                                                        <div class="email-features-icon"><span nz-icon
                                                                                [nzType]="getPropertyValue(block, 'Icon 3')"
                                                                                nzTheme="outline"></span></div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="width: 25%; text-align: center; vertical-align: top;"
                                                                        [style.color]="getPropertyValue(block, 'Text color')">
                                                                        <div class="email-features-label">
                                                                            {{getPropertyValue(block,
                                                                            'Text
                                                                            1')}}</div>
                                                                    </td>
                                                                    <td style="width: 25%; text-align: center; vertical-align: top;"
                                                                        [style.color]="getPropertyValue(block, 'Text color')">
                                                                        <div class="email-features-label">
                                                                            {{getPropertyValue(block,
                                                                            'Text
                                                                            2')}}</div>
                                                                    </td>
                                                                    <td style="width: 25%; text-align: center; vertical-align: top;"
                                                                        [style.color]="getPropertyValue(block, 'Text color')">
                                                                        <div class="email-features-label">
                                                                            {{getPropertyValue(block,
                                                                            'Text
                                                                            3')}}</div>
                                                                    </td>
                                                                </tr>
                                                            </table> -->
                                                        </div>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'PRODUCT'">
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div>
                                                            <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div>
                                                            <div class="email-product-table" nzJustify="center">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                    <div id="{{'block-' + index + '-' + itemIndex}}"
                                                                        class="email-product-item-small"
                                                                        style="text-align: center">
                                                                        <img class="email-product-image-small"
                                                                            src="{{item.imageUrl}}" />
                                                                        <div class="email-product-title-small"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                            {{item.title}}</div>
                                                                        <div class="email-product-title-small"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                            <span
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                class="compare-at-price">${{item.compareAtPrice}}</span>${{item.price}}
                                                                        </div>
                                                                        <div class="email-product-description-small"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                            {{item.description}}
                                                                        </div>
                                                                        <div class="email-product-button-small"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                            {{getPropertyValue(block, 'Button text')}}
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getPropertyValue(block, 'Bottom button text')">
                                                                <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARDLARGE'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div>
                                                            <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div>
                                                            <div class="email-product-table" nzJustify="center">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                    <div id="{{'block-' + index + '-' + itemIndex}}"
                                                                        class="email-product-item"
                                                                        style="text-align: center">
                                                                        <img class="email-product-image-large"
                                                                            src="{{item.imageUrl}}" />
                                                                        <div class="email-category-title"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                            {{item.title}}</div>
                                                                        <div class="email-category-title"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                            <span
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                class="compare-at-price">${{item.compareAtPrice}}</span>${{item.price}}
                                                                        </div>
                                                                        <div class="email-product-description-small"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                            {{item.description}}
                                                                        </div>
                                                                        <div class="email-product-button-large"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                            {{getPropertyValue(block, 'Button text')}}
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getPropertyValue(block, 'Bottom button text')">
                                                                <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARDXL'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div>
                                                            <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div>
                                                            <div class="email-product-table" nzJustify="center">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                    <div id="{{'block-' + index + '-' + itemIndex}}"
                                                                        class="email-product-item-xl"
                                                                        style="text-align: center">
                                                                        <img class="email-product-image-large"
                                                                            src="{{item.imageUrl}}" />
                                                                        <div class="email-product-title-xl"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                            {{item.title}}</div>
                                                                        <div class="email-category-title"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                            <span
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                class="compare-at-price">${{item.compareAtPrice}}</span>${{item.price}}
                                                                        </div>
                                                                        <div class="email-product-description"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                            {{item.description}}</div>
                                                                        <div class="email-product-button-large"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                            {{getPropertyValue(block, 'Button text')}}
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getPropertyValue(block, 'Bottom button text')">
                                                                <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'HORIZONTALBLOCK'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div>
                                                            <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div>
                                                            <table class="email-category-table">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                    <tr id="{{'block-' + index + '-' + itemIndex}}">

                                                                        <td style="width: 50%"><img
                                                                                [src]="item.imageUrl" />
                                                                        </td>
                                                                        <td
                                                                            style="width: 50%; text-align: center; padding: 24px 0px;">
                                                                            <div class="email-category-title"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                                {{item.title}}</div>
                                                                            <div class="email-category-title"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                <span
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                    class="compare-at-price">${{item.compareAtPrice}}</span>${{item.price}}
                                                                            </div>
                                                                            <div class="email-product-description-small"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                {{item.description}}</div>
                                                                            <div class="email-product-button-small"
                                                                                [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                                {{getPropertyValue(block, 'Button
                                                                                text')}}
                                                                            </div>
                                                                        </td>

                                                                    </tr>
                                                                </ng-container>
                                                            </table>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getPropertyValue(block, 'Bottom button text')">
                                                                <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'HORIZONTALBLOCKALTERNATING'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div>
                                                            <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div>
                                                            <table class="email-category-table">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                    <ng-container *ngIf="itemIndex % 2 == 0">
                                                                        <tr id="{{'block-' + index + '-' + itemIndex}}">

                                                                            <td style="width: 50%"><img
                                                                                    [src]="item.imageUrl" /></td>
                                                                            <td
                                                                                style="width: 50%; text-align: center; padding: 24px 0px;">
                                                                                <div class="email-category-title"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                                    {{item.title}}</div>
                                                                                <div class="email-category-title"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    <span
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                        class="compare-at-price">${{item.compareAtPrice}}</span>${{item.price}}
                                                                                </div>
                                                                                <div class="email-product-description-small"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    {{item.description}}</div>
                                                                                <div class="email-product-button-small"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                                    {{getPropertyValue(block, 'Button
                                                                                    text')}}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="itemIndex % 2 != 0">
                                                                        <tr id="{{'block-' + index + '-' + itemIndex}}">
                                                                            <td
                                                                                style="width: 50%; text-align: center; padding: 24px 0px;">
                                                                                <div class="email-category-title"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                                    {{item.title}}</div>
                                                                                <div class="email-category-title"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    <span
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                        class="compare-at-price">${{item.compareAtPrice}}</span>${{item.price}}
                                                                                </div>
                                                                                <div class="email-product-description-small"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    {{item.description}}</div>
                                                                                <div class="email-product-button-small"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                                    {{getPropertyValue(block, 'Button
                                                                                    text')}}
                                                                                </div>
                                                                            </td>
                                                                            <td style="width: 50%"><img
                                                                                    [src]="item.imageUrl" /></td>
                                                                        </tr>

                                                                    </ng-container>



                                                                </ng-container>
                                                            </table>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getPropertyValue(block, 'Bottom button text')">
                                                                <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'HORIZONTAL'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div>
                                                            <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div>
                                                            <table class="email-category-table">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                    <tr id="{{'block-' + index + '-' + itemIndex}}">

                                                                        <td style="width: 50%; padding: 32px;"><img
                                                                                class="email-gallery-image"
                                                                                [src]="item.imageUrl" /></td>
                                                                        <td
                                                                            style="width: 50%; text-align: center; padding: 16px">
                                                                            <div class="email-category-title"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                                {{item.title}}</div>
                                                                            <div class="email-category-title"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                <span
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                    class="compare-at-price">${{item.compareAtPrice}}</span>${{item.price}}
                                                                            </div>
                                                                            <div class="email-product-description-small"
                                                                                *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                {{item.description}}
                                                                            </div>
                                                                            <div class="email-product-button-small"
                                                                                [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                                {{getPropertyValue(block, 'Button
                                                                                text')}}
                                                                            </div>
                                                                        </td>

                                                                    </tr>
                                                                </ng-container>
                                                            </table>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getPropertyValue(block, 'Bottom button text')">
                                                                <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'HORIZONTALALTERNATING'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div>
                                                            <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div>
                                                            <table class="email-category-table">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                    <ng-container *ngIf="itemIndex % 2 == 0">
                                                                        <tr id="{{'block-' + index + '-' + itemIndex}}">

                                                                            <td style="width: 50%; padding: 32px 0px;">
                                                                                <img class="email-gallery-image"
                                                                                    [src]="item.imageUrl" />
                                                                            </td>
                                                                            <td
                                                                                style="width: 50%; text-align: center; padding: 16px">
                                                                                <div class="email-category-title"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                                    {{item.title}}</div>
                                                                                <div class="email-category-title"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    <span
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                        class="compare-at-price">${{item.compareAtPrice}}</span>${{item.price}}
                                                                                </div>
                                                                                <div class="email-product-description-small"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    {{item.description}}
                                                                                </div>
                                                                                <div class="email-product-button-small"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                                    {{getPropertyValue(block, 'Button
                                                                                    text')}}
                                                                                </div>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="itemIndex % 2 != 0">
                                                                        <tr id="{{'block-' + index + '-' + itemIndex}}">


                                                                            <td
                                                                                style="width: 50%; text-align: center; padding: 16px">
                                                                                <div class="email-category-title"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                                    {{item.title}}</div>
                                                                                <div class="email-category-title"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product price').value"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    <span
                                                                                        *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product compare at price').value"
                                                                                        class="compare-at-price">${{item.compareAtPrice}}</span>${{item.price}}
                                                                                </div>
                                                                                <div class="email-product-description-small"
                                                                                    *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Body').fontWeight">
                                                                                    {{item.description}}
                                                                                </div>
                                                                                <div class="email-product-button-small"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                                    {{getPropertyValue(block, 'Button
                                                                                    text')}}
                                                                                </div>
                                                                            </td>
                                                                            <td style="width: 50%; padding: 32px 0px;">
                                                                                <img class="email-gallery-image"
                                                                                    [src]="item.imageUrl" />
                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>



                                                                </ng-container>
                                                            </table>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getPropertyValue(block, 'Bottom button text')">
                                                                <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARDXL2IMAGES'">
                                                        <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                            (click)="selectBlock(block)">
                                                            <div class="email-product-block-title"
                                                                *ngIf="getPropertyValue(block, 'Title')"
                                                                id="{{'product-title-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                                {{getPropertyValue(block, 'Title')}}
                                                            </div>
                                                            <div class="email-article-subtitle"
                                                                *ngIf="getPropertyValue(block, 'Subtitle')"
                                                                id="{{'product-subtitle-' + index}}"
                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').font"
                                                                [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Subtitle').fontWeight">
                                                                {{getPropertyValue(block, 'Subtitle')}}
                                                            </div>
                                                            <div class="email-product-table" nzJustify="center">
                                                                <ng-container
                                                                    *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                    <div class="email-product-item-xl"
                                                                        style="text-align: center; max-width: unset; padding: 24px;"
                                                                        id="{{'block-' + index + '-' + itemIndex}}">
                                                                        <tr>
                                                                            <td style="width: 50%;"><img
                                                                                    class="email-product-image-large"
                                                                                    src="{{item.imageUrl}}" /></td>
                                                                            <td style="width: 50%;"><img
                                                                                    class="email-product-image-large"
                                                                                    src="{{item.imageUrl}}" /></td>
                                                                        </tr>

                                                                        <div class="email-product-title-xl"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight"
                                                                            style="text-align: center;">
                                                                            {{item.title}}</div>
                                                                        <div class="email-product-description"
                                                                            *ngIf="getArrayItem(getProperty(block, 'Components').items, 'Product descriptions').value"
                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                            [style.fontFamily]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                            {{item.description}}</div>
                                                                        <br>
                                                                        <div class="email-product-button-large"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                            {{getPropertyValue(block, 'Button text')}}
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                            <div class="email-product-bottom-button-wrapper"
                                                                id="{{'product-bottom-button-' + index}}"
                                                                *ngIf="getPropertyValue(block, 'Bottom button text')">
                                                                <div class="email-product-button-small"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                    [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').font"
                                                                    [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Button').fontWeight">
                                                                    {{getPropertyValue(block, 'Bottom button text')}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'BANNER'">

                                                    <div id="{{'block-' + index}}" class="email-block-wrapper padding16"
                                                        (click)="selectBlock(block)"
                                                        [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                        <div class="email-banner-wrapper"
                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                            [style.fontFamily]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').font"
                                                            [style.fontWeight]="getArrayItem(this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts, 'Title').fontWeight">
                                                            {{getPropertyValue(block, 'Text')}}
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="block.type == 'GALLERY'">
                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding24 horizontal"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">

                                                            <ng-container
                                                                *ngFor="let item of getArrayItem(block.properties, 'Images').value; let imageIndex=index">

                                                                <ng-container
                                                                    *ngIf="imageIndex == 0 || imageIndex == 1">
                                                                    <div class="email-gallery-image w50"><img
                                                                            class="email-gallery-image-img"
                                                                            src="{{getArrayItem(item, 'Image').value}}" />
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container *ngIf="imageIndex == 2">
                                                                    <div class="email-gallery-image w100"><img
                                                                            class="email-gallery-image-img"
                                                                            src="{{getArrayItem(item, 'Image').value}}" />
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="imageIndex == 3 || imageIndex == 4 || imageIndex == 5">
                                                                    <div class="email-gallery-image w33"><img
                                                                            class="email-gallery-image-img"
                                                                            src="{{getArrayItem(item, 'Image').value}}" />
                                                                    </div>
                                                                </ng-container>

                                                            </ng-container>

                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARDREVERSE'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding24 horizontal"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">

                                                            <ng-container
                                                                *ngFor="let item of getArrayItem(block.properties, 'Images').value; let imageIndex=index">

                                                                <ng-container
                                                                    *ngIf="imageIndex == 1 || imageIndex == 2">
                                                                    <div class="email-gallery-image w50"><img
                                                                            class="email-gallery-image-img"
                                                                            src="{{getArrayItem(item, 'Image').value}}" />
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container *ngIf="imageIndex == 0">
                                                                    <div class="email-gallery-image w100"><img
                                                                            class="email-gallery-image-img"
                                                                            src="{{getArrayItem(item, 'Image').value}}" />
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="imageIndex == 3 || imageIndex == 4 || imageIndex == 5">
                                                                    <div class="email-gallery-image w33"><img
                                                                            class="email-gallery-image-img"
                                                                            src="{{getArrayItem(item, 'Image').value}}" />
                                                                    </div>
                                                                </ng-container>

                                                            </ng-container>

                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="getPropertyValue(block, 'Layout').value == 'FIVE'">
                                                        <div id="{{'block-' + index}}"
                                                            class="email-block-wrapper padding24 horizontal"
                                                            (click)="selectBlock(block)"
                                                            [style.backgroundColor]="getPropertyValue(block, 'Background color')">

                                                            <ng-container
                                                                *ngFor="let item of getArrayItem(block.properties, 'Images').value; let imageIndex=index">

                                                                <ng-container
                                                                    *ngIf="imageIndex == 0 || imageIndex == 1">
                                                                    <div class="email-gallery-image w50"><img
                                                                            class="email-gallery-image-img"
                                                                            src="{{getArrayItem(item, 'Image').value}}" />
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="imageIndex == 2 || imageIndex == 3 || imageIndex == 4">
                                                                    <div class="email-gallery-image w33"><img
                                                                            class="email-gallery-image-img"
                                                                            src="{{getArrayItem(item, 'Image').value}}" />
                                                                    </div>
                                                                </ng-container>

                                                            </ng-container>

                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                                <button class="add-block-button" nz-button nzType="primary"
                                                    (click)="openBlockLibrary(index)" style=""><span nz-icon
                                                        nzType="plus" nzTheme="outline"></span></button>
                                            </ng-container>

                                        </div>
                                        <div nz-col nzFlex="75px">
                                            <div class="email-block-controls">
                                                <ng-container
                                                    *ngIf="block.type == 'HERO' || block.type == 'ARTICLE' || block.type == 'LISTICLE' || block.type == 'BANNER' || block.type == 'REVIEW'">
                                                    <button (click)="this.refreshBlockIndex = index" nzShape="circle"
                                                        nz-button nzType="default" nz-popover
                                                        [nzPopoverContent]="contentTemplate"
                                                        nzPopoverPlacement="bottomRight" nzPopoverTrigger="click"
                                                        style="transition: none !important;"
                                                        [(nzPopoverVisible)]="block.popoverVisible"><span nz-icon
                                                            nzType="sync" nzTheme="outline"></span></button>
                                                    <ng-template #contentTemplate>
                                                        <div>
                                                            <nz-form-item
                                                                style="width: 300px; margin-bottom: 8px !important;">
                                                                <nz-form-label [nzNoColon]="true" nzLabelAlign="left"
                                                                    nzSpan="24">
                                                                    Feedback
                                                                </nz-form-label>
                                                                <nz-form-control nzSpan="24">
                                                                    <textarea [(ngModel)]="blockFeedback" nz-input
                                                                        placeholder="Add your feedback here..."
                                                                        style="height: 100px;"></textarea>
                                                                </nz-form-control>
                                                            </nz-form-item>
                                                            <div style="text-align: right">
                                                                <!-- <button nzType="primary" nz-button
                                                                    (click)="refreshBlock(refreshBlockIndex, blockFeedback)">Send</button> -->
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <ng-template #blockLibraryTemplate>
        <div style="width: 300px;">
            <div nz-row [nzGutter]="[16, 16]">
                <ng-container *ngFor="let blockType of blockLibrary">
                    <div nz-col [nzSpan]="8">
                        <div class="block-palette-block" (click)="addBlock(blockType, 0)">
                            <div class="block-palette-icon">
                                <span nz-icon nzType="{{blockType.icon}}" nzTheme="outline"></span>
                            </div>
                            {{blockType.title}}
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-template>
</div>




<!-- <ng-container *ngIf="this.loading">
        <div class="content-column-left"></div>
        <div class="content-column-right">
            <div class="custom-card">
                <div class="custom-card-body">
                    <nz-skeleton [nzParagraph]="{ rows: 2 }" [nzActive]="true"></nz-skeleton>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="this.this.sharedService.rendererOpportunityObject.stage == 'NEW'">
        <div class="content-column-left">
            <nz-steps [nzCurrent]="0" nzDirection="vertical">
                <nz-step nzTitle="Generating proposal"></nz-step>
                <nz-step nzTitle="Approval"></nz-step>
                <nz-step nzTitle="Publishing"></nz-step>
                <nz-step nzTitle="Live"></nz-step>
            </nz-steps>
        </div>


        <div class="content-column-right">
            <div class="custom-card" style="text-align: center">
                <div class="custom-card-body">
                    <div class="meter purple">
                        <span style="width: 100%"></span>
                    </div>
                    <h3>Copilot is generating a proposal.</h3>
                    <h3>This may take a moment.</h3>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="this.this.sharedService.rendererOpportunityObject.stage == 'PENDING'">
        <div class="content-column-left">
            <nz-steps [nzCurrent]="1" nzDirection="vertical">
                <nz-step nzTitle="Generating proposal"></nz-step>
                <nz-step nzTitle="Approval"></nz-step>
                <nz-step nzTitle="Publishing"></nz-step>
                <nz-step nzTitle="Live"></nz-step>
            </nz-steps>
        </div>


        <div class="content-column-right">
            <div class="custom-card">
                <div class="custom-card-body">
                    <pre style="white-space: pre-wrap;">{{this.this.sharedService.rendererOpportunityObject.proposal}}</pre>
                </div>
            </div>
        </div>
    </ng-container> -->


<!-- <div class="middle-column">
        <div class="right-column-content">
            <div class="messages-wrapper">
                <ng-container *ngFor="let message of this.sharedService.rendererOpportunityObject.messages">
                    <ng-container *ngIf="message.sender">
                        <div class="message-row">
                            <div class="message-wrapper right">
                                <div class="message-header">
                                    <div class="message-title">{{message.sender}}</div>
                                    <div class="message-date">{{message.createdAt | date}}</div>
                                </div>
                                <div class="message-body">
                                    {{message.body}}
                                </div>
                            </div>
                        </div>

                    </ng-container>
                    <ng-container *ngIf="!message.sender">
                        <div class="message-row">
                            <div class="message-wrapper">
                                <div class="message-header">
                                    <div class="message-title">Copilot</div>
                                    <div class="message-date">11:30am 4/20/23</div>
                                </div>
                                <div class="message-body">
                                    Test
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <div class="textbox-wrapper">
                <textarea placeholder="Type a message..." nz-input [(ngModel)]="this.newMessageBody"></textarea>
                <button nz-button nzType="default" (click)="this.newMessage()">Send</button>
            </div>
        </div>
    </div> -->