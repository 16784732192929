import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SharedService } from '../shared.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-block-library-v2',
  templateUrl: './block-library-v2.component.html',
  styleUrls: ['./block-library-v2.component.scss']
})
export class BlockLibraryV2Component {

  opportunities: any = [];
  loading = true;
  selectedObject: any = {};
  selectedBlock: any = {};
  selectedVariant: any = {};

  blockList: any = [];

  mode: any = "DEFAULT";
  blockInstructions: any = "";
  generatingBlockContent: any = false;
  savedBlockList: any = [];

  blockType: any = "RIPPLE";

  filterOptions: any = [
    {
      "name": "Article",
      "value": false,
      "type": "BLOCKTYPE"
    },
    {
      "name": "Banner",
      "value": false,
      "type": "BLOCKTYPE"
    },
    {
      "name": "Hero",
      "value": false,
      "type": "BLOCKTYPE"
    },
    {
      "name": "Navigation",
      "value": false,
      "type": "BLOCKTYPE"
    },
    {
      "name": "Product",
      "value": false,
      "type": "BLOCKTYPE"
    },
    {
      "name": "Review",
      "value": false,
      "type": "BLOCKTYPE"
    },
    {
      "name": "Minimalist",
      "value": false,
      "type": "THEME"
    },
  ]

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private message: NzMessageService
  ) { }

  ngOnInit() {
    this.apiService.getSystemBlocks().subscribe(
      (data: any) => {
        console.log(data);
        this.blockList = data;
        this.loading = false;
      },
      error => {
        console.log(error);
      });
  }

  setSelectedObject(object: any) {
    this.selectedObject = object;
  }

  setPropertyValue() {
    this.sharedService.setPropertyValue(this.selectedObject);
    this.sharedService.pickerOpen = false;
  }

  close() {
    this.sharedService.blockLibraryV2Open = false;
  }

  selectBlock(block: any, variant: any) {
    console.log("selectBlock");
    this.selectedBlock = block;
    this.selectedVariant = variant;
  }

  addBlock() {

    if (this.sharedService.blockLibraryMode == "NEWBLOCK") {
      if (this.getProperty(this.selectedBlock, "Layout")) {
        this.getProperty(this.selectedBlock, "Layout").value = {
          title: "Standard",
          value: "STANDARD",
          image: ""
        };
      }

      // this.sharedService.currentTemplate.blocks.push(this.selectedBlock);
      if (this.sharedService.getArrayItem(this.selectedBlock.properties, "name", "Link")) {
        this.sharedService.getArrayItem(this.selectedBlock.properties, "name", "Link").value = `https://${this.sharedService.currentUser.workspace.domain}`;
      }
      this.sharedService.currentTemplate.blocks.splice(this.sharedService.blockIndex + 1, 0, this.selectedBlock);
      console.log(this.sharedService.currentTemplate);
      this.sharedService.blockLibraryOpen = false;
      this.cdr.detectChanges();
    }

    if (this.sharedService.blockLibraryMode == "CHANGEVARIANT") {
      this.sharedService.currentProperty.value = this.selectedVariant
      this.sharedService.blockLibraryOpen = false;
      this.cdr.detectChanges();
    }
  }

  addSavedBlock() {
    this.sharedService.currentTemplate.blocks.splice(this.sharedService.blockIndex + 1, 0, this.selectedBlock.contentTemplate.blocks[0]);
    console.log(this.sharedService.currentTemplate);
    this.sharedService.blockLibraryOpen = false;
    this.cdr.detectChanges();
  }

  getProperty(block: any, propertyName: any) {
    let property = block.properties.filter((obj: any) => {
      return obj.name === propertyName;
    });

    return property[0];
  }

  chooseBlockType() {
    this.mode = "FILLBLOCK";
  }

  fillWithAI() {
    this.generatingBlockContent = true;
    if (this.getProperty(this.selectedBlock, "Layout")) {
      this.getProperty(this.selectedBlock, "Layout").value = {
        title: "Standard",
        value: "STANDARD",
        image: ""
      };
    };

    let blockToSend = {
      type: this.selectedBlock.type,
      instructions: this.blockInstructions,
      backgroundColor: ""
    }

    this.apiService.generateTemplateContent2(this.sharedService.rendererOpportunityObject._id, "ONEBLOCK", "", blockToSend).subscribe((data4: any) => {
      console.log(data4);
      let block = data4.result;

      if (block.type == "PRODUCT") {
        if (this.getArrayItem(block.properties, "Products") && this.getArrayItem(block.properties, "Products").value) {
          for (let product of this.getArrayItem(block.properties, "Products").value) {
            if (!product.buttonText) {
              product.buttonText = "Shop now";
            }
          }
        }
      }

      if (block.type == "COLLECTION") {
        if (this.getArrayItem(block.properties, "Collections") && this.getArrayItem(block.properties, "Collections").value) {
          for (let product of this.getArrayItem(block.properties, "Collections").value) {
            if (!product.buttonText) {
              product.buttonText = "Shop now";
            }
          }
        }
      }

      this.sharedService.currentTemplate.blocks.splice(this.sharedService.blockIndex + 1, 0, data4.result);
      this.sharedService.blockLibraryOpen = false;
      this.cdr.detectChanges();
    });
  }

  getArrayItem(array: any, propertyName: any) {
    let property = array.filter((obj: any) => {
      return obj.name === propertyName;
    });

    if (property[0]) {
      return property[0];
    } else {
      return "";
    }
  }

  addBlockV2(block: any) {
    this.selectedBlock = block;

    console.log("ADDBLOCKV2");
    console.log(this.selectedBlock);

    // function removeQuillEditor(component: any) {
    //   delete component.quillEditor;

    //   if (component.children) {
    //     for (let child of component.children) {
    //       removeQuillEditor(child);
    //     }
    //   }
    // }

    // for (let child of this.selectedBlock.contentTemplate.template.children) {
    //   removeQuillEditor(child);
    // }

    for (let component of this.sharedService.getArrayItem(this.selectedBlock.contentTemplate.blocks[0].properties, 'name', "Components").items) {
      delete component.quillEditor;
    }
    
    console.log(this.sharedService.newEditorCurrentOpp);

    // this.selectedBlock.contentTemplate.template.type = "BLOCK";
    // this.selectedBlock.contentTemplate.template.title = "Hero";
    // this.selectedBlock.contentTemplate.template.icon = "appstore"

    // this.sharedService.newEditorCurrentOpp.contentTemplate.template.children.push(JSON.parse(JSON.stringify(this.selectedBlock.contentTemplate.template)));
    // this.sharedService.newEditorCurrentOpp.contentTemplate.template.children = [...this.sharedService.newEditorCurrentOpp.contentTemplate.template.children];
    this.sharedService.currentTemplate.blocks.splice(this.sharedService.blockIndex + 1, 0, this.selectedBlock.contentTemplate.blocks[0]);
    this.sharedService.blockLibraryV2Open = false;
    this.cdr.detectChanges();
    this.message.create("success", `Block added`);
  }
}
