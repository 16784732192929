import { Component } from '@angular/core';
import { SharedService } from '../shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { filter } from 'rxjs';

@Component({
  selector: 'app-block-builder',
  templateUrl: './block-builder.component.html',
  styleUrls: ['./block-builder.component.scss']
})
export class BlockBuilderComponent {
  loading: any = false;

  thisWeek: any = [];
  toDo: any = [];

  loadingThisWeek: any = true;
  toDoLoading: any = true;

  openRateMetric: any = {};
  loadingOpenRateMetric = true;

  clickRateMetric: any = {};
  loadingClickRateMetric = true;

  receivedEmailMetric: any = {};
  loadingReceivedEmailMetric = true;

  campaignsSentMetric: any = {};
  loadingCampaignsSentMetric = true;

  ordersMetric: any = {};
  loadingOrdersMetric = true;

  metricsFilter: any = "30DAYS";

  nestedData = [
    {
      value: "Test",
      children: [
        {
          value: "child test",
          children: [
            {
              value: "grandchild test",
              children: []
            }
          ]
        }
      ]
    }
  ];

  block: any = {
    "properties": [
      { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
      { "type": "BORDER", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#000", "borderStyle": "solid" },
      { "type": "RICHTEXT", "name": "styles", "value": "" }
    ],
    "components": [
      {
        "type": "CONTAINER",
        "title": "Container",
        "value": "",
        "properties": [
          { "type": "BORDER", "name": "border", "value": false, "borderWidth": "0px", "borderColor": "#000", "borderStyle": "solid" },
          { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
          { "type": "BACKGROUND", "name": "background", "value": "#f2f2f2", "mode": "solid", "backgroundPosition": "center", "backgroundSize": "cover" },
          { "type": "RICHTEXT", "name": "styles", "value": "" }
        ],
        "children": [
          {
            "type": "TEXT",
            "title": "Text",
            "value": "Check out these products!",
            "properties": [
              { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "Aspekta", "fontSize": "41px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "1.5px" },
              { "type": "COLOR", "name": "color", "value": "#FFF" },
              { "type": "ALIGNMENT", "name": "textAlign", "value": "center" },
              { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
              { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
              { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } }
            ]
          },
          {
            "type": "DATACONTAINER",
            "title": "Products",
            "source": "PRODUCTS",
            "value": [
              {
                "title": "Test product",
                "imageUrl": "https://ripple-files-cdn.azureedge.net/images/66a2671a3b902f4eb28c61fd-17751200825497-thumbnail.png"
              },
              {
                "title": "Test product 2",
                "imageUrl": "https://ripple-files-cdn.azureedge.net/images/66a2671a3b902f4eb28c61fd-17751200825497-thumbnail.png"
              }
            ],
            "children": [
              {
                "type": "CONTAINER",
                "title": "Container",
                "value": "",
                "properties": [
                  { "type": "BORDER", "name": "border", "value": false, "borderWidth": "0px", "borderColor": "#000", "borderStyle": "solid" },
                  { "type": "PADDING", "name": "padding", "value": "0px", "paddingTop": "0px", "paddingBottom": "0px", "paddingLeft": "-px", "paddingRight": "0px" },
                  { "type": "BACKGROUND", "name": "background", "value": "#f2f2f2", "mode": "solid", "backgroundPosition": "center", "backgroundSize": "cover" },
                  { "type": "RICHTEXT", "name": "styles", "value": "" },
                  { "type": "PIXELS", "name": "width", "value": "50%", "mode": "fixed" },
                ],
                "children": [
                  {
                    "type": "IMAGE",
                    "title": "Image",
                    "value": "imageUrl",
                    "mode": "DYNAMIC",
                    "properties": [
                      { "type": "IMAGE", "name": "image", "value": "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-13f95681-1482-47ac-a783-0c9e6b71e1cd.png" },
                      { "type": "PADDING", "name": "padding", "value": "8px", "paddingTop": "8px", "paddingBottom": "8px", "paddingLeft": "16px", "paddingRight": "16px" },
                      { "type": "RICHTEXT", "name": "styles", "value": { "margin": "auto", "width": "100%" } },
                      { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
                    ]
                  },
                  {
                    "type": "TEXT",
                    "title": "Text",
                    "value": "title",
                    "mode": "DYNAMIC",
                    "properties": [
                      { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "Aspekta", "fontSize": "16px", "fontWeight": "500", "lineHeight": "48px", "letterSpacing": "1.5px" },
                      { "type": "COLOR", "name": "color", "value": "#FFF" },
                      { "type": "ALIGNMENT", "name": "textAlign", "value": "center" },
                      { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
                      { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
                      { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } }
                    ]
                  },
                ]
              }
            ],
            "properties": [
              { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "Aspekta", "fontSize": "41px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "1.5px" },
              { "type": "COLOR", "name": "color", "value": "#FFF" },
              { "type": "ALIGNMENT", "name": "textAlign", "value": "center" },
              { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "0px", "borderColor": "#d47555", "borderStyle": "solid" },
              { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
              { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } },
              { "type": "PRODUCTS", "name": "styles", "value": "" }
            ]
          },
          {
            "type": "BUTTON",
            "title": "Button",
            "value": "Happy National Matcha Day",
            "properties": [
              { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "Aspekta", "fontSize": "41px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "1.5px" },
              { "type": "COLOR", "name": "color", "value": "#FFF" },
              { "type": "ALIGNMENT", "name": "textAlign", "value": "center" },
              { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
              { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
              { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } }
            ]
          }
        ]
      }
    ]
  }

  block2: any = {
    "properties": [
      // { "type": "PIXELS", "name": "height", "value": "" },
      { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
      { "type": "BORDER", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#000", "borderStyle": "solid" },
      { "type": "RICHTEXT", "name": "styles", "value": "" }
    ],
    "components": [
      {
        "type": "CONTAINER",
        "title": "Container",
        "value": "",
        "properties": [
          { "type": "BORDER", "name": "border", "value": false, "borderWidth": "0px", "borderColor": "#000", "borderStyle": "solid" },
          { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
          { "type": "BACKGROUND", "name": "background", "value": "https://images.ctfassets.net/dfp1t53x5luq/2htKZCIYTHMkix8GVabzsX/ca38b93cbc24b9b4851c2292a02b95e7/Cookware_Plus_-_Lifestyle_on_Stovetop__1_.jpg?w=1916&h=1412&q=85&fm=webp", "mode": "image", "backgroundPosition": "center", "backgroundSize": "cover" },
          { "type": "RICHTEXT", "name": "styles", "value": "" }
        ],
        "children": [
          {
            "type": "CONTAINER",
            "title": "Container",
            "value": "",
            "properties": [
              { "type": "BORDER", "name": "border", "value": true, "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
              { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "0px", "paddingBottom": "0px", "paddingLeft": "0px", "paddingRight": "0px" },
              { "type": "BACKGROUND", "name": "background", "value": "transparent", "mode": "solid", "backgroundPosition": "center", "backgroundSize": "contain" },
              { "type": "RICHTEXT", "name": "styles", "value": "" }
            ],
            "children": [
              {
                "type": "CONTAINER",
                "title": "Container",
                "value": "",
                "properties": [
                  { "type": "BORDER", "name": "border", "value": false, "borderWidth": "0px", "borderColor": "#d47555", "borderStyle": "solid" },
                  { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
                  { "type": "BACKGROUND", "name": "background", "value": "#fbf9f9", "mode": "solid", "backgroundPosition": "center", "backgroundSize": "contain" },
                  { "type": "RICHTEXT", "name": "styles", "value": { "border-bottom-width": "3px" } }
                ],
                "children": [
                  {
                    "type": "IMAGE",
                    "title": "Image",
                    "value": "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-13f95681-1482-47ac-a783-0c9e6b71e1cd.png",
                    "properties": [
                      { "type": "IMAGE", "name": "image", "value": "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-13f95681-1482-47ac-a783-0c9e6b71e1cd.png" },
                      { "type": "PADDING", "name": "padding", "value": "8px", "paddingTop": "8px", "paddingBottom": "8px", "paddingLeft": "16px", "paddingRight": "16px" },
                      { "type": "RICHTEXT", "name": "styles", "value": { "margin": "auto", "width": "200px" } },
                      { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
                    ]
                  },
                  // {
                  //   "type": "BUTTON",
                  //   "value": "Click Me",
                  //   "properties": [
                  //     { "type": "font", "name": "fontSize", "value": "24px" },
                  //     { "type": "font", "name": "fontWeight", "value": "600" },
                  //     { "type": "color", "name": "color", "value": "#000000" },
                  //     { "type": "textAlign", "name": "textAlign", "value": "center" },
                  //     { "type": "padding", "name": "paddingTop", "value": "0px" },
                  //     { "type": "padding", "name": "paddingBottom", "value": "0px" },
                  //     { "type": "padding", "name": "paddingLeft", "value": "0px" },
                  //     { "type": "padding", "name": "paddingRight", "value": "0px" },
                  //     { "type": "border", "name": "borderRadius", "value": "4px" },
                  //     { "type": "border", "name": "borderColor", "value": "#000000" },
                  //     { "type": "border", "name": "borderWidth", "value": "1px" },
                  //     { "type": "border", "name": "borderStyle", "value": "solid" },
                  //     { "type": "styles", "name": "styles", "value": "" }
                  //   ]
                  // }
                ]
              },
              {
                "type": "CONTAINER",
                "title": "Container",
                "value": "",
                "properties": [
                  { "type": "BORDER", "name": "border", "value": false, "borderWidth": "0px", "borderColor": "#d47555", "borderStyle": "solid" },
                  { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
                  { "type": "PIXELS", "name": "width", "value": "30%", "mode": "fixed" },
                  { "type": "BACKGROUND", "name": "background", "value": "#d47555", "mode": "solid", "backgroundPosition": "center", "backgroundSize": "contain" },
                  { "type": "RICHTEXT", "name": "styles", "value": "" }
                ],
                "children": [
                  {
                    "type": "TEXT",
                    "title": "Text",
                    "value": "20%\nOFF",
                    "properties": [
                      { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "Aspekta", "fontSize": "41px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "1.5px" },
                      { "type": "COLOR", "name": "color", "value": "#FFF" },
                      { "type": "ALIGNMENT", "name": "textAlign", "value": "center" },
                      { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
                      { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
                      { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } },
                      { "type": "TEXTSTYLES", "name": "textStyles", "value": "" }
                    ]
                  },
                ]
              },
              {
                "type": "CONTAINER",
                "title": "Container",
                "value": "",
                "properties": [
                  { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
                  { "type": "padding", "name": "borderRadius", "value": "0px" },
                  { "type": "BORDER", "name": "border", "value": false, "borderWidth": "0px", "borderColor": "#d47555", "borderStyle": "solid" },
                  { "type": "PIXELS", "name": "width", "value": "70%", "mode": "fixed" },
                  { "type": "BACKGROUND", "name": "background", "value": "#fbf9f9", "mode": "solid", "backgroundPosition": "center", "backgroundSize": "contain" },
                  { "type": "RICHTEXT", "name": "styles", "value": "" }
                ],
                "children": [
                  {
                    "type": "TEXT",
                    "title": "Text",
                    "value": "Iconic\nMust-Haves",
                    "properties": [
                      { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "Meriweather", "fontSize": "40px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "1.5px", "toolbar": true },
                      { "type": "COLOR", "name": "color", "value": "#d47555", "toolbar": true },
                      { "type": "ALIGNMENT", "name": "textAlign", "value": "left", "toolbar": true },
                      { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
                      { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } },
                      { "type": "TEXTSTYLES", "name": "textStyles", "value": "", "toolbar": true }
                    ]
                  },
                ]
              }
            ]
          }
        ]
      }
    ]
  };

  block1: any = {
    properties: {
      paddingTop: "16px",
      paddingBottom: "16px",
      paddingLeft: "16px",
      paddingRight: "16px",
      styles: ""
    },
    components: [
      {
        type: "TEXT",
        value: "Welcome to the Block Builder",
        properties: {
          fontSize: "24px",
          fontWeight: "600",
          fontFamily: "Arial",
          color: "#000000",
          textAlign: "center",
          paddingTop: "40px",
          paddingBottom: "16px",
          paddingLeft: "0px",
          paddingRight: "0px",
          styles: { 'color': 'red', 'font-size': '24px' }
        }
      },
      {
        type: "TEXT",
        value: "Welcome to the Block Builder",
        properties: {
          fontSize: "24px",
          fontWeight: "600",
          fontFamily: "Arial",
          color: "#000000",
          textAlign: "center",
          paddingTop: "0px",
          paddingBottom: "16px",
          paddingLeft: "0px",
          paddingRight: "0px",
          styles: " "
        }
      },
      {
        type: "IMAGE",
        value: "Welcome to the Block Builder",
        properties: {
          fontSize: "24px",
          fontWeight: "600",
          color: "#000000",
          textAlign: "center",
          paddingTop: "0px",
          paddingBottom: "0px",
          paddingLeft: "0px",
          paddingRight: "0px",
          styles: ""
        }
      },
      {
        type: "BUTTON",
        value: "Welcome to the Block Builder",
        properties: {
          fontSize: "24px",
          fontWeight: "600",
          color: "#000000",
          textAlign: "center",
          paddingTop: "0px",
          paddingBottom: "0px",
          paddingLeft: "0px",
          paddingRight: "0px",
          borderRadius: "4px",
          borderColor: "#000000",
          borderWidth: "1px",
          borderStyle: "solid",
          styles: ""
        }
      }
    ]
  };

  jsonString = JSON.stringify(this.block, null, 2);

  activeComponent: any = null;

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public sharedService: SharedService
  ) {

  }

  ngOnInit() {

    let filterDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
    this.apiService.getThisWeek().subscribe(
      (data: any) => {
        console.log(data);
        this.thisWeek = data;
        this.loadingThisWeek = false;
      },
      error => {
        console.log(error);
      });

    this.apiService.get14DayPlaceholders().subscribe(
      (data: any) => {
        console.log(data);
        this.toDo = data;
        this.toDoLoading = false;
      },
      error => {
        console.log(error);
      });

    if (this.sharedService.currentUser.adminLevel == 'SUPER') {

      this.apiService.getWorkspace(this.sharedService.currentUser.workspace._id).subscribe(
        (data: any) => {

          if (data.metrics?.WORKSPACECAMPAIGNSSENT) {
            this.campaignsSentMetric = data.metrics?.WORKSPACECAMPAIGNSSENT;
          }

          if (data.metrics?.WORKSPACEOPENRATE) {
            this.openRateMetric = data.metrics?.WORKSPACEOPENRATE;
          }

          if (data.metrics?.WORKSPACECLICKRATE) {
            this.clickRateMetric = data.metrics?.WORKSPACECLICKRATE;
          }

          if (data.metrics?.WORKSPACEATTRIBUTEDORDERS) {
            this.ordersMetric = data.metrics?.WORKSPACEATTRIBUTEDORDERS;
          }

          this.apiService.getMetric("WORKSPACECAMPAIGNSSENT", filterDate).subscribe(
            (data: any) => {
              console.log("CAMPAIGNSSENT", data);
              // this.loading = false;
              this.campaignsSentMetric = data;
              this.loadingCampaignsSentMetric = false;

              this.apiService.getMetric("WORKSPACEOPENRATE", filterDate).subscribe(
                (data: any) => {
                  console.log("OPENRATE", data);
                  // this.loading = false;
                  this.openRateMetric = data;
                  this.loadingOpenRateMetric = false;

                  this.apiService.getMetric("WORKSPACECLICKRATE", filterDate).subscribe(
                    (data: any) => {
                      console.log("CLICKRATE", data);
                      // this.loading = false;
                      this.clickRateMetric = data;
                      this.loadingClickRateMetric = false;

                      this.apiService.getMetric("WORKSPACEATTRIBUTEDORDERS", filterDate).subscribe(
                        (data: any) => {
                          console.log("ATTRIBUTEDORDERS", data);
                          // this.loading = false;
                          this.ordersMetric = data;
                          this.loadingOrdersMetric = false;
                        },
                        error => {
                          console.log(error);
                        });
                    },
                    error => {
                      console.log(error);
                    });
                },
                error => {
                  console.log(error);
                });
            },
            error => {
              console.log(error);
            });

        }, error => {
          console.log(error);
        });
    }
  }

  changeMetricFilter() {

    var filterDate: any = null;

    if (this.metricsFilter == "7DAYS") {
      filterDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    } else if (this.metricsFilter == "30DAYS") {
      filterDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
    } else if (this.metricsFilter == "24HOURS") {
      filterDate = new Date(Date.now() - 24 * 60 * 60 * 1000);
    }

    this.loadingCampaignsSentMetric = true;
    this.loadingOpenRateMetric = true;
    this.loadingClickRateMetric = true;
    this.loadingOrdersMetric = true;

    this.campaignsSentMetric = {};
    this.openRateMetric = {};
    this.clickRateMetric = {};
    this.ordersMetric = {};

    this.apiService.getMetric("WORKSPACECAMPAIGNSSENT", filterDate).subscribe(
      (data: any) => {
        console.log("CAMPAIGNSSENT", data);
        // this.loading = false;
        this.campaignsSentMetric = data;
        this.loadingCampaignsSentMetric = false;

        this.apiService.getMetric("WORKSPACEOPENRATE", filterDate).subscribe(
          (data: any) => {
            console.log("OPENRATE", data);
            // this.loading = false;
            this.openRateMetric = data;
            this.loadingOpenRateMetric = false;

            this.apiService.getMetric("WORKSPACECLICKRATE", filterDate).subscribe(
              (data: any) => {
                console.log("CLICKRATE", data);
                // this.loading = false;
                this.clickRateMetric = data;
                this.loadingClickRateMetric = false;

                this.apiService.getMetric("WORKSPACEATTRIBUTEDORDERS", filterDate).subscribe(
                  (data: any) => {
                    console.log("ATTRIBUTEDORDERS", data);
                    // this.loading = false;
                    this.ordersMetric = data;
                    this.loadingOrdersMetric = false;
                  },
                  error => {
                    console.log(error);
                  });
              },
              error => {
                console.log(error);
              });
          },
          error => {
            console.log(error);
          });
      },
      error => {
        console.log(error);
      });
  };

  updateNestedData() {
    try {
      this.block = JSON.parse(this.jsonString);
    } catch (e) {
      console.error('Invalid JSON:', e);
    }
  }

  clickTree(event: any) {
    console.log(event);
    this.sharedService.activeComponent = event?.node?.origin;
  }

}
