<div class="page-wrapper">
    <div class="header-wrapper">
        <nz-page-header nzTitle="Saved blocks">
            <nz-page-header-extra>
                <!-- <button nz-button nzType="primary" (click)="this.sharedService.openNew(null)"><span nz-icon
                        nzType="plus" nzTheme="outline"></span>New</button> -->
                <button nz-button nzType="primary" (click)="openNewFlow()"><span nz-icon nzType="plus"
                        nzTheme="outline"></span>New saved block</button>
            </nz-page-header-extra>
        </nz-page-header>
    </div>
    <div class="content-wrapper">
        <ng-container *ngIf="this.loading">
            <div class="custom-card">
                <div class="loading-wrapper">
                    <div class="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!this.loading">
            <div class="content-inner">
                <div class="custom-card">
                    <ng-container *ngIf="!this.opportunities || this.opportunities.length == 0">
                        <div class="custom-card-body">
                            <div style="text-align: center; padding-bottom: 16px;">
                                <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="null"></nz-empty>
                                <div style="font-weight: 500; margin-bottom: 24px;">You don't have any flows :/
                                </div>
                                <button nz-button nzType="primary" (click)="openNewFlow()"><span nz-icon nzType="plus"
                                        nzTheme="outline"></span>Create a flow</button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="this.opportunities && this.opportunities.length > 0">

                        <div class="custom-card-header"
                            style="margin: 0px 0px; border-bottom: none; padding: 0px !important;">
                            <div nz-row class="table-row" [nzGutter]="16" style="padding: 0px 24px !important">
                                <!-- <div nz-col class="gutter-row" [nzSpan]="2">
                    <div class="inner-box"></div>
                </div> -->
                                <div nz-col class="gutter-row" [nzSpan]="12">
                                    Title
                                </div>
                                <div nz-col class="gutter-row" [nzSpan]="6">
                                    Sendtime
                                </div>
                                <div nz-col class="gutter-row" [nzSpan]="6">
                                    Updated
                                </div>
                            </div>
                        </div>
                        <div class="custom-card-body" style="padding: 0px !important;">
                            <ng-container *ngFor="let opportunity of opportunities; index as index">
                                <ng-container
                                    *ngIf="index >= (this.currentPage - 1) * 50 && index < (this.currentPage * 50)">
                                    <div nz-row [nzGutter]="16" class="table-row"
                                        [routerLink]="['/flows',opportunity._id]" style="padding: 0px 24px !important">

                                        <div nz-col class="gutter-row" [nzSpan]="12"
                                            style="color: #374151; font-weight: 500;">
                                            <div style="text-overflow: ellipsis; overflow: hidden;
                            white-space: nowrap;">
                                                <!-- <ng-container
                                    *ngIf="opportunity.contentTemplate && opportunity.contentTemplate.subjectLine">
                                    {{opportunity.contentTemplate.subjectLine}}
                                </ng-container>
                                <ng-container
                                    *ngIf="!opportunity.contentTemplate || !opportunity.contentTemplate.subjectLine">
                                </ng-container> -->
                                                {{opportunity.name}}

                                            </div>
                                        </div>
                                        <!-- <div nz-col class="gutter-row" [nzSpan]="6">
                            <ng-container *ngIf="opportunity.stage == 'LIVE'">
                                <nz-tag class="aqua">Scheduled</nz-tag>
                            </ng-container>
                            <ng-container *ngIf="opportunity.stage == 'PENDING'">
                                <nz-tag>Draft</nz-tag>
                            </ng-container>
                            <ng-container *ngIf="opportunity.stage == 'FAILED'">
                                <nz-tag [nzColor]="'red'">Failed&nbsp;&nbsp;<span nz-icon nzType="stop"
                                        nzTheme="outline"></span></nz-tag><button nzType="text" nz-button
                                    (click)="this.sharedService.tryJobAgain(opportunity)"><span nz-icon nzType="sync"
                                        nzTheme="outline"></span>Try again</button>
                            </ng-container>
                            <ng-container *ngIf="opportunity.stage == 'INPROGRESS'">
                                <nz-tag class="aqua">Generating&nbsp;&nbsp;<span nz-icon nzType="loading"
                                        nzTheme="outline" [nzSpin]="true"></span></nz-tag>
                            </ng-container>
                            <ng-container *ngIf="opportunity.stage == 'NEW'">
                                <nz-tag>Waiting&nbsp;&nbsp;<span nz-icon nzType="pause-circle"
                                        nzTheme="outline"></span></nz-tag>
                            </ng-container>

                        </div> -->
                                        <div nz-col class="gutter-row" [nzSpan]="6">
                                            {{opportunity.createdAt | date}}
                                        </div>
                                        <div nz-col class="gutter-row" [nzSpan]="6">
                                            {{opportunity.updatedAt | date}}
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="custom-card-footer" style="border-top: none !important;">
                            <nz-pagination [nzTotal]="opportunities.length" [nzPageSize]="50"
                                (nzPageIndexChange)="this.pageIndexChange($event)"></nz-pagination>
                        </div>
                    </ng-container>
                </div>
            </div>
            <!-- <div class="footer-wrapper">
                <nz-pagination [nzTotal]="opportunities.length" [nzPageSize]="50"
                    (nzPageIndexChange)="this.pageIndexChange($event)"
                    style="float: right; margin: 8px"></nz-pagination>
            </div> -->
        </ng-container>

        <!-- <div class="column-right">
            <nz-page-header style="padding-bottom: 6px !important" class="site-page-header">
                <nz-page-header-title>Suggestions</nz-page-header-title>
                <nz-breadcrumb nz-page-header-breadcrumb>
                    <nz-breadcrumb-item>Opportunities</nz-breadcrumb-item>
                    <nz-breadcrumb-item>Pending</nz-breadcrumb-item>
                </nz-breadcrumb>
                <nz-page-header-extra>
                    <button nz-button nzType="link" [routerLink]="['/new']">See more</button>
                </nz-page-header-extra>
            </nz-page-header>
            <ng-container *ngIf="this.loadingNewOpps">
                <div class="custom-card">
                    <div class="custom-card-body mini">
                        <nz-skeleton [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                    </div>
                </div>
                <div class="custom-card">
                    <div class="custom-card-body mini">
                        <nz-skeleton [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                    </div>
                </div>
                <div class="custom-card">
                    <div class="custom-card-body mini">
                        <nz-skeleton [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngFor="let opp of this.newOpportunities">
                <div class="custom-card hover" [routerLink]="['/opportunities',opp._id]">
                    <div class="custom-card-body mini">
                        <div class="new-opp-title">{{opp.title}}</div>
                        <ng-container *ngIf="opp.template == 'FEATUREDPRODUCT'">
                            <nz-tag>Featured product</nz-tag>
                        </ng-container>
                        <ng-container *ngIf="opp.template == 'EDUCATIONAL'">
                            <nz-tag>Educational</nz-tag>
                        </ng-container>
                        <ng-container *ngIf="opp.template == 'FEATUREDCATEGORY'">
                            <nz-tag>Featured category</nz-tag>
                        </ng-container>
                        <div class="new-opp-body">{{opp.description}}</div>
                    </div>
                </div>
            </ng-container>
        </div> -->


    </div>
</div>