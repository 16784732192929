<div class="page-wrapper">
    <img class="logo-signup" src="../../../assets/rippleLogo.png" />
    <div class="content-wrapper">


        <ng-container *ngIf="this.loading">
            <!-- <div class="custom-card">
                <div class="custom-card-body" style="text-align: center;">
                    <div class="meter purple">
                        <span style="width: 100%"></span>
                    </div>
                    <h3>Copilot is analyzing your store.</h3>
                    <h3>This may take a moment.</h3>
                </div>
            </div> -->
            <div class="rippleLoaderWrapper">
                <div class="rippleLoader">
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div class="subtitle" style="font-weight: 500;">Ripple is learning about your brand.<br>This may take a
                moment.</div>
        </ng-container>
        <ng-container *ngIf="!this.loading">
            <h2 nz-typography class="setup-title">Confirm your brand details</h2>
            <div class="subtitle">Branding can be changed later in
                settings.</div>
            <form nz-form nzLayout="vertical">
                <nz-form-item style="text-align: left;">
                    <nz-form-label nzNoColon="true" nzLabelAlign="left" nzSpan="8">Summary<span nz-tooltip
                            nzTooltipTitle="Ripple generated this summary based on your website"
                            style="float: right;">&nbsp;<span nz-icon nzType="question-circle"
                                nzTheme="outline"></span></span></nz-form-label>
                    <nz-form-control nzSpan="24">
                        <div>{{this.imagesAndColorsObject.brandDescription}}</div>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item style="text-align: left;">
                    <nz-form-label nzNoColon="true" nzLabelAlign="left" nzSpan="8">Logo&nbsp;<span nz-icon nzType="edit"
                            nzTheme="outline"></span></nz-form-label>
                    <nz-form-control nzSpan="24">
                        <img *ngIf="this.imagesAndColorsObject.logo" class="image-big" src="{{this.imagesAndColorsObject.logo.src}}" />
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item style="text-align: left; margin-bottom: 32px;">
                    <nz-form-label nzNoColon="true" nzLabelAlign="left" nzSpan="24">Primary color (choose one)</nz-form-label>
                    <nz-form-control nzSpan="24">
                        <!-- <div class="color-block-title">Primary color (choose one)</div> -->
                        <ng-container *ngFor="let color of imagesAndColorsObject.colors">
                            <div class="color-block-wrapper" [class.selected]="this.imagesAndColorsObject.color == color">

                                <div class="color-block" [style.backgroundColor]="color"
                                    
                                    (click)="this.imagesAndColorsObject.color = color">
                                </div>
                            </div>
                        </ng-container>
                        <!-- <div class="color-block-wrapper">
                            <div class="color-block-title">Secondary color</div>
                            <div class="color-block" [style.backgroundColor]="this.imagesAndColorsObject.colorScheme[1]">
                            </div>
                        </div>
                        <div class="color-block-wrapper">
                            <div class="color-block-title">Background 1</div>
                            <div class="color-block" [style.backgroundColor]="this.imagesAndColorsObject.colorScheme[2]">
                            </div>
                        </div>
                        <div class="color-block-wrapper">
                            <div class="color-block-title">Background 2</div>
                            <div class="color-block" [style.backgroundColor]="this.imagesAndColorsObject.colorScheme[3]">
                            </div>
                        </div> -->
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item style="text-align: left; margin-bottom: 32px;">
                    <nz-form-label nzNoColon="true" nzLabelAlign="left" nzSpan="8">Fonts&nbsp;<span nz-icon
                            nzType="edit" nzTheme="outline"></span></nz-form-label>
                    <nz-form-control nzSpan="24">
                        <div class="font-wrapper">
                            <div class="font-title">Header font</div>
                            <div class="font-display header" [style.fontFamily]="this.imagesAndColorsObject.headerFont">
                                {{this.imagesAndColorsObject.headerFont}}</div>
                        </div>
                        <div class="font-wrapper">
                            <div class="font-title">Body font</div>
                            <div class="font-display" [style.fontFamily]="this.imagesAndColorsObject.bodyFont">
                                {{this.imagesAndColorsObject.bodyFont}}</div>
                        </div>
                        <!-- <div class="color-block-wrapper">
                            <div class="color-block-title">Secondary color</div>
                            <div class="color-block" [style.backgroundColor]="this.imagesAndColorsObject.colorScheme[1]">
                            </div>
                        </div>
                        <div class="color-block-wrapper">
                            <div class="color-block-title">Background 1</div>
                            <div class="color-block" [style.backgroundColor]="this.imagesAndColorsObject.colorScheme[2]">
                            </div>
                        </div>
                        <div class="color-block-wrapper">
                            <div class="color-block-title">Background 2</div>
                            <div class="color-block" [style.backgroundColor]="this.imagesAndColorsObject.colorScheme[3]">
                            </div>
                        </div> -->
                    </nz-form-control>
                </nz-form-item>
            </form>

            <button nz-button nzType="primary" (click)="saveBrandStyles()" nzBlock>Confirm</button>
        </ng-container>
    </div>
</div>


<!-- <p>Copilot identified the following as your logo.</p>
<br>
<img class="image-big" src="{{this.imagesAndColorsObject.logo.src}}" />
<br>
<button *ngIf="!this.showImageSelector" (click)="showImageSelector = true" nz-button nzType="default">Choose
    a different image</button>
<ng-container *ngIf="this.showImageSelector">
    <nz-divider></nz-divider>
    <div nz-row [nzGutter]="[16, 24]">
        <ng-container *ngFor="let image of imagesAndColorsObject.images">
            <ng-container *ngIf="image.src">
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <img class="image-list" (click)="this.imagesAndColorsObject.logo = image"
                        src="{{image.src}}">
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-container>



<p>Copilot identified the following as your primary color.</p>
<br>
<div class="image-list" style="width: 100px; height: 100px;"
    [style.backgroundColor]="this.imagesAndColorsObject.color">
</div>
<br>


<button *ngIf="!this.showColorSelector" (click)="showColorSelector = true" nz-button nzType="default">Choose
    a different color</button>
<ng-container *ngIf="this.showColorSelector">
    <nz-divider></nz-divider>
    <p>Copilot found the following colors on your site:</p>
    <br>
    <div nz-row [nzGutter]="[16, 24]">
        <ng-container *ngFor="let color of imagesAndColorsObject.colors">
            <div nz-col class="gutter-row" [nzSpan]="2">
                <div class="image-list color" [class.selected]="this.imagesAndColorsObject.color == color"
                    (click)="this.imagesAndColorsObject.color = color" [style.backgroundColor]="color">
                </div>
            </div>
        </ng-container>
    </div>
    <br>
    <p>Or enter a custom color:</p>
    <input style="width: 300px;" [(ngModel)]="this.imagesAndColorsObject.color" nz-input
        placeholder="Enter a color code" />
</ng-container> -->