<div class="custom-modal-header">
    <div class="custom-modal-title"></div>
    <div class="custom-modal-close" (click)="sharedService.closeDatabaseEditor()"><span nz-icon nzType="close" nzTheme="outline"></span></div>
</div>
<div class="custom-modal-body">
    <nz-form-item>
        <nz-form-label [nzNoColon]="true">Object type</nz-form-label>
        <nz-form-control [nzSpan]="24">
            <input nz-input [(ngModel)]="this.sharedService.databaseEditorObjectFull.type" />
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-label [nzNoColon]="true">Object data</nz-form-label>
        <nz-form-control [nzSpan]="24">
            <textarea style="height:380px" nz-input [(ngModel)]="this.sharedService.databaseEditorObject"></textarea>
        </nz-form-control>
    </nz-form-item>
</div>
<div class="custom-modal-footer">
    <button nz-button nzType="primary" (click)="saveDatabaseObject()"
                    [nzLoading]="saving">Save</button>
</div>