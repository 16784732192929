<div class="page-wrapper">
    <div class="navbar">
        <div class="title">kConnect</div>
    </div>
    <div class="content-wrapper">
        <div class="content-wrapper-inner">
            <div class="custom-card">

                <form nz-form nzLayout="vertical" (ngSubmit)="onSubmit()">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="6" nzFor="email">Email</nz-form-label>
                        <nz-form-control nzHasFeedback nzErrorTip="Please enter a valid email" [nzSpan]="24">
                            <input nz-input [(ngModel)]="this.newUserObject.email" name="email" type="email" id="email" email>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="6" nzFor="password">Password</nz-form-label>
                        <nz-form-control [nzSpan]="24" nzHasFeedback nzErrorTip="Password must be at least 6 characters">
                            <input nz-input [(ngModel)]="this.newUserObject.password" name="password" type="password"
                                id="password" minlength="6">
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-control [nzSpan]="24">
                            <button type="submit" nz-button nzType="primary" nzBlock>Sign up</button>
                        </nz-form-control>
                    </nz-form-item>
                </form>
            </div>
        </div>
    </div>
</div>