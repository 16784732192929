<div class="page-wrapper">
    <div class="header-wrapper">
        <nz-page-header nzTitle="Campaigns">
            <nz-page-header-tags>
                <ul nz-menu nzMode="horizontal" style="background: #FAFAFA">
                    <li nz-menu-item nzSelected (click)="filterOpportunities('ALL')">
                        <span>All campaigns</span>
                    </li>
                    <li nz-menu-item (click)="filterOpportunities('CAMPAIGN')">
                        <span>Email</span>
                    </li>
                    <ng-container *ngIf="this.workspaceObject?.brandStyles?.enableSMS">
                        <li nz-menu-item (click)="filterOpportunities('SMS')">
                            <span>SMS</span>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="this.workspaceObject?.brandStyles?.enableSocialPosts">
                        <li nz-menu-item (click)="filterOpportunities('SOCIALPOST')">
                            <span>Social posts</span>
                        </li>
                    </ng-container>
                </ul>
            </nz-page-header-tags>
            <nz-page-header-extra>
                <!-- <button nz-button nzType="primary" (click)="this.sharedService.openNew(null)"><span nz-icon
                        nzType="plus" nzTheme="outline"></span>New</button> -->
                <!-- <button nz-button routerLink="/assistant/calendar"><span nz-icon nzType="robot" nzTheme="outline"></span>Planning assistant</button>&nbsp; -->
                <nz-segmented [(ngModel)]="this.viewMode" [nzOptions]="options" style="margin-right: 6px;"
                    (nzValueChange)="changedView($event)"></nz-segmented>
                <button nz-button nzType="primary" routerLink="/create"><span nz-icon nzType="plus"
                        nzTheme="outline"></span>New campaign</button>
            </nz-page-header-extra>
        </nz-page-header>
    </div>
    <div class="content-wrapper">

        <!-- <nz-page-header style="padding-bottom: 6px !important" nzTitle="Analytics">
                <nz-page-header-extra>
                    <button nz-button nzType="link" [routerLink]="['/new']">See more</button>
                </nz-page-header-extra>
            </nz-page-header>
            <div class="custom-card">
                <div class="custom-card-body"></div>
            </div>
            <nz-page-header style="padding-bottom: 6px !important" nzTitle="Calendar">
                <nz-page-header-extra>
                    <button nz-button nzType="link" [routerLink]="['/new']">See more</button>
                </nz-page-header-extra>
            </nz-page-header>
            <div class="custom-card">
                <div class="custom-card-body"></div>
            </div> -->
        <!-- <nz-page-header style="padding-bottom: 6px !important" nzTitle="Drafts">
                <nz-page-header-extra>
                    <button nz-button nzType="link" [routerLink]="['/new']">See more</button>
                </nz-page-header-extra>
            </nz-page-header> -->
        <!-- <div class="custom-card table-card" style="height: auto !important;" *ngIf="sharedService.pendingOpps.length > 0">
            <div class="header-row" style="padding: 2px 8px 0px;">
                Generating
            </div>
            <div class="custom-card-body table-card" style="height: auto !important;">
                <ng-container *ngFor="let opportunity of sharedService.pendingOpps; index as index">
                    <div nz-row [nzGutter]="16" class="table-row">
                        <div nz-col class="gutter-row" [nzSpan]="4">
                            <div style="text-overflow: ellipsis; overflow: hidden;
                            white-space: nowrap;">



                            </div>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="20">
                            <div style="text-overflow: ellipsis; overflow: hidden;
                            white-space: nowrap;">
                                <ng-container *ngIf="opportunity.status == 'FAILED'">
                                    <span nz-icon nzType="stop" nzTheme="outline"></span>&nbsp;&nbsp;<nz-tag
                                        [nzColor]="'red'">Failed</nz-tag>
                                </ng-container>
                                <ng-container *ngIf="opportunity.status == 'INPROGRESS'">
                                    <span nz-icon nzType="loading" nzTheme="outline"
                                        [nzSpin]="true"></span>&nbsp;&nbsp;<nz-tag class="aqua">Generating</nz-tag>
                                </ng-container>
                                <ng-container *ngIf="opportunity.status == 'NEW'">
                                    <span nz-icon nzType="loading-3-quarters"
                                        nzTheme="outline"></span>&nbsp;&nbsp;<nz-tag>Waiting</nz-tag>
                                </ng-container>
                                {{opportunity.opportunity.title}}
                            </div>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="4" style="text-align: right">
                            <ng-container *ngIf="opportunity.status == 'FAILED'">
                                <button nz-button (click)="this.sharedService.tryJobAgain(opportunity)"><span nz-icon nzType="sync"
                                        nzTheme="outline"></span>Try again</button>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div> -->
        <!-- </div> -->
        <!-- <div class="header-row" style="height: 49px; padding: 0px 16px;">
            <nz-select nzPlaceHolder="Status" style="width: 200px;">
                <nz-option nzLabel="All" nzValue="4"></nz-option>
                <nz-option nzLabel="Draft" nzValue="0"></nz-option>
                <nz-option nzLabel="Scheduled" nzValue="1"></nz-option>
                <nz-option nzLabel="Sent" nzValue="2"></nz-option>
                <nz-option nzLabel="Generating" nzValue="3"></nz-option>
            </nz-select> 
            <ul nz-menu nzMode="horizontal" style="float: left; margin-top: 5px;">
                <li nz-menu-item nzSelected>
                    <span>All</span>
                </li>
                <li nz-menu-item>
                    <span>Draft</span>
                </li>
                <li nz-menu-item>
                    <span>Scheduled</span>
                </li>
                <li nz-menu-item>
                    <span>Sent</span>
                </li>
            </ul>
            
        </div> -->
        <ng-container *ngIf="this.loading">
            <div class="loading-wrapper">
                <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <!-- <div nz-row [nzGutter]="16" class="table-row" style="padding-top: 16px;">
                <div nz-col class="gutter-row" [nzSpan]="8">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
            </div>
            <div nz-row [nzGutter]="16" class="table-row" style="padding-top: 16px;">
                <div nz-col class="gutter-row" [nzSpan]="8">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
            </div>
            <div nz-row [nzGutter]="16" class="table-row" style="padding-top: 16px;">
                <div nz-col class="gutter-row" [nzSpan]="8">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
            </div>
            <div nz-row [nzGutter]="16" class="table-row" style="padding-top: 16px;">
                <div nz-col class="gutter-row" [nzSpan]="8">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
            </div> -->

        </ng-container>
        <ng-container *ngIf="!this.loading">
            <ng-container *ngIf="this.viewMode == 0">
                <div class="grid-outer">
                    <div class="grid-wrapper">
                        <div nz-row [nzGutter]="16">
                            <ng-container *ngFor="let opportunity of opportunities; index as index">
                                <ng-container
                                    *ngIf="index >= (this.currentPage - 1) * 52 && index < (this.currentPage * 52)">
                                    <div nz-col class="gutter-row" [nzSpan]="6">
                                        <div class="custom-card hover"
                                            [routerLink]="['/opportunities',opportunity._id,'e']">
                                            <div class="custom-card-header" style="padding: 0px;">
                                                <ng-container *ngIf="opportunity.type == 'SMS'">
                                                    <div class="email-thumbnail"
                                                        style="background: #f9f9f9; text-align: center; font-size: 48px; padding-top: 75px; color: #d9d9d9">
                                                        <i class="lni lni-popup"></i>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="opportunity.type != 'SMS'">
                                                    <ng-container *ngIf="!opportunity.thumbnail">
                                                        <div class="email-thumbnail"
                                                            style="background: #f9f9f9; text-align: center; font-size: 48px; padding-top: 75px; color: #d9d9d9">
                                                            <i class="lni lni-image"></i>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="opportunity.thumbnail">
                                                        <ng-container *ngIf="opportunity.loadedThumbnail">
                                                            <!-- <img [src]="opportunity.thumbnail"> -->
                                                            <div class="opportunity-thumbnail-display"
                                                                [style.background-image]="'url('+opportunity.thumbnail+')'">

                                                            </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="!opportunity.loadedThumbnail">
                                                            <nz-skeleton-element nzType="image" [nzActive]="true"
                                                                class="preview"></nz-skeleton-element>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                            <div class="custom-card-body">
                                                <ng-container *ngIf="opportunity.stage == 'NEW'">
                                                    <div class="email-title">{{opportunity.prompt}}
                                                    </div>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="opportunity.stage == 'PENDING' || opportunity.stage == 'LIVE' || opportunity.stage == 'PLACEHOLDER'">
                                                    <div class="email-title">{{opportunity.title}}
                                                    </div>
                                                </ng-container>
                                                <div style="margin-top: 8px;">
                                                    <ng-container
                                                        *ngIf="opportunity.stage == 'LIVE' && sharedService.timeInFuture(opportunity.sendTime)">
                                                        <nz-tag class="aqua"
                                                            style="margin-right: 4px;">Scheduled</nz-tag>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="opportunity.stage == 'LIVE' && !sharedService.timeInFuture(opportunity.sendTime)">
                                                        <nz-tag class="aqua" style="margin-right: 4px;">Sent</nz-tag>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="opportunity.stage == 'PENDING' || opportunity.stage == 'NEW'">
                                                        <nz-tag style="margin-right: 4px;">Draft</nz-tag>
                                                    </ng-container>
                                                    <ng-container *ngIf="opportunity.stage == 'PLACEHOLDER'">
                                                        <nz-tag style="margin-right: 4px;">To do</nz-tag>
                                                    </ng-container>
                                                    <ng-container *ngIf="opportunity.type == 'CAMPAIGN'">
                                                        <nz-tag class="aqua">Email</nz-tag>
                                                    </ng-container>
                                                    <ng-container *ngIf="opportunity.type == 'SMS'">
                                                        <nz-tag class="aqua">SMS</nz-tag>
                                                    </ng-container>
                                                    <ng-container *ngIf="opportunity.type == 'SOCIALPOST'">
                                                        <nz-tag class="aqua">Social post</nz-tag>
                                                    </ng-container>
                                                    <div style="float: right; line-height: 26px;">
                                                        {{opportunity.sendTime
                                                        | date:'shortDate'}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <nz-pagination [nzTotal]="opportunities.length" [nzPageSize]="52"
                    (nzPageIndexChange)="this.pageIndexChange($event)" style="float: right;"></nz-pagination>
            </ng-container>
            <ng-container *ngIf="this.viewMode == 1">
                <div class="custom-card" style="height: 100%;">
                    <div class="custom-card-header"
                        style="margin: 0px 0px; border-bottom: none; padding: 0px !important;">
                        <div nz-row class="table-row" [nzGutter]="16" style="padding: 0px 24px !important">
                            <div nz-col class="gutter-row" nzFlex="50px">
                                <div class="inner-box"><i class="lni lni-image" style="font-size: 16px;
                                    float: left;
                                    margin-top: 20px;
                                    margin-left: 5px;"></i></div>
                            </div>
                            <div nz-col class="gutter-row" nzFlex="auto">
                                <div class="inner-box">Title</div>
                            </div>
                            <div nz-col class="gutter-row" nzFlex="180px">
                                <div class="inner-box">Type</div>
                            </div>
                            <div nz-col class="gutter-row" nzFlex="180px">
                                <div class="inner-box">Status</div>
                            </div>
                            <!-- <div nz-col class="gutter-row" nzFlex="100px">
                                <div class="inner-box">Open rate</div>
                            </div>
                            <div nz-col class="gutter-row" nzFlex="100px">
                                <div class="inner-box">Click rate</div>
                            </div>
                            <div nz-col class="gutter-row" nzFlex="100px">
                                <div class="inner-box">Revenue</div>
                            </div> -->
                            <div nz-col class="gutter-row" nzFlex="140px">
                                <div class="inner-box">Send time</div>
                            </div>
                            <div nz-col class="gutter-row" nzFlex="140px">
                                <div class="inner-box">Updated</div>
                            </div>
                        </div>
                    </div>
                    <div class="custom-card-body table-card">
                        <ng-container *ngFor="let opportunity of opportunities; index as index">
                            <ng-container
                                *ngIf="index >= (this.currentPage - 1) * 50 && index < (this.currentPage * 50)">
                                <div nz-row [nzGutter]="16" class="table-row"
                                    [routerLink]="['/opportunities',opportunity._id, 'e']">

                                    <div nz-col class="gutter-row" nzFlex="0 1 50px" style="width: 42px !important;">
                                        <ng-container *ngIf="opportunity.thumbnail">
                                            <div class="email-thumbnail mini"
                                                [ngStyle]="{'background-image': 'url(' + opportunity.thumbnail + ')'}">
                                            </div>


                                        </ng-container>
                                        <ng-container *ngIf="!opportunity.thumbnail">
                                            <div class="email-thumbnail mini"
                                                style="background: #f9f9f9; text-align: center; font-size: 16px; padding-top: 0px; color: #d9d9d9">
                                                <i class="lni lni-image"></i>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div nz-col class="gutter-row" nzFlex="1 1 auto"
                                        style="color: #374151; font-weight: 500;">
                                        <div
                                            style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; max-width: 300px;">
                                            <ng-container
                                                *ngIf="opportunity.contentTemplate && opportunity.contentTemplate.subjectLine">
                                                {{opportunity.contentTemplate.subjectLine}}
                                            </ng-container>
                                            <ng-container
                                                *ngIf="!opportunity.contentTemplate || !opportunity.contentTemplate.subjectLine">
                                                {{opportunity.title}}
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div nz-col class="gutter-row" nzFlex="0 1 180px">
                                        <ng-container *ngIf="opportunity.type == 'CAMPAIGN'">
                                            <nz-tag class="aqua">Email</nz-tag>
                                        </ng-container>
                                        <ng-container *ngIf="opportunity.type == 'SMS'">
                                            <nz-tag class="aqua">SMS</nz-tag>
                                        </ng-container>
                                        <ng-container *ngIf="opportunity.type == 'SOCIALPOST'">
                                            <nz-tag class="aqua">Social post</nz-tag>
                                        </ng-container>
                                    </div>
                                    <div nz-col class="gutter-row" nzFlex="0 1 180px">
                                        <ng-container
                                            *ngIf="opportunity.stage == 'LIVE' && sharedService.timeInFuture(opportunity.sendTime)">
                                            <nz-tag class="aqua">Scheduled</nz-tag>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="opportunity.stage == 'LIVE' && !sharedService.timeInFuture(opportunity.sendTime)">
                                            <nz-tag class="aqua">Sent</nz-tag>
                                        </ng-container>
                                        <ng-container *ngIf="opportunity.stage == 'PENDING'">
                                            <nz-tag>Draft</nz-tag>
                                        </ng-container>
                                        <ng-container *ngIf="opportunity.stage == 'FAILED'">
                                            <nz-tag [nzColor]="'red'">Failed&nbsp;&nbsp;<span nz-icon nzType="stop"
                                                    nzTheme="outline"></span></nz-tag><button nzType="text" nz-button
                                                (click)="this.sharedService.tryJobAgain(opportunity)"><span nz-icon
                                                    nzType="sync" nzTheme="outline"></span>Try again</button>
                                        </ng-container>
                                        <ng-container *ngIf="opportunity.stage == 'INPROGRESS'">
                                            <nz-tag class="aqua">Generating&nbsp;&nbsp;<span nz-icon nzType="loading"
                                                    nzTheme="outline" [nzSpin]="true"></span></nz-tag>
                                        </ng-container>
                                        <ng-container *ngIf="opportunity.stage == 'NEW'">
                                            <nz-tag>Waiting&nbsp;&nbsp;<span nz-icon nzType="pause-circle"
                                                    nzTheme="outline"></span></nz-tag>
                                        </ng-container>
                                    </div>
                                    <!-- <div nz-col class="gutter-row" nzFlex="0 1 100px">
                                        <ng-container
                                            *ngIf="opportunity?.metrics?.CAMPAIGNOPENRATE && (opportunity?.metrics?.CAMPAIGNOPENRATE.value || opportunity?.metrics?.CAMPAIGNOPENRATE.value == 0)">
                                            <nz-tag>{{opportunity.metrics.CAMPAIGNOPENRATE.value |
                                                percent}}</nz-tag>
                                            <ng-container *ngIf="opportunity.loadingOpenRate">
                                                <span nz-icon [nzType]="'sync'" [nzSpin]="true"></span>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="!opportunity?.metrics?.CAMPAIGNOPENRATE || (!opportunity?.metrics?.CAMPAIGNOPENRATE.value && opportunity?.metrics?.CAMPAIGNOPENRATE.value != 0)">
                                            <ng-container *ngIf="opportunity.loadingOpenRate">
                                                <nz-skeleton-element nzType="input" [nzActive]="true" nzSize="large"
                                                    style="width: 36px !important; overflow: hidden; margin-top: 14px; height: 26px !important"></nz-skeleton-element>
                                            </ng-container>
                                            <ng-container *ngIf="!opportunity.loadingOpenRate">
                                                -
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div nz-col class="gutter-row" nzFlex="0 1 100px">
                                        <ng-container
                                            *ngIf="opportunity?.metrics?.CAMPAIGNCLICKRATE && opportunity?.metrics?.CAMPAIGNCLICKRATE.value">
                                            <nz-tag>{{opportunity.metrics.CAMPAIGNCLICKRATE.value |
                                                percent}}</nz-tag>
                                            <ng-container *ngIf="opportunity.loadingClickRate">
                                                <span nz-icon [nzType]="'sync'" [nzSpin]="true"></span>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="!opportunity?.metrics?.CAMPAIGNCLICKRATE || !opportunity?.metrics?.CAMPAIGNCLICKRATE.value">
                                            <ng-container *ngIf="opportunity.loadingClickRate">
                                                <nz-skeleton-element nzType="input" [nzActive]="true" nzSize="large"
                                                    style="width: 36px !important; overflow: hidden; margin-top: 14px; height: 26px !important"></nz-skeleton-element>
                                            </ng-container>
                                            <ng-container *ngIf="!opportunity.loadingClickRate">
                                                -
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div nz-col class="gutter-row" nzFlex="0 1 100px">
                                        <ng-container
                                            *ngIf="opportunity?.metrics?.CAMPAIGNATTRIBUTEDORDERS && opportunity?.metrics?.CAMPAIGNATTRIBUTEDORDERS.value">
                                            <nz-tag>{{opportunity.metrics.CAMPAIGNATTRIBUTEDORDERS.value |
                                                number}}</nz-tag>
                                            <ng-container *ngIf="opportunity.loadingConversionRate">
                                                <span nz-icon [nzType]="'sync'" [nzSpin]="true"></span>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="!opportunity?.metrics?.CAMPAIGNATTRIBUTEDORDERS || !opportunity?.metrics?.CAMPAIGNATTRIBUTEDORDERS.value">
                                            <ng-container *ngIf="opportunity.loadingConversionRate">
                                                <nz-skeleton-element nzType="input" [nzActive]="true" nzSize="large"
                                                    style="width: 36px !important; overflow: hidden; margin-top: 14px; height: 26px !important"></nz-skeleton-element>
                                            </ng-container>
                                            <ng-container *ngIf="!opportunity.loadingConversionRate">
                                                -
                                            </ng-container>
                                        </ng-container>
                                    </div> -->
                                    <div nz-col class="gutter-row" nzFlex="0 1 140px">
                                        {{opportunity.sendTime | date}}
                                    </div>
                                    <div nz-col class="gutter-row" nzFlex="0 1 140px">
                                        {{opportunity.updatedAt | date}}
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div class="custom-card-footer" style="padding: 8px 16px;">
                        <nz-pagination [nzTotal]="opportunities.length" [nzPageSize]="50"
                            (nzPageIndexChange)="this.pageIndexChange($event)"></nz-pagination>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>