<div class="page-wrapper">
    <img class="logo-signup" src="../../../assets/rippleLogo.png" />

    <div class="content-wrapper">
        <h2 nz-typography class="setup-title">You're almost done!</h2>
        <div class="subtitle"><img class="icon" src="https://cdn.cookielaw.org/logos/3e565b06-47fd-45de-97fa-9f31af85ede5/33382758-db4f-42f4-a76d-7ef4bea5be62/e815cd95-d604-40f1-a5a6-2ee7938e7402/klaviyo-flag-mark-charcoal-small.png" />Connect your Klaviyo account to complete setup.</div>
        <form nz-form nzLayout="vertical">
            <nz-form-item>
                <nz-form-label nzNoColon="true" nzLabelAlign="left" nzSpan="8">Klaviyo API Key</nz-form-label>
                <nz-form-control nzSpan="24">
                    <input [ngModelOptions]="{standalone: true}" [(ngModel)]="this.klaviyoObject.accessToken" nz-input placeholder="pk_xxxxx" />
                </nz-form-control>
            </nz-form-item>
        </form>
        <button nz-button nzType="primary" (click)="createIntegration()" nzBlock style="margin-bottom: 24px;">Continue</button>
        <a><div class="disclaimer">Find your Klaviyo API key here</div></a>

    </div>
</div>