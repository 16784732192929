<div class="page-wrapper">
    <img class="logo-signup" src="../../../assets/rippleLogo.png" />
    <div class="content-wrapper">
        <h2 nz-typography class="setup-title">Setup complete!</h2>

        <div class="rippleLoaderWrapper" style="margin-top: 60px;">
            <div class="rippleLoader">
                <div></div>
                <div></div>
            </div>
        </div>
        <div class="subtitle" style="font-weight: 500;">Ripple is identifying opportunities for your brand.<br>This may
            take a
            moment.</div>
    </div>
</div>