import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SharedService } from '../shared.service';
import { NzSegmentedOption } from 'ng-zorro-antd/segmented';

@Component({
  selector: 'app-email-flows',
  templateUrl: './email-flows.component.html',
  styleUrls: ['./email-flows.component.scss']
})
export class EmailFlowsComponent {

  opportunities: any = [];
  loading = true;
  loadingNewOpps = true;
  newOpportunities: any = [];
  currentPage = 1;
  viewMode: any = 0;
  options = [
    { label: '', value: 'GRID', icon: 'appstore' },
    { label: '', value: 'TABLE', icon: 'unordered-list' }
  ];
  flowTypes: any = [
    {
      name: "Abandoned cart",
      icon: "lni lni-cart",
      prompt: "Please make an abandoned cart flow that reminds the user to come back and place their order.",
      properties: [
        {
          type: "INPUT",
          name: "Number of emails",
          value: "3",
          prefix: "Here is the number of emails in the flow"
        },
        {
          type: "RICHTEXT",
          name: "Offer (optional)",
          value: "",
          prefix: "Here is the offer to include in the flow"
        },
        {
          type: "RICHTEXT",
          name: "Additional instructions (optional)",
          value: "",
          prefix: "Here are some additional instructions"
        }
      ]
    },
    {
      name: "Abandoned browse",
      icon: "lni lni-exit",
      prompt: "Make me a catalog overview email",
      properties: [
        {
          type: "RICHTEXT",
          name: "Products or collections to highlight",
          value: "",
          prefix: "Here are the products/collections to feature"
        },
        {
          type: "RICHTEXT",
          name: "Angle (optional)",
          value: "",
          prefix: "Here is the angle of the email"
        },
        {
          type: "RICHTEXT",
          name: "Additional context (optional)",
          value: "",
          prefix: "Here is some additional info"
        }
      ]
    },
    {
      name: "Welcome series",
      icon: "lni lni-handshake",
      prompt: "Make me a review highlight email",
      properties: [
        {
          type: "RICHTEXT",
          name: "Topic",
          value: "",
          prefix: "Here is the topic of the email"
        },
        {
          type: "RICHTEXT",
          name: "Additional context (optional)",
          value: "",
          prefix: "Here is some additional info"
        }
      ]
    },
    {
      name: "Winback series",
      icon: "lni lni-reload",
      prompt: "Make me an email based on a blog post / article",
      properties: [
        {
          type: "RICHTEXT",
          name: "Source content",
          value: "",
          prefix: "Here is the source content for the email"
        },
        {
          type: "RICHTEXT",
          name: "Additional context (optional)",
          value: "",
          prefix: "Here is some additional info"
        }
      ]
    },
    {
      name: "Post-purchase series",
      icon: "lni lni-money-location",
      prompt: "Make me an educational email",
      properties: [
        {
          type: "RICHTEXT",
          name: "Topic",
          value: "",
          prefix: "Here is the topic for the email"
        },
        {
          type: "RICHTEXT",
          name: "Additional context (optional)",
          value: ""
        }
      ]
    },
    {
      name: "Refill reminder",
      icon: "lni lni-protection",
      prompt: "Make me an email for a sale / promotion",
      properties: [
        {
          type: "INPUT",
          name: "Sale / promotion name",
          value: "",
          prefix: "Here is the name of the sale / promotion"
        },
        {
          type: "RICHTEXT",
          name: "Offer details",
          value: "",
          prefix: "Here are the details of the offer"
        },
        {
          type: "INPUT",
          name: "Dates (optional)",
          value: "",
          prefix: "Here are the dates of the offer"
        },
        {
          type: "INPUT",
          name: "Promo code (optional)",
          value: "",
          prefix: "Here is the promo code to use for the offer"
        },
        {
          type: "RICHTEXT",
          name: "Additional context (optional)",
          value: "",
          prefix: "Here is some additional info"
        }
      ]
    }
  ];
  currentEmailType: any = {};
  emailOptions: any = false;

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public sharedService: SharedService
  ) {

  }



  ngOnInit() {
    this.apiService.getFlows().subscribe(
      (data: any) => {
        console.log(data);
        this.opportunities = data;
        this.loading = false;
      },
      error => {
        console.log(error);
      });

    // this.apiService.getNewOpportunities().subscribe(
    //   (data: any) => {
    //     console.log(data);
    //     this.newOpportunities = data;
    //     this.loadingNewOpps = false;
    //   },
    //   error => {
    //     console.log(error);
    //   });
  }

  pageIndexChange(event: any) {
    console.log(event);
    this.currentPage = event;

    const element: any = document.querySelector('#goUp');
    element.scrollIntoView();
  }

  changedView(event: any) {
    console.log(event);
  }


  chooseFlowType(type: any) {
    this.currentEmailType = type;
    this.emailOptions = true;
  }

  async generateFlow() {

    let promptToUse = this.currentEmailType.prompt;
    for (let property of this.currentEmailType.properties) {
      if (property.value) {
        if (property.type == "PRODUCTLIST") {
          let productStringList = "";
          for (let product of property.value) {
            productStringList += `${product.title}, `;
          }
          promptToUse += `, ${property.prefix}: ${productStringList}`;
        } else {
          promptToUse += `, ${property.prefix}: ${property.value}`;
        }
      }
    }

    let flowReturned: any = await this.apiService.generateFlow(promptToUse).toPromise();
    console.log(flowReturned);
    this.router.navigate([`/flows/${flowReturned._id}`]);
  }

  openNewFlow() {
    this.sharedService.newFlowFolderOpen = true;
  }
}
