import { ChangeDetectorRef, Injectable } from '@angular/core';
import * as htmlToImage from 'html-to-image';
import { ApiService } from './api.service';
import { Subscription } from 'rxjs';
import { interval } from "rxjs/internal/observable/interval";
import { startWith, takeWhile } from 'rxjs/operators';
import { Observable, timer, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Route, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  INTERVAL = 2000;  // <-- poll every 5 seconds
  closeTimer$ = new Subject<void>();
  currentProperty: any = {};
  pickerOpen: any = false;
  collectionPickerOpen: any = false;
  pickerMode: any = "";
  blockLibraryOpen: any = false;
  blockLibraryV2Open: any = false;
  currentBlock: any = {};
  currentTemplate: any = {};
  blockIndex: any = 0;
  blockLibraryMode = "";
  workspace: any = {};
  newOpen: any = false;
  newDate: any = null;
  currentUser: any = {};
  publishingEmailOpen: any = false;
  calendarData: any = [];
  calendarMonth: number = (new Date().getMonth());
  calendarYear: number = (new Date().getFullYear());
  loadingCalendar: any = true;
  pickerNumber: any = "SINGLE";
  pendingOpps: any = [];
  rendererOpportunityObject: any = {};
  brandLogo: any = "";
  databaseEditorOpen: any = false;
  databaseEditorType: any = "";
  databaseEditorObject: any = {};
  databaseEditorObjectFull: any = {};
  loadingRenderer: any = true;
  inviteUserOpen: any = false;
  htmlOpen: any = false;
  htmlBody: any = "";
  fonts = [
    { "value": "'ador-hairline', sans-serif" },
    { "value": `Altmark` },
    { "value": `'Amsterdam One', serif` },
    { "value": `'Antique Olive', sans-serif` },
    { "value": `'Antonio', sans-serif` },
    { "value": `'Archivo', sans-serif` },
    { "value": `'Arial', sans-serif` },
    { "value": `"Arsenal", sans-serif` },
    { "value": `'Aspekta', sans-serif` },
    { "value": `'Assistant', sans-serif;` },
    { "value": `"Austin News Deck", serif` },
    { "value": `Avenir Next Rounded W04 Medium` },
    { "value": `'Awesome Serif', serif` },
    { "value": `'Book Antiqua', serif` },
    { "value": `'Cabin', sans-serif` },
    { "value": `'Cardo', serif` },
    { "value": `'Catamaran', sans-serif` },
    { "value": `'Chivo', sans-serif` },
    { "value": `'Clarice', serif` },
    { "value": `'Clarice book', serif` },
    { "value": `'Cormorant Garamond', serif` },
    { "value": `'Cy Grotesk', sans-serif` },
    { "value": `'Darker Grotesque', sans-serif` },
    { "value": `'DM Sans', sans-serif` },
    { "value": `'Droid Serif', sans-serif` },
    { "value": `'EB Garamond', serif` },
    { "value": `'Eczar', serif` },
    { "value": `Editors note` },
    { "value": "'Fjalla One', sans-serif" },
    { "value": `Foco` },
    { "value": `FoundersGrotesk-Web` },
    { "value": `Frequenz` },
    { "value": `'Futura PT', sans-serif` },
    { "value": `'Gambarino-Regular'` },
    { "value": `'Gilroy Black', sans-serif` },
    { "value": `'gopher', serif` },
    { "value": `'Gotham', sans-serif` },
    { "value": `'Gotham Book', sans-serif` },
    { "value": `'Gotham Condensed', sans-serif` },
    { "value": `'Gotham Extra Narrow', sans-serif` },
    { "value": `'Glacial Indifference', sans-serif` },
    { "value": `'Glance sans', sans-serif` },
    { "value": `Jack and Sally` },
    { "value": `'Josefin Sans', sans-serif` },
    { "value": `'Harmonia sans', sans-serif` },
    { "value": `'Helvetica Neue', sans-serif` },
    { "value": `'IBM Plex Sans', sans-serif` },
    { "value": `'Inter', sans-serif` },
    { "value": `ivyora-display, sans-serif` },
    { "value": `'Kreon', serif` },
    { "value": `Lato, sans-serif` },
    { "value": `'Libre Bodoni', serif` },
    { "value": `"Libre Franklin", sans-serif` },
    { "value": `'Lovelyn', serif` },
    { "value": `'Mabry', serif` },
    { "value": `'Marcellus', serif` },
    { "value": `'Messina Sans', sans-serif` },
    { "value": `'Montserrat', sans-serif` },
    { "value": `'Neue-Plak'` },
    { "value": `'Neuzeit Office Soft Rounded Pro'` },
    { "value": `"Nunito Sans", sans-serif` },
    { "value": `"Open sans", sans-serif` },
    { "value": `'Optima nova LT Pro', sans-serif` },
    { "value": `"Oswald", sans-serif` },
    { "value": `'Panton', sans-serif` },
    { "value": `'Playfair Display', serif` },
    { "value": `'Poppins', sans-serif` },
    { "value": `'PT Mono', monospace` },
    { "value": `'proxima-nova', sans-serif` },
    { "value": `'Questrial', sans-serif` },
    { "value": `'Recoleta', serif` },
    { "value": `'Roboto', sans-serif` },
    { "value": `'Rubik', sans-serif` },
    { "value": `'roc-grotesk', sans-serif` },
    { "value": `Satoshi` },
    { "value": `'Segoe UI', sans-serif` },
    { "value": `"Source Sans Pro", sans-serif` },
    { "value": `'Syne', sans-serif` },
    { "value": `'Tan Aegean', serif` },
    { "value": `The Seasons` },
    { "value": `'Times New Roman', sans-serif` },
    { "value": `'Work Sans', sans-serif` }
  ];
  currentPlanningThread = null;
  currentPlanningSession = null;
  emailChatMode = "EMAIL"
  iconLibrary = {
    "icons": [
      {
        "value": "lni lni-zoom-out"
      },
      {
        "value": "lni lni-zoom-in"
      },
      {
        "value": "lni lni-zip"
      },
      {
        "value": "lni lni-website"
      },
      {
        "value": "lni lni-website-alt"
      },
      {
        "value": "lni lni-users"
      },
      {
        "value": "lni lni-user"
      },
      {
        "value": "lni lni-upload"
      },
      {
        "value": "lni lni-unlink"
      },
      {
        "value": "lni lni-trash-can"
      },
      {
        "value": "lni lni-timer"
      },
      {
        "value": "lni lni-tab"
      },
      {
        "value": "lni lni-star-half"
      },
      {
        "value": "lni lni-star-fill"
      },
      {
        "value": "lni lni-star-empty"
      },
      {
        "value": "lni lni-signal"
      },
      {
        "value": "lni lni-shortcode"
      },
      {
        "value": "lni lni-shopping-basket"
      },
      {
        "value": "lni lni-share"
      },
      {
        "value": "lni lni-share-alt"
      },
      {
        "value": "lni lni-search"
      },
      {
        "value": "lni lni-search-alt"
      },
      {
        "value": "lni lni-scroll-down"
      },
      {
        "value": "lni lni-save"
      },
      {
        "value": "lni lni-rss-feed"
      },
      {
        "value": "lni lni-rocket"
      },
      {
        "value": "lni lni-remove-file"
      },
      {
        "value": "lni lni-reload"
      },
      {
        "value": "lni lni-printer"
      },
      {
        "value": "lni lni-plug"
      },
      {
        "value": "lni lni-package"
      },
      {
        "value": "lni lni-mouse"
      },
      {
        "value": "lni lni-mobile"
      },
      {
        "value": "lni lni-microphone"
      },
      {
        "value": "lni lni-mic"
      },
      {
        "value": "lni lni-menu"
      },
      {
        "value": "lni lni-map"
      },
      {
        "value": "lni lni-map-marker"
      },
      {
        "value": "lni lni-magnifier"
      },
      {
        "value": "lni lni-magnet"
      },
      {
        "value": "lni lni-link"
      },
      {
        "value": "lni lni-laptop"
      },
      {
        "value": "lni lni-laptop-phone"
      },
      {
        "value": "lni lni-keyboard"
      },
      {
        "value": "lni lni-image"
      },
      {
        "value": "lni lni-headphone"
      },
      {
        "value": "lni lni-harddrive"
      },
      {
        "value": "lni lni-game"
      },
      {
        "value": "lni lni-folder"
      },
      {
        "value": "lni lni-files"
      },
      {
        "value": "lni lni-empty-file"
      },
      {
        "value": "lni lni-download"
      },
      {
        "value": "lni lni-domain"
      },
      {
        "value": "lni lni-display"
      },
      {
        "value": "lni lni-display-alt"
      },
      {
        "value": "lni lni-database"
      },
      {
        "value": "lni lni-dashboard"
      },
      {
        "value": "lni lni-cut"
      },
      {
        "value": "lni lni-control-panel"
      },
      {
        "value": "lni lni-cogs"
      },
      {
        "value": "lni lni-cog"
      },
      {
        "value": "lni lni-code"
      },
      {
        "value": "lni lni-code-alt"
      },
      {
        "value": "lni lni-cloud-upload"
      },
      {
        "value": "lni lni-cloud-sync"
      },
      {
        "value": "lni lni-cloud-network"
      },
      {
        "value": "lni lni-cloud-download"
      },
      {
        "value": "lni lni-cloud-check"
      },
      {
        "value": "lni lni-camera"
      },
      {
        "value": "lni lni-calendar"
      },
      {
        "value": "lni lni-bulb"
      },
      {
        "value": "lni lni-bookmark"
      },
      {
        "value": "lni lni-bookmark-alt"
      },
      {
        "value": "lni lni-bluetooth"
      },
      {
        "value": "lni lni-ban"
      },
      {
        "value": "lni lni-archive"
      },
      {
        "value": "lni lni-alarm"
      },
      {
        "value": "lni lni-alarm-clock"
      },
      {
        "value": "lni lni-add-files"
      },
      {
        "value": "lni lni-thunder"
      },
      {
        "value": "lni lni-thunder-alt"
      },
      {
        "value": "lni lni-sun"
      },
      {
        "value": "lni lni-rain"
      },
      {
        "value": "lni lni-night"
      },
      {
        "value": "lni lni-drop"
      },
      {
        "value": "lni lni-cloudy-sun"
      },
      {
        "value": "lni lni-cloud"
      },
      {
        "value": "lni lni-travel"
      },
      {
        "value": "lni lni-surf-board"
      },
      {
        "value": "lni lni-pyramids"
      },
      {
        "value": "lni lni-underline"
      },
      {
        "value": "lni lni-text-format"
      },
      {
        "value": "lni lni-text-format-remove"
      },
      {
        "value": "lni lni-text-align-right"
      },
      {
        "value": "lni lni-text-align-left"
      },
      {
        "value": "lni lni-text-align-justify"
      },
      {
        "value": "lni lni-text-align-center"
      },
      {
        "value": "lni lni-strikethrough"
      },
      {
        "value": "lni lni-spellcheck"
      },
      {
        "value": "lni lni-sort-amount-dsc"
      },
      {
        "value": "lni lni-sort-amount-asc"
      },
      {
        "value": "lni lni-sort-alpha-asc"
      },
      {
        "value": "lni lni-pilcrow"
      },
      {
        "value": "lni lni-pagination"
      },
      {
        "value": "lni lni-page-break"
      },
      {
        "value": "lni lni-line-spacing"
      },
      {
        "value": "lni lni-line-double"
      },
      {
        "value": "lni lni-line-dotted"
      },
      {
        "value": "lni lni-line-dashed"
      },
      {
        "value": "lni lni-italic"
      },
      {
        "value": "lni lni-indent-increase"
      },
      {
        "value": "lni lni-indent-decrease"
      },
      {
        "value": "lni lni-bold"
      },
      {
        "value": "lni lni-spinner"
      },
      {
        "value": "lni lni-spinner-solid"
      },
      {
        "value": "lni lni-spinner-arrow"
      },
      {
        "value": "lni lni-hourglass"
      },
      {
        "value": "lni lni-tounge"
      },
      {
        "value": "lni lni-suspect"
      },
      {
        "value": "lni lni-speechless"
      },
      {
        "value": "lni lni-smile"
      },
      {
        "value": "lni lni-sad"
      },
      {
        "value": "lni lni-happy"
      },
      {
        "value": "lni lni-friendly"
      },
      {
        "value": "lni lni-cool"
      },
      {
        "value": "lni lni-thought"
      },
      {
        "value": "lni lni-t-shirt"
      },
      {
        "value": "lni lni-home"
      },
      {
        "value": "lni lni-gift"
      },
      {
        "value": "lni lni-cup"
      },
      {
        "value": "lni lni-crown"
      },
      {
        "value": "lni lni-briefcase"
      },
      {
        "value": "lni lni-spray"
      },
      {
        "value": "lni lni-flags-alt-1"
      },
      {
        "value": "lni lni-fireworks"
      },
      {
        "value": "lni lni-candy"
      },
      {
        "value": "lni lni-candy-cane"
      },
      {
        "value": "lni lni-cake"
      },
      {
        "value": "lni lni-baloon"
      },
      {
        "value": "lni lni-trees"
      },
      {
        "value": "lni lni-tree"
      },
      {
        "value": "lni lni-sprout"
      },
      {
        "value": "lni lni-mushroom"
      },
      {
        "value": "lni lni-mashroom"
      },
      {
        "value": "lni lni-leaf"
      },
      {
        "value": "lni lni-island"
      },
      {
        "value": "lni lni-flower"
      },
      {
        "value": "lni lni-bug"
      },
      {
        "value": "lni lni-volume"
      },
      {
        "value": "lni lni-volume-mute"
      },
      {
        "value": "lni lni-volume-medium"
      },
      {
        "value": "lni lni-volume-low"
      },
      {
        "value": "lni lni-volume-high"
      },
      {
        "value": "lni lni-video"
      },
      {
        "value": "lni lni-stop"
      },
      {
        "value": "lni lni-shuffle"
      },
      {
        "value": "lni lni-play"
      },
      {
        "value": "lni lni-pause"
      },
      {
        "value": "lni lni-gallery"
      },
      {
        "value": "lni lni-full-screen"
      },
      {
        "value": "lni lni-frame-expand"
      },
      {
        "value": "lni lni-forward"
      },
      {
        "value": "lni lni-backward"
      },
      {
        "value": "lni lni-target-customer"
      },
      {
        "value": "lni lni-seo"
      },
      {
        "value": "lni lni-network"
      },
      {
        "value": "lni lni-keyword-research"
      },
      {
        "value": "lni lni-graph"
      },
      {
        "value": "lni lni-bar-chart"
      },
      {
        "value": "lni lni-warning"
      },
      {
        "value": "lni lni-unlock"
      },
      {
        "value": "lni lni-ticket"
      },
      {
        "value": "lni lni-ticket-alt"
      },
      {
        "value": "lni lni-thumbs-up"
      },
      {
        "value": "lni lni-thumbs-down"
      },
      {
        "value": "lni lni-teabag"
      },
      {
        "value": "lni lni-shield"
      },
      {
        "value": "lni lni-radio-button"
      },
      {
        "value": "lni lni-question-circle"
      },
      {
        "value": "lni lni-protection"
      },
      {
        "value": "lni lni-power-switch"
      },
      {
        "value": "lni lni-pointer"
      },
      {
        "value": "lni lni-plus"
      },
      {
        "value": "lni lni-music"
      },
      {
        "value": "lni lni-more"
      },
      {
        "value": "lni lni-more-alt"
      },
      {
        "value": "lni lni-minus"
      },
      {
        "value": "lni lni-lock"
      },
      {
        "value": "lni lni-lock-alt"
      },
      {
        "value": "lni lni-list"
      },
      {
        "value": "lni lni-lifering"
      },
      {
        "value": "lni lni-key"
      },
      {
        "value": "lni lni-invention"
      },
      {
        "value": "lni lni-information"
      },
      {
        "value": "lni lni-infinite"
      },
      {
        "value": "lni lni-help"
      },
      {
        "value": "lni lni-heart"
      },
      {
        "value": "lni lni-heart-fill"
      },
      {
        "value": "lni lni-hand"
      },
      {
        "value": "lni lni-funnel"
      },
      {
        "value": "lni lni-flag"
      },
      {
        "value": "lni lni-flag-alt"
      },
      {
        "value": "lni lni-eye"
      },
      {
        "value": "lni lni-cross-circle"
      },
      {
        "value": "lni lni-close"
      },
      {
        "value": "lni lni-circle-plus"
      },
      {
        "value": "lni lni-circle-minus"
      },
      {
        "value": "lni lni-checkmark"
      },
      {
        "value": "lni lni-checkmark-circle"
      },
      {
        "value": "lni lni-check-box"
      },
      {
        "value": "lni lni-bolt"
      },
      {
        "value": "lni lni-bolt-alt"
      },
      {
        "value": "lni lni-wheelchair"
      },
      {
        "value": "lni lni-weight"
      },
      {
        "value": "lni lni-syringe"
      },
      {
        "value": "lni lni-sthethoscope"
      },
      {
        "value": "lni lni-slim"
      },
      {
        "value": "lni lni-skipping-rope"
      },
      {
        "value": "lni lni-pulse"
      },
      {
        "value": "lni lni-microscope"
      },
      {
        "value": "lni lni-hospital"
      },
      {
        "value": "lni lni-heart-monitor"
      },
      {
        "value": "lni lni-first-aid"
      },
      {
        "value": "lni lni-dumbbell"
      },
      {
        "value": "lni lni-capsule"
      },
      {
        "value": "lni lni-basketball"
      },
      {
        "value": "lni lni-ambulance"
      },
      {
        "value": "lni lni-service"
      },
      {
        "value": "lni lni-restaurant"
      },
      {
        "value": "lni lni-pizza"
      },
      {
        "value": "lni lni-juice"
      },
      {
        "value": "lni lni-fresh-juice"
      },
      {
        "value": "lni lni-dinner"
      },
      {
        "value": "lni lni-coffee-cup"
      },
      {
        "value": "lni lni-chef-hat"
      },
      {
        "value": "lni lni-burger"
      },
      {
        "value": "lni lni-write"
      },
      {
        "value": "lni lni-world"
      },
      {
        "value": "lni lni-world-alt"
      },
      {
        "value": "lni lni-school-bench"
      },
      {
        "value": "lni lni-school-bench-alt"
      },
      {
        "value": "lni lni-pencil"
      },
      {
        "value": "lni lni-pencil-alt"
      },
      {
        "value": "lni lni-library"
      },
      {
        "value": "lni lni-graduation"
      },
      {
        "value": "lni lni-eraser"
      },
      {
        "value": "lni lni-compass"
      },
      {
        "value": "lni lni-clipboard"
      },
      {
        "value": "lni lni-certificate"
      },
      {
        "value": "lni lni-book"
      },
      {
        "value": "lni lni-blackboard"
      },
      {
        "value": "lni lni-shift-right"
      },
      {
        "value": "lni lni-shift-left"
      },
      {
        "value": "lni lni-pointer-top"
      },
      {
        "value": "lni lni-pointer-right"
      },
      {
        "value": "lni lni-pointer-left"
      },
      {
        "value": "lni lni-pointer-down"
      },
      {
        "value": "lni lni-exit"
      },
      {
        "value": "lni lni-exit-up"
      },
      {
        "value": "lni lni-exit-down"
      },
      {
        "value": "lni lni-enter"
      },
      {
        "value": "lni lni-direction"
      },
      {
        "value": "lni lni-direction-rtl"
      },
      {
        "value": "lni lni-direction-ltr"
      },
      {
        "value": "lni lni-direction-alt"
      },
      {
        "value": "lni lni-chevron-up"
      },
      {
        "value": "lni lni-chevron-up-circle"
      },
      {
        "value": "lni lni-chevron-right"
      },
      {
        "value": "lni lni-chevron-right-circle"
      },
      {
        "value": "lni lni-chevron-left"
      },
      {
        "value": "lni lni-chevron-left-circle"
      },
      {
        "value": "lni lni-chevron-down"
      },
      {
        "value": "lni lni-chevron-down-circle"
      },
      {
        "value": "lni lni-arrows-vertical"
      },
      {
        "value": "lni lni-arrows-horizontal"
      },
      {
        "value": "lni lni-arrow-up"
      },
      {
        "value": "lni lni-arrow-up-circle"
      },
      {
        "value": "lni lni-arrow-top-right"
      },
      {
        "value": "lni lni-arrow-top-left"
      },
      {
        "value": "lni lni-arrow-right"
      },
      {
        "value": "lni lni-arrow-right-circle"
      },
      {
        "value": "lni lni-arrow-left"
      },
      {
        "value": "lni lni-arrow-left-circle"
      },
      {
        "value": "lni lni-arrow-down"
      },
      {
        "value": "lni lni-arrow-down-circle"
      },
      {
        "value": "lni lni-angle-double-up"
      },
      {
        "value": "lni lni-angle-double-right"
      },
      {
        "value": "lni lni-angle-double-left"
      },
      {
        "value": "lni lni-angle-double-down"
      },
      {
        "value": "lni lni-vector"
      },
      {
        "value": "lni lni-ux"
      },
      {
        "value": "lni lni-slice"
      },
      {
        "value": "lni lni-select-cursor"
      },
      {
        "value": "lni lni-pallet"
      },
      {
        "value": "lni lni-move"
      },
      {
        "value": "lni lni-layout"
      },
      {
        "value": "lni lni-layers"
      },
      {
        "value": "lni lni-highlight"
      },
      {
        "value": "lni lni-highlight-alt"
      },
      {
        "value": "lni lni-grid"
      },
      {
        "value": "lni lni-grid-alt"
      },
      {
        "value": "lni lni-crop"
      },
      {
        "value": "lni lni-brush"
      },
      {
        "value": "lni lni-brush-alt"
      },
      {
        "value": "lni lni-yen"
      },
      {
        "value": "lni lni-wallet"
      },
      {
        "value": "lni lni-rupee"
      },
      {
        "value": "lni lni-pound"
      },
      {
        "value": "lni lni-money-protection"
      },
      {
        "value": "lni lni-money-location"
      },
      {
        "value": "lni lni-euro"
      },
      {
        "value": "lni lni-dollar"
      },
      {
        "value": "lni lni-diamond-shape"
      },
      {
        "value": "lni lni-diamond-alt"
      },
      {
        "value": "lni lni-credit-cards"
      },
      {
        "value": "lni lni-coin"
      },
      {
        "value": "lni lni-calculator"
      },
      {
        "value": "lni lni-calculator-alt"
      },
      {
        "value": "lni lni-wheelbarrow"
      },
      {
        "value": "lni lni-trowel"
      },
      {
        "value": "lni lni-shovel"
      },
      {
        "value": "lni lni-ruler"
      },
      {
        "value": "lni lni-ruler-pencil"
      },
      {
        "value": "lni lni-ruler-alt"
      },
      {
        "value": "lni lni-paint-roller"
      },
      {
        "value": "lni lni-paint-bucket-alt"
      },
      {
        "value": "lni lni-helmet"
      },
      {
        "value": "lni lni-hammer"
      },
      {
        "value": "lni lni-construction"
      },
      {
        "value": "lni lni-construction-hammer"
      },
      {
        "value": "lni lni-bricks"
      },
      {
        "value": "lni lni-train"
      },
      {
        "value": "lni lni-train-alt"
      },
      {
        "value": "lni lni-taxi"
      },
      {
        "value": "lni lni-support"
      },
      {
        "value": "lni lni-ship"
      },
      {
        "value": "lni lni-scooter"
      },
      {
        "value": "lni lni-road"
      },
      {
        "value": "lni lni-reply"
      },
      {
        "value": "lni lni-postcard"
      },
      {
        "value": "lni lni-popup"
      },
      {
        "value": "lni lni-plane"
      },
      {
        "value": "lni lni-phone"
      },
      {
        "value": "lni lni-phone-set"
      },
      {
        "value": "lni lni-inbox"
      },
      {
        "value": "lni lni-helicopter"
      },
      {
        "value": "lni lni-envelope"
      },
      {
        "value": "lni lni-comments"
      },
      {
        "value": "lni lni-comments-reply"
      },
      {
        "value": "lni lni-comments-alt-2"
      },
      {
        "value": "lni lni-caravan"
      },
      {
        "value": "lni lni-car"
      },
      {
        "value": "lni lni-car-alt"
      },
      {
        "value": "lni lni-bus"
      },
      {
        "value": "lni lni-bullhorn"
      },
      {
        "value": "lni lni-bubble"
      },
      {
        "value": "lni lni-bridge"
      },
      {
        "value": "lni lni-bi-cycle"
      },
      {
        "value": "lni lni-anchor"
      },
      {
        "value": "lni lni-target"
      },
      {
        "value": "lni lni-target-revenue"
      },
      {
        "value": "lni lni-tag"
      },
      {
        "value": "lni lni-stats-up"
      },
      {
        "value": "lni lni-stats-down"
      },
      {
        "value": "lni lni-stamp"
      },
      {
        "value": "lni lni-revenue"
      },
      {
        "value": "lni lni-quotation"
      },
      {
        "value": "lni lni-pin"
      },
      {
        "value": "lni lni-pie-chart"
      },
      {
        "value": "lni lni-paperclip"
      },
      {
        "value": "lni lni-offer"
      },
      {
        "value": "lni lni-notepad"
      },
      {
        "value": "lni lni-license"
      },
      {
        "value": "lni lni-investment"
      },
      {
        "value": "lni lni-invest-monitor"
      },
      {
        "value": "lni lni-handshake"
      },
      {
        "value": "lni lni-grow"
      },
      {
        "value": "lni lni-delivery"
      },
      {
        "value": "lni lni-customer"
      },
      {
        "value": "lni lni-consulting"
      },
      {
        "value": "lni lni-cart"
      },
      {
        "value": "lni lni-cart-full"
      },
      {
        "value": "lni lni-briefcase-alt"
      },
      {
        "value": "lni lni-apartment"
      },
      {
        "value": "lni lni-agenda"
      },
      {
        "value": "lni lni-zoom"
      },
      {
        "value": "lni lni-zapier"
      },
      {
        "value": "lni lni-youtube"
      },
      {
        "value": "lni lni-ycombinator"
      },
      {
        "value": "lni lni-yahoo"
      },
      {
        "value": "lni lni-xrp"
      },
      {
        "value": "lni lni-xbox"
      },
      {
        "value": "lni lni-wordpress"
      },
      {
        "value": "lni lni-wordpress-fill"
      },
      {
        "value": "lni lni-windows"
      },
      {
        "value": "lni lni-whatsapp"
      },
      {
        "value": "lni lni-wechat"
      },
      {
        "value": "lni lni-webhooks"
      },
      {
        "value": "lni lni-vuejs"
      },
      {
        "value": "lni lni-vs-code"
      },
      {
        "value": "lni lni-volkswagen"
      },
      {
        "value": "lni lni-vmware"
      },
      {
        "value": "lni lni-vk"
      },
      {
        "value": "lni lni-visa"
      },
      {
        "value": "lni lni-vimeo"
      },
      {
        "value": "lni lni-vercel"
      },
      {
        "value": "lni lni-unsplash"
      },
      {
        "value": "lni lni-ubuntu"
      },
      {
        "value": "lni lni-typescript"
      },
      {
        "value": "lni lni-twitter"
      },
      {
        "value": "lni lni-twitter-original"
      },
      {
        "value": "lni lni-twitter-fill"
      },
      {
        "value": "lni lni-twitch"
      },
      {
        "value": "lni lni-tumblr"
      },
      {
        "value": "lni lni-trello"
      },
      {
        "value": "lni lni-toyota"
      },
      {
        "value": "lni lni-tiktok"
      },
      {
        "value": "lni lni-tiktok-alt"
      },
      {
        "value": "lni lni-tesla"
      },
      {
        "value": "lni lni-telegram"
      },
      {
        "value": "lni lni-telegram-original"
      },
      {
        "value": "lni lni-tailwindcss"
      },
      {
        "value": "lni lni-swift"
      },
      {
        "value": "lni lni-svelte"
      },
      {
        "value": "lni lni-stumbleupon"
      },
      {
        "value": "lni lni-stripe"
      },
      {
        "value": "lni lni-steam"
      },
      {
        "value": "lni lni-stackoverflow"
      },
      {
        "value": "lni lni-squarespace"
      },
      {
        "value": "lni lni-spotify"
      },
      {
        "value": "lni lni-spotify-original"
      },
      {
        "value": "lni lni-soundcloud"
      },
      {
        "value": "lni lni-soundcloud-original"
      },
      {
        "value": "lni lni-snapchat"
      },
      {
        "value": "lni lni-slideshare"
      },
      {
        "value": "lni lni-slack"
      },
      {
        "value": "lni lni-slack-line"
      },
      {
        "value": "lni lni-skype"
      },
      {
        "value": "lni lni-sketch"
      },
      {
        "value": "lni lni-signal-app"
      },
      {
        "value": "lni lni-shopify"
      },
      {
        "value": "lni lni-safari"
      },
      {
        "value": "lni lni-reddit"
      },
      {
        "value": "lni lni-react"
      },
      {
        "value": "lni lni-quora"
      },
      {
        "value": "lni lni-python"
      },
      {
        "value": "lni lni-producthunt"
      },
      {
        "value": "lni lni-postman"
      },
      {
        "value": "lni lni-postgresql"
      },
      {
        "value": "lni lni-playstation"
      },
      {
        "value": "lni lni-play-store"
      },
      {
        "value": "lni lni-play-store-fill"
      },
      {
        "value": "lni lni-play-store-alt"
      },
      {
        "value": "lni lni-pinterest"
      },
      {
        "value": "lni lni-php"
      },
      {
        "value": "lni lni-paypal"
      },
      {
        "value": "lni lni-paypal-original"
      },
      {
        "value": "lni lni-patreon"
      },
      {
        "value": "lni lni-paddle"
      },
      {
        "value": "lni lni-opera"
      },
      {
        "value": "lni lni-oculus"
      },
      {
        "value": "lni lni-npm"
      },
      {
        "value": "lni lni-notion"
      },
      {
        "value": "lni lni-nodejs"
      },
      {
        "value": "lni lni-nodejs-alt"
      },
      {
        "value": "lni lni-nissan"
      },
      {
        "value": "lni lni-nextjs"
      },
      {
        "value": "lni lni-netlify"
      },
      {
        "value": "lni lni-netflix"
      },
      {
        "value": "lni lni-mysql"
      },
      {
        "value": "lni lni-mongodb"
      },
      {
        "value": "lni lni-microsoft"
      },
      {
        "value": "lni lni-microsoft-teams"
      },
      {
        "value": "lni lni-microsoft-edge"
      },
      {
        "value": "lni lni-meta"
      },
      {
        "value": "lni lni-meta-2"
      },
      {
        "value": "lni lni-meta-1"
      },
      {
        "value": "lni lni-mercedes"
      },
      {
        "value": "lni lni-medium"
      },
      {
        "value": "lni lni-medium-alt"
      },
      {
        "value": "lni lni-mastercard"
      },
      {
        "value": "lni lni-markdown"
      },
      {
        "value": "lni lni-mailchimp"
      },
      {
        "value": "lni lni-magento"
      },
      {
        "value": "lni lni-linkedin"
      },
      {
        "value": "lni lni-linkedin-original"
      },
      {
        "value": "lni lni-lineicons"
      },
      {
        "value": "lni lni-lineicons-symbol"
      },
      {
        "value": "lni lni-lineicons-symbol-alt"
      },
      {
        "value": "lni lni-lineicons-symbol-alt-2"
      },
      {
        "value": "lni lni-lineicons-alt"
      },
      {
        "value": "lni lni-line"
      },
      {
        "value": "lni lni-laravel"
      },
      {
        "value": "lni lni-kubernetes"
      },
      {
        "value": "lni lni-jsfiddle"
      },
      {
        "value": "lni lni-joomla"
      },
      {
        "value": "lni lni-joomla-original"
      },
      {
        "value": "lni lni-jcb"
      },
      {
        "value": "lni lni-javascript"
      },
      {
        "value": "lni lni-java"
      },
      {
        "value": "lni lni-jamstack"
      },
      {
        "value": "lni lni-jaguar"
      },
      {
        "value": "lni lni-ios"
      },
      {
        "value": "lni lni-instagram"
      },
      {
        "value": "lni lni-instagram-original"
      },
      {
        "value": "lni lni-instagram-fill"
      },
      {
        "value": "lni lni-imdb"
      },
      {
        "value": "lni lni-html5"
      },
      {
        "value": "lni lni-hacker-news"
      },
      {
        "value": "lni lni-grammarly"
      },
      {
        "value": "lni lni-google"
      },
      {
        "value": "lni lni-google-wallet"
      },
      {
        "value": "lni lni-google-pay"
      },
      {
        "value": "lni lni-google-drive"
      },
      {
        "value": "lni lni-google-cloud"
      },
      {
        "value": "lni lni-google-meet"
      },
      {
        "value": "lni lni-goodreads"
      },
      {
        "value": "lni lni-go"
      },
      {
        "value": "lni lni-github"
      },
      {
        "value": "lni lni-github-original"
      },
      {
        "value": "lni lni-git"
      },
      {
        "value": "lni lni-gatsby"
      },
      {
        "value": "lni lni-ford"
      },
      {
        "value": "lni lni-flutter"
      },
      {
        "value": "lni lni-flickr"
      },
      {
        "value": "lni lni-fitbit"
      },
      {
        "value": "lni lni-firefox"
      },
      {
        "value": "lni lni-firefox-original"
      },
      {
        "value": "lni lni-firebase"
      },
      {
        "value": "lni lni-figma"
      },
      {
        "value": "lni lni-facetime"
      },
      {
        "value": "lni lni-facebook"
      },
      {
        "value": "lni lni-facebook-oval"
      },
      {
        "value": "lni lni-facebook-original"
      },
      {
        "value": "lni lni-facebook-messenger"
      },
      {
        "value": "lni lni-facebook-line"
      },
      {
        "value": "lni lni-facebook-fill"
      },
      {
        "value": "lni lni-ethereum"
      },
      {
        "value": "lni lni-envato"
      },
      {
        "value": "lni lni-edge"
      },
      {
        "value": "lni lni-drupal"
      },
      {
        "value": "lni lni-drupal-original"
      },
      {
        "value": "lni lni-dropbox"
      },
      {
        "value": "lni lni-dropbox-original"
      },
      {
        "value": "lni lni-dribbble"
      },
      {
        "value": "lni lni-docker"
      },
      {
        "value": "lni lni-discover"
      },
      {
        "value": "lni lni-discord"
      },
      {
        "value": "lni lni-discord-alt"
      },
      {
        "value": "lni lni-diners-club"
      },
      {
        "value": "lni lni-digitalocean"
      },
      {
        "value": "lni lni-dialogflow"
      },
      {
        "value": "lni lni-dev"
      },
      {
        "value": "lni lni-css3"
      },
      {
        "value": "lni lni-creative-commons"
      },
      {
        "value": "lni lni-cpanel"
      },
      {
        "value": "lni lni-coral"
      },
      {
        "value": "lni lni-connectdevelop"
      },
      {
        "value": "lni lni-coinbase"
      },
      {
        "value": "lni lni-codepen"
      },
      {
        "value": "lni lni-cloudflare"
      },
      {
        "value": "lni lni-cisco"
      },
      {
        "value": "lni lni-chromecast"
      },
      {
        "value": "lni lni-chrome"
      },
      {
        "value": "lni lni-cash-app"
      },
      {
        "value": "lni lni-btc"
      },
      {
        "value": "lni lni-brave"
      },
      {
        "value": "lni lni-bootstrap"
      },
      {
        "value": "lni lni-bootstrap-5"
      },
      {
        "value": "lni lni-bmw"
      },
      {
        "value": "lni lni-bluetooth-original"
      },
      {
        "value": "lni lni-blogger"
      },
      {
        "value": "lni lni-bittorrent"
      },
      {
        "value": "lni lni-bitcoin"
      },
      {
        "value": "lni lni-bitbucket"
      },
      {
        "value": "lni lni-behance"
      },
      {
        "value": "lni lni-behance-original"
      },
      {
        "value": "lni lni-azure"
      },
      {
        "value": "lni lni-aws"
      },
      {
        "value": "lni lni-audi"
      },
      {
        "value": "lni lni-atlassian"
      },
      {
        "value": "lni lni-apple-pay"
      },
      {
        "value": "lni lni-apple-music"
      },
      {
        "value": "lni lni-apple-brand"
      },
      {
        "value": "lni lni-app-store"
      },
      {
        "value": "lni lni-angular"
      },
      {
        "value": "lni lni-angellist"
      },
      {
        "value": "lni lni-android"
      },
      {
        "value": "lni lni-android-original"
      },
      {
        "value": "lni lni-amex"
      },
      {
        "value": "lni lni-amazon"
      },
      {
        "value": "lni lni-amazon-pay"
      },
      {
        "value": "lni lni-amazon-original"
      },
      {
        "value": "lni lni-airtable"
      },
      {
        "value": "lni lni-airbnb"
      },
      {
        "value": "lni lni-adobe"
      },
      {
        "value": "lni lni-500px"
      }
    ]
  };
  emailTypes: any = [
    {
      name: "Product feature",
      icon: "lni lni-bullhorn",
      prompt: "Make me a product feature email",
      category: "PROMOTIONAL",
      properties: [
        {
          type: "PRODUCTLIST",
          name: "Product(s)",
          value: [],
          prefix: "Here is the product to feature"
        },
        {
          type: "RICHTEXT",
          name: "Prompt",
          value: "",
          prefix: "Here is the information about the product"
        }
      ]
    },
    {
      name: "Collection feature",
      icon: "lni lni-grid-alt",
      prompt: "Make me a collection feature email. You must use the collection chosen in a product block, in dynamic/collection mode. Don't use static product mode. Only include one product block that highlights the collection chosen.",
      category: "PROMOTIONAL",
      properties: [
        {
          type: "COLLECTION",
          name: "Collection to feature",
          value: [],
          prefix: "Here is the collection to feature."
        },
        {
          type: "RICHTEXT",
          name: "Prompt",
          value: "",
          prefix: "Here is the information about the product"
        }
      ]
    },
    {
      name: "Review highlight",
      icon: "lni lni-comments",
      prompt: "Make me a review highlight email",
      category: "PROMOTIONAL",
      properties: [
        {
          type: "RICHTEXT",
          name: "Topic",
          value: [],
          prefix: "Here is the topic of the email to focus on"
        }
      ]
    },
    {
      name: "Blog",
      icon: "lni lni-rss-feed",
      prompt: "Make me an email based on a blog post",
      category: "PROMOTIONAL",
      properties: [
        {
          type: "RICHTEXT",
          name: "Source material (copy & paste)",
          value: [],
          prefix: "Here is the source material"
        },
        {
          type: "RICHTEXT",
          name: "Prompt",
          value: [],
          prefix: "Here are instructions from the user"
        }
      ]
    },
    {
      name: "Sale",
      icon: "lni lni-dollar",
      prompt: "Make me an email for a sale",
      category: "PROMOTIONAL",
      properties: [
        {
          type: "INPUT",
          name: "Sale / promotion name",
          value: "",
          prefix: "Here is the name of the sale"
        },
        {
          type: "RICHTEXT",
          name: "Offer details",
          value: "",
          prefix: "Here are the details of the offer"
        },
        {
          type: "INPUT",
          name: "Dates (optional)",
          value: "",
          prefix: "Here are the dates of the offer"
        },
        {
          type: "INPUT",
          name: "Promo code (optional)",
          value: "",
          prefix: "Here is the promo code to use for the offer"
        },
        {
          type: "RICHTEXT",
          name: "Additional context (optional)",
          value: "",
          prefix: "Here is some additional info"
        }
      ]
    }
  ]
  editImageOpen: any = false;
  editImageCurrentImage: any = {};
  editImageBackgroundOpen: any = false;
  editImageBackgroundCurrentImage: any = {};
  reviewPickerOpen: any = false;
  aiModalLoading: any = false;
  aiModalMessage: any = "";
  newFlowFolderOpen: any = false;
  sendTestEmailOpen: any = false;
  sendingPreview: any = false;
  sendTestEmailObject: any = {};
  createTemplateOpen: any = false;
  createTemplateOppId: any = "";
  collectionPickerMode: any = "MULTI";
  createEmailPlaceholderMode: any = false;
  createEmailPlaceholderModeObject: any = {};
  promptFromChat: any = "";
  smsPrompt: any = "";
  socialPrompt: any = "";
  activeComponent: any = null;

  textComponentObject = {
    type: "TEXT",
    title: "Text",
    value: "Add text here",
    isLeaf: true,
    props: {
      color: "#000",
      fontSize: "16px",
      fontWeight: "400",
      textAlign: "left",
      lineHeight: "1.5",
      letterSpacing: "0",
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingTop: "0px",
      paddingBottom: "0px",
      fontFamily: "Arial",
    }
  };

  imageComponentObject = {
    type: "IMAGE",
    title: "Image",
    value: "Add text here",
    isLeaf: true,
    props: {
      color: "#000",
      fontSize: "16px",
      fontWeight: "400",
      textAlign: "left",
      lineHeight: "1.5",
      letterSpacing: "0",
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingTop: "0px",
      paddingBottom: "0px",
      fontFamily: "Arial",
    },
    properties: [
      {
        name: "image",
        value: ""
      }
    ]
  };

  buttonComponentObject = {
    type: "BUTTON",
    title: "Button",
    value: "Add text here",
    isLeaf: true,
    props: {
      color: "#000",
      fontSize: "16px",
      fontWeight: "400",
      textAlign: "left",
      lineHeight: "1.5",
      letterSpacing: "0",
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingTop: "0px",
      paddingBottom: "0px",
      fontFamily: "Arial",
    }
  };

  containerComponentObject = {
    type: "CONTAINER",
    title: "Container",
    value: "Add text here",
    children: [],
    props: {
      color: "#000",
      fontSize: "16px",
      fontWeight: "400",
      textAlign: "left",
      lineHeight: "1.5",
      letterSpacing: "0",
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingTop: "0px",
      paddingBottom: "0px",
      fontFamily: "Arial",
      backgroundMode: "solid",
      backgroundColor: "transparent",
      backgroundPosition: "center",
      backgroundSize: "cover",
      borderWidth: "2",
      borderColor: "#000000",
      shadowColor: "#000000",
      shadowBlur: "0",
      shadowDepth: "5"
    }
  };

  productComponentObject = {
    type: "PRODUCT",
    title: "Product",
    value: "Add text here",
    children: [],
    props: {
      color: "#000",
      fontSize: "16px",
      fontWeight: "400",
      textAlign: "left",
      lineHeight: "1.5",
      letterSpacing: "0",
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingTop: "0px",
      paddingBottom: "0px",
      fontFamily: "Arial",
      backgroundMode: "solid",
      backgroundColor: "transparent",
      backgroundPosition: "center",
      backgroundSize: "cover",
      borderWidth: "2",
      borderColor: "#000000",
      shadowColor: "#000000",
      shadowBlur: "0",
      shadowDepth: "5"
    }
  };

  reviewComponentObject = {
    type: "REVIEW",
    title: "Review",
    value: "Add text here",
    children: [],
    props: {
      color: "#000",
      fontSize: "16px",
      fontWeight: "400",
      textAlign: "left",
      lineHeight: "1.5",
      letterSpacing: "0",
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingTop: "0px",
      paddingBottom: "0px",
      fontFamily: "Arial",
      backgroundMode: "solid",
      backgroundColor: "transparent",
      backgroundPosition: "center",
      backgroundSize: "cover",
      borderWidth: "2",
      borderColor: "#000000",
      shadowColor: "#000000",
      shadowBlur: "0",
      shadowDepth: "5"
    }
  };

  newEditorCurrentOpp: any = {};
  currentComponent: any = null;

  constructor(
    public apiService: ApiService,
    public router: Router,
    private message: NzMessageService,
  ) { }

  setPropertyValue(value: any) {
    console.log(this.currentProperty);
    console.log(value);
    if (this.pickerMode == "MEDIA") {
      if (this.pickerNumber == "SINGLE") {
        console.log(this.currentProperty.value);
        if (this.currentProperty.value || this.currentProperty.value == "") {
          this.currentProperty.value = value[0].url;
        } else if (this.currentProperty.imageUrl || this.currentProperty.imageUrl == "") {
          this.currentProperty.imageUrl = value[0].url;
        } else {
          this.currentProperty = value[0].url;
          console.log(this.currentProperty);
        }
        this.currentProperty.imageUrl = value[0].url;
      } else {
        this.currentProperty.value = this.currentProperty.value.concat(value);
      }
      // if (this.currentProperty.value) {
      //   this.currentProperty.value = value.url;
      // } else {
      //   this.currentProperty.value = value.url;
      // }
      console.log(this.currentProperty);
    }

    if (this.pickerMode == "PRODUCTS") {
      if (this.pickerNumber == "SINGLE") {
        this.currentProperty.value.push(value[0]);
      } else {
        this.currentProperty.value = this.currentProperty.value.concat(value);
      }
    }
  }

  setPropertyValueDynamic(value: any) {
    console.log("SET VALUE DYNAMIC");
    this.currentProperty.value = value[0];
    console.log(this.currentProperty);
    this.applyDynamicValues(this.currentProperty, this.currentProperty.value);
    this.pickerOpen = false;
  }

  openNew(date: any) {
    if (date) {
      if (!this.dateInPast(date)) {
        console.log(date);
        this.newDate = new Date(Date.parse(date));
        console.log(this.newDate.toISOString());
        this.newDate.setMinutes(this.newDate.getMinutes() + this.newDate.getTimezoneOffset());
        // this.newDate.setDate(this.newDate.getDate() + 1);
        // this.newDate = Date.UTC(this.newDate.getUTCFullYear(), this.newDate.getUTCMonth(),
        // this.newDate.getUTCDate(), this.newDate.getUTCHours(),
        // this.newDate.getUTCMinutes(), this.newDate.getUTCSeconds());
        // this.newDate = new Date(this.newDate);
        console.log(this.newDate);
        this.newDate.setHours(11, 0, 0);
        this.newOpen = true;
      }

    } else {
      this.newDate = new Date();
      this.newDate.setDate(this.newDate.getDate() + 1);
      this.newDate.setHours(11, 0, 0);
      this.newOpen = true;
    }


  }

  async getBlockImages(opportunityObject: any) {
    // Loop through blocks
    for (let block in opportunityObject.contentTemplate.blocks) {
      //Set current block
      let currentBlock = opportunityObject.contentTemplate.blocks[block];

      if (currentBlock.type == "NAVBAR") {

        for (let subBlock in this.getPropertyValue(currentBlock, 'Items')) {
          let dataUrl = await htmlToImage.toPng(currentBlock.images[subBlock], { pixelRatio: 2 });
          currentBlock.images[subBlock] = dataUrl;
        }
      } else if (currentBlock.type == "PRODUCT") {
        for (let subBlock in this.getPropertyValue(currentBlock, 'Products')) {
          let dataUrl = await htmlToImage.toPng(currentBlock.images[subBlock], { pixelRatio: 2 });
          currentBlock.images[subBlock] = dataUrl;
        }
      } else {
        let dataUrl = await htmlToImage.toPng(currentBlock.images[0], { pixelRatio: 2 });
        currentBlock.images[0] = dataUrl;
      }
    }

    this.apiService.publishOpportunity(opportunityObject._id, opportunityObject, "TEMPLATE").subscribe(
      (data: any) => {
        console.log(data);
        this.publishingEmailOpen = false;
      },
      error => {
        console.log(error);
      });
  }


  getPropertyValue(block: any, propertyName: any) {
    // console.log("get property value");
    let property = block.properties.filter((obj: any) => {
      return obj.name === propertyName;
    });
    // console.log(property);

    return property[0].value;
  }

  getProperty(propertyName: any, properties: any) {
    // console.log("get property value");
    let property = properties.filter((obj: any) => {
      return obj.name === propertyName;
    });
    // console.log(property);

    return property[0];
  }

  timeInFuture(date: any) {
    let currentDate = new Date();
    let sendTime = new Date(date);

    if (currentDate < sendTime) {
      return true;
    } else {
      return false;
    }
  }

  fetchCalendar() {

    this.apiService.getEmailCampaignCalendar(this.calendarMonth, this.calendarYear).subscribe(
      (data: any) => {
        console.log(data);
        this.calendarData = data;
        this.loadingCalendar = false;
      },
      error => {
        console.log(error);
      });
  }

  removeItemFromArray(index: any, list: Array<any>) {
    list.splice(index, 1);
  }

  getArrayItem(array: any, propertyName: any, propertyValue: any) {
    let property = array.filter((obj: any) => {
      return obj[propertyName] === propertyValue;
    });

    if (property[0]) {
      return property[0];
    } else {
      return "";
    }
  }

  openPicker(property: any, mode: any, number: any) {
    console.log("Open Picker");
    console.log(mode);
    this.currentProperty = property;
    this.pickerMode = mode;
    this.pickerNumber = number;
    this.pickerOpen = true;
  }

  openCollectionPicker(property: any) {
    console.log("Open Collection Picker");
    this.currentProperty = property;
    // this.pickerMode = mode;
    // this.pickerNumber = number;
    this.collectionPickerOpen = true;
  }

  checkForGeneratingEmails() {
    timer(0, this.INTERVAL).pipe(      // <-- start immediately and poll every `INTERVAL` seconds
      switchMap(() => this.apiService.checkEmailJobs()),  // <-- map to another observable
      takeUntil(this.closeTimer$)   // <-- close the subscription when `closeTimer$` emits
    ).subscribe({
      next: (res: any) => {
        console.log("checked generating status: ", res);
        for (let opp of res) {
          // this.fetchCalendar();
          // this.message.create("success", `Your email has finished generating`);

          let oppInArray = this.getArrayItem(this.pendingOpps, "_id", opp._id);

          if (!oppInArray) {
            this.pendingOpps.push(opp);
            if (opp.status == "INPROGRESS") {
              this.fillProgressBar(opp.currentProgress, 120);
            }
          } else if (oppInArray.status != opp.status) {
            oppInArray = opp;
            if (opp.status == "COMPLETE") {
              this.message.create("success", `Your email has finished generating`);
            };

            if (opp.status == "FAILED") {
              this.message.create("error", `There was an error generating your email`);
            }

            if (opp.status == "INPROGRESS") {
              this.fillProgressBar(oppInArray.currentProgress, 120);
            }
          }
        }


        // if (!res.pending || res.pending.length == 0) {
        //   this.closeTimer$.next();  // <-- stop polling
        //   // this.router.navigate([`/editor/${res._id}`]);
        // }
      },
      error: (error: any) => {
        // handle errors
        // note that any errors would stop the polling here
      }
    });
  }

  dateInPast(date: any) {

    let adjustedDate = new Date(Date.parse(date));
    adjustedDate.setMinutes(adjustedDate.getMinutes() + adjustedDate.getTimezoneOffset());

    if ((adjustedDate.getDate() < new Date().getDate() && adjustedDate.getMonth() <= new Date().getMonth() && adjustedDate.getFullYear() <= new Date().getFullYear()) || (adjustedDate.getMonth() < new Date().getMonth() && adjustedDate.getFullYear() <= new Date().getFullYear()) || adjustedDate.getFullYear() < new Date().getFullYear()) {
      return true;
    } else {
      return false;
    }
  }

  openDatabaseEditor(obj: any, type: any) {
    this.databaseEditorType = type;
    this.databaseEditorObject = JSON.stringify(obj.data);
    this.databaseEditorObjectFull = obj;
    this.databaseEditorOpen = true;
  }

  closeDatabaseEditor() {

    this.databaseEditorOpen = false;
  }

  hoverItem(item: any) {
    this.loadingRenderer = true;
    this.rendererOpportunityObject = item;
  }

  fillProgressBar(currentProgress: any, durationInSeconds: number) {
    const totalSteps = 100; // Total steps to reach 100%
    const stepsPerSecond = totalSteps / durationInSeconds;
    const updateInterval = 1000; // Update the progress every second

    const updateProgress = () => {
      if (currentProgress < totalSteps) {
        currentProgress += stepsPerSecond;
        setTimeout(updateProgress, updateInterval);
      } else {
        currentProgress = totalSteps; // Ensure it reaches 100%
      }
    };

    setTimeout(updateProgress, updateInterval);
  }

  tryJobAgain(job: any) {
    job.status = "INPROGRESS";
    this.apiService.tryJobAgain(job._id).subscribe(res => {

    });
  }

  openInviteUser() {
    this.inviteUserOpen = true;
  }

  async checkOpportunityStatus(opportunity: any, status: any) {
    const intervalId = setInterval(() => {
      return this.apiService.getOpportunity(opportunity).subscribe(
        (data: any) => {
          console.log(data);
          if (data.stage == status) {
            clearInterval(intervalId);
            return data;
          }
        });
    }, 2000);
  }

  setActiveComponent(event: any, component: any, block: any = null) {
    console.log(component);
    component.key = this.generateRandomKey(20);
    this.activeComponent = component;
    if (block) {
      this.currentBlock = block;
    } 
    event.stopPropagation();
  }

  findAndInsert(array: any, targetKey: any, newObject: any) {
    newObject.key = this.generateRandomKey(20);

    function recursiveSearchAndInsert(arr: any, targetKey: any, newObject: any) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].key === targetKey) {
          arr.splice(i + 1, 0, newObject); // Insert new object after the found object
          return true; // Found and inserted, end recursion
        } else if (arr[i].children && arr[i].children.length > 0) {
          const found = recursiveSearchAndInsert(arr[i].children, targetKey, newObject);
          if (found) return true; // End recursion if found
        }
      }
      return false; // Not found
    }

    recursiveSearchAndInsert(array, targetKey, newObject);
  }

  addText(id: any) {
    this.findAndInsert(this.newEditorCurrentOpp.contentTemplate.template.children, id, JSON.parse(JSON.stringify(this.textComponentObject)));
  }

  addImage(id: any) {
    this.findAndInsert(this.newEditorCurrentOpp.contentTemplate.template.children, id, JSON.parse(JSON.stringify(this.imageComponentObject)));
  }

  addButton(id: any) {
    this.findAndInsert(this.newEditorCurrentOpp.contentTemplate.template.children, id, JSON.parse(JSON.stringify(this.buttonComponentObject)));
  }

  addContainer(id: any) {
    this.findAndInsert(this.newEditorCurrentOpp.contentTemplate.template.children, id, JSON.parse(JSON.stringify(this.containerComponentObject)));
  }

  addProduct(id: any) {
    this.findAndInsert(this.newEditorCurrentOpp.contentTemplate.template.children, id, JSON.parse(JSON.stringify(this.productComponentObject)));
  }

  addReview(id: any) {
    this.findAndInsert(this.newEditorCurrentOpp.contentTemplate.template.children, id, JSON.parse(JSON.stringify(this.reviewComponentObject)));
  }

  generateRandomKey(length: any) {
    console.log("GENERATE KEY");
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  findAndDelete(array: any, targetKey: any) {
    function recursiveSearchAndDelete(arr: any, targetKey: any) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].key === targetKey) {
          arr.splice(i, 1); // Delete the object with the matching key
          return true; // Found and deleted, end recursion
        } else if (arr[i].children && arr[i].children.length > 0) {
          const found = recursiveSearchAndDelete(arr[i].children, targetKey);
          if (found) return true; // End recursion if found
        }
      }
      return false; // Not found
    }

    recursiveSearchAndDelete(array, targetKey);
  }

  insertDuplicateComponent(id: any, component: any) {
    delete component.quillEditor;

    function removeQuillEditor(component: any) {
      delete component.quillEditor;

      if (component.children) {
        for (let child of component.children) {
          removeQuillEditor(child);
        }
      }
    }

    if (component.children) {
      for (let child of component.children) {
        removeQuillEditor(child);
      }
    }

    let newComponent = JSON.parse(JSON.stringify(component));

    const newRandomKey = (component: any) => {
      component.key = this.generateRandomKey(20);

      if (component.children) {
        for (let child of component.children) {
          newRandomKey(child);
        }
      }
    };

    newRandomKey(newComponent);

    this.findAndInsert(this.newEditorCurrentOpp.contentTemplate.template.children, id, newComponent);
  }

  applyDynamicValues(component: any, value: any) {
    console.log("APPLY DYNAMIC VALUES");
    console.log(component);
    console.log(value);

    if (component.props.dynamicContent) {
      if (component.type == "CONTAINER") {
        component.imageUrl = value[component.props.dynamicContentValue];
      }

      if (component.type == "TEXT") {
        component.value = value[component.props.dynamicContentValue];
      }
    }

    if (component.children) {
      for (let child of component.children) {
        this.applyDynamicValues(child, value);
      }
    }
  }
}
