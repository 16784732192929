import { Component } from '@angular/core';
import { NzMarks } from 'ng-zorro-antd/slider';
import { ApiService } from 'src/app/api.service';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-settings-voice',
  templateUrl: './settings-voice.component.html',
  styleUrls: ['./settings-voice.component.scss']
})
export class SettingsVoiceComponent {

  loading = false;
  saving = false;
  workspace: any = null;

  selectModel: any = ['1', '2', '4'];


  constructor(
    public apiService: ApiService,
    public sharedService: SharedService
  ) { }

  ngOnInit() {
    this.apiService.getCurrentWorkspace().subscribe(
      (data: any) => {
        console.log(data);
        this.workspace = data;
        this.sharedService.workspace = data;
        this.loading = false;
      },
      error => {
        console.log(error);
      });
  }

  openPicker(property: any, mode: any) {
    console.log("Open Picker");
    this.sharedService.currentProperty = property;
    this.sharedService.pickerMode = mode;
    this.sharedService.pickerOpen = true;
  }

  saveBrandStyles() {
    this.saving = true;
    this.apiService.saveBrandStyles(this.workspace.brandStyles).subscribe(
      (data: any) => {
        console.log(data);
        this.saving = false;
      },
      error => {
        console.log(error);
      });
  }

  compareFn = (o1: any, o2: any): boolean => (o1 && o2 ? o1 === o2 : o1 === o2);

  changeSelect() {
    console.log(this.selectModel);
  }
}


