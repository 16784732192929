import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-email-campaigns',
  templateUrl: './email-campaigns.component.html',
  styleUrls: ['./email-campaigns.component.scss']
})
export class EmailCampaignsComponent {
  opportunities: any = [];
  emailCampaigns: any = [];
  loading = true;
  noCampaigns = true;
  generatingCampaigns = false;

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    // this.apiService.getEmailCampaignCalendar().subscribe(
    //   (data: any) => {
    //     console.log(data);
    //     this.opportunities = data;
    
    //   },
    //   error => {
    //     console.log(error);
    //   });

    //   this.apiService.getEmailCampaigns().subscribe(
    //     (data: any) => {
    //       console.log(data);
    //       this.emailCampaigns = data;
    //       if (this.emailCampaigns.length > 0) {
    //         this.noCampaigns = false;
    //       }
    //       this.loading = false;
  
    //     },
    //     error => {
    //       console.log(error);
    //     });
  }
  
}
