import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-new-connection',
  templateUrl: './new-connection.component.html',
  styleUrls: ['./new-connection.component.scss']
})
export class NewConnectionComponent {
  connectionObject = {
    type: '',
    publicKey1: '',
    privateKey1: '',
    publicKey2: '',
    privateKey2: '',
    url: '',
    settings: {}
  }

  errorString = '';

  constructor(
    public apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  saveConnection() {
    this.apiService.newConnection(this.connectionObject).subscribe(
      (data: any) => {
        console.log(data);
        
      },
      error => {
        console.log(error);
      });
  }
}
