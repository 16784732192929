import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-select-colors',
  templateUrl: './select-colors.component.html',
  styleUrls: ['./select-colors.component.scss']
})
export class SelectColorsComponent {
  workspaceId: any = '';
  workspaceObject: any = {};
  loading = true;
  newMessageBody = '';
  imagesAndColorsObject: any = {};
  showColorSelector = false;

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    this.apiService.scrapeImagesAndColors().subscribe(
      (data: any) => {
        console.log(data);
        this.imagesAndColorsObject = data;
        this.imagesAndColorsObject.logo = this.imagesAndColorsObject.logos[0];
        this.loading = false;
      },
      error => {
        console.log(error);
      });
  }

  completeSignup() {
    this.apiService.completeSignup(this.workspaceObject).subscribe(
      data => {
          // login successful if there's a jwt token in the response
          console.log(data);
          if (data) {
            console.log(data);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(data));
            this.router.navigate(['signup/3']);
          }
      },
      error => {
          console.log(error);
      });
  }
}
