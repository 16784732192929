<div class="custom-modal-header">
    <div class="custom-modal-title">Crop image</div>
    <div class="custom-modal-close" (click)="close()"><span nz-icon nzType="close" nzTheme="outline"></span></div>
</div>
<div class="custom-modal-body" style="overflow:hidden;">
    <!-- <input type="file" (change)="fileChangeEvent($event)" /> -->
    <ng-container *ngIf="!this.loading">
        <div class="image-select-box">
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false" format="png"
                (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)" output="base64"
                [imageURL]="this.sharedService.editImageCurrentImage.value" (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()"></image-cropper>
        </div>
    </ng-container>
    <ng-container *ngIf="this.loading">
        <div class="loading-wrapper">
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </ng-container>

    <!-- <img [src]="croppedImage" /> -->
</div>
<div class="custom-modal-footer">
    <button nz-button nzType="primary" (click)="uploadImage()" [nzLoading]="this.loading">Save</button>
</div>