<div class="page-wrapper">
    <div class="content-wrapper">
        <ng-container
            *ngIf="this.opportunityObject.stage == 'NEW' || this.opportunityObject.stage == 'INPROGRESS' || this.opportunityObject.stage == 'FAILED'">
            <div class="fullscreen-header">
                <nz-page-header class="site-page-header">
                    <nz-page-header-title>New email campaign</nz-page-header-title>
                    <nz-page-header-extra>
                        <button nz-button (click)="generateTemplateContent()"><span nz-icon nzType="sync"
                                nzTheme="outline"></span>Try
                            again</button>&nbsp;
                        <button nz-button (click)="deleteOpportunity()" [nzLoading]="deleting">Delete</button>&nbsp;
                    </nz-page-header-extra>
                </nz-page-header>
            </div>
            <div style="text-align: center">
                <div class="rippleLoaderWrapper">
                    <div class="rippleLoader">
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <h3>Ripple is generating a proposal.</h3>
                <h3>This may take a moment.</h3>
            </div>
        </ng-container>
        <ng-container *ngIf="this.opportunityObject.stage != 'NEW'">

            <div class="fullscreen-header">
                <ng-container *ngIf="!this.loading">

                    <nz-page-header nzBackIcon (nzBack)="handleBackButton()" class="site-page-header">
                        <!-- <nz-breadcrumb nz-page-header-breadcrumb>
                    <nz-breadcrumb-item><a [routerLink]="['/opportunities/new']">Opportunities</a></nz-breadcrumb-item>
                    <nz-breadcrumb-item>{{this.opportunityObject.title}}</nz-breadcrumb-item>
                </nz-breadcrumb> -->
                        <nz-page-header-title>
                            {{opportunityObject.title}}
                        </nz-page-header-title>
                        <ng-container
                            *ngIf="this.opportunityObject.type != 'TEMPLATE' && this.opportunityObject.type != 'SYSTEMTEMPLATE2'">

                            <nz-page-header-tags>
                                <ng-container *ngIf="this.opportunityObject.stage == 'LIVE'">

                                    <ng-container *ngIf="sendTimeInFuture()">
                                        <nz-tag class="aqua">Published</nz-tag>
                                    </ng-container>
                                    <ng-container *ngIf="!sendTimeInFuture()">
                                        <nz-tag class="aqua">Published</nz-tag>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="this.opportunityObject.stage == 'PENDING'">
                                    <nz-tag>Draft</nz-tag>
                                </ng-container>
                                <!-- <ng-container *ngIf="this.opportunityObject.template == 'FEATUREDPRODUCT'">
                                <nz-tag>Featured product</nz-tag>
                            </ng-container>
                            <ng-container *ngIf="this.opportunityObject.template == 'EDUCATIONAL'">
                                <nz-tag>Educational</nz-tag>
                            </ng-container>
                            <ng-container *ngIf="this.opportunityObject.template == 'FEATUREDCATEGORY'">
                                <nz-tag>Featured category</nz-tag>
                            </ng-container> -->
                            </nz-page-header-tags>
                        </ng-container>
                        <nz-page-header-extra>
                            <!-- <ng-container *ngIf="this.opportunityObject.stage == 'PENDING'">
                                <button nz-button (click)="generateTemplateContent()"><span nz-icon nzType="sync"
                                        nzTheme="outline"></span>Try
                                    again</button>&nbsp;

                            </ng-container> -->
                            <!-- <ng-container *ngIf="this.opportunityObject.stage == 'LIVE'">
                                <a href="https://klaviyo.com/email-editor/{{opportunityObject.externalData.klaviyoTemplate}}/edit"
                                    target="_blank"><button nz-button><span nz-icon nzType="link"
                                            nzTheme="outline"></span>View
                                        in Klaviyo</button></a>&nbsp;
                            </ng-container> -->
                            <!-- <button class="new-block-button" nz-button nzType="primary" (click)="publishOpportunity()">Generate image</button> -->
                            <!-- <ng-container *ngIf="this.opportunityObject.stage == 'LIVE' && sendTimeInFuture()">
                        <button [nzLoading]="publishingOpportunity"
                        (click)="publishOpportunity()" nz-button nzType="primary">Save</button>&nbsp;
                    </ng-container> -->
                            <!-- <button nz-button (click)="duplicateOpportunity()"
                                [nzLoading]="duplicating">Duplicate</button>&nbsp; -->
                            <!-- <button nz-button nz-popover [nzPopoverContent]="contentTemplate"
                                nzPopoverPlacement="bottomRight" nzPopoverTrigger="click" [disabled]="saving"><span
                                    nz-icon nzType="sync" nzTheme="outline"></span>Try again</button> -->
                            <ng-container>
                                &nbsp;

                                <ng-container
                                    *ngIf="this.opportunityObject.type == 'TEMPLATE' || this.opportunityObject.type == 'SYSTEMTEMPLATE2'">
                                    <nz-button-group>
                                        <button nz-button (click)="saveOpportunity()" [nzLoading]="saving"
                                            nzType="primary">Save</button>
                                        <button nzType="primary" nz-button nz-dropdown [nzDropdownMenu]="menu3"
                                            nzPlacement="bottomRight">
                                            <span nz-icon nzType="ellipsis"></span>
                                        </button>
                                    </nz-button-group>
                                </ng-container>

                                <ng-container *ngIf="this.opportunityObject.type == 'FLOW'">
                                    <button nz-button (click)="saveOpportunity()" [nzLoading]="saving"
                                        style="margin-right: 8px;">Save</button>

                                    <nz-button-group>
                                        <button nz-button nzType="primary" [nzLoading]="publishingOpportunity"
                                            (click)="publishOpportunityTemplate()">Publish</button>
                                        <button nzType="primary" nz-button nz-dropdown [nzDropdownMenu]="menu2"
                                            nzPlacement="bottomRight">
                                            <span nz-icon nzType="ellipsis"></span>
                                        </button>
                                    </nz-button-group>
                                </ng-container>

                                <ng-container *ngIf="this.opportunityObject.type == 'CAMPAIGN'">
                                    <ng-container *ngIf="this.opportunityObject.stage == 'PLACEHOLDER'">
                                        <nz-button-group>
                                            <button nz-button (click)="saveOpportunity()" [nzLoading]="saving"
                                                nzType="primary">Save</button>
                                            <button nzType="primary" nz-button nz-dropdown [nzDropdownMenu]="menu3"
                                                nzPlacement="bottomRight">
                                                <span nz-icon nzType="ellipsis"></span>
                                            </button>
                                        </nz-button-group>
                                    </ng-container>
                                    <ng-container *ngIf="this.opportunityObject.stage != 'PLACEHOLDER'">
                                        <button nz-button (click)="saveOpportunity()" [nzLoading]="saving"
                                            style="margin-right: 8px;">Save</button>
                                        <!-- <ng-container *ngIf="this.workspaceObject?.brandStyles?.enableSMS">
                                            <button nz-button (click)="generateSMSFromEmail()" [nzLoading]="this.sendingPreview"
                                            style="margin-right: 8px;"><span nz-icon nzType="message"
                                                nzTheme="outline"></span>Create SMS</button>
                                        </ng-container>
                                        <ng-container *ngIf="this.workspaceObject?.brandStyles?.enableSocialPosts">
                                            <button nz-button (click)="generateSocialPostFromEmail()" [nzLoading]="this.sendingPreview"
                                            style="margin-right: 8px;"><span nz-icon nzType="share-alt"
                                                nzTheme="outline"></span>Create social post</button>
                                        </ng-container> -->

                                        <ng-container
                                            *ngIf="this.workspaceObject?.brandStyles?.enableSocialPosts || this.workspaceObject?.brandStyles?.enableSMS">
                                            <button nz-button style="margin-right: 8px;" nz-dropdown
                                                [nzDropdownMenu]="menu4" nzPlacement="bottomRight"><span nz-icon
                                                    nzType="plus" nzTheme="outline"></span>Add channel</button>
                                        </ng-container>
                                        <button nz-button (click)="sendTestEmail()" [nzLoading]="this.sendingPreview"
                                            style="margin-right: 8px;"><span nz-icon nzType="send"
                                                nzTheme="outline"></span>Send test email</button>
                                        <nz-button-group>
                                            <button nz-button nzType="primary" [nzLoading]="publishingOpportunity"
                                                (click)="publishOpportunity()"><span nz-icon nzType="upload"
                                                    nzTheme="outline"></span>Publish</button>
                                            <button nzType="primary" nz-button nz-dropdown [nzDropdownMenu]="menu1"
                                                nzPlacement="bottomRight">
                                                <span nz-icon nzType="ellipsis"></span>
                                            </button>
                                        </nz-button-group>
                                    </ng-container>
                                </ng-container>
                            </ng-container>

                            <!-- <button nzPlacement="bottomRight" nz-button nz-dropdown [nzDropdownMenu]="menu4"
                                style="top: -3px;">
                                <span nz-icon nzType="ellipsis"></span>
                            </button> -->

                            <nz-dropdown-menu #menu1="nzDropdownMenu">
                                <ul nz-menu style="width: 225px;">
                                    <li nz-menu-item (click)="publishOpportunityDraft()"><span nz-icon nzType="upload"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Publish as Draft
                                    </li>
                                    <li nz-menu-item (click)="publishOpportunityTemplate()"><span nz-icon
                                            nzType="upload" nzTheme="outline"></span>&nbsp;&nbsp;Publish as template
                                    </li>
                                    <li nz-menu-item (click)="publishOpportunityShopify()"><span nz-icon nzType="code"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Export to Shopify
                                    </li>
                                    <li nz-menu-item (click)="publishOpportunityHTML()"><span nz-icon nzType="code"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Export HTML
                                    </li>
                                    <li nz-menu-divider></li>
                                    <!-- <li nz-menu-item *ngIf="this.opportunityObject.stage == 'PENDING'"
                                        (click)="generateTemplateContent()"><span nz-icon nzType="sync"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Try again</li> -->
                                    <li nz-menu-item (click)="openNewTemplate()"><span nz-icon nzType="save"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Save as template</li>
                                    <li nz-menu-item (click)="duplicateOpportunity()"><span nz-icon nzType="copy"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Duplicate</li>
                                    <li nz-menu-item (click)="deleteOpportunity()"><span nz-icon nzType="delete"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Delete</li>
                                </ul>
                            </nz-dropdown-menu>

                            <nz-dropdown-menu #menu2="nzDropdownMenu">
                                <ul nz-menu>
                                    <li nz-menu-item (click)="publishOpportunityTemplate()"><span nz-icon
                                            nzType="upload" nzTheme="outline"></span>&nbsp;&nbsp;Publish as template
                                    </li>
                                    <li nz-menu-item (click)="publishOpportunityHTML()"><span nz-icon nzType="code"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Export HTML
                                    </li>
                                    <li nz-menu-divider></li>
                                    <!-- <li nz-menu-item *ngIf="this.opportunityObject.stage == 'PENDING'"
                                        (click)="generateTemplateContent()"><span nz-icon nzType="sync"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Try again</li> -->
                                    <li nz-menu-item (click)="duplicateOpportunity()"><span nz-icon nzType="copy"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Duplicate</li>
                                    <li nz-menu-item (click)="deleteOpportunity()"><span nz-icon nzType="delete"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Delete</li>
                                </ul>
                            </nz-dropdown-menu>

                            <nz-dropdown-menu #menu3="nzDropdownMenu">
                                <ul nz-menu>
                                    <li nz-menu-item (click)="deleteOpportunity()"><span nz-icon nzType="delete"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Delete</li>
                                </ul>
                            </nz-dropdown-menu>

                            <nz-dropdown-menu #menu4="nzDropdownMenu">
                                <ul nz-menu style="width: 225px;">
                                    <ng-container *ngIf="this.workspaceObject?.brandStyles?.enableSMS">

                                        <li nz-menu-item (click)="generateSMSFromEmail()"><span nz-icon nzType="message"
                                                nzTheme="outline"></span>&nbsp;&nbsp;SMS</li>
                                    </ng-container>
                                    <ng-container *ngIf="this.workspaceObject?.brandStyles?.enableSocialPosts">

                                        <li nz-menu-item (click)="generateSocialPostFromEmail()"><span nz-icon
                                                nzType="share-alt" nzTheme="outline"></span>&nbsp;&nbsp;Social post</li>
                                    </ng-container>
                                </ul>
                            </nz-dropdown-menu>
                            <!-- <nz-dropdown-menu #menu4="nzDropdownMenu">
                                <ul nz-menu>

                                </ul>
                            </nz-dropdown-menu> -->

                        </nz-page-header-extra>

                    </nz-page-header>
                </ng-container>

                <ng-container *ngIf="this.loading">

                </ng-container>

            </div>
            <div class="content-outer">
                <ng-container *ngIf="this.loading">
                    <div class="loading-wrapper">
                        <div class="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!this.loading">
                    <div class="column-left">
                        <div class="content-inner">
                            <div class="inner">
                                <ng-container
                                    *ngIf="this.opportunityObject.type == 'TEMPLATE' || this.opportunityObject.type == 'SYSTEMTEMPLATE2'">
                                    <nz-form-item>
                                        <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                            Title
                                        </nz-form-label>
                                        <nz-form-control nzSpan="24">
                                            <input nz-input name="title" [(ngModel)]="opportunityObject.title" />
                                        </nz-form-control>
                                    </nz-form-item>
                                    <nz-form-item>
                                        <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                            Instructions
                                        </nz-form-label>
                                        <nz-form-control nzSpan="24">
                                            <textarea nz-input name="subjectLine"
                                                [(ngModel)]="opportunityObject.instructions"
                                                [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>
                                        </nz-form-control>
                                    </nz-form-item>
                                    <ng-container
                                        *ngIf="this.opportunityObject.type == 'SYSTEMTEMPLATE2'">
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                Template type
                                            </nz-form-label>
                                            <nz-form-control nzSpan="24">
                                                <nz-select [(ngModel)]="this.opportunityObject.templateCategory">
                                                    <nz-option nzValue="CAMPAIGN" nzLabel="Campaign"></nz-option>
                                                    <nz-option nzValue="FLOW" nzLabel="Flow"></nz-option>
                                                  </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                Tags
                                            </nz-form-label>
                                            <nz-form-control nzSpan="24">
                                                <ng-container *ngFor="let tag of this.opportunityObject.tags">
                                                    <nz-tag nzMode="closeable"
                                                        (nzOnClose)="handleClose(tag)">{{tag}}</nz-tag>
                                                </ng-container>
                                                <ng-container *ngIf="!inputVisible">
                                                    <nz-tag class="editable-tag" nzNoAnimation (click)="showInput()">
                                                        <span nz-icon nzType="plus"></span>
                                                        New Tag
                                                    </nz-tag>
                                                </ng-container>
                                                <ng-container *ngIf="inputVisible">
                                                    <input #inputElement nz-input nzSize="small" type="text"
                                                        [(ngModel)]="inputValue" style="width: 200px;"
                                                        (blur)="handleInputConfirm()"
                                                        (keydown.enter)="handleInputConfirm()" />
                                                </ng-container>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                RTL version
                                            </nz-form-label>
                                            <nz-form-control nzSpan="24">
                                                <input nz-input name="title" [(ngModel)]="opportunityObject.rtlVersion" />
                                            </nz-form-control>
                                        </nz-form-item>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="this.opportunityObject.type == 'FLOW'">
                                    <form nz-form nzLayout="vertical">
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true">Flow</nz-form-label>
                                            <nz-form-control [nzSpan]="24">
                                                <nz-select nzShowSearch nzPlaceHolder="Select a flow"
                                                    [(ngModel)]="opportunityObject.flow" style="width: 100%;"
                                                    [compareWith]="compareFn3" name="flow">
                                                    <ng-container *ngFor="let flow of this.flowList">
                                                        <nz-option [nzLabel]="flow.name"
                                                            nzValue="{{flow._id}}"></nz-option>
                                                    </ng-container>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                Title
                                            </nz-form-label>
                                            <nz-form-control nzSpan="24">
                                                <input nz-input name="title" [(ngModel)]="opportunityObject.title" />
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                Subject line
                                            </nz-form-label>
                                            <nz-form-control nzSpan="24">
                                                <ng-container
                                                    *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                    <textarea nz-input name="subjectLine"
                                                        [(ngModel)]="opportunityObject.contentTemplate.subjectLine"
                                                        [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                    {{opportunityObject.contentTemplate.subjectLine}}
                                                </ng-container>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                Preview text
                                            </nz-form-label>
                                            <nz-form-control nzSpan="24">
                                                <ng-container
                                                    *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                    <textarea nz-input name="previewtext"
                                                        [(ngModel)]="opportunityObject.contentTemplate.previewText"
                                                        [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                    {{opportunityObject.contentTemplate.previewText}}
                                                </ng-container>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <ng-container
                                            *ngIf="(opportunityObject.externalData && opportunityObject.externalData.klaviyoCampaign) || (opportunityObject.externalData && opportunityObject.externalData.klaviyoTemplate)">
                                            <nz-form-item>
                                                <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                    Connections
                                                </nz-form-label>
                                                <nz-form-control nzSpan="24">
                                                    <ng-container
                                                        *ngIf="(opportunityObject.externalData && opportunityObject.externalData.klaviyoTemplate) && (opportunityObject.externalData && !opportunityObject.externalData.klaviyoCampaign)">

                                                        <div class="custom-card">
                                                            <div class="custom-card-body" style="line-height: 36px">
                                                                Synced to Klaviyo template
                                                                <nz-tag>{{opportunityObject.externalData.klaviyoTemplate}}</nz-tag>
                                                                <a href="https://klaviyo.com/email-editor/{{opportunityObject.externalData.klaviyoTemplate}}/edit"
                                                                    target="_blank" style="float: right"><button
                                                                        nz-button><span nz-icon nzType="link"
                                                                            nzTheme="outline"></span>View
                                                                        in Klaviyo</button></a>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="(opportunityObject.externalData && opportunityObject.externalData.klaviyoCampaign)">

                                                        <div class="custom-card">
                                                            <div class="custom-card-body" style="line-height: 36px">
                                                                Synced to Klaviyo campaign
                                                                <nz-tag>{{opportunityObject.externalData.klaviyoCampaign}}</nz-tag>
                                                                <a href="https://klaviyo.com/campaign/{{opportunityObject.externalData.klaviyoCampaign}}/scheduled"
                                                                    target="_blank" style="float: right"><button
                                                                        nz-button><span nz-icon nzType="link"
                                                                            nzTheme="outline"></span>View
                                                                        in Klaviyo</button></a>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </nz-form-control>

                                            </nz-form-item>
                                        </ng-container>
                                    </form>
                                </ng-container>
                                <ng-container *ngIf="this.opportunityObject.type == 'CAMPAIGN'">
                                    <ng-container *ngIf="!this.loading">
                                        <ng-container *ngIf="this.opportunityObject.stage == 'PLACEHOLDER'">
                                            <nz-form-item>
                                                <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                    Title
                                                </nz-form-label>
                                                <nz-form-control nzSpan="24">
                                                    <input nz-input name="title"
                                                        [(ngModel)]="opportunityObject.title" />
                                                </nz-form-control>
                                            </nz-form-item>
                                            <nz-form-item>
                                                <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">Send
                                                    time
                                                </nz-form-label>
                                                <nz-form-control nzSpan="24">
                                                    <div nz-row [nzGutter]="16">
                                                        <div nz-col class="gutter-row" [nzSpan]="14">
                                                            <ng-container>
                                                                <nz-date-picker name="sendTime"
                                                                    [(ngModel)]="opportunityObject.sendTime"
                                                                    nzFormat="yyyy-MM-dd" style="width:100%;"
                                                                    [nzDisabledDate]="disablePastDates"
                                                                    [disabled]="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()"></nz-date-picker>
                                                            </ng-container>
                                                            <!-- <ng-container
                                                            *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                            {{opportunityObject.sendTime | date:'EEEE M/d/YY h:mm
                                                            a'}}
                                                        </ng-container> -->


                                                        </div>
                                                        <div nz-col class="gutter-row" [nzSpan]="10">
                                                            <ng-container>
                                                                <nz-time-picker name="sendTimeHours"
                                                                    [(ngModel)]="opportunityObject.sendTimeHours"
                                                                    [nzMinuteStep]="15" nzFormat="HH:mm"
                                                                    style="width:100%;"
                                                                    (ngModelChange)="changeTime($event)"
                                                                    [disabled]="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()"></nz-time-picker>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </nz-form-control>
                                            </nz-form-item>
                                            <nz-form-item>
                                                <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                    Type
                                                </nz-form-label>
                                                <nz-form-control nzSpan="24">
                                                    <ng-container *ngIf="opportunityObject.template.name">
                                                        <nz-tag>{{opportunityObject.template.name | titlecase}}</nz-tag>
                                                    </ng-container>
                                                    <ng-container *ngIf="opportunityObject.template.title">
                                                        <nz-tag>{{opportunityObject.template.title |
                                                            titlecase}}</nz-tag>
                                                    </ng-container>
                                                </nz-form-control>
                                            </nz-form-item>
                                            <div nz-row [nzGutter]="16">
                                                <div nz-col class="gutter-row" [nzSpan]="12">
                                                    <nz-form-item>
                                                        <nz-form-label [nzNoColon]="true" nzLabelAlign="left"
                                                            nzSpan="24">Included
                                                            segments
                                                        </nz-form-label>
                                                        <nz-form-control nzSpan="24"
                                                            [nzValidateStatus]="segmentIncludeStatus"
                                                            nzErrorTip="You must select at least one segment to include">


                                                            <nz-select nzPlaceHolder="Choose" [compareWith]="compareFn"
                                                                [(ngModel)]="this.opportunityObject.audienceInclude"
                                                                (ngModelChange)="this.changeSelect()"
                                                                name="audienceInclude" nzMode="multiple">
                                                                <ng-container
                                                                    *ngFor="let segment of klaviyoListsAndSegments">
                                                                    <nz-option nzCustomContent
                                                                        [nzLabel]="segment.attributes.name"
                                                                        [nzValue]="segment">
                                                                        {{segment.attributes.name}} <nz-tag
                                                                            style="float: right"
                                                                            class="aqua">{{segment.type
                                                                            | titlecase}}</nz-tag>
                                                                    </nz-option>
                                                                </ng-container>

                                                                <!-- <nz-option nzLabel="Engaged non-purchasers" nzValue="2"></nz-option>
                                                        <nz-option nzLabel="One-time purchasers" nzValue="3"></nz-option>
                                                        <nz-option nzLabel="Frequent buyers" nzValue="4"></nz-option>
                                                        <nz-option nzLabel="Dormant purchasers" nzValue="5"></nz-option>
                                                        <nz-option nzLabel="Lapsed subscribers" nzValue="6"></nz-option>
                                                        <nz-option nzLabel="VIPs" nzValue="7"></nz-option>
                                                        <nz-option nzLabel="Seasonal shoppers" nzValue="8"></nz-option> -->
                                                            </nz-select>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                <div nz-col class="gutter-row" [nzSpan]="12">
                                                    <nz-form-item>
                                                        <nz-form-label [nzNoColon]="true" nzLabelAlign="left"
                                                            nzSpan="24">Excluded
                                                            segments
                                                        </nz-form-label>
                                                        <nz-form-control nzSpan="24"
                                                            [nzValidateStatus]="segmentIncludeStatus"
                                                            nzErrorTip="You must select at least one segment to include">


                                                            <nz-select nzPlaceHolder="Choose" [compareWith]="compareFn"
                                                                [(ngModel)]="this.opportunityObject.audienceExclude"
                                                                (ngModelChange)="this.changeSelect()"
                                                                name="audienceExclude" nzMode="multiple">
                                                                <ng-container
                                                                    *ngFor="let segment of klaviyoListsAndSegments">
                                                                    <nz-option nzCustomContent
                                                                        [nzLabel]="segment.attributes.name"
                                                                        [nzValue]="segment">
                                                                        {{segment.attributes.name}} <nz-tag
                                                                            style="float: right"
                                                                            class="aqua">{{segment.type
                                                                            | titlecase}}</nz-tag>
                                                                    </nz-option>
                                                                </ng-container>

                                                                <!-- <nz-option nzLabel="Engaged non-purchasers" nzValue="2"></nz-option>
                                                        <nz-option nzLabel="One-time purchasers" nzValue="3"></nz-option>
                                                        <nz-option nzLabel="Frequent buyers" nzValue="4"></nz-option>
                                                        <nz-option nzLabel="Dormant purchasers" nzValue="5"></nz-option>
                                                        <nz-option nzLabel="Lapsed subscribers" nzValue="6"></nz-option>
                                                        <nz-option nzLabel="VIPs" nzValue="7"></nz-option>
                                                        <nz-option nzLabel="Seasonal shoppers" nzValue="8"></nz-option> -->
                                                            </nz-select>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="this.opportunityObject.stage != 'PLACEHOLDER'">

                                            <ng-container *ngIf="this.opportunityObject.stage == 'PUBLISHING'">
                                                <nz-alert nzType="info"
                                                    nzMessage="Copilot is publishing this opportunity, and will notify you when it is live. This may take up to 24 hours."
                                                    nzShowIcon></nz-alert>
                                            </ng-container>
                                            <ng-container *ngIf="this.sharedService.currentUser.adminLevel == 'SUPER1'">
                                                <ng-container
                                                    *ngIf="this.opportunityObject.externalData?.klaviyoCampaign && !sendTimeInFuture()">
                                                    <nz-row [nzGutter]="16" style="margin-top: 24px;">
                                                        <nz-col [nzSpan]="6">
                                                            <div class="custom-card">
                                                                <div class="custom-card-body">
                                                                    <!-- <nz-statistic
                                                                        [nzValue]="(receivedEmailMetric.value | number)!"
                                                                        [nzTitle]="'Recipients'"></nz-statistic> -->
                                                                    <div class="metric-title">
                                                                        Recipients
                                                                        <ng-container
                                                                            *ngIf="this.loadingReceivedEmailMetric">
                                                                            <span nz-icon [nzType]="'sync'"
                                                                                [nzSpin]="true"
                                                                                style="float: right; margin-top: 4px;"></span>
                                                                        </ng-container>
                                                                    </div>
                                                                    <ng-container
                                                                        *ngIf="!this.receivedEmailMetric?.value">
                                                                        <nz-skeleton-element nzType="input"
                                                                            [nzActive]="true" nzSize="large"
                                                                            style="width: 90px !important; overflow: hidden; margin-top: 4px;"></nz-skeleton-element>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="this.receivedEmailMetric?.value">
                                                                        <div class="metric-content">
                                                                            {{receivedEmailMetric.value | number}}</div>
                                                                        <div class="metric-subcontent"> <a
                                                                                href="https://www.klaviyo.com/campaign/{{opportunityObject.externalData.klaviyoCampaign}}/reports/recipients?list=sent"
                                                                                target="_blank">See all</a></div>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </nz-col>
                                                        <nz-col [nzSpan]="6">
                                                            <div class="custom-card">
                                                                <div class="custom-card-body">
                                                                    <!-- <nz-statistic
                                                                        [nzValue]="(receivedEmailMetric.value | number)!"
                                                                        [nzTitle]="'Recipients'"></nz-statistic> -->
                                                                    <div class="metric-title">
                                                                        Open rate
                                                                        <ng-container
                                                                            *ngIf="this.loadingOpenRateMetric">
                                                                            <span nz-icon [nzType]="'sync'"
                                                                                [nzSpin]="true"
                                                                                style="float: right; margin-top: 4px;"></span>
                                                                        </ng-container>
                                                                    </div>
                                                                    <ng-container *ngIf="!this.openRateMetric?.value">
                                                                        <nz-skeleton-element nzType="input"
                                                                            [nzActive]="true" nzSize="large"
                                                                            style="width: 90px !important; overflow: hidden; margin-top: 4px;"></nz-skeleton-element>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="this.openRateMetric?.value">
                                                                        <ng-container *ngIf="openRateMetric?.value">
                                                                            <div class="metric-content">
                                                                                {{openRateMetric.value | percent:
                                                                                '1.2-2'}}
                                                                            </div>
                                                                            <div class="metric-subcontent">
                                                                                <a href="https://www.klaviyo.com/campaign/{{opportunityObject.externalData.klaviyoCampaign}}/reports/recipients?list=open"
                                                                                    target="_blank">
                                                                                    {{openRateMetric.extraData.openedCount}}
                                                                                    recipients</a>
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="!openRateMetric?.value">
                                                                            <div class="metric-content">
                                                                                -
                                                                            </div>
                                                                            <div class="metric-subcontent">
                                                                                -
                                                                            </div>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </nz-col>
                                                        <nz-col [nzSpan]="6">
                                                            <div class="custom-card">
                                                                <div class="custom-card-body">
                                                                    <!-- <nz-statistic
                                                                        [nzValue]="(receivedEmailMetric.value | number)!"
                                                                        [nzTitle]="'Recipients'"></nz-statistic> -->
                                                                    <div class="metric-title">
                                                                        Click rate
                                                                        <ng-container
                                                                            *ngIf="this.loadingClickRateMetric">
                                                                            <span nz-icon [nzType]="'sync'"
                                                                                [nzSpin]="true"
                                                                                style="float: right; margin-top: 4px;"></span>
                                                                        </ng-container>
                                                                    </div>
                                                                    <ng-container
                                                                        *ngIf="!this.clickRateMetric?.value && this.clickRateMetric?.value != 0">
                                                                        <nz-skeleton-element nzType="input"
                                                                            [nzActive]="true" nzSize="large"
                                                                            style="width: 90px !important; overflow: hidden; margin-top: 4px;"></nz-skeleton-element>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="this.clickRateMetric?.value || this.clickRateMetric?.value == 0">
                                                                        <ng-container *ngIf="clickRateMetric?.value">
                                                                            <div class="metric-content">
                                                                                {{clickRateMetric.value | percent:
                                                                                '1.2-2'}}
                                                                            </div>
                                                                            <div class="metric-subcontent">
                                                                                <a href="https://www.klaviyo.com/campaign/{{opportunityObject.externalData.klaviyoCampaign}}/reports/recipients?list=clicked"
                                                                                    target="_blank">
                                                                                    {{clickRateMetric.extraData?.clickedCount}}
                                                                                    recipients</a>
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="!clickRateMetric?.value">
                                                                            <div class="metric-content">
                                                                                -
                                                                            </div>
                                                                            <div class="metric-subcontent">
                                                                                -
                                                                            </div>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </nz-col>
                                                        <nz-col [nzSpan]="6">
                                                            <div class="custom-card">
                                                                <div class="custom-card-body">
                                                                    <!-- <nz-statistic
                                                                        [nzValue]="(receivedEmailMetric.value | number)!"
                                                                        [nzTitle]="'Recipients'"></nz-statistic> -->
                                                                    <div class="metric-title">
                                                                        Revenue
                                                                        <ng-container *ngIf="this.loadingOrdersMetric">
                                                                            <span nz-icon [nzType]="'sync'"
                                                                                [nzSpin]="true"
                                                                                style="float: right; margin-top: 4px;"></span>
                                                                        </ng-container>
                                                                    </div>
                                                                    <ng-container
                                                                        *ngIf="!this.ordersMetric?.value && this.ordersMetric?.value != 0">
                                                                        <nz-skeleton-element nzType="input"
                                                                            [nzActive]="true" nzSize="large"
                                                                            style="width: 90px !important; overflow: hidden;"></nz-skeleton-element>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="this.ordersMetric?.value || this.ordersMetric?.value == 0">
                                                                        <div class="metric-content">
                                                                            ${{ordersMetric.value | number}}</div>
                                                                        <div class="metric-subcontent">
                                                                            {{ordersMetric.extraData?.ordersCount}}
                                                                            orders</div>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </nz-col>
                                                    </nz-row>
                                                    <nz-divider></nz-divider>

                                                </ng-container>
                                            </ng-container>
                                            <form nz-form nzLayout="vertical">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                        Title
                                                    </nz-form-label>
                                                    <nz-form-control nzSpan="24">

                                                        <input nz-input name="title"
                                                            [(ngModel)]="opportunityObject.title" />

                                                    </nz-form-control>

                                                </nz-form-item>
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true" nzLabelAlign="left"
                                                        nzSpan="24">Send
                                                        time
                                                    </nz-form-label>
                                                    <nz-form-control nzSpan="24">
                                                        <div nz-row [nzGutter]="16">
                                                            <div nz-col class="gutter-row" [nzSpan]="14">
                                                                <ng-container>
                                                                    <nz-date-picker name="sendTime"
                                                                        [(ngModel)]="opportunityObject.sendTime"
                                                                        nzFormat="yyyy-MM-dd" style="width:100%;"
                                                                        [nzDisabledDate]="disablePastDates"
                                                                        [disabled]="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()"></nz-date-picker>
                                                                </ng-container>
                                                                <!-- <ng-container
                                                                *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                                {{opportunityObject.sendTime | date:'EEEE M/d/YY h:mm
                                                                a'}}
                                                            </ng-container> -->


                                                            </div>
                                                            <div nz-col class="gutter-row" [nzSpan]="10">
                                                                <ng-container>
                                                                    <nz-time-picker name="sendTimeHours"
                                                                        [(ngModel)]="opportunityObject.sendTimeHours"
                                                                        [nzMinuteStep]="15" nzFormat="HH:mm"
                                                                        style="width:100%;"
                                                                        (ngModelChange)="changeTime($event)"
                                                                        [disabled]="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()"></nz-time-picker>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </nz-form-control>
                                                </nz-form-item>

                                                <div nz-row [nzGutter]="16">
                                                    <div nz-col class="gutter-row" [nzSpan]="12">
                                                        <nz-form-item>
                                                            <nz-form-label [nzNoColon]="true" nzLabelAlign="left"
                                                                nzSpan="20">
                                                                <nz-switch
                                                                    [(ngModel)]="this.opportunityObject.localTime"
                                                                    name="localTime"
                                                                    disabled></nz-switch>&nbsp;&nbsp;Send
                                                                in recipient's
                                                                local time
                                                            </nz-form-label>
                                                        </nz-form-item>
                                                    </div>
                                                    <div nz-col class="gutter-row" [nzSpan]="12">
                                                        <nz-form-item>
                                                            <nz-form-label [nzNoColon]="true" nzLabelAlign="left"
                                                                nzSpan="20">
                                                                <nz-switch
                                                                    [(ngModel)]="this.opportunityObject.smartSending"
                                                                    name="smartSending"
                                                                    [disabled]="this.opportunityObject.externalData?.klaviyoCampaign"></nz-switch>&nbsp;&nbsp;Smart
                                                                sending
                                                            </nz-form-label>
                                                        </nz-form-item>
                                                    </div>
                                                </div>
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="20">
                                                        Subject line
                                                        <!-- <button nz-button (click)="saveOpportunity()"
                                                [nzLoading]="saving">Save</button> -->

                                                        <!-- <button style="float: right; margin-left: 16px;" nz-button (click)="showChatDrawer()"
                                *ngIf="!editSubjectLine"><span nz-icon nzType="edit"
                                    nzTheme="outline"></span>Edit</button> -->
                                                    </nz-form-label>

                                                    <nz-form-control nzSpan="24">
                                                        <ng-container
                                                            *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                            <nz-row [nzGutter]="8">
                                                                <nz-col [nzSpan]="22">
                                                                    <input nz-input name="subjectLine"
                                                                        [(ngModel)]="opportunityObject.contentTemplate.subjectLine" />
                                                                </nz-col>
                                                                <nz-col [nzSpan]="2">
                                                                    <button nz-button nzBlock nz-popover
                                                                        [nzPopoverContent]="contentTemplate2"
                                                                        nzPopoverPlacement="bottomRight"
                                                                        nzPopoverTrigger="click"
                                                                        [(nzPopoverVisible)]="subjectLinePopoverVisible"><span
                                                                            nz-icon nzType="sync"
                                                                            nzTheme="outline"></span></button>
                                                                    <ng-template #contentTemplate2>
                                                                        <div
                                                                            style="margin: -12px -16px; overflow: hidden; border-radius: 10px; width: 500px;">
                                                                            <div class="custom-modal-header">
                                                                                Regenerate subject line
                                                                            </div>
                                                                            <div class="custom-modal-body">
                                                                                <textarea
                                                                                    [(ngModel)]="subjectLineFeedback"
                                                                                    nz-input
                                                                                    placeholder="Ex: Make the copy more fun"
                                                                                    style="height: 60px;"
                                                                                    name="subjectLineFeedback"></textarea>
                                                                                <div class="loading-wrapper"
                                                                                    *ngIf="this.loadingSubjectLineFeedback"
                                                                                    style="text-align: center; padding: 24px !important;">
                                                                                    <img src="../../assets/magicWand.gif"
                                                                                        style="width: 125px; height: 125px; margin: auto; margin-bottom: 16px;" />
                                                                                    Generating content...
                                                                                </div>
                                                                                <ng-container
                                                                                    *ngIf="!this.loadingSubjectLineFeedback && showSubjectLines">
                                                                                    <div style="margin-top: 8px;">
                                                                                        <ng-container
                                                                                            *ngFor="let subjectLine of this.subjectLines">
                                                                                            <button nz-button
                                                                                                (click)="selectSubjectLine(subjectLine)"
                                                                                                nzBlock
                                                                                                style="margin-top: 8px;">
                                                                                                {{subjectLine.text}}
                                                                                            </button>
                                                                                        </ng-container>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </div>
                                                                            <div class="custom-modal-footer">
                                                                                <div style="text-align: right">
                                                                                    <button nz-button nzType="primary"
                                                                                        (click)="sendSubjectLineFeedback()"
                                                                                        [nzLoading]="loadingSubjectLineFeedback">Send</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ng-template>
                                                                </nz-col>
                                                            </nz-row>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                            {{opportunityObject.contentTemplate.subjectLine}}
                                                        </ng-container>


                                                        <!-- <ng-container *ngIf="this.editSubjectLine">
                                <div class="feedback-wrapper">
                                    <textarea placeholder="Enter feedback for Copilot" rows="2" nz-input
                                        [(ngModel)]="subjectLineFeedback"></textarea>
                                    <button style="float: right" nz-button (click)="sendSubjectLineFeedback()"
                                        [nzLoading]="loadingSubjectLineFeedback">Send</button>
                                </div>
                            </ng-container> -->
                                                    </nz-form-control>

                                                </nz-form-item>

                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                        Preview text
                                                    </nz-form-label>
                                                    <nz-form-control nzSpan="24">
                                                        <ng-container
                                                            *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                            <textarea nz-input name="previewtext"
                                                                [(ngModel)]="opportunityObject.contentTemplate.previewText"
                                                                [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                            {{opportunityObject.contentTemplate.previewText}}
                                                        </ng-container>
                                                    </nz-form-control>

                                                </nz-form-item>
                                            </form>

                                            <form nz-form nzLayout="vertical">
                                                <div nz-row [nzGutter]="16">
                                                    <div nz-col class="gutter-row" [nzSpan]="12">
                                                        <nz-form-item>
                                                            <nz-form-label [nzNoColon]="true" nzLabelAlign="left"
                                                                nzSpan="24">Included
                                                                segments
                                                            </nz-form-label>
                                                            <nz-form-control nzSpan="24"
                                                                [nzValidateStatus]="segmentIncludeStatus"
                                                                nzErrorTip="You must select at least one segment to include">

                                                                <ng-container
                                                                    *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                                    <nz-select nzPlaceHolder="Choose"
                                                                        [compareWith]="compareFn"
                                                                        [(ngModel)]="this.opportunityObject.audienceInclude"
                                                                        (ngModelChange)="this.changeSelect()"
                                                                        name="audienceInclude" nzMode="multiple">
                                                                        <ng-container
                                                                            *ngFor="let segment of klaviyoListsAndSegments">
                                                                            <nz-option nzCustomContent
                                                                                [nzLabel]="segment.attributes.name"
                                                                                [nzValue]="segment">
                                                                                {{segment.attributes.name}} <nz-tag
                                                                                    style="float: right"
                                                                                    class="aqua">{{segment.type
                                                                                    | titlecase}}</nz-tag>
                                                                            </nz-option>
                                                                        </ng-container>

                                                                        <!-- <nz-option nzLabel="Engaged non-purchasers" nzValue="2"></nz-option>
                                                        <nz-option nzLabel="One-time purchasers" nzValue="3"></nz-option>
                                                        <nz-option nzLabel="Frequent buyers" nzValue="4"></nz-option>
                                                        <nz-option nzLabel="Dormant purchasers" nzValue="5"></nz-option>
                                                        <nz-option nzLabel="Lapsed subscribers" nzValue="6"></nz-option>
                                                        <nz-option nzLabel="VIPs" nzValue="7"></nz-option>
                                                        <nz-option nzLabel="Seasonal shoppers" nzValue="8"></nz-option> -->
                                                                    </nz-select>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                                    <nz-select disabled nzPlaceHolder="Choose"
                                                                        [compareWith]="compareFn"
                                                                        [(ngModel)]="this.opportunityObject.audienceInclude"
                                                                        (ngModelChange)="this.changeSelect()"
                                                                        name="audienceInclude" nzMode="multiple">
                                                                        <ng-container
                                                                            *ngFor="let segment of klaviyoListsAndSegments">
                                                                            <nz-option nzCustomContent
                                                                                [nzLabel]="segment.attributes.name"
                                                                                [nzValue]="segment">
                                                                                {{segment.attributes.name}} <nz-tag
                                                                                    style="float: right"
                                                                                    class="aqua">{{segment.type
                                                                                    | titlecase}}</nz-tag>
                                                                            </nz-option>
                                                                        </ng-container>

                                                                        <!-- <nz-option nzLabel="Engaged non-purchasers" nzValue="2"></nz-option>
                                                        <nz-option nzLabel="One-time purchasers" nzValue="3"></nz-option>
                                                        <nz-option nzLabel="Frequent buyers" nzValue="4"></nz-option>
                                                        <nz-option nzLabel="Dormant purchasers" nzValue="5"></nz-option>
                                                        <nz-option nzLabel="Lapsed subscribers" nzValue="6"></nz-option>
                                                        <nz-option nzLabel="VIPs" nzValue="7"></nz-option>
                                                        <nz-option nzLabel="Seasonal shoppers" nzValue="8"></nz-option> -->
                                                                    </nz-select>
                                                                </ng-container>
                                                                <!-- <ng-container *ngIf="this.opportunityObject.stage == 'PENDING'">
                                        <button style="float: right" nz-button><span nz-icon nzType="edit"
                                                nzTheme="outline"></span>Edit</button>
                                    </ng-container> -->
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                    <div nz-col class="gutter-row" [nzSpan]="12">
                                                        <nz-form-item>
                                                            <nz-form-label [nzNoColon]="true" nzLabelAlign="left"
                                                                nzSpan="24">Excluded
                                                                segments
                                                            </nz-form-label>
                                                            <nz-form-control nzSpan="24">

                                                                <ng-container
                                                                    *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                                    <nz-select nzPlaceHolder="Choose"
                                                                        [compareWith]="compareFn"
                                                                        [(ngModel)]="this.opportunityObject.audienceExclude"
                                                                        (ngModelChange)="this.changeSelect()"
                                                                        name="audienceExclude" nzMode="multiple">
                                                                        <ng-container
                                                                            *ngFor="let segment of klaviyoListsAndSegments">
                                                                            <nz-option nzCustomContent
                                                                                [nzLabel]="segment.attributes.name"
                                                                                [nzValue]="segment">
                                                                                {{segment.attributes.name}} <nz-tag
                                                                                    style="float: right"
                                                                                    class="aqua">{{segment.type
                                                                                    | titlecase}}</nz-tag>
                                                                            </nz-option>
                                                                        </ng-container>

                                                                    </nz-select>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                                    <nz-select disabled nzPlaceHolder="Choose"
                                                                        [compareWith]="compareFn"
                                                                        [(ngModel)]="this.opportunityObject.audienceExclude"
                                                                        (ngModelChange)="this.changeSelect()"
                                                                        name="audienceExclude" nzMode="multiple">
                                                                        <ng-container
                                                                            *ngFor="let segment of klaviyoListsAndSegments">
                                                                            <nz-option nzCustomContent
                                                                                [nzLabel]="segment.attributes.name"
                                                                                [nzValue]="segment">
                                                                                {{segment.attributes.name}} <nz-tag
                                                                                    style="float: right"
                                                                                    class="aqua">{{segment.type
                                                                                    | titlecase}}</nz-tag>
                                                                            </nz-option>
                                                                        </ng-container>
                                                                    </nz-select>
                                                                </ng-container>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                </div>



                                            </form>
                                            <ng-container
                                                *ngIf="(opportunityObject.externalData && opportunityObject.externalData.klaviyoCampaign) || (opportunityObject.externalData && opportunityObject.externalData.klaviyoTemplate)">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                        Connections
                                                    </nz-form-label>
                                                    <nz-form-control nzSpan="24">
                                                        <ng-container
                                                            *ngIf="(opportunityObject.externalData && opportunityObject.externalData.klaviyoTemplate) && (opportunityObject.externalData && !opportunityObject.externalData.klaviyoCampaign)">

                                                            <div class="custom-card">
                                                                <div class="custom-card-body" style="line-height: 36px">
                                                                    Synced to Klaviyo template
                                                                    <nz-tag>{{opportunityObject.externalData.klaviyoTemplate}}</nz-tag>
                                                                    <a href="https://klaviyo.com/email-editor/{{opportunityObject.externalData.klaviyoTemplate}}/edit"
                                                                        target="_blank" style="float: right"><button
                                                                            nz-button><span nz-icon nzType="link"
                                                                                nzTheme="outline"></span>View
                                                                            in Klaviyo</button></a>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="(opportunityObject.externalData && opportunityObject.externalData.klaviyoCampaign)">

                                                            <div class="custom-card">
                                                                <div class="custom-card-body" style="line-height: 36px">
                                                                    Synced to Klaviyo campaign
                                                                    <nz-tag>{{opportunityObject.externalData.klaviyoCampaign}}</nz-tag>
                                                                    <a href="https://klaviyo.com/campaign/{{opportunityObject.externalData.klaviyoCampaign}}/scheduled"
                                                                        target="_blank" style="float: right"><button
                                                                            nz-button><span nz-icon nzType="link"
                                                                                nzTheme="outline"></span>View
                                                                            in Klaviyo</button></a>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </nz-form-control>

                                                </nz-form-item>
                                            </ng-container>

                                            <nz-divider></nz-divider>

                                            <!-- <nz-form-item>
                                                <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                    Follow ups
                                                </nz-form-label>
                                                <nz-form-control nzSpan="24">
                                                    <ng-container
                                                        *ngFor="let followUp of this.opportunityObject.followUps; let index=index">
                                                        <div class="custom-card">
                                                            <div class="custom-card-header" style="text-align: right">
                                                                <span nz-icon nzType="close" nzTheme="outline"
                                                                    class="remove"
                                                                    (click)="removeItemFromArray(index, this.opportunityObject.followUps)"></span>
                                                            </div>
                                                            <div class="custom-card-body" style="line-height: 36px">
                                                                <div nz-row [nzGutter]="16">
                                                                    <div nz-col class="gutter-row" [nzSpan]="6">
                                                                        <nz-form-item>
                                                                            <nz-form-label [nzNoColon]="true"
                                                                                nzLabelAlign="left" nzSpan="24">
                                                                                Delay (hours)
                                                                            </nz-form-label>
                                                                            <nz-form-control nzSpan="24">
                                                                                <nz-input-number style="width: 100%;"
                                                                                    [(ngModel)]="followUp.delay"></nz-input-number>
                                                                            </nz-form-control>
                                                                        </nz-form-item>
                                                                    </div>
                                                                    <div nz-col class="gutter-row" [nzSpan]="18">
                                                                        <nz-form-item>
                                                                            <nz-form-label [nzNoColon]="true"
                                                                                nzLabelAlign="left" nzSpan="24">
                                                                                Criteria
                                                                            </nz-form-label>
                                                                            <nz-form-control nzSpan="24">
                                                                                <div nz-row [nzGutter]="16">
                                                                                    <div nz-col class="gutter-row"
                                                                                        [nzSpan]="24">
                                                                                        <nz-select
                                                                                            [(ngModel)]="followUp.criteria"
                                                                                            [compareWith]="compareFn2">
                                                                                            <nz-option
                                                                                                [nzValue]="'OPENEDEMAIL'"
                                                                                                nzLabel="Did not open email"></nz-option>
                                                                                            <nz-option
                                                                                                [nzValue]="'CLICKEDEMAIL'"
                                                                                                nzLabel="Opened email but did not click"></nz-option>
                                                                                            <nz-option
                                                                                                [nzValue]="'PLACEDORDER'"
                                                                                                nzLabel="Clicked email but did not place order"></nz-option>
                                                                                        </nz-select>
                                                                                    </div>
                                                                                </div>
                                                                            </nz-form-control>
                                                                        </nz-form-item>
                                                                    </div>
                                                                </div>
                                                                <nz-form-item>
                                                                    <nz-form-label [nzNoColon]="true"
                                                                        nzLabelAlign="left" nzSpan="24">
                                                                        Subject line
                                                                    </nz-form-label>
                                                                    <nz-form-control nzSpan="24">
                                                                        <ng-container
                                                                            *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                                            <textarea nz-input name="subjectLine"
                                                                                [(ngModel)]="followUp.subjectLine"
                                                                                [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                                            {{followUp.subjectLine}}
                                                                        </ng-container>
                                                                    </nz-form-control>
                                                                </nz-form-item>
                                                                <nz-form-item>
                                                                    <nz-form-label [nzNoColon]="true"
                                                                        nzLabelAlign="left" nzSpan="24">
                                                                        Unique content
                                                                    </nz-form-label>
                                                                    <nz-form-control nzSpan="24">
                                                                        <div nz-row [nzGutter]="16">
                                                                            <div nz-col class="gutter-row" [nzSpan]="12"
                                                                                style="line-height: 36px">
                                                                                <nz-switch
                                                                                    [(ngModel)]="followUp.uniqueContent"
                                                                                    [nzLoading]="followUp.addingUniqueContent"
                                                                                    (ngModelChange)="toggleFollowUpUniqueContent(followUp)"></nz-switch>
                                                                            </div>
                                                                            <div nz-col class="gutter-row" [nzSpan]="12"
                                                                                style="text-align: right">
                                                                                <button *ngIf="followUp.uniqueContent"
                                                                                    nz-button
                                                                                    [routerLink]="['/editor',followUp.opportunity]"
                                                                                    nzBock> <span nz-icon nzType="edit"
                                                                                        nzTheme="outline"></span>Edit
                                                                                    content</button>
                                                                            </div>
                                                                        </div>
                                                                    </nz-form-control>
                                                                </nz-form-item>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <button nz-button nzBlock (click)="addFollowUp()"><span nz-icon
                                                            nzType="plus" nzTheme="outline"></span>Add
                                                        follow
                                                        up</button>
                                                </nz-form-control>

                                            </nz-form-item>
                                            <nz-divider></nz-divider> -->

                                            <nz-form-item>
                                                <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                    Segmented versions
                                                </nz-form-label>
                                                <nz-form-control nzSpan="24">
                                                    <ng-container
                                                        *ngFor="let followUp of this.opportunityObject.segmentVersions; let index=index">
                                                        <div class="custom-card">
                                                            <div class="custom-card-header" style="text-align: right">
                                                                <span nz-icon nzType="close" nzTheme="outline"
                                                                    class="remove"
                                                                    (click)="removeItemFromArray(index, this.opportunityObject.segmentVersions)"></span>
                                                            </div>
                                                            <ng-container *ngIf="followUp.loading">
                                                                <div class="loading-wrapper">
                                                                    <div class="lds-ellipsis">
                                                                        <div></div>
                                                                        <div></div>
                                                                        <div></div>
                                                                        <div></div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngIf="!followUp.loading">
                                                                <ng-container *ngIf="!followUp.opportunity">
                                                                    <div class="custom-card-body">
                                                                        <nz-form-item>
                                                                            <nz-form-label [nzNoColon]="true"
                                                                                nzLabelAlign="left" nzSpan="24">Segment
                                                                            </nz-form-label>
                                                                            <nz-form-control nzSpan="24"
                                                                                [nzValidateStatus]="segmentIncludeStatus"
                                                                                nzErrorTip="You must select at least one segment to include">

                                                                                <ng-container
                                                                                    *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                                                    <nz-select nzPlaceHolder="Choose"
                                                                                        [compareWith]="compareFn"
                                                                                        [(ngModel)]="this.opportunityObject.audienceInclude"
                                                                                        (ngModelChange)="this.changeSelect()"
                                                                                        name="audienceInclude"
                                                                                        nzShowSearch nzMode="multiple">
                                                                                        <ng-container
                                                                                            *ngFor="let segment of klaviyoListsAndSegments">
                                                                                            <nz-option nzCustomContent
                                                                                                [nzLabel]="segment.attributes.name"
                                                                                                [nzValue]="segment">
                                                                                                {{segment.attributes.name}}
                                                                                                <nz-tag
                                                                                                    style="float: right"
                                                                                                    class="aqua">{{segment.type
                                                                                                    |
                                                                                                    titlecase}}</nz-tag>
                                                                                            </nz-option>
                                                                                        </ng-container>
                                                                                    </nz-select>
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                                                    <nz-select disabled
                                                                                        nzPlaceHolder="Choose"
                                                                                        [compareWith]="compareFn"
                                                                                        [(ngModel)]="this.opportunityObject.audienceInclude"
                                                                                        (ngModelChange)="this.changeSelect()"
                                                                                        name="audienceInclude"
                                                                                        nzMode="multiple"
                                                                                        [nzMaxTagCount]="2"
                                                                                        [nzMaxTagPlaceholder]="tagPlaceHolder">
                                                                                        <ng-container
                                                                                            *ngFor="let segment of klaviyoListsAndSegments">
                                                                                            <nz-option nzCustomContent
                                                                                                [nzLabel]="segment.attributes.name"
                                                                                                [nzValue]="segment">
                                                                                                {{segment.attributes.name}}
                                                                                                <nz-tag
                                                                                                    style="float: right"
                                                                                                    class="aqua">{{segment.type
                                                                                                    |
                                                                                                    titlecase}}</nz-tag>
                                                                                            </nz-option>
                                                                                        </ng-container>
                                                                                    </nz-select>
                                                                                </ng-container>
                                                                            </nz-form-control>
                                                                        </nz-form-item>
                                                                        <nz-form-item>
                                                                            <nz-form-label [nzNoColon]="true"
                                                                                nzLabelAlign="left" nzSpan="24">Context
                                                                                (optional)
                                                                            </nz-form-label>
                                                                            <nz-form-control nzSpan="24"
                                                                                [nzValidateStatus]="segmentIncludeStatus"
                                                                                nzErrorTip="You must select at least one segment to include">
                                                                                <textarea nz-input name="feedback"
                                                                                    [(ngModel)]="followUp.feedback"
                                                                                    [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>

                                                                            </nz-form-control>
                                                                        </nz-form-item>
                                                                    </div>
                                                                    <div class="custom-card-footer">
                                                                        <button nz-button nzType="primary"
                                                                            [nzLoading]="publishingOpportunity"
                                                                            (click)="generateSegmentVersion(followUp)">Generate</button>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container *ngIf="followUp.opportunity">
                                                                    <div class="custom-card-body"
                                                                        style="line-height: 36px">
                                                                        <div nz-row [nzGutter]="16">
                                                                            <div nz-col class="gutter-row"
                                                                                [nzSpan]="19">
                                                                                <nz-form-item>
                                                                                    <nz-form-label [nzNoColon]="true"
                                                                                        nzLabelAlign="left"
                                                                                        nzSpan="24">Segment
                                                                                    </nz-form-label>
                                                                                    <nz-form-control nzSpan="24"
                                                                                        [nzValidateStatus]="segmentIncludeStatus"
                                                                                        nzErrorTip="You must select at least one segment to include">

                                                                                        <ng-container
                                                                                            *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                                                            <nz-select
                                                                                                nzPlaceHolder="Choose"
                                                                                                [compareWith]="compareFn"
                                                                                                [(ngModel)]="followUp.segment"
                                                                                                name="segment"
                                                                                                nzShowSearch
                                                                                                nzMode="multiple">
                                                                                                <ng-container
                                                                                                    *ngFor="let segment of klaviyoListsAndSegments">
                                                                                                    <nz-option
                                                                                                        nzCustomContent
                                                                                                        [nzLabel]="segment.attributes.name"
                                                                                                        [nzValue]="segment">
                                                                                                        {{segment.attributes.name}}
                                                                                                        <nz-tag
                                                                                                            style="float: right"
                                                                                                            class="aqua">{{segment.type
                                                                                                            |
                                                                                                            titlecase}}</nz-tag>
                                                                                                    </nz-option>
                                                                                                </ng-container>
                                                                                            </nz-select>
                                                                                        </ng-container>
                                                                                        <ng-container
                                                                                            *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                                                            <nz-select disabled
                                                                                                nzPlaceHolder="Choose"
                                                                                                [compareWith]="compareFn"
                                                                                                [(ngModel)]="followUp.segment"
                                                                                                name="segment"
                                                                                                [nzMaxTagCount]="2"
                                                                                                [nzMaxTagPlaceholder]="tagPlaceHolder">
                                                                                                <ng-container
                                                                                                    *ngFor="let segment of klaviyoListsAndSegments">
                                                                                                    <nz-option
                                                                                                        nzCustomContent
                                                                                                        [nzLabel]="segment.attributes.name"
                                                                                                        [nzValue]="segment">
                                                                                                        {{segment.attributes.name}}
                                                                                                        <nz-tag
                                                                                                            style="float: right"
                                                                                                            class="aqua">{{segment.type
                                                                                                            |
                                                                                                            titlecase}}</nz-tag>
                                                                                                    </nz-option>
                                                                                                </ng-container>
                                                                                            </nz-select>
                                                                                        </ng-container>
                                                                                    </nz-form-control>
                                                                                </nz-form-item>
                                                                            </div>
                                                                            <div nz-col class="gutter-row" [nzSpan]="5"
                                                                                style="padding-top: 40px;">
                                                                                <button nz-button
                                                                                    [routerLink]="['/editor',followUp.opportunity]"
                                                                                    nzBock>
                                                                                    <span nz-icon nzType="edit"
                                                                                        nzTheme="outline"></span>Edit
                                                                                    content</button>
                                                                            </div>
                                                                        </div>
                                                                        <nz-form-item>
                                                                            <nz-form-label [nzNoColon]="true"
                                                                                nzLabelAlign="left" nzSpan="24">
                                                                                Subject line
                                                                            </nz-form-label>
                                                                            <nz-form-control nzSpan="24">
                                                                                <ng-container
                                                                                    *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                                                    <textarea nz-input
                                                                                        name="subjectLine"
                                                                                        [(ngModel)]="followUp.subjectLine"
                                                                                        [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                                                    {{followUp.subjectLine}}
                                                                                </ng-container>
                                                                            </nz-form-control>
                                                                        </nz-form-item>
                                                                        <nz-form-item>
                                                                            <nz-form-label [nzNoColon]="true"
                                                                                nzLabelAlign="left" nzSpan="24">
                                                                                Preview text
                                                                            </nz-form-label>
                                                                            <nz-form-control nzSpan="24">
                                                                                <ng-container
                                                                                    *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                                                    <textarea nz-input
                                                                                        name="previewText"
                                                                                        [(ngModel)]="followUp.previewText"
                                                                                        [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                                                    {{followUp.previewText}}
                                                                                </ng-container>
                                                                            </nz-form-control>
                                                                        </nz-form-item>
                                                                        <nz-form-item
                                                                            style="margin-bottom: 0px !important">
                                                                            <nz-form-control nzSpan="24">

                                                                                <ng-container
                                                                                    *ngIf="(followUp.opportunityObject && followUp.opportunityObject.externalData && followUp.opportunityObject.externalData.klaviyoTemplate) && (followUp.opportunityObject.externalData && !followUp.opportunityObject.externalData.klaviyoCampaign)">

                                                                                    <div class="custom-card">
                                                                                        <div class="custom-card-body"
                                                                                            style="line-height: 36px">
                                                                                            Synced to Klaviyo template
                                                                                            <nz-tag>{{followUp.opportunityObject.externalData.klaviyoTemplate}}</nz-tag>
                                                                                            <a href="https://klaviyo.com/email-editor/{{followUp.opportunityObject.externalData.klaviyoTemplate}}/edit"
                                                                                                target="_blank"
                                                                                                style="float: right"><button
                                                                                                    nz-button><span
                                                                                                        nz-icon
                                                                                                        nzType="link"
                                                                                                        nzTheme="outline"></span>View
                                                                                                    in
                                                                                                    Klaviyo</button></a>
                                                                                        </div>
                                                                                    </div>
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="followUp.opportunityObject && (followUp.opportunityObject.externalData && followUp.opportunityObject.externalData.klaviyoCampaign)">

                                                                                    <div class="custom-card">
                                                                                        <div class="custom-card-body"
                                                                                            style="line-height: 36px">
                                                                                            Synced to Klaviyo campaign
                                                                                            <nz-tag>{{followUp.opportunityObject.externalData.klaviyoCampaign}}</nz-tag>
                                                                                            <a href="https://klaviyo.com/campaign/{{followUp.opportunityObject.externalData.klaviyoCampaign}}/scheduled"
                                                                                                target="_blank"
                                                                                                style="float: right"><button
                                                                                                    nz-button><span
                                                                                                        nz-icon
                                                                                                        nzType="link"
                                                                                                        nzTheme="outline"></span>View
                                                                                                    in
                                                                                                    Klaviyo</button></a>
                                                                                        </div>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </nz-form-control>
                                                                        </nz-form-item>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                    <button nz-button nzBlock (click)="addSegmentVersion()"
                                                        [nzLoading]="addingSegmentVersion"><span nz-icon nzType="plus"
                                                            nzTheme="outline"></span>Add segment</button>
                                                </nz-form-control>

                                            </nz-form-item>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </div>

                        </div>
                    </div>
                    <!-- <div *ngIf="this.sharedService.publishingEmailOpen">Publishing...</div> -->
                    <div class="column-right">
                        <ng-container *ngIf="this.loading">
                            <div style="max-width: 800px; margin: auto; margin-top: 60px;">
                                <div class="custom-card">
                                    <div class="custom-card-body">
                                        <nz-skeleton [nzParagraph]="{ rows: 2 }" [nzActive]="true"></nz-skeleton>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!this.loading">
                            <ng-container *ngIf="this.opportunityObject.stage != 'PLACEHOLDER'">

                                <div class="column-right-header">
                                    <button [routerLink]="['/editor',opportunityObject._id]" style="float: right;"
                                        [disabled]="saving" nz-button><span nz-icon nzType="edit"
                                            nzTheme="outline"></span>Edit</button>
                                </div>
                                <div class="renderer-wrapper">
                                    <app-email-renderer [mode]="'VIEW'"></app-email-renderer>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="this.opportunityObject.stage == 'PLACEHOLDER'">
                                <div class="renderer-wrapper" style="text-align:center; padding-top: 300px">
                                    <button nz-button nzType="primary" (click)="createEmailFromPlaceholder()">Generate
                                        email</button>
                                </div>
                            </ng-container>


                            <!-- <div class="email-background-wrapper" id="email-background-wrapper"
                                [style.backgroundColor]="opportunityObject.contentTemplate.templateBackground">

                                <div class="email-content-wrapper"
                                    [style.backgroundColor]="opportunityObject.contentTemplate.templateContentBackground">
                                    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                                        <div class="example-box"
                                            *ngFor="let block of opportunityObject.contentTemplate.blocks" cdkDrag>
                                            {{block.type}}</div>
                                    </div>
                                    <nz-progress [nzPercent]="30"></nz-progress>
                                    <div class="loading-element-wrapper">
                                        <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"
                                            style="width: 250px;
                            height: 40px;
                            margin: auto;
                            margin-top: 32px;"></nz-skeleton>
                                        <table>
                                            <tr>
                                                <td><nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }"
                                                        [nzActive]="true"></nz-skeleton></td>
                                                <td><nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }"
                                                        [nzActive]="true"></nz-skeleton></td>
                                                <td><nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }"
                                                        [nzActive]="true"></nz-skeleton></td>
                                                <td><nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }"
                                                        [nzActive]="true"></nz-skeleton></td>
                                            </tr>
                                        </table>
                                        <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }"
                                            [nzActive]="true"></nz-skeleton>
                                        <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }"
                                            [nzActive]="true"></nz-skeleton>
                                        <nz-skeleton-element nzType="image" [nzActive]="true"
                                            class="picker"></nz-skeleton-element>
                                        <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }"
                                            [nzActive]="true"></nz-skeleton>
                                    </div>
                                    <div class="example-list">
                                        <div class="example-box" *ngFor="let movie of movies" cdkDrag>{{movie}}</div>
                                        <ng-container
                                            *ngFor="let block of opportunityObject.contentTemplate.blocks; let index=index">
                                            <div class="email-block-outer">
                                                <div nz-row>
                                                    <div nz-col nzFlex="75px">
                                                        <div class="email-block-controls">
                                                            <button nzShape="circle" nz-button nzType="default"
                                                                (click)="moveUp(block, index)"><span nz-icon nzType="up"
                                                                    nzTheme="outline"></span></button><br>
                                                            <button nz-button nzType="default" nzShape="circle"
                                                                (click)="moveDown(block, index)"><span nz-icon
                                                                    nzType="down"></span></button>
                                                            <button nz-button nzType="default" nzShape="circle"><span
                                                                    nz-icon nzType="drag"
                                                                    nzTheme="outline"></span></button>
                                                        </div>
                                                    </div>
                                                    <div nz-col nzFlex="600px">

                                                        <ng-container *ngIf="block.type == 'LOGO'">


                                                            <div id="{{'block-' + index}}"
                                                                class="email-block-wrapper padding24"
                                                                (click)="selectBlock(block)"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                <div class="email-logo-image-wrapper">
                                                                    <img class="email-logo-image"
                                                                        src="{{block.properties[0].value}}" />
                                                                </div>

                                                            </div>


                                                        </ng-container>

                                                        <ng-container *ngIf="block.type == 'TITLE'">
                                                            <div id="{{'block-' + index}}"
                                                                class="email-block-wrapper padding32"
                                                                (click)="selectBlock(block)">
                                                                <div class="email-hero-title">
                                                                    {{block.properties[0].value}}</div>
                                                                <div class="email-hero-subtitle">
                                                                    {{block.properties[1].value}}</div>
                                                            </div>
                                                        </ng-container>

                                                        <ng-container *ngIf="block.type == 'BUTTON'">

                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper padding40"
                                                                    (click)="selectBlock(block)"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <div class="email-button"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                        {{block.properties[0].value}}</div>
                                                                </div>
                                                            </ng-container>

                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'OVERIMAGE'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper"
                                                                    (click)="selectBlock(block)">
                                                                    <div class="email-cta-background"
                                                                        [style.background-image]="'url('+getPropertyValue(block, 'Image')+')'">
                                                                        <div class="email-button"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                            [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                            {{getPropertyValue(block, 'Button
                                                                            text')}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>

                                                        </ng-container>

                                                        <ng-container *ngIf="block.type == 'PROMOTION'">

                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper padding40"
                                                                    (click)="selectBlock(block)"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <div class="email-promotion-title"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div>
                                                                    <div class="email-promotion-subtitle"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                        {{getPropertyValue(block, 'Body')}}
                                                                    </div>
                                                                    <div class="email-promotion-code"
                                                                        [style.color]="getPropertyValue(block, 'Promo color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Promo background color')"
                                                                        [style.color]="getPropertyValue(block, 'Promo text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                        {{getPropertyValue(block, 'Promo
                                                                        code')}}
                                                                    </div>
                                                                    <div class="email-button"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                        {{getPropertyValue(block, 'Button')}}</div>
                                                                </div>
                                                            </ng-container>

                                                        </ng-container>

                                                        <ng-container *ngIf="block.type == 'IMAGEFULLWIDTH'">
                                                            <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                                (click)="selectBlock(block)">
                                                                <div class="email-hero-image"
                                                                    [style.backgroundImage]="'url('+block.properties[0].value+')'">
                                                                </div>
                                                            </div>
                                                        </ng-container>

                                                        <ng-container *ngIf="block.type == 'IMAGE'">
                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper"
                                                                    (click)="selectBlock(block)"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <img class="email-image"
                                                                        src="{{block.properties[0].value}}">
                                                                </div>
                                                            </ng-container>

                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'FULLWIDTH'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper"
                                                                    (click)="selectBlock(block)"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <img class="email-image-fullwidth"
                                                                        src="{{block.properties[0].value}}">
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>

                                                        <ng-container *ngIf="block.type == 'BLURB'">
                                                            <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                                (click)="selectBlock(block)"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                [style.color]="getPropertyValue(block, 'Text color')">
                                                                <div class="email-body-text">
                                                                    {{getPropertyValue(block,
                                                                    'Text')}}
                                                                </div>
                                                            </div>
                                                        </ng-container>

                                                        <ng-container *ngIf="block.type == 'GALLERY'">
                                                            <div id="{{'block-' + index}}"
                                                                class="email-block-wrapper padding32"
                                                                [style.backgroundColor]="block.properties[5].value"
                                                                (click)="selectBlock(block)">
                                                                <div class="email-gallery-title">
                                                                    {{block.properties[4].value}}</div>
                                                                <table class="email-gallery-table">
                                                                    <tr>
                                                                        <td><img class="email-gallery-image"
                                                                                src="{{block.properties[0].value}}" />
                                                                        </td>
                                                                        <td><img class="email-gallery-image"
                                                                                src="{{block.properties[1].value}}" />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><img class="email-gallery-image"
                                                                                src="{{block.properties[2].value}}" />
                                                                        </td>
                                                                        <td><img class="email-gallery-image"
                                                                                src="{{block.properties[3].value}}" />
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </ng-container>

                                                        <ng-container *ngIf="block.type == 'REVIEW'">
                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper padding32"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                    (click)="selectBlock(block)">
                                                                    <div class="email-review-wrapper ">
                                                                        <div class="email-review-stars"
                                                                            [style.color]="getPropertyValue(block, 'Decoration color')">
                                                                            <span nz-icon nzType="star"
                                                                                nzTheme="fill"></span><span nz-icon
                                                                                nzType="star"
                                                                                nzTheme="fill"></span><span nz-icon
                                                                                nzType="star"
                                                                                nzTheme="fill"></span><span nz-icon
                                                                                nzType="star"
                                                                                nzTheme="fill"></span><span nz-icon
                                                                                nzType="star" nzTheme="fill"></span>
                                                                        </div>
                                                                        <div class="email-review-title"
                                                                            [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont"
                                                                            [style.color]="getPropertyValue(block, 'Title color')">
                                                                            {{getPropertyValue(block, 'Title')}}
                                                                        </div>
                                                                        <div class="email-review-text"
                                                                            [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont"
                                                                            [style.color]="getPropertyValue(block, 'Text color')">
                                                                            "{{getPropertyValue(block,
                                                                            'Quote')}}"
                                                                        </div>
                                                                        <div class="email-review-name"
                                                                            [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont"
                                                                            [style.color]="getPropertyValue(block, 'Name color')">
                                                                            -
                                                                            {{getPropertyValue(block, 'Name')}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>

                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'BUBBLE'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper"
                                                                    (click)="selectBlock(block)"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <div class="email-review-wrapper bubble"
                                                                        [style.borderColor]="getPropertyValue(block, 'Text color')">
                                                                        <div class="email-review-stars"
                                                                            [style.color]="getPropertyValue(block, 'Decoration color')">
                                                                            <span nz-icon nzType="star"
                                                                                nzTheme="fill"></span><span nz-icon
                                                                                nzType="star"
                                                                                nzTheme="fill"></span><span nz-icon
                                                                                nzType="star"
                                                                                nzTheme="fill"></span><span nz-icon
                                                                                nzType="star"
                                                                                nzTheme="fill"></span><span nz-icon
                                                                                nzType="star" nzTheme="fill"></span>
                                                                        </div>
                                                                        <div class="email-review-title"
                                                                            [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont"
                                                                            [style.color]="getPropertyValue(block, 'Title color')">
                                                                            {{getPropertyValue(block, 'Title')}}
                                                                        </div>
                                                                        <div class="email-review-text"
                                                                            [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont"
                                                                            [style.color]="getPropertyValue(block, 'Text color')">
                                                                            "{{getPropertyValue(block,
                                                                            'Quote')}}"
                                                                        </div>
                                                                        <div class="email-review-name"
                                                                            [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont"
                                                                            [style.color]="getPropertyValue(block, 'Name color')">
                                                                            -
                                                                            {{getPropertyValue(block, 'Name')}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>

                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'BIG'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper"
                                                                    (click)="selectBlock(block)">
                                                                    <div class="email-review-wrapper big"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                        <div class="email-review-title"
                                                                            [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont"
                                                                            [style.color]="getPropertyValue(block, 'Title color')">
                                                                            {{getPropertyValue(block, 'Title')}}
                                                                        </div>
                                                                        <div class="email-review-big-quote"
                                                                            [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont"
                                                                            [style.color]="getPropertyValue(block, 'Name color')">
                                                                            “”
                                                                        </div>
                                                                        <div class="email-review-text big"
                                                                            [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont"
                                                                            [style.color]="getPropertyValue(block, 'Text color')">
                                                                            "{{getPropertyValue(block,
                                                                            'Quote')}}"
                                                                        </div>
                                                                        <div class="email-review-stars"
                                                                            [style.color]="getPropertyValue(block, 'Decoration color')">
                                                                            <span nz-icon nzType="star"
                                                                                nzTheme="fill"></span><span nz-icon
                                                                                nzType="star"
                                                                                nzTheme="fill"></span><span nz-icon
                                                                                nzType="star"
                                                                                nzTheme="fill"></span><span nz-icon
                                                                                nzType="star"
                                                                                nzTheme="fill"></span><span nz-icon
                                                                                nzType="star" nzTheme="fill"></span>
                                                                        </div>
                                                                        <div class="email-review-name big"
                                                                            [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont"
                                                                            [style.color]="getPropertyValue(block, 'Name color')">
                                                                            -
                                                                            {{getPropertyValue(block, 'Name')}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>

                                                        </ng-container>

                                                        <ng-container *ngIf="block.type == 'HERO'">
                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper"
                                                                    (click)="selectBlock(block)">
                                                                    <div class="on-block-hover">New block</div>
                                                                    <div class="email-hero-background"
                                                                        [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                                        <div class="email-hero-opacity">
                                                                            <div class="email-hero-title"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                                {{getPropertyValue(block,
                                                                                'Title')}}
                                                                            </div>
                                                                            <div class="email-hero-subtitle"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                {{getPropertyValue(block,
                                                                                'Subtitle')}}
                                                                            </div>
                                                                            <div class="email-hero-body"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                {{getPropertyValue(block, 'Body')}}
                                                                            </div>
                                                                            <div class="email-hero-button"
                                                                                [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                {{getPropertyValue(block,
                                                                                'Button')}}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'SEPARATEIMAGE'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper"
                                                                    (click)="selectBlock(block)"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <div class="email-hero-background"
                                                                        [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                                    </div>
                                                                    <div class="email-hero-title"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                        {{block.properties[0].value}}
                                                                    </div>
                                                                    <div class="email-hero-subtitle"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                        {{block.properties[1].value}}
                                                                    </div>
                                                                    <div class="email-hero-button"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                        {{getPropertyValue(block, 'Button')}}
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'MIDDLEIMAGE'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper"
                                                                    (click)="selectBlock(block)"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <div class="email-hero-title"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                        {{block.properties[0].value}}
                                                                    </div>
                                                                    <div class="email-hero-subtitle middleimage"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                        {{block.properties[1].value}}
                                                                    </div>
                                                                    <div class="email-hero-background"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                                    </div>
                                                                    <div class="email-hero-button"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                        {{getPropertyValue(block, 'Button')}}
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'CONTAINEDIMAGESQUARE'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper"
                                                                    (click)="selectBlock(block)"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <div class="email-hero-title"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                        {{block.properties[0].value}}
                                                                    </div>
                                                                    <div class="email-hero-subtitle middleimage"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                        {{block.properties[1].value}}
                                                                    </div>
                                                                    <div class="email-hero-background contained-image-square"
                                                                        [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                                    </div>
                                                                    <div class="email-hero-button"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                        {{getPropertyValue(block, 'Button')}}

                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'CONTAINEDIMAGEROUNDED'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper"
                                                                    (click)="selectBlock(block)"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <div class="email-hero-title"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                        {{block.properties[0].value}}
                                                                    </div>
                                                                    <div class="email-hero-subtitle middleimage"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                        {{block.properties[1].value}}
                                                                    </div>
                                                                    <div class="email-hero-background contained-image-rounded"
                                                                        [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                                    </div>
                                                                    <div class="email-hero-button"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                        {{getPropertyValue(block, 'Button')}}
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'CONTAINEDIMAGECIRCLE'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper"
                                                                    (click)="selectBlock(block)"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <div class="email-hero-title"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                        {{block.properties[0].value}}
                                                                    </div>
                                                                    <div class="email-hero-subtitle middleimage"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                        {{block.properties[1].value}}
                                                                    </div>
                                                                    <div class="email-hero-background contained-image-circle"
                                                                        [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                                    </div>
                                                                    <div class="email-hero-button"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                        {{getPropertyValue(block, 'Button')}}
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'TITLEONIMAGE'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper"
                                                                    (click)="selectBlock(block)"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <div class="on-block-hover">New block</div>
                                                                    <div class="email-hero-background"
                                                                        [style.background-image]="'url('+getPropertyValue(block, 'Background image')+')'">
                                                                        <div class="email-hero-opacity">
                                                                            <div class="email-hero-title"
                                                                                [style.color]="getPropertyValue(block, 'Text color')"
                                                                                [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                                {{getPropertyValue(block,
                                                                                'Title')}}
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div class="email-hero-subtitle"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                        {{getPropertyValue(block, 'Subtitle')}}
                                                                    </div>
                                                                    <div class="email-hero-body"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                        {{getPropertyValue(block, 'Body')}}
                                                                    </div>
                                                                    <div class="email-hero-button"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                        {{getPropertyValue(block, 'Button')}}
                                                                    </div>

                                                                </div>
                                                            </ng-container>
                                                        </ng-container>

                                                        <ng-container *ngIf="block.type == 'CATEGORY'">
                                                            <div id="{{'block-' + index}}" class="email-block-wrapper"
                                                                [style.backgroundColor]="block.properties[3].value"
                                                                (click)="selectBlock(block)">
                                                                <table class="email-category-table">
                                                                    <tr>
                                                                        <ng-container *ngIf="block.layout == 'RIGHT'">
                                                                            <td
                                                                                style="width: 50%; text-align: center; ">
                                                                                <div class="email-category-title">
                                                                                    {{block.properties[1].value}}
                                                                                </div>
                                                                                <div class="email-category-button"
                                                                                    [style.background]="opportunityObject.contentTemplate.buttonColor">
                                                                                    {{block.properties[2].value}}
                                                                                </div>
                                                                            </td>
                                                                            <td style="width: 50%"><img
                                                                                    class="email-gallery-image"
                                                                                    src="{{block.properties[0].value}}" />
                                                                            </td>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="block.layout != 'RIGHT'">
                                                                            <td style="width: 50%"><img
                                                                                    class="email-gallery-image"
                                                                                    src="{{block.properties[0].value}}" />
                                                                            </td>
                                                                            <td style="width: 50%; text-align: center;">
                                                                                <div class="email-category-title">
                                                                                    {{block.properties[1].value}}
                                                                                </div>
                                                                                <div class="email-category-button"
                                                                                    [style.background]="opportunityObject.contentTemplate.buttonColor">
                                                                                    {{getPropertyValue(block,
                                                                                    'Button')"}}
                                                                                </div>
                                                                            </td>
                                                                        </ng-container>

                                                                    </tr>
                                                                </table>

                                                            </div>
                                                        </ng-container>

                                                        <ng-container *ngIf="block.type == 'FOOTER'">
                                                            <div id="{{'block-' + index}}"
                                                                class="email-block-wrapper padding24"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                (click)="selectBlock(block)">
                                                                <div class="email-logo-image-wrapper" id="footer-logo">
                                                                    <img class="email-logo-image"
                                                                        src="{{block.properties[0].value}}" />
                                                                </div>
                                                                <div class="social-links-wrapper"
                                                                    id="footer-social-links"
                                                                    *ngIf="getArrayItem(block.properties, 'Social links').value.length > 0">
                                                                    <ng-container
                                                                        *ngFor="let item of getArrayItem(block.properties, 'Social links').value; let itemIndex=index">
                                                                        <span
                                                                            id="{{'block-' + index + '-' + itemIndex}}"
                                                                            [style.color]="getPropertyValue(block, 'Social icon color')"
                                                                            nz-icon
                                                                            [nzType]="getArrayItem(item, 'Platform').value.value"
                                                                            nzTheme="outline"></span>
                                                                    </ng-container>

                                                                    <svg style="display: inline-block; margin-right: 16px; font-size: 24px;"
                                                                        xmlns="http://www.w3.org/2000/svg" height="1em"
                                                                        viewBox="0 0 512 512">
                                                                        <path
                                                                            d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
                                                                    </svg>
                                                                    <svg style="display: inline-block; font-size: 24px;"
                                                                        xmlns="http://www.w3.org/2000/svg" height="1em"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                                                                    </svg>
                                                                </div>
                                                                <div class="email-disclaimer-wrapper"
                                                                    id="footer-disclaimer"
                                                                    *ngIf="getPropertyValue(block, 'Disclaimer')"
                                                                    [style.color]="getPropertyValue(block, 'Disclaimer text color')">
                                                                    {{getPropertyValue(block, 'Disclaimer')}}
                                                                </div>
                                                                <div class="email-unsubscribe-wrapper"
                                                                    id="footer-unsubscribe"
                                                                    [style.color]="getPropertyValue(block, 'Disclaimer text color')">
                                                                    {{getPropertyValue(block, 'Unsubscribe
                                                                    text')}}
                                                                    Unsubscribe here
                                                                </div>
                                                            </div>
                                                        </ng-container>

                                                        <ng-container *ngIf="block.type == 'NAVBAR'">
                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper"
                                                                    (click)="selectBlock(block)"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <table class="email-category-table"
                                                                        style="width: 100%; font-weight: 600; table-layout: fixed;">
                                                                        <tr>
                                                                            <ng-container
                                                                                *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">
                                                                                <td style="text-align: center; padding: 16px 0px;"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    id="{{'block-' + index + '-' + itemIndex}}">
                                                                                    {{getArrayItem(item,
                                                                                    'Title').value}}
                                                                                </td>
                                                                            </ng-container>

                                                                            <td style="width: 25%; text-align: center;"
                                                                                [style.color]="getPropertyValue(block, 'Text color')">
                                                                                {{block.properties[1].value}}
                                                                            </td>
                                                                            <td style="width: 25%; text-align: center;"
                                                                                [style.color]="getPropertyValue(block, 'Text color')">
                                                                                {{block.properties[2].value}}
                                                                            </td>
                                                                            <td style="width: 25%; text-align: center;"
                                                                                [style.color]="getPropertyValue(block, 'Text color')">
                                                                                {{block.properties[3].value}}
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </ng-container>

                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'GRID'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper padding32"
                                                                    (click)="selectBlock(block)"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <table class="email-category-table"
                                                                        style="width: 100%; font-weight: 600; margin: 0px 0px 16px;">
                                                                        <ng-container
                                                                            *ngIf="getArrayItem(block.properties, 'Items').value[0] && getArrayItem(block.properties, 'Items').value[1] && getArrayItem(block.properties, 'Items').value[2] && getArrayItem(block.properties, 'Items').value[3]"></ng-container>
                                                                        <tr>
                                                                            <td id="{{'block-' + index + '-0'}}"
                                                                                style="width: 50%; text-align: center; padding: 32px; border-bottom: 1px solid #d9d9d9; border-right: 1px solid #d9d9d9"
                                                                                [style.color]="getPropertyValue(block, 'Text color')">
                                                                                {{getArrayItem(getArrayItem(block.properties,
                                                                                'Items').value[0],
                                                                                'Title').value}}
                                                                            </td>
                                                                            <td id="{{'block-' + index + '-1'}}"
                                                                                style="width: 50%; text-align: center; padding: 32px; border-bottom: 1px solid #d9d9d9"
                                                                                [style.color]="getPropertyValue(block, 'Text color')">
                                                                                {{getArrayItem(getArrayItem(block.properties,
                                                                                'Items').value[1],
                                                                                'Title').value}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="{{'block-' + index + '-2'}}"
                                                                                style="width: 50%; text-align: center; padding: 32px; border-right: 1px solid #d9d9d9"
                                                                                [style.color]="getPropertyValue(block, 'Text color')">
                                                                                {{getArrayItem(getArrayItem(block.properties,
                                                                                'Items').value[2],
                                                                                'Title').value}}
                                                                            </td>
                                                                            <td id="{{'block-' + index + '-3'}}"
                                                                                style="width: 50%; text-align: center; padding: 32px;"
                                                                                [style.color]="getPropertyValue(block, 'Text color')">
                                                                                {{getArrayItem(getArrayItem(block.properties,
                                                                                'Items').value[3],
                                                                                'Title').value}}
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </ng-container>

                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'VERTICAL'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper padding16"
                                                                    (click)="selectBlock(block)"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <table class="email-category-table"
                                                                        style="width: 100%; font-weight: 600; margin: 0px 0px 16px;">
                                                                        <ng-container
                                                                            *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">
                                                                            <tr>
                                                                                <td id="{{'block-' + index + '-' + itemIndex}}"
                                                                                    style="text-align: center; padding: 16px; border-bottom: 1px solid #d9d9d9;"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')">
                                                                                    {{getArrayItem(item,
                                                                                    'Title').value}}
                                                                                </td>
                                                                            </tr>
                                                                        </ng-container>


                                                                    </table>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>

                                                        <ng-container *ngIf="block.type == 'LISTICLE'">
                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper padding16"
                                                                    (click)="selectBlock(block)"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <ng-container
                                                                        *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">
                                                                        <div class="email-listicle-item"
                                                                            [style.backgroundColor]="getPropertyValue(block, 'Card color')">
                                                                            <div nz-row>
                                                                                <div nz-col [nzSpan]="4">
                                                                                    <div class="sticker"
                                                                                        [style.backgroundColor]="getPropertyValue(block, 'Sticker color')">
                                                                                        {{itemIndex+1}}</div>
                                                                                </div>
                                                                                <div nz-col [nzSpan]="20">

                                                                                    <div class="email-listicle-title"
                                                                                        [style.color]="getPropertyValue(block, 'Title text color')"
                                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.titleFont">
                                                                                        {{getArrayItem(item,
                                                                                        'Title').value}}
                                                                                    </div>
                                                                                    <div class="email-listicle-body"
                                                                                        [style.color]="getPropertyValue(block, 'Body text color')"
                                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                        {{getArrayItem(item,
                                                                                        'Body').value}}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'ALTERNATING'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper padding16"
                                                                    (click)="selectBlock(block)">
                                                                    <ng-container
                                                                        *ngFor="let item of getArrayItem(block.properties, 'Items').value; let itemIndex=index">

                                                                        <ng-container *ngIf="!isEven(itemIndex)">
                                                                            <div class="email-listicle-item right"
                                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                                <div class="email-listicle-title"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.titleFont">
                                                                                    {{getArrayItem(item,
                                                                                    'Title').value}}
                                                                                </div>
                                                                                <div class="email-listicle-body"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                    {{getArrayItem(item,
                                                                                    'Body').value}}
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container *ngIf="isEven(itemIndex)">
                                                                            <div class="email-listicle-item left"
                                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                                <div class="email-listicle-title"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.titleFont">
                                                                                    {{getArrayItem(item,
                                                                                    'Title').value}}
                                                                                </div>
                                                                                <div class="email-listicle-body"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                    {{getArrayItem(item,
                                                                                    'Body').value}}
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                    </ng-container>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>

                                                        <ng-container *ngIf="block.type == 'ARTICLE'">
                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper"
                                                                    (click)="selectBlock(block)"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <div class="email-article-title"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                        {{block.properties[0].value}}
                                                                    </div>
                                                                    <div class="email-article-subtitle"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                        {{block.properties[1].value}}
                                                                    </div>
                                                                    <div class="email-article-image contained-image-rounded"
                                                                        [style.background-image]="'url('+getPropertyValue(block, 'Image')+')'">
                                                                    </div>
                                                                    <div class="email-article-body"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                        {{getPropertyValue(block, 'Body')}}
                                                                    </div>
                                                                    <div class="email-hero-button"
                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                        {{getPropertyValue(block, 'Button
                                                                        text')}}
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>

                                                        <ng-container *ngIf="block.type == 'FEATURES'">
                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper padding32"
                                                                    (click)="selectBlock(block)"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                    <table class="email-category-table"
                                                                        style="width: 100%; font-weight: 600; margin: 0px 0px 16px;">
                                                                        <tr>
                                                                            <td style="width: 25%; text-align: center;"
                                                                                [style.color]="getPropertyValue(block, 'Icon color')">
                                                                                <div class="email-features-icon">
                                                                                    <span nz-icon
                                                                                        [nzType]="getPropertyValue(block, 'Icon 1')"
                                                                                        nzTheme="outline"></span>
                                                                                </div>
                                                                            </td>
                                                                            <td style="width: 25%; text-align: center;"
                                                                                [style.color]="getPropertyValue(block, 'Icon color')">
                                                                                <div class="email-features-icon">
                                                                                    <span nz-icon
                                                                                        [nzType]="getPropertyValue(block, 'Icon 2')"
                                                                                        nzTheme="outline"></span>
                                                                                </div>
                                                                            </td>
                                                                            <td style="width: 25%; text-align: center;"
                                                                                [style.color]="getPropertyValue(block, 'Icon color')">
                                                                                <div class="email-features-icon">
                                                                                    <span nz-icon
                                                                                        [nzType]="getPropertyValue(block, 'Icon 3')"
                                                                                        nzTheme="outline"></span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style="width: 25%; text-align: center; vertical-align: top;"
                                                                                [style.color]="getPropertyValue(block, 'Text color')">
                                                                                <div class="email-features-label">
                                                                                    {{getPropertyValue(block,
                                                                                    'Text
                                                                                    1')}}</div>
                                                                            </td>
                                                                            <td style="width: 25%; text-align: center; vertical-align: top;"
                                                                                [style.color]="getPropertyValue(block, 'Text color')">
                                                                                <div class="email-features-label">
                                                                                    {{getPropertyValue(block,
                                                                                    'Text
                                                                                    2')}}</div>
                                                                            </td>
                                                                            <td style="width: 25%; text-align: center; vertical-align: top;"
                                                                                [style.color]="getPropertyValue(block, 'Text color')">
                                                                                <div class="email-features-label">
                                                                                    {{getPropertyValue(block,
                                                                                    'Text
                                                                                    3')}}</div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>

                                                        <ng-container *ngIf="block.type == 'PRODUCT'">
                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper padding40"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                    (click)="selectBlock(block)">
                                                                    <div class="email-product-block-title"
                                                                        id="{{'product-title-' + index}}"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div>
                                                                    <div class="email-product-table" nzJustify="center">
                                                                        <ng-container
                                                                            *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                            <div id="{{'block-' + index + '-' + itemIndex}}"
                                                                                class="email-product-item-small"
                                                                                style="text-align: center">
                                                                                <img class="email-product-image-small"
                                                                                    src="{{item.imageUrl}}" />
                                                                                <div class="email-product-title-small"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                                    {{item.title}}</div>
                                                                                <div class="email-product-button-small"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                    [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                    {{getPropertyValue(block,
                                                                                    'Button
                                                                                    text')}}
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                    </div>


                                                                </div>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARDLARGE'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper padding40"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                    (click)="selectBlock(block)">
                                                                    <div class="email-product-block-title"
                                                                        id="{{'product-title-' + index}}"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div>
                                                                    <div class="email-product-table" nzJustify="center">
                                                                        <ng-container
                                                                            *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                            <div id="{{'block-' + index + '-' + itemIndex}}"
                                                                                class="email-product-item"
                                                                                style="text-align: center">
                                                                                <img class="email-product-image-large"
                                                                                    src="{{item.imageUrl}}" />
                                                                                <div class="email-product-title-small"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                                    {{item.title}}</div>
                                                                                <div class="email-product-button-large"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                    [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                    {{getPropertyValue(block,
                                                                                    'Button
                                                                                    text')}}
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                            </ng-container>

                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARDXL'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper padding40"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                    (click)="selectBlock(block)">
                                                                    <div class="email-product-block-title"
                                                                        id="{{'product-title-' + index}}"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div>
                                                                    <div class="email-product-table" nzJustify="center">
                                                                        <ng-container
                                                                            *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                            <div id="{{'block-' + index + '-' + itemIndex}}"
                                                                                class="email-product-item-xl"
                                                                                style="text-align: center">
                                                                                <img class="email-product-image-large"
                                                                                    src="{{item.imageUrl}}" />
                                                                                <div class="email-product-title-xl"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                                    {{item.title}}</div>
                                                                                <div class="email-product-description"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                    {{item.description}}</div>
                                                                                <div class="email-product-button-large"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                    [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                    {{getPropertyValue(block,
                                                                                    'Button
                                                                                    text')}}
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                            </ng-container>

                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'HORIZONTALBLOCK'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper padding40"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                    (click)="selectBlock(block)">
                                                                    <div class="email-product-block-title"
                                                                        id="{{'product-title-' + index}}"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div>
                                                                    <table class="email-category-table">
                                                                        <ng-container
                                                                            *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                            <tr
                                                                                id="{{'block-' + index + '-' + itemIndex}}">

                                                                                <td style="width: 50%"><img
                                                                                        [src]="item.imageUrl" />
                                                                                </td>
                                                                                <td
                                                                                    style="width: 50%; text-align: center;">
                                                                                    <div class="email-category-title"
                                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                                        {{item.title}}</div>
                                                                                    <div class="email-product-description"
                                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                        {{item.description}}</div>
                                                                                    <div class="email-product-button-small"
                                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                        {{getPropertyValue(block,
                                                                                        'Button text')}}
                                                                                    </div>
                                                                                </td>

                                                                            </tr>
                                                                        </ng-container>
                                                                    </table>
                                                                </div>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'HORIZONTALBLOCKALTERNATING'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper padding40"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                    (click)="selectBlock(block)">
                                                                    <div class="email-product-block-title"
                                                                        id="{{'product-title-' + index}}"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div>
                                                                    <table class="email-category-table">
                                                                        <ng-container
                                                                            *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                            <ng-container *ngIf="itemIndex % 2 == 0">
                                                                                <tr
                                                                                    id="{{'block-' + index + '-' + itemIndex}}">

                                                                                    <td style="width: 50%"><img
                                                                                            [src]="item.imageUrl" />
                                                                                    </td>
                                                                                    <td
                                                                                        style="width: 50%; text-align: center;">
                                                                                        <div class="email-category-title"
                                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                                            [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                                            {{item.title}}</div>
                                                                                        <div class="email-product-description"
                                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                                            [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                            {{item.description}}</div>
                                                                                        <div class="email-product-button-small"
                                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                            [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                            {{getPropertyValue(block,
                                                                                            'Button
                                                                                            text')}}
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-container>
                                                                            <ng-container *ngIf="itemIndex % 2 != 0">
                                                                                <tr
                                                                                    id="{{'block-' + index + '-' + itemIndex}}">
                                                                                    <td
                                                                                        style="width: 50%; text-align: center;">
                                                                                        <div class="email-category-title"
                                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                                            [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                                            {{item.title}}</div>
                                                                                        <div class="email-product-description"
                                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                                            [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                            {{item.description}}</div>
                                                                                        <div class="email-product-button-small"
                                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                            [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                            {{getPropertyValue(block,
                                                                                            'Button
                                                                                            text')}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td style="width: 50%"><img
                                                                                            [src]="item.imageUrl" />
                                                                                    </td>
                                                                                </tr>

                                                                            </ng-container>



                                                                        </ng-container>
                                                                    </table>
                                                                </div>
                                                            </ng-container>

                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'HORIZONTAL'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper padding40"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                    (click)="selectBlock(block)">
                                                                    <div class="email-product-block-title"
                                                                        id="{{'product-title-' + index}}"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div>
                                                                    <table class="email-category-table">
                                                                        <ng-container
                                                                            *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                            <tr
                                                                                id="{{'block-' + index + '-' + itemIndex}}">

                                                                                <td style="width: 50%; padding: 32px;">
                                                                                    <img class="email-gallery-image"
                                                                                        [src]="item.imageUrl" />
                                                                                </td>
                                                                                <td
                                                                                    style="width: 50%; text-align: center; padding: 16px">
                                                                                    <div class="email-category-title"
                                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                                        {{item.title}}</div>
                                                                                    <div class="email-product-description"
                                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                        {{(item.description.length>50)?
                                                                                        (item.description |
                                                                                        slice:0:100)+'...':(item.description)}}
                                                                                    </div>
                                                                                    <div class="email-product-button-small"
                                                                                        [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                        [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                        [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                        {{getPropertyValue(block,
                                                                                        'Button text')}}
                                                                                    </div>
                                                                                </td>

                                                                            </tr>
                                                                        </ng-container>
                                                                    </table>
                                                                </div>
                                                            </ng-container>

                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'HORIZONTALALTERNATING'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper padding40"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                    (click)="selectBlock(block)">
                                                                    <div class="email-product-block-title"
                                                                        id="{{'product-title-' + index}}"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div>
                                                                    <table class="email-category-table">
                                                                        <ng-container
                                                                            *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                            <ng-container *ngIf="itemIndex % 2 == 0">
                                                                                <tr
                                                                                    id="{{'block-' + index + '-' + itemIndex}}">

                                                                                    <td
                                                                                        style="width: 50%; padding: 32px;">
                                                                                        <img class="email-gallery-image"
                                                                                            [src]="item.imageUrl" />
                                                                                    </td>
                                                                                    <td
                                                                                        style="width: 50%; text-align: center; padding: 16px">
                                                                                        <div class="email-category-title"
                                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                                            [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                                            {{item.title}}</div>
                                                                                        <div class="email-product-description"
                                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                                            [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                            {{(item.description.length>50)?
                                                                                            (item.description |
                                                                                            slice:0:100)+'...':(item.description)}}
                                                                                        </div>
                                                                                        <div class="email-product-button-small"
                                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                            [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                            {{getPropertyValue(block,
                                                                                            'Button
                                                                                            text')}}
                                                                                        </div>
                                                                                    </td>

                                                                                </tr>
                                                                            </ng-container>
                                                                            <ng-container *ngIf="itemIndex % 2 != 0">
                                                                                <tr
                                                                                    id="{{'block-' + index + '-' + itemIndex}}">


                                                                                    <td
                                                                                        style="width: 50%; text-align: center; padding: 16px">
                                                                                        <div class="email-category-title"
                                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                                            [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                                            {{item.title}}</div>
                                                                                        <div class="email-product-description"
                                                                                            [style.color]="getPropertyValue(block, 'Text color')"
                                                                                            [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                            {{(item.description.length>50)?
                                                                                            (item.description |
                                                                                            slice:0:100)+'...':(item.description)}}
                                                                                        </div>
                                                                                        <div class="email-product-button-small"
                                                                                            [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                            [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                            [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                            [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                            {{getPropertyValue(block,
                                                                                            'Button
                                                                                            text')}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td
                                                                                        style="width: 50%; padding: 32px;">
                                                                                        <img class="email-gallery-image"
                                                                                            [src]="item.imageUrl" />
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-container>



                                                                        </ng-container>
                                                                    </table>
                                                                </div>
                                                            </ng-container>

                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARDXL2IMAGES'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper padding40"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')"
                                                                    (click)="selectBlock(block)">
                                                                    <div class="email-product-block-title"
                                                                        id="{{'product-title-' + index}}"
                                                                        [style.color]="getPropertyValue(block, 'Text color')"
                                                                        [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                        {{getPropertyValue(block, 'Title')}}
                                                                    </div>
                                                                    <div class="email-product-table" nzJustify="center">
                                                                        <ng-container
                                                                            *ngFor="let item of getProperty(block, 'Products').value; let itemIndex=index">
                                                                            <div class="email-product-item-xl"
                                                                                style="text-align: center; max-width: unset; padding: 24px;"
                                                                                id="{{'block-' + index + '-' + itemIndex}}">
                                                                                <tr>
                                                                                    <td style="width: 50%;"><img
                                                                                            class="email-product-image-large"
                                                                                            src="{{item.imageUrl}}" />
                                                                                    </td>
                                                                                    <td style="width: 50%;"><img
                                                                                            class="email-product-image-large"
                                                                                            src="{{item.imageUrl}}" />
                                                                                    </td>
                                                                                </tr>

                                                                                <div class="email-product-title-xl"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont"
                                                                                    style="text-align: center;">
                                                                                    {{item.title}}</div>
                                                                                <div class="email-product-description"
                                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                                    [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                    {{item.description}}</div>
                                                                                <br>
                                                                                <div class="email-product-button-large"
                                                                                    [style.backgroundColor]="getPropertyValue(block, 'Button color')"
                                                                                    [style.color]="getPropertyValue(block, 'Button text color')"
                                                                                    [style.borderRadius]="getPropertyValue(block, 'Border radius')"
                                                                                    [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.bodyFont">
                                                                                    {{getPropertyValue(block,
                                                                                    'Button
                                                                                    text')}}
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>

                                                        <ng-container *ngIf="block.type == 'BANNER'">

                                                            <div id="{{'block-' + index}}"
                                                                class="email-block-wrapper padding16"
                                                                (click)="selectBlock(block)"
                                                                [style.backgroundColor]="getPropertyValue(block, 'Background color')">
                                                                <div class="email-banner-wrapper"
                                                                    [style.color]="getPropertyValue(block, 'Text color')"
                                                                    [style.fontFamily]="opportunityObject.contentTemplate.brandStyles.headerFont">
                                                                    {{getPropertyValue(block, 'Text')}}
                                                                </div>
                                                            </div>
                                                        </ng-container>

                                                        <ng-container *ngIf="block.type == 'GALLERY'">
                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARD'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper padding24 horizontal"
                                                                    (click)="selectBlock(block)"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">

                                                                    <ng-container
                                                                        *ngFor="let item of getArrayItem(block.properties, 'Images').value; let imageIndex=index">

                                                                        <ng-container
                                                                            *ngIf="imageIndex == 0 || imageIndex == 1">
                                                                            <div class="email-gallery-image w50">
                                                                                <img class="email-gallery-image-img"
                                                                                    src="{{getArrayItem(item, 'Image').value}}" />
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="imageIndex == 2">
                                                                            <div class="email-gallery-image w100">
                                                                                <img class="email-gallery-image-img"
                                                                                    src="{{getArrayItem(item, 'Image').value}}" />
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="imageIndex == 3 || imageIndex == 4 || imageIndex == 5">
                                                                            <div class="email-gallery-image w33">
                                                                                <img class="email-gallery-image-img"
                                                                                    src="{{getArrayItem(item, 'Image').value}}" />
                                                                            </div>
                                                                        </ng-container>

                                                                    </ng-container>

                                                                </div>
                                                            </ng-container>

                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'STANDARDREVERSE'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper padding24 horizontal"
                                                                    (click)="selectBlock(block)"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">

                                                                    <ng-container
                                                                        *ngFor="let item of getArrayItem(block.properties, 'Images').value; let imageIndex=index">

                                                                        <ng-container
                                                                            *ngIf="imageIndex == 1 || imageIndex == 2">
                                                                            <div class="email-gallery-image w50">
                                                                                <img class="email-gallery-image-img"
                                                                                    src="{{getArrayItem(item, 'Image').value}}" />
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="imageIndex == 0">
                                                                            <div class="email-gallery-image w100">
                                                                                <img class="email-gallery-image-img"
                                                                                    src="{{getArrayItem(item, 'Image').value}}" />
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="imageIndex == 3 || imageIndex == 4 || imageIndex == 5">
                                                                            <div class="email-gallery-image w33">
                                                                                <img class="email-gallery-image-img"
                                                                                    src="{{getArrayItem(item, 'Image').value}}" />
                                                                            </div>
                                                                        </ng-container>

                                                                    </ng-container>

                                                                </div>
                                                            </ng-container>

                                                            <ng-container
                                                                *ngIf="getPropertyValue(block, 'Layout').value == 'FIVE'">
                                                                <div id="{{'block-' + index}}"
                                                                    class="email-block-wrapper padding24 horizontal"
                                                                    (click)="selectBlock(block)"
                                                                    [style.backgroundColor]="getPropertyValue(block, 'Background color')">

                                                                    <ng-container
                                                                        *ngFor="let item of getArrayItem(block.properties, 'Images').value; let imageIndex=index">

                                                                        <ng-container
                                                                            *ngIf="imageIndex == 0 || imageIndex == 1">
                                                                            <div class="email-gallery-image w50">
                                                                                <img class="email-gallery-image-img"
                                                                                    src="{{getArrayItem(item, 'Image').value}}" />
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="imageIndex == 2 || imageIndex == 3 || imageIndex == 4">
                                                                            <div class="email-gallery-image w33">
                                                                                <img class="email-gallery-image-img"
                                                                                    src="{{getArrayItem(item, 'Image').value}}" />
                                                                            </div>
                                                                        </ng-container>

                                                                    </ng-container>

                                                                </div>
                                                            </ng-container>
                                                        </ng-container>

                                                    </div>
                                                    <div nz-col nzFlex="75px">

                                                    </div>
                                                </div>
                                            </div>
                                            <ng-container *ngIf="block.type == 'HERO'">
                                                <div class="email-hero-block"
                                                    [style.backgroundColor]="block.backgroundColor">
                                                    <div class="email-logo-image-wrapper">
                                                        <img class="email-logo-image" src="{{block.logoImage}}" />
                                                    </div>

                                                </div>
                                            </ng-container>

                                        </ng-container>
                                    </div>
                                </div>
                            </div> -->
                        </ng-container>

                    </div>
                </ng-container>
            </div>
        </ng-container>



        <!-- <ng-container *ngIf="this.opportunityObject.stage == 'LIVE'">
            <nz-page-header style="margin-top: 40px; padding-bottom: 0px !important" class="site-page-header">
                <nz-page-header-title>Email campaign</nz-page-header-title>
                <nz-page-header-tags>
                    <ng-container *ngIf="sendTimeInFuture()">
                        <nz-tag class="aqua">Scheduled</nz-tag>
                    </ng-container>
                    <ng-container *ngIf="!sendTimeInFuture()">
                        <nz-tag class="aqua">Sent</nz-tag>
                    </ng-container>
                </nz-page-header-tags>
            </nz-page-header>
            <div style="margin-bottom: 24px;">{{opportunityObject.contentTemplate.subjectLine}}</div>
            <ng-container *ngIf="sendTimeInFuture()">
                <div class="custom-alert aqua">
                    This email is scheduled for {{opportunityObject.sendTime | date:'EEEE M/d/YY h:mm a'}}
                    <a href="https://klaviyo.com/campaign/{{opportunityObject.externalData.klaviyoCampaign}}/scheduled"
                        target="_blank"><span style="float: right">View in Klaviyo</span></a>
                </div>
            </ng-container>
            <ng-container *ngIf="!sendTimeInFuture()">
                <div class="custom-alert aqua">
                    This email was sent on {{opportunityObject.sendTime | date:'EEEE M/d/YY h:mm a'}}
                    <a href="https://klaviyo.com/campaign/{{opportunityObject.externalData.klaviyoCampaign}}/scheduled"
                        target="_blank"><span style="float: right">View in Klaviyo</span></a>
                </div>
                <div nz-row [nzGutter]="24">
                    <div nz-col class="gutter-row" [nzSpan]="8">
                        <div class="custom-card">
                            <div class="custom-card-body">
                                <nz-statistic [nzValue]="'$1,240'" [nzTitle]="'Revenue generated'"></nz-statistic>
                            </div>
                        </div>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="8">
                        <div class="custom-card">
                            <div class="custom-card-body">
                                <nz-statistic [nzValue]="'3.4%'" [nzTitle]="'Conversion rate'"></nz-statistic>
                            </div>
                        </div>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="8">
                        <div class="custom-card">
                            <div class="custom-card-body">
                                <nz-statistic [nzValue]="'10.2%'" [nzTitle]="'Open rate'"></nz-statistic>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

        </ng-container> -->
    </div>
</div>

<nz-drawer nzWidth="450px" [nzClosable]="false" [nzVisible]="chatDrawerVisible" nzPlacement="right"
    (nzOnClose)="hideChatDrawer()">
    <ng-container *nzDrawerContent>
        <ng-container *ngFor="let message of chatLog">
            <ng-container *ngIf="message.sender == 'RIPPLE'">
                <div class="message-bubble-ripple">{{message.message}}</div>
            </ng-container>

            <ng-container *ngIf="message.sender == 'USER'">
                <div class="message-bubble-user-wrapper">
                    <div class="message-bubble-user">{{message.message}}</div>
                </div>
            </ng-container>
        </ng-container>
        <div class="message-bubble-ripple" *ngIf="loadingSubjectLineFeedback">
            <div class="typing">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>
        <div class="drawer-footer-wrapper">
            <textarea style="width: calc(100% - 72px)" placeholder="Enter feedback for Copilot" rows="1" nz-input
                [(ngModel)]="subjectLineFeedback"></textarea>
            <button style="float: right" nz-button (click)="sendSubjectLineFeedback()"
                [disabled]="loadingSubjectLineFeedback" nzType="primary">Send</button>
        </div>
    </ng-container>
</nz-drawer>





<!-- <div class="middle-column">
        <div class="right-column-content">
            <div class="messages-wrapper">
                <ng-container *ngFor="let message of opportunityObject.messages">
                    <ng-container *ngIf="message.sender">
                        <div class="message-row">
                            <div class="message-wrapper right">
                                <div class="message-header">
                                    <div class="message-title">{{message.sender}}</div>
                                    <div class="message-date">{{message.createdAt | date}}</div>
                                </div>
                                <div class="message-body">
                                    {{message.body}}
                                </div>
                            </div>
                        </div>

                    </ng-container>
                    <ng-container *ngIf="!message.sender">
                        <div class="message-row">
                            <div class="message-wrapper">
                                <div class="message-header">
                                    <div class="message-title">Copilot</div>
                                    <div class="message-date">11:30am 4/20/23</div>
                                </div>
                                <div class="message-body">
                                    Test
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <div class="textbox-wrapper">
                <textarea placeholder="Type a message..." nz-input [(ngModel)]="this.newMessageBody"></textarea>
                <button nz-button nzType="default" (click)="this.newMessage()">Send</button>
            </div>
        </div>
    </div> -->

<!-- 
    <ng-container *ngIf="!loading">
        <div class="header-wrapper">
            <nz-page-header class="site-page-header">

                <nz-breadcrumb nz-page-header-breadcrumb>
                    <nz-breadcrumb-item>Opportunities</nz-breadcrumb-item>
                    <nz-breadcrumb-item>{{this.opportunityObject.title}}</nz-breadcrumb-item>
                </nz-breadcrumb>
                <nz-page-header-title>{{this.opportunityObject.title}}</nz-page-header-title>
                <nz-page-header-extra>
                    <button nz-button (click)="generateTemplateContent()"><span nz-icon nzType="sync"
                            nzTheme="outline"></span>Try
                        again</button>&nbsp;
                    <button nz-button nzType="primary">Approve</button>
                </nz-page-header-extra>
            </nz-page-header>
        </div>
        <div class="left-column">
            <div class="left-column-content">
                <nz-steps [nzCurrent]="1" nzDirection="vertical">
                    <nz-step nzTitle="Generating proposal"></nz-step>
                    <nz-step nzTitle="Approval"></nz-step>
                    <nz-step nzTitle="Publishing"></nz-step>
                    <nz-step nzTitle="Live"></nz-step>
                </nz-steps>
            </div>
        </div>
        <div class="middle-column">
            <ng-container *ngIf="opportunityObject.stage == 'NEW'">
                <div style="max-width: 600px; margin: auto; margin-top: 24px; text-align: center;">
                    <div class="meter purple">
                        <span style="width: 100%"></span>
                    </div>
                    <h3>Copilot is generating a proposal.</h3>
                    <h3>This may take a moment.</h3>
                </div>
            </ng-container>

            <ng-container *ngIf="opportunityObject.stage != 'NEW'">
                <div class="email-content-wrapper"
                    [style.backgroundColor]="opportunityObject.contentTemplate.templateContentBackground">
                    <ng-container *ngFor="let block of opportunityObject.contentTemplate.blocks">
                        <ng-container *ngIf="block.type == 'LOGO'">
                            <div class="email-block-wrapper" (click)="selectBlock(block)">
                                <div class="email-logo-image-wrapper">
                                    <img class="email-logo-image" src="{{block.properties[0].value}}" />
                                </div>
                            </div>

                        </ng-container>

                        <ng-container *ngIf="block.type == 'TITLE'">
                            <div class="email-block-wrapper" (click)="selectBlock(block)">
                                <div class="email-hero-title">{{block.properties[0].value}}</div>
                                <div class="email-hero-subtitle">{{block.properties[1].value}}</div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="block.type == 'BUTTON'">
                            <div class="email-block-wrapper" (click)="selectBlock(block)">
                                <div class="email-button" [style.backgroundColor]="block.properties[1].value">
                                    {{block.properties[0].value}}</div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="block.type == 'IMAGEFULLWIDTH'">
                            <div class="email-block-wrapper" (click)="selectBlock(block)">
                                <div class="email-hero-image"
                                    [style.backgroundImage]="'url('+block.properties[0].value+')'">
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="block.type == 'IMAGE'">
                            <div class="email-block-wrapper" (click)="selectBlock(block)">
                                <img class="email-image" src="{{block.properties[1].value}}"
                                    [style.height]="block.properties[0].value+'px'">
                            </div>
                        </ng-container>

                        <ng-container *ngIf="block.type == 'BODYTEXT'">
                            <div class="email-block-wrapper" (click)="selectBlock(block)">
                                <div class="email-body-text">{{block.properties[0].value}}</div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="block.type == 'HERO'">
                            <div class="email-hero-block" [style.backgroundColor]="block.backgroundColor">
                                <div class="email-logo-image-wrapper">
                                    <img class="email-logo-image" src="{{block.logoImage}}" />
                                </div>

                            </div>
                        </ng-container>

                    </ng-container>
                </div>
            </ng-container>
        </div>
        <div class="right-column">
            <ng-container *ngIf="this.activeBlock.properties">
                <ng-container *ngFor="let property of this.activeBlock.properties">
                    <ng-container *ngIf="property.type == 'INPUT'">
                        <nz-form-item>
                            <nz-form-label>{{ property.name }}</nz-form-label>
                            <nz-form-control [nzSpan]="24">
                                <input nz-input [(ngModel)]="property.value" />
                            </nz-form-control>
                        </nz-form-item>
                    </ng-container>
                    <ng-container *ngIf="property.type == 'RICHTEXT'">
                        <nz-form-item>
                            <nz-form-label>{{ property.name }}</nz-form-label>
                            <nz-form-control [nzSpan]="24">
                                <textarea rows="4" nz-input [(ngModel)]="property.value"></textarea>
                            </nz-form-control>
                        </nz-form-item>
                    </ng-container>
                    <ng-container *ngIf="property.type == 'IMAGESELECT'">
                        <div>Select an image</div>
                        <div nz-row [nzGutter]="[16, 24]">
                            <ng-container *ngFor="let image of opportunityObject.scrapedData.images">
                                <ng-container *ngIf="image.src">
                                    <div nz-col class="gutter-row" [nzSpan]="8">
                                        <img (click)="property.value = image.src" class="image-list"
                                            src="{{image.src}}">
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </ng-container> -->


<!-- <ng-container *ngIf="this.opportunityObject.stage == ''">
        <div class="content-wrapper">
            <nz-page-header class="site-page-header">

                <nz-breadcrumb nz-page-header-breadcrumb>
                    <nz-breadcrumb-item>Opportunities</nz-breadcrumb-item>
                    <nz-breadcrumb-item>{{this.opportunityObject.title}}</nz-breadcrumb-item>
                </nz-breadcrumb>
                <nz-page-header-title>{{this.opportunityObject.title}}</nz-page-header-title>
            </nz-page-header>
            <ng-container *ngIf="fetchingSiteData">
                <div class="custom-card" style="text-align: center">
                    <div class="custom-card-body">
                        <div class="meter purple">
                            <span style="width: 100%"></span>
                        </div>
                        <h3>Copilot is fetching data.</h3>
                        <h3>This may take a moment.</h3>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!fetchingSiteData">
                <div class="custom-card" style="text-align: center">
                    <div class="custom-card-body">
                        <h2>Choose a primary color</h2>
                        <div nz-row [nzGutter]="[16, 24]">
                            <ng-container *ngFor="let color of siteData.colors">
                                <div nz-col class="gutter-row" [nzSpan]="3">
                                    <div (click)="selectPrimaryColor(color)" class="color-list"
                                        [style.backgroundColor]="color"></div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="custom-card" style="text-align: center">
                    <div class="custom-card-body">
                        <h2>Choose a logo image</h2>
                        <div nz-row [nzGutter]="[16, 24]">
                            <ng-container *ngFor="let image of siteData.images">
                                <ng-container *ngIf="image.src">
                                    <div nz-col class="gutter-row" [nzSpan]="4">
                                        <img (click)="selectLogoImage(image.src)" class="image-list"
                                            src="{{image.src}}">
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container> -->



<!-- <div class="content-column-left">
        <ng-container *ngIf="this.opportunityObject.stage == 'PENDING'">
            <nz-steps [nzCurrent]="1" nzDirection="vertical">
                <nz-step nzTitle="Generating proposal"></nz-step>
                <nz-step nzTitle="Approval"></nz-step>
                <nz-step nzTitle="Publishing"></nz-step>
                <nz-step nzTitle="Live"></nz-step>
            </nz-steps>
        </ng-container>

        <ng-container *ngIf="this.opportunityObject.stage == 'PUBLISHING'">
            <nz-steps [nzCurrent]="2" nzDirection="vertical">
                <nz-step nzTitle="Generating proposal"></nz-step>
                <nz-step nzTitle="Approval"></nz-step>
                <nz-step nzTitle="Publishing"></nz-step>
                <nz-step nzTitle="Live"></nz-step>
            </nz-steps>
        </ng-container>
    </div>


    <div class="content-column-right">
        <div class="custom-card">
            <div class="custom-card-body">
                <ng-container *ngIf="this.opportunityObject.stage == 'PENDING'">
                    <div class="custom-alert">Copilot has generated the following proposal, please review and
                        approve when ready. <span nz-tooltip style="color: #4d6fff"
                            [nzTooltipTitle]="opportunityObject.contentTemplate.emailReason">Why send this
                            email&nbsp;<span nz-icon nzType="question-circle" nzTheme="outline"></span></span>
                    </div>
                </ng-container>
                <ng-container *ngIf="this.opportunityObject.stage == 'PUBLISHING'">
                    <nz-alert nzType="info"
                        nzMessage="Copilot is publishing this opportunity, and will notify you when it is live. This may take up to 24 hours."
                        nzShowIcon></nz-alert>
                </ng-container>
                <br>
                <nz-form-item>
                    <nz-form-label nzNoColon="true" nzLabelAlign="left" nzSpan="6">Subject</nz-form-label>
                    <nz-form-control nzSpan="18">
                        {{opportunityObject.contentTemplate.subjectLine}}
                        <ng-container *ngIf="this.opportunityObject.stage == 'PENDING'">
                            <button style="float: right; margin-top: -7px; margin-left: 16px;" nz-button
                                (click)="editSubjectLine = true" *ngIf="!editSubjectLine"><span nz-icon
                                    nzType="edit" nzTheme="outline"></span>Edit</button>
                        </ng-container>
                        <span nz-tooltip [nzTooltipTitle]="opportunityObject.contentTemplate.subjectLineReason"
                            style="float: right;">Why&nbsp;<span nz-icon nzType="question-circle"
                                nzTheme="outline"></span></span>

                        <ng-container *ngIf="this.editSubjectLine">
                            <div class="feedback-wrapper">
                                <textarea placeholder="Enter feedback for Copilot" rows="2" nz-input
                                    [(ngModel)]="subjectLineFeedback"></textarea>
                                <button style="float: right" nz-button (click)="sendSubjectLineFeedback()"
                                    [nzLoading]="loadingSubjectLineFeedback">Send</button>
                            </div>
                        </ng-container>
                    </nz-form-control>

                </nz-form-item>
                <nz-form-item>
                    <nz-form-label nzNoColon="true" nzLabelAlign="left" nzSpan="6">Audience</nz-form-label>
                    <nz-form-control nzSpan="18">
                        {{opportunityObject.audience}}
                        <span nz-tooltip [nzTooltipTitle]="opportunityObject.contentTemplate.audienceReason"
                            style="float: right">Why&nbsp;<span nz-icon nzType="question-circle"
                                nzTheme="outline"></span></span>
                        <ng-container *ngIf="this.opportunityObject.stage == 'PENDING'">
                            <button style="float: right" nz-button><span nz-icon nzType="edit"
                                    nzTheme="outline"></span>Edit</button>
                        </ng-container>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label nzNoColon="true" nzLabelAlign="left" nzSpan="6">Send time</nz-form-label>
                    <nz-form-control nzSpan="18">
                        TBD
                        <span nz-tooltip [nzTooltipTitle]="opportunityObject.contentTemplate.audienceReason"
                            style="float: right">Why&nbsp;<span nz-icon nzType="question-circle"
                                nzTheme="outline"></span></span>
                        <ng-container *ngIf="this.opportunityObject.stage == 'PENDING'">

                        </ng-container>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item style="margin-bottom: 0px !important;">
                    <nz-form-label nzNoColon="true" nzLabelAlign="left" nzSpan="6">Template</nz-form-label>
                    <nz-form-control nzSpan="18">
                        <ng-container *ngIf="this.opportunityObject.stage == 'PENDING'">
                            <button [routerLink]="['/editor',opportunityObject._id]" style="float: right"
                                nz-button><span nz-icon nzType="edit" nzTheme="outline"></span>Edit</button>
                        </ng-container>
                        <div class="email-content-wrapper"
                            [style.backgroundColor]="opportunityObject.contentTemplate.templateContentBackground">
                            <ng-container *ngFor="let block of opportunityObject.contentTemplate.blocks">
                                <ng-container *ngIf="block.type == 'LOGO'">
                                    <div class="email-block-wrapper padding24" (click)="selectBlock(block)">
                                        <div class="email-logo-image-wrapper">
                                            <img class="email-logo-image" src="{{block.properties[0].value}}" />
                                        </div>
                                    </div>

                                </ng-container>

                                <ng-container *ngIf="block.type == 'TITLE'">
                                    <div class="email-block-wrapper">
                                        <div class="email-hero-title">{{block.properties[0].value}}</div>
                                        <div class="email-hero-subtitle">{{block.properties[1].value}}</div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="block.type == 'BUTTON'">
                                    <div class="email-block-wrapper">
                                        <div class="email-button"
                                            [style.backgroundColor]="block.properties[1].value"
                                            [style.color]="block.properties[2].value">
                                            {{block.properties[0].value}}</div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="block.type == 'IMAGEFULLWIDTH'">
                                    <div class="email-block-wrapper">
                                        <div class="email-hero-image"
                                            [style.backgroundImage]="'url('+block.properties[0].value+')'">
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="block.type == 'IMAGE'">
                                    <div class="email-block-wrapper">
                                        <img class="email-image" src="{{block.properties[1].value}}"
                                            [style.height]="block.properties[0].value+'px'">
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="block.type == 'BODYTEXT'">
                                    <div class="email-block-wrapper">
                                        <div class="email-body-text">{{block.properties[0].value}}</div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="block.type == 'GALLERY'">
                                    <div class="email-block-wrapper padding32"
                                        [style.backgroundColor]="block.properties[5].value">
                                        <div class="email-gallery-title">{{block.properties[4].value}}</div>
                                        <table class="email-gallery-table">
                                            <tr>
                                                <td><img class="email-gallery-image"
                                                        src="{{block.properties[0].value}}" /></td>
                                                <td><img class="email-gallery-image"
                                                        src="{{block.properties[1].value}}" /></td>
                                            </tr>
                                            <tr>
                                                <td><img class="email-gallery-image"
                                                        src="{{block.properties[2].value}}" /></td>
                                                <td><img class="email-gallery-image"
                                                        src="{{block.properties[3].value}}" /></td>
                                            </tr>
                                        </table>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="block.type == 'REVIEW'">
                                    <div class="email-block-wrapper padding32"
                                        [style.backgroundColor]="block.properties[2].value">
                                        <div class="email-review-wrapper ">
                                            <div class="email-review-stars">⭐ ⭐ ⭐ ⭐ ⭐</div>
                                            <div class="email-review-text">"{{block.properties[0].value}}"</div>
                                            <div class="email-review-name"> - {{block.properties[1].value}}
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="block.type == 'HERO'">
                                    <div class="email-block-wrapper">
                                        <div class="email-hero-background">
                                            <div class="email-hero-opacity">
                                                <div class="email-hero-title">{{block.properties[0].value}}
                                                </div>
                                                <div class="email-hero-subtitle">{{block.properties[1].value}}
                                                </div>
                                                <div class="email-hero-button">{{block.properties[2].value}}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </ng-container>

                                <ng-container *ngIf="block.type == 'CATEGORY'">
                                    <div class="email-block-wrapper padding24 horizontal"
                                        [style.backgroundColor]="block.properties[3].value">
                                        <table class="email-category-table">
                                            <tr>
                                                <td style="width: 30%"><img class="email-gallery-image"
                                                        src="{{block.properties[0].value}}" /></td>
                                                <td style="width: 70%; text-align: left; padding-left: 32px;">
                                                    <div class="email-category-title">
                                                        {{block.properties[1].value}}</div>
                                                    <div class="email-category-button">
                                                        {{block.properties[2].value}}</div>
                                                </td>
                                            </tr>
                                        </table>

                                    </div>
                                </ng-container>

                                <ng-container *ngIf="block.type == 'HERO'">
            <div class="email-hero-block" [style.backgroundColor]="block.backgroundColor">
                <div class="email-logo-image-wrapper">
                    <img class="email-logo-image" src="{{block.logoImage}}" />
                </div>

            </div>
        </ng-container>

                            </ng-container>
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </div> -->

<ng-template #tagPlaceHolder let-selectedList>...</ng-template>
<ng-template #contentTemplate>
    <div>
        <nz-form-item style="width: 300px; margin-bottom: 8px !important;">
            <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                Feedback (optional)
            </nz-form-label>
            <nz-form-control nzSpan="24">
                <textarea [(ngModel)]="subjectLineFeedback" nz-input placeholder="Ex: Make the copy more fun"
                    style="height: 100px;"></textarea>
            </nz-form-control>
        </nz-form-item>
        <div style="text-align: right">
            <button nzType="primary" nz-button (click)="sendSubjectLineFeedback()">Send</button>
        </div>
        <!-- <div style="text-align: right">
            <button nzType="primary" nz-button nz-popconfirm
            nzPopconfirmTitle="This will overwrite any changes you've made in the editor"
            (nzOnConfirm)="sendSubjectLineFeedback()"
            nzPopconfirmPlacement="bottomRight">Send</button>
        </div> -->
    </div>
</ng-template>