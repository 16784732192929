<div class="custom-modal-header">
    <div class="custom-modal-title">Edit background</div>
    <div class="custom-modal-close" (click)="close()"><span nz-icon nzType="close" nzTheme="outline"></span></div>
</div>
<div class="custom-modal-body" style="overflow:hidden;" style="padding: 16px 0px 16px 16px;">
    <!-- <input type="file" (change)="fileChangeEvent($event)" /> -->
    <ng-container *ngIf="!this.removingBackground">
        <div class="left-column">
            <div class="image-select-box">
                <canvas id="myCanvas" width="400" height="400" style="margin: auto"></canvas>
                <!-- <ng-container *ngIf="!newImageBackground.type">
                    <div class="new-image-background">
                        <img [src]="removedBackground">
                    </div>
                </ng-container>
                <ng-container *ngIf="newImageBackground.type">
                    <ng-container *ngIf="newImageBackground.type == 'COLOR'">
                        <div class="new-image-background" [style.backgroundColor]="newImageBackground.value">
                                <img [src]="removedBackground">
                        </div>
                    </ng-container>

                    <ng-container *ngIf="newImageBackground.type == 'IMAGE'">
                        <div class="new-image-background" [style.backgroundImage]="'url('+newImageBackground.value+')'">
                                <img [src]="removedBackground">
                        </div>
                    </ng-container>
                </ng-container> -->
            </div>
        </div>
        <div class="right-column">
            <ul nz-menu nzMode="horizontal" style="margin-bottom: 8px;">
                <li nz-menu-item nzSelected (click)="this.mode = 'COLORS'">
                    <span>Colors</span>
                </li>
                <li nz-menu-item (click)="this.mode = 'PATTERNS'">
                    <span>Patterns</span>
                </li>
                <!-- <li nz-menu-item (click)="this.mode = 'UPLOAD'">
                    <span>Upload</span>
                </li> -->
            </ul>
            <div class="right-column-content">
                <ng-container *ngIf="this.mode == 'COLORS'">
                    <nz-form-item>
                        <nz-form-label [nzNoColon]="true">Background color</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <ngx-colors ngx-colors-trigger [(ngModel)]="this.selectedBackgroundColor"
                                [palette]="this.sharedService.rendererOpportunityObject.contentTemplate.colorPalette" (ngModelChange)="changeBackgroundColor()"></ngx-colors>
                        </nz-form-control>
                    </nz-form-item>
                </ng-container>
                <ng-container *ngIf="this.mode == 'PATTERNS'">
                    <div nz-row [nzGutter]="[8, 8]">
                        <ng-container *ngFor="let option of this.backgroundOptions">
                            <div nz-col class="gutter-row" [nzSpan]="12">
                                <ng-container *ngIf="option.type == 'COLOR'">
                                    <div class="background-option" (click)="changeBackground(option)"
                                        [style.backgroundColor]="option.value"></div>
                                </ng-container>
                                <ng-container *ngIf="option.type == 'IMAGE'">
                                    <div class="background-option" (click)="changeBackground(option)"
                                        [style.backgroundImage]="'url('+option.value+')'"></div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <!-- <ng-container *ngIf="this.mode == 'UPLOAD'">

                </ng-container> -->
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="this.removingBackground">
        <div class="loading-wrapper" style="padding: 200px 0px; color: #767676;">
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <br>
            <br>
            <div>Removing image background...</div>
            <div>This may take ~30 seconds</div>
        </div>
    </ng-container>

    <!-- <img [src]="croppedImage" /> -->
</div>
<div class="custom-modal-footer">
    <button nz-button nzType="primary" (click)="uploadImage()" [nzLoading]="this.loading">Save</button>
</div>