import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SharedService } from '../shared.service';
import posthog from 'posthog-js';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  newUserObject = {
    email: '',
    password: ''
  }

  returnUrl = '';

  errorString = '';

  constructor(
    public apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    public sharedService: SharedService

  ) { }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    posthog.reset(true);
    posthog.stopSessionRecording();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/email';
  }

  onSubmit() {
    this.apiService.authenticateUser(this.newUserObject).subscribe(
      (data: any) => {
        // login successful if there's a jwt token in the response
        console.log(data);
        if (data && data['token']) {
          console.log(data);
          // posthog.reset(true);
          // posthog.stopSessionRecording();
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.sharedService.currentUser = data;
          localStorage.setItem('currentUser', JSON.stringify(data));
          // posthog.init('phc_qjIs4HBFLXNqZ9ZulV04eenlV5EiBd9sTC0BCn9HKKw', { api_host: 'https://app.posthog.com', capture_pageview: false, disable_session_recording: true, disable_persistence: true });
          posthog.identify(data._id, { email: data.email });
          posthog.startSessionRecording();

          // if (!data.email.includes("getripple.ai") && !data.email.includes("tristan.m.dannelongue@gmail.com")) {
          // }
          this.router.navigate([this.returnUrl]);
        }

        if (data && data.error) {
          this.errorString = data.error;
        }
      },
      error => {
        console.log(error);
      });
  }
}
