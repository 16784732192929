<div class="page-wrapper">
    <div class="content-wrapper">
        <nz-page-header class="site-page-header" nzTitle="Confirm colors"></nz-page-header>
        <div class="custom-card">
            <ng-container *ngIf="this.loading">
                <div class="custom-card-body" style="text-align: center;">

                    <div class="meter purple">
                        <span style="width: 100%"></span>
                    </div>
                    <h3>Copilot is analyzing your store.</h3>
                    <h3>This may take a moment.</h3>
                </div>
            </ng-container>
            <ng-container *ngIf="!this.loading">
                <div class="custom-card-body">

                    <h3>Copilot found the following colors on your site. Please choose your primary brand color.</h3>
                    <br>
                    <div nz-row [nzGutter]="[16, 24]">
                        <ng-container *ngFor="let color of imagesAndColorsObject.colors">
                            <div nz-col class="gutter-row" [nzSpan]="4">
                                <div class="image-list color"
                                    [class.selected]="this.imagesAndColorsObject.color == color"
                                    (click)="this.imagesAndColorsObject.color = color" [style.backgroundColor]="color">
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <br>
                    <button *ngIf="!this.showColorSelector" (click)="showColorSelector = true" nz-button
                        nzType="default">Choose a different color</button>
                    <ng-container *ngIf="this.showColorSelector">
                        <nz-divider></nz-divider>
                        <input style="width: 300px;" [(ngModel)]="this.imagesAndColorsObject.color" nz-input
                            placeholder="Enter a color code" />
                    </ng-container>
                </div>
                <div class="custom-card-footer">
                    <button nz-button nzType="primary" (click)="completeSignup()">Continue</button>
                </div>
            </ng-container>

        </div>
    </div>
</div>