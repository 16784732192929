import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { SharedService } from '../shared.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-accept-workspace-invite',
  templateUrl: './accept-workspace-invite.component.html',
  styleUrls: ['./accept-workspace-invite.component.scss']
})
export class AcceptWorkspaceInviteComponent {
  workspaceId: any = '';
  workspaceObject: any = {};
  loading = true;
  newMessageBody = '';
  inviteId: any = "";
  mode: any = "";
  inviteObject: any = {};

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public sharedService: SharedService,
    public message: NzMessageService
  ) { }

  ngOnInit() {
    this.inviteId = this.route.snapshot.paramMap.get("id");
    console.log(this.inviteId);
    this.apiService.getInvite(this.inviteId).subscribe(
      (data: any) => {
        console.log(data);

        if (!data.userExists) {
          this.mode = "NEWUSER";
        } else {
          if (this.sharedService?.currentUser?.email == data.email) {
            this.mode = "EXISTINGUSER";
          } else {
            this.mode = "WRONGEMAIL";
          }
        }

        this.inviteObject = data;
        this.loading = false;

      },
      error => {
        console.log(error);
      });
  }

  completeSignup() {
    this.apiService.completeSignup(this.workspaceObject).subscribe(
      data => {
          // login successful if there's a jwt token in the response
          console.log(data);
          if (data) {
            console.log(data);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(data));
            this.router.navigate(['signup/2']);
          }
      },
      error => {
          console.log(error);
      });
  }

  acceptInviteNewUser() {
    this.apiService.acceptInviteNewUser(this.inviteObject._id, this.inviteObject).subscribe(
      data => {
          // login successful if there's a jwt token in the response
          console.log(data);
          if (data) {
            console.log(data);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(data));
            this.sharedService.currentUser = data;
            this.router.navigate(['/']);
          }
      },
      error => {
          console.log(error);
      });
  }

  acceptInvite() {
    this.apiService.acceptInvite(this.inviteObject._id).subscribe(
      data => {
          // login successful if there's a jwt token in the response
          console.log(data);
          if (data) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this.apiService.changeWorkspace(this.inviteObject.workspace._id).subscribe(
              (data: any) => {
                // login successful if there's a jwt token in the response
                console.log(data);
                if (data && data['token']) {
                  // store user details and jwt token in local storage to keep user logged in between page refreshes
                  this.sharedService.currentUser = data;
                  localStorage.setItem('currentUser', JSON.stringify(data));
                  this.message.create("success", `Accepted invite to ${this.inviteObject.workspace.name}`);
                  this.router.navigateByUrl('/');
                  this.sharedService.loadingCalendar = true;
                  this.apiService.getEmailCampaignCalendar(this.sharedService.calendarMonth, this.sharedService.calendarYear).subscribe(
                    (data: any) => {
                      console.log(data);
                      this.sharedService.calendarData = data;
                      this.sharedService.loadingCalendar = false;
                    },
                    error => {
                      console.log(error);
                    });
                }
              },
              error => {
                console.log(error);
              });
          }
      },
      error => {
          console.log(error);
      });
  }
}
