import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-confirm-details',
  templateUrl: './confirm-details.component.html',
  styleUrls: ['./confirm-details.component.scss']
})
export class ConfirmDetailsComponent {
  workspaceId: any = '';
  workspaceObject: any = {};
  loading = true;
  newMessageBody = '';

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    this.workspaceId = this.route.snapshot.paramMap.get("id");
    console.log(this.workspaceId);
    this.apiService.getWorkspace(this.workspaceId).subscribe(
      (data: any) => {
        console.log(data);
        this.workspaceObject = data;
        this.loading = false;
      },
      error => {
        console.log(error);
      });
  }

  completeSignup() {
    this.apiService.completeSignup(this.workspaceObject).subscribe(
      data => {
          // login successful if there's a jwt token in the response
          console.log(data);
          if (data) {
            console.log(data);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(data));
            this.router.navigate(['signup/3']);
          }
      },
      error => {
          console.log(error);
      });
  }
}
