<div class="custom-modal-header">
    <div class="custom-modal-title">Send test email</div>
    <div class="custom-modal-close" (click)="close()"><span nz-icon nzType="close" nzTheme="outline"></span></div>
</div>
<div class="custom-modal-body" style="overflow:hidden;">
    <ng-container *ngFor="let email of emailsToSend">
        <nz-form-item style="margin-bottom: 8px;">
            <nz-form-control [nzSpan]="24">
                <input placeholder="Enter an email" nz-input [(ngModel)]="email.email" />
            </nz-form-control>
        </nz-form-item>
    </ng-container>
    <button nz-button (click)="addEmail()" nzBlock><span nz-icon nzType="plus" nzTheme="outline"></span>Add
        email</button>
</div>
<div class="custom-modal-footer">
    <button nz-button nzType="primary" (click)="sendTestEmail()" [nzLoading]="this.sendingPreview"><span nz-icon nzType="send" nzTheme="outline"></span>Send</button>
</div>