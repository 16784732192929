<div class="page-wrapper">
    <div class="header-wrapper">
        <nz-page-header nzBackIcon (nzBack)="handleBackButton()" class="site-page-header">

            <!-- <nz-breadcrumb nz-page-header-breadcrumb>
                <nz-breadcrumb-item>Opportunities</nz-breadcrumb-item>
                <nz-breadcrumb-item>{{this.opportunityObject.title}}</nz-breadcrumb-item>
            </nz-breadcrumb> -->
            <nz-page-header-title>
                <ng-container *ngIf="mode == 'CAMPAIGN'">Create email</ng-container>
                <ng-container *ngIf="mode == 'FLOW'">Create flow email</ng-container>
                <ng-container *ngIf="mode == 'SOCIALPOST'">Create social post</ng-container>
            </nz-page-header-title>
            <nz-page-header-extra>
                <ng-container *ngIf="!this.showRenderer">
                    <!-- <button (click)="this.chooseEmailType(this.customPromptEmailType)" nz-button><span nz-icon
                            nzType="edit" nzTheme="outline"></span>Write a custom
                        prompt</button> -->
                </ng-container>
                <ng-container *ngIf="this.showRenderer">
                    <!-- <button nz-button><i class="lni lni-trash-can"
                            style="float: left; margin-top: 3px;"></i>&nbsp;&nbsp;Clear</button>&nbsp; -->
                    <!-- <nz-input-group nzCompact style="float: left; width: 180px;">
                        <button nz-button nzType="default" (click)="previousEmail()" [disabled]="!this.emailDone">
                            <span nz-icon nzType="left"></span>
                        </button>
                        <button nz-button [disabled]="!this.emailDone">Version {{this.currentEmail +
                            1}}/{{this.emailHistory.length}}</button>
                        <button nz-button nzType="default" (click)="nextEmail()" [disabled]="!this.emailDone">
                            <span nz-icon nzType="right"></span>
                        </button>
                    </nz-input-group>
                    <button (click)="saveEmail()" nz-button nzType="primary" [disabled]="!this.emailDone">Save & continue</button> -->
                </ng-container>
            </nz-page-header-extra>
        </nz-page-header>
    </div>
    <div class="content-wrapper">
        <!-- <div class="left-sidebar">
            <div class="session-wrapper navbar">
                <ul nz-menu nzMode="inline">
                    <ng-container *ngFor="let sessionObject of sessionList">
                        <button (click)="changeSession(sessionObject._id)" nz-button
                            style="width: 100%; text-align: left; margin-bottom: 4px;"><i
                                class="lni lni-alarm-clock"
                                style="float: left; margin-top: 3px;"></i>&nbsp;&nbsp;{{sessionObject.updatedAt |
                            date:'M/d/YY
                            h:mm
                            a'}}</button>
                        <li nz-menu-item (click)="changeSession(sessionObject._id)"
                            routerLinkActive="ant-menu-item-selected"><i
                                class="lni lni-calendar"></i>&nbsp;&nbsp;<span>{{sessionObject.updatedAt |
                                date:'M/d/YY
                                h:mm
                                a'}}</span></li>
                    </ng-container>
                </ul>
            </div>
        </div> -->
        <div class="left-column wide" *ngIf="!this.showRenderer && !this.creatingLayout && !this.showHeroOptions"
            style="width: 100%;">
            <div class="upper-inner">
                <ng-container *ngIf="mode == 'CAMPAIGN' || mode == 'FLOW'">

                    <ng-container *ngIf="!emailOptions">
                        <!-- <h4 nz-typography style="margin-bottom: 24px;">Choose a template to start from</h4> -->
                        <div class="left-column-2">
                            <div class="navbar">
                                <!-- <ul nz-menu nzMode="inline" style="background: #FCFCFC">
                                    <button nz-button style="width: 214px;
                                        text-align: left;
                                        margin-left: 8px;
                                        margin-bottom: 16px;"><span nz-icon nzType="plus"
                                                nzTheme="outline"></span>Start with ai</button>
                                    <li nz-menu-item>
                                        &nbsp;&nbsp;<span>All</span></li>
                                    <li nz-menu-item>
                                        &nbsp;&nbsp;<span>Product feature</span></li>
                                    <li nz-menu-item>
                                        &nbsp;&nbsp;<span>Collection feature</span></li>
                                    <li nz-menu-item>
                                        &nbsp;&nbsp;<span>Sale</span></li>
                                    <li nz-menu-item>
                                        &nbsp;&nbsp;<span>Review highlight</span></li>
                                </ul> -->
                                <!-- <nz-tag class="bubble">Product highlight</nz-tag>
                                <nz-tag class="bubble">Educational</nz-tag>
                                <nz-tag class="bubble">Collection highlight</nz-tag>
                                <nz-tag class="bubble">Product launch</nz-tag>
                                <nz-tag class="bubble">Sale</nz-tag> -->
                                <div style="margin-bottom: 8px; font-weight: 600;">Filter by use case</div>
                                <ng-container *ngIf="this.templateFilter">
                                    <button nz-button (click)="filterTemplates(null)" style="margin-bottom: 8px;"><span
                                            nz-icon nzType="close-circle" nzTheme="outline"></span>Clear
                                        filters</button>
                                    <br>
                                </ng-container>
                                <ng-container *ngFor="let templateTag of templateTags">
                                    <button nz-button class="tag" (click)="filterTemplates(templateTag)"
                                        [ngStyle]="{'outline': templateFilter === templateTag ? '2px solid #00809C' : 'none'}">{{templateTag}}</button>
                                </ng-container>
                                <!-- <div style="margin-top: 24px; margin-bottom: 8px; font-weight: 600;">Filter by style</div>
                                <button nz-button class="tag">Simple</button>
                                <button nz-button class="tag">Clean</button>
                                <button nz-button class="tag">Fun</button>
                                <button nz-button class="tag">Modern</button> -->
                            </div>
                        </div>
                        <div class="right-column-2">
                            <ng-container *ngIf="this.loadingTemplates">
                                <div class="loading-wrapper">
                                    <div class="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!this.loadingTemplates">
                                <ng-container *ngIf="emailTemplates.length > 0 && !templateFilter && mode != 'FLOW'">

                                    <h4 nz-typography style="margin-bottom: 24px;">Your templates</h4>
                                    <div nz-row [nzGutter]="16">
                                        <ng-container *ngFor="let emailTemplate of emailTemplates">
                                            <!-- <div nz-col class="gutter-row" [nzSpan]="12">
                                            <div class="custom-card">
                                                <div nz-row>
                                                    <div nz-col class="gutter-row" [nzSpan]="20">
                                                        <div class="custom-card-body hover" style="padding: 16px;"
                                                            (click)="chooseTemplate(emailTemplate)">
                                                            <div class="email-type-label">{{emailTemplate.title}}</div>
                                                        </div>
                                                    </div>
                                                    <div nz-col class="gutter-row" [nzSpan]="4"
                                                        style="border-left: 1px solid #e5e7eb; text-align: center;"
                                                        [routerLink]="['/opportunities',emailTemplate._id,'create']">
                                                        <div class="custom-card-body hover" style="padding: 16px;">
                                                            <span nz-icon nzType="edit"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                            <!-- <div nz-col class="gutter-row" [nzSpan]="4">
                                            <div class="custom-card hover"
                                                [routerLink]="['/opportunities',emailTemplate._id,'templates']">
                                                <img  src="{{opportunity.thumbnail}}" />
                                                <img class="picker-item-image" [src]="emailTemplate.thumbnail">
                                                <div class="custom-card-footer mini"
                                                    style="padding: 8px; text-align: left;">
                                                    <div class="picker-item-title">{{emailTemplate.title}}</div>
                                                </div>
                                            </div>
                                        </div> -->
                                            <div nz-col class="gutter-row" [nzSpan]="6">
                                                <div class="custom-card">
                                                    <div class="custom-card-header mini"
                                                        style="padding: 8px; text-align: left;">
                                                        <div class="picker-item-title">{{emailTemplate.title}}</div>
                                                    </div>
                                                    <!-- <img src="{{emailTemplate.thumbnail}}" /> -->
                                                    <div class="image-wrap" style="height: 250px;">
                                                        <img [src]="emailTemplate.thumbnail">
                                                    </div>
                                                    <div class="custom-card-footer mini"
                                                        style="padding: 8px; text-align: right;">
                                                        <button nz-button nzType="default"
                                                            (click)="chooseTemplate(emailTemplate)">Use<span nz-icon
                                                                nzType="arrow-right" nzTheme="outline"></span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <br>
                                    <h4 nz-typography style="margin-bottom: 24px;">Ripple templates</h4>
                                </ng-container>
                                <ng-container *ngIf="emailTemplates.length == 0 || templateFilter|| mode == 'FLOW'">
                                    <h4 nz-typography>Choose a template to start from</h4>
                                </ng-container>
                                <nz-dropdown-menu #menu3="nzDropdownMenu">
                                    <ul nz-menu>
                                        <li nz-menu-item><span nz-icon nzType="edit"
                                                nzTheme="outline"></span>&nbsp;&nbsp;Edit
                                        </li>
                                    </ul>
                                </nz-dropdown-menu>

                                <!-- <div nz-row [nzGutter]="16" style="margin-bottom: 32px;">
                                <div nz-col class="gutter-row" [nzSpan]="8">
                                    <div class="custom-card hover"
                                        (click)="this.chooseEmailType(this.customPromptEmailType)">
                                        <div class="custom-card-body" style="padding: 16px;">
                                            <img src="https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-5191e631-b9f8-4a2d-ae49-25c084402d36.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div nz-row [nzGutter]="16" style="margin-bottom: 32px;">
                                <div nz-col class="gutter-row" [nzSpan]="12">
                                    <div class="custom-card hover"
                                        (click)="this.chooseEmailType(this.customPromptEmailType)">
                                        <div class="custom-card-body" style="padding: 16px;">
                                            <div class="email-type-wrapper">
                                                <i class="lni lni-pencil"></i>
                                            </div>
                                            <div class="email-type-label">Write a custom prompt</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h5 nz-typography>Promotional</h5>
                            <div nz-row [nzGutter]="16" style="margin-bottom: 32px;">
                                <ng-container *ngFor="let emailType of emailTypes">
                                    <ng-container *ngIf="emailType.category == 'PROMOTIONAL'">
                                        <div nz-col class="gutter-row" [nzSpan]="12">
                                            <div class="custom-card hover" (click)="chooseEmailType(emailType)">
                                                <div class="custom-card-body" style="padding: 16px;">
                                                    <div class="email-type-wrapper">
                                                        <i class="{{emailType.icon}}"></i>
                                                    </div>
                                                    <div class="email-type-label">{{emailType.name}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <h5 nz-typography>Social proof</h5>
                            <div nz-row [nzGutter]="16" style="margin-bottom: 32px;">
                                <ng-container *ngFor="let emailType of emailTypes">
                                    <ng-container *ngIf="emailType.category == 'SOCIALPROOF'">
                                        <div nz-col class="gutter-row" [nzSpan]="12">
                                            <div class="custom-card hover" (click)="chooseEmailType(emailType)">
                                                <div class="custom-card-body" style="padding: 16px;">
                                                    <div class="email-type-wrapper">
                                                        <i class="{{emailType.icon}}"></i>
                                                    </div>
                                                    <div class="email-type-label">{{emailType.name}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <h5 nz-typography>Educational</h5>
                            <div nz-row [nzGutter]="16" style="margin-bottom: 32px;">
                                <ng-container *ngFor="let emailType of emailTypes">
                                    <ng-container *ngIf="emailType.category == 'EDUCATIONAL'">
                                        <div nz-col class="gutter-row" [nzSpan]="12">
                                            <div class="custom-card hover" (click)="chooseEmailType(emailType)">
                                                <div class="custom-card-body" style="padding: 16px;">
                                                    <div class="email-type-wrapper">
                                                        <i class="{{emailType.icon}}"></i>
                                                    </div>
                                                    <div class="email-type-label">{{emailType.name}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <h5 nz-typography>Sale</h5>
                            <div nz-row [nzGutter]="16" style="margin-bottom: 40px;">
                                <ng-container *ngFor="let emailType of emailTypes">
                                    <ng-container *ngIf="emailType.category == 'SALE'">
                                        <div nz-col class="gutter-row" [nzSpan]="12">
                                            <div class="custom-card hover" (click)="chooseEmailType(emailType)">
                                                <div class="custom-card-body" style="padding: 16px;">
                                                    <div class="email-type-wrapper">
                                                        <i class="{{emailType.icon}}"></i>
                                                    </div>
                                                    <div class="email-type-label">{{emailType.name}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div> -->
                                <ng-container *ngFor="let templateTag of templateTags">
                                    <ng-container *ngIf="!templateFilter || templateFilter == templateTag">

                                        <h5 nz-typography style="margin-bottom: 16px; margin-top: 24px;">{{templateTag}}
                                        </h5>
                                        <div (click)="filterTemplates(templateTag)"
                                            style="float: right; font-weight: 600; margin-top: -39px; cursor: pointer; color: #00809C">
                                            See all</div>

                                        <ng-container *ngIf="!templateFilter">
                                            <div nz-row [nzGutter]="16">
                                                <ng-container
                                                    *ngFor="let emailType of groupedByTags[templateTag]; let i = index">
                                                    <ng-container *ngIf="i < 4">

                                                        <ng-container *ngIf="emailType.tags?.includes(templateTag)">
                                                            <div nz-col class="gutter-row" [nzSpan]="6">
                                                                <div class="custom-card">
                                                                    <!-- <div class="custom-card-header mini"
                                                                        style="padding: 8px; text-align: left;">
                                                                        <div class="picker-item-title">
                                                                            {{emailType.title}}
                                                                        </div>
                                                                    </div> -->
                                                                    <!-- <img src="{{emailTemplate.thumbnail}}" /> -->
                                                                    <div class="image-wrap">
                                                                        <img [src]="emailType.thumbnail">
                                                                    </div>
                                                                    <div class="custom-card-footer mini"
                                                                        style="padding: 8px; text-align: right;">
                                                                        <button nz-button nzType="default"
                                                                            (click)="chooseTemplate(emailType)">Use<span
                                                                                nz-icon nzType="arrow-right"
                                                                                nzTheme="outline"></span></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="templateFilter">
                                            <div nz-row [nzGutter]="16">
                                                <ng-container
                                                    *ngFor="let emailType of groupedByTags[templateTag]; let i = index">
                                                    <ng-container *ngIf="emailType.tags?.includes(templateTag)">
                                                        <div nz-col class="gutter-row" [nzSpan]="6">
                                                            <div class="custom-card">
                                                                <div class="custom-card-header mini"
                                                                    style="padding: 8px; text-align: left;">
                                                                    <div class="picker-item-title">{{emailType.title}}
                                                                    </div>
                                                                </div>
                                                                <!-- <img src="{{emailTemplate.thumbnail}}" /> -->
                                                                <div class="image-wrap">
                                                                    <img [src]="emailType.thumbnail">
                                                                </div>
                                                                <div class="custom-card-footer mini"
                                                                    style="padding: 8px; text-align: right;">
                                                                    <button nz-button nzType="default"
                                                                        (click)="chooseTemplate(emailType)">Use<span
                                                                            nz-icon nzType="arrow-right"
                                                                            nzTheme="outline"></span></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container>

                        </div>
                    </ng-container>
                    <ng-container *ngIf="emailOptions">
                        <div class="custom-card"
                            style="max-width: 1200px; margin: auto; height: calc(100% - 64px); margin-top: 32px; margin-bottom: 32px;">
                            <div class="custom-card-header" style="padding: 16px;">
                                <button (click)="this.emailOptions = false" nz-button><span nz-icon nzType="arrow-left"
                                        nzTheme="outline"></span>Back</button>
                                <ng-container *ngIf="this.sharedService.currentUser.adminLevel == 'SUPER'">
                                    <button (click)="duplicateOpportunity(currentEmailType.template._id)" nz-button
                                        style="margin-left: 8px;"><span nz-icon nzType="copy"
                                            nzTheme="outline"></span>Duplicate</button>
                                    <button [routerLink]="['/opportunities',currentEmailType.template._id]" nz-button
                                        style="margin-left: 8px;"><span nz-icon nzType="edit"
                                            nzTheme="outline"></span>Edit</button>
                                </ng-container>
                            </div>
                            <div class="custom-card-body" style="height: calc(100% - 69px); padding: 0px;">
                                <div nz-row style="height: 100%;">
                                    <div nz-col nzSpan="12" style="height: 100%; padding: 16px;">
                                        <ng-container *ngFor="let property of currentEmailType.properties">
                                            <ng-container *ngIf="property.type == 'INPUT'">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true">{{ property.name
                                                        }}</nz-form-label>
                                                    <nz-form-control [nzSpan]="24">
                                                        <input nz-input [(ngModel)]="property.value" />
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </ng-container>
                                            <ng-container *ngIf="property.type == 'RICHTEXT'">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true">{{ property.name
                                                        }}</nz-form-label>
                                                    <nz-form-control [nzSpan]="24">
                                                        <textarea [nzAutosize]="{ minRows: 2, maxRows: 15 }" nz-input
                                                            [(ngModel)]="property.value"></textarea>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </ng-container>
                                            <ng-container *ngIf="property.type == 'URL'">
                                                <nz-form-label [nzNoColon]="true">{{ property.name
                                                    }}</nz-form-label>
                                                <nz-form-item style="margin-bottom: 8px;">

                                                    <ng-container *ngFor="let pageToScrape of property.value">
                                                        <nz-form-control [nzSpan]="24">
                                                            <input placeholder="Enter a url" nz-input
                                                                style="margin-bottom: 4px;"
                                                                [(ngModel)]="pageToScrape.url" />
                                                        </nz-form-control>
                                                    </ng-container>

                                                    <button nz-button (click)="property.value.push({'url': ''})"
                                                        nzBlock><span nz-icon nzType="plus" nzTheme="outline"></span>Add
                                                        url</button>
                                                </nz-form-item>
                                            </ng-container>
                                            <ng-container *ngIf="property.type == 'PRODUCTLIST'">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true">{{ property.name
                                                        }}</nz-form-label>
                                                    <nz-form-control [nzSpan]="24">
                                                        <div nz-row [nzGutter]="8">
                                                            <ng-container
                                                                *ngFor="let item of property.value; let itemIndex=index">

                                                                <div nz-col nzSpan="6">
                                                                    <div class="custom-card mini">
                                                                        <div class="custom-circle-button delete-item"
                                                                            style="color: red;"
                                                                            (click)="this.removeItemFromArray(itemIndex, property.value)">
                                                                            <span nz-icon nzType="delete"
                                                                                nzTheme="outline"></span>
                                                                        </div>
                                                                        <img class="picker-item-image"
                                                                            src="{{item.thumbnail}}" />
                                                                        <div class="custom-card-footer mini"
                                                                            style="padding: 8px; text-align: left; height: 39px">
                                                                            <div class="picker-item-title">
                                                                                {{item.title}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>

                                                            <div nz-col nzSpan="6">
                                                                <div class="list-add-item"
                                                                    (click)="this.sharedService.openPicker(property, 'PRODUCTS', 'MULTI')"
                                                                    style="padding: 95px 12px">
                                                                    <span nz-icon nzType="plus"
                                                                        nzTheme="outline"></span>&nbsp;Add
                                                                    product
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </nz-form-control>
                                                </nz-form-item>
                                            </ng-container>
                                            <ng-container *ngIf="property.type == 'COLLECTION'">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true">{{ property.name
                                                        }}</nz-form-label>
                                                    <nz-form-control [nzSpan]="24">
                                                        <nz-select nzShowSearch nzAllowClear
                                                            nzPlaceHolder="Select a collection"
                                                            [(ngModel)]="property.value" style="width: 100%;">
                                                            <ng-container *ngFor="let collection of this.collections">
                                                                <nz-option [nzLabel]="collection.data.title"
                                                                    [nzValue]="collection"></nz-option>
                                                            </ng-container>
                                                        </nz-select>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </ng-container>
                                            <ng-container *ngIf="property.type == 'FLOW'">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true">{{ property.name }}</nz-form-label>
                                                    <nz-form-control [nzSpan]="24">
                                                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a flow"
                                                            [(ngModel)]="property.value" style="width: 100%;" [nzDropdownRender]="renderTemplate">
                                                            <ng-container *ngFor="let flow of this.flowList">
                                                                <nz-option [nzLabel]="flow.name" [nzValue]="flow"></nz-option>
                                                            </ng-container>
                                                        </nz-select>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </ng-container>
                                        </ng-container>
                                        <button nz-button nzType="primary" (click)="newEmail()"
                                            [nzLoading]="loadingNewEmail"
                                            style="float: right; margin-top: 16px;">Generate</button>
                                    </div>
                                    <div nz-col nzSpan="12"
                                        style="height: 100%; overflow-y: auto; padding: 16px; border-left: 1px solid #E5E7EB;">
                                        <img [src]="currentEmailType.template.thumbnail" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="mode == 'SOCIALPOST'">

                    <ng-container *ngIf="!emailOptions">
                        <!-- <ng-container *ngIf="emailTemplates.length > 0">
                                <h4 nz-typography style="margin-bottom: 24px;">Your templates</h4>
                                <div nz-row [nzGutter]="16">
                                    <ng-container *ngFor="let emailTemplate of emailTemplates">
                                        <div nz-col class="gutter-row" [nzSpan]="12">
                                            <div class="custom-card">
                                                <div nz-row>
                                                    <div nz-col class="gutter-row" [nzSpan]="20">
                                                        <div class="custom-card-body hover" style="padding: 16px;"
                                                            (click)="chooseTemplate(emailTemplate)">
                                                            <div class="email-type-label">{{emailTemplate.title}}</div>
                                                        </div>
                                                    </div>
                                                    <div nz-col class="gutter-row" [nzSpan]="4"
                                                        style="border-left: 1px solid #e5e7eb; text-align: center;"
                                                        [routerLink]="['/opportunities',emailTemplate._id,'create']">
                                                        <div class="custom-card-body hover" style="padding: 16px;">
                                                            <span nz-icon nzType="edit"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <br>
                                <h4 nz-typography style="margin-bottom: 24px;">Ripple templates</h4>
                            </ng-container>
                            <ng-container *ngIf="emailTemplates.length == 0">
                                <h4 nz-typography style="margin-bottom: 24px;">Choose a campaign type</h4>
                            </ng-container> -->
                        <h4 nz-typography style="margin-bottom: 24px;">Choose a type</h4>
                        <nz-dropdown-menu #menu3="nzDropdownMenu">
                            <ul nz-menu>
                                <li nz-menu-item><span nz-icon nzType="edit" nzTheme="outline"></span>&nbsp;&nbsp;Edit
                                </li>
                            </ul>
                        </nz-dropdown-menu>

                        <div nz-row [nzGutter]="16">
                            <ng-container *ngFor="let emailType of emailTypes">
                                <div nz-col class="gutter-row" [nzSpan]="12">
                                    <div class="custom-card hover" (click)="chooseEmailType(emailType)">
                                        <div class="custom-card-body" style="padding: 16px;">
                                            <div class="email-type-wrapper">
                                                <i class="{{emailType.icon}}"></i>
                                            </div>
                                            <div class="email-type-label">{{emailType.name}}</div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <nz-divider nzText="or"></nz-divider>
                        <button (click)="this.chooseEmailType(this.customPromptEmailType)" nz-button nzBlock><span
                                nz-icon nzType="edit" nzTheme="outline"></span>Write a custom
                            prompt</button>
                    </ng-container>
                    <ng-container *ngIf="emailOptions">
                        <div class="custom-card">
                            <div class="custom-card-header" style="padding: 16px;">
                                <button (click)="this.emailOptions = false" nz-button><span nz-icon nzType="arrow-left"
                                        nzTheme="outline"></span>Back</button>
                            </div>
                            <div class="custom-card-body" style="padding: 16px;">
                                <ng-container *ngFor="let property of currentEmailType.properties">
                                    <ng-container *ngIf="property.type == 'INPUT'">
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true">{{ property.name }}</nz-form-label>
                                            <nz-form-control [nzSpan]="24">
                                                <input nz-input [(ngModel)]="property.value" />
                                            </nz-form-control>
                                        </nz-form-item>
                                    </ng-container>
                                    <ng-container *ngIf="property.type == 'RICHTEXT'">
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true">{{ property.name }}</nz-form-label>
                                            <nz-form-control [nzSpan]="24">
                                                <textarea [nzAutosize]="{ minRows: 2, maxRows: 15 }" nz-input
                                                    [(ngModel)]="property.value"></textarea>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </ng-container>
                                    <ng-container *ngIf="property.type == 'URL'">
                                        <nz-form-label [nzNoColon]="true">{{ property.name }}</nz-form-label>
                                        <nz-form-item style="margin-bottom: 8px;">

                                            <ng-container *ngFor="let pageToScrape of property.value">
                                                <nz-form-control [nzSpan]="24">
                                                    <input placeholder="Enter a url" nz-input
                                                        style="margin-bottom: 4px;" [(ngModel)]="pageToScrape.url" />
                                                </nz-form-control>
                                            </ng-container>

                                            <button nz-button (click)="property.value.push({'url': ''})" nzBlock><span
                                                    nz-icon nzType="plus" nzTheme="outline"></span>Add
                                                url</button>
                                        </nz-form-item>
                                    </ng-container>
                                    <ng-container *ngIf="property.type == 'PRODUCTLIST'">
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true">{{ property.name }}</nz-form-label>
                                            <nz-form-control [nzSpan]="24">
                                                <div nz-row [nzGutter]="8">
                                                    <ng-container
                                                        *ngFor="let item of property.value; let itemIndex=index">

                                                        <div nz-col nzSpan="6">
                                                            <div class="custom-card mini">
                                                                <div class="custom-circle-button delete-item"
                                                                    style="color: red;"
                                                                    (click)="this.removeItemFromArray(itemIndex, property.value)">
                                                                    <span nz-icon nzType="delete"
                                                                        nzTheme="outline"></span>
                                                                </div>
                                                                <img class="picker-item-image"
                                                                    src="{{item.thumbnail}}" />
                                                                <div class="custom-card-footer mini"
                                                                    style="padding: 8px; text-align: left; height: 39px">
                                                                    <div class="picker-item-title">{{item.title}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <div nz-col nzSpan="6">
                                                        <div class="list-add-item"
                                                            (click)="this.sharedService.openPicker(property, 'PRODUCTS', 'MULTI')"
                                                            style="padding: 95px 12px">
                                                            <span nz-icon nzType="plus"
                                                                nzTheme="outline"></span>&nbsp;Add
                                                            product
                                                        </div>
                                                    </div>
                                                </div>

                                            </nz-form-control>
                                        </nz-form-item>
                                    </ng-container>
                                    <ng-container *ngIf="property.type == 'COLLECTION'">
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true">{{ property.name }}</nz-form-label>
                                            <nz-form-control [nzSpan]="24">
                                                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a collection"
                                                    [(ngModel)]="property.value" style="width: 100%;">
                                                    <ng-container *ngFor="let collection of this.collections">
                                                        <nz-option [nzLabel]="collection.data.title"
                                                            [nzValue]="collection"></nz-option>
                                                    </ng-container>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div class="custom-card-footer">
                                <button nz-button nzType="primary" (click)="generateSocialPost()"
                                    [nzLoading]="loadingNewEmail">Generate</button>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="mode == 'FLOW2'">
                    <!-- <h2 nz-typography style="margin-bottom: 24px;">Choose a flow type</h2> -->
                    <div class="custom-card">
                        <ng-container *ngIf="!emailOptions">
                            <div class="custom-card-body" style="padding: 16px;">
                                <div nz-row [nzGutter]="16">
                                    <ng-container *ngFor="let emailType of emailTypes">
                                        <div nz-col class="gutter-row" [nzSpan]="6">
                                            <div class="custom-card hover" (click)="chooseEmailType(emailType)">
                                                <div class="custom-card-body" style="padding: 16px;">
                                                    <div class="email-type-wrapper">
                                                        <i class="{{emailType.icon}}"></i>
                                                    </div>
                                                    <div class="email-type-label">{{emailType.name}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <nz-divider nzText="or"></nz-divider>
                                <!-- <button (click)="this.chooseEmailType(this.customPromptEmailType)" nz-button
                                        nzBlock><span nz-icon nzType="edit" nzTheme="outline"></span>Write a custom
                                        prompt</button> -->
                            </div>
                        </ng-container>
                        <ng-container *ngIf="emailOptions">
                            <div class="custom-card-body" style="height: 100%;">
                                <ng-container *ngFor="let property of currentEmailType.properties">
                                    <ng-container *ngIf="property.type == 'INPUT'">
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true">{{ property.name }}</nz-form-label>
                                            <nz-form-control [nzSpan]="24">
                                                <input nz-input [(ngModel)]="property.value" />
                                            </nz-form-control>
                                        </nz-form-item>
                                    </ng-container>
                                    <ng-container *ngIf="property.type == 'RICHTEXT'">
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true">{{ property.name }}</nz-form-label>
                                            <nz-form-control [nzSpan]="24">
                                                <textarea [nzAutosize]="{ minRows: 2, maxRows: 15 }" nz-input
                                                    [(ngModel)]="property.value"></textarea>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </ng-container>
                                    <ng-container *ngIf="property.type == 'FLOW'">
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true">{{ property.name }}</nz-form-label>
                                            <nz-form-control [nzSpan]="24">
                                                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a flow"
                                                    [(ngModel)]="property.value" style="width: 100%;">
                                                    <ng-container *ngFor="let flow of this.flowList">
                                                        <nz-option [nzLabel]="flow.name" [nzValue]="flow"></nz-option>
                                                    </ng-container>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </ng-container>
                                    <ng-container *ngIf="property.type == 'PRODUCTLIST'">
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true">{{ property.name }}</nz-form-label>
                                            <nz-form-control [nzSpan]="24">
                                                <div nz-row [nzGutter]="8">
                                                    <ng-container
                                                        *ngFor="let item of property.value; let itemIndex=index">

                                                        <div nz-col nzSpan="6">
                                                            <div class="custom-card mini">
                                                                <div class="custom-circle-button delete-item"
                                                                    style="color: red;"
                                                                    (click)="this.removeItemFromArray(itemIndex, property.value)">
                                                                    <span nz-icon nzType="delete"
                                                                        nzTheme="outline"></span>
                                                                </div>
                                                                <img class="picker-item-image"
                                                                    src="{{item.thumbnail}}" />
                                                                <div class="custom-card-footer mini"
                                                                    style="padding: 8px; text-align: left; height: 39px">
                                                                    <div class="picker-item-title">{{item.title}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <div nz-col nzSpan="6">
                                                        <div class="list-add-item"
                                                            (click)="this.sharedService.openPicker(property, 'PRODUCTS', 'MULTI')"
                                                            style="padding: 95px 12px">
                                                            <span nz-icon nzType="plus"
                                                                nzTheme="outline"></span>&nbsp;Add
                                                            product
                                                        </div>
                                                    </div>
                                                </div>

                                            </nz-form-control>
                                        </nz-form-item>
                                    </ng-container>
                                    <ng-container *ngIf="property.type == 'COLLECTION'">
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true">{{ property.name }}</nz-form-label>
                                            <nz-form-control [nzSpan]="24">
                                                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a collection"
                                                    [(ngModel)]="property.value" style="width: 100%;">
                                                    <ng-container *ngFor="let collection of this.collections">
                                                        <nz-option [nzLabel]="collection.data.title"
                                                            [nzValue]="collection"></nz-option>
                                                    </ng-container>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div class="custom-card-footer">
                                <button nz-button nzType="primary" (click)="newEmail()"
                                    [nzLoading]="loadingNewEmail">Generate</button>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
        <ng-container *ngIf="mode == 'CAMPAIGN1'">
            <div class="right-column skinny"
                *ngIf="!this.showRenderer && !this.creatingLayout && !this.showHeroOptions">
                <ng-container *ngIf="!this.showRenderer && !this.creatingLayout">
                    <div class="sidebar-header">
                        <nz-page-header class="site-page-header">
                            <nz-page-header-title>Suggestions</nz-page-header-title>
                            <nz-page-header-extra>
                                <ng-container *ngIf="!this.loadingSuggestions">
                                    <button (click)="generateSuggestions()" nz-button><i class="lni lni-reload"
                                            style="float: left; margin-top: 3px;"></i>&nbsp;&nbsp;Refresh</button>
                                </ng-container>
                            </nz-page-header-extra>
                        </nz-page-header>
                    </div>
                    <div class="lower-inner" style="height: calc(100% - 65px); overflow-y: auto;">
                        <ng-container *ngIf="this.loadingSuggestions">
                            <div class="loading-wrapper">
                                <div class="lds-ellipsis">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!this.loadingSuggestions">
                            <ng-container *ngFor="let suggestion of suggestions">
                                <div class="custom-card">
                                    <div class="custom-card-header" style="padding: 12px 16px">
                                        {{suggestion.title}}

                                    </div>
                                    <div class="custom-card-body" style="padding: 12px 16px">
                                        {{suggestion.reason}}
                                    </div>
                                    <div class="custom-card-footer" style="padding: 12px 16px">
                                        <button nz-button (click)="generateSuggestion(suggestion)">Generate</button>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>

                <!-- <nz-page-header
                    style="padding-bottom: 6px !important; margin-top: 24px !important; padding-top: 0px !important;"
                    class="site-page-header">
                    <nz-page-header-title>Suggestions</nz-page-header-title>
                    <nz-breadcrumb nz-page-header-breadcrumb>
                        <nz-breadcrumb-item>Opportunities</nz-breadcrumb-item>
                        <nz-breadcrumb-item>Pending</nz-breadcrumb-item>
                    </nz-breadcrumb>
                </nz-page-header>
                <ng-container *ngFor="let opp of this.opportunities">
                    <div class="custom-card hover" [routerLink]="['/opportunities',opp._id]">
                        <div class="custom-card-body mini">
                            <div class="new-opp-title"><ng-container *ngIf="opp.template == 'FEATUREDPRODUCT'">
                                    <nz-tag>Featured product</nz-tag>
                                </ng-container>
                                <ng-container *ngIf="opp.template == 'EDUCATIONAL'">
                                    <nz-tag>Educational</nz-tag>
                                </ng-container>
                                <ng-container *ngIf="opp.template == 'FEATUREDCATEGORY'">
                                    <nz-tag>Featured category</nz-tag>
                                </ng-container>{{opp.title}}
                            </div>
                            <div class="new-opp-body">{{opp.description}}</div>
                        </div>
                    </div>
                </ng-container> -->
            </div>
        </ng-container>

        <ng-container *ngIf="this.showRenderer || this.creatingLayout || this.showHeroOptions">
            <!-- <div class="left-column">
                <div class="chat-wrapper-2">

                    <ng-container *ngFor="let message of chatLog">
                        <ng-container *ngIf="message.sender == 'RIPPLE'">
                            <div class="message-bubble-ripple">
                                <div class="message-bubble-title">Ripple</div>

                                {{message.message}}
                            </div>
                        </ng-container>

                        <ng-container *ngIf="message.sender == 'USER'">
                            <div class="message-bubble-user-wrapper">

                                <div class="message-bubble-user">
                                    <div class="message-bubble-title">You</div>

                                    {{message.message}}
                                </div>
                            </div>

                        </ng-container>
                    </ng-container>
                    <div class="message-bubble-ripple" *ngIf="loadingChat">
                        <div class="typing">
                            <div class="dot"></div>
                            <div class="dot"></div>
                            <div class="dot"></div>
                        </div>
                    </div>
                </div>
                <div class="email-controls">
                    <div class="custom-card">
                        <div class="custom-card-body" style="padding: 16px;">

                            <textarea [nzAutosize]="{ minRows: 1, maxRows: 15 }" class="prompt-input scroll" nz-input
                                placeholder="Don't love your email? Give feedback and try again"
                                [(ngModel)]="this.prompt" style="width: 100%;"></textarea>
                        </div>
                        <div class="custom-card-footer" style="height: 68px">
                            <button nz-button nzType="primary" style="float: right" (click)="newEmail()" [disabled]="!this.emailDone"
                                [nzLoading]="loadingNewEmail">Send</button>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="right-column" style="width: 100%;">

                <ng-container *ngIf="this.showHeroOptions">
                    <div nz-row [nzGutter]="16">
                        <ng-container *ngFor="let heroOption of this.heroOptions">
                            <div nz-col class="gutter-row" [nzSpan]="8">
                                <div class="custom-card">
                                    <div class="custom-card-body">
                                        <div class="email-thumbnail"
                                            [ngStyle]="{'background-image': 'url(' + heroOption.screenshot.url + ')'}">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngIf="this.showRenderer">
                    <!-- <div class="meter">
                        <span style="width: 100%"></span>
                    </div> -->
                    <!-- <div *ngIf="delayMessage2" class="floating-error-message">
                        <div class="custom-card">
                            <div class="custom-card-body" style="line-height: 36px; text-align: left">
                                <span>⚠️&nbsp;&nbsp;Ripple encountered an issue</span>
                                <button nz-button nzType="primary" style="float: right" (click)="newEmail()">Try
                                    again</button>
                            </div>
                        </div>
                    </div> -->
                    <app-email-renderer [mode]="'VIEW'"></app-email-renderer>

                </ng-container>

                <ng-container *ngIf="this.creatingLayout">
                    <div class="loading-wrapper" style="text-align: center; width: 600px; margin: auto">
                        <!-- <div class="rippleLoaderWrapper">
                            <div class="rippleLoader">
                                <div></div>
                                <div></div>
                            </div>
                        </div> -->
                        <div class="custom-card">
                            <div class="custom-card-body">
                                <img src="../../assets/magicWand.gif"
                                    style="width: 125px; height: 125px; margin: auto;" />
                                <br>
                                <br>
                                <div class="pulsing-italic">{{this.loadingText}}</div>
                                <br>

                            </div>
                        </div>
                        <!-- <div *ngIf="delayMessage">
                            <div class="custom-card">
                                <div class="custom-card-body" style="line-height: 36px; text-align: left">
                                    <span>⚠️&nbsp;&nbsp;Ripple encountered an issue</span>
                                    <button nz-button nzType="primary" style="float: right" (click)="newEmail()">Try
                                        again</button>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </ng-container>
            </div>
        </ng-container>

    </div>
</div>

<div class="custom-modal-wrapper" *ngIf="createFlowFolderOpen">
    <div class="custom-modal-inner" style="width: 500px; height: auto; margin-top: 100px;">
        <div class="custom-modal-header">
            <div class="custom-modal-title">New flow</div>
            <div class="custom-modal-close" (click)="this.createFlowFolderOpen = false"><span nz-icon nzType="close" nzTheme="outline"></span></div>
        </div>
        <div class="custom-modal-body" style="overflow:hidden;">
            <nz-form-control [nzSpan]="24">
                <input placeholder="Ex: Abandonded cart flow" nz-input [(ngModel)]="this.flowName"/>
            </nz-form-control>
        </div>
        <div class="custom-modal-footer">
            <button nz-button nzType="primary" (click)="createFlow()" [nzLoading]="this.creatingFlows">Create</button>
        </div>
    </div>
</div>

<ng-template #renderTemplate>
    <div class="custom-modal-footer" style="padding: 8px; margin-top: 8px;">
        <button nz-button (click)="this.createFlowFolderOpen = true"><span nz-icon nzType="plus" nzTheme="outline"></span>Create flow</button>
    </div>
  </ng-template>