<div class="page-wrapper">
    <div class="header-wrapper">
        <nz-page-header class="site-page-header" nzTitle="Segments">
            <!-- <nz-breadcrumb nz-page-header-breadcrumb>
                <nz-breadcrumb-item>Opportunities</nz-breadcrumb-item>
                <nz-breadcrumb-item>Pending</nz-breadcrumb-item>
            </nz-breadcrumb> -->
            <nz-page-header-extra>
                <button nz-button nzType="primary" [routerLink]="['/new']"><span nz-icon nzType="plus"
                        nzTheme="outline"></span>New</button>
            </nz-page-header-extra>
        </nz-page-header>
    </div>
    <div class="content-wrapper">
        <!-- <ul nz-menu nzMode="horizontal" style="margin-bottom: 16px !important;">
                <li nz-menu-item [routerLink]="['/opportunities/new']">
                    New
                </li>
                <li nz-menu-item nzSelected>
                    Pending
                </li>
                <li nz-menu-item>
                    Live
                </li>
            </ul> -->
        <div class="custom-card table-card">
            <div class="header-row">
                <div nz-row [nzGutter]="16">
                    <div nz-col class="gutter-row" [nzSpan]="16">
                        <div class="inner-box">Title</div>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="4">
                        <div class="inner-box">Size</div>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="4">
                        <div class="inner-box">Updated</div>
                    </div>
                </div>
            </div>
            <div class="custom-card-body table-card">

                <ng-container *ngIf="this.loading">
                    <div class="loading-wrapper">
                        <div class="dot"></div>
                    </div>

                    <!-- <div nz-row [nzGutter]="16" class="table-row" style="padding-top: 16px;">
                    <div nz-col class="gutter-row" [nzSpan]="15">
                        <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="5">
                        <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="4">
                        <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                    </div>
                </div>
                <div nz-row [nzGutter]="16" class="table-row" style="padding-top: 16px;">
                    <div nz-col class="gutter-row" [nzSpan]="15">
                        <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="5">
                        <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="4">
                        <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                    </div>
                </div>
                <div nz-row [nzGutter]="16" class="table-row" style="padding-top: 16px;">
                    <div nz-col class="gutter-row" [nzSpan]="15">
                        <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="5">
                        <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="4">
                        <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                    </div>
                </div>
                <div nz-row [nzGutter]="16" class="table-row" style="padding-top: 16px;">
                    <div nz-col class="gutter-row" [nzSpan]="15">
                        <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="5">
                        <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="4">
                        <nz-skeleton [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                    </div>
                </div> -->

                </ng-container>
                <ng-container *ngIf="!this.loading">

                    <div nz-row [nzGutter]="16" class="table-row">
                        <div nz-col class="gutter-row" [nzSpan]="16">New subscribers</div>
                        <div nz-col class="gutter-row" [nzSpan]="4">17541</div>
                        <div nz-col class="gutter-row" [nzSpan]="4">Sep 3, 2023</div>
                    </div>
                    <div nz-row [nzGutter]="16" class="table-row">
                        <div nz-col class="gutter-row" [nzSpan]="16">Engaged non-purchasers</div>
                        <div nz-col class="gutter-row" [nzSpan]="4">2051</div>
                        <div nz-col class="gutter-row" [nzSpan]="4">Sep 3, 2023</div>
                    </div>
                    <div nz-row [nzGutter]="16" class="table-row">
                        <div nz-col class="gutter-row" [nzSpan]="16">One-time purchasers</div>
                        <div nz-col class="gutter-row" [nzSpan]="4">1891</div>
                        <div nz-col class="gutter-row" [nzSpan]="4">Sep 3, 2023</div>
                    </div>
                    <div nz-row [nzGutter]="16" class="table-row">
                        <div nz-col class="gutter-row" [nzSpan]="16">Frequent buyers</div>
                        <div nz-col class="gutter-row" [nzSpan]="4">452</div>
                        <div nz-col class="gutter-row" [nzSpan]="4">Sep 3, 2023</div>
                    </div>
                    <div nz-row [nzGutter]="16" class="table-row">
                        <div nz-col class="gutter-row" [nzSpan]="16">Dormant purchasers</div>
                        <div nz-col class="gutter-row" [nzSpan]="4">1320</div>
                        <div nz-col class="gutter-row" [nzSpan]="4">Sep 3, 2023</div>
                    </div>
                    <div nz-row [nzGutter]="16" class="table-row">
                        <div nz-col class="gutter-row" [nzSpan]="16">Lapsed subscribers</div>
                        <div nz-col class="gutter-row" [nzSpan]="4">1621</div>
                        <div nz-col class="gutter-row" [nzSpan]="4">Sep 3, 2023</div>
                    </div>
                    <div nz-row [nzGutter]="16" class="table-row">
                        <div nz-col class="gutter-row" [nzSpan]="16">VIPs</div>
                        <div nz-col class="gutter-row" [nzSpan]="4">2021</div>
                        <div nz-col class="gutter-row" [nzSpan]="4">Sep 3, 2023</div>
                    </div>
                    <div nz-row [nzGutter]="16" class="table-row">
                        <div nz-col class="gutter-row" [nzSpan]="16">Seasonal shoppers</div>
                        <div nz-col class="gutter-row" [nzSpan]="4">792</div>
                        <div nz-col class="gutter-row" [nzSpan]="4">Sep 3, 2023</div>
                    </div>
                </ng-container>
            </div>

            <nz-pagination [nzTotal]="opportunities.length" [nzPageSize]="50"
                (nzPageIndexChange)="this.pageIndexChange($event)" style="float: right; margin: 8px"></nz-pagination>

            <!-- <ng-container *ngFor="let opportunity of opportunities; index as index">
                <ng-container *ngIf="index >= (this.currentPage - 1) * 50 && index < (this.currentPage * 50)">
                    <div nz-row [nzGutter]="16" class="table-row" [routerLink]="['/opportunities',opportunity._id]">
                        <div nz-col class="gutter-row" [nzSpan]="8">
                            <div style="text-overflow: ellipsis; overflow: hidden;
                            white-space: nowrap;">{{opportunity.title}}</div>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="4">
                            <ng-container *ngIf="opportunity.stage == 'LIVE'">
                                <nz-tag class="aqua">Scheduled</nz-tag>
                            </ng-container>
                            <ng-container *ngIf="opportunity.stage == 'PENDING'">
                                <nz-tag>Draft</nz-tag>
                            </ng-container>
                            <ng-container *ngIf="opportunity.stage == 'NEW'">
                                Generating
                            </ng-container>

                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="4">
                            <ng-container *ngIf="opportunity.template == 'FEATUREDPRODUCT'">
                                <nz-tag>Featured product</nz-tag>
                            </ng-container>
                            <ng-container *ngIf="opportunity.template == 'EDUCATIONAL'">
                                <nz-tag>Educational</nz-tag>
                            </ng-container>
                            <ng-container *ngIf="opportunity.template == 'FEATUREDCATEGORY'">
                                <nz-tag>Featured category</nz-tag>
                            </ng-container>

                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="4">
                            {{opportunity.sendTime | date}}
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="4">
                            {{opportunity.updatedAt | date}}
                        </div>
                    </div>
                </ng-container>
            </ng-container> -->

        </div>
        <!-- <div class="column-right">
            <nz-page-header style="padding-bottom: 6px !important" class="site-page-header">
                <nz-page-header-title>Suggestions</nz-page-header-title>
                <nz-page-header-extra>
                    <button nz-button [routerLink]="['/new']">See more</button>
                </nz-page-header-extra>
            </nz-page-header>
            <ng-container *ngFor="let opp of this.newOpportunities">
                <div class="custom-card hover" [routerLink]="['/opportunities',opp._id]">
                    <div class="custom-card-body mini">
                        <div class="new-opp-title">{{opp.title}}</div>
                        <ng-container *ngIf="opp.template == 'FEATUREDPRODUCT'">
                            <nz-tag >Featured product</nz-tag>
                        </ng-container>
                        <ng-container *ngIf="opp.template == 'EDUCATIONAL'">
                            <nz-tag >Educational</nz-tag>
                        </ng-container>
                        <ng-container *ngIf="opp.template == 'FEATUREDCATEGORY'">
                            <nz-tag >Featured category</nz-tag>
                        </ng-container>
                        <div class="new-opp-body">{{opp.description}}</div>
                    </div>
                </div>
            </ng-container>
        </div> -->


    </div>
</div>