import { Component } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ApiService } from 'src/app/api.service';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-settings-brand',
  templateUrl: './settings-brand.component.html',
  styleUrls: ['./settings-brand.component.scss']
})
export class SettingsBrandComponent {

  loading = true;
  saving = false;
  workspace: any = null;

  navbarLinksTemplate = {
    name: "Navbar links",
    type: "DATALIST",
    category: "CONTENT",
    value: [
    ],
    dataTemplate: [
      {
        name: "Title",
        type: "INPUT",
        category: "CONTENT",
        value: "",
      },
      {
        name: "Link",
        type: "INPUT",
        category: "CONTENT",
        value: "",
      },
    ]
  };

  footerLinksTemplate = {
    name: "Social links",
    type: "DATALIST",
    category: "CONTENT",
    value: [
    ],
    dataTemplate: [
      {
        name: "Platform",
        type: "SELECT",
        category: "CONTENT",
        value: {
          title: "Instagram",
          value: "fa-brands fa-instagram"
        },
        options: [
          {
            title: "Instagram",
            value: "fa-brands fa-instagram"
          },
          {
            title: "Facebook",
            value: "fa-brands fa-facebook"
          },
          {
            title: "Twitter",
            value: "fa-brands fa-x-twitter"
          },
          {
            title: "Pinterest",
            value: "fa-brands fa-pinterest"
          },
          {
            title: "Youtube",
            value: "fa-brands fa-youtube"
          },
          {
            title: "Linkedin",
            value: "fa-brands fa-linkedin"
          },
          {
            title: "Discord",
            value: "fa-brands fa-discord"
          }
        ]
      },
      {
        name: "Link",
        type: "INPUT",
        category: "CONTENT",
        value: "",
      },
    ]
  };

  brandImagesTemplate = {
    name: "Images",
    type: "DATALIST",
    category: "CONTENT",
    value: [
    ],
    dataTemplate: [
      {
        name: "Image",
        type: "IMAGESELECT",
        value: "",
      }
    ]
  };

  fontsTemplate = [
    {
      name: "Hero",
      font: "",
      fontWeight: "",
      fontSize: "54",
      lineHeight: "62"
    },
    {
      name: "Title",
      font: "",
      fontWeight: "",
      fontSize: "32",
      lineHeight: "40"
    },
    {
      name: "Subtitle",
      font: "",
      fontWeight: "",
      fontSize: "24",
      lineHeight: "32"
    },
    {
      name: "Body",
      font: "",
      fontWeight: "",
      fontSize: "18",
      lineHeight: "26"
    },
    {
      name: "Button",
      font: "",
      fontWeight: "",
      fontSize: "16",
      lineHeight: "24"
    },
  ];

  fontsTemplateHero = {
    name: "Hero",
    font: "",
    fontWeight: "",
    fontSize: "54"
  };

  customFontTemplate = {
    value: "",
    url: "",
  };

  constructor(
    public apiService: ApiService,
    public sharedService: SharedService,
    private message: NzMessageService
  ) { }

  ngOnInit() {
    this.apiService.getCurrentWorkspace().subscribe(
      (data: any) => {
        console.log(data);
        if (!data.brandStyles.navbar) {
          data.brandStyles.navbar = JSON.parse(JSON.stringify(this.navbarLinksTemplate));
        }

        if (!data.brandStyles.footerLinks) {
          data.brandStyles.footerLinks = JSON.parse(JSON.stringify(this.footerLinksTemplate));
        } else {
          data.brandStyles.footerLinks.dataTemplate[0].options = JSON.parse(JSON.stringify(this.footerLinksTemplate.dataTemplate[0].options));
        }

        if (!data.brandStyles.brandImages) {
          data.brandStyles.brandImages = JSON.parse(JSON.stringify(this.brandImagesTemplate));
        }

        if (!data.brandStyles.headerTemplate) {
          this.apiService.createHeaderTemplate().subscribe(res => {

          });
        }

        if (!data.brandStyles.footerTemplate) {
          this.apiService.createFooterTemplate().subscribe(res => {

          });
        }

        if (!data.brandStyles.fonts) {
          data.brandStyles.fonts = JSON.parse(JSON.stringify(this.fontsTemplate));
        }

        for (let fontNumber in data.brandStyles?.fonts) {
          let font = data.brandStyles.fonts[fontNumber];
          let fontCount: number = parseInt(fontNumber);

          if (!font.lineHeight) {
            if (this.fontsTemplate[fontCount]) {
              font.lineHeight = this.fontsTemplate[fontCount].lineHeight;
            }
          }

          if (!font.fontSize) {
            if (this.fontsTemplate[fontCount]) {
              font.fontSize = this.fontsTemplate[fontCount].fontSize;
            }
          }
        }

        if (!this.sharedService.getArrayItem(data.brandStyles.fonts, "name", "Hero")) {
          data.brandStyles.fonts.unshift(JSON.parse(JSON.stringify(this.fontsTemplateHero)));
          console.log(data.brandStyles.fonts);
        }

        this.workspace = data;
        this.sharedService.brandLogo = this.workspace.brandStyles.logo;
        this.sharedService.workspace = data;
        this.loading = false;
      },
      error => {
        console.log(error);
      });
  }

  openPicker(property: any, mode: any) {
    console.log("Open Picker");
    this.sharedService.currentProperty = property;
    this.sharedService.pickerMode = mode;
    this.sharedService.pickerOpen = true;
  }

  saveBrandStyles() {
    this.saving = true;
    this.apiService.saveBrandStyles(this.workspace.brandStyles).subscribe(
      (data: any) => {
        console.log(data);
        this.saving = false;
        this.message.create("success", `Settings saved`);
      },
      error => {
        console.log(error);
      });
  }

  compareFn = (o1: any, o2: any): boolean => (o1 && o2 ? o1 === o2 : o1 === o2);

  compareFnSocialLinks = (o1: any, o2: any): boolean => (o1 && o2 ? o1.value === o2.value : o1 === o2);


  pushDataListValue(property: any) {
    property.value.push(JSON.parse(JSON.stringify(property.dataTemplate)));
  }

  addColor() {
    if (!this.workspace.brandStyles.additionalColors) {
      this.workspace.brandStyles.additionalColors = [];
    }
    this.workspace.brandStyles.additionalColors.push({ value: "#FFFFFF" });
  }

  addCustomFont() {
    if (!this.workspace.brandStyles.customFonts) {
      this.workspace.brandStyles.customFonts = [];
    }
    this.workspace.brandStyles.customFonts.push(JSON.parse(JSON.stringify(this.customFontTemplate)));
  }
}


