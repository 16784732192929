<div class="page-wrapper">
    <div class="content-wrapper">
        <nz-page-header class="site-page-header" style="padding: 0px !important">
            <nz-page-header-title></nz-page-header-title>
            <nz-page-header-extra>
                <button (click)="saveBrandStyles()" nz-button nzType="primary">Save</button>
            </nz-page-header-extra>
        </nz-page-header>
        <div class="custom-card" style="margin-top: 16px;">
            <div class="custom-card-body">
                <nz-form-item>
                    <nz-form-label [nzNoColon]="true">Tone & voice</nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <nz-select nzMode="multiple" nzPlaceHolder="Select" style="width: 400px" [(ngModel)]="selectModel" (ngModelChange)="changeSelect()" [nzMaxTagCount]="2" [nzMaxTagPlaceholder]="tagPlaceHolder">
                            <nz-option nzLabel="Formal" nzValue="4"></nz-option>
                            <nz-option nzLabel="Casual" nzValue="0"></nz-option>
                            <nz-option nzLabel="Friendly" nzValue="1"></nz-option>
                            <nz-option nzLabel="Authoritative" nzValue="2"></nz-option>
                            <nz-option nzLabel="Playful" nzValue="3"></nz-option>
                            <nz-option nzLabel="Inspiring" nzValue="3"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzNoColon]="true">Subject line style</nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <nz-select nzMode="multiple" nzPlaceHolder="Select" style="width: 400px" [(ngModel)]="selectModel" [nzMaxTagCount]="2" [nzMaxTagPlaceholder]="tagPlaceHolder">
                            <nz-option nzLabel="Question based" nzValue="4"></nz-option>
                            <nz-option nzLabel="Urgency driven" nzValue="0"></nz-option>
                            <nz-option nzLabel="Teaser" nzValue="1"></nz-option>
                            <nz-option nzLabel="Personalized" nzValue="2"></nz-option>
                            <nz-option nzLabel="Benefit-focused" nzValue="3"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzNoColon]="true">Call to action style</nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <nz-select nzMode="multiple" nzPlaceHolder="Select" style="width: 400px" [(ngModel)]="selectModel" [nzMaxTagCount]="2" [nzMaxTagPlaceholder]="tagPlaceHolder">
                            <nz-option nzLabel="Shop now" nzValue="4"></nz-option>
                            <nz-option nzLabel="Discover" nzValue="0"></nz-option>
                            <nz-option nzLabel="Get yours" nzValue="1"></nz-option>
                            <nz-option nzLabel="Don't miss out" nzValue="2"></nz-option>
                            <nz-option nzLabel="See the magic" nzValue="3"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzNoColon]="true">Greeting style</nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <nz-select  nzMode="multiple" nzPlaceHolder="Select" style="width: 400px" [(ngModel)]="selectModel" [nzMaxTagCount]="2" [nzMaxTagPlaceholder]="tagPlaceHolder">
                            <nz-option nzLabel="Personal greeting" nzValue="4"></nz-option>
                            <nz-option nzLabel="Bold statement" nzValue="0"></nz-option>
                            <nz-option nzLabel="Intruiging question" nzValue="1"></nz-option>
                            <nz-option nzLabel="Statistic or fact" nzValue="2"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzNoColon]="true">Perzonalization</nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <nz-select  nzPlaceHolder="Select" style="width: 400px" [(ngModel)]="selectModel" nzMode="multiple" [nzMaxTagCount]="2" [nzMaxTagPlaceholder]="tagPlaceHolder">
                            <nz-option nzLabel="First name" nzValue="4"></nz-option>
                            <nz-option nzLabel="Last name" nzValue="1"></nz-option>
                            <nz-option nzLabel="Recommendations based on browsing" nzValue="3"></nz-option>
                            <nz-option nzLabel="Last purcase mention" nzValue="2"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzNoColon]="true">Email length</nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <nz-slider [ngModel]="30"></nz-slider>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzNoColon]="true">Storytelling</nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <nz-slider [ngModel]="60"></nz-slider>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzNoColon]="true">Educational / sales content ratio</nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <nz-slider [ngModel]="20"></nz-slider>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzNoColon]="true">Urgency / scarcity emphasis</nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <nz-slider [ngModel]="90"></nz-slider>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </div>
</div>

<ng-template #tagPlaceHolder let-selectedList>+1</ng-template>
