import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from '../api.service';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-calendar-planning',
  templateUrl: './calendar-planning.component.html',
  styleUrls: ['./calendar-planning.component.scss']
})
export class CalendarPlanningComponent {
  @ViewChild('chatDiv') chatDiv!: ElementRef;

  opportunities: any = [];
  emailCampaigns: any = [];
  loading = true;
  noCampaigns = false;
  generatingCampaigns = false;
  month: number = 10;
  year: number = 2023

  generationResponse: any = {};
  chatLog: any = [];

  loadingChat: any = false;
  chatBox: any = "";

  popoverItem: any = {};

  calendarData: any = [];

  saving: any = false;

  threadId = null;

  mode = "CALENDAR";

  sessionList: any = [];

  emailsOnCalendar: any = false;

  @ViewChild('customTemplate', { static: false }) customTemplate!: TemplateRef<any>;

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public sharedService: SharedService,
    public notification: NzNotificationService,
    private message: NzMessageService,
  ) {

  }

  ngOnInit() {

    this.apiService.getAssistantSessions().subscribe(
      (data: any) => {
        console.log(data);
        this.sessionList = data;
      },
      error => {
        console.log(error);
      });

    // this.chatLog.push({ message: "Hey there! What can I help you with today?", sender: "RIPPLE" });
    // this.chatLog.push({
    //   message: "Enter your own message below, or try one of these to get started :)", sender: "RIPPLE", actions: [
    //     {
    //       value: "Make an email calendar for the next 30 days"
    //     },
    //     {
    //       value: "Make a series of emails for an upcoming sale"
    //     },
    //     {
    //       value: "I have a specific email I want to make"
    //     },

    //   ]
    // });

    this.sharedService.currentPlanningThread = null;
    this.sharedService.currentPlanningSession = null;
    this.generationResponse.emails = [];
    this.emailsOnCalendar = false;
    this.chatLog = [];
    this.chatLog.push({ message: "Hey there! What can I help you with today?", sender: "RIPPLE" });
    this.chatLog.push({
      message: "Enter your own message below, or try one of these to get started :)", sender: "RIPPLE", actions: [
        {
          value: "Make an email calendar for the next 30 days"
        },
        {
          value: "Make a series of emails for an upcoming sale"
        },
        {
          value: "I have a specific email I want to make"
        },

      ]
    });
    // this.changeDate();

    // this.chatLog.push({ message: "Ripple is generating a proposal...", sender: "RIPPLE" });

    if (this.sharedService.currentPlanningThread) {
      this.apiService.getOpenAiThread(this.sharedService.currentPlanningThread).subscribe(
        (data: any) => {
          console.log(data);
          // this.generationResponse = data;
          // this.convertToCalendarFormat(data.gptTemplate);
          // this.chatLog.push({ message: data.description, sender: "RIPPLE" });
          // this.loadingChat = false;
          // this.sharedService.loadingCalendar = false;
        },
        error => {
          console.log(error);
        });
    }

    this.apiService.getEmailCampaignCalendar(this.sharedService.calendarMonth, this.sharedService.calendarYear).subscribe(
      (data: any) => {
        console.log(data);
        this.calendarData = data;
        this.sharedService.loadingCalendar = false;
      },
      error => {
        console.log(error);
      });

    // this.apiService.generateEmailCalendar2({}).subscribe(
    //   (data: any) => {
    //     console.log(data);
    //     this.generationResponse = data;
    //     this.convertToCalendarFormat(data.gptTemplate);
    //     this.chatLog.push({ message: data.description, sender: "RIPPLE" });
    //     this.loadingChat = false;
    //     this.sharedService.loadingCalendar = false;
    //   },
    //   error => {
    //     console.log(error);
    //   });
    // this.apiService.getEmailCampaignCalendar(this.sharedService.calendarMonth, this.sharedService.calendarYear).subscribe(
    //   (data: any) => {
    //     console.log(data);
    //     this.sharedService.calendarData = data;
    //     this.sharedService.loadingCalendar = false;
    //   },
    //   error => {
    //     console.log(error);
    //   });

    // this.apiService.getEmailCampaigns().subscribe(
    //   (data: any) => {
    //     console.log(data);
    //     this.emailCampaigns = data;
    //     if (this.emailCampaigns.length > 0) {
    //       this.noCampaigns = false;
    //     }
    //     this.loading = false;

    //   },
    //   error => {
    //     console.log(error);
    //   });
  }

  changeDate() {
    this.sharedService.loadingCalendar = true;
    console.log(this.sharedService.calendarMonth);
    console.log(this.sharedService.calendarYear);

    if (typeof this.sharedService.calendarYear == "string") {
      this.sharedService.calendarYear = parseInt(this.sharedService.calendarYear);
    }

    if (typeof this.sharedService.calendarMonth == "string") {
      this.sharedService.calendarMonth = parseInt(this.sharedService.calendarMonth);
    }

    this.apiService.getEmailCampaignCalendar(this.sharedService.calendarMonth, this.sharedService.calendarYear).subscribe(
      (data: any) => {
        console.log(data);
        this.calendarData = data;
        this.sharedService.loadingCalendar = false;
        this.convertToCalendarFormat(this.generationResponse.emails);
      },
      error => {
        console.log(error);
      });
  }

  compareFn = (o1: any, o2: any): boolean => (o1 && o2 ? parseInt(o1) == o2 : o1 == o2);

  increaseMonth() {
    if (this.sharedService.calendarMonth < 11) {
      this.sharedService.calendarMonth = this.sharedService.calendarMonth + 1;
      this.month = this.month + 1;
    } else {
      this.month = 0;
      this.sharedService.calendarMonth = 0;
      this.sharedService.calendarYear = this.sharedService.calendarYear + 1;
    }

    console.log(this.sharedService.calendarMonth);
    console.log(this.sharedService.calendarYear);

    this.changeDate();
  }

  decreaseMonth() {
    if (this.sharedService.calendarMonth > 0) {
      this.sharedService.calendarMonth = this.sharedService.calendarMonth - 1;
      this.month = this.month - 1;
    } else {
      this.month = 11;
      this.sharedService.calendarMonth = 11;
      this.sharedService.calendarYear = this.sharedService.calendarYear - 1;
    }

    console.log(this.sharedService.calendarMonth);
    console.log(this.sharedService.calendarYear);

    this.changeDate();
  }

  dateInPast(date: any) {

    let adjustedDate = new Date(Date.parse(date));
    adjustedDate.setMinutes(adjustedDate.getMinutes() + adjustedDate.getTimezoneOffset());

    if ((adjustedDate.getDate() < new Date().getDate() && adjustedDate.getMonth() <= new Date().getMonth() && adjustedDate.getFullYear() <= new Date().getFullYear()) || (adjustedDate.getMonth() < new Date().getMonth() && adjustedDate.getFullYear() <= new Date().getFullYear()) || adjustedDate.getFullYear() < new Date().getFullYear()) {
      return true;
    } else {
      return false;
    }
  }

  sendFeedback() {
    this.chatLog.push({ message: this.chatBox, sender: "USER" });
    this.loadingChat = true;
    this.apiService.generateEmailCalendar2({ mode: "FEEDBACK", feedback: this.chatBox, gptTemplate: this.generationResponse, messages: this.chatLog }).subscribe((data: any) => {
      this.generationResponse = data;
      this.convertToCalendarFormat(data.gptTemplate);
      this.chatLog.push({ message: data.description, sender: "RIPPLE" });
      this.loadingChat = false;
    });
    setTimeout((complete: any) => {
      this.chatLog.push({ message: "Got it! Working on that now", sender: "RIPPLE" });

    }, 1500);
    this.chatBox = "";
  }

  convertToCalendarFormat(events: any) {

    let month = this.sharedService.calendarMonth;
    let year = this.sharedService.calendarYear;
    var date = new Date(year, month, 1);
    var days: any = [];
    while (date.getMonth() === month) {
      days.push({
        date: new Date(date),
        dayOfWeek: date.getDay(),
        dayOfMonth: date.getDate(),
        items: []
      });
      date.setDate(date.getDate() + 1);
    }

    console.log(days);

    for (let opp of events) {
      let oppDate = new Date(opp.date);
      if (oppDate.getMonth() === month && oppDate.getFullYear() === year) {
        let oppDay = new Date(opp.date).getDate();

        days[oppDay - 1].items.push(opp);
      }
    }

    if (days[0].dayOfWeek > 0) {
      let dayCounter: any = 0;

      while (dayCounter < days[0 + dayCounter].dayOfWeek) {
        days.unshift({ filler: true });
        dayCounter++;
      }
    }

    if (days.length < 42) {
      let difference = 42 - days.length;
      let n = 0;
      while (n < difference) {
        days.push({ filler: true });
        n++;
      }
    }

    let weeks = [];

    while (days.length) {
      weeks.push(days.splice(0, 7));
    }

    let week: any = 0;
    console.log(this.calendarData);
    console.log(weeks);
    for (week in this.calendarData) {
      for (let day in this.calendarData[week]) {
        if (weeks[week][day].items) {
          if (weeks[week][day].items.length > 0) {
            this.calendarData[week][day].items.unshift(weeks[week][day].items[0]);
          }
        }
      }
    }

    // this.calendarData = weeks;
    this.sharedService.loadingCalendar = false;
    console.log(this.calendarData);
  }

  // Function that saves the email calendar that was generated and then directs the user back to the home page
  async saveCalendar() {
    this.saving = true;
    let data: any = await this.apiService.saveEmailCalendar(this.generationResponse.emails).toPromise();
    console.log(data);
    //Loop through the saved emails and trigger generate content template for each one
    for (let email of data) {
      // let data2 = await this.apiService.generateTemplateContent2(email._id, "NEW", "").toPromise();
      let result = {};
      this.apiService.generateTemplateContent2(email._id, "NEW", "").subscribe((result: any) => {
        console.log(result);
        // const template: TemplateRef<void> = this.customTemplate;

        this.message.create(
          'success',
          'Email generated'
        );

        // this.notification.template(this.customTemplate!, { nzData: {title: "test1234", oppId: email._id}, nzPlacement: "top" });

        this.sharedService.fetchCalendar();
      });
    };

    // this.notification.template(this.customTemplate!, { nzData: {title: "test1234"}, nzPlacement: "top" });

    this.message.create(
      'success',
      'Email calendar saved'
    );

    this.saving = false;
    this.router.navigate(['/calendar']);
  }

  sendOpenAiChat(message: any) {
    this.chatLog.push({ message: message, sender: "USER" });
    this.loadingChat = true;

    this.chatLog[1].actions = [];

    // this.scrollToBottom();

    this.apiService.sendOpenAiPlannerChat(message, this.sharedService.currentPlanningThread, this.sharedService.currentPlanningSession).subscribe(
      (data2: any) => {
        console.log(data2);

        if (!this.sharedService.currentPlanningThread) {
          this.refreshSessions();
          this.sharedService.currentPlanningThread = data2.thread;
          this.sharedService.currentPlanningSession = data2.session;
        };

        if (data2.emails && data2.emails.length > 0) {
          this.emailsOnCalendar = true;
          this.generationResponse = data2;
          this.convertToCalendarFormat(data2.emails);
          this.sharedService.calendarMonth = new Date(data2.emails[0].date).getMonth();
          this.sharedService.calendarYear = new Date(data2.emails[0].date).getFullYear();
          this.changeDate();
        }

        if (data2.message && data2.message != "") {
          this.chatLog.push({ message: data2.message, sender: "RIPPLE" });
          this.loadingChat = false;
        }

        this.loadingChat = false;
        // this.scrollToBottom();

        // if (data2.required_action) {
        //   let responseJson = JSON.parse(data2.required_action.submit_tool_outputs.tool_calls[0].function.arguments);
        //   this.generationResponse = responseJson;
        //   this.convertToCalendarFormat(responseJson.emails);
        //   this.sharedService.calendarMonth = new Date(responseJson.emails[0].date).getMonth();
        //   this.sharedService.calendarYear = new Date(responseJson.emails[0].date).getFullYear();
        //   this.changeDate();
        //   this.loadingChat = false;
        // } else {
        //   this.chatLog.push({ message: data2.data[0].content[0].text.value, sender: "RIPPLE" });
        //   this.loadingChat = false;
        // }

        // if (data2.data[0].content[0].text.value[0] == '`') {
        //   // Use a regular expression to extract the JSON part
        //   const jsonMatch = data2.data[0].content[0].text.value.match(/```json([\s\S]*?)```/);

        //   if (jsonMatch && jsonMatch[1]) {
        //     const jsonString = jsonMatch[1].trim();

        //     try {
        //       // Parse the extracted JSON
        //       const responseJson = JSON.parse(jsonString);

        //       if (responseJson.message) {
        //         this.chatLog.push({ message: responseJson.message, sender: "RIPPLE" });
        //         if (responseJson.emails && responseJson.emails.length > 0) {
        //           this.convertToCalendarFormat(responseJson.emails);
        //           this.mode = "CALENDAR";
        //         }
        //         this.loadingChat = false;
        //       }

        //       this.generationResponse = responseJson;

        //     } catch (error) {
        //       console.error("Error parsing JSON:", error);
        //     }
        //   } else {
        //     console.error("No JSON found in the input string");
        //   }
        // } else {
        //   let responseJson = JSON.parse(data2.data[0].content[0].text.value);
        //   if (responseJson.message) {
        //     this.chatLog.push({ message: responseJson.message, sender: "RIPPLE" });
        //     if (responseJson.emails && responseJson.emails.length > 0) {
        //       this.convertToCalendarFormat(responseJson.emails);
        //       this.sharedService.calendarMonth = new Date(responseJson.emails[0].date).getMonth();
        //       this.sharedService.calendarYear = new Date(responseJson.emails[0].date).getFullYear();
        //       this.changeDate();
        //       this.mode = "CALENDAR";
        //     }
        //     this.loadingChat = false;
        //   }

        //   this.generationResponse = responseJson;
        // }
      },
      error => {
        console.log(error);
      });

    this.chatBox = "";
  }

  sendChatFromButton(message: any, messageObject: any) {
    messageObject.actions = [];
    this.sendOpenAiChat(message);
  }

  scrollToBottom(): void {
    try {
      this.chatDiv.nativeElement.scrollTop = this.chatDiv.nativeElement.scrollHeight;
    } catch (err) { }
  }

  changeSession(session: any) {
    this.loading = true;
    this.apiService.getAssistantSession(session).subscribe(
      (data: any) => {
        console.log(data);
        this.chatLog = data.data.messages;
        this.generationResponse.emails = data.data.calendar;

        if (data.data.calendar.length > 0) {
          this.emailsOnCalendar = true;
          this.convertToCalendarFormat(data.data.calendar);
          this.sharedService.calendarMonth = new Date(data.data.calendar[0].date).getMonth();
          this.sharedService.calendarYear = new Date(data.data.calendar[0].date).getFullYear();
        } else {
          this.emailsOnCalendar = false;
        }

        this.changeDate();
        this.sharedService.currentPlanningThread = data.externalData.thread;
        this.sharedService.currentPlanningSession = data._id;
        this.loading = false;
      },
      error => {
        console.log(error);
      });
  }

  refreshSessions() {
    this.apiService.getAssistantSessions().subscribe(
      (data: any) => {
        console.log(data);
        this.sessionList = data;
      },
      error => {
        console.log(error);
      });
  }

  newSession() {
    this.sharedService.currentPlanningThread = null;
    this.sharedService.currentPlanningSession = null;
    this.generationResponse.emails = [];
    this.emailsOnCalendar = false;
    this.chatLog = [];
    this.chatLog.push({ message: "Hey there! What can I help you with today?", sender: "RIPPLE" });
    this.chatLog.push({
      message: "Enter your own message below, or try one of these to get started :)", sender: "RIPPLE", actions: [
        {
          value: "Make an email calendar for the next 30 days"
        },
        {
          value: "Make a series of emails for an upcoming sale"
        },
        {
          value: "I have a specific email I want to make"
        },

      ]
    });
    this.changeDate();
  }

}
