import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SharedService } from '../shared.service';
import { NgOptimizedImage } from '@angular/common'
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-content-templates-view',
  templateUrl: './content-templates-view.component.html',
  styleUrls: ['./content-templates-view.component.scss']
})
export class ContentTemplatesViewComponent {

  opportunities: any = [];
  loading = true;
  selectedObject: any = {};
  imagesLoaded = false;
  imageSearchTerm = "";
  searchLoading = false;

  currentPage = 1;

  txtQueryChanged: Subject<string> = new Subject<string>();

  uploading = false;
  base64textString = "";
  base64FileArray: any = [];

  totalItems: any = 0;

  selectedObjects: any = [];

  brandImages: any = [];

  mediaCategory: any = "BRAND";

  productStatus: any = "ACTIVE";

  productStock: any = "INSTOCK";

  searchMode = false;

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public sharedService: SharedService,
    public notification: NzNotificationService,
    private message: NzMessageService
  ) {

    this.txtQueryChanged
      .pipe(
        debounceTime(1000), // wait 1 sec after the last event before emitting last event
        distinctUntilChanged() // only emit if value is different from previous value
      )
      .subscribe(model => {
        this.imageSearchTerm = model;

        // Call your function which calls API or do anything you would like do after a lag of 1 sec
        console.log(this.imageSearchTerm);
        this.searchMedia();
      });
  }

  ngOnInit() {
    // this.apiService.getMedia().subscribe(
    //   (data: any) => {
    //     console.log(data);
    //     this.brandImages = data;

    //     this.apiService.getMediaPage(this.currentPage, this.mediaCategory).subscribe(
    //       (data: any) => {
    //         console.log(data);
    //         this.opportunities = data.images;
    //         this.totalItems = data.imagesCount;
    //         this.loading = false;

    //         this.opportunities.forEach((image: any, index: any) => {
    //           const img = new Image();
    //           img.onload = () => {
    //             this.opportunities[index].loadedThumbnail = true;
    //           };
    //           img.src = image.thumbnail;
    //         });
    //       },
    //       error => {
    //         console.log(error);
    //       });
    //   },
    //   error => {
    //     console.log(error);
    //   });

    this.apiService.getEmailTemplates().subscribe(
      (data: any) => {
        console.log(data);
        this.opportunities = data;
        this.loading = false;

        this.opportunities.forEach((image: any, index: any) => {
          const img = new Image();
          img.onload = () => {
            this.opportunities[index].loadedThumbnail = true;
          };
          img.src = image.thumbnail;
        });
      },
      error => {
        console.log(error);
      });
  }



  setSelectedObject(object: any) {
    if (this.sharedService.pickerNumber == "SINGLE") {
      this.selectedObjects = [object];
    } else {
      if (this.sharedService.getArrayItem(this.selectedObjects, "_id", object._id)) {

      } else {
        this.selectedObjects.push(object);
      }
    }
  }

  setPropertyValue() {
    if (this.sharedService.pickerMode == "PRODUCTS") {
      for (let object of this.selectedObjects) {
        object.buttonText = "Shop Now";
      }
    }
    this.sharedService.setPropertyValue(this.selectedObjects);
    this.sharedService.pickerOpen = false;
  }

  close() {
    this.sharedService.pickerOpen = false;
  }

  pageIndexChange(event: any) {
    console.log(event);
    this.currentPage = event;

    this.loading = true;
    this.apiService.getMediaPage(this.currentPage, this.mediaCategory).subscribe(
      (data: any) => {
        console.log(data);
        this.loading = false;
        this.opportunities = data.images;
        this.opportunities.forEach((image: any, index: any) => {
          const img = new Image();
          img.onload = () => {
            this.opportunities[index].loadedThumbnail = true;
          };
          img.src = image.thumbnail;
        });
      },
      error => {
        console.log(error);
      });



    const element: any = document.querySelector('#goUp');
    element.scrollIntoView();
  }

  allImagesLoaded() {
    console.log("All images loaded");
    this.loading = false;
  }

  onSearchChange(query: string) {
    this.txtQueryChanged.next(query);
  }

  searchMedia() {
    this.loading = true;
    this.opportunities = [];
    this.searchMode = true

    if (this.imageSearchTerm == "") {
      this.changeMediaCategory("ALL");
      this.searchMode = false;
    } else {
      this.mediaCategory = "ALL";
      this.apiService.searchMedia(this.imageSearchTerm).subscribe(
        (data: any) => {
          console.log(data);
          this.opportunities = data;
          this.loading = false;
          this.opportunities.forEach((image: any, index: any) => {
            const img = new Image();
            img.onload = () => {
              this.opportunities[index].loadedThumbnail = true;
            };
            img.src = image.thumbnail;
          });
        },
        error => {
          console.log(error);
        });

    }

  }

  chooseFiles() {

  }

  async handleFileSelect(evt: any) {
    this.base64FileArray = [];
    var files = evt.target.files;
    var file = files[0];

    this.uploading = true;
    // this.notification.create(
    //   'success',
    //   'Your images are being uploaded!',
    //   ''
    // );

    if (files.length > 0) {
      for (let file of files) {
        var reader = await new FileReader();

        // reader.onload = this._handleReaderLoaded.bind(this);
        reader.onload = (readerEvt: any) => {
          var binaryString = readerEvt.target.result;
          let base64 = btoa(binaryString);
          let base64FileArray = [`data:image/png;base64, ${base64}`];
          console.log(readerEvt);
          this.uploadImage(base64FileArray, file.name);
          // console.log(btoa(binaryString));
        }

        await reader.readAsBinaryString(file);
      }
    }

  }

  // async _handleReaderLoaded(readerEvt: any) {
  //   var binaryString = readerEvt.target.result;
  //   let base64 = await btoa(binaryString);
  //   let base64FileArray = [`data:image/png;base64, ${base64}`];
  //   console.log(readerEvt);
  //   this.uploadImage(base64FileArray);
  //   // console.log(btoa(binaryString));
  // }

  uploadImage(base64FileArray: any, fileName: any) {
    console.log(base64FileArray);
    this.apiService.uploadImages(base64FileArray, fileName).subscribe(value => {
      this.loading = true;
      // this.apiService.getMedia().subscribe(
      //   (data: any) => {
      //     console.log(data);
      //     this.opportunities = data;
      //     this.loading = false;
      //   },
      //   error => {
      //     console.log(error);
      //   });
      this.currentPage = 1;
      this.apiService.getMediaPage(this.currentPage, this.mediaCategory).subscribe(
        (data: any) => {
          console.log(data);
          this.opportunities = data.images;
          this.totalItems = data.imagesCount;
          this.mediaCategory = "ALL";
          this.loading = false;
          this.uploading = false;
          this.opportunities.forEach((image: any, index: any) => {
            const img = new Image();
            img.onload = () => {
              this.opportunities[index].loadedThumbnail = true;
            };
            img.src = image.thumbnail;
          });
        },
        error => {
          console.log(error);
        });
    });
  }

  changeMediaCategory(category: any) {
    this.loading = true;
    this.mediaCategory = category;
    this.apiService.getMediaPage(this.currentPage, this.mediaCategory).subscribe(
      (data: any) => {
        console.log(data);
        this.opportunities = data.images;
        this.totalItems = data.imagesCount;
        this.loading = false;
        this.opportunities.forEach((image: any, index: any) => {
          const img = new Image();
          img.onload = () => {
            this.opportunities[index].loadedThumbnail = true;
          };
          img.src = image.thumbnail;
        });
      },
      error => {
        console.log(error);
      });
  }

  deleteFile(file: any) {
    this.apiService.deleteFile(file).subscribe(
      (data: any) => {
        console.log(data);
        this.opportunities = this.opportunities.filter((item: any) => item._id != file);

        // this.loading = true;
        // this.apiService.getMediaPage(this.currentPage, this.mediaCategory).subscribe(
        //   (data: any) => {
        //     console.log(data);
        //     this.opportunities = data.images;
        //     this.totalItems = data.imagesCount;
        //     this.loading = false;
        //     this.message.create("success", `Image deleted`);
        //   },
        //   error => {
        //     console.log(error);
        //   });
      },
      error => {
        console.log(error);
      });
  }

  exitSearch() {
    this.searchMode = false;
    this.imageSearchTerm = "";
    this.searchMedia();
  }

  createProduct() {
    this.apiService.createProduct().subscribe(
      (data: any) => {
        console.log(data);
        this.router.navigate([`/content/products/${data._id}`]);

      },
      error => {
        console.log(error);
      });
  }
}
