<div class="page-wrapper">
    <div class="header-wrapper">
        <nz-page-header class="site-page-header">
            <nz-page-header-title>Welcome 👋</nz-page-header-title>
            <nz-page-header-extra>

            </nz-page-header-extra>
        </nz-page-header>
    </div>
    <div class="content-wrapper">
        <div class="content-wrapper-inner">
            <h4 nz-typography style="margin-bottom: 24px;">Create something</h4>
            <div nz-row [nzGutter]="[16, 16]">

                <div nz-col class="gutter-row" [nzSpan]="8">
                    <div class="custom-card hover campaignEmail" style="margin-bottom: 0px;" routerLink="/create/email-template">
                        <div class="custom-card-body" style="padding: 32px 16px;">
                            <div class="email-type-wrapper">
                                <i class="lni lni-envelope"></i>
                            </div>
                            <div class="email-type-label">Campaign email</div>
                        </div>
                    </div>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="8">
                    <div class="custom-card hover flowEmail" style="margin-bottom: 0px;" routerLink="/create/flow-template">
                        <div class="custom-card-body" style="padding: 32px 16px;">
                            <div class="email-type-wrapper">
                                <i class="lni lni-envelope"></i>
                            </div>
                            <div class="email-type-label">Flow email</div>
                        </div>
                    </div>
                </div>
                <!-- <div nz-col class="gutter-row" [nzSpan]="8">
                    <div class="custom-card hover" style="margin-bottom: 0px;" routerLink="/create/email">
                        <div class="custom-card-body" style="padding: 32px 16px;">
                            <div class="email-type-wrapper">
                                <i class="lni lni-envelope"></i>
                            </div>
                            <div class="email-type-label">Email</div>
                        </div>
                    </div>
                </div> -->
                <!-- <div nz-col class="gutter-row" [nzSpan]="12">
                            <div class="custom-card hover" style="margin-bottom: 0px;"
                                routerLink="/create/email">
                                <div class="custom-card-body" style="padding: 40px 16px;">
                                    <div class="email-type-wrapper">
                                        <i class="lni lni-add-files"></i>
                                    </div>
                                    <div class="email-type-label">Single campaign</div>
                                    <div class="email-type-description">Instantly generate a single campaign</div>
                                </div>
                            </div>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="12">
                            <div class="custom-card hover" style="margin-bottom: 0px;"
                                routerLink="/assistant/calendar">
                                <div class="custom-card-body" style="padding: 29px 16px;">
                                    <div class="email-type-wrapper">
                                        <i class="lni lni-files"></i>
                                    </div>
                                    <div class="email-type-label">Multiple campaigns</div>
                                    <div class="email-type-description">Use the Ripple planning assistant to generate several campaigns</div>
                                </div>
                            </div>
                        </div> -->
            </div>

            <h4 nz-typography style="margin-bottom: 24px;margin-top: 56px;">Recent</h4>

            <ng-container *ngIf="this.toDoLoading">
                <div class="loading-wrapper">
                    <div class="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!this.toDoLoading">
                <div nz-row [nzGutter]="16">
                    <ng-container *ngFor="let opportunity of opportunities; index as index">

                        <div nz-col class="gutter-row" [nzSpan]="6">
                            <div class="custom-card hover" [routerLink]="['/opportunities',opportunity._id,'e']">
                                <div class="custom-card-header" style="padding: 0px;">
                                    <ng-container *ngIf="opportunity.type == 'SMS'">
                                        <div class="email-thumbnail"
                                            style="background: #f9f9f9; text-align: center; font-size: 48px; padding-top: 75px; color: #d9d9d9">
                                            <i class="lni lni-popup"></i>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="opportunity.type != 'SMS'">
                                        <ng-container *ngIf="!opportunity.thumbnail">
                                            <div class="email-thumbnail"
                                                style="background: #f9f9f9; text-align: center; font-size: 48px; padding-top: 75px; color: #d9d9d9">
                                                <i class="lni lni-image"></i>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="opportunity.thumbnail">
                                            <ng-container *ngIf="opportunity.loadedThumbnail">
                                                <!-- <img [src]="opportunity.thumbnail"> -->
                                                <div class="opportunity-thumbnail-display"
                                                    [style.background-image]="'url('+opportunity.thumbnail+')'">

                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="!opportunity.loadedThumbnail">
                                                <nz-skeleton-element nzType="image" [nzActive]="true"
                                                    class="preview"></nz-skeleton-element>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <div class="custom-card-body">
                                    <ng-container *ngIf="opportunity.stage == 'NEW'">
                                        <div class="email-title">{{opportunity.prompt}}
                                        </div>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="opportunity.stage == 'PENDING' || opportunity.stage == 'LIVE' || opportunity.stage == 'PLACEHOLDER'">
                                        <div class="email-title">{{opportunity.title}}
                                        </div>
                                    </ng-container>
                                    <div style="margin-top: 8px;">
                                        <ng-container
                                            *ngIf="opportunity.stage == 'LIVE' && sharedService.timeInFuture(opportunity.sendTime)">
                                            <nz-tag class="aqua" style="margin-right: 4px;">Scheduled</nz-tag>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="opportunity.stage == 'LIVE' && !sharedService.timeInFuture(opportunity.sendTime)">
                                            <nz-tag class="aqua" style="margin-right: 4px;">Sent</nz-tag>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="opportunity.stage == 'PENDING' || opportunity.stage == 'NEW'">
                                            <nz-tag style="margin-right: 4px;">Draft</nz-tag>
                                        </ng-container>
                                        <ng-container *ngIf="opportunity.stage == 'PLACEHOLDER'">
                                            <nz-tag style="margin-right: 4px;">To do</nz-tag>
                                        </ng-container>
                                        <ng-container *ngIf="opportunity.type == 'CAMPAIGN'">
                                            <nz-tag class="aqua">Email</nz-tag>
                                        </ng-container>
                                        <ng-container *ngIf="opportunity.type == 'SMS'">
                                            <nz-tag class="aqua">SMS</nz-tag>
                                        </ng-container>
                                        <ng-container *ngIf="opportunity.type == 'SOCIALPOST'">
                                            <nz-tag class="aqua">Social post</nz-tag>
                                        </ng-container>
                                        <div style="float: right; line-height: 26px;">
                                            {{opportunity.sendTime
                                            | date:'shortDate'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</div>