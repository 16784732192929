<div class="page-wrapper">
    <ng-container *ngIf="this.loading">
        <div class="loading-wrapper">
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!this.loading">

        <div class="header-wrapper">
            <nz-page-header [nzTitle]="this.flowObject.name" nzBackIcon (nzBack)="handleBackButton()">
                <nz-page-header-extra>
                    <button nz-button (click)="deleteFlow()"><span nz-icon nzType="delete"
                        nzTheme="outline"></span>&nbsp;&nbsp;Delete</button>
                    <!-- <nz-button-group>

                        <button nz-button nzType="primary"> <a
                                href="https://dashing-replace-7b7.notion.site/Flow-Email-Guide-24ceff1e1a3246e5a19a5c0fced7636b?pvs=4"
                                target="_blank"><span nz-icon nzType="upload"
                                    nzTheme="outline"></span>&nbsp;&nbsp;Publish</a></button>

                        <button nzType="primary" nz-button nz-dropdown [nzDropdownMenu]="menu2"
                            nzPlacement="bottomRight">
                            <span nz-icon nzType="ellipsis"></span>
                        </button>
                    </nz-button-group>
                    <nz-dropdown-menu #menu2="nzDropdownMenu">
                        <ul nz-menu>
                            <li nz-menu-item (click)="deleteFlow()"><span nz-icon nzType="delete"
                                    nzTheme="outline"></span>&nbsp;&nbsp;Delete</li>
                        </ul>
                    </nz-dropdown-menu> -->
                </nz-page-header-extra>
            </nz-page-header>
        </div>
        <div class="content-wrapper">

            <ng-container *ngIf="this.loading">
                <div class="loading-wrapper">
                    <div class="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>

            </ng-container>
            <ng-container *ngIf="!this.loading">
                <ng-container *ngIf="!flowObject.opportunities || flowObject.opportunities.length == 0">
                    <div style="width: 500px; padding-top: 40px; margin: auto;">
                        <div class="custom-card" style="text-align: center;">
                            <div class="custom-card-body">
                                <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="null"></nz-empty>
                                <div style="font-weight: 500; margin-bottom: 24px;">This flow doesn't have any emails...
                                    yet</div>
                                <button nz-button nzType="primary" nzBlock [routerLink]="'/create/flow'"><span nz-icon
                                        nzType="plus" nzTheme="outline"></span>Add email to flow</button>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="flowObject.opportunities && flowObject.opportunities.length > 0">
                    <div class="grid-outer">
                        <div class="grid-wrapper">

                            <div class="custom-alert aqua">
                                <span nz-icon nzType="info-circle" nzTheme="outline"></span>&nbsp;&nbsp;Learn how to
                                export
                                your
                                flows to your email service provider <a
                                    href="https://dashing-replace-7b7.notion.site/Flow-Email-Guide-24ceff1e1a3246e5a19a5c0fced7636b?pvs=4"
                                    target="_blank"><strong style="text-decoration: underline;">here</strong></a>
                            </div>
                            <div nz-row [nzGutter]="16" cdkDropList (cdkDropListDropped)="dropItem($event)">
                                <ng-container *ngFor="let opportunity of flowObject.opportunities; index as index">
                                    <ng-container
                                        *ngIf="opportunity && opportunity._id && index >= (this.currentPage - 1) * 50 && index < (this.currentPage * 50)">
                                        <div nz-col class="gutter-row" [nzSpan]="24">
                                            <div class="custom-card clickable"
                                                [routerLink]="['/opportunities',opportunity._id,'flow']" cdkDrag>
                                                <div class="custom-card-body">
                                                    <div nz-row [nzGutter]="16">
                                                        <div nz-col class="gutter-row" [nzSpan]="2"
                                                            style="line-height: 76px; text-align: center; cursor: grab;" cdkDragHandle>
                                                            <span nz-icon nzType="holder" nzTheme="outline"></span>

                                                        </div>
                                                        <div nz-col class="gutter-row" [nzSpan]="4">
                                                            <ng-container *ngIf="opportunity.thumbnail">
                                                                <div class="email-thumbnail"
                                                                    [ngStyle]="{'background-image': 'url(' + opportunity.thumbnail + ')'}">
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngIf="!opportunity.thumbnail">
                                                                <div class="email-thumbnail"
                                                                    style="background: #f9f9f9; text-align: center; font-size: 32px; padding-top: 18px; color: #d9d9d9">
                                                                    <i class="lni lni-image"></i>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                        <div nz-col class="gutter-row" [nzSpan]="18">
                                                            <ng-container *ngIf="opportunity.stage == 'NEW'">
                                                                <div class="email-title"><nz-spin
                                                                        style="display: inline; margin-right: 24px;"
                                                                        [nzIndicator]="indicatorTemplate"></nz-spin>Generating
                                                                </div>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="opportunity.stage == 'PENDING' || opportunity.stage == 'LIVE'">
                                                                <div class="email-title">{{opportunity.title}}
                                                                </div>
                                                            </ng-container>
                                                            <div style="margin-top: 8px;">
                                                                <ng-container *ngIf="opportunity.stage == 'LIVE'">
                                                                    <nz-tag class="aqua">Scheduled</nz-tag>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="opportunity.stage == 'PENDING' || opportunity.stage == 'NEW'">
                                                                    <nz-tag>Draft</nz-tag>
                                                                </ng-container>
                                                                <div style="float: right; line-height: 26px;">
                                                                    {{opportunity.sendTime
                                                                    | date:'shortDate'}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <button nz-button nzBlock [routerLink]="'/create/flow'"><span nz-icon nzType="plus"
                                    nzTheme="outline"></span>Add email to flow</button>
                        </div>
                    </div>
                    <nz-pagination [nzTotal]="opportunities.length" [nzPageSize]="50"
                        (nzPageIndexChange)="this.pageIndexChange($event)"
                        style="float: right; margin: 8px"></nz-pagination>
                </ng-container>
            </ng-container>




            <!-- <div class="column-right">
            <nz-page-header style="padding-bottom: 6px !important" class="site-page-header">
                <nz-page-header-title>Suggestions</nz-page-header-title>
                <nz-breadcrumb nz-page-header-breadcrumb>
                    <nz-breadcrumb-item>Opportunities</nz-breadcrumb-item>
                    <nz-breadcrumb-item>Pending</nz-breadcrumb-item>
                </nz-breadcrumb>
                <nz-page-header-extra>
                    <button nz-button nzType="link" [routerLink]="['/new']">See more</button>
                </nz-page-header-extra>
            </nz-page-header>
            <ng-container *ngIf="this.loadingNewOpps">
                <div class="custom-card">
                    <div class="custom-card-body mini">
                        <nz-skeleton [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                    </div>
                </div>
                <div class="custom-card">
                    <div class="custom-card-body mini">
                        <nz-skeleton [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                    </div>
                </div>
                <div class="custom-card">
                    <div class="custom-card-body mini">
                        <nz-skeleton [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngFor="let opp of this.newOpportunities">
                <div class="custom-card hover" [routerLink]="['/opportunities',opp._id]">
                    <div class="custom-card-body mini">
                        <div class="new-opp-title">{{opp.title}}</div>
                        <ng-container *ngIf="opp.template == 'FEATUREDPRODUCT'">
                            <nz-tag>Featured product</nz-tag>
                        </ng-container>
                        <ng-container *ngIf="opp.template == 'EDUCATIONAL'">
                            <nz-tag>Educational</nz-tag>
                        </ng-container>
                        <ng-container *ngIf="opp.template == 'FEATUREDCATEGORY'">
                            <nz-tag>Featured category</nz-tag>
                        </ng-container>
                        <div class="new-opp-body">{{opp.description}}</div>
                    </div>
                </div>
            </ng-container>
        </div> -->


        </div>
    </ng-container>
</div>

<ng-template #indicatorTemplate><span nz-icon style="height: 10px !important; width: 10px !important;"
        nzType="loading"></span></ng-template>