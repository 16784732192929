<div class="page-wrapper">
    <div class="left-column">
        <div class="custom-card">
            <div class="custom-card-body" style="padding: 20px 20px 16px;">
                <!-- <div class="custom-card mini" *ngIf="this.emailsOnCalendar">
                        <div class="custom-card-body" style="padding: 12px; line-height: 36px;">
                            Your emails are ready <button [nzLoading]="this.saving"
                                [disabled]="this.loadingChat || !this.emailsOnCalendar" (click)="saveCalendar()"
                                nz-button nzType="primary" style="float: right">Generate
                                emails</button>
                        </div>
                    </div> -->

                <textarea [(ngModel)]="prompt" placeholder="Enter a prompt..." [nzAutosize]="{ minRows: 1, maxRows: 15 }" nz-input
                    class="chatbot-input"></textarea>
                <div style="text-align: right">
                    <button (click)="this.sendPrompt(this.prompt)" nz-button nzType="primary"><span nz-icon nzType="send"
                            nzTheme="outline"></span>Generate</button>
                </div>
            </div>
        </div>
        <div class="custom-card">
            <div class="custom-card-body">
                <div style="font-weight: 600;"><span nz-icon nzType="bulb" nzTheme="outline"></span>&nbsp;&nbsp;Prompt tips for this template</div>
            </div>
        </div>
        <button nz-button (click)="this.duplicateOpportunity()" [nzLoading]="duplicating">Duplcate template</button>
    </div>
    <div class="right-column">
        <div class="content-outer">
            <div class="content-inner">
                <ng-container *ngIf="this.opportunityObject">
                    <img [src]="this.opportunityObject.thumbnail" />
                </ng-container>
            </div>
        </div>
    </div>
</div>