<div class="page-wrapper">
    <div class="content-wrapper">
        <nz-page-header class="site-page-header">
            <nz-page-header-title>Connections</nz-page-header-title>
            <nz-page-header-extra>
                <button nz-button routerLink="/new-connection">New Connection</button>
            </nz-page-header-extra>
        </nz-page-header>
        <div class="table-wrapper">
            <nz-table #basicTable [nzData]="listOfData" nzShowPagination="false">
                <thead>
                    <tr>
                        <th>Connector</th>
                        <th>Klaviyo account</th>
                        <th>Created</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of connectionList" >
                        <td>{{ data.name }}</td>
                        <td>{{ data.age }}</td>
                        <td>{{ data.address }}</td>
                        <td>
                            <button nz-button [routerLink]="['/connection',data._id]">Manage</button>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
    </div>
</div>