import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-connection-view',
  templateUrl: './connection-view.component.html',
  styleUrls: ['./connection-view.component.scss']
})
export class ConnectionViewComponent {
  connectionId: any = '';
  connectionObject: any = {};

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    this.connectionId = this.route.snapshot.paramMap.get("id");
    console.log(this.connectionId);
    this.apiService.getConnection(this.connectionId).subscribe(
      (data: any) => {
        console.log(data);
        
      },
      error => {
        console.log(error);
      });
  }
}
