import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from '../api.service';
import { SharedService } from '../shared.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import imglyRemoveBackground from "@imgly/background-removal"
import { fabric } from 'fabric';


@Component({
  selector: 'app-edit-image-background',
  templateUrl: './edit-image-background.component.html',
  styleUrls: ['./edit-image-background.component.scss']
})
export class EditImageBackgroundComponent {

  opportunities: any = [];
  loading = false;
  loadingNewEmail = false;
  emailType: any = {}

  prompt: any = "";

  options = true;

  email: any = {};

  emailTypes: any = [];

  promotion = false;
  promotionValue = "";

  sourceUrl = false;

  products = false;

  // emailTypes = [
  //   {
  //     name: "Featured product",
  //     value: "FEATUREDPRODUCT",
  //     properties: [
  //       {
  //         type: "PRODUCTLIST",
  //         name: "Product",
  //         value: []
  //       }
  //     ]
  //   },
  //   {
  //     name: "Featured category",
  //     value: "FEATUREDCATEGORY",
  //     properties: [
  //       {
  //         type: "INPUT",
  //         name: "Category",
  //         value: null
  //       }
  //     ]
  //   },
  //   {
  //     name: "Listicle",
  //     value: "LISTICLE",
  //     properties: [{
  //       type: "INPUT",
  //       name: "Source article URL",
  //       value: null
  //     }]
  //   }
  // ]

  emailObject: any = {
    sendTime: null,
    template: null,
    prompt: "",
    templateOptions: {
      properties: []
    },
    type: {
      properties: []
    },
    properties: [
      {
        type: "PRODUCTLIST",
        name: "Product",
        value: []
      }
    ],
    stage: "NEW"
  }

  PRODUCTFEATUREOPTIONS = [
    {
      type: "PRODUCTLIST",
      name: "Product",
      value: []
    }
  ];

  CATEGORYFEATUREOPTIONS = [
    {
      type: "INPUT",
      name: "Category",
      value: null
    }
  ];

  LISTICLEOPTIONS = [
    {
      type: "INPUT",
      name: "Source article URL",
      value: null
    }
  ];
  loadingChat = false;
  chatLog: any = [];
  imageChangedEvent: any = '';
  croppedImage: any = '';
  currentObj: any = {};
  removingBackground = true;
  removedBackground = "";
  backgroundOptions = [
    // {
    //   type: "COLOR",
    //   value: "#000000"
    // },
    {
      type: "IMAGE",
      value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-27a67ca7-0006-428f-9fb7-b59411e044cf.png"
    },
    {
      type: "IMAGE",
      value: "https://images.unsplash.com/photo-1579546929518-9e396f3cc809?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8fA%3D%3D"
    },
    {
      type: "IMAGE",
      value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-3a61f0e5-418c-47e3-a557-ce6175ac456a.png"
    },
    {
      type: "IMAGE",
      value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-fe528fbd-710c-4472-9f97-a51634cd056d.png"
    },
    {
      type: "IMAGE",
      value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-37e83279-49a6-4314-9465-ac1abf297cbb.png"
    },
    {
      type: "IMAGE",
      value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-77ad983c-536c-46ef-81c7-47861bdbc200.png"
    },
    {
      type: "IMAGE",
      value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-c43b761d-55c3-4ec4-ba78-c708837c998c.png"
    },
    {
      type: "IMAGE",
      value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-992d5549-cbdb-4d32-a5a7-5c8ed746ba31.png"
    },
    {
      type: "IMAGE",
      value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-ea646bc8-b8ed-4512-8124-b9ef2049be81.png"
    },
    {
      type: "IMAGE",
      value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-f545fdf8-54ce-4698-88a4-bbee1973c480.png"
    },
    {
      type: "IMAGE",
      value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-84c92afe-4218-4005-b864-60567d88e94c.png"
    },
    {
      type: "IMAGE",
      value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-b69ed14c-cfca-4ab2-99ef-44a740487bf2.png"
    },
    {
      type: "IMAGE",
      value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-94318d62-d933-4c0e-ad06-e2b174f9bf86.png"
    },
    {
      type: "IMAGE",
      value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-9f5371d4-7976-4347-870c-dd537ca34db3.png"
    },
    {
      type: "IMAGE",
      value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-9e5f5ce8-8e05-4317-a18c-c4f5ae6db326.png"
    },
    {
      type: "IMAGE",
      value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-8ccf0031-d56f-49e4-b7f1-8af0e9ef7f59.png"
    },
    {
      type: "IMAGE",
      value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-9c82891e-acbb-4dd4-848a-adfe2c5fcae6.png"
    },
    {
      type: "IMAGE",
      value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-c831deac-5f04-4243-b520-1e271c3f009d.png"
    },
    {
      type: "IMAGE",
      value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-6575cc04-fd9d-427e-940c-3fd370ae8157.png"
    },
    {
      type: "IMAGE",
      value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-17a5b500-302f-457d-b17a-14546ed3c775.png"
    },
    {
      type: "IMAGE",
      value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-b30b7cc5-1b31-4f65-810d-9b5c4c0b8636.png"
    },
    {
      type: "IMAGE",
      value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-b80697dc-0272-49e3-9743-5a45c4a969c6.png"
    },
    // {
    //   type: "IMAGE",
    //   value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-3c892923-0b1a-4ecf-9f17-0afa831234f6.png"
    // },
    // {
    //   type: "IMAGE",
    //   value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-0bae694a-6bd8-475f-9f10-625ec0feda20.png"
    // },
    // {
    //   type: "IMAGE",
    //   value: "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-d41d850b-bb1d-461f-a691-de561a919a5d.png"
    // }
  ]

  newImageBackground: any = {}

  canvas: any = {};

  mode: any = "COLORS";
  selectedBackgroundColor: any = "";

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public sharedService: SharedService,
    public notification: NzNotificationService,
    private message: NzMessageService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
  ) {

  }

  async ngOnInit() {
    // this.apiService.getSuggestionOpportunities().subscribe(
    //   (data: any) => {
    //     console.log(data);
    //     this.opportunities = data;
    //     this.loading = false;
    //   },
    //   error => {
    //     console.log(error);
    //   });

    // this.apiService.getEmailTypes().subscribe(
    //   (data: any) => {
    //     console.log(data);
    //     this.emailTypes = data;
    //     this.emailObject.templateOptions = this.emailTypes[0];
    //   },
    //   error => {
    //     console.log(error);
    //   });

    // let base64Data = await this.urlToBase64(this.sharedService.editImageCurrentImage.value);
    // console.log(base64Data);
    console.log("Edit Image Background 1");
    let blob = await imglyRemoveBackground(this.sharedService.editImageBackgroundCurrentImage.value);
    // The result is a blob encoded as PNG. It can be converted to an URL to be used as HTMLImage.src
    console.log("Edit Image Background 2");
    console.log(blob);
    const url = URL.createObjectURL(blob);
    this.removedBackground = url;
    console.log(this.removedBackground);
    console.log("Edit Image Background 3", url);
    this.removingBackground = false;

    const reader = new FileReader();
    let base64url: any = null;
    reader.onloadend = () => {
      // Save the base64 data URL to a variable
      base64url = reader.result;

      // Use the base64url variable as needed
      console.log(base64url); // For example, log it to the console
      // You can now use this variable wherever you need the base64 representation of your image

      setTimeout(() => {
        console.log('2 seconds have passed');

        const canvas: any = new fabric.Canvas('myCanvas');
        this.canvas = canvas;
        // canvas.setBackgroundImage('https://www.shutterstock.com/image-photo/blurred-bathroom-background-marble-counter-600nw-2273057931.jpg', canvas.renderAll.bind(canvas));
        // URL of the image you want to convert to Base64 and set as background
        // const imageUrl = 'https://www.shutterstock.com/image-photo/blurred-bathroom-background-marble-counter-600nw-2273057931.jpg';

        // // Fetch the image
        // fetch(imageUrl)
        //   .then(response => response.blob()) // Convert the response to a Blob
        //   .then(blob => {
        //     // Create a new FileReader object
        //     const reader = new FileReader();
        //     reader.readAsDataURL(blob); // Read the Blob as DataURL (Base64)
        //     reader.onloadend = function () {
        //       const base64data: any = reader.result; // This is the Base64 data URL

        //       // Now use this Base64 data URL to set the background image
        //       // canvas.setBackgroundImage(base64data, canvas.renderAll.bind(canvas), {
        //       //   // Options for the background image (optional)
        //       // });

        //       fabric.Image.fromURL(base64data, function (img) {
        //         img.scaleToWidth(canvas.width);
        //         img.scaleToHeight(canvas.height);
        //         canvas.setBackgroundImage(img);
        //         canvas.requestRenderAll();
        //       });
        //     }
        //   })
        //   .catch(error => console.error('Error fetching or converting image:', error));
        // const text = new fabric.Textbox('Hello World', {
        //   width: 200,
        //   height: 100,
        //   fontSize: 24,
        //   cursorColor: 'blue',
        //   left: 50,
        //   top: 50
        // });

        // canvas.add(text);

        // const circle = new fabric.Circle({
        //   radius: 50,
        //   fill: 'red',
        //   left: 100,
        //   top: 100
        // });

        // canvas.add(circle);

        // var imgInstance = new fabric.Image(this.removedBackground, {
        //   left: 100,
        //   top: 100,
        //   angle: 30,
        //   opacity: 0.85
        // });
        // canvas.add(imgInstance);

        fabric.Image.fromURL(base64url, function (img) {
          // img.set({
          //   width: 300
          // });
          img.scaleToWidth(300);
          canvas.add(img);
          img.center();
          canvas.renderAll();
        }, {
          crossOrigin: 'anonymous'
        });

        this.cdr.detectChanges();
      }, 2000);
    };

    reader.readAsDataURL(blob);

  }

  newEmail() {
    this.loadingNewEmail = true;
    this.apiService.newEmail(this.prompt, this.sharedService.newDate).subscribe(
      (data: any) => {
        console.log(data);
        this.email = data;
        this.options = true;
        this.loadingNewEmail = false;
      }
    )
  }

  generateEmail() {
    this.emailObject.sendTime = this.sharedService.newDate;
    this.emailObject.template = this.emailObject.templateOptions.value;

    if (this.promotion) {
      this.emailObject.templateOptions.properties.push({
        type: "RICHTEXT",
        name: "Promotion",
        value: this.promotionValue
      });
    }

    this.apiService.saveOpportunity(this.emailObject).subscribe(
      (data: any) => {
        console.log(data);
        // this.apiService.generateTemplateContent(data._id).subscribe(
        this.apiService.generateTemplateContent2(data._id, "NEW", "").subscribe(
          (data2: any) => {
            console.log(data2);
            this.sharedService.fetchCalendar();
            this.sharedService.newOpen = false;
            // this.sharedService.checkForGeneratingEmails();
            this.message.create("success", `Your email is being generated`);

            // this.notification.create(
            //   'success',
            //   'Your email is being generated',
            //   ''
            // );
          },
          error => {
            console.log(error);
          });
      },
      error => {
        console.log(error);
      });
  }

  sendOpenAiChat() {
    this.loadingChat = true;
    this.apiService.sendOpenAiChat(this.emailObject.prompt).subscribe(
      (data2: any) => {
        console.log(data2);
        this.chatLog.push({ message: JSON.parse(data2.data[0].content[0].text.value).message, sender: "RIPPLE" });
        this.loadingChat = false;
      },
      error => {
        console.log(error);
      });
  }

  openPicker(property: any, mode: any) {
    console.log("Open Picker");
    this.sharedService.currentProperty = property;
    this.sharedService.pickerMode = mode;
    this.sharedService.pickerOpen = true;
  }

  removeItemFromArray(index: any, list: Array<any>) {
    list.splice(index, 1);
  }

  colorChosen(value: any) {
    console.log(value);

    // if (value.length == 7) {
    //   // Check if color already in palette 
    //   let index = this.opportunityObject.contentTemplate.colorPalette.findIndex((color: any) => color == value);
    //   console.log(index);

    //   if (index == -1) {
    //     this.opportunityObject.contentTemplate.colorPalette.push(value);
    //   }
    // }
  }

  generateSuggestion(suggestion: any) {
    this.prompt = suggestion.prompt;
    this.newEmail();
  }

  compareFn = (o1: any, o2: any): boolean => (o1 && o2 ? o1.value === o2.value : o1 === o2);

  close() {
    this.sharedService.editImageBackgroundOpen = false;
  }

  sendInvite() {

  }


  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    let objUrl: any = event.objectUrl;
    this.currentObj = event;
    console.log(this.currentObj);
    // this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(objUrl);
    this.croppedImage = event.base64;
    console.log(this.croppedImage);
    // event.blob can be used to upload the cropped image
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  uploadImage() {
    // console.log(base64FileArray);
    this.loading = true;
    let canvas = this.canvas;
    const dataURL = canvas.toDataURL({
      width: 400,
      height: 400,
      left: 0,
      top: 0,
      format: 'png',
      multiplier: 4
    });

    console.log(dataURL);
    this.apiService.uploadImages([dataURL], "Edited background").subscribe((value: any) => {
      console.log(value);
      this.sharedService.editImageBackgroundCurrentImage.value = value.file.url;
      this.sharedService.editImageBackgroundCurrentImage.imageUrl = value.file.url;
      this.sharedService.editImageBackgroundOpen = false;
      this.message.create("success", `Image updated`);
    });
  }

  // Function to fetch content from a URL and encode it to Base64
  async urlToBase64(url: any) {
    try {
      // Fetch the content of the URL
      const response = await fetch(url);
      const data = await response.blob();
      console.log(response);

      // Convert the fetched data to a Base64 string
      const reader = new FileReader();
      reader.readAsDataURL(data);
      return new Promise((resolve, reject) => {
        reader.onloadend = () => {
          let itemToSplit: any = reader.result;
          const base64Data = itemToSplit.split(',')[1];
          resolve(base64Data);
        };
        reader.onerror = reject;
      });
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  }

  changeBackground(option: any) {
    this.newImageBackground = option;
    let canvas = this.canvas;

    // Fetch the image
    fetch(option.value)
      .then(response => response.blob()) // Convert the response to a Blob
      .then(blob => {
        // Create a new FileReader object
        const reader = new FileReader();
        reader.readAsDataURL(blob); // Read the Blob as DataURL (Base64)
        reader.onloadend = function () {
          const base64data: any = reader.result; // This is the Base64 data URL

          // Now use this Base64 data URL to set the background image
          // canvas.setBackgroundImage(base64data, canvas.renderAll.bind(canvas), {
          //   // Options for the background image (optional)
          // });

          fabric.Image.fromURL(base64data, function (img) {
            img.scaleToWidth(canvas.width);
            img.scaleToHeight(canvas.height);
            canvas.setBackgroundImage(img);
            canvas.requestRenderAll();
          });
        }
      })
      .catch(error => console.error('Error fetching or converting image:', error));

    // fabric.Image.fromURL(option.value, function (img) {
    //   img.scaleToWidth(canvas.width);
    //   img.scaleToHeight(canvas.height);
    //   canvas.setBackgroundImage(img);
    //   canvas.requestRenderAll();
    // });
    // this.canvas.setBackgroundImage(option.value, this.canvas.renderAll.bind(this.canvas));
    // this.canvas.backgroundImage.scaleToWidth(400);
  }

  changeBackgroundColor() {
    this.canvas.setBackgroundImage(null, this.canvas.renderAll.bind(this.canvas));
    this.canvas.setBackgroundColor(this.selectedBackgroundColor, this.canvas.renderAll.bind(this.canvas));
  }
}
