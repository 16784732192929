import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-segments',
  templateUrl: './segments.component.html',
  styleUrls: ['./segments.component.scss']
})
export class SegmentsComponent {

  opportunities: any = [];
  loading = true;
  loadingNewOpps = true;
  newOpportunities: any = [];
  currentPage = 1;

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    this.apiService.getPendingOpportunities().subscribe(
      (data: any) => {
        console.log(data);
        this.opportunities = data;
        this.loading = false;
      },
      error => {
        console.log(error);
      });

    this.apiService.getNewOpportunities().subscribe(
      (data: any) => {
        console.log(data);
        this.newOpportunities = data;
        this.loadingNewOpps = false;
      },
      error => {
        console.log(error);
      });
  }

  pageIndexChange(event: any) {
    console.log(event);
    this.currentPage = event;

    const element: any = document.querySelector('#goUp');
    element.scrollIntoView();
  }
}
