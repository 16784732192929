<div class="page-wrapper">
    <img class="logo-signup" src="../../../assets/rippleLogo.png" />
    <div class="content-wrapper">
        <ng-container *ngIf="this.loading">
            <div class="loading-wrapper">
                <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!this.loading">
            <ng-container *ngIf="this.mode == 'NEWUSER'">
                <!-- <h2 nz-typography class="setup-title"></h2> -->
                <img [src]="this.inviteObject.workspace.brandStyles.logo"
                    style="width: 120px; margin: auto; margin-bottom: 16px;" />
                <div style="margin-bottom: 8px;">You're invited to <nz-tag class="aqua"
                        style="margin-right: 0px;">{{this.inviteObject.workspace.name}}</nz-tag> on Ripple</div>
                <div style="margin-bottom: 24px;">Create an account to accept</div>
                <form nz-form nzLayout="vertical">
                    <nz-form-item style="margin-bottom: 16px;">
                        <nz-form-label nzNoColon="true" nzSpan="8">Email</nz-form-label>
                        <nz-form-control nzSpan="24">
                            <input [ngModelOptions]="{standalone: true}" [(ngModel)]="this.inviteObject.email" nz-input
                                placeholder="ceo@abccorp.com" disabled />
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item style="margin-bottom: 32px;">
                        <nz-form-label nzNoColon="true" nzSpan="8">Password</nz-form-label>
                        <nz-form-control nzSpan="24">
                            <input [ngModelOptions]="{standalone: true}" [(ngModel)]="this.inviteObject.password"
                                nz-input placeholder="Super secret password" type="password" />
                        </nz-form-control>
                    </nz-form-item>
                </form>
                <!-- <button nz-button nzType="primary" (click)="completeSignup()" nzBlock>Continue</button> -->
                <button nz-button nzType="primary" (click)="acceptInviteNewUser()" nzBlock>Accept invite</button>
            </ng-container>
            <ng-container *ngIf="this.mode == 'EXISTINGUSER'">
                <img [src]="this.inviteObject.workspace.brandStyles.logo"
                    style="width: 120px; margin: auto; margin-bottom: 16px;" />
                <div style="margin-bottom: 40px;">You're invited to <nz-tag class="aqua"
                        style="margin-right: 0px;">{{this.inviteObject.workspace.name}}</nz-tag> on Ripple</div>
                <!-- <button nz-button nzType="primary" (click)="completeSignup()" nzBlock>Continue</button> -->
                <button nz-button nzType="primary" (click)="acceptInvite()" nzBlock>Accept invite</button>
            </ng-container>
            <ng-container *ngIf="this.mode == 'WRONGEMAIL'">
                <i class="lni lni-unlink" style="font-size: 40px; margin-bottom: 32px;"></i>
                <div style="margin-bottom: 24px;">Log in to accept this invite</div>
                <button nz-button nzType="primary" nzBlock>Log in</button>
            </ng-container>

        </ng-container>
    </div>
</div>