<!-- <ng-container *ngIf="this.sharedService.pickerMode == 'MEDIA'">
        <nz-input-group [nzSuffix]="suffixIconSearch"
            style="border-radius: 5px; margin-bottom: 16px; width: 300px; padding-top: 0px; padding-bottom: 0px;">
            <input #input type="text" nz-input placeholder="Search" [(ngModel)]="imageSearchTerm"
                (ngModelChange)="onSearchChange($event)" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
            <span nz-icon nzType="search"></span>
        </ng-template>
    </ng-container> -->
<div class="page-wrapper">
    <div class="header-wrapper">
        <nz-page-header nzTitle="Products">
            <nz-page-header-extra>

                <button nz-button nzType="primary" style="float: right;" (click)="createProduct()"><span nz-icon
                        nzType="plus" nzTheme="outline"></span>Add
                    product</button>

                <nz-input-group [nzSuffix]="suffixIconSearch"
                    style="border-radius: 7px; margin-right: 8px; float: right; padding-top: 0px; padding-bottom: 0px; width: 300px;">
                    <input #input type="text" nz-input placeholder="Search" [(ngModel)]="imageSearchTerm"
                        (ngModelChange)="onSearchChange($event)" />
                </nz-input-group>
                <ng-template #suffixIconSearch>
                    <ng-container *ngIf="imageSearchTerm">
                        <span nz-icon class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle"
                            (click)="exitSearch()"></span>
                    </ng-container>
                    <ng-container *ngIf="!imageSearchTerm">
                        <span nz-icon nzType="search"></span>
                    </ng-container>
                </ng-template>
                <!-- <button nz-button routerLink="/assistant/calendar"><span nz-icon nzType="robot" nzTheme="outline"></span>Planning assistant</button>&nbsp; -->

            </nz-page-header-extra>
        </nz-page-header>

    </div>
    <div class="content-wrapper-outer">
        <div class="content-wrapper">

            <!-- <nz-select nzPlaceHolder="Status" style="width: 120px" [(ngModel)]="productStatus">
            <nz-option nzLabel="All" nzValue="ALL"></nz-option>
            <nz-option nzLabel="Active" nzValue="ACTIVE"></nz-option>
        </nz-select> -->
            <ng-container *ngIf="this.loading">
                <div nz-row [nzGutter]="[12, 12]">
                    <!-- <div nz-col class="gutter-row" [nzSpan]="4">
                <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
            </div> -->
                    <div nz-col class="gutter-row" [nzSpan]="4">
                        <div class="custom-card">
                            <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                            <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                                <div class="picker-item-title"></div>
                            </div>
                        </div>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="4">
                        <div class="custom-card">
                            <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                            <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                                <div class="picker-item-title"></div>
                            </div>
                        </div>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="4">
                        <div class="custom-card">
                            <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                            <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                                <div class="picker-item-title"></div>
                            </div>
                        </div>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="4">
                        <div class="custom-card">
                            <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                            <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                                <div class="picker-item-title"></div>
                            </div>
                        </div>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="4">
                        <div class="custom-card">
                            <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                            <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                                <div class="picker-item-title"></div>
                            </div>
                        </div>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="4">
                        <div class="custom-card">
                            <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                            <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                                <div class="picker-item-title"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-container>

            <div id="goUp"></div>
            <ng-container *ngIf="!this.loading">
                <div class="visible">


                    <div nz-row [nzGutter]="[12, 12]">
                        <ng-container *ngFor="let opportunity of opportunities; index as index">
                            <!-- <ng-container *ngIf="index >= (this.currentPage - 1) * 50 && index < (this.currentPage * 50)"> -->
                            <div nz-col class="gutter-row" [nzSpan]="4">
                                <div class="custom-card hover" [routerLink]="['/content/products',opportunity._id]">
                                    <!-- <img class="picker-item-image" src="{{opportunity.thumbnail}}" /> -->
                                    <ng-container *ngIf="opportunity.loadedThumbnail">
                                        <img class="picker-item-image" [src]="opportunity.thumbnail">
                                    </ng-container>
                                    <ng-container *ngIf="!opportunity.loadedThumbnail">
                                        <nz-skeleton-element nzType="image" [nzActive]="true"
                                            class="image-preview"></nz-skeleton-element>
                                    </ng-container>
                                    <div class="custom-card-footer mini" style="padding: 8px; text-align: left;">
                                        <div class="picker-item-title">{{opportunity.title}}</div>
                                    </div>
                                </div>
                            </div>
                            <!-- </ng-container> -->
                        </ng-container>
                    </div>
                    <!-- <nz-select nzPlaceHolder="Stock" style="width: 120px" [(ngModel)]="productStock">
                    <nz-option nzLabel="All" nzValue="ALL"></nz-option>
                    <nz-option nzLabel="In stock" nzValue="INSTOCK"></nz-option>
                </nz-select> -->
                </div>
            </ng-container>
        </div>
    </div>
    <div class="footer-wrapper">
        <ng-container
            *ngIf="this.sharedService.pickerMode == 'PRODUCTS' || (this.sharedService.pickerMode == 'MEDIA' && this.mediaCategory != 'BRAND') || !this.imageSearchTerm">
            <nz-pagination [nzTotal]="totalItems" [nzPageSize]="50" (nzPageIndexChange)="this.pageIndexChange($event)"></nz-pagination>
        </ng-container>
    </div>
</div>