import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-block-library',
  templateUrl: './block-library.component.html',
  styleUrls: ['./block-library.component.scss']
})
export class BlockLibraryComponent {

  opportunities: any = [];
  loading = true;
  selectedObject: any = {};
  selectedBlock: any = {};
  selectedVariant: any = {};

  blockList: any = [];

  blockListOld = [
    {
      title: "Hero",
      type: "HERO",
      properties: [
        {
          name: "Title",
          type: "INPUT",
          value: "Title",
          category: "CONTENT"
        },
        {
          name: "Subtitle",
          type: "INPUT",
          value: "Subtitle",
          category: "CONTENT"
        },
        {
          name: "Body",
          type: "INPUT",
          value: "Body",
          category: "CONTENT"
        },
        {
          name: "Button",
          type: "INPUT",
          value: "Click here",
          category: "CONTENT"
        },
        {
          name: "Background image",
          type: "IMAGESELECT",
          value: "",
          category: "CONTENT"
        },
        {
          name: "Button color",
          type: "COLOR",
          category: "STYLES",
          value: this.sharedService.currentTemplate.colorPalette[0],
        },
        {
          name: "Background color",
          type: "COLOR",
          category: "STYLES",
          value: "#FFFFFF",
        },
        {
          name: "Button text color",
          type: "COLOR",
          value: "#FFFFFF",
          category: "STYLES"
        },
        {
          name: "Border radius",
          type: "INPUT",
          category: "STYLES",
          value: this.sharedService.currentTemplate.brandStyles.borderRadius,
        },
        {
          name: "Layout",
          type: "SELECT",
          category: "LAYOUT",
          value: {
            title: "Standard",
            value: "STANDARD",
            image: "standard.png"
          },
          options: [
            {
              title: "Standard",
              value: "STANDARD",
              image: "standard.png"
            },
            {
              title: "Separate image",
              value: "SEPARATEIMAGE",
              image: "separateImage.png"
            },
            {
              title: "Contained image",
              value: "CONTAINEDIMAGEROUNDED",
              image: "containedImageRounded.png"
            },
            {
              title: "Middle image",
              value: "MIDDLEIMAGE",
              image: "middleImage.png"
            },
            {
              title: "Square contained image",
              value: "CONTAINEDIMAGESQUARE",
              image: "containedImageSquare.png"
            },
            {
              title: "Circle contained image",
              value: "CONTAINEDIMAGECIRCLE",
              image: "containedImageCircle.png"
            },
          ]
        },
        {
          name: "Text color",
          type: "INPUT",
          value: "#000",
        },
        {
          name: "Link",
          type: "INPUT",
          category: "CONTENT",
          value: "",
        },
      ]
    },
    // {
    //   title: "Title",
    //   type: "TITLE",
    //   properties: [
    //     {
    //       name: "Title",
    //       type: "INPUT",
    //       category: "CONTENT",
    //       value: "Don't let your cart slip away",
    //     },
    //     {
    //       name: "Subtitle",
    //       type: "INPUT",
    //       category: "CONTENT",
    //       value: "Looks like you left some items in your cart",
    //     },
    //     {
    //       name: "Background color",
    //       type: "COLOR",
    //       category: "STYLES",
    //       value: "#FFF",
    //     },
    //     {
    //       name: "Layout",
    //       type: "SELECT",
    //       category: "LAYOUT",
    //       value: {
    //         title: "Standard",
    //         value: "STANDARD"
    //       },
    //       options: [
    //         {
    //           title: "Standard",
    //           value: "STANDARD"
    //         },
    //       ]
    //     }
    //   ]
    // },
    {
      title: "Button",
      type: "BUTTON",
      properties: [
        {
          name: "Button text",
          type: "INPUT",
          category: "CONTENT",
          value: "Click here",
        },
        {
          name: "Background color",
          type: "COLOR",
          category: "STYLES",
          value: "#FFF",
        },
        {
          name: "Link",
          type: "INPUT",
          category: "CONTENT",
          value: "",
        },
        {
          name: "Image",
          type: "IMAGESELECT",
          category: "CONTENT",
          value: ""
        },
        {
          name: "Text color",
          type: "COLOR",
          category: "STYLES",
          value: "#000",
        },
        {
          name: "Button text color",
          type: "COLOR",
          category: "STYLES",
          value: "#FFF",
        },
        {
          name: "Button color",
          type: "COLOR",
          category: "STYLES",
          value: this.sharedService.currentTemplate.colorPalette[0],
        },
        {
          name: "Border radius",
          type: "RADIO",
          category: "STYLES",
          value: this.sharedService.workspace.brandStyles.borderRadius,
        },
        {
          name: "Layout",
          type: "SELECT",
          category: "LAYOUT",
          value: {
            title: "Standard",
            value: "STANDARD"
          },
          options: [
            {
              title: "Standard",
              value: "STANDARD",
              image: "productXl.png"
            },
            {
              title: "Over image",
              value: "OVERIMAGE",
              image: "productXl.png"
            }
          ]
        }
      ]
    },
    {
      title: "Product",
      type: "PRODUCT",
      properties: [
        {
          name: "Title",
          type: "RICHTEXT",
          category: "CONTENT",
          value: ""
        },
        {
          name: "Button text",
          type: "INPUT",
          category: "CONTENT",
          value: "Shop now",
        },
        {
          name: "Background color",
          type: "COLOR",
          category: "STYLES",
          value: "",
        },
        {
          name: "Text color",
          type: "COLOR",
          category: "STYLES",
          value: "",
        },
        {
          name: "Button color",
          type: "COLOR",
          category: "STYLES",
          value: this.sharedService.currentTemplate.colorPalette[0],
        },
        {
          name: "Button text color",
          type: "COLOR",
          category: "STYLES",
          value: "",
        },
        {
          name: "Layout",
          type: "SELECT",
          category: "LAYOUT",
          value: "",
          options: [
            {
              title: "Standard",
              value: "STANDARD",
              image: "productStandard.png"
            },
            {
              title: "Standard large",
              value: "STANDARDLARGE",
              image: "productLarge.png"
            },
            {
              title: "Standard XL",
              value: "STANDARDXL",
              image: "productXl.png"
            },
            {
              title: "Horizontal",
              value: "HORIZONTAL",
              image: "productXl.png"
            },
            {
              title: "Horizontal alternating",
              value: "HORIZONTALALTERNATING",
              image: "productXl.png"
            },
            {
              title: "Horizontal block",
              value: "HORIZONTALBLOCK",
              image: "productXl.png"
            },
            {
              title: "Horizontal block alternating",
              value: "HORIZONTALBLOCKALTERNATING",
              image: "productXl.png"
            },
            {
              title: "Standard XL 2 images",
              value: "STANDARDXL2IMAGES",
              image: "productXl.png"
            }
          ]
        },
        {
          name: "Border radius",
          type: "RADIO",
          category: "STYLES",
          value: "",
        },
        {
          type: "PRODUCTLIST",
          name: "Products",
          category: "CONTENT",
          value: []
        }
      ]
    },
    {
      title: "Image",
      type: "IMAGE",
      properties: [
        {
          name: "Image",
          type: "IMAGESELECT",
          category: "CONTENT",
          value: ""
        },
        {
          name: "Background color",
          type: "COLOR",
          category: "STYLES",
          value: "#FFF",
        },
        {
          name: "Layout",
          type: "SELECT",
          category: "LAYOUT",
          value: {
            title: "Full width",
            value: "FULLWIDTH"
          },
          options: [
            {
              title: "Standard",
              value: "STANDARD"
            },
            {
              title: "Full width",
              value: "FULLWIDTH"
            }
          ]
        },
      ]
    },
    {
      title: "Blurb",
      type: "BLURB",
      properties: [
        {
          name: "Text",
          type: "RICHTEXT",
          category: "CONTENT",
          value: "Text here",
        },
        // {
        //   name: "Title",
        //   type: "INPUT",
        //   category: "CONTENT",
        //   value: "Title",
        // },
        // {
        //   name: "Subtitle",
        //   type: "INPUT",
        //   category: "CONTENT",
        //   value: "Subtitle",
        // },
        // {
        //   name: "Image",
        //   type: "INPUT",
        //   category: "CONTENT",
        //   value: "",
        // },
        {
          name: "Text color",
          type: "COLOR",
          category: "STYLES",
          value: "#767676",
        },
        {
          name: "Background color",
          type: "COLOR",
          category: "STYLES",
          value: "#FFFFFF",
        },
        // {
        //   name: "Button",
        //   type: "INPUT",
        //   category: "CONTENT",
        //   value: "",
        // },
        {
          name: "Layout",
          type: "SELECT",
          category: "LAYOUT",
          value: {
            title: "Standard",
            value: "STANDARD"
          },
          options: [
            {
              title: "Standard",
              value: "STANDARD"
            },
          ]
        },
      ]
    },
    {
      title: "Footer",
      type: "FOOTER",
      properties:
        [
          {
            name: "Logo",
            type: "IMAGESELECT",
            category: "CONTENT",
            value: ""
          },
          {
            name: "Background color",
            type: "COLOR",
            category: "STYLES",
            value: "",
          },
          {
            name: "Disclaimer",
            type: "RICHTEXT",
            category: "CONTENT",
            value: this.sharedService.workspace.brandStyles.footerDisclaimer,
          },
          {
            name: "Unsubscribe text",
            type: "RICHTEXT",
            category: "CONTENT",
            value: this.sharedService.workspace.brandStyles.footerUnsubscribeText,
          },
          {
            name: "Disclaimer text color",
            type: "COLOR",
            category: "STYLES",
            value: "#767676",
          },
          {
            name: "Social icon color",
            type: "COLOR",
            category: "STYLES",
            value: "",
          },
          {
            name: "Layout",
            type: "SELECT",
            category: "LAYOUT",
            value: {
              title: "Standard",
              value: "STANDARD"
            },
            options: [
              {
                title: "Standard",
                value: "STANDARD"
              }
            ]
          },
          {
            name: "Social links",
            type: "DATALIST",
            category: "CONTENT",
            value: this.sharedService.workspace.brandStyles.footerLinks.value,
            dataTemplate: [
              {
                name: "Platform",
                type: "SELECT",
                category: "CONTENT",
                value: {
                  title: "Instagram",
                  value: "instagram"
                },
                options: [
                  {
                    title: "Instagram",
                    value: "instagram"
                  },
                  {
                    title: "Facebook",
                    value: "facebook"
                  },
                  {
                    title: "Twitter",
                    value: "twitter"
                  }
                ]
              },
              {
                name: "Link",
                type: "INPUT",
                category: "CONTENT",
                value: "",
              },
            ]
          },
        ]
    },
    {
      title: "Navigation",
      type: "NAVBAR",
      properties: [
        {
          name: "Background color",
          type: "COLOR",
          category: "STYLES",
          value: "#FFF",
        },
        {
          name: "Text color",
          type: "COLOR",
          category: "STYLES",
          value: "#000",
        },
        {
          name: "Layout",
          type: "SELECT",
          category: "LAYOUT",
          value: {
            title: "Standard",
            value: "STANDARD",
            image: "navigationStandard.png"
          },
          options: [
            {
              title: "Standard",
              value: "STANDARD",
              image: "navigationStandard.png"
            },
            {
              title: "Vertical",
              value: "VERTICAL",
              image: "vertical.png"
            },
            {
              title: "Grid",
              value: "GRID",
              image: "grid.png"
            }
          ]
        },
        {
          name: "Items",
          type: "DATALIST",
          category: "CONTENT",
          value: this.sharedService.workspace.brandStyles.navbar.value,
          dataTemplate: [
            {
              name: "Title",
              type: "INPUT",
              category: "CONTENT",
              value: "",
            },
            {
              name: "Link",
              type: "INPUT",
              category: "CONTENT",
              value: "",
            },
          ]
        }
      ]
    },
    {
      title: "Logo",
      type: "LOGO",
      properties: [
        {
          name: "Image",
          type: "IMAGESELECT",
          category: "CONTENT",
          value: this.sharedService.currentTemplate.brandStyles.logo,
        },
        {
          name: "Background color",
          type: "COLOR",
          category: "STYLES",
          value: "#FFF",
        },
        {
          name: "Layout",
          type: "SELECT",
          category: "LAYOUT",
          value: {
            title: "Standard",
            value: "STANDARD"
          },
          options: [
            {
              title: "Standard",
              value: "STANDARD"
            },
          ]
        },
      ]
    },
    {
      title: "Review",
      type: "REVIEW",
      properties: [
        {
          name: "Title",
          type: "RICHTEXT",
          category: "CONTENT",
          value: "",
        },
        {
          name: "Quote",
          type: "RICHTEXT",
          category: "CONTENT",
          value: "",
        },
        {
          name: "Name",
          type: "INPUT",
          category: "CONTENT",
          value: "",
        },
        {
          name: "Background color",
          type: "COLOR",
          category: "STYLES",
          value: "#FFF",
        },
        {
          name: "Title color",
          type: "COLOR",
          category: "STYLES",
          value: "#000",
        },
        {
          name: "Text color",
          type: "COLOR",
          category: "STYLES",
          value: "#000",
        },
        {
          name: "Name color",
          type: "COLOR",
          category: "STYLES",
          value: "#000",
        },
        {
          name: "Decoration color",
          type: "COLOR",
          category: "STYLES",
          value: "#D4AF37",
        },
        {
          name: "Layout",
          type: "SELECT",
          category: "LAYOUT",
          value: {
            title: "Big quote",
            value: "BIG"
          },
          options: [
            {
              title: "Standard",
              value: "STANDARD"
            },
            {
              title: "Bubble",
              value: "BUBBLE"
            },
            {
              title: "Big quote",
              value: "BIG"
            },
          ]
        },
      ]
    },
    {
      title: "Article",
      type: "ARTICLE",
      properties: [
        {
          name: "Title",
          type: "RICHTEXT",
          value: "",
          category: "CONTENT"
        },
        {
          name: "Subtitle",
          type: "RICHTEXT",
          value: "",
          category: "CONTENT"
        },
        {
          name: "Body",
          type: "RICHTEXT",
          value: "",
          category: "CONTENT"
        },
        {
          name: "Button text",
          type: "INPUT",
          value: "",
          category: "CONTENT"
        },
        {
          name: "Link",
          type: "INPUT",
          value: "",
          category: "CONTENT"
        },
        {
          name: "Image",
          type: "IMAGESELECT",
          value: "",
          category: "CONTENT"
        },
        {
          name: "Button color",
          type: "COLOR",
          value: "#000",
          category: "STYLES"
        },
        {
          name: "Button text color",
          type: "COLOR",
          value: "#000",
          category: "STYLES"
        },
        {
          name: "Background color",
          type: "COLOR",
          value: "#FFF",
          category: "STYLES"
        },
        {
          name: "Text color",
          type: "COLOR",
          value: "#FFF",
          category: "STYLES"
        },
        {
          name: "Border radius",
          type: "RADIO",
          value: "5px",
          category: "STYLES"
        },
        {
          name: "Layout",
          type: "SELECT",
          category: "LAYOUT",
          value: {
            title: "Standard",
            value: "STANDARD"
          },
          options: [
            {
              title: "Standard",
              value: "STANDARD"
            }
          ]
        },
      ]
    },
    {
      title: "Gallery",
      type: "GALLERY",
      properties: [
        {
          name: "Background color",
          type: "COLOR",
          category: "STYLES",
          value: "",
        },
        {
          name: "Text color",
          type: "COLOR",
          category: "STYLES",
          value: "",
        },
        {
          name: "Layout",
          type: "SELECT",
          category: "LAYOUT",
          value: {
            title: "Standard",
            value: "STANDARD",
            image: "navigationStandard.png"
          },
          options: [
            {
              title: "Standard",
              value: "STANDARD",
              image: "navigationStandard.png"
            },
            {
              title: "Standard reverse",
              value: "STANDARDREVERSE",
              image: "navigationStandard.png"
            },
            {
              title: "Five",
              value: "FIVE",
              image: "navigationStandard.png"
            },
          ]
        },
        {
          name: "Images",
          type: "DATALIST",
          category: "CONTENT",
          value: [
          ],
          dataTemplate: [
            {
              name: "Image",
              type: "IMAGESELECT",
              value: "",
            }
          ]
        },
      ]
    },
    {
      title: "Banner",
      type: "BANNER",
      properties: [
        {
          name: "Text",
          type: "RICHTEXT",
          category: "CONTENT",
          value: "",
        },
        {
          name: "Text color",
          type: "COLOR",
          category: "STYLES",
          value: "",
        },
        {
          name: "Background color",
          type: "COLOR",
          category: "STYLES",
          value: "",
        },
        {
          name: "Layout",
          type: "SELECT",
          category: "LAYOUT",
          value: {
            title: "Standard",
            value: "STANDARD"
          },
          options: [
            {
              title: "Standard",
              value: "STANDARD"
            },
          ]
        },
      ]
    },
    {
      title: "Listicle",
      type: "LISTICLE",
      properties: [
        {
          name: "Background color",
          type: "COLOR",
          category: "STYLES",
          value: "",
        },
        {
          name: "Card color",
          type: "COLOR",
          category: "STYLES",
          value: "",
        },
        {
          name: "Sticker color",
          type: "COLOR",
          category: "STYLES",
          value: "",
        },
        {
          name: "Text color",
          type: "COLOR",
          category: "STYLES",
          value: "",
        },
        {
          name: "Title text color",
          type: "COLOR",
          category: "STYLES",
          value: "",
        },
        {
          name: "Body text color",
          type: "COLOR",
          category: "STYLES",
          value: "",
        },
        {
          name: "Layout",
          type: "SELECT",
          category: "LAYOUT",
          value: {
            title: "Standard",
            value: "STANDARD",
            image: ""
          },
          options: [
            {
              title: "Standard",
              value: "STANDARD",
              image: ""
            },
            {
              title: "Alernating",
              value: "ALTERNATING",
              image: ""
            }
          ]
        },
        {
          name: "Items",
          type: "DATALIST",
          category: "CONTENT",
          value: [
          ],
          dataTemplate: [
            {
              name: "Title",
              type: "RICHTEXT",
              category: "CONTENT",
              value: "",
            },
            {
              name: "Body",
              type: "RICHTEXT",
              category: "CONTENT",
              value: "",
            },
          ]
        },
      ]
    },
    {
      title: "Promotion",
      type: "PROMOTION",
      properties: [
        {
          name: "Title",
          type: "RICHTEXT",
          category: "CONTENT",
          value: "",
        },

        {
          name: "Body",
          type: "RICHTEXT",
          category: "CONTENT",
          value: "",
        },
        {
          name: "Promo code",
          type: "RICHTEXT",
          category: "CONTENT",
          value: "",
        },
        {
          name: "Text color",
          type: "COLOR",
          category: "STYLES",
          value: "",
        },
        {
          name: "Promo background color",
          type: "COLOR",
          category: "STYLES",
          value: "",
        },
        {
          name: "Promo text color",
          type: "COLOR",
          category: "STYLES",
          value: "",
        },
        {
          name: "Background color",
          type: "COLOR",
          category: "STYLES",
          value: "",
        },
        {
          name: "Layout",
          type: "SELECT",
          category: "LAYOUT",
          value: {
            title: "Standard",
            value: "STANDARD"
          },
          options: [
            {
              title: "Standard",
              value: "STANDARD"
            },
          ]
        }
      ]
    },
  ];
  mode: any = "DEFAULT";
  blockInstructions: any = "";
  generatingBlockContent: any = false;
  savedBlockList: any = [];

  blockType: any = "RIPPLE";

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public sharedService: SharedService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.apiService.getBlockTypes().subscribe(
      (data: any) => {
        console.log(data);
        this.blockList = data;
        this.loading = false;
      },
      error => {
        console.log(error);
      });

    this.apiService.getSavedBlocks().subscribe(
      (data: any) => {
        console.log(data);
        this.savedBlockList = data;
      },
      error => {
        console.log(error);
      });
  }

  setSelectedObject(object: any) {
    this.selectedObject = object;
  }

  setPropertyValue() {
    this.sharedService.setPropertyValue(this.selectedObject);
    this.sharedService.pickerOpen = false;
  }

  close() {
    this.sharedService.blockLibraryOpen = false;
  }

  selectBlock(block: any, variant: any) {
    console.log("selectBlock");
    this.selectedBlock = block;
    this.selectedVariant = variant;
  }

  addBlock() {

    if (this.sharedService.blockLibraryMode == "NEWBLOCK") {
      if (this.getProperty(this.selectedBlock, "Layout")) {
        this.getProperty(this.selectedBlock, "Layout").value = {
          title: "Standard",
          value: "STANDARD",
          image: ""
        };
      }

      // this.sharedService.currentTemplate.blocks.push(this.selectedBlock);
      if (this.sharedService.getArrayItem(this.selectedBlock.properties, "name", "Link")) {
        this.sharedService.getArrayItem(this.selectedBlock.properties, "name", "Link").value = `https://${this.sharedService.currentUser.workspace.domain}`;
      }
      this.sharedService.currentTemplate.blocks.splice(this.sharedService.blockIndex + 1, 0, this.selectedBlock);
      console.log(this.sharedService.currentTemplate);
      this.sharedService.blockLibraryOpen = false;
      this.cdr.detectChanges();
    }

    if (this.sharedService.blockLibraryMode == "CHANGEVARIANT") {
      this.sharedService.currentProperty.value = this.selectedVariant
      this.sharedService.blockLibraryOpen = false;
      this.cdr.detectChanges();
    }
  }

  addSavedBlock() {
    this.sharedService.currentTemplate.blocks.splice(this.sharedService.blockIndex + 1, 0, this.selectedBlock.contentTemplate.blocks[0]);
    console.log(this.sharedService.currentTemplate);
    this.sharedService.blockLibraryOpen = false;
    this.cdr.detectChanges();
  }

  getProperty(block: any, propertyName: any) {
    let property = block.properties.filter((obj: any) => {
      return obj.name === propertyName;
    });

    return property[0];
  }

  chooseBlockType() {
    this.mode = "FILLBLOCK";
  }

  fillWithAI() {
    this.generatingBlockContent = true;
    if (this.getProperty(this.selectedBlock, "Layout")) {
      this.getProperty(this.selectedBlock, "Layout").value = {
        title: "Standard",
        value: "STANDARD",
        image: ""
      };
    };

    let blockToSend = {
      type: this.selectedBlock.type,
      instructions: this.blockInstructions,
      backgroundColor: ""
    }

    this.apiService.generateTemplateContent2(this.sharedService.rendererOpportunityObject._id, "ONEBLOCK", "", blockToSend).subscribe((data4: any) => {
      console.log(data4);
      let block = data4.result;
      
      if (block.type == "PRODUCT") {
        if (this.getArrayItem(block.properties, "Products") && this.getArrayItem(block.properties, "Products").value) {
          for (let product of this.getArrayItem(block.properties, "Products").value) {
            if (!product.buttonText) {
              product.buttonText = "Shop now";
            }
          }
        }
      }

      if (block.type == "COLLECTION") {
        if (this.getArrayItem(block.properties, "Collections") && this.getArrayItem(block.properties, "Collections").value) {
          for (let product of this.getArrayItem(block.properties, "Collections").value) {
            if (!product.buttonText) {
              product.buttonText = "Shop now";
            }
          }
        }
      }

      this.sharedService.currentTemplate.blocks.splice(this.sharedService.blockIndex + 1, 0, data4.result);
      this.sharedService.blockLibraryOpen = false;
      this.cdr.detectChanges();
    });
  }

  getArrayItem(array: any, propertyName: any) {
    let property = array.filter((obj: any) => {
      return obj.name === propertyName;
    });

    if (property[0]) {
      return property[0];
    } else {
      return "";
    }
  }
}
