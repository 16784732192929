<div class="page-wrapper">
    <div class="header-wrapper">
        <nz-page-header nzBackIcon class="site-page-header">

            <!-- <nz-breadcrumb nz-page-header-breadcrumb>
                <nz-breadcrumb-item>Opportunities</nz-breadcrumb-item>
                <nz-breadcrumb-item>{{this.opportunityObject.title}}</nz-breadcrumb-item>
            </nz-breadcrumb> -->
            <nz-page-header-title>Create flow</nz-page-header-title>
            <nz-page-header-extra>
                <ng-container *ngIf="this.showRenderer">
                    <!-- <button nz-button><i class="lni lni-trash-can"
                            style="float: left; margin-top: 3px;"></i>&nbsp;&nbsp;Clear</button>&nbsp; -->
                    <nz-input-group nzCompact style="float: left; width: 180px;">
                        <button nz-button nzType="default" (click)="previousEmail()" [disabled]="!this.emailDone">
                            <span nz-icon nzType="left"></span>
                        </button>
                        <button nz-button [disabled]="!this.emailDone">Version {{this.currentEmail +
                            1}}/{{this.emailHistory.length}}</button>
                        <button nz-button nzType="default" (click)="nextEmail()" [disabled]="!this.emailDone">
                            <span nz-icon nzType="right"></span>
                        </button>
                    </nz-input-group>
                    <button (click)="saveEmail()" nz-button nzType="primary" [disabled]="!this.emailDone">Save &
                        continue</button>
                </ng-container>
            </nz-page-header-extra>
        </nz-page-header>
    </div>
    <div class="content-wrapper">
        <div class="left-column wide" *ngIf="!this.showRenderer && !this.creatingLayout && !this.showHeroOptions">
            <div class="upper-inner" style="padding-top: 48px;">
                <div>
                    <ng-container *ngIf="!generating">
                        <h2 nz-typography style="margin-bottom: 24px;">Choose a flow type</h2>
                    </ng-container>
                    <ng-container *ngIf="generating">
                        <h2 nz-typography style="margin-bottom: 24px;">Generating flow...</h2>
                    </ng-container>
                    <div class="custom-card">
                        <ng-container *ngIf="!generating">
                            <ng-container *ngIf="!emailOptions">
                                <div class="custom-card-body" style="padding: 16px;">
                                    <div nz-row [nzGutter]="16">
                                        <ng-container *ngFor="let emailType of flowTypes">
                                            <div nz-col class="gutter-row" [nzSpan]="6">
                                                <div class="custom-card hover" (click)="chooseEmailType(emailType)">
                                                    <div class="custom-card-body" style="padding: 16px;">
                                                        <div class="email-type-wrapper">
                                                            <i class="{{emailType.icon}}"></i>
                                                        </div>
                                                        <div class="email-type-label">{{emailType.name}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <nz-divider nzText="or"></nz-divider>
                                    <button (click)="this.chooseEmailType(this.customPromptEmailType)" nz-button
                                        nzBlock><span nz-icon nzType="edit" nzTheme="outline"></span>Write a custom
                                        prompt</button>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="emailOptions">
                                <div class="custom-card-header" style="padding: 16px;">
                                    <button (click)="this.emailOptions = false" nz-button><span nz-icon
                                            nzType="arrow-left" nzTheme="outline"></span>Back</button>
                                </div>
                                <div class="custom-card-body" style="padding: 16px;">
                                    <ng-container *ngFor="let property of currentEmailType.properties">
                                        <ng-container *ngIf="property.type == 'INPUT'">
                                            <nz-form-item>
                                                <nz-form-label [nzNoColon]="true">{{ property.name }}</nz-form-label>
                                                <nz-form-control [nzSpan]="24">
                                                    <input nz-input [(ngModel)]="property.value" />
                                                </nz-form-control>
                                            </nz-form-item>
                                        </ng-container>
                                        <ng-container *ngIf="property.type == 'RICHTEXT'">
                                            <nz-form-item>
                                                <nz-form-label [nzNoColon]="true">{{ property.name }}</nz-form-label>
                                                <nz-form-control [nzSpan]="24">
                                                    <textarea [nzAutosize]="{ minRows: 2, maxRows: 15 }" nz-input
                                                        [(ngModel)]="property.value"></textarea>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </ng-container>
                                        <ng-container *ngIf="property.type == 'PRODUCTLIST'">
                                            <nz-form-item>
                                                <nz-form-label [nzNoColon]="true">{{ property.name }}</nz-form-label>
                                                <nz-form-control [nzSpan]="24">
                                                    <div nz-row [nzGutter]="8">
                                                        <ng-container
                                                            *ngFor="let item of property.value; let itemIndex=index">

                                                            <div nz-col nzSpan="6">
                                                                <div class="custom-card mini">
                                                                    <div class="custom-circle-button delete-item"
                                                                        style="color: red;"
                                                                        (click)="this.removeItemFromArray(itemIndex, property.value)">
                                                                        <span nz-icon nzType="delete"
                                                                            nzTheme="outline"></span>
                                                                    </div>
                                                                    <img class="picker-item-image"
                                                                        src="{{item.thumbnail}}" />
                                                                    <div class="custom-card-footer mini"
                                                                        style="padding: 8px; text-align: left; height: 39px">
                                                                        <div class="picker-item-title">{{item.title}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>

                                                        <div nz-col nzSpan="6">
                                                            <div class="list-add-item"
                                                                (click)="this.sharedService.openPicker(property, 'PRODUCTS', 'MULTI')"
                                                                style="padding: 95px 12px">
                                                                <span nz-icon nzType="plus"
                                                                    nzTheme="outline"></span>&nbsp;Add
                                                                product
                                                            </div>
                                                        </div>
                                                    </div>

                                                </nz-form-control>
                                            </nz-form-item>
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <div class="custom-card-footer">
                                    <button nz-button nzType="primary" (click)="generateFlow()"
                                        [nzLoading]="loadingNewEmail">Generate</button>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="generating">
                            <div class="custom-card-body" style="padding: 16px;">
                                <div class="loading-wrapper" style="text-align: center;">
                                    <img src="../../assets/magicWand.gif"
                                        style="width: 125px; height: 125px; margin: auto;" />
                                    <br>
                                    <br>
                                    <div class="pulsing-italic">{{this.loadingText}}</div>
                                    <br>
                                    <div *ngIf="delayMessage">Experiencing delay due to high volume, thanks for bearing
                                        with us
                                        :)
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="this.showRenderer || this.creatingLayout || this.showHeroOptions">
            <div class="left-column">
                <div class="chat-wrapper-2">

                    <ng-container *ngFor="let message of chatLog">
                        <ng-container *ngIf="message.sender == 'RIPPLE'">
                            <div class="message-bubble-ripple">
                                <div class="message-bubble-title">Ripple</div>

                                {{message.message}}
                            </div>
                        </ng-container>

                        <ng-container *ngIf="message.sender == 'USER'">
                            <div class="message-bubble-user-wrapper">

                                <div class="message-bubble-user">
                                    <div class="message-bubble-title">You</div>

                                    {{message.message}}
                                </div>
                            </div>

                        </ng-container>
                    </ng-container>
                    <div class="message-bubble-ripple" *ngIf="loadingChat">
                        <div class="typing">
                            <div class="dot"></div>
                            <div class="dot"></div>
                            <div class="dot"></div>
                        </div>
                    </div>
                </div>
                <div class="email-controls">
                    <div class="custom-card">
                        <div class="custom-card-body" style="padding: 16px;">

                            <textarea [nzAutosize]="{ minRows: 1, maxRows: 15 }" class="prompt-input scroll" nz-input
                                placeholder="Don't love your email? Give feedback and try again"
                                [(ngModel)]="this.prompt" style="width: 100%;"></textarea>
                        </div>
                        <div class="custom-card-footer" style="height: 68px">
                            <button nz-button nzType="primary" style="float: right" (click)="newEmail()"
                                [disabled]="!this.emailDone" [nzLoading]="loadingNewEmail">Send</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-column">

                <ng-container *ngIf="this.showHeroOptions">
                    <div nz-row [nzGutter]="16">
                        <ng-container *ngFor="let heroOption of this.heroOptions">
                            <div nz-col class="gutter-row" [nzSpan]="8">
                                <div class="custom-card">
                                    <div class="custom-card-body">
                                        <div class="email-thumbnail"
                                            [ngStyle]="{'background-image': 'url(' + heroOption.screenshot.url + ')'}">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngIf="this.showRenderer">
                    <app-email-renderer [mode]="'VIEW'"></app-email-renderer>
                </ng-container>

                <ng-container *ngIf="this.creatingLayout">
                    <div class="loading-wrapper" style="text-align: center;">
                        <img src="../../assets/magicWand.gif" style="width: 125px; height: 125px; margin: auto;" />
                        <br>
                        <br>
                        <div class="pulsing-italic">{{this.loadingText}}</div>
                        <br>
                        <div *ngIf="delayMessage">Experiencing delay due to high volume, thanks for bearing with us
                            :)
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>

    </div>
</div>