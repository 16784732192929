<div class="page-wrapper">
    <img class="logo-signup" src="../../../assets/rippleLogo.png" />
    <div class="content-wrapper">
        <h2 nz-typography class="setup-title">Create a workspace</h2>
        <form nz-form nzLayout="vertical">
            <nz-form-item style="margin-bottom: 16px;">
                <nz-form-label nzNoColon="true" nzSpan="8">Workspace name</nz-form-label>
                <nz-form-control nzSpan="24">
                    <input [ngModelOptions]="{standalone: true}" [(ngModel)]="this.workspaceObject.name" nz-input
                        placeholder="Abc corp." />
                </nz-form-control>
            </nz-form-item>
        </form>
        <!-- <button nz-button nzType="primary" (click)="completeSignup()" nzBlock>Continue</button> -->
        <button nz-button nzType="primary" (click)="createWorkspace()" nzBlock>Continue</button>

        
    </div>
</div>