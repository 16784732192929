<div class="custom-modal-header">
    <div class="custom-modal-title">New flow</div>
    <div class="custom-modal-close" (click)="close()"><span nz-icon nzType="close" nzTheme="outline"></span></div>
</div>
<div class="custom-modal-body" style="overflow:hidden;">
    <nz-form-control [nzSpan]="24">
        <input placeholder="Ex: Abandonded cart flow" nz-input [(ngModel)]="this.flowName"/>
    </nz-form-control>
</div>
<div class="custom-modal-footer">
    <button nz-button nzType="primary" (click)="createFlow()" [nzLoading]="this.saving">Create</button>
</div>