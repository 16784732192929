<div class="page-wrapper">

    <ng-container *ngIf="!options">

        <div class="upper-wrapper">
            <div class="upper-inner">
                <!-- Choose a suggestion below or enter a custo prompt -->
                <div class="custom-card">
                    <div class="custom-card-body" style="padding: 16px;">
                        <textarea [nzAutosize]="{ minRows: 1, maxRows: 15 }" class="prompt-input scroll" nz-input
                            placeholder="Tell us about your email, and be specific!"
                            [(ngModel)]="this.prompt"></textarea>
                        <button nz-button nzType="primary" style="float: right" (click)="newEmail()"
                            [nzLoading]="loadingNewEmail">Send</button>
                    </div>
                    <!-- <nz-date-picker [(ngModel)]="sharedService.newDate"></nz-date-picker>
                        <nz-collapse nzAccordion>
                            <nz-collapse-panel nzHeader="Options">
                                <nz-form-item>
                                    <nz-form-label [nzNoColon]="true">Email type</nz-form-label>
                                    <nz-form-control [nzSpan]="24">
                                        <nz-select nzPlaceHolder="Choose">
                                            <nz-option nzLabel="Featured product" nzValue="0"></nz-option>
                                            <nz-option nzLabel="Featured category" nzValue="0"></nz-option>
                                            <nz-option nzLabel="Listicle" nzValue="0"></nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                </nz-form-item>
                            </nz-collapse-panel>
                        </nz-collapse> -->
                </div>
            </div>
        </div>
        <div class="lower-wrapper">
            <div class="lower-inner">
                <nz-page-header style="padding-bottom: 6px !important; padding-top: 0px !important;"
                    class="site-page-header">
                    <nz-page-header-title>Suggestions</nz-page-header-title>
                    <!-- <nz-breadcrumb nz-page-header-breadcrumb>
                <nz-breadcrumb-item>Opportunities</nz-breadcrumb-item>
                <nz-breadcrumb-item>Pending</nz-breadcrumb-item>
            </nz-breadcrumb> -->
                </nz-page-header>
                <ng-container *ngFor="let opp of this.opportunities">
                    <div class="custom-card hover" (click)="generateSuggestion(opp)">
                        <div class="custom-card-body mini">
                            <div class="new-opp-title">
                                {{opp.title}}
                            </div>
                            <ng-container *ngIf="opp.template == 'FEATUREDPRODUCT'">
                                <nz-tag class="aqua">Featured product</nz-tag>
                            </ng-container>
                            <ng-container *ngIf="opp.template == 'EDUCATIONAL'">
                                <nz-tag class="aqua">Educational</nz-tag>
                            </ng-container>
                            <ng-container *ngIf="opp.template == 'FEATUREDCATEGORY'">
                                <nz-tag class="aqua">Featured category</nz-tag>
                            </ng-container>
                            <ng-container *ngIf="opp.template == 'REVIEWHIGHLIGHT'">
                                <nz-tag class="aqua">Review highlight</nz-tag>
                            </ng-container>
                            <div class="new-opp-body">{{opp.description}}</div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="options">
        <!-- <div nz-row [nzGutter]="16">
                <div nz-col nzSpan="9">
                    <nz-form-item>
                        <nz-form-label [nzNoColon]="true">Send time</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <nz-date-picker [(ngModel)]="sharedService.newDate" [nzShowTime]="{ nzFormat: 'HH:mm' }"
                                nzFormat="yyyy-MM-dd HH:mm" style="width: 100%"></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="15">
                    <nz-form-item>
                        <nz-form-label [nzNoColon]="true">Email type</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <nz-select nzPlaceHolder="Choose" [compareWith]="compareFn"
                                [(ngModel)]="emailObject.templateOptions">
                                <ng-container *ngFor="let type of emailTypes">
                                    <nz-option nzLabel="{{type.name}}" [nzValue]="type"></nz-option>
                                </ng-container>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div> -->


        <!-- <nz-form-item>
                <nz-form-label [nzNoColon]="true">Segment</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzPlaceHolder="Choose" [(ngModel)]="this.email.audience">
                        <ng-container>
                            <nz-option nzLabel="New subscribers" nzValue="1"></nz-option>
                            <nz-option nzLabel="Engaged non-purchasers" nzValue="2"></nz-option>
                            <nz-option nzLabel="One-time purchasers" nzValue="3"></nz-option>
                            <nz-option nzLabel="Frequent buyers" nzValue="4"></nz-option>
                            <nz-option nzLabel="Dormant purchasers" nzValue="5"></nz-option>
                            <nz-option nzLabel="Lapsed subscribers" nzValue="6"></nz-option>
                            <nz-option nzLabel="VIPs" nzValue="7"></nz-option>
                            <nz-option nzLabel="Seasonal shoppers" nzValue="8"></nz-option>
                        </ng-container>
                    </nz-select>
                </nz-form-control>
            </nz-form-item> -->




        <!-- <ng-container *ngFor="let property of this.emailObject.templateOptions.properties">

                <ng-container *ngIf="property.type == 'INPUT'">
                    <nz-form-item>
                        <nz-form-label [nzNoColon]="true">{{ property.name }}</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <input nz-input [(ngModel)]="property.value" />
                        </nz-form-control>
                    </nz-form-item>
                </ng-container>
                <ng-container *ngIf="property.type == 'RICHTEXT'">
                    <nz-form-item>
                        <nz-form-label [nzNoColon]="true">{{ property.name }}</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <textarea rows="4" nz-input [(ngModel)]="property.value"></textarea>
                        </nz-form-control>
                    </nz-form-item>
                </ng-container>
                <ng-container *ngIf="property.type == 'IMAGESELECT'">
                    <nz-form-item>
                        <nz-form-label [nzNoColon]="true">{{ property.name }}</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <div class="image-select-box">
                                <img class="image-select-chosen-image" src="{{property.value}}">
                            </div>
                            <button nz-button (click)="openPicker(property, 'MEDIA')">Change</button>&nbsp;
                            <div class="image-select-wrapper">
                                            <div nz-row [nzGutter]="[16, 24]">
                                                <ng-container *ngFor="let image of opportunityObject.scrapedData.images">
                                                    <ng-container *ngIf="image.src">
                                                        <div nz-col class="gutter-row" [nzSpan]="8">
                                                            <img (click)="property.value = image.src" class="image-list"
                                                                src="{{image.src}}">
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </div>
                        </nz-form-control>
                    </nz-form-item>
                </ng-container>
                <ng-container *ngIf="property.type == 'SELECT'">
                    <nz-form-item>
                        <nz-form-label [nzNoColon]="true">{{ property.name }}</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <nz-select [(ngModel)]="property.value" nzPlaceHolder="Choose">
                                <nz-option *ngFor="let option of property.options" [nzValue]="option"
                                    [nzLabel]="option.title"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </ng-container>
                <ng-container *ngIf="property.type == 'PRODUCTLIST'">
                    <nz-form-item>
                        <nz-form-label [nzNoColon]="true">{{ property.name }}</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <ng-container *ngFor="let item of property.value; let itemIndex=index">
                                <div class="custom-card mini">
                                    <div class="custom-card-body mini">
                                        <div nz-row [nzGutter]="8">
                                            <div nz-col nzSpan="4"><img class="list-image" src="{{item.imageUrl}}">
                                            </div>
                                            <div nz-col nzSpan="18">
                                                <div class="list-title">{{item.title}}</div>
                                            </div>
                                            <div nz-col nzSpan="2">
                                                <div class="list-title"><span
                                                        (click)="this.removeItemFromArray(itemIndex, property.value)"
                                                        nz-icon nzType="close" nzTheme="outline"></span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </ng-container>
                            <div class="list-add-item" (click)="openPicker(property, 'PRODUCTS')">
                                <span nz-icon nzType="plus" nzTheme="outline"></span>&nbsp;Add item
                            </div>
                        </nz-form-control>
                    </nz-form-item>
                </ng-container>
                <ng-container *ngIf="property.type == 'COLOR'">
                    <nz-form-item>

                        <nz-form-control [nzSpan]="24">
                            <ngx-colors ngx-colors-trigger [(ngModel)]="property.value"
                                (ngModelChange)="colorChosen(property.value)" style="float: left"></ngx-colors>
                            <div class="color-picker-label">{{ property.name }}</div>
                        </nz-form-control>
                    </nz-form-item>
                </ng-container>
                <ng-container *ngIf="property.type == 'SLIDER'">
                    <nz-form-item>
                        <nz-form-label [nzNoColon]="true">{{ property.name }}</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <nz-row>
                                <nz-col nzSpan="16">
                                    <nz-slider [nzMin]="1" [nzMax]="50" [(ngModel)]="property.value"></nz-slider>
                                </nz-col>
                                <div nz-col nzSpan="4">
                                    <nz-input-number [nzMin]="1" [nzMax]="50" [ngStyle]="{ marginLeft: '16px' }"
                                        [(ngModel)]="property.value"></nz-input-number>
                                </div>
                            </nz-row>
                        </nz-form-control>
                    </nz-form-item>
                </ng-container>
                <ng-container *ngIf="property.type == 'RADIO'">
                    <nz-form-item>
                        <nz-form-label [nzNoColon]="true">{{ property.name }}</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <nz-radio-group [(ngModel)]="property.value">
                                <label nz-radio-button nzValue="0px">Straight</label>
                                <label nz-radio-button nzValue="10px">Curved</label>
                                <label nz-radio-button nzValue="1000px">Round</label>
                            </nz-radio-group>
                        </nz-form-control>
                    </nz-form-item>
                </ng-container>

            </ng-container> -->
        <div class="chat-wrapper">

            <ng-container *ngFor="let message of chatLog">
                <ng-container *ngIf="message.sender == 'RIPPLE'">
                    <div class="message-bubble-ripple">{{message.message}}</div>
                </ng-container>

                <ng-container *ngIf="message.sender == 'USER'">
                    <div class="message-bubble-user-wrapper">
                        <div class="message-bubble-user">{{message.message}}</div>
                    </div>
                </ng-container>
            </ng-container>
            <div class="message-bubble-ripple" *ngIf="loadingChat">
                <div class="typing">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
            <div class="generating email" *ngIf="generating">
                <button nz-button [nzLoading]="true">Finalizing email - stay on this page</button>
            </div>
        </div>
        <div class="chat-footer-wrapper">
            <nz-form-item style="margin-bottom: 8px !important;">
                <nz-form-control [nzSpan]="24">
                    <textarea rows="4" nz-input [(ngModel)]="emailObject.prompt"></textarea>
                </nz-form-control>
            </nz-form-item>
            <button nz-button nzType="primary" style="float: right" (click)="sendOpenAiChat()"
                [nzLoading]="loadingChat || generating">Send</button>
        </div>
        
    </ng-container>
</div>