<div class="page-wrapper">
    <div class="content-wrapper">
        <ng-container *ngIf="loading">
            <div class="loading-wrapper">
                <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!loading">

            <nz-page-header style="padding: 0px !important; margin-bottom: 16px;">
                <nz-page-header-title>{{integration.type | titlecase}}</nz-page-header-title>
                <nz-page-header-extra>
                    <button [nzLoading]="saving" (click)="saveIntegration()" nz-button nzType="primary">Save</button>
                </nz-page-header-extra>
            </nz-page-header>
            <div nz-row [nzGutter]="8">
                <!-- <div nz-col nzSpan="8">
                </div> -->
                <div nz-col nzSpan="24">
                    <div class="custom-card">
                        <div class="custom-card-header">
                            Connection details
                        </div>
                        <div class="custom-card-body">
                            <ng-container *ngIf="this.integration.type == 'META'">
                                <ng-container *ngIf="!this.integration.accessToken">
                                    <a
                                        href="https://www.facebook.com/v10.0/dialog/oauth?client_id=325445220607973&redirect_uri={{environment.apiUrl}}/auth/facebook/callback&scope=public_profile,pages_show_list,pages_read_engagement,pages_manage_posts,business_management,ads_management,instagram_content_publish,instagram_basic&state={{integration._id}}"><button
                                            nz-button nzType="primary">Connect to Meta</button></a>
                                </ng-container>
                                <ng-container *ngIf="this.integration.accessToken">
                                    <ng-container *ngIf="this.setupFbPage">
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true">Choose a Facebook page</nz-form-label>
                                            <nz-form-control [nzSpan]="24">
                                                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a flow"
                                                    [(ngModel)]="integration.data.facebookPage" style="width: 100%;">
                                                    <ng-container *ngFor="let page of this.facebookPages">
                                                        <nz-option [nzLabel]="page.name" [nzValue]="page"></nz-option>
                                                    </ng-container>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </ng-container>
                                    <ng-container *ngIf="!this.setupFbPage">
                                        This integration is connected to <nz-tag>{{this.integration.data.facebookPage.name}}</nz-tag>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="this.integration.type == 'KLAVIYO'">
                                <div class="custom-section">
                                    <div class="custom-section-body">
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true">Access token</nz-form-label>
                                            <nz-form-control [nzSpan]="24">
                                                <input nz-input [(ngModel)]="integration.accessToken" />
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-form-item style="margin-bottom: 0px">
                                            <nz-form-label [nzNoColon]="true">Periodic syncs</nz-form-label>
                                            <nz-form-control [nzSpan]="24">
                                                <nz-switch [(ngModel)]="integration.periodicSyncs"></nz-switch>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="this.integration.type == 'SHOPIFY'">
                                <nz-form-item>
                                    <nz-form-label [nzNoColon]="true">Access token</nz-form-label>
                                    <nz-form-control [nzSpan]="24">
                                        <input nz-input [(ngModel)]="integration.accessToken" />
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-label [nzNoColon]="true">Shopify domain</nz-form-label>
                                    <nz-form-control [nzSpan]="24">
                                        <input nz-input [(ngModel)]="integration.externalUrl" />
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item style="margin-bottom: 0px">
                                    <nz-form-label [nzNoColon]="true">Periodic syncs</nz-form-label>
                                    <nz-form-control [nzSpan]="24">
                                        <nz-switch [(ngModel)]="integration.periodicSyncs"></nz-switch>
                                    </nz-form-control>
                                </nz-form-item>

                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <ng-container *ngIf="this.integration.type == 'KLAVIYO'">
                <div class="custom-card">
                    <div class="custom-card-header">Connection details</div>
                    <div class="custom-card-body">
                        <nz-form-item>
                            <nz-form-label [nzNoColon]="true">Access token</nz-form-label>
                            <nz-form-control [nzSpan]="24">
                                <input nz-input [(ngModel)]="integration.accessToken" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </ng-container> -->
            <ng-container *ngIf="this.integration.type == 'SHOPIFY'">

                <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                        <ng-container *ngFor="let sync of this.integration.syncs">
                            <ng-container *ngIf="sync.type == 'SHOPIFYIMAGES'">
                                <div class="custom-card">
                                    <div class="custom-card-header" style="line-height: 36px;">Images&nbsp;
                                        <ng-container
                                            *ngIf="sync.sync && sync.sync.status == 'INPROGRESS' || sync.sync && sync.sync.status == 'WAITING'">
                                            <nz-tag> <nz-spin style="display: inline; margin-right: 18px;"
                                                    [nzIndicator]="indicatorTemplate"></nz-spin>&nbsp;In
                                                progress</nz-tag>
                                        </ng-container>
                                        <ng-container *ngIf="sync.sync && sync.sync.status == 'COMPLETE'">
                                            <nz-tag class="aqua">Complete</nz-tag>
                                        </ng-container>
                                        <ng-container *ngIf="sync.sync && sync.sync.status == 'CANCELLED'">
                                            <nz-tag class="red">Cancelled</nz-tag>
                                        </ng-container>
                                        <!-- <button (click)="syncShopifyImages(sync)" nz-button nzType="primary"
                                            style="float: right" [nzLoading]="sync.loading"
                                            [disabled]="sync.sync && sync.sync.status == 'INPROGRESS'">Start
                                            sync</button> -->

                                        <ng-container
                                            *ngIf="!sync || !sync.sync || (sync.sync && sync.sync.status == 'COMPLETE') || (sync.sync && sync.sync.status == 'CANCELLED')">
                                            <nz-button-group style="float: right">
                                                <button (click)="syncShopifyImages(sync)" nz-button nzType="primary"
                                                    [nzLoading]="sync.loading"
                                                    [disabled]="sync.sync && sync.sync.status == 'INPROGRESS' || sync.sync && sync.sync.status == 'WAITING'">Start
                                                    sync</button>
                                                <button
                                                    [disabled]="sync.sync && sync.sync.status == 'INPROGRESS' || sync.sync && sync.sync.status == 'WAITING'"
                                                    nzType="primary" nz-button nz-dropdown [nzDropdownMenu]="menu3"
                                                    nzPlacement="bottomRight">
                                                    <span nz-icon nzType="ellipsis"></span>
                                                </button>
                                            </nz-button-group>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="sync.sync && sync.sync.status == 'INPROGRESS' || sync.sync && sync.sync.status == 'WAITING'">
                                            <button nz-button style="float: right" nzDanger [nzLoading]="sync.loading"
                                                (click)="cancelSync(sync)"><span nz-icon nzType="close-circle"
                                                    nzTheme="outline"></span>Cancel</button>
                                        </ng-container>

                                        <nz-dropdown-menu #menu3="nzDropdownMenu">
                                            <ul nz-menu>
                                                <li nz-menu-item (click)="syncShopifyImagesFull(sync)">Run full
                                                    sync
                                                </li>
                                            </ul>
                                        </nz-dropdown-menu>
                                    </div>
                                    <div class="custom-card-body" *ngIf="sync.sync">
                                        <nz-tag style="margin-right: 0px;">{{sync.sync.data.count}}</nz-tag>
                                        Images processed in the most recent sync. Last sync run at <nz-tag
                                            style="margin-right: 0px;">{{sync.sync.createdAt | date:'short'}}</nz-tag>
                                        <br><br>
                                        Imported <nz-tag style="margin-right: 0px;">{{integration.filesCount}}</nz-tag>
                                        images total
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="sync.type == 'SHOPIFYPRODUCTS'">
                                <div class="custom-card">
                                    <div class="custom-card-header" style="line-height: 36px;">Products&nbsp;
                                        <ng-container
                                            *ngIf="sync.sync && sync.sync.status == 'INPROGRESS' || sync.sync && sync.sync.status == 'WAITING'">
                                            <nz-tag> <nz-spin style="display: inline; margin-right: 18px;"
                                                    [nzIndicator]="indicatorTemplate"></nz-spin>&nbsp;In
                                                progress</nz-tag>
                                        </ng-container>
                                        <ng-container *ngIf="sync.sync && sync.sync.status == 'COMPLETE'">
                                            <nz-tag class="aqua">Complete</nz-tag>
                                        </ng-container>
                                        <ng-container *ngIf="sync.sync && sync.sync.status == 'CANCELLED'">
                                            <nz-tag class="red">Cancelled</nz-tag>
                                        </ng-container>
                                        <!-- <button (click)="syncShopifyProducts(sync)" nz-button nzType="primary"
                                            style="float: right" [nzLoading]="sync.loading"
                                            [disabled]="sync.sync && sync.sync.status == 'INPROGRESS'">Start
                                            sync</button> -->
                                        <ng-container
                                            *ngIf="!sync || !sync.sync || (sync.sync && sync.sync.status == 'COMPLETE') || (sync.sync && sync.sync.status == 'CANCELLED')">
                                            <nz-button-group style="float: right">
                                                <button (click)="syncShopifyProducts(sync)" nz-button nzType="primary"
                                                    [nzLoading]="sync.loading"
                                                    [disabled]="sync.sync && sync.sync.status == 'INPROGRESS' || sync.sync && sync.sync.status == 'WAITING'">Start
                                                    sync</button>
                                                <button
                                                    [disabled]="sync.sync && sync.sync.status == 'INPROGRESS' || sync.sync && sync.sync.status == 'WAITING'"
                                                    nzType="primary" nz-button nz-dropdown [nzDropdownMenu]="menu1"
                                                    nzPlacement="bottomRight">
                                                    <span nz-icon nzType="ellipsis"></span>
                                                </button>
                                            </nz-button-group>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="sync.sync && sync.sync.status == 'INPROGRESS' || sync.sync && sync.sync.status == 'WAITING'">
                                            <button nz-button style="float: right" nzDanger [nzLoading]="sync.loading"
                                                (click)="cancelSync(sync)"><span nz-icon nzType="close-circle"
                                                    nzTheme="outline"></span>Cancel</button>
                                        </ng-container>

                                        <!-- <button nzPlacement="bottomRight" nz-button nz-dropdown [nzDropdownMenu]="menu4"
                                            style="top: -3px;">
                                            <span nz-icon nzType="ellipsis"></span>
                                        </button> -->

                                        <nz-dropdown-menu #menu1="nzDropdownMenu">
                                            <ul nz-menu>
                                                <li nz-menu-item (click)="syncShopifyProductsFull(sync)">Run full sync
                                                </li>
                                            </ul>
                                        </nz-dropdown-menu>
                                    </div>
                                    <div class="custom-card-body" *ngIf="sync.sync">
                                        <nz-tag style="margin-right: 0px;">{{sync.sync.data.count}}</nz-tag>
                                        Products processed in the most recent sync. Last sync run at <nz-tag
                                            style="margin-right: 0px;">{{sync.sync.createdAt | date:'short'}}</nz-tag>
                                        <br><br>
                                        Imported <nz-tag
                                            style="margin-right: 0px;">{{integration.productsCount}}</nz-tag>
                                        products total
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="sync.type == 'SHOPIFYCOLLECTIONS'">
                                <div class="custom-card">
                                    <div class="custom-card-header" style="line-height: 36px;">Collections&nbsp;
                                        <ng-container
                                            *ngIf="sync.sync && sync.sync.status == 'INPROGRESS' || sync.sync && sync.sync.status == 'WAITING'">
                                            <nz-tag> <nz-spin style="display: inline; margin-right: 18px;"
                                                    [nzIndicator]="indicatorTemplate"></nz-spin>&nbsp;In
                                                progress</nz-tag>
                                        </ng-container>
                                        <ng-container *ngIf="sync.sync && sync.sync.status == 'COMPLETE'">
                                            <nz-tag class="aqua">Complete</nz-tag>
                                        </ng-container>
                                        <ng-container *ngIf="sync.sync && sync.sync.status == 'CANCELLED'">
                                            <nz-tag class="red">Cancelled</nz-tag>
                                        </ng-container>
                                        <!-- <button (click)="syncShopifyCollections(sync)" nz-button nzType="primary"
                                            style="float: right" [nzLoading]="sync.loading"
                                            [disabled]="sync.sync && sync.sync.status == 'INPROGRESS'">Start
                                            sync</button> -->

                                        <ng-container
                                            *ngIf="!sync || !sync.sync || (sync.sync && sync.sync.status == 'COMPLETE') || (sync.sync && sync.sync.status == 'CANCELLED')">
                                            <nz-button-group style="float: right">
                                                <button (click)="syncShopifyCollections(sync)" nz-button
                                                    nzType="primary" [nzLoading]="sync.loading"
                                                    [disabled]="sync.sync && sync.sync.status == 'INPROGRESS' || sync.sync && sync.sync.status == 'WAITING'">Start
                                                    sync</button>
                                                <button
                                                    [disabled]="sync.sync && sync.sync.status == 'INPROGRESS' || sync.sync && sync.sync.status == 'WAITING'"
                                                    nzType="primary" nz-button nz-dropdown [nzDropdownMenu]="menu2"
                                                    nzPlacement="bottomRight">
                                                    <span nz-icon nzType="ellipsis"></span>
                                                </button>
                                            </nz-button-group>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="sync.sync && sync.sync.status == 'INPROGRESS' || sync.sync && sync.sync.status == 'WAITING'">
                                            <button nz-button style="float: right" nzDanger [nzLoading]="sync.loading"
                                                (click)="cancelSync(sync)"><span nz-icon nzType="close-circle"
                                                    nzTheme="outline"></span>Cancel</button>
                                        </ng-container>

                                        <nz-dropdown-menu #menu2="nzDropdownMenu">
                                            <ul nz-menu>
                                                <li nz-menu-item (click)="syncShopifyCollectionsFull(sync)">Run full
                                                    sync
                                                </li>
                                            </ul>
                                        </nz-dropdown-menu>
                                    </div>
                                    <div class="custom-card-body" *ngIf="sync.sync">
                                        <nz-tag style="margin-right: 0px;">{{sync.sync.data.count}}</nz-tag>
                                        Collections processed in the most recent sync. Last sync run at <nz-tag
                                            style="margin-right: 0px;">{{sync.sync.createdAt | date:'short'}}</nz-tag>
                                        <br><br>
                                        Imported <nz-tag
                                            style="margin-right: 0px;">{{integration.collectionsCount}}</nz-tag>
                                        collections total
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </nz-form-control>
                </nz-form-item>
            </ng-container>

            <ng-container *ngIf="this.integration.type == 'SHOPIFY'">
                <!-- <div class="custom-card">
                    <div class="custom-card-header">Connection details</div>
                    <div class="custom-card-body">
                        <nz-form-item>
                            <nz-form-label [nzNoColon]="true">Access token</nz-form-label>
                            <nz-form-control [nzSpan]="24">
                                <input nz-input [(ngModel)]="integration.accessToken" />
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [nzNoColon]="true">Shopify domain</nz-form-label>
                            <nz-form-control [nzSpan]="24">
                                <input nz-input [(ngModel)]="integration.externalUrl" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div> -->
                <!-- <div class="custom-section">
                    <div class="custom-section-body">
                        <nz-form-item>
                            <nz-form-label [nzNoColon]="true">Sync products</nz-form-label>
                            <nz-form-control [nzSpan]="24">
                                <button (click)="syncShopifyProducts()" nz-button nzType="primary">Sync</button>
                                {{integration.productsCount}} synced
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [nzNoColon]="true">Sync images</nz-form-label>
                            <nz-form-control [nzSpan]="24">
                                <button (click)="syncShopifyImages()" nz-button nzType="primary">Sync</button>
                                {{integration.filesCount}} synced
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [nzNoColon]="true">Sync collections</nz-form-label>
                            <nz-form-control [nzSpan]="24">
                                <button (click)="syncShopifyCollections()" nz-button nzType="primary">Sync</button>
                                {{integration.collectionsCount}} synced
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [nzNoColon]="true">Vectorize products</nz-form-label>
                            <nz-form-control [nzSpan]="24">
                                <button (click)="vectorDbProducts()" nz-button nzType="primary">Sync</button>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [nzNoColon]="true">Sync to OpenAi</nz-form-label>
                            <nz-form-control [nzSpan]="24">
                                <button (click)="syncToOpenAi()" nz-button nzType="primary">Sync</button>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div> -->
            </ng-container>

            <ng-container *ngIf="this.integration.type == 'KLAVIYO'">

                <!-- <nz-form-label [nzNoColon]="true">Syncs</nz-form-label> -->
                <nz-form-control [nzSpan]="24">
                    <ng-container *ngFor="let sync of this.integration.syncs">
                        <ng-container *ngIf="sync.type == 'KLAVIYOEVENTS'">
                            <div class="custom-card">
                                <div class="custom-card-header" style="line-height: 36px;">Events&nbsp;
                                    <ng-container
                                        *ngIf="sync.sync && sync.sync.status == 'INPROGRESS' || sync.sync && sync.sync.status == 'WAITING'">
                                        <nz-tag> <nz-spin style="display: inline; margin-right: 18px;"
                                                [nzIndicator]="indicatorTemplate"></nz-spin>&nbsp;In
                                            progress</nz-tag>
                                    </ng-container>
                                    <ng-container *ngIf="sync.sync && sync.sync.status == 'COMPLETE'">
                                        <nz-tag class="aqua">Complete</nz-tag>
                                    </ng-container>
                                    <ng-container *ngIf="sync.sync && sync.sync.status == 'CANCELLED'">
                                        <nz-tag class="red">Cancelled</nz-tag>
                                    </ng-container>
                                    <!-- <button (click)="syncShopifyImages(sync)" nz-button nzType="primary"
                                        style="float: right" [nzLoading]="sync.loading"
                                        [disabled]="sync.sync && sync.sync.status == 'INPROGRESS'">Start
                                        sync</button> -->

                                    <ng-container
                                        *ngIf="!sync || !sync.sync || (sync.sync && sync.sync.status == 'COMPLETE') || (sync.sync && sync.sync.status == 'CANCELLED')">
                                        <nz-button-group style="float: right">
                                            <button (click)="syncKlaviyoEvents(sync)" nz-button nzType="primary"
                                                [nzLoading]="sync.loading"
                                                [disabled]="sync.sync && sync.sync.status == 'INPROGRESS' || sync.sync && sync.sync.status == 'WAITING'">Start
                                                sync</button>
                                            <button
                                                [disabled]="sync.sync && sync.sync.status == 'INPROGRESS' || sync.sync && sync.sync.status == 'WAITING'"
                                                nzType="primary" nz-button nz-dropdown [nzDropdownMenu]="menu3"
                                                nzPlacement="bottomRight">
                                                <span nz-icon nzType="ellipsis"></span>
                                            </button>
                                        </nz-button-group>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="sync.sync && sync.sync.status == 'INPROGRESS' || sync.sync && sync.sync.status == 'WAITING'">
                                        <button nz-button style="float: right" nzDanger [nzLoading]="sync.loading"
                                            (click)="cancelSync(sync)"><span nz-icon nzType="close-circle"
                                                nzTheme="outline"></span>Cancel</button>
                                    </ng-container>

                                    <nz-dropdown-menu #menu3="nzDropdownMenu">
                                        <ul nz-menu>
                                            <li nz-menu-item (click)="syncKlaviyoEventsFull(sync)">Run full
                                                sync
                                            </li>
                                        </ul>
                                    </nz-dropdown-menu>
                                </div>
                                <div class="custom-card-body" *ngIf="sync.sync">
                                    <!-- <nz-tag style="margin-right: 0px;">{{sync.sync.data.count}}</nz-tag>
                                    Images processed in the most recent sync. Last sync run at <nz-tag
                                        style="margin-right: 0px;">{{sync.sync.createdAt | date:'short'}}</nz-tag>
                                    <br><br>
                                    Imported <nz-tag style="margin-right: 0px;">{{integration.filesCount}}</nz-tag>
                                    images total -->

                                    Last sync run at <nz-tag style="margin-right: 0px;">{{sync.sync.createdAt |
                                        date:'short'}}</nz-tag>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <!-- <div class="custom-card">
                        <div class="custom-card-header" style="line-height: 36px;">Events&nbsp;

                            <button style="float: right" (click)="syncKlaviyoEvents()" nz-button nzType="primary">Start
                                sync</button>

                        </div>

                    </div> -->
                </nz-form-control>
            </ng-container>
        </ng-container>
    </div>
</div>

<ng-template #indicatorTemplate><span nz-icon style="height: 10px !important; width: 10px !important;"
        nzType="loading"></span></ng-template>