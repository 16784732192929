import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SharedService } from '../shared.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-create-from-template',
  templateUrl: './create-from-template.component.html',
  styleUrls: ['./create-from-template.component.scss']
})
export class CreateFromTemplateComponent {

  opportunities: any = [];
  loading = true;
  loadingNewEmail = false;
  prompt: any = "";
  emailObject: any = {
    sendTime: null,
    template: null,
    prompt: "",
    templateOptions: {
      properties: []
    },
    type: {
      properties: []
    },
    properties: [
      {
        type: "PRODUCTLIST",
        name: "Product",
        value: []
      }
    ],
    stage: "NEW"
  }
  showRenderer = false;
  creatingLayout = false;
  emailDone = false;
  promptHistory: any = [];
  emailHistory: any = [];
  currentEmail = 0;
  loadingStrings = [
    "Calculating the meaning of life...",
    "Summoning dragons...",
    "Searching for unicorns...",
    "Brewing a potion...",
    "Chasing rainbows...",
    "Plotting world domination...",
    "Juggling flaming torches...",
    "Solving the puzzle...",
    "Harnessing the power of the stars...",
    "Translating ancient runes...",
    "Harnessing the energy of thunderstorms...",
    "Deciphering hieroglyphics...",
    "Communing with spirits...",
    "Crafting a masterpiece...",
    "Sailing uncharted waters...",
    "Venturing into the unknown...",
    "Taming wild beasts...",
    "Building castles in the air...",
    "Exploring the cosmos...",
    "Unraveling mysteries of the universe...",
    "Whispering secrets to the wind...",
    "Dreaming of distant galaxies...",
    "Casting spells in the moonlight...",
    "Riding on the wings of imagination...",
    "Diving into the depths of the ocean...",
    "Dancing with fireflies...",
    "Wandering through enchanted forests...",
    "Wrestling with giants...",
    "Creating a symphony of stars...",
    "Painting with all the colors of the wind...",
    "Diving into the rabbit hole...",
    "Fishing for dreams in the sea of possibilities...",
    "Chasing after shooting stars...",
    "Trekking through the wilderness of ideas...",
    "Hunting for treasure in the depths of the mind...",
    "Wandering through the labyrinth of creativity...",
    "Climbing the mountain of inspiration...",
    "Exploring the caverns of imagination...",
    "Navigating the maze of innovation..."
  ];
  loadingText = "";
  chatLog: any = [];
  loadingChat: any = false;
  chatBox: any = "";
  delayMessage: any = false;
  delayMessage2: any = false;
  showHeroOptions: any = false;
  heroOptions: any = false;
  suggestions: any = [];
  loadingSuggestions: any = true;
  promptEmailHistory: any = [];
  emailTypes: any = [
    {
      name: "Product feature",
      icon: "lni lni-bullhorn",
      prompt: "Make me a product feature email",
      category: "PROMOTIONAL",
      properties: [
        {
          type: "PRODUCTLIST",
          name: "Product(s)",
          value: [],
          prefix: "Here is the product to feature"
        },
        {
          type: "RICHTEXT",
          name: "Prompt",
          value: "",
          prefix: "Here is the information about the product"
        }
      ]
    },
    {
      name: "Collection feature",
      icon: "lni lni-grid-alt",
      prompt: "Make me a collection feature email. You must use the collection chosen in a product block, in dynamic/collection mode. Don't use static product mode. Only include one product block that highlights the collection chosen.",
      category: "PROMOTIONAL",
      properties: [
        {
          type: "COLLECTION",
          name: "Collection to feature",
          value: [],
          prefix: "Here is the collection to feature."
        },
        {
          type: "RICHTEXT",
          name: "Prompt",
          value: "",
          prefix: "Here is the information about the product"
        }
      ]
    },
    {
      name: "Review highlight",
      icon: "lni lni-comments",
      prompt: "Make me a review highlight email",
      category: "PROMOTIONAL",
      properties: [
        {
          type: "RICHTEXT",
          name: "Topic",
          value: [],
          prefix: "Here is the topic of the email to focus on"
        }
      ]
    },
    {
      name: "Blog",
      icon: "lni lni-rss-feed",
      prompt: "Make me an email based on a blog post",
      category: "PROMOTIONAL",
      properties: [
        {
          type: "RICHTEXT",
          name: "Source material (copy & paste)",
          value: [],
          prefix: "Here is the source material"
        },
        {
          type: "RICHTEXT",
          name: "Prompt",
          value: [],
          prefix: "Here are instructions from the user"
        }
      ]
    },
    {
      name: "Sale",
      icon: "lni lni-dollar",
      prompt: "Make me an email for a sale",
      category: "PROMOTIONAL",
      properties: [
        {
          type: "INPUT",
          name: "Sale / promotion name",
          value: "",
          prefix: "Here is the name of the sale"
        },
        {
          type: "RICHTEXT",
          name: "Offer details",
          value: "",
          prefix: "Here are the details of the offer"
        },
        {
          type: "INPUT",
          name: "Dates (optional)",
          value: "",
          prefix: "Here are the dates of the offer"
        },
        {
          type: "INPUT",
          name: "Promo code (optional)",
          value: "",
          prefix: "Here is the promo code to use for the offer"
        },
        {
          type: "RICHTEXT",
          name: "Additional context (optional)",
          value: "",
          prefix: "Here is some additional info"
        }
      ]
    },
    // {
    //   name: "Product feature",
    //   icon: "lni lni-bullhorn",
    //   prompt: "Make me an email that features a single product. I’ll give you the product to feature and some additional info about the product. The email should have the following layout: HERO, ARTICLE, VALUEPROPS, REVIEW. Don’t add any other blocks. In the list block, please highlight 3 key features about the product, taken from the product information I give you. Make the Article block short with just a body text, no title, no subtitle, and no button.",
    //   category: "PROMOTIONAL",
    //   properties: [
    //     {
    //       type: "PRODUCTLIST",
    //       name: "Product(s)",
    //       value: [],
    //       prefix: "Here is the product to feature"
    //     },
    //     {
    //       type: "RICHTEXT",
    //       name: "Prompt",
    //       value: "",
    //       prefix: "Here is the information about the product"
    //     }
    //   ]
    // },
    // {
    //   name: "Multi product",
    //   icon: "lni lni-bullhorn",
    //   prompt: "Make me an email that features several products. I’ll give you the products to feature. The email should have the following layout: HERO, ARTICLE, PRODUCT, REVIEW. Don’t add any other blocks. Put ALL the products I provide you in that one product block in the layout.",
    //   category: "PROMOTIONAL",
    //   properties: [
    //     {
    //       type: "PRODUCTLIST",
    //       name: "Product(s)",
    //       value: [],
    //       prefix: "Here are the products to feature"
    //     },
    //     {
    //       type: "RICHTEXT",
    //       name: "Prompt",
    //       value: "",
    //       prefix: "Here are are some additional instructions from the user"
    //     }
    //   ]
    // },
    // {
    //   name: "Collection feature",
    //   icon: "lni lni-grid-alt",
    //   prompt: "Make me an email that features a product collection. I’ll give you the collection to feature. The email should have the following layout: HERO, ARTICLE, PRODUCT, REVIEW. Don’t add any other blocks. In the product block, you must set it to be a collection product block and put the exact collection I provide you in it. Make sure the copy throughout the email centers around the specific collection you have been asked to feature.",
    //   category: "PROMOTIONAL",
    //   properties: [
    //     {
    //       type: "RICHTEXT",
    //       name: "Collection",
    //       value: "",
    //       prefix: "Here is the collection to feature"
    //     },
    //     {
    //       type: "RICHTEXT",
    //       name: "Prompt",
    //       value: "",
    //       prefix: "Here are are some additional instructions from the user"
    //     }
    //   ]
    // },
    // {
    //   name: "Catalog",
    //   icon: "lni lni-grid-alt",
    //   prompt: "Make me an email that features several product collections. I’ll give you the collection to feature. The email should have the following layout: HERO, ARTICLE, PRODUCT (add a product block for each collection requested by the user), REVIEW. Don’t add any other blocks. You must add a product block for each collection requested by the user. In each product block you much feature the collection requested by the user. The product block must be in collection mode. Make sure the copy throughout the email centers around the specific collection you have been asked to feature.",
    //   category: "PROMOTIONAL",
    //   properties: [
    //     {
    //       type: "RICHTEXT",
    //       name: "Collections",
    //       value: "",
    //       prefix: "Here are collections to feature"
    //     },
    //     {
    //       type: "RICHTEXT",
    //       name: "Prompt",
    //       value: "",
    //       prefix: "Here are are some additional instructions from the user"
    //     }
    //   ]
    // },
    // {
    //   name: "Back in stock",
    //   icon: "lni lni-reload",
    //   prompt: "Make me an email announcing a product is back in stock. I’ll give you the product to feature. The email should have the following layout: HERO, ARTICLE, PRODUCT. Don’t add any other blocks. In the product block you must feature the product you are asked to. Make sure the copy throughout the email centers around the product being featured.",
    //   category: "PROMOTIONAL",
    //   properties: [
    //     {
    //       type: "PRODUCTLIST",
    //       name: "Product",
    //       value: [],
    //       prefix: "Here is the product to feature"
    //     },
    //     {
    //       type: "RICHTEXT",
    //       name: "Prompt",
    //       value: "",
    //       prefix: "Here are are some additional instructions from the user"
    //     }
    //   ]
    // },
    // {
    //   name: "Catalog overview",
    //   icon: "lni lni-grid-alt",
    //   prompt: "Make me a catalog overview email",
    //   properties: [
    //     {
    //       type: "RICHTEXT",
    //       name: "Products or collections to highlight",
    //       value: "",
    //       prefix: "Here are the products/collections to feature"
    //     },
    //     {
    //       type: "RICHTEXT",
    //       name: "Prompt",
    //       value: "",
    //       prefix: "Here are are some additional instructions from the user"
    //     }
    //   ]
    // },
    // {
    //   name: "Review highlight",
    //   icon: "lni lni-comments-alt-2",
    //   prompt: "Make me an email highlighting several reviews from my brand. I’ll give you the topic to focus on. The email should have the following layout: HERO, REVIEW, REVIEW, REVIEW. Don’t add any other blocks. Make sure the copy throughout the email and the reviews chosen centers around the topic being featured.",
    //   category: "SOCIALPROOF",
    //   properties: [
    //     {
    //       type: "RICHTEXT",
    //       name: "Prompt",
    //       value: "",
    //       prefix: "Here are the instructions from the user"
    //     },
    //     // {
    //     //   type: "RICHTEXT",
    //     //   name: "Additional context (optional)",
    //     //   value: "",
    //     //   prefix: "Here is some additional info"
    //     // }
    //   ]
    // },
    // {
    //   name: "Before & after",
    //   icon: "lni lni-hourglass",
    //   prompt: "Make me a review highlight email",
    //   category: "SOCIALPROOF",
    //   properties: [
    //     {
    //       type: "RICHTEXT",
    //       name: "Prompt",
    //       value: "",
    //       prefix: "Here are the instructions from the user"
    //     },
    //     // {
    //     //   type: "RICHTEXT",
    //     //   name: "Additional context (optional)",
    //     //   value: "",
    //     //   prefix: "Here is some additional info"
    //     // }
    //   ]
    // },
    // {
    //   name: "Value props",
    //   icon: "lni lni-list",
    //   prompt: "Make me an email highlighting the value props of our products. I’ll give you the topic to focus on and some additional info to pull the value props from,. The email should have the following layout: HERO, ARTICLE, LIST, REVIEW. Don’t add any other blocks. You must populate the list block with value props based on the topic and additional info I give you. ",
    //   category: "EDUCATIONAL",
    //   properties: [
    //     {
    //       type: "RICHTEXT",
    //       name: "Prompt",
    //       value: "",
    //       prefix: "Here are instructions from the user"
    //     },
    //     {
    //       type: "RICHTEXT",
    //       name: "Source material (copy & paste here)",
    //       value: "",
    //       prefix: "Here is the source material for the email"
    //     }
    //   ]
    // },
    // {
    //   name: "How to",
    //   icon: "lni lni-hammer",
    //   prompt: "Make me a “how to” email on a certain topic. I’ll give you the topic to focus on and some additional info to pull the value props from. The email should have the following layout: HERO, ARTICLE, LIST. Don’t add any other blocks. You must populate the list and article blocks with relevant information from the how to. Populate the list with the how to steps and make the article block an intro.",
    //   category: "EDUCATIONAL",
    //   properties: [
    //     {
    //       type: "RICHTEXT",
    //       name: "Prompt",
    //       value: "",
    //       prefix: "Here are instructions from the user"
    //     },
    //     {
    //       type: "RICHTEXT",
    //       name: "Source material (copy & paste here)",
    //       value: "",
    //       prefix: "Here is the source material for the email"
    //     }
    //   ]
    // },
    // {
    //   name: "FAQ",
    //   icon: "lni lni-question-circle",
    //   prompt: "Make me an FAQ email on a certain topic. I’ll give you the topic to focus on and some additional info to pull the FAQ from. The email should have the following layout: HERO, LIST. Don’t add any other blocks. You must populate the list and article blocks with relevant information from the FAQ. Populate the list with the FAQ.",
    //   category: "EDUCATIONAL",
    //   properties: [
    //     {
    //       type: "RICHTEXT",
    //       name: "Prompt",
    //       value: "",
    //       prefix: "Here are instructions from the user"
    //     },
    //     {
    //       type: "RICHTEXT",
    //       name: "Source material (copy & paste here)",
    //       value: "",
    //       prefix: "Here is the source material for the email"
    //     }
    //   ]
    // },
    // {
    //   name: "Blog post",
    //   icon: "lni lni-rss-feed",
    //   prompt: "    - Make me an email based on a blog post. I’ll give you the blog content and some additional instructions. You’ll have to choose a layout that makes sense based on the content of the blog. Analyze the blog content before deciding. If the blog is just long form content, you can use one or several ARTICLE blocks to highlight it. If the blog is a list/bullet point style content, you should use a LIST block. Make sure to also include a CTA to read the full article. ",
    //   category: "EDUCATIONAL",
    //   properties: [
    //     {
    //       type: "RICHTEXT",
    //       name: "Prompt",
    //       value: "",
    //       prefix: "Here are instructions from the user"
    //     },
    //     {
    //       type: "RICHTEXT",
    //       name: "Source material (copy & paste here)",
    //       value: "",
    //       prefix: "Here is the source material for the email"
    //     }
    //   ]
    // },
    // {
    //   name: "Blog list",
    //   icon: "lni lni-rss-feed",
    //   prompt: "Make me an email featuring several blog posts. I’ll give you the list of blog posts. The email should have the following layout: HERO, LIST. Do not include any other blocks. The list block must consist of a list of the blogs that I provide you. For each one make the list item title the title of the blog post, and make the list item body a short blurb of the content of the blog post. On each list item include a CTA to read the relevant blog post.",
    //   category: "EDUCATIONAL",
    //   properties: [
    //     {
    //       type: "RICHTEXT",
    //       name: "Prompt",
    //       value: "",
    //       prefix: "Here are instructions from the user"
    //     },
    //     {
    //       type: "RICHTEXT",
    //       name: "Source material (copy & paste here)",
    //       value: "",
    //       prefix: "Here is the list of blog posts"
    //     }
    //   ]
    // },
    // {
    //   name: "Brand story",
    //   icon: "lni lni-information",
    //   prompt: "Make me an email about the story of this brand. I’ll give you some source content to leverage as well as some additional instructions. The email should have the following layout: HERO, CONTENT, IMAGE. Do not include any other blocks.",
    //   category: "EDUCATIONAL",
    //   properties: [
    //     {
    //       type: "RICHTEXT",
    //       name: "Prompt",
    //       value: "",
    //       prefix: "Here are instructions from the user"
    //     },
    //     {
    //       type: "RICHTEXT",
    //       name: "Source material (copy & paste here)",
    //       value: "",
    //       prefix: "Here is the source material for the email"
    //     }
    //   ]
    // },
    // {
    //   name: "Sale teaser",
    //   icon: "lni lni-coin",
    //   prompt: "Make me an email teasing an upcoming sale. I’ll give you some info about the sale. The email should have the following layout: BANNER, HERO, CONTENT, PROMOTION (only include the promo block if the user added a promo code to the email). Do not include any other blocks. Make sure the copy throughout the email reflects the nature of the sale appropriately.  ",
    //   category: "SALE",
    //   properties: [
    //     {
    //       type: "INPUT",
    //       name: "Sale name",
    //       value: "",
    //       prefix: "Here is the name of the sale / promotion"
    //     },
    //     {
    //       type: "RICHTEXT",
    //       name: "Offer details",
    //       value: "",
    //       prefix: "Here are the details of the offer"
    //     },
    //     {
    //       type: "INPUT",
    //       name: "Dates (optional)",
    //       value: "",
    //       prefix: "Here are the dates of the offer"
    //     },
    //     {
    //       type: "INPUT",
    //       name: "Promo code (optional)",
    //       value: "",
    //       prefix: "Here is the promo code to use for the offer"
    //     },
    //     {
    //       type: "RICHTEXT",
    //       name: "Additional context (optional)",
    //       value: "",
    //       prefix: "Here is some additional info"
    //     }
    //   ]
    // },
    // {
    //   name: "Sale launch",
    //   icon: "lni lni-coin",
    //   prompt: "Make me an email announcing the start of a sale. I’ll give you some info about the sale. The email should have the following layout: BANNER, HERO, CONTENT, PROMOTION (only include the promo block if the user added a promo code to the email). Do not include any other blocks. Make sure the copy throughout the email reflects the nature of the sale appropriately. ",
    //   category: "SALE",
    //   properties: [
    //     {
    //       type: "INPUT",
    //       name: "Sale / promotion name",
    //       value: "",
    //       prefix: "Here is the name of the sale / promotion"
    //     },
    //     {
    //       type: "RICHTEXT",
    //       name: "Offer details",
    //       value: "",
    //       prefix: "Here are the details of the offer"
    //     },
    //     {
    //       type: "INPUT",
    //       name: "Dates (optional)",
    //       value: "",
    //       prefix: "Here are the dates of the offer"
    //     },
    //     {
    //       type: "INPUT",
    //       name: "Promo code (optional)",
    //       value: "",
    //       prefix: "Here is the promo code to use for the offer"
    //     },
    //     {
    //       type: "RICHTEXT",
    //       name: "Additional context (optional)",
    //       value: "",
    //       prefix: "Here is some additional info"
    //     }
    //   ]
    // },
    // {
    //   name: "Sale reminder",
    //   icon: "lni lni-coin",
    //   prompt: "Make me an email reminding customers about an ongoing sale, this email is being sent during the middle of the sale. I’ll give you some info about the sale. The email should have the following layout: BANNER, HERO, CONTENT, PROMOTION (only include the promo block if the user added a promo code to the email). Do not include any other blocks. Make sure the copy throughout the email reflects the nature of the sale appropriately. ",
    //   category: "SALE",
    //   properties: [
    //     {
    //       type: "INPUT",
    //       name: "Sale / promotion name",
    //       value: "",
    //       prefix: "Here is the name of the sale / promotion"
    //     },
    //     {
    //       type: "RICHTEXT",
    //       name: "Offer details",
    //       value: "",
    //       prefix: "Here are the details of the offer"
    //     },
    //     {
    //       type: "INPUT",
    //       name: "Dates (optional)",
    //       value: "",
    //       prefix: "Here are the dates of the offer"
    //     },
    //     {
    //       type: "INPUT",
    //       name: "Promo code (optional)",
    //       value: "",
    //       prefix: "Here is the promo code to use for the offer"
    //     },
    //     {
    //       type: "RICHTEXT",
    //       name: "Additional context (optional)",
    //       value: "",
    //       prefix: "Here is some additional info"
    //     }
    //   ]
    // },
    // {
    //   name: "Sale last call",
    //   icon: "lni lni-coin",
    //   prompt: "Make me an email giving customers a last call for an ongoing sale. I’ll give you some info about the sale. The email should have the following layout: BANNER, HERO, CONTENT, PROMOTION (only include the promo block if the user added a promo code to the email). Do not include any other blocks. Make sure the copy throughout the email reflects the nature of the sale appropriately. ",
    //   category: "SALE",
    //   properties: [
    //     {
    //       type: "INPUT",
    //       name: "Sale / promotion name",
    //       value: "",
    //       prefix: "Here is the name of the sale / promotion"
    //     },
    //     {
    //       type: "RICHTEXT",
    //       name: "Offer details",
    //       value: "",
    //       prefix: "Here are the details of the offer"
    //     },
    //     {
    //       type: "INPUT",
    //       name: "Dates (optional)",
    //       value: "",
    //       prefix: "Here are the dates of the offer"
    //     },
    //     {
    //       type: "INPUT",
    //       name: "Promo code (optional)",
    //       value: "",
    //       prefix: "Here is the promo code to use for the offer"
    //     },
    //     {
    //       type: "RICHTEXT",
    //       name: "Additional context (optional)",
    //       value: "",
    //       prefix: "Here is some additional info"
    //     }
    //   ]
    // },
    // {
    //   name: "Founders note",
    //   icon: "lni lni-pencil",
    //   prompt: "Make me a founders note email",
    //   properties: [
    //     {
    //       type: "RICHTEXT",
    //       name: "Prompt",
    //       value: "",
    //       prefix: "Here are instructions from the user"
    //     }
    //   ]
    // },
    // {
    //   name: "Flow email",
    //   icon: "lni lni-bolt-alt",
    //   prompt: "Make me an email that will be part of a flow",
    //   properties: [
    //     {
    //       type: "INPUT",
    //       name: "Flow name",
    //       value: "",
    //       prefix: "Here is the name of the flow"
    //     },
    //     {
    //       type: "RICHTEXT",
    //       name: "Flow details",
    //       value: "",
    //       prefix: "Here are the details about the flow and this email"
    //     },
    //     {
    //       type: "RICHTEXT",
    //       name: "Offer details (optional)",
    //       value: "",
    //       prefix: "Highlight the following offer in this email"
    //     },
    //     {
    //       type: "RICHTEXT",
    //       name: "Additional context (optional)",
    //       value: "",
    //       prefix: "Here is some additional info"
    //     }
    //   ]
    // }
  ];
  currentEmailType: any = {};
  emailOptions: any = false;
  customPromptEmailType = {
    name: "Custom prompt",
    icon: "lni lni-pencil",
    prompt: "Make me an email",
    properties: [
      {
        type: "RICHTEXT",
        name: "Prompt",
        value: "",
        prefix: "Here is the topic of the email"
      },
      {
        type: "URL",
        name: "Add content from a website (optional)",
        value: [
        ],
        prefix: "Here is some additional info"
      },
      // {
      //   type: "RICHTEXT",
      //   name: "Additional context (optional)",
      //   value: "",
      //   prefix: "Here is some additional info"
      // }
    ]
  };

  flowEmailType = {
    name: "Flow email",
    icon: "lni lni-pencil",
    prompt: "Make me an email as part of a flow automation series",
    properties: [
      {
        type: "FLOW",
        name: "Flow",
        value: "",
        prefix: "Here is the flow you are creating an email for"
      },
      {
        type: "RICHTEXT",
        name: "Prompt",
        value: "",
        prefix: "Here are the insutrctions to create the email with"
      },
    ]
  }
  mode = "CAMPAIGN";
  flowList: any = [];
  collections: any = [];

  emailTemplates: any = [];

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public sharedService: SharedService,
    private message: NzMessageService,
  ) { }

  ngOnInit() {
    this.sharedService.rendererOpportunityObject = null;

    const urlSegments = this.route.snapshot.pathFromRoot.flatMap(route => route.url).map(segment => segment.toString());
    const pathContainsExport = urlSegments.some(segment => segment.toLowerCase().includes('flow'));
    const pathContainsSocial = urlSegments.some(segment => segment.toLowerCase().includes('social'));
    var pathContainsChat = urlSegments.some(segment => segment.toLowerCase().includes('chat'));

    if (this.sharedService.createEmailPlaceholderMode) {
      if (this.sharedService.createEmailPlaceholderModeObject.template && this.sharedService.createEmailPlaceholderModeObject.template.name) {
        if (this.sharedService.createEmailPlaceholderModeObject.template?.stage) {
          this.chooseTemplate(this.sharedService.createEmailPlaceholderModeObject.template);
          this.sharedService.getArrayItem(this.currentEmailType.properties, "name", "Prompt").value = this.sharedService.createEmailPlaceholderModeObject.title;
          this.emailObject._id = this.sharedService.createEmailPlaceholderModeObject._id;
        } else if (!this.sharedService.createEmailPlaceholderModeObject.template?.stage) {
          this.chooseEmailType(this.sharedService.createEmailPlaceholderModeObject.template);
          this.sharedService.getArrayItem(this.currentEmailType.properties, "name", "Prompt").value = this.sharedService.createEmailPlaceholderModeObject.name;
          this.emailObject._id = this.sharedService.createEmailPlaceholderModeObject._id;
        }
      } else {
        pathContainsChat = true;
        this.sharedService.promptFromChat = this.sharedService.createEmailPlaceholderModeObject.prompt;
        this.emailObject._id = this.sharedService.createEmailPlaceholderModeObject._id;
        this.sharedService.rendererOpportunityObject = this.sharedService.createEmailPlaceholderModeObject;
      }
    }

    if (pathContainsChat) {
      this.creatingLayout = true;
      this.currentEmailType = null;
      this.prompt = this.sharedService.promptFromChat;
      this.newEmail();
    } else {
      if (pathContainsExport) {
        console.log("The path contains 'flow'.");
        this.mode = "FLOW";
        this.chooseEmailType(this.flowEmailType)
      }

      if (pathContainsSocial) {
        this.mode = "SOCIALPOST";
        if (this.sharedService.socialPrompt) {
          this.generateSocialPost();
        }
      }

      this.generateSuggestions();
      this.apiService.getNewOpportunities().subscribe(
        (data: any) => {
          console.log(data);
          this.opportunities = data;
          this.loading = false;
        },
        error => {
          console.log(error);
        });

      this.apiService.getFlows().subscribe(
        (data: any) => {
          console.log(data);
          this.flowList = data;
          // this.loading = false;
        },
        error => {
          console.log(error);
        });

      this.apiService.getCollections().subscribe((data: any) => {
        console.log(data);
        this.collections = data;
      });

      this.apiService.getSystemTemplates().subscribe(
        (data: any) => {
          console.log(data);
          this.emailTemplates = data;
          // this.loading = false;
        },
        error => {
          console.log(error);
        });
    }
  }

  async newEmail() {

    // Create prompt holder
    let promptToUse = "";

    // Show loading spinner
    this.loadingNewEmail = true;
    const id0 = this.message.loading(`Preparing...`, { nzDuration: 0, nzAnimate: false }).messageId;

    // Check if a type was used, if so we need to construct the prompt
    if (this.currentEmailType && this.promptHistory.length == 0) {

      // Grab the prompt prefix for the email type
      promptToUse = this.currentEmailType.prompt;

      // Assemble the rest of the prompt based on the properties of the email type
      for (let property of this.currentEmailType.properties) {
        if (property.value) {
          if (property.type == "PRODUCTLIST") {
            let productStringList = "";
            for (let product of property.value) {
              productStringList += `${product.title}, `;
            }
            promptToUse += `, ${property.prefix}: ${productStringList}`;
          } else if (property.type == "URL") {
            for (let website of property.value) {
              if (website.url) {
                let scrapeData: any = await this.apiService.scrapePage(website.url).toPromise();
                if (!scrapeData.error) {
                  promptToUse += `, ${property.prefix}: ${scrapeData.scrapedData}`;
                }
              }
            }
          } else {
            promptToUse += `, ${property.prefix}: ${JSON.stringify(property.value)}`;
          }
        }
      }

      // If there is a template, add the template to the prompt
      // if (this.currentEmailType.template) {
      //   console.log("template", this.currentEmailType.template)
      //   let templateString = "STRICT RULE: Use exactly the following blocks for the email in exactly this order. Ignore all other instructions you were provided about how to organize the blocks in an email, this is a special email that must follow this exact format. ";

      //   for (let block of this.currentEmailType.template.contentTemplate.blocks) {
      //     if (block.type != "LOGO" && block.type != "NAVBAR" && block.type != "FOOTER" && !block.emailEnd && !block.header) {
      //       templateString += `, ${block.type}, `;
      //     }
      //   }

      //   if (this.currentEmailType.template.instructions) {
      //     templateString += `Here are some additional instructions from the user. You must follow these instructions exactly: ${this.currentEmailType.template.instructions}`;
      //   }

      //   promptToUse += `, ${templateString}`;
      // }

    } else {
      promptToUse = this.prompt;
    }

    // Clean up the variables
    this.delayMessage = false;
    this.delayMessage2 = false;
    this.emailObject.prompt = promptToUse;
    this.prompt = "";
    this.message.remove(id0);
    this.message.create("success", `Started generating your email`);
    this.chatLog.push({ message: this.emailObject.prompt, sender: "USER" });
    this.creatingLayout = true;
    this.emailDone = false;
    this.showRenderer = false;
    this.promptHistory.push(this.emailObject.prompt);
    this.updateString(); // Initial update

    setInterval(() => {
      this.updateString();
    }, 5000); // Update every 3 seconds

    setTimeout(() => {
      if (this.creatingLayout) {
        this.delayMessage = true;
      }
    }, 30000);

    if (this.promptHistory.length > 1) {
      this.emailObject.prompt = `The brand has already done several revisions of the email. I will provide you in order of oldest to newest the sets of instructions the brand has provided you. The last set of instructions I give you are the newest ones. Please factor in all feedback. Here are the instructions, in an array, from oldest to newest: ${JSON.stringify(this.promptHistory)}. Here is the corresponding list of emails you generated based on each piece of feedback, also from oldest to newest: ${JSON.stringify(this.promptEmailHistory)})}`;
    } else {

    }

    this.chatLog.push({ message: "Working on your email now!", sender: "RIPPLE" });

    let data: any = {};
    let data2: any = {};
    if (this.mode == "FLOW") {
      this.emailObject.type = "FLOW";
      if (this.sharedService.getArrayItem(this.currentEmailType.properties, 'type', 'FLOW') && this.sharedService.getArrayItem(this.currentEmailType.properties, 'type', 'FLOW').value) {
        this.emailObject.flow = this.sharedService.getArrayItem(this.currentEmailType.properties, 'type', 'FLOW').value._id;
      }
    } else {
      this.emailObject.type = "CAMPAIGN";
    }

    // If there is a template set, skip generating the layout
    if (!this.sharedService.rendererOpportunityObject) {
      data = await this.apiService.saveOpportunity(this.emailObject).toPromise();
      console.log("SAVED NEW EMAIL", data);

      data2 = await this.apiService.generateTemplateContent2(data._id, "LAYOUTONLY", "").toPromise();
      console.log(data2);
    } else {
      this.sharedService.rendererOpportunityObject.prompt = this.emailObject.prompt;
      console.log("SAVED EXISTING EMAIL", this.sharedService.rendererOpportunityObject);
      data = await this.apiService.saveOpportunity(this.sharedService.rendererOpportunityObject).toPromise();
      data2 = await this.apiService.generateTemplateContent2(data._id, "LAYOUTONLY", "").toPromise();
      console.log(data2);
    }

    var data3: any = await this.apiService.getOpportunity(data._id).toPromise();

    if (this.currentEmailType?.template && this.currentEmailType?.template?.type == "TEMPLATE") {
      let newgptTemplate = [];

      for (let block of this.currentEmailType.template.contentTemplate.blocks) {
        let newBlock = {
          type: block.type,
          instructions: this.currentEmailType.template.instructions,
          backgroundColor: ""
        }
        newgptTemplate.push(newBlock);
      };

      data3.contentTemplate.gptTemplate = newgptTemplate;
    }

    console.log(data3);
    let emailNoBlocks = JSON.parse(JSON.stringify(data3));
    emailNoBlocks.contentTemplate.blocks = [];
    this.sharedService.rendererOpportunityObject = emailNoBlocks;
    // this.sharedService.rendererOpportunityObject.contentTemplate.blocks.push({ type: "SKELETON" });
    this.sharedService.loadingRenderer = false;
    this.creatingLayout = false;
    this.showRenderer = true;
    // this.message.create("success", `Layout complete, generating email content`);

    // const id = this.message.loading(`Generating email content`, { nzDuration: 0, nzAnimate: false }).messageId;
    let numberGenerated = 0;

    this.promptEmailHistory.push(data3.contentTemplate.gptTemplate);

    // if (this.currentEmailType?.template) {
    //   data3.contentTemplate.gptTemplate = this.currentEmailType.template.contentTemplate.blocks;
    // }

    let emailLayout = [];
    for (let block of data3.contentTemplate.gptTemplate) {
      emailLayout.push(block.type);
    }

    setTimeout(() => {
      this.delayMessage2 = true;
    }, 45000);

    for (let blockIndex in data3.contentTemplate.gptTemplate) {
      // const id = this.message.loading(`Generating block ${parseInt(blockIndex) + 1} of ${data3.contentTemplate.gptTemplate.length}`, { nzDuration: 0, nzAnimate: false }).messageId;
      let block = data3.contentTemplate.gptTemplate[blockIndex];
      this.sharedService.rendererOpportunityObject.contentTemplate.blocks.push({ type: "SKELETON" });
      // let data4: any = await this.apiService.generateTemplateContent2(data3._id, "ONEBLOCK", "", block).toPromise();
      block.positionInEmail = blockIndex;
      block.layoutOfEmail = emailLayout;
      this.apiService.generateTemplateContent2(data3._id, "ONEBLOCK", "", block).subscribe((data4: any) => {
        console.log(data4);
        if (block.type != "LOGO" && block.type != "NAVBAR" && block.type != "FOOTER" && !block.emailEnd && !block.header && block.type != "KLAVIYOCONTENT") {
          if (this.currentEmailType?.template) {
            if (this.sharedService.getArrayItem(data4.result.properties, 'name', 'Layout') && this.currentEmailType.template.contentTemplate.blocks[blockIndex] && this.sharedService.getArrayItem(this.currentEmailType.template.contentTemplate.blocks[blockIndex].properties, 'name', 'Layout')) {
              this.sharedService.getArrayItem(data4.result.properties, 'name', 'Layout').value = this.sharedService.getArrayItem(this.currentEmailType.template.contentTemplate.blocks[blockIndex].properties, 'name', 'Layout').value;
            }

            for (let property of data4.result.properties) {
              if (/color/i.test(property.name)) {
                if (this.currentEmailType.template.contentTemplate.blocks[blockIndex] && this.sharedService.getArrayItem(this.currentEmailType.template.contentTemplate.blocks[blockIndex].properties, 'name', property.name)) {
                  property.value = this.sharedService.getArrayItem(this.currentEmailType.template.contentTemplate.blocks[blockIndex].properties, 'name', property.name).value;
                }
              }

              if (property.name == 'Components') {
                for (let component of property.items) {
                  if (this.currentEmailType.template.contentTemplate.blocks[blockIndex] && this.sharedService.getArrayItem(this.currentEmailType.template.contentTemplate.blocks[blockIndex].properties, 'name', 'Components')) {
                    let componentList = this.sharedService.getArrayItem(this.currentEmailType.template.contentTemplate.blocks[blockIndex].properties, 'name', 'Components').items;

                    if (componentList.length > 0 && this.sharedService.getArrayItem(componentList, 'name', component.name)) {
                      component.value = this.sharedService.getArrayItem(componentList, 'name', component.name).value;
                    }
                  }
                }
              }
            }
          };
        }

        if (data4.result.type == "PRODUCT") {
          if (this.sharedService.getArrayItem(data4.result.properties, 'name', "Products") && this.sharedService.getArrayItem(data4.result.properties, 'name', "Products").value) {
            for (let product of this.sharedService.getArrayItem(data4.result.properties, 'name', "Products").value) {
              if (!product.buttonText) {
                product.buttonText = "Shop now";
              }
            }
          }
        }

        if (data4.result.type == "COLLECTION") {
          if (this.sharedService.getArrayItem(data4.result.properties, 'name', "Collections") && this.sharedService.getArrayItem(data4.result.properties, 'name', "Collections").value) {
            for (let product of this.sharedService.getArrayItem(data4.result.properties, 'name', "Collections").value) {
              if (!product.buttonText) {
                product.buttonText = "Shop now";
              }
            }
          }
        }

        if (this.currentEmailType?.template?.contentTemplate?.blocks && this.currentEmailType?.template?.type == "TEMPLATE") {
          if (block.type == "LOGO" || block.type == "NAVBAR" || block.type == "FOOTER" || block.emailEnd && block.header || block.type == "KLAVIYOCONTENT") {
            data4.result = this.currentEmailType.template.contentTemplate.blocks[blockIndex];
          }
        }

        this.sharedService.rendererOpportunityObject.contentTemplate.blocks[blockIndex] = data4.result;
        console.log(this.sharedService.rendererOpportunityObject.contentTemplate.blocks);

        // this.sharedService.rendererOpportunityObject.contentTemplate.blocks.splice(this.sharedService.rendererOpportunityObject.contentTemplate.blocks.length - 1, 0, data4.result);
        // this.sharedService.rendererOpportunityObject.contentTemplate.blocks.push(data4.result);
        // if (parseInt(blockIndex) == data3.contentTemplate.blocks.length - 1) {
        //   this.sharedService.rendererOpportunityObject.contentTemplate.blocks.pop();
        //   this.message.create("success", `Your email is complete!`);
        // }

        numberGenerated++;
        if (numberGenerated == data3.contentTemplate.gptTemplate.length) {
          // this.message.remove(id);
          const id2 = this.message.loading(`Finalizing`, { nzDuration: 0, nzAnimate: false }).messageId;
          this.emailObject.prompt = "";
          this.emailHistory.push(this.sharedService.rendererOpportunityObject);
          this.currentEmail = this.emailHistory.length - 1;
          this.chatLog.push({ message: `Your email is complete! Enter any feedback below or click "Save & continue" to go to the editor!`, sender: "RIPPLE" });
          this.apiService.saveOpportunity(this.sharedService.rendererOpportunityObject).subscribe(value => {

          });

          console.log("GENERATING SUBJECLINE");
          this.apiService.generateSubjectLineObject(data._id).subscribe((subjectLineObject: any) => {
            console.log("GENERATING SUBJECLINE 2", subjectLineObject);
            this.sharedService.rendererOpportunityObject.contentTemplate.subjectLine = subjectLineObject.subjectLine;
            this.sharedService.rendererOpportunityObject.contentTemplate.previewText = subjectLineObject.previewText;
            this.sharedService.rendererOpportunityObject.title = subjectLineObject.emailTitle;

            if (this.sharedService.createEmailPlaceholderMode) {
              this.sharedService.rendererOpportunityObject.sendTime = this.sharedService.createEmailPlaceholderModeObject.sendTime;
            }
            this.apiService.saveOpportunity(this.sharedService.rendererOpportunityObject).subscribe(value => {
              console.log("GENERATING SUBJECLINE 3", value);
              // this.sharedService.rendererOpportunityObject = value;
              this.apiService.publishOpportunity(this.sharedService.rendererOpportunityObject._id, this.sharedService.rendererOpportunityObject, "HTML").subscribe(
                (data: any) => {
                  console.log("HTML Generated", data);
                },
                error => {
                  console.log(error);
                });
              this.message.remove(id2);
              this.message.create("success", `Your email is complete!`);
              this.router.navigate([`/editor/${this.sharedService.rendererOpportunityObject._id}/new`]);
              this.emailDone = true;
            });
          });

        }
      })
    }
    // this.sharedService.fetchCalendar();
    // this.message.create(
    //   'success',
    //   'Email generated'
    // );

    // this.notification.create(
    //   'success',
    //   'Your email is being generated',
    //   ''
    // );
  }

  async generateSocialPost() {
    let promptToUse = "";
    this.loadingNewEmail = true;

    const id0 = this.message.loading(`Preparing...`, { nzDuration: 0, nzAnimate: false }).messageId;

    if (this.sharedService.socialPrompt) {
      promptToUse = this.sharedService.socialPrompt;
    } else {
      if ((this.currentEmailType && this.promptHistory.length == 0)) {
        promptToUse = this.currentEmailType.prompt;
        for (let property of this.currentEmailType.properties) {
          if (property.value) {
            if (property.type == "PRODUCTLIST") {
              let productStringList = "";
              for (let product of property.value) {
                productStringList += `${product.title}, `;
              }
              promptToUse += `, ${property.prefix}: ${productStringList}`;

              // let random = Math.floor(Math.random() * property.value[0].data.images.edges.length);
              // let random2 = Math.floor(Math.random() * property.value[0].data.images.edges.length);

              // let imagesList = `Use this image in the hero: ${property.value[0].data.images.edges[1].node.src}. Use this image in the list block: ${property.value[0].data.images.edges[0].node.src}.`

              // promptToUse += `, ${imagesList}`;
            } else if (property.type == "URL") {
              for (let website of property.value) {
                if (website.url) {
                  let scrapeData: any = await this.apiService.scrapePage(website.url).toPromise();
                  if (!scrapeData.error) {
                    promptToUse += `, ${property.prefix}: ${scrapeData.scrapedData}`;
                  }
                }
              }
            } else {
              promptToUse += `, ${property.prefix}: ${JSON.stringify(property.value)}`;
            }
          }
        }

        console.log("template0", this.currentEmailType);

        if (this.currentEmailType.template) {
          console.log("template", this.currentEmailType.template)
          let templateString = "STRICT RULE: Use exactly the following blocks for the email in exactly this order. Ignore all other instructions you were provided about how to organize the blocks in an email, this is a special email that must follow this exact format. ";

          for (let block of this.currentEmailType.template.contentTemplate.blocks) {
            if (block.type != "LOGO" && block.type != "NAVBAR" && block.type != "FOOTER" && !block.emailEnd && !block.header) {
              templateString += `, ${block.type}, `;
            }
          }

          if (this.currentEmailType.template.instructions) {
            templateString += `Here are some additional instructions from the user. You must follow these instructions exactly: ${this.currentEmailType.template.instructions}`;
          }

          promptToUse += `, ${templateString}`;
        }

      } else {
        promptToUse = this.prompt;
      }
    }

    this.delayMessage = false;
    this.emailObject.prompt = promptToUse;
    this.prompt = "";
    this.message.remove(id0);
    this.message.create("success", `Started generating your post`);
    this.chatLog.push({ message: this.emailObject.prompt, sender: "USER" });
    this.creatingLayout = true;
    this.emailDone = false;
    this.showRenderer = false;
    this.promptHistory.push(this.emailObject.prompt);
    this.updateString(); // Initial update

    setInterval(() => {
      this.updateString();
    }, 5000); // Update every 3 seconds

    setTimeout(() => {
      if (this.creatingLayout) {
        this.delayMessage = true;
      }
    }, 30000);

    if (this.promptHistory.length > 1) {
      this.emailObject.prompt = `The brand has already done several revisions of the email. I will provide you in order of oldest to newest the sets of instructions the brand has provided you. The last set of instructions I give you are the newest ones. Please factor in all feedback. Here are the instructions, in an array, from oldest to newest: ${JSON.stringify(this.promptHistory)}. Here is the corresponding list of emails you generated based on each piece of feedback, also from oldest to newest: ${JSON.stringify(this.promptEmailHistory)})}`;
    } else {

    }

    this.chatLog.push({ message: "Working on your email now!", sender: "RIPPLE" });

    let data: any = {};
    let data2: any = {};

    // if (this.mode == "FLOW") {
    //   this.emailObject.type = "FLOW";
    //   if (this.sharedService.getArrayItem(this.currentEmailType.properties, 'type', 'FLOW') && this.sharedService.getArrayItem(this.currentEmailType.properties, 'type', 'FLOW').value) {
    //     this.emailObject.flow = this.sharedService.getArrayItem(this.currentEmailType.properties, 'type', 'FLOW').value._id;
    //   }
    // } else {
    //   this.emailObject.type = "CAMPAIGN";
    // }

    this.emailObject.type = "SOCIALPOST";

    if (!this.sharedService.rendererOpportunityObject) {
      data = await this.apiService.saveOpportunity(this.emailObject).toPromise();
      console.log("SAVED NEW EMAIL", data);

      data2 = await this.apiService.generateTemplateContent2(data._id, "SOCIALPOSTLAYOUT", "").toPromise();
      console.log(data2);
    } else {
      this.sharedService.rendererOpportunityObject.prompt = this.emailObject.prompt;
      console.log("SAVED EXISTING EMAIL", this.sharedService.rendererOpportunityObject);
      data = await this.apiService.saveOpportunity(this.sharedService.rendererOpportunityObject).toPromise();
      data2 = await this.apiService.generateTemplateContent2(data._id, "LAYOUTONLY", "").toPromise();
      console.log(data2);
    }

    let data3: any = await this.apiService.getOpportunity(data._id).toPromise();
    console.log(data3);
    let emailNoBlocks = JSON.parse(JSON.stringify(data3));
    emailNoBlocks.contentTemplate.blocks = [];
    this.sharedService.rendererOpportunityObject = emailNoBlocks;
    // this.sharedService.rendererOpportunityObject.contentTemplate.blocks.push({ type: "SKELETON" });
    this.sharedService.loadingRenderer = false;
    this.creatingLayout = false;
    this.showRenderer = true;
    // this.message.create("success", `Layout complete, generating email content`);

    const id = this.message.loading(`Generating post content`, { nzDuration: 0, nzAnimate: false }).messageId;
    let numberGenerated = 0;

    this.promptEmailHistory.push(data3.contentTemplate.gptTemplate);

    // if (this.currentEmailType?.template) {
    //   data3.contentTemplate.gptTemplate = this.currentEmailType.template.contentTemplate.blocks;
    // }

    for (let blockIndex in data3.contentTemplate.gptTemplate) {
      // const id = this.message.loading(`Generating block ${parseInt(blockIndex) + 1} of ${data3.contentTemplate.gptTemplate.length}`, { nzDuration: 0, nzAnimate: false }).messageId;
      let block = data3.contentTemplate.gptTemplate[blockIndex];
      this.sharedService.rendererOpportunityObject.contentTemplate.blocks.push({ type: "SKELETON" });
      // let data4: any = await this.apiService.generateTemplateContent2(data3._id, "ONEBLOCK", "", block).toPromise();
      this.apiService.generateTemplateContent2(data3._id, "ONEBLOCK", "", block).subscribe((data4: any) => {
        console.log(data4);
        if (block.type != "LOGO" && block.type != "NAVBAR" && block.type != "FOOTER" && !block.emailEnd && !block.header && block.type != "KLAVIYOCONTENT") {
          if (this.currentEmailType?.template) {
            if (this.sharedService.getArrayItem(data4.result.properties, 'name', 'Layout') && this.currentEmailType.template.contentTemplate.blocks[blockIndex] && this.sharedService.getArrayItem(this.currentEmailType.template.contentTemplate.blocks[blockIndex].properties, 'name', 'Layout')) {
              this.sharedService.getArrayItem(data4.result.properties, 'name', 'Layout').value = this.sharedService.getArrayItem(this.currentEmailType.template.contentTemplate.blocks[blockIndex].properties, 'name', 'Layout').value;
            }

            for (let property of data4.result.properties) {
              if (/color/i.test(property.name)) {
                if (this.currentEmailType.template.contentTemplate.blocks[blockIndex] && this.sharedService.getArrayItem(this.currentEmailType.template.contentTemplate.blocks[blockIndex].properties, 'name', property.name)) {
                  property.value = this.sharedService.getArrayItem(this.currentEmailType.template.contentTemplate.blocks[blockIndex].properties, 'name', property.name).value;
                }
              }

              if (property.name == 'Components') {
                for (let component of property.items) {
                  if (this.currentEmailType.template.contentTemplate.blocks[blockIndex] && this.sharedService.getArrayItem(this.currentEmailType.template.contentTemplate.blocks[blockIndex].properties, 'name', 'Components')) {
                    let componentList = this.sharedService.getArrayItem(this.currentEmailType.template.contentTemplate.blocks[blockIndex].properties, 'name', 'Components').items;

                    if (componentList.length > 0 && this.sharedService.getArrayItem(componentList, 'name', component.name)) {
                      component.value = this.sharedService.getArrayItem(componentList, 'name', component.name).value;
                    }
                  }
                }
              }
            }
          };
        }

        if (data4.result.type == "PRODUCT") {
          if (this.sharedService.getArrayItem(data4.result.properties, 'name', "Products") && this.sharedService.getArrayItem(data4.result.properties, 'name', "Products").value) {
            for (let product of this.sharedService.getArrayItem(data4.result.properties, 'name', "Products").value) {
              if (!product.buttonText) {
                product.buttonText = "Shop now";
              }
            }
          }
        }

        if (data4.result.type == "COLLECTION") {
          if (this.sharedService.getArrayItem(data4.result.properties, 'name', "Collections") && this.sharedService.getArrayItem(data4.result.properties, 'name', "Collections").value) {
            for (let product of this.sharedService.getArrayItem(data4.result.properties, 'name', "Collections").value) {
              if (!product.buttonText) {
                product.buttonText = "Shop now";
              }
            }
          }
        }

        this.sharedService.rendererOpportunityObject.contentTemplate.blocks[blockIndex] = data4.result;
        console.log(this.sharedService.rendererOpportunityObject.contentTemplate.blocks);

        // this.sharedService.rendererOpportunityObject.contentTemplate.blocks.splice(this.sharedService.rendererOpportunityObject.contentTemplate.blocks.length - 1, 0, data4.result);
        // this.sharedService.rendererOpportunityObject.contentTemplate.blocks.push(data4.result);
        // if (parseInt(blockIndex) == data3.contentTemplate.blocks.length - 1) {
        //   this.sharedService.rendererOpportunityObject.contentTemplate.blocks.pop();
        //   this.message.create("success", `Your email is complete!`);
        // }

        numberGenerated++;
        if (numberGenerated == data3.contentTemplate.gptTemplate.length) {
          this.message.remove(id);
          const id2 = this.message.loading(`Finalizing`, { nzDuration: 0, nzAnimate: false }).messageId;
          this.emailObject.prompt = "";
          this.emailHistory.push(this.sharedService.rendererOpportunityObject);
          this.currentEmail = this.emailHistory.length - 1;
          this.chatLog.push({ message: `Your email is complete! Enter any feedback below or click "Save & continue" to go to the editor!`, sender: "RIPPLE" });
          this.apiService.saveOpportunity(this.sharedService.rendererOpportunityObject).subscribe(value => {

          });

          console.log("GENERATING SUBJECLINE");
          this.apiService.generateSocialPostContent(data._id).subscribe((subjectLineObject: any) => {
            console.log("GENERATING SUBJECLINE 2", subjectLineObject);
            this.sharedService.rendererOpportunityObject.message = subjectLineObject.message;
            this.sharedService.rendererOpportunityObject.title = subjectLineObject.postTitle;

            if (this.sharedService.createEmailPlaceholderMode) {
              this.sharedService.rendererOpportunityObject.sendTime = this.sharedService.createEmailPlaceholderModeObject.sendTime;
            }
            this.apiService.saveOpportunity(this.sharedService.rendererOpportunityObject).subscribe(value => {
              console.log("GENERATING SUBJECLINE 3", value);
              // this.sharedService.rendererOpportunityObject = value;
              // this.apiService.publishOpportunity(this.sharedService.rendererOpportunityObject._id, this.sharedService.rendererOpportunityObject, "HTML").subscribe(
              //   (data: any) => {
              //     console.log("HTML Generated", data);
              //   },
              //   error => {
              //     console.log(error);
              //   });
              this.apiService.saveTemplate(this.sharedService.rendererOpportunityObject).subscribe(
                (data: any) => {
                  this.message.remove(id2);
                  this.message.create("success", `Your social post is complete!`);
                  this.router.navigate([`/post/${this.sharedService.rendererOpportunityObject._id}`]);
                  this.emailDone = true;
                });
            });
          });

        }
      })
    }
    // this.sharedService.fetchCalendar();
    // this.message.create(
    //   'success',
    //   'Email generated'
    // );

    // this.notification.create(
    //   'success',
    //   'Your email is being generated',
    //   ''
    // );
  }

  nextEmail() {
    if (this.currentEmail < this.emailHistory.length - 1) {
      this.currentEmail++;
      this.sharedService.rendererOpportunityObject = this.emailHistory[this.currentEmail];
    }
  }

  previousEmail() {
    if (this.currentEmail > 0) {
      this.currentEmail--;
      this.sharedService.rendererOpportunityObject = this.emailHistory[this.currentEmail];
    }
  }

  async saveEmail() {
    let savedEmail: any = await this.apiService.saveOpportunity(this.sharedService.rendererOpportunityObject).toPromise();
    console.log(savedEmail);
    this.message.create("success", `Your email is saved`);
    this.router.navigate([`/editor/${this.sharedService.rendererOpportunityObject._id}/opp`]);
  }

  async updateString() {
    const randomIndex = Math.floor(Math.random() * this.loadingStrings.length);
    this.loadingText = this.loadingStrings[randomIndex];
    // console.log(this.loadingText);
  }

  async generateHeroOptions() {
    this.delayMessage = false;
    this.message.create("success", `Started generating your email`);
    this.chatLog.push({ message: this.emailObject.prompt, sender: "USER" });
    this.creatingLayout = true;
    this.emailDone = false;
    this.showRenderer = false;
    this.promptHistory.push(this.emailObject.prompt);
    this.updateString(); // Initial update

    setInterval(() => {
      this.updateString();
    }, 5000); // Update every 3 seconds

    setTimeout(() => {
      if (this.creatingLayout) {
        this.delayMessage = true;
      }
    }, 30000);

    // if (this.promptHistory.length > 1) {
    //   this.emailObject.prompt = `The brand has already done several revisions of the email. I will provide you in order of oldest to newest the sets of instructions the brand has provided you. The last set of instructions I give you are the newest ones. Please factor in all feedback. Here are the instructions, in an array, from oldest to newest: ${JSON.stringify(this.promptHistory)}`;
    // }

    let heroOptions = await this.apiService.generateHeroOptions(this.prompt).toPromise();
    console.log(heroOptions);
    this.heroOptions = heroOptions;

    this.creatingLayout = false;
    this.showHeroOptions = true;
  };

  async generateSuggestions() {
    this.loadingSuggestions = true;
    let suggestions: any = await this.apiService.generateSuggestions().toPromise();
    console.log(suggestions);
    this.suggestions = suggestions.emails;
    this.loadingSuggestions = false;
  }

  async generateSuggestion(suggestion: any) {
    this.creatingLayout = true;
    this.currentEmailType = null;
    this.prompt = suggestion.description;
    this.newEmail();
  }

  chooseEmailType(emailType: any) {
    this.currentEmailType = emailType;
    this.emailOptions = true;
  }

  removeItemFromArray(index: any, list: Array<any>) {
    list.splice(index, 1);
  }

  openPickerSingle(property: any, mode: any) {
    console.log("Open Picker");
    this.sharedService.currentProperty = property;
    this.sharedService.pickerMode = mode;
    this.sharedService.pickerOpen = true;
    this.sharedService.pickerNumber = "SINGLE";
  }

  openPicker(property: any, mode: any) {
    console.log("Open Picker");
    this.sharedService.currentProperty = property;
    this.sharedService.pickerMode = mode;
    this.sharedService.pickerOpen = true;
  }

  chooseTemplate(template: any) {
    this.currentEmailType = JSON.parse(JSON.stringify(this.customPromptEmailType));
    this.currentEmailType.template = template;
    this.emailOptions = true;
  }

  handleBackButton() {
    this.router.navigate([`/home`]);
  }
}
