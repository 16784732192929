<div class="custom-modal-header">
    <div class="custom-modal-title">Collections</div>
    <div class="custom-modal-close" (click)="close()"><span nz-icon nzType="close" nzTheme="outline"></span></div>
</div>
<div class="custom-modal-body">
    <ng-container *ngIf="this.loading">
        <div nz-row [nzGutter]="[12, 12]">
            <div nz-col class="gutter-row" [nzSpan]="4">
                <div class="custom-card">
                    <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                    <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                        <div class="picker-item-title"></div>
                    </div>
                </div>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <div class="custom-card">
                    <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                    <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                        <div class="picker-item-title"></div>
                    </div>
                </div>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <div class="custom-card">
                    <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                    <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                        <div class="picker-item-title"></div>
                    </div>
                </div>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <div class="custom-card">
                    <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                    <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                        <div class="picker-item-title"></div>
                    </div>
                </div>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <div class="custom-card">
                    <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                    <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                        <div class="picker-item-title"></div>
                    </div>
                </div>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="4">
                <div class="custom-card">
                    <nz-skeleton-element nzType="image" [nzActive]="true" class="picker"></nz-skeleton-element>
                    <div class="custom-card-footer mini" style="padding: 8px; text-align: left; height: 39px">
                        <div class="picker-item-title"></div>
                    </div>
                </div>
            </div>
        </div>

    </ng-container>

    <div id="goUp"></div>
    <ng-container *ngIf="!this.loading">
        <div nz-row [nzGutter]="[12, 12]">
            <ng-container *ngFor="let opportunity of opportunities; index as index">
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <div class="custom-card hover" (click)="setSelectedObject(opportunity)"
                        [class.selected]="this.sharedService.getArrayItem(this.selectedObjects, '_id', opportunity._id)">
                        <img class="picker-item-image" [src]="opportunity.data.imageUrl">
                        <ng-container *ngIf="!opportunity.data.imageUrl">
                            <div class="email-thumbnail"
                                style="background: #f9f9f9; text-align: center; font-size: 48px; padding-top: 50px; color: #d9d9d9; height: 164px;">
                                <i class="lni lni-image"></i>
                            </div>
                        </ng-container>
                        <div class="custom-card-footer mini" style="padding: 8px 16px; text-align: left;">
                            <div class="picker-item-title" style="font-weight: 500;">{{opportunity.data.title}}</div>
                            <span class="picker-item-title"
                                style="font-weight: 500;">{{opportunity.data.productsCount}}</span> products
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>
<div class="custom-modal-footer">
    <button nz-button nzType="primary" (click)="setPropertyValue()">Confirm</button>
</div>