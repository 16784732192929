import { NgModule, createComponent } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ConnectionsComponent } from './home/connections/connections.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { SettingsComponent } from './home/settings/settings.component';
import { NewConnectionComponent } from './home/new-connection/new-connection.component';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { ConnectionViewComponent } from './home/connection-view/connection-view.component';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ApiService } from './api.service';
import { JwtInterceptorProvider } from './helpers/jwt.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { OpportunitiesNewComponent } from './opportunities-new/opportunities-new.component';
import { OpportunitiesPendingComponent } from './opportunities-pending/opportunities-pending.component';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { OpportunityViewComponent } from './opportunity-view/opportunity-view.component';
import { OpportunitiesLiveComponent } from './opportunities-live/opportunities-live.component';
import { AccountCreationComponent } from './account-creation/account-creation.component';
import { IntroComponent } from './account-creation/intro/intro.component';
import { ConfirmDetailsComponent } from './account-creation/confirm-details/confirm-details.component';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { StaffComponent } from './staff/staff.component';
import { StaffInboxComponent } from './staff/staff-inbox/staff-inbox.component';
import { StaffOpportunitiesComponent } from './staff/staff-opportunities/staff-opportunities.component';
import { StaffAccountsComponent } from './staff/staff-accounts/staff-accounts.component';
import { KlaviyoSetupComponent } from './account-creation/klaviyo-setup/klaviyo-setup.component';
import { CompletingSetupComponent } from './account-creation/completing-setup/completing-setup.component';
import { EmailEditorComponent } from './email-editor/email-editor.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { SelectLogoComponent } from './account-creation/select-logo/select-logo.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { SelectColorsComponent } from './account-creation/select-colors/select-colors.component';
import { AboutScrapeComponent } from './account-creation/about-scrape/about-scrape.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { EmailCampaignsComponent } from './email-campaigns/email-campaignscomponent';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzMessageModule } from 'ng-zorro-antd/message';

import {DragDropModule} from '@angular/cdk/drag-drop';
import { OpportunitiesAllComponent } from './opportunities-all/opportunities-all.component';
import { createOpportunityComponent } from './create-opportunity/create-opportunity.component';
import { PickerComponent } from './picker/picker.component';
import { NgxColorsModule } from 'ngx-colors';
import { BlockLibraryComponent } from './block-library/block-library.component';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NgOptimizedImage } from '@angular/common'
import { SegmentsComponent } from './segments/segments.component';
import { SmsComponent } from './sms/sms.component';
import { SettingsBrandComponent } from './home/settings-brand/settings-brand.component';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { DatabaseComponent } from './home/database/database.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { CalendarComponent } from './calendar/calendar.component';
import { createOppNewComponent } from './create-opp-new/create-opp-new..component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { SettingsVoiceComponent } from './home/settings-voice/settings-voice.component';import { SettingsIntegtationsComponent } from './home/settings-integrations/settings-integrations.component';
import { IntegrationViewComponent } from './home/integration-view/integration-view.component';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { LoggedInComponent } from './logged-in/logged-in.component';
import { WorkspaceComponent } from './workspace/workspace.component';
import { EmailRendererComponent } from './email-renderer/email-renderer.component';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { DatabaseEditorComponent } from './database-editor/database-editor.component';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { CalendarPlanningComponent } from './calendar-planning/calendar-planning.component';
import { emailChatComponent } from './email-chat/email-chat.component';
import { SettingsAccountComponent } from './home/settings-account/settings-account.component';
import { SettingsTeamComponent } from './home/settings-team/settings-team.component';
import { InviteUserComponent } from './invite-user/invite-user.component';
import { ViewHtmlComponent } from './view-html/view-html.component';
import { QuillModule } from 'ngx-quill'
import { ObjectivesComponent } from './objectives/objectives.component';
import { MixpanelService } from './mixpanel.service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { EditImageComponent } from './edit-image/edit-image.component';
import { EditImageBackgroundComponent } from './edit-image-background/edit-image-background.component';
import { EmailFlowsComponent } from './email-flows/email-flows.component';
import { FlowViewComponent } from './flow-view/flow-view.component';
import { CreateComponent } from './create/create.component';
import { CreateFlowComponent } from './create-flow/create-flow.component';
import { ReviewPickerComponent } from './review-picker/review-picker.component';
import { CreateFlowFolderComponent } from './create-flow-folder/create-flow-folder.component';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { SendTestEmailComponent } from './send-test-email/send-test-email.component';
import { DynamicCssLoaderService } from './dynamic-css-loader.service';
import { CollectionPickerComponent } from './collection-picker/collection-picker.component';
import { CreateTemplateComponent } from './create-template/create-template.component';
import { ContentSettingsComponent } from './home/content/content.component';
import { ContentTemplatesComponent } from './content-templates/content-templates.component';
import { AcceptWorkspaceInviteComponent } from './accept-workspace-invite/accept-workspace-invite.component';
import { CreateWorkspaceComponent } from './account-creation/create-workspace/create-workspace.component';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { MarkdownModule } from 'ngx-markdown';
import { ContentProductsComponent } from './content-products/content-products.component';
import { ProductViewComponent } from './home/product-view/product-view.component';
import { ContentMediaComponent } from './content-media/content-media.component';
import { ContentTemplatesViewComponent } from './content-templates-view/content-templates-view.component';
import { ContentBlocksComponent } from './content-blocks/content-blocks.component';
import { SMSViewComponent } from './sms-view/sms-view.component';
import { CreateSMSComponent } from './create-sms/create-sms.component';
import { SocialPostViewComponent } from './social-post-view/social-post-view.component';
import { FacebookService } from './facebook.service';
import { CreateFromTemplateComponent } from './create-from-template/create-from-template.component';
import { BlockBuilderComponent } from './block-builder/block-builder.component';
import { ComponentRendererComponent } from './component-renderer/component-renderer.component';
import { PropertyRendererComponent } from './property-renderer/property-renderer.component';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { ContentEditorComponent } from './content-editor/content-editor.component';
import { TemplateLibraryComponent } from './template-library/template-library.component';
import { FillTemplateComponent } from './fill-template/fill-template.component';
import { BlockLibraryV2Component } from './block-library-v2/block-library-v2.component';
import { EmailEditorV2Component } from './email-editor-v2/email-editor-v2.component';
import { EmailRendererV2Component } from './email-renderer-v2/email-renderer-v2.component';
import { createOpportunityV2Component } from './create-opportunity-v2/create-opportunity-v2.component';
import { HomeV2Component } from './home-v2/home-v2.component';
import { ComponentRendererV2Component } from './component-renderer-2/component-renderer-2.component';
import { EmailEditorV3Component } from './email-editor-v3/email-editor-v3.component';
import { EmailRendererV3Component } from './email-renderer-v3/email-renderer-v3.component';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { createOpportunityV3Component } from './create-opportunity-v3/create-opportunity-v3.component';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ConnectionsComponent,
    SettingsComponent,
    NewConnectionComponent,
    ConnectionViewComponent,
    LoginComponent,
    RegisterComponent,
    OpportunitiesNewComponent,
    OpportunitiesPendingComponent,
    OpportunityViewComponent,
    OpportunitiesLiveComponent,
    AccountCreationComponent,
    IntroComponent,
    ConfirmDetailsComponent,
    StaffComponent,
    StaffInboxComponent,
    StaffOpportunitiesComponent,
    StaffAccountsComponent,
    KlaviyoSetupComponent,
    CompletingSetupComponent,
    EmailEditorComponent,
    SelectLogoComponent,
    SelectColorsComponent,
    AboutScrapeComponent,
    EmailCampaignsComponent,
    OpportunitiesAllComponent,
    createOpportunityComponent,
    PickerComponent,
    BlockLibraryComponent,
    SegmentsComponent,
    SmsComponent,
    SettingsBrandComponent,
    DatabaseComponent,
    CalendarComponent,
    createOppNewComponent,
    SettingsVoiceComponent,
    SettingsIntegtationsComponent,
    IntegrationViewComponent,
    LoggedInComponent,
    WorkspaceComponent,
    EmailRendererComponent,
    DatabaseEditorComponent,
    CalendarPlanningComponent,
    emailChatComponent,
    SettingsAccountComponent,
    SettingsTeamComponent,
    InviteUserComponent,
    ViewHtmlComponent,
    ObjectivesComponent,
    EditImageComponent,
    EditImageBackgroundComponent,
    EmailFlowsComponent,
    FlowViewComponent,
    CreateComponent,
    CreateFlowComponent,
    ReviewPickerComponent,
    CreateFlowFolderComponent,
    SendTestEmailComponent,
    CollectionPickerComponent,
    CreateTemplateComponent,
    ContentSettingsComponent,
    ContentTemplatesComponent,
    AcceptWorkspaceInviteComponent,
    CreateWorkspaceComponent,
    ChatbotComponent,
    ContentProductsComponent,
    ProductViewComponent,
    ContentMediaComponent,
    ContentTemplatesViewComponent,
    ContentBlocksComponent,
    SMSViewComponent,
    CreateSMSComponent,
    SocialPostViewComponent,
    CreateFromTemplateComponent,
    BlockBuilderComponent,
    ComponentRendererComponent,
    PropertyRendererComponent,
    ContentEditorComponent,
    TemplateLibraryComponent,
    FillTemplateComponent,
    BlockLibraryV2Component,
    EmailEditorV2Component,
    EmailRendererV2Component,
    createOpportunityV2Component,
    HomeV2Component,
    ComponentRendererV2Component,
    EmailEditorV3Component,
    EmailRendererV3Component,
    createOpportunityV3Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzPageHeaderModule,
    NzButtonModule,
    NzTableModule,
    NzMenuModule,
    NzDropDownModule,
    NzTypographyModule,
    NzFormModule,
    NzInputModule,
    NzStatisticModule,
    NzCardModule,
    NzBreadCrumbModule,
    NzIconModule,
    NzTimelineModule,
    NzStepsModule,
    NzSkeletonModule,
    NzCollapseModule,
    NzTagModule,
    NzAlertModule,
    NzDividerModule,
    NzToolTipModule,
    NzSelectModule,
    NzTabsModule,
    NzTimelineModule,
    NzDrawerModule,
    NzNotificationModule,
    DragDropModule,
    NzPopoverModule,
    NzModalModule,
    NgxColorsModule,
    NzSliderModule,
    NzInputNumberModule,
    NzRadioModule,
    NzPaginationModule,
    NzProgressModule,
    NgxJsonViewerModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzSpinModule,
    NzMessageModule,
    NzSwitchModule,
    NzSegmentedModule,
    NzPopconfirmModule,
    NzDividerModule,
    QuillModule.forRoot(),
    ImageCropperModule,
    NzEmptyModule,
    MarkdownModule.forRoot(),
    NzTreeModule,
    NzCheckboxModule
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    ApiService,
    AuthGuard,
    MixpanelService,
    JwtInterceptorProvider,
    DynamicCssLoaderService,
    FacebookService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
