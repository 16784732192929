<div class="page-wrapper">
    <div class="left-column">
        <nz-tabset style="height:100%;">
            <nz-tab nzTitle="Tree">
                <nz-tree #nzTreeComponent [nzData]="block.components" (nzClick)="clickTree($event)"></nz-tree>
            </nz-tab>
            <nz-tab nzTitle="Editor">
                <ng-container *ngIf="this.sharedService.activeComponent">
                    <ng-container *ngFor="let property of this.sharedService.activeComponent.properties">
                        <app-property-renderer [data]="property" [mode]="'sidebar'"></app-property-renderer>
                    </ng-container>
                </ng-container>
            </nz-tab>
            <nz-tab nzTitle="JSON" style="height:100%;">
                <textarea [(ngModel)]="this.jsonString" class="code-block" nz-input
                    style="height: calc(100% - 40px);"></textarea>
                <button nz-button nzType="primary" (click)="updateNestedData()"
                    style="margin-top: 8px; float: right;">Update</button>
            </nz-tab>
        </nz-tabset>
    </div>
    <div class="right-column">
        <div class="toolbar">
            <ng-container *ngIf="this.sharedService.activeComponent">
                <ng-container *ngIf="this.sharedService.activeComponent.type == 'DATACONTAINER'">
                    <ng-container *ngIf="this.sharedService.activeComponent.source == 'PRODUCTS'">
                        <button nz-button style="margin-right: 8px;" (click)="this.sharedService.openPicker(this.sharedService.activeComponent, 'PRODUCTS', 'MULTI')">Change products</button>
                    </ng-container>
                </ng-container>
                <ng-container *ngFor="let property of this.sharedService.activeComponent.properties">
                    <ng-container *ngIf="property.toolbar">
                        <app-property-renderer [data]="property" [mode]="'toolbar'" style="margin-right: 8px;"></app-property-renderer>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
        <div class="block-renderer">
            <ng-container *ngFor="let component of block.components">
                <app-component-renderer [data]="component"
                    (click)="this.sharedService.setActiveComponent($event, component)"></app-component-renderer>
                <!-- <ng-container *ngIf="component.type == 'TEXT'">
                   <div [style.paddingTop]="component.properties.paddingTop" [style.paddingBottom]="component.properties.paddingBottom" [ngStyle]="component.properties.styles">{{component.value}}</div>
                </ng-container>
                <ng-container *ngIf="component.type == 'BUTTON'">
                    <div [style.paddingTop]="component.properties.paddingTop" [style.paddingBottom]="component.properties.paddingBottom" [ngStyle]="component.properties.styles">{{component.value}}</div>
                 </ng-container>
                 <ng-container *ngIf="component.type == 'IMAGE'">
                    <div [style.paddingTop]="component.properties.paddingTop" [style.paddingBottom]="component.properties.paddingBottom" [ngStyle]="component.properties.styles">{{component.value}}</div>
                 </ng-container> -->
            </ng-container>
        </div>
    </div>
</div>