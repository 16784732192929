import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SharedService } from '../shared.service';
import { NgOptimizedImage } from '@angular/common'
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-review-picker',
  templateUrl: './review-picker.component.html',
  styleUrls: ['./review-picker.component.scss']
})
export class ReviewPickerComponent {

  opportunities: any = [];
  loading = true;
  selectedObject: any = {};
  imagesLoaded = false;
  imageSearchTerm = "";
  searchLoading = false;
  currentPage = 1;
  txtQueryChanged: Subject<string> = new Subject<string>();
  uploading = false;
  base64textString = "";
  base64FileArray: any = [];
  totalItems: any = 0;
  selectedObjects: any = [];
  brandImages: any = [];
  mediaCategory: any = "BRAND";
  productStatus: any = "ACTIVE";
  productStock: any = "INSTOCK";
  reviewSnippets: any = [];
  tagList: any = [];
  currentTag: any = "";
  filteredReviews: any = [];
  mode: any = "DEFAULT";
  searchedReviews: any = [];

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public sharedService: SharedService,
    public notification: NzNotificationService,
    private message: NzMessageService
  ) {

    this.txtQueryChanged
      .pipe(
        debounceTime(500), // wait 1 sec after the last event before emitting last event
        distinctUntilChanged() // only emit if value is different from previous value
      )
      .subscribe(model => {
        this.imageSearchTerm = model;

        if (this.imageSearchTerm == "" || this.imageSearchTerm == null || this.imageSearchTerm == " ") {
          this.mode = "DEFAULT";
        } else {
          this.mode = "SEARCH";
          this.currentTag = "";
          console.log(this.imageSearchTerm);
          this.searchedReviews = [];
          this.searchedReviews = this.reviewSnippets.filter((obj: any) => {
            return obj.data.snippet.toLowerCase().includes(this.imageSearchTerm.toLowerCase());
          });
          console.log(this.searchedReviews);
        }
      });
  }

  ngOnInit() {
    this.apiService.getReviewSnippets().subscribe(
      (data: any) => {
        console.log(data);
        this.reviewSnippets = data;
        this.loading = false;

        // Calculate frequency of each tag
        let tagFrequency: any = {};
        this.reviewSnippets.forEach((obj: any) => {

          if (obj?.data?.tags) {
            obj.data.tags.forEach((tag: any) => {
              if (tagFrequency[tag]) {
                tagFrequency[tag] += 1;
              } else {
                tagFrequency[tag] = 1;
              }
            });
          }
        });

        // Sort tags by frequency, then alphabetically
        const sortedTags = Object.entries(tagFrequency).sort((a: any, b: any) => {
          // If frequencies are equal, sort alphabetically
          if (b[1] === a[1]) {
            return a[0].localeCompare(b[0]);
          }
          // Otherwise, sort by frequency
          return b[1] - a[1];
        });

        // Extract just the tag names, now sorted by frequency and then alphabetically
        let sortedTagNames = sortedTags.map(tag => tag[0]);

        console.log(sortedTagNames);
        this.tagList = sortedTagNames;
      },
      error => {
        console.log(error);
      });
  }

  setSelectedObject(object: any) {
    if (this.sharedService.pickerNumber == "SINGLE") {
      this.selectedObjects = [object];
    } else {
      if (this.sharedService.getArrayItem(this.selectedObjects, "_id", object._id)) {

      } else {
        this.selectedObjects.push(object);
      }
    }
  }

  setPropertyValue() {
    this.sharedService.setPropertyValue(this.selectedObjects);
    this.sharedService.pickerOpen = false;
  }

  close() {
    this.sharedService.reviewPickerOpen = false;
  }

  pageIndexChange(event: any) {
    console.log(event);
    this.currentPage = event;

    if (this.sharedService.pickerMode == "PRODUCTS") {
      this.loading = true;
      this.apiService.getProductsPage(this.currentPage).subscribe(
        (data: any) => {
          console.log(data);
          this.opportunities = data.products;
          this.loading = false;
        },
        error => {
          console.log(error);
        });
    }

    if (this.sharedService.pickerMode == "MEDIA") {
      this.loading = true;
      this.apiService.getMediaPage(this.currentPage, this.mediaCategory).subscribe(
        (data: any) => {
          console.log(data);
          this.loading = false;
          this.opportunities = data.images;
        },
        error => {
          console.log(error);
        });
    }

    const element: any = document.querySelector('#goUp');
    element.scrollIntoView();
  }

  allImagesLoaded() {
    console.log("All images loaded");
    this.loading = false;
  }

  onSearchChange(query: string) {
    this.txtQueryChanged.next(query);
  }

  searchMedia() {
    this.loading = true;
    this.opportunities = [];

    if (this.sharedService.pickerMode == "MEDIA") {
      if (this.imageSearchTerm == "") {
        this.changeMediaCategory("ALL");
      } else {
        this.mediaCategory = "ALL";
        this.apiService.searchMedia(this.imageSearchTerm).subscribe(
          (data: any) => {
            console.log(data);
            this.opportunities = data;
            this.loading = false;
          },
          error => {
            console.log(error);
          });
      }
    }

    if (this.sharedService.pickerMode == "PRODUCTS") {
      if (this.imageSearchTerm == "") {
        this.apiService.getProducts().subscribe(
          (data: any) => {
            console.log(data);
            this.opportunities = data;
            this.loading = false;
          },
          error => {
            console.log(error);
          });
      } else {
        this.apiService.searchProducts(this.imageSearchTerm).subscribe(
          (data: any) => {
            console.log(data);
            this.opportunities = data;
            this.loading = false;
          },
          error => {
            console.log(error);
          });
      }
    }
  }

  chooseFiles() {

  }

  async handleFileSelect(evt: any) {
    this.base64FileArray = [];
    var files = evt.target.files;
    var file = files[0];

    this.uploading = true;
    // this.notification.create(
    //   'success',
    //   'Your images are being uploaded!',
    //   ''
    // );

    if (files.length > 0) {
      for (let file of files) {
        var reader = await new FileReader();

        // reader.onload = this._handleReaderLoaded.bind(this);
        reader.onload = (readerEvt: any) => {
          var binaryString = readerEvt.target.result;
          let base64 = btoa(binaryString);
          let base64FileArray = [`data:image/png;base64, ${base64}`];
          console.log(readerEvt);
          this.uploadImage(base64FileArray, file.name);
          // console.log(btoa(binaryString));
        }

        await reader.readAsBinaryString(file);
      }
    }

  }

  // async _handleReaderLoaded(readerEvt: any) {
  //   var binaryString = readerEvt.target.result;
  //   let base64 = await btoa(binaryString);
  //   let base64FileArray = [`data:image/png;base64, ${base64}`];
  //   console.log(readerEvt);
  //   this.uploadImage(base64FileArray);
  //   // console.log(btoa(binaryString));
  // }

  uploadImage(base64FileArray: any, fileName: any) {
    console.log(base64FileArray);
    this.apiService.uploadImages(base64FileArray, fileName).subscribe(value => {
      this.loading = true;
      // this.apiService.getMedia().subscribe(
      //   (data: any) => {
      //     console.log(data);
      //     this.opportunities = data;
      //     this.loading = false;
      //   },
      //   error => {
      //     console.log(error);
      //   });
      this.currentPage = 1;
      this.apiService.getMediaPage(this.currentPage, this.mediaCategory).subscribe(
        (data: any) => {
          console.log(data);
          this.opportunities = data.images;
          this.totalItems = data.imagesCount;
          this.mediaCategory = "ALL";
          this.loading = false;
          this.uploading = false;
        },
        error => {
          console.log(error);
        });
    });
  }

  changeMediaCategory(category: any) {
    this.loading = true;
    this.mediaCategory = category;
    this.apiService.getMediaPage(this.currentPage, this.mediaCategory).subscribe(
      (data: any) => {
        console.log(data);
        this.opportunities = data.images;
        this.totalItems = data.imagesCount;
        this.loading = false;
      },
      error => {
        console.log(error);
      });
  }

  deleteFile(file: any) {
    this.apiService.deleteFile(file).subscribe(
      (data: any) => {
        console.log(data);
        this.opportunities = this.opportunities.filter((item: any) => item._id != file);

        // this.loading = true;
        // this.apiService.getMediaPage(this.currentPage, this.mediaCategory).subscribe(
        //   (data: any) => {
        //     console.log(data);
        //     this.opportunities = data.images;
        //     this.totalItems = data.imagesCount;
        //     this.loading = false;
        //     this.message.create("success", `Image deleted`);
        //   },
        //   error => {
        //     console.log(error);
        //   });
      },
      error => {
        console.log(error);
      });
  }

  setReview() {
    if (this.sharedService.currentBlock && this.sharedService.currentBlock.type) {
      console.log(this.sharedService.currentBlock);
      this.sharedService.getArrayItem(this.sharedService.currentBlock.properties, "name", "Quote").value = this.replaceInnerMostContent(this.sharedService.getArrayItem(this.sharedService.currentBlock.properties, "name", "Quote").value, this.selectedObjects[0].data.snippet);
      this.sharedService.getArrayItem(this.sharedService.currentBlock.properties, "name", "Name").value = this.replaceInnerMostContent(this.sharedService.getArrayItem(this.sharedService.currentBlock.properties, "name", "Name").value, this.selectedObjects[0].data.reviewer);
      this.sharedService.reviewPickerOpen = false;
    }

    if (this.sharedService.activeComponent) {
      this.sharedService.activeComponent.value = this.selectedObjects[0].data;
      this.sharedService.applyDynamicValues(this.sharedService.activeComponent, this.selectedObjects[0].data);
      this.sharedService.reviewPickerOpen = false;
    }

  }

  filterReviews(tag: any) {
    this.currentTag = tag;
    this.mode = "TAG";

    this.filteredReviews = this.reviewSnippets.filter((obj: any) => {
      return obj.data?.tags?.includes(tag);
    });
  }

  removeTag() {
    this.mode = 'DEFAULT';
    this.currentTag = "";
  }

  exitSearch() {
    this.mode = "DEFAULT";
    this.imageSearchTerm = "";
  }

  replaceInnerMostContent(htmlString: any, replacement: any) {
    // Check if the string starts with "<"
    if (htmlString.startsWith("<")) {
      // Find the innermost content between ">" and "<" using a regular expression
      // This regex finds the last ">" and the next "<" in the string
      const regex = />([^<>]+)<\/[^<>]+>$/;
      const match = htmlString.match(regex);

      if (match && match[1]) {
        // Replace the found content with the replacement string
        // We only replace the first occurrence to ensure we're dealing with the innermost content
        return htmlString.replace(match[1], replacement);
      }
    } else {
      htmlString = `<p class="ql-align-center">${replacement}</p>`;
    }
    // Return the original string if it doesn't start with "<" or no match was found
    return htmlString;
  }
}
