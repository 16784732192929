<div class="page-wrapper">
    <div class="header-wrapper">
        <nz-page-header class="site-page-header">
            <nz-page-header-title>Settings</nz-page-header-title>
            <nz-page-header-extra>

            </nz-page-header-extra>
        </nz-page-header>
    </div>
    <div class="content-wrapper">
        <!-- <div class="column-left">
            <div class="navbar">
                
            </div>
        </div> -->
        <div class="filterBar">
            <ul nz-menu nzMode="horizontal" style="background: #FAFAFA">
                <li nz-menu-item routerLink="/settings/account" routerLinkActive="ant-menu-item-selected">
                    <i class="lni lni-cog" style="float: left; margin-top: 12px;"></i>&nbsp;&nbsp;<span>Account</span>
                </li>
                <li nz-menu-item routerLink="/settings/styles" routerLinkActive="ant-menu-item-selected">
                    <i class="lni lni-brush" style="float: left; margin-top: 12px;"></i><span>&nbsp;&nbsp;Brand</span>
                </li>
                <!-- <li nz-menu-item routerLink="/brand/voice" routerLinkActive="ant-menu-item-selected">
                    <span>Brand voice</span>
                </li> -->
                <li nz-menu-item routerLink="/settings/team" routerLinkActive="ant-menu-item-selected">
                    <i class="lni lni-user" style="float: left; margin-top: 12px;"></i>&nbsp;&nbsp;<span>Team</span>
                </li>
                <li nz-menu-item routerLink="/settings/integrations" routerLinkActive="ant-menu-item-selected">
                    <i class="lni lni-plug"
                        style="float: left; margin-top: 12px;"></i>&nbsp;&nbsp;<span>Integrations</span>
                </li>
                <!-- <li nz-menu-item routerLink="/settings/blocks" routerLinkActive="ant-menu-item-selected">
                    <i class="lni lni-archive"
                        style="float: left; margin-top: 12px;"></i>&nbsp;&nbsp;<span>Saved blocks</span>
                </li> -->

            </ul>
        </div>

        <div class="column-right">

            <router-outlet></router-outlet>
        </div>
    </div>
</div>