<div class="page-wrapper">
    <img class="logo-signup" src="../../../assets/rippleLogo.png" />
    <div class="content-wrapper">
        <h2 nz-typography class="setup-title">Welcome to Ripple</h2>
        <div style="margin-bottom: 24px;">Create an account</div>
        <form nz-form nzLayout="vertical">
            <nz-form-item style="margin-bottom: 16px;">
                <nz-form-label nzNoColon="true" nzSpan="8">Account name</nz-form-label>
                <nz-form-control nzSpan="24">
                    <input [ngModelOptions]="{standalone: true}" [(ngModel)]="this.workspaceObject.name" nz-input
                        placeholder="Abc corp." />
                </nz-form-control>
            </nz-form-item>
            <nz-form-item style="margin-bottom: 16px;">
                <nz-form-label nzNoColon="true" nzSpan="8">Website</nz-form-label>
                <nz-form-control nzSpan="24">
                    <input [ngModelOptions]="{standalone: true}" [(ngModel)]="this.workspaceObject.domain" nz-input
                        placeholder="yourwebsite.com" />
                </nz-form-control>
            </nz-form-item>
            <nz-form-item style="margin-bottom: 16px;">
                <nz-form-label nzNoColon="true" nzSpan="8">Email</nz-form-label>
                <nz-form-control nzSpan="24">
                    <input [ngModelOptions]="{standalone: true}" [(ngModel)]="this.workspaceObject.email" nz-input
                        placeholder="ceo@abccorp.com" />
                </nz-form-control>
            </nz-form-item>
            <nz-form-item style="margin-bottom: 32px;">
                <nz-form-label nzNoColon="true" nzSpan="8">Password</nz-form-label>
                <nz-form-control nzSpan="24">
                    <input [ngModelOptions]="{standalone: true}" [(ngModel)]="this.workspaceObject.password" nz-input
                        placeholder="Super secret password" type="password" />
                </nz-form-control>
            </nz-form-item>
        </form>
        <!-- <button nz-button nzType="primary" (click)="completeSignup()" nzBlock>Continue</button> -->
        <button nz-button nzType="primary" (click)="completeSignup()" nzBlock>Continue</button>

        
    </div>
</div>