<div class="page-wrapper">
    <div class="content-wrapper">
        <!-- <div class="header-wrapper">
            <nz-page-header nzBackIcon class="site-page-header">
                <nz-page-header-title>Planning assistant</nz-page-header-title>
                <nz-page-header-tags>
                    <nz-tag class="aqua">Beta</nz-tag>
                </nz-page-header-tags>
                <nz-page-header-extra>
                    <ng-container *ngIf="this.mode == 'CALENDAR'">
                        <button nz-button><i class="lni lni-trash-can"
                                style="float: left; margin-top: 3px;"></i>&nbsp;&nbsp;Clear</button>&nbsp;
                        <button [nzLoading]="this.saving" [disabled]="this.loadingChat || !this.emailsOnCalendar"
                            (click)="saveCalendar()" nz-button nzType="primary">Generate emails</button>
                    </ng-container>
                </nz-page-header-extra>
            </nz-page-header>
        </div> -->

        <!-- <ng-container *ngIf="this.noCampaigns">
                <ng-container *ngIf="!this.generatingCampaigns">
                    <nz-card style="text-align: center; padding: 8px; margin-top: 8px">
                        <h3>Get started with Copilot email campaigns</h3>
                        <p>Copilot will create a custom content calendar for your brand, all you do is approve!</p>
                        <br>
                        <button nz-button nzType="primary" routerLink="/signup/2">Get started</button>
                    </nz-card>
                </ng-container>

                <ng-container *ngIf="this.generatingCampaigns">
                    <nz-card style="text-align: center; padding: 8px; margin-top: 8px">
                        <h3>Get started with Copilot email campaigns</h3>
                        <p>Copilot will create a custom content calendar for your brand, all you do is approve!</p>
                        <br>
                        <button nz-button nzType="primary" routerLink="/signup/2">Get started</button>
                    </nz-card>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="!this.noCampaigns"> -->
        <ng-container *ngIf="this.mode == 'CHAT'">
            <div class="chat-outer">
                <div class="chat-inner">
                    <div class="chat-wrapper-2">

                        <ng-container *ngFor="let message of chatLog">
                            <ng-container *ngIf="message.sender == 'RIPPLE'">
                                <div class="message-bubble-ripple">
                                    <div class="message-bubble-title">Ripple</div>

                                    {{message.message}}
                                </div>
                                <ng-container *ngIf="message.actions && message.actions.length > 0">
                                    <ng-container *ngFor="let action of message.actions">
                                        <button (click)="sendChatFromButton(action.value, message)"
                                            style="margin-bottom: 4px; float: right" nz-button>{{action.value}}</button>
                                    </ng-container>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="message.sender == 'USER'">
                                <div class="message-bubble-user-wrapper">

                                    <div class="message-bubble-user">
                                        <div class="message-bubble-title">You</div>

                                        {{message.message}}
                                    </div>
                                </div>

                            </ng-container>
                        </ng-container>
                        <div class="message-bubble-ripple" *ngIf="loadingChat">
                            <div class="typing">
                                <div class="dot"></div>
                                <div class="dot"></div>
                                <div class="dot"></div>
                            </div>
                        </div>
                    </div>

                    <div class="drawer-footer-wrapper-2">
                        <!-- <div class="navbar">
                            <ul nz-menu nzMode="inline">
                                <li nz-menu-group nzTitle="Ideas" nzIcon="appstore"></li>
                                <li nz-menu-item><span>Make an email calendar for the next 30 days</span></li>
                                <li nz-menu-item><span>Make a series of emails for an upcoming sale</span></li>
                                <li nz-menu-item><span>Make an email featuring my best selling products</span></li>
                            </ul>
                        </div> -->
                        <textarea placeholder="Enter feedback for Ripple" rows="2" nz-input
                            [(ngModel)]="chatBox"></textarea>
                        <button style="float: right; margin-top: 8px;" nz-button [disabled]="loadingChat"
                            nzType="primary" (click)="sendOpenAiChat(this.chatBox)">Send</button>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="this.mode == 'CALENDAR'">
            <!-- <div class="header-wrapper">
                <nz-page-header nzTitle="Calendar">
                    <nz-page-header-extra>
                        <button nz-button>Cancel</button>&nbsp;
                        <button [nzLoading]="this.saving" (click)="saveCalendar()" nz-button nzType="primary"><span
                                nz-icon nzType="plus" nzTheme="outline"></span>Save</button>
                    </nz-page-header-extra>
                </nz-page-header>

            </div> -->
            <!-- <div class="left-column">

                    <div class="left-column-content">
                        <nz-timeline>
                            <ng-container *ngFor="let item of emailCampaigns">
                                <nz-timeline-item>
                                    <div class="custom-card">
                                        <div class="custom-card-body">
                                            <div class="timeline-item-date"><span nz-icon nzType="clock-circle" nzTheme="outline"></span>&nbsp;{{item.sendTime | date:'EEEE M/d/YY h:mm a'}}</div>
                                            <div class="timeline-item-title">{{item.title}}</div> 
                                        </div>
                                    </div>
                                    <div class="custom-card hover">
                                        <div class="custom-card-header small">{{item.sendTime |
                                            date:'EEEE
                                            M/d/YY h:mm a'}}</div>
                                        <div class="custom-card-body small">
                                            <div>{{item.title}}</div>
                                            <div style="margin-top: 8px;">{{item.description}}</div>
                                        </div>
                                        <div class="custom-card-footer grey">
                                            <button nz-button nzType="primary"
                                                [routerLink]="['/opportunities',item._id]">Explore</button>
                                        </div>
                                    </div>
                                </nz-timeline-item>
                            </ng-container>

                        </nz-timeline>
                    </div>
                </div> -->
            <div class="left-column">
                <div class="session-wrapper navbar">
                    <button (click)="newSession()" nz-button
                        style="width: calc(100% - 16px); text-align: left; margin: 16px 8px;"><i
                            class="lni lni-pencil-alt" style="float: left; margin-top: 3px;"></i>&nbsp;&nbsp;New
                        chat</button>
                    <ul nz-menu nzMode="inline" style="background: #fafafa;">
                        <ng-container *ngFor="let sessionObject of sessionList">
                            <!-- <button (click)="changeSession(sessionObject._id)" nz-button
                                style="width: 100%; text-align: left; margin-bottom: 4px;"><i
                                    class="lni lni-alarm-clock"
                                    style="float: left; margin-top: 3px;"></i>&nbsp;&nbsp;{{sessionObject.updatedAt |
                                date:'M/d/YY
                                h:mm
                                a'}}</button> -->
                            <li nz-menu-item (click)="changeSession(sessionObject._id)"
                                routerLinkActive="ant-menu-item-selected"><i
                                    class="lni lni-calendar"></i>&nbsp;&nbsp;<span>{{sessionObject.updatedAt |
                                    date:'M/d/YY
                                    h:mm
                                    a'}}</span></li>
                        </ng-container>
                    </ul>
                </div>
            </div>
            <div class="middle-column">
                <div class="chat-content-wrapper">
                    <div class="chat-wrapper" #chatDiv [scrollTop]="chatDiv.scrollHeight"
                        [class.extra-padding]="this.emailsOnCalendar">
                        <!-- <div class="custom-alert aqua"><span nz-icon nzType="info-circle"
                                nzTheme="outline"></span>&nbsp;Chat with the assistant untill you're happy with the
                            emails it proposes, then click "Generate emails" to add them to your calendar!</div> -->

                        <ng-container *ngFor="let message of chatLog">
                            <ng-container *ngIf="message.sender == 'RIPPLE'">
                                <div class="message-bubble-ripple">
                                    <div class="message-bubble-title">Ripple</div>
                                    <markdown [data]="message.message">
                                    </markdown>
                                    <!-- <div>{{message.message}}</div> -->
                                    <ng-container *ngIf="message.actions && message.actions.length > 0">
                                        <div style="height: 8px;"></div>
                                        <ng-container *ngFor="let action of message.actions">
                                            <button (click)="sendChatFromButton(action.value, message)"
                                                style="margin-bottom: 4px;" nz-button>{{action.value}}</button><br>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="message.actions && message.actions.emails && message.actions.emails[0]">
                                        <div style="height: 8px;"></div>
                                        <ng-container *ngFor="let action of message.actions.emails">
                                            <div class="custom-card mini">
                                                <div class="custom-card-body mini"
                                                    style="padding: 16px; line-height: 36px;">
                                                    <div class="item-header">
                                                        <nz-tag>Email</nz-tag>
                                                        <nz-tag>{{action.sendTime | date: 'EEEE, MMMM d, y, hh:mm a'}}</nz-tag>

                                                        <button style="float: right; padding: 3px 8px; height: 30px;"
                                                            nz-button (click)="generateEmail(action)">Generate</button>
                                                        <button
                                                            style="float: right; margin-right: 4px; padding: 3px 8px; height: 30px;"
                                                            nz-button (click)="saveEmail(action)"><span nz-icon
                                                                nzType="save" nzTheme="outline"></span>Save</button>
                                                    </div>
                                                    <div class="item-title">{{action.title}}</div>
                                                    <div class="item-body">{{action.reason}}</div>
                                                </div>
                                                <!-- <div class="custom-card-footer mini" style="padding: 8px 16px;">
                                                    
                                                </div> -->
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>

                            </ng-container>

                            <ng-container *ngIf="message.sender == 'USER'">
                                <div class="message-bubble-user-wrapper">
                                    <div class="message-bubble-user">
                                        <div class="message-bubble-title">You</div>
                                        <div>{{message.message}}</div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <div class="message-bubble-ripple" *ngIf="loadingChat">
                            <div class="typing">
                                <div class="dot"></div>
                                <div class="dot"></div>
                                <div class="dot"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="drawer-footer-wrapper">
                    <div class="drawer-footer-inner">
                        <!-- <div class="custom-card mini" *ngIf="this.emailsOnCalendar">
                            <div class="custom-card-body" style="padding: 12px; line-height: 36px;">
                                Your emails are ready <button [nzLoading]="this.saving"
                                    [disabled]="this.loadingChat || !this.emailsOnCalendar" (click)="saveCalendar()"
                                    nz-button nzType="primary" style="float: right">Generate
                                    emails</button>
                            </div>
                        </div> -->

                        <textarea placeholder="Type a message for Ripple..." [nzAutosize]="{ minRows: 1, maxRows: 15 }"
                            nz-input [(ngModel)]="chatBox" class="chatbot-input"></textarea>
                        <div style="text-align: right">
                            <button nz-button [disabled]="loadingChat" nzType="primary"
                                (click)="sendOpenAiChat(this.chatBox)"><span nz-icon nzType="send"
                                    nzTheme="outline"></span>Send</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-column" style="display: none">

                <div class="filter-bar">
                    <nz-input-group nzCompact>
                        <button nz-button nzType="default" (click)="decreaseMonth()">
                            <span nz-icon nzType="left"></span>
                        </button>
                        <nz-select style="width: 140px;" [(ngModel)]="this.sharedService.calendarMonth"
                            (ngModelChange)="this.changeDate()" [compareWith]="compareFn">
                            <nz-option nzLabel="January" nzValue="0"></nz-option>
                            <nz-option nzLabel="February" nzValue="1"></nz-option>
                            <nz-option nzLabel="March" nzValue="2"></nz-option>
                            <nz-option nzLabel="April" nzValue="3"></nz-option>
                            <nz-option nzLabel="May" nzValue="4"></nz-option>
                            <nz-option nzLabel="June" nzValue="5"></nz-option>
                            <nz-option nzLabel="July" nzValue="6"></nz-option>
                            <nz-option nzLabel="August" nzValue="7"></nz-option>
                            <nz-option nzLabel="September" nzValue="8"></nz-option>
                            <nz-option nzLabel="October" nzValue="9"></nz-option>
                            <nz-option nzLabel="November" nzValue="10"></nz-option>
                            <nz-option nzLabel="December" nzValue="11"></nz-option>
                        </nz-select>
                        <nz-select style="width: 92px;" [(ngModel)]="this.sharedService.calendarYear"
                            (ngModelChange)="this.changeDate()" [compareWith]="compareFn">
                            <nz-option nzLabel="2023" nzValue="2023"></nz-option>
                            <nz-option nzLabel="2024" nzValue="2024"></nz-option>
                            <nz-option nzLabel="2025" nzValue="2025"></nz-option>
                            <nz-option nzLabel="2026" nzValue="2026"></nz-option>
                            <nz-option nzLabel="2027" nzValue="2027"></nz-option>
                            <nz-option nzLabel="2028" nzValue="2028"></nz-option>
                            <nz-option nzLabel="2029" nzValue="2029"></nz-option>
                            <nz-option nzLabel="2030" nzValue="2030"></nz-option>
                            <nz-option nzLabel="2031" nzValue="2031"></nz-option>
                            <nz-option nzLabel="2032" nzValue="2032"></nz-option>
                        </nz-select>
                        <button nz-button nzType="default" (click)="increaseMonth()">
                            <span nz-icon nzType="right"></span>
                        </button>
                    </nz-input-group>
                </div>
                <div class="custom-card-body table-card">
                    <div class="calendar-wrapper" style="height: 900px">

                        <div class="calendar-block header">
                            <h3>SUN</h3>
                        </div>
                        <div class="calendar-block header">
                            <h3>MON</h3>
                        </div>
                        <div class="calendar-block header">
                            <h3>TUE</h3>
                        </div>
                        <div class="calendar-block header">
                            <h3>WED</h3>
                        </div>
                        <div class="calendar-block header">
                            <h3>THU</h3>
                        </div>
                        <div class="calendar-block header">
                            <h3>FRI</h3>
                        </div>
                        <div class="calendar-block header">
                            <h3>SAT</h3>
                        </div>
                        <ng-container *ngIf="this.sharedService.loadingCalendar">
                            <div class="loading-wrapper">
                                <div class="lds-ellipsis">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!this.sharedService.loadingCalendar">
                            <ng-container *ngFor="let week of this.calendarData">
                                <div class="calendar-row">
                                    <ng-container *ngFor="let day of week">

                                        <ng-container *ngIf="day.filler">
                                            <div class="calendar-block" style="background: #f9f9f9;">
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="!day.filler">
                                            <div class="calendar-block" [class.hover]="!dateInPast(day.date)">
                                                <div class="calendar-date">{{day.dayOfMonth}}</div>
                                                <div *ngIf="!dateInPast(day.date)" class="calendar-new-button">
                                                    +
                                                    New
                                                </div>

                                                <ng-container *ngIf="day.items.length > 0">
                                                    <ng-container *ngFor="let item of day.items">
                                                        <ng-container *ngIf="!item.generated && item.contentTemplate">

                                                            <div class="calendar-item" (click)="popoverItem = item">
                                                                <!-- <div class="calendar-item-title">{{day.items[0].title}}</div> -->
                                                                <div class="calendar-item-time">
                                                                    <!-- {{item.sendTime |date:'shortTime'}} -->

                                                                    <!-- <div class="generating-wrapper"><nz-spin
                                                                        style="display: inline; margin-right: 18px;"
                                                                        [nzIndicator]="indicatorTemplate"></nz-spin>
                                                                    Generating...</div> -->
                                                                    <div class="calendar-item-title">
                                                                        {{item.title}}
                                                                    </div>

                                                                    <!-- <div class="generating-wrapper"><nz-spin
                                                                        style="display: inline; margin-right: 18px;"
                                                                        [nzIndicator]="indicatorTemplate"></nz-spin>
                                                                    Generating...</div> -->
                                                                </div>
                                                            </div>
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="item.generated || (!item.contentTemplate && item.subjectLine)">

                                                            <div class="calendar-item new" nz-popover
                                                                [nzPopoverContent]="contentTemplate"
                                                                nzPopoverPlacement="rightTop" nzPopoverTrigger="click"
                                                                (click)="popoverItem = item">
                                                                <!-- <div class="calendar-item-title">{{day.items[0].title}}</div> -->
                                                                <div class="calendar-item-time">
                                                                    <!-- {{item.sendTime |date:'shortTime'}} -->

                                                                    <!-- <div class="generating-wrapper"><nz-spin
                                                                        style="display: inline; margin-right: 18px;"
                                                                        [nzIndicator]="indicatorTemplate"></nz-spin>
                                                                    Generating...</div> -->
                                                                    <div class="calendar-item-title">
                                                                        {{item.subjectLine}}
                                                                    </div>

                                                                    <!-- <div class="generating-wrapper"><nz-spin
                                                                        style="display: inline; margin-right: 18px;"
                                                                        [nzIndicator]="indicatorTemplate"></nz-spin>
                                                                    Generating...</div> -->
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </div>

                                        </ng-container>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>

        </ng-container>


    </div>
</div>

<ng-template #contentTemplate>
    <div>
        <nz-form-item style="width: 300px; margin-bottom: 8px !important;">
            <nz-form-label style="padding:0px" [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                Send time
            </nz-form-label>
            <nz-form-control nzSpan="24">
                {{popoverItem.date | date:'EEEE M/d/YY h:mm
                a'}}
            </nz-form-control>
        </nz-form-item>
        <nz-form-item style="width: 400px; margin-bottom: 8px !important;">
            <nz-form-label style="padding:0px" [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                Subject line
            </nz-form-label>
            <nz-form-control nzSpan="24">
                {{popoverItem.subjectLine}}
            </nz-form-control>
        </nz-form-item>
        <nz-form-item style="width: 400px; margin-bottom: 8px !important;">
            <nz-form-label style="padding:0px" [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                Details
            </nz-form-label>
            <nz-form-control nzSpan="24">
                {{popoverItem.description}}
            </nz-form-control>
        </nz-form-item>
        <nz-form-item style="width: 400px; margin-bottom: 8px !important;">
            <nz-form-label style="padding:0px" [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                Details
            </nz-form-label>
            <nz-form-control nzSpan="24">
                <textarea nz-input [(ngModel)]="popoverItem.prompt"></textarea>
            </nz-form-control>
        </nz-form-item>
        <!-- <div class="popover-footer">
            <button nz-button>Try again</button>
        </div> -->
        <!-- <div style="text-align: right">
            <button nzType="primary" nz-button nz-popconfirm
            nzPopconfirmTitle="This will overwrite any changes you've made in the editor"
            (nzOnConfirm)="sendSubjectLineFeedback()"
            nzPopconfirmPlacement="bottomRight">Send</button>
        </div> -->
    </div>
</ng-template>

<ng-template #contentTemplate>
    <div style="width:800px; height: 700px;">
        <app-email-renderer [mode]="'VIEW'"></app-email-renderer>
    </div>
</ng-template>

<ng-template #customTemplate let-data>
    <!-- Your custom template content goes here -->
    Email generated&nbsp;&nbsp;&nbsp;{{data.title}}<button nz-button
        [routerLink]="['/opportunities', data.oppId]">Open</button>
</ng-template>


<!-- <div class="page-wrapper">
    <div class="left-column">
        <div class="left-column-content">
            <nz-page-header class="site-page-header" nzTitle="New">
                <nz-breadcrumb nz-page-header-breadcrumb>
                    <nz-breadcrumb-item>Opportunities</nz-breadcrumb-item>
                    <nz-breadcrumb-item>New</nz-breadcrumb-item>
                </nz-breadcrumb>
            </nz-page-header>
            <ng-container *ngFor="let opportunity of opportunities">
                <nz-card nzHoverable>{{opportunity.title}}</nz-card>
            </ng-container>
        </div>
    </div>
    <div class="right-column">

    </div>
</div> -->