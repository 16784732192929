import { Component } from '@angular/core';

@Component({
  selector: 'app-staff-opportunities',
  templateUrl: './staff-opportunities.component.html',
  styleUrls: ['./staff-opportunities.component.scss']
})
export class StaffOpportunitiesComponent {

}
