import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SharedService } from '../shared.service';
import { NzSegmentedOption } from 'ng-zorro-antd/segmented';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-flow-view',
  templateUrl: './flow-view.component.html',
  styleUrls: ['./flow-view.component.scss']
})
export class FlowViewComponent {

  opportunities: any = [];
  loading = true;
  loadingNewOpps = true;
  newOpportunities: any = [];
  currentPage = 1;

  viewMode: any = 0;

  options = [
    { label: '', value: 'GRID', icon: 'appstore' },
    { label: '', value: 'TABLE', icon: 'unordered-list' }
  ];
  flowId: any = "";
  flowObject: any = {};

  deleting: any = false;

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public sharedService: SharedService,
    private message: NzMessageService
  ) {

  }

  async ngOnInit() {
    this.flowId = this.route.snapshot.paramMap.get("id");
    let data: any = await this.apiService.getFlow(this.flowId).toPromise();

    console.log(data);
    this.flowObject = data;
    this.loading = false;

    for (let i = 0; i < this.flowObject.opportunities.length; i++) {
      let opp = this.flowObject.opportunities[i];

      if (opp && opp.stage && opp.stage == "NEW") {
        // this.sharedService.checkOpportunityStatus(opp._id, "PENDING").then((data: any) => {
        //   // this.message.create("success", `Flow strategy complete`);
        //   this.flowObject.opportunities[i] = data;
        // });
        let isPending = true;
        const wait = (ms: any) => new Promise(resolve => setTimeout(resolve, ms));

        while (isPending) {
          // Assuming `apiService.getOpportunity` returns a Promise
          let data: any = await this.apiService.getOpportunity(opp._id).toPromise();
          console.log(data);

          if (data.stage == "PENDING") {
            isPending = false;
            this.message.create("success", `Email completed`);
            this.flowObject.opportunities[i] = data;
          } else {
            await wait(2000);
          }
        }
      }
    }
  }

  pageIndexChange(event: any) {
    console.log(event);
    this.currentPage = event;

    const element: any = document.querySelector('#goUp');
    element.scrollIntoView();
  }

  changedView(event: any) {
    console.log(event);
  }

  handleBackButton() {
      this.router.navigate([`/flows`]);
  }

  async deleteFlow() {
    this.deleting = true;

    this.apiService.deleteFlow(this.flowObject._id).subscribe(
      (data: any) => {
        console.log(data);
        this.message.create("success", `Successfully deleted flow`);
        this.router.navigate(['flows']);
        this.deleting = false;
      },
      error => {
        console.log(error);
      });
  }

  dropItem(event: any) {
    console.log(event);
    moveItemInArray(this.flowObject.opportunities, event.previousIndex, event.currentIndex);

    for (let i = 0; i < this.flowObject.opportunities.length; i++) {
      this.flowObject.opportunities[i].flowPosition = i;

      this.apiService.saveOpportunity(this.flowObject.opportunities[i]).subscribe(
        (data: any) => {
          console.log(data);
        },
        error => {
          console.log(error);
        });
    }
  };
}
