import { ChangeDetectorRef, Component } from '@angular/core';
import { SharedService } from '../shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { Observable, filter, of } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzFormatBeforeDropEvent, NzFormatEmitEvent } from 'ng-zorro-antd/tree';

@Component({
  selector: 'app-content-editor',
  templateUrl: './content-editor.component.html',
  styleUrls: ['./content-editor.component.scss']
})
export class ContentEditorComponent {
  loading: any = true;

  thisWeek: any = [];
  toDo: any = [];

  loadingThisWeek: any = true;
  toDoLoading: any = true;

  openRateMetric: any = {};
  loadingOpenRateMetric = true;

  clickRateMetric: any = {};
  loadingClickRateMetric = true;

  receivedEmailMetric: any = {};
  loadingReceivedEmailMetric = true;

  campaignsSentMetric: any = {};
  loadingCampaignsSentMetric = true;

  ordersMetric: any = {};
  loadingOrdersMetric = true;

  metricsFilter: any = "30DAYS";

  nestedData = [
    {
      value: "Test",
      children: [
        {
          value: "child test",
          children: [
            {
              value: "grandchild test",
              children: []
            }
          ]
        }
      ]
    }
  ];

  block: any = {
    "properties": [
      { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
      { "type": "BORDER", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#000", "borderStyle": "solid" },
      { "type": "RICHTEXT", "name": "styles", "value": "" }
    ],
    "components": [
      {
        "type": "CONTAINER",
        "title": "Container",
        "value": "",
        "properties": [
          { "type": "BORDER", "name": "border", "value": false, "borderWidth": "0px", "borderColor": "#000", "borderStyle": "solid" },
          { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
          { "type": "BACKGROUND", "name": "background", "value": "#f2f2f2", "mode": "solid", "backgroundPosition": "center", "backgroundSize": "cover" },
          { "type": "RICHTEXT", "name": "styles", "value": "" }
        ],
        "children": [
          {
            "type": "TEXT",
            "title": "Text",
            "value": "Check out these products!",
            "properties": [
              { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "Aspekta", "fontSize": "41px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "1.5px", "toolbar": true },
              { "type": "COLOR", "name": "color", "value": "#FFF", "toolbar": true },
              { "type": "ALIGNMENT", "name": "textAlign", "value": "center", "toolbar": true },
              { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
              { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
              { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } }
            ]
          },
          {
            "type": "DATACONTAINER",
            "title": "Products",
            "source": "PRODUCTS",
            "value": [
              {
                "title": "Test product",
                "imageUrl": "https://ripple-files-cdn.azureedge.net/images/66a2671a3b902f4eb28c61fd-17751200825497-thumbnail.png"
              },
              {
                "title": "Test product 2",
                "imageUrl": "https://ripple-files-cdn.azureedge.net/images/66a2671a3b902f4eb28c61fd-17751200825497-thumbnail.png"
              }
            ],
            "children": [
              {
                "type": "CONTAINER",
                "title": "Container",
                "value": "",
                "properties": [
                  { "type": "BORDER", "name": "border", "value": false, "borderWidth": "0px", "borderColor": "#000", "borderStyle": "solid" },
                  { "type": "PADDING", "name": "padding", "value": "0px", "paddingTop": "0px", "paddingBottom": "0px", "paddingLeft": "-px", "paddingRight": "0px" },
                  { "type": "BACKGROUND", "name": "background", "value": "#f2f2f2", "mode": "solid", "backgroundPosition": "center", "backgroundSize": "cover" },
                  { "type": "RICHTEXT", "name": "styles", "value": "" },
                  { "type": "PIXELS", "name": "width", "value": "50%", "mode": "fixed" },
                ],
                "children": [
                  {
                    "type": "IMAGE",
                    "title": "Image",
                    "value": "imageUrl",
                    "mode": "DYNAMIC",
                    "properties": [
                      { "type": "IMAGE", "name": "image", "value": "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-13f95681-1482-47ac-a783-0c9e6b71e1cd.png" },
                      { "type": "PADDING", "name": "padding", "value": "8px", "paddingTop": "8px", "paddingBottom": "8px", "paddingLeft": "16px", "paddingRight": "16px" },
                      { "type": "RICHTEXT", "name": "styles", "value": { "margin": "auto", "width": "100%" } },
                      { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
                    ]
                  },
                  {
                    "type": "TEXT",
                    "title": "Text",
                    "value": "title",
                    "mode": "DYNAMIC",
                    "properties": [
                      { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "Aspekta", "fontSize": "16px", "fontWeight": "500", "lineHeight": "48px", "letterSpacing": "1.5px" },
                      { "type": "COLOR", "name": "color", "value": "#FFF" },
                      { "type": "ALIGNMENT", "name": "textAlign", "value": "center" },
                      { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
                      { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
                      { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } }
                    ]
                  },
                ]
              }
            ],
            "properties": [
              { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "Aspekta", "fontSize": "41px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "1.5px" },
              { "type": "COLOR", "name": "color", "value": "#FFF" },
              { "type": "ALIGNMENT", "name": "textAlign", "value": "center" },
              { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "0px", "borderColor": "#d47555", "borderStyle": "solid" },
              { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
              { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } },
              { "type": "PRODUCTS", "name": "styles", "value": "" }
            ]
          },
          {
            "type": "BUTTON",
            "title": "Button",
            "value": "Happy National Matcha Day",
            "properties": [
              { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "Aspekta", "fontSize": "41px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "1.5px" },
              { "type": "COLOR", "name": "color", "value": "#FFF" },
              { "type": "ALIGNMENT", "name": "textAlign", "value": "center" },
              { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
              { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
              { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } }
            ]
          }
        ]
      }
    ]
  }

  block2: any = {
    "properties": [
      // { "type": "PIXELS", "name": "height", "value": "" },
      { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
      { "type": "BORDER", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#000", "borderStyle": "solid" },
      { "type": "RICHTEXT", "name": "styles", "value": "" }
    ],
    "components": [
      {
        "type": "CONTAINER",
        "title": "Container",
        "value": "",
        "properties": [
          { "type": "BORDER", "name": "border", "value": false, "borderWidth": "0px", "borderColor": "#000", "borderStyle": "solid" },
          { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
          { "type": "BACKGROUND", "name": "background", "value": "https://images.ctfassets.net/dfp1t53x5luq/2htKZCIYTHMkix8GVabzsX/ca38b93cbc24b9b4851c2292a02b95e7/Cookware_Plus_-_Lifestyle_on_Stovetop__1_.jpg?w=1916&h=1412&q=85&fm=webp", "mode": "image", "backgroundPosition": "center", "backgroundSize": "cover" },
          { "type": "RICHTEXT", "name": "styles", "value": "" }
        ],
        "children": [
          {
            "type": "CONTAINER",
            "title": "Container",
            "value": "",
            "properties": [
              { "type": "BORDER", "name": "border", "value": true, "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
              { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "0px", "paddingBottom": "0px", "paddingLeft": "0px", "paddingRight": "0px" },
              { "type": "BACKGROUND", "name": "background", "value": "transparent", "mode": "solid", "backgroundPosition": "center", "backgroundSize": "contain" },
              { "type": "RICHTEXT", "name": "styles", "value": "" }
            ],
            "children": [
              {
                "type": "CONTAINER",
                "title": "Container",
                "value": "",
                "properties": [
                  { "type": "BORDER", "name": "border", "value": false, "borderWidth": "0px", "borderColor": "#d47555", "borderStyle": "solid" },
                  { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
                  { "type": "BACKGROUND", "name": "background", "value": "#fbf9f9", "mode": "solid", "backgroundPosition": "center", "backgroundSize": "contain" },
                  { "type": "RICHTEXT", "name": "styles", "value": { "border-bottom-width": "3px" } }
                ],
                "children": [
                  {
                    "type": "IMAGE",
                    "title": "Image",
                    "value": "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-13f95681-1482-47ac-a783-0c9e6b71e1cd.png",
                    "properties": [
                      { "type": "IMAGE", "name": "image", "value": "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-13f95681-1482-47ac-a783-0c9e6b71e1cd.png" },
                      { "type": "PADDING", "name": "padding", "value": "8px", "paddingTop": "8px", "paddingBottom": "8px", "paddingLeft": "16px", "paddingRight": "16px" },
                      { "type": "RICHTEXT", "name": "styles", "value": { "margin": "auto", "width": "200px" } },
                      { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
                    ]
                  },
                  // {
                  //   "type": "BUTTON",
                  //   "value": "Click Me",
                  //   "properties": [
                  //     { "type": "font", "name": "fontSize", "value": "24px" },
                  //     { "type": "font", "name": "fontWeight", "value": "600" },
                  //     { "type": "color", "name": "color", "value": "#000000" },
                  //     { "type": "textAlign", "name": "textAlign", "value": "center" },
                  //     { "type": "padding", "name": "paddingTop", "value": "0px" },
                  //     { "type": "padding", "name": "paddingBottom", "value": "0px" },
                  //     { "type": "padding", "name": "paddingLeft", "value": "0px" },
                  //     { "type": "padding", "name": "paddingRight", "value": "0px" },
                  //     { "type": "border", "name": "borderRadius", "value": "4px" },
                  //     { "type": "border", "name": "borderColor", "value": "#000000" },
                  //     { "type": "border", "name": "borderWidth", "value": "1px" },
                  //     { "type": "border", "name": "borderStyle", "value": "solid" },
                  //     { "type": "styles", "name": "styles", "value": "" }
                  //   ]
                  // }
                ]
              },
              {
                "type": "CONTAINER",
                "title": "Container",
                "value": "",
                "properties": [
                  { "type": "BORDER", "name": "border", "value": false, "borderWidth": "0px", "borderColor": "#d47555", "borderStyle": "solid" },
                  { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
                  { "type": "PIXELS", "name": "width", "value": "30%", "mode": "fixed" },
                  { "type": "BACKGROUND", "name": "background", "value": "#d47555", "mode": "solid", "backgroundPosition": "center", "backgroundSize": "contain" },
                  { "type": "RICHTEXT", "name": "styles", "value": "" }
                ],
                "children": [
                  {
                    "type": "TEXT",
                    "title": "Text",
                    "value": "20%\nOFF",
                    "properties": [
                      { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "Aspekta", "fontSize": "41px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "1.5px" },
                      { "type": "COLOR", "name": "color", "value": "#FFF" },
                      { "type": "ALIGNMENT", "name": "textAlign", "value": "center" },
                      { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
                      { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
                      { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } },
                      { "type": "TEXTSTYLES", "name": "textStyles", "value": "" }
                    ]
                  },
                ]
              },
              {
                "type": "CONTAINER",
                "title": "Container",
                "value": "",
                "properties": [
                  { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
                  { "type": "padding", "name": "borderRadius", "value": "0px" },
                  { "type": "BORDER", "name": "border", "value": false, "borderWidth": "0px", "borderColor": "#d47555", "borderStyle": "solid" },
                  { "type": "PIXELS", "name": "width", "value": "70%", "mode": "fixed" },
                  { "type": "BACKGROUND", "name": "background", "value": "#fbf9f9", "mode": "solid", "backgroundPosition": "center", "backgroundSize": "contain" },
                  { "type": "RICHTEXT", "name": "styles", "value": "" }
                ],
                "children": [
                  {
                    "type": "TEXT",
                    "title": "Text",
                    "value": "Iconic\nMust-Haves",
                    "properties": [
                      { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "Meriweather", "fontSize": "40px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "1.5px", "toolbar": true },
                      { "type": "COLOR", "name": "color", "value": "#d47555", "toolbar": true },
                      { "type": "ALIGNMENT", "name": "textAlign", "value": "left", "toolbar": true },
                      { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
                      { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } },
                      { "type": "TEXTSTYLES", "name": "textStyles", "value": "", "toolbar": true }
                    ]
                  },
                ]
              }
            ]
          }
        ]
      }
    ]
  };

  template: any = {
    "properties": [
      { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "40px", "paddingLeft": "32px", "paddingRight": "32px" },
      { "type": "BORDER", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#000", "borderStyle": "solid" },
      { "type": "RICHTEXT", "name": "styles", "value": "" }
    ],
    "children": [
      {
        "type": "BLOCK",
        "title": "Header",
        "value": "",
        "properties": [
          { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "40px", "paddingLeft": "32px", "paddingRight": "32px" },
          { "type": "padding", "name": "borderRadius", "value": "0px" },
          { "type": "BORDER", "name": "border", "value": false, "borderWidth": "0px", "borderColor": "#d47555", "borderStyle": "solid" },
          { "type": "PIXELS", "name": "width", "value": "100%", "mode": "fixed" },
          { "type": "BACKGROUND", "name": "background", "value": "#4F592B", "mode": "solid", "backgroundPosition": "center", "backgroundSize": "contain" },
          { "type": "RICHTEXT", "name": "styles", "value": "" }
        ],
        "children": [
          {
            "type": "IMAGE",
            "title": "Image",
            "value": "https://ripple-files-cdn.azureedge.net/images/651c243a193f937fe667cd23-7ba419b9-8a69-4924-9103-c0207145696c.png",
            "properties": [
              { "type": "IMAGE", "name": "image", "value": "https://ripple-files-cdn.azureedge.net/images/651c243a193f937fe667cd23-7ba419b9-8a69-4924-9103-c0207145696c.png" },
              { "type": "PADDING", "name": "padding", "value": "8px", "paddingTop": "64px", "paddingBottom": "40px", "paddingLeft": "0px", "paddingRight": "0px" },
              { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
              { "type": "PIXELS", "name": "width", "value": "175px", "mode": "fixed" },
            ]
          },
        ]
      },
      {
        "type": "BLOCK",
        "title": "Hero",
        "value": "",
        "properties": [
          { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "40px", "paddingLeft": "32px", "paddingRight": "32px" },
          { "type": "padding", "name": "borderRadius", "value": "0px" },
          { "type": "BORDER", "name": "border", "value": false, "borderWidth": "0px", "borderColor": "#d47555", "borderStyle": "solid" },
          { "type": "PIXELS", "name": "width", "value": "100%", "mode": "fixed" },
          { "type": "BACKGROUND", "name": "background", "value": "#4F592B", "mode": "solid", "backgroundPosition": "center", "backgroundSize": "contain" },
          { "type": "RICHTEXT", "name": "styles", "value": "" }
        ],
        "children": [
          {
            "type": "TEXT",
            "title": "Text",
            "value": "Happy National\nMatcha Day",
            "properties": [
              { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "'Chivo', sans-serif", "fontSize": "56px", "fontWeight": "500", "lineHeight": "60px", "letterSpacing": "1.5px", "toolbar": true },
              { "type": "COLOR", "name": "color", "value": "#FFF", "toolbar": true },
              { "type": "ALIGNMENT", "name": "textAlign", "value": "center", "toolbar": true },
              { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
              { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "0px", "paddingRight": "0px" },
              { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } }
            ]
          },
          {
            "type": "IMAGE",
            "title": "Image",
            "value": "https://ripple-files-cdn.azureedge.net/images/651c243a193f937fe667cd23-1b2da5bd-7db9-42b1-845e-23b09de5d523.png",
            "properties": [
              { "type": "IMAGE", "name": "image", "value": "https://ripple-files-cdn.azureedge.net/images/651c243a193f937fe667cd23-1b2da5bd-7db9-42b1-845e-23b09de5d523.png" },
              { "type": "PADDING", "name": "padding", "value": "8px", "paddingTop": "8px", "paddingBottom": "8px", "paddingLeft": "0px", "paddingRight": "0px" },
              { "type": "RICHTEXT", "name": "styles", "value": { "margin": "auto", "width": "100%" } },
              { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
            ]
          },
          {
            "type": "TEXT",
            "title": "Text",
            "value": "Celebrate with our matcha blend and get 15% off with code MATCHADAY",
            "properties": [
              { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "'Chivo', sans-serif", "fontSize": "20px", "fontWeight": "400", "lineHeight": "48px", "letterSpacing": "1.5px", "toolbar": true },
              { "type": "COLOR", "name": "color", "value": "#FFF", "toolbar": true },
              { "type": "ALIGNMENT", "name": "textAlign", "value": "center", "toolbar": true },
              { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
              { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "0px", "paddingRight": "0px" },
              { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } }
            ]
          },
          {
            "type": "TEXT",
            "title": "Text",
            "value": "Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test\n\n\nTest test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test",
            "properties": [
              { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "'Chivo', sans-serif", "fontSize": "16px", "fontWeight": "400", "lineHeight": "48px", "letterSpacing": "1.5px", "toolbar": true },
              { "type": "COLOR", "name": "color", "value": "#FFF", "toolbar": true },
              { "type": "ALIGNMENT", "name": "textAlign", "value": "center", "toolbar": true },
              { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
              { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "40px", "paddingLeft": "0px", "paddingRight": "0px" },
              { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } }
            ]
          },
          {
            "type": "BUTTON",
            "title": "Button",
            "value": "Give it a try",
            "properties": [
              { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "'Chivo', sans-serif", "fontSize": "14px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "1.5px" },
              { "type": "COLOR", "name": "color", "value": "#000" },
              { "type": "ALIGNMENT", "name": "textAlign", "value": "center" },
              { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
              { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "8px", "paddingBottom": "8px", "paddingLeft": "50px", "paddingRight": "50px" },
              { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } },
              { "type": "BACKGROUND", "name": "background", "value": "#FFF", "mode": "solid", "backgroundPosition": "center", "backgroundSize": "contain" },
            ]
          },
        ]
      },
      {
        "type": "BLOCK",
        "title": "Products",
        "value": "",
        "properties": [
          { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "40px", "paddingLeft": "32px", "paddingRight": "32px" },
          { "type": "padding", "name": "borderRadius", "value": "0px" },
          { "type": "BORDER", "name": "border", "value": false, "borderWidth": "0px", "borderColor": "#d47555", "borderStyle": "solid" },
          { "type": "PIXELS", "name": "width", "value": "100%", "mode": "fixed" },
          { "type": "BACKGROUND", "name": "background", "value": "#4F592B", "mode": "solid", "backgroundPosition": "center", "backgroundSize": "contain" },
          { "type": "RICHTEXT", "name": "styles", "value": "" }
        ],
        "children": [
          {
            "type": "DATACONTAINER",
            "title": "Products",
            "source": "PRODUCTS",
            "instructions": "Pick 2 products",
            "value": [
              {
                "title": "Test product",
                "imageUrl": "https://ripple-files-cdn.azureedge.net/images/66a2671a3b902f4eb28c61fd-17751200825497-thumbnail.png"
              }
            ],
            "children": [
              {
                "type": "IMAGE",
                "title": "Image",
                "value": "imageUrl",
                "mode": "DYNAMIC",
                "properties": [
                  { "type": "IMAGE", "name": "image", "value": "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-13f95681-1482-47ac-a783-0c9e6b71e1cd.png" },
                  { "type": "PADDING", "name": "padding", "value": "8px", "paddingTop": "8px", "paddingBottom": "8px", "paddingLeft": "0px", "paddingRight": "0px" },
                  { "type": "RICHTEXT", "name": "styles", "value": { "margin": "auto", "width": "100%" } },
                  { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
                ]
              },
              {
                "type": "TEXT",
                "title": "Text",
                "value": "title",
                "mode": "DYNAMIC",
                "properties": [
                  { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "Aspekta", "fontSize": "16px", "fontWeight": "500", "lineHeight": "48px", "letterSpacing": "1.5px" },
                  { "type": "COLOR", "name": "color", "value": "#FFF" },
                  { "type": "ALIGNMENT", "name": "textAlign", "value": "center" },
                  { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
                  { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
                  { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } }
                ]
              },
            ],
            "properties": [
              { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "Aspekta", "fontSize": "41px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "1.5px" },
              { "type": "COLOR", "name": "color", "value": "#FFF" },
              { "type": "ALIGNMENT", "name": "textAlign", "value": "center" },
              { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "0px", "borderColor": "#d47555", "borderStyle": "solid" },
              { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "0px", "paddingRight": "0px" },
              { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } },
              { "type": "PRODUCTS", "name": "styles", "value": "" }
            ]
          },
        ]
      },
    ]
  };

  template2: any = {
    "properties": [
      { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "40px", "paddingLeft": "32px", "paddingRight": "32px" },
      { "type": "BORDER", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#000", "borderStyle": "solid" },
      { "type": "RICHTEXT", "name": "styles", "value": "" }
    ],
    children: [
      {
        "type": "BLOCK",
        "title": "Header",
        "value": "",
        "properties": [
          { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "40px", "paddingLeft": "32px", "paddingRight": "32px" },
          { "type": "padding", "name": "borderRadius", "value": "0px" },
          { "type": "BORDER", "name": "border", "value": false, "borderWidth": "0px", "borderColor": "#d47555", "borderStyle": "solid" },
          { "type": "PIXELS", "name": "width", "value": "100%", "mode": "fixed" },
          { "type": "BACKGROUND", "name": "background", "value": "#4F592B", "mode": "solid", "backgroundPosition": "center", "backgroundSize": "contain" },
          { "type": "RICHTEXT", "name": "styles", "value": "" }
        ],
        "children": [
          {
            "type": "IMAGE",
            "title": "Image",
            "value": "https://ripple-files-cdn.azureedge.net/images/651c243a193f937fe667cd23-7ba419b9-8a69-4924-9103-c0207145696c.png",
            "properties": [
              { "type": "IMAGE", "name": "image", "value": "https://ripple-files-cdn.azureedge.net/images/651c243a193f937fe667cd23-7ba419b9-8a69-4924-9103-c0207145696c.png" },
              { "type": "PADDING", "name": "padding", "value": "8px", "paddingTop": "64px", "paddingBottom": "40px", "paddingLeft": "0px", "paddingRight": "0px" },
              { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
              { "type": "PIXELS", "name": "width", "value": "175px", "mode": "fixed" },
            ]
          },
        ]
      },
      {
        "type": "BLOCK",
        "title": "Hero",
        "value": "",
        "properties": [
          { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "40px", "paddingLeft": "32px", "paddingRight": "32px" },
          { "type": "padding", "name": "borderRadius", "value": "0px" },
          { "type": "BORDER", "name": "border", "value": false, "borderWidth": "0px", "borderColor": "#d47555", "borderStyle": "solid" },
          { "type": "PIXELS", "name": "width", "value": "100%", "mode": "fixed" },
          { "type": "BACKGROUND", "name": "background", "value": "#4F592B", "mode": "solid", "backgroundPosition": "center", "backgroundSize": "contain" },
          { "type": "RICHTEXT", "name": "styles", "value": "" }
        ],
        "children": [
          {
            "type": "TEXT",
            "title": "Text",
            "value": "Happy National\nMatcha Day",
            "properties": [
              { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "'Chivo', sans-serif", "fontSize": "56px", "fontWeight": "500", "lineHeight": "60px", "letterSpacing": "1.5px", "toolbar": true },
              { "type": "COLOR", "name": "color", "value": "#FFF", "toolbar": true },
              { "type": "ALIGNMENT", "name": "textAlign", "value": "center", "toolbar": true },
              { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
              { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "0px", "paddingRight": "0px" },
              { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } }
            ]
          },
          {
            "type": "IMAGE",
            "title": "Image",
            "value": "https://ripple-files-cdn.azureedge.net/images/651c243a193f937fe667cd23-1b2da5bd-7db9-42b1-845e-23b09de5d523.png",
            "properties": [
              { "type": "IMAGE", "name": "image", "value": "https://ripple-files-cdn.azureedge.net/images/651c243a193f937fe667cd23-1b2da5bd-7db9-42b1-845e-23b09de5d523.png" },
              { "type": "PADDING", "name": "padding", "value": "8px", "paddingTop": "8px", "paddingBottom": "8px", "paddingLeft": "0px", "paddingRight": "0px" },
              { "type": "RICHTEXT", "name": "styles", "value": { "margin": "auto", "width": "100%" } },
              { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
            ]
          },
          {
            "type": "TEXT",
            "title": "Text",
            "value": "Celebrate with our matcha blend and get 15% off with code MATCHADAY",
            "properties": [
              { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "'Chivo', sans-serif", "fontSize": "20px", "fontWeight": "400", "lineHeight": "48px", "letterSpacing": "1.5px", "toolbar": true },
              { "type": "COLOR", "name": "color", "value": "#FFF", "toolbar": true },
              { "type": "ALIGNMENT", "name": "textAlign", "value": "center", "toolbar": true },
              { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
              { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "0px", "paddingRight": "0px" },
              { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } }
            ]
          },
          {
            "type": "TEXT",
            "title": "Text",
            "value": "Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test\n\n\nTest test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test",
            "properties": [
              { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "'Chivo', sans-serif", "fontSize": "16px", "fontWeight": "400", "lineHeight": "48px", "letterSpacing": "1.5px", "toolbar": true },
              { "type": "COLOR", "name": "color", "value": "#FFF", "toolbar": true },
              { "type": "ALIGNMENT", "name": "textAlign", "value": "center", "toolbar": true },
              { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
              { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "40px", "paddingLeft": "0px", "paddingRight": "0px" },
              { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } }
            ]
          },
          {
            "type": "BUTTON",
            "title": "Button",
            "value": "Give it a try",
            "properties": [
              { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "'Chivo', sans-serif", "fontSize": "14px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "1.5px" },
              { "type": "COLOR", "name": "color", "value": "#000" },
              { "type": "ALIGNMENT", "name": "textAlign", "value": "center" },
              { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
              { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "8px", "paddingBottom": "8px", "paddingLeft": "50px", "paddingRight": "50px" },
              { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } },
              { "type": "BACKGROUND", "name": "background", "value": "#FFF", "mode": "solid", "backgroundPosition": "center", "backgroundSize": "contain" },
            ]
          },
        ]
      },
      {
        "type": "BLOCK",
        "title": "Products",
        "value": "",
        "properties": [
          { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "40px", "paddingLeft": "32px", "paddingRight": "32px" },
          { "type": "padding", "name": "borderRadius", "value": "0px" },
          { "type": "BORDER", "name": "border", "value": false, "borderWidth": "0px", "borderColor": "#d47555", "borderStyle": "solid" },
          { "type": "PIXELS", "name": "width", "value": "100%", "mode": "fixed" },
          { "type": "BACKGROUND", "name": "background", "value": "#4F592B", "mode": "solid", "backgroundPosition": "center", "backgroundSize": "contain" },
          { "type": "RICHTEXT", "name": "styles", "value": "" }
        ],
        "children": [
          {
            "type": "DATACONTAINER",
            "title": "Products",
            "source": "PRODUCTS",
            "instructions": "Pick 2 products",
            "value": [
              {
                "title": "Test product",
                "imageUrl": "https://ripple-files-cdn.azureedge.net/images/66a2671a3b902f4eb28c61fd-17751200825497-thumbnail.png"
              }
            ],
            "children": [
              {
                "type": "IMAGE",
                "title": "Image",
                "value": "imageUrl",
                "mode": "DYNAMIC",
                "properties": [
                  { "type": "IMAGE", "name": "image", "value": "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-13f95681-1482-47ac-a783-0c9e6b71e1cd.png" },
                  { "type": "PADDING", "name": "padding", "value": "8px", "paddingTop": "8px", "paddingBottom": "8px", "paddingLeft": "0px", "paddingRight": "0px" },
                  { "type": "RICHTEXT", "name": "styles", "value": { "margin": "auto", "width": "100%" } },
                  { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
                ]
              },
              {
                "type": "TEXT",
                "title": "Text",
                "value": "title",
                "mode": "DYNAMIC",
                "properties": [
                  { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "Aspekta", "fontSize": "16px", "fontWeight": "500", "lineHeight": "48px", "letterSpacing": "1.5px" },
                  { "type": "COLOR", "name": "color", "value": "#FFF" },
                  { "type": "ALIGNMENT", "name": "textAlign", "value": "center" },
                  { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
                  { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
                  { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } }
                ]
              },
            ],
            "properties": [
              { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "Aspekta", "fontSize": "41px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "1.5px" },
              { "type": "COLOR", "name": "color", "value": "#FFF" },
              { "type": "ALIGNMENT", "name": "textAlign", "value": "center" },
              { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "0px", "borderColor": "#d47555", "borderStyle": "solid" },
              { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "0px", "paddingRight": "0px" },
              { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } },
              { "type": "PRODUCTS", "name": "styles", "value": "" }
            ]
          },
        ]
      },
    ]
  };

  template3: any = {
    children: [
      {
        "properties": [
          { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "40px", "paddingLeft": "32px", "paddingRight": "32px" },
          { "type": "BORDER", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#000", "borderStyle": "solid" },
          { "type": "RICHTEXT", "name": "styles", "value": "" }
        ],
        "children": [
          {
            "type": "CONTAINER",
            "title": "Container",
            "value": "",
            "properties": [
              { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "40px", "paddingLeft": "32px", "paddingRight": "32px" },
              { "type": "padding", "name": "borderRadius", "value": "0px" },
              { "type": "BORDER", "name": "border", "value": false, "borderWidth": "0px", "borderColor": "#d47555", "borderStyle": "solid" },
              { "type": "PIXELS", "name": "width", "value": "100%", "mode": "fixed" },
              { "type": "BACKGROUND", "name": "background", "value": "#4F592B", "mode": "solid", "backgroundPosition": "center", "backgroundSize": "contain" },
              { "type": "RICHTEXT", "name": "styles", "value": "" }
            ],
            "children": [
              {
                "type": "IMAGE",
                "title": "Image",
                "value": "https://ripple-files-cdn.azureedge.net/images/651c243a193f937fe667cd23-7ba419b9-8a69-4924-9103-c0207145696c.png",
                "source": "LOGO",
                "properties": [
                  { "type": "IMAGE", "name": "image", "value": "https://ripple-files-cdn.azureedge.net/images/651c243a193f937fe667cd23-7ba419b9-8a69-4924-9103-c0207145696c.png" },
                  { "type": "PADDING", "name": "padding", "value": "8px", "paddingTop": "64px", "paddingBottom": "40px", "paddingLeft": "0px", "paddingRight": "0px" },
                  { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
                  { "type": "PIXELS", "name": "width", "value": "175px", "mode": "fixed" },
                ]
              },
              {
                "type": "TEXT",
                "title": "Text",
                "value": "Happy National\nMatcha Day",
                "properties": [
                  { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "'Chivo', sans-serif", "fontSize": "56px", "fontWeight": "500", "lineHeight": "60px", "letterSpacing": "1.5px", "toolbar": true },
                  { "type": "COLOR", "name": "color", "value": "#FFF", "toolbar": true },
                  { "type": "ALIGNMENT", "name": "textAlign", "value": "center", "toolbar": true },
                  { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
                  { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "0px", "paddingRight": "0px" },
                  { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } }
                ]
              },
              {
                "type": "IMAGE",
                "title": "Image",
                "value": "https://ripple-files-cdn.azureedge.net/images/651c243a193f937fe667cd23-1b2da5bd-7db9-42b1-845e-23b09de5d523.png",
                "properties": [
                  { "type": "IMAGE", "name": "image", "value": "https://ripple-files-cdn.azureedge.net/images/651c243a193f937fe667cd23-1b2da5bd-7db9-42b1-845e-23b09de5d523.png" },
                  { "type": "PADDING", "name": "padding", "value": "8px", "paddingTop": "8px", "paddingBottom": "8px", "paddingLeft": "0px", "paddingRight": "0px" },
                  { "type": "RICHTEXT", "name": "styles", "value": { "margin": "auto", "width": "100%" } },
                  { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
                ]
              },
              {
                "type": "TEXT",
                "title": "Text",
                "value": "Celebrate with our matcha blend and get 15% off with code MATCHADAY",
                "properties": [
                  { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "'Chivo', sans-serif", "fontSize": "20px", "fontWeight": "400", "lineHeight": "48px", "letterSpacing": "1.5px", "toolbar": true },
                  { "type": "COLOR", "name": "color", "value": "#FFF", "toolbar": true },
                  { "type": "ALIGNMENT", "name": "textAlign", "value": "center", "toolbar": true },
                  { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
                  { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "0px", "paddingRight": "0px" },
                  { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } }
                ]
              },
              {
                "type": "TEXT",
                "title": "Text",
                "value": "Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test\n\n\nTest test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test Test test test test",
                "properties": [
                  { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "'Chivo', sans-serif", "fontSize": "16px", "fontWeight": "400", "lineHeight": "48px", "letterSpacing": "1.5px", "toolbar": true },
                  { "type": "COLOR", "name": "color", "value": "#FFF", "toolbar": true },
                  { "type": "ALIGNMENT", "name": "textAlign", "value": "center", "toolbar": true },
                  { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
                  { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "40px", "paddingLeft": "0px", "paddingRight": "0px" },
                  { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } }
                ]
              },
              {
                "type": "BUTTON",
                "title": "Button",
                "value": "Give it a try",
                "properties": [
                  { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "'Chivo', sans-serif", "fontSize": "14px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "1.5px" },
                  { "type": "COLOR", "name": "color", "value": "#000" },
                  { "type": "ALIGNMENT", "name": "textAlign", "value": "center" },
                  { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
                  { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "8px", "paddingBottom": "8px", "paddingLeft": "50px", "paddingRight": "50px" },
                  { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } },
                  { "type": "BACKGROUND", "name": "background", "value": "#FFF", "mode": "solid", "backgroundPosition": "center", "backgroundSize": "contain" },
                ]
              },
              {
                "type": "DATACONTAINER",
                "title": "Products",
                "source": "PRODUCTS",
                "instructions": "Pick whichever products the user specifies",
                "value": [
                  {
                    "title": "Test product",
                    "imageUrl": "https://ripple-files-cdn.azureedge.net/images/66a2671a3b902f4eb28c61fd-17751200825497-thumbnail.png"
                  }
                ],
                "children": [
                  {
                    "type": "IMAGE",
                    "title": "Image",
                    "value": "imageUrl",
                    "mode": "DYNAMIC",
                    "properties": [
                      { "type": "IMAGE", "name": "image", "value": "https://ripple-files-cdn.azureedge.net/images/65567f8ed6561192d6b34ce5-13f95681-1482-47ac-a783-0c9e6b71e1cd.png" },
                      { "type": "PADDING", "name": "padding", "value": "8px", "paddingTop": "8px", "paddingBottom": "8px", "paddingLeft": "16px", "paddingRight": "16px" },
                      { "type": "RICHTEXT", "name": "styles", "value": { "margin": "auto", "width": "100%" } },
                      { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
                    ]
                  },
                  {
                    "type": "TEXT",
                    "title": "Text",
                    "value": "title",
                    "mode": "DYNAMIC",
                    "properties": [
                      { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "Aspekta", "fontSize": "16px", "fontWeight": "500", "lineHeight": "48px", "letterSpacing": "1.5px" },
                      { "type": "COLOR", "name": "color", "value": "#FFF" },
                      { "type": "ALIGNMENT", "name": "textAlign", "value": "center" },
                      { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "3px", "borderColor": "#d47555", "borderStyle": "solid" },
                      { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
                      { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } }
                    ]
                  },
                ],
                "properties": [
                  { "type": "FONT", "name": "font", "value": "Aspekta", "fontFamily": "Aspekta", "fontSize": "41px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "1.5px" },
                  { "type": "COLOR", "name": "color", "value": "#FFF" },
                  { "type": "ALIGNMENT", "name": "textAlign", "value": "center" },
                  { "type": "BORDERS", "name": "border", "value": "16px", "borderWidth": "0px", "borderColor": "#d47555", "borderStyle": "solid" },
                  { "type": "PADDING", "name": "padding", "value": "16px", "paddingTop": "16px", "paddingBottom": "16px", "paddingLeft": "16px", "paddingRight": "16px" },
                  { "type": "RICHTEXT", "name": "styles", "value": { "white-space": "pre-wrap" } },
                  { "type": "PRODUCTS", "name": "styles", "value": "" }
                ]
              },
            ]
          }
        ]
      }
    ]
  };

  block1: any = {
    properties: {
      paddingTop: "16px",
      paddingBottom: "16px",
      paddingLeft: "16px",
      paddingRight: "16px",
      styles: ""
    },
    components: [
      {
        type: "TEXT",
        value: "Welcome to the Block Builder",
        properties: {
          fontSize: "24px",
          fontWeight: "600",
          fontFamily: "Arial",
          color: "#000000",
          textAlign: "center",
          paddingTop: "40px",
          paddingBottom: "16px",
          paddingLeft: "0px",
          paddingRight: "0px",
          styles: { 'color': 'red', 'font-size': '24px' }
        }
      },
      {
        type: "TEXT",
        value: "Welcome to the Block Builder",
        properties: {
          fontSize: "24px",
          fontWeight: "600",
          fontFamily: "Arial",
          color: "#000000",
          textAlign: "center",
          paddingTop: "0px",
          paddingBottom: "16px",
          paddingLeft: "0px",
          paddingRight: "0px",
          styles: " "
        }
      },
      {
        type: "IMAGE",
        value: "Welcome to the Block Builder",
        properties: {
          fontSize: "24px",
          fontWeight: "600",
          color: "#000000",
          textAlign: "center",
          paddingTop: "0px",
          paddingBottom: "0px",
          paddingLeft: "0px",
          paddingRight: "0px",
          styles: ""
        }
      },
      {
        type: "BUTTON",
        value: "Welcome to the Block Builder",
        properties: {
          fontSize: "24px",
          fontWeight: "600",
          color: "#000000",
          textAlign: "center",
          paddingTop: "0px",
          paddingBottom: "0px",
          paddingLeft: "0px",
          paddingRight: "0px",
          borderRadius: "4px",
          borderColor: "#000000",
          borderWidth: "1px",
          borderStyle: "solid",
          styles: ""
        }
      }
    ]
  };

  jsonString = "";

  activeComponent: any = null;
  opportunityId: any = null;
  opportunityObject: any = null;
  saving: any = false;
  selectedIndex: number = 0;
  selectedIndexContentMode: number = 0;

  TEXTPROPS = [
    { "type": "TEXTSTYLES", "title": "false" },
    { "type": "FONT", "title": "false" },
    { "type": "ALIGNMENT", "title": "false" },
    { "type": "COLOR", "title": "false" },
    { "type": "PADDING", "title": "false" },
    { "type": "BORDERS", "title": "false" },
  ];

  treeData: any = [
    {
      "title": "Email",
      "key": "abc",
      "children": [
      ]
    }
  ];

  showTree = false;

  testTree = [
    {
      "title": "Parent 1",
      "key": "0",
      children: [
        {
          "title": "Child 1",
          key: "0-0",
          isLeaf: true
        }
      ]
    },
    {
      "title": "Parent 2",
      "key": "1",
      children: [
        {
          "title": "Child 2",
          key: "1-0",
          isLeaf: true
        },
        {
          "title": "Child 3",
          key: "1-1",
          isLeaf: true
        },
        {
          "title": "Child 4",
          key: "1-2",
          isLeaf: true
        }
      ]
    },
    {
      "title": "Parent 3",
      "key": "3",
      children: [
        {
          "title": "Child 5",
          key: "2-0",
          isLeaf: true
        }
      ]
    },
  ];

  defaultSelectedKeys: any = [];

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public sharedService: SharedService,
    private message: NzMessageService,
    private cdr: ChangeDetectorRef
  ) {

  }

  ngOnInit() {
    this.opportunityId = this.route.snapshot.paramMap.get("id");

    if (this.opportunityId) {
      this.apiService.getOpportunity(this.opportunityId).subscribe(
        (data: any) => {
          console.log(data);
          this.opportunityObject = data;
          this.sharedService.newEditorCurrentOpp = data;

          if (!this.opportunityObject.contentTemplate.template) {
            this.opportunityObject.contentTemplate.template = JSON.parse(JSON.stringify(this.template2));
          }

          // async function generateRandomKey(length: any, obj: any) {
          //   console.log("GENERATE KEY");
          //   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          //   let result = '';
          //   const charactersLength = characters.length;

          //   for (let i = 0; i < length; i++) {
          //     result += characters.charAt(Math.floor(Math.random() * charactersLength));
          //   }

          //   obj.key = result;
          //   console.log(obj);
          //   if (!obj.props) {
          //     obj.props = {};
          //   }

          //   if (obj.children) {
          //     for (let child of obj.children) {
          //       generateRandomKey(20, child);
          //     }
          //   }
          // }

          // for (let child of this.opportunityObject.contentTemplate.template.children) {
          //   generateRandomKey(20, child);
          // }

          async function generateNestedId(length: any, obj: any, parentId = '', index = 0) {
            console.log("GENERATE ID");

            // Generate the current ID based on parent ID and current index
            const currentId = parentId ? `${parentId}-${index}` : `${index}`;
            obj.id = currentId;
            console.log(obj);

            // Ensure obj.props exists
            if (!obj.props) {
              obj.props = {};
            }

            // If the object has children, recursively call the function for each child
            if (obj.children) {
              for (let i = 0; i < obj.children.length; i++) {
                generateNestedId(length, obj.children[i], currentId, i);
              }
            } else {
              obj.isLeaf = true;
            }

            if (obj.type == "IMAGE") {
              obj.icon = "file-image";
            }

            if (obj.type == "TEXT") {
              obj.icon = "font-size";
            }

            if (obj.type == "BUTTON") {
              obj.icon = "font-size";
            }

            if (obj.type == "BLOCK") {
              obj.icon = "appstore";
            }

            if (obj.type == "CONTAINER") {
              obj.icon = "border-outer";
            }
          }

          // Assuming this.opportunityObject.contentTemplate.template.children is the root array of children
          for (let i = 0; i < this.opportunityObject.contentTemplate.template.children.length; i++) {
            generateNestedId(20, this.opportunityObject.contentTemplate.template.children[i], '', i);
          }

          this.template = this.opportunityObject.contentTemplate.template;
          this.treeData[0].children = this.opportunityObject.contentTemplate.template.children;
          console.log(this.treeData);
          this.showTree = true;
          this.loading = false;

          this.jsonString = JSON.stringify(this.template, null, 2);


        });
    }

    let filterDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
    this.apiService.getThisWeek().subscribe(
      (data: any) => {
        console.log(data);
        this.thisWeek = data;
        this.loadingThisWeek = false;
      },
      error => {
        console.log(error);
      });

    this.apiService.get14DayPlaceholders().subscribe(
      (data: any) => {
        console.log(data);
        this.toDo = data;
        this.toDoLoading = false;
      },
      error => {
        console.log(error);
      });

    if (this.sharedService.currentUser.adminLevel == 'SUPER') {

      this.apiService.getWorkspace(this.sharedService.currentUser.workspace._id).subscribe(
        (data: any) => {

          if (data.metrics?.WORKSPACECAMPAIGNSSENT) {
            this.campaignsSentMetric = data.metrics?.WORKSPACECAMPAIGNSSENT;
          }

          if (data.metrics?.WORKSPACEOPENRATE) {
            this.openRateMetric = data.metrics?.WORKSPACEOPENRATE;
          }

          if (data.metrics?.WORKSPACECLICKRATE) {
            this.clickRateMetric = data.metrics?.WORKSPACECLICKRATE;
          }

          if (data.metrics?.WORKSPACEATTRIBUTEDORDERS) {
            this.ordersMetric = data.metrics?.WORKSPACEATTRIBUTEDORDERS;
          }

          this.apiService.getMetric("WORKSPACECAMPAIGNSSENT", filterDate).subscribe(
            (data: any) => {
              console.log("CAMPAIGNSSENT", data);
              // this.loading = false;
              this.campaignsSentMetric = data;
              this.loadingCampaignsSentMetric = false;

              this.apiService.getMetric("WORKSPACEOPENRATE", filterDate).subscribe(
                (data: any) => {
                  console.log("OPENRATE", data);
                  // this.loading = false;
                  this.openRateMetric = data;
                  this.loadingOpenRateMetric = false;

                  this.apiService.getMetric("WORKSPACECLICKRATE", filterDate).subscribe(
                    (data: any) => {
                      console.log("CLICKRATE", data);
                      // this.loading = false;
                      this.clickRateMetric = data;
                      this.loadingClickRateMetric = false;

                      this.apiService.getMetric("WORKSPACEATTRIBUTEDORDERS", filterDate).subscribe(
                        (data: any) => {
                          console.log("ATTRIBUTEDORDERS", data);
                          // this.loading = false;
                          this.ordersMetric = data;
                          this.loadingOrdersMetric = false;
                        },
                        error => {
                          console.log(error);
                        });
                    },
                    error => {
                      console.log(error);
                    });
                },
                error => {
                  console.log(error);
                });
            },
            error => {
              console.log(error);
            });

        }, error => {
          console.log(error);
        });
    }
  }

  changeMetricFilter() {

    var filterDate: any = null;

    if (this.metricsFilter == "7DAYS") {
      filterDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    } else if (this.metricsFilter == "30DAYS") {
      filterDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
    } else if (this.metricsFilter == "24HOURS") {
      filterDate = new Date(Date.now() - 24 * 60 * 60 * 1000);
    }

    this.loadingCampaignsSentMetric = true;
    this.loadingOpenRateMetric = true;
    this.loadingClickRateMetric = true;
    this.loadingOrdersMetric = true;

    this.campaignsSentMetric = {};
    this.openRateMetric = {};
    this.clickRateMetric = {};
    this.ordersMetric = {};

    this.apiService.getMetric("WORKSPACECAMPAIGNSSENT", filterDate).subscribe(
      (data: any) => {
        console.log("CAMPAIGNSSENT", data);
        // this.loading = false;
        this.campaignsSentMetric = data;
        this.loadingCampaignsSentMetric = false;

        this.apiService.getMetric("WORKSPACEOPENRATE", filterDate).subscribe(
          (data: any) => {
            console.log("OPENRATE", data);
            // this.loading = false;
            this.openRateMetric = data;
            this.loadingOpenRateMetric = false;

            this.apiService.getMetric("WORKSPACECLICKRATE", filterDate).subscribe(
              (data: any) => {
                console.log("CLICKRATE", data);
                // this.loading = false;
                this.clickRateMetric = data;
                this.loadingClickRateMetric = false;

                this.apiService.getMetric("WORKSPACEATTRIBUTEDORDERS", filterDate).subscribe(
                  (data: any) => {
                    console.log("ATTRIBUTEDORDERS", data);
                    // this.loading = false;
                    this.ordersMetric = data;
                    this.loadingOrdersMetric = false;
                  },
                  error => {
                    console.log(error);
                  });
              },
              error => {
                console.log(error);
              });
          },
          error => {
            console.log(error);
          });
      },
      error => {
        console.log(error);
      });
  };

  updateNestedData() {
    try {
      this.template = JSON.parse(this.jsonString);
      this.opportunityObject.contentTemplate.template = this.template;
    } catch (e) {
      console.error('Invalid JSON:', e);
    }
  }

  clickTree(event: any) {
    console.log(event);
    this.sharedService.activeComponent = event?.node?.origin;
  }

  async applyStyles(target: any, origin: any) {
    console.log("target", target);
    console.log("origin", origin);

    if (target && origin) {

      if (target.mode != "DYNAMIC") {
        target.value = origin.value;
      }

      if (origin.background) {
        target.props.background = origin.background;
      }

      if (origin.color) {
        target.props.color = origin.color;

        if (origin.type == "TEXT") {
          await this.delay(1000);
          console.log("TEXT", origin.color);
          console.log(target);
          // target.quillEditor.format('color', origin.color);
          let quill = target.quillEditor;

          // Get the entire length of the editor content
          let length = quill.getLength();
          console.log(length);

          // Apply the color format to the entire range
          quill.formatText(0, length, 'color', origin.color);

        }
      }

      if (origin.type == "IMAGE") {
        target.imageUrl = origin.image;
      }

      if (origin.children && target.children) {
        for (let i = 0; i < origin.children.length; i++) {
          this.applyStyles(target.children[i], origin.children[i]);
        }
      }
    }
  }

  testFillOutTemplate() {
    console.log(this.template2);
    this.apiService.fillOutTemplate(this.template2, "").subscribe(
      (data: any) => {
        console.log(data);

        let newTemplate = {
          children: data.template
        }

        for (let component in this.template.children) {
          this.applyStyles(this.template.children[component], newTemplate.children[component]);
        }
      });
  }

  delay(ms: any) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async saveTemplate() {
    this.saving = true;
    console.log(this.opportunityObject.contentTemplate.template);

    async function removeQuillEditor(component: any) {
      console.log("REMOVE QUILL");

      if (component.quillEditor) {
        delete component.quillEditor;
      }

      if (component.children) {
        for (let child of component.children) {
          await removeQuillEditor(child);
        }

      }
    }

    for (let child of this.opportunityObject.contentTemplate.template.children) {
      await removeQuillEditor(child);
    }

    this.apiService.saveTemplate(this.opportunityObject).subscribe(
      (data: any) => {
        console.log(data);
        this.saving = false;
        this.message.create("success", `Your changes have been saved`);
        // const currentUrl = this.router.url;
        // const routeConfig = this.router.config;
        // const currentIndex = routeConfig.findIndex(route => route.path === currentUrl);
        // console.log(routeConfig);
        // console.log(currentIndex);

        // if (currentIndex > 0) {
        // } else {
        //   this.router.navigate([`/opportunities/${this.sharedService.rendererOpportunityObject._id}`]);
        // }
      },
      error => {
        console.log(error);
      });
  }

  setQuillAlignment(direction: any) {
    this.sharedService.activeComponent.quillEditor.format('align', null);
    this.sharedService.activeComponent.quillEditor.format('align', direction);
  }

  setQuillColor(property: any) {
    if (this.sharedService.activeComponent.type == "TEXT") {
      this.sharedService.activeComponent.quillEditor.format('color', property.props.color);
      // const quill = this.sharedService.activeComponent.quillEditor;
      // const range = quill.getSelection();

      // // Check if there is a selection
      // if (range && range.length > 0) {
      //   // Apply the color format to the selected text
      //   quill.format('color', property.value);
      // } else {
      //   // Get the entire length of the editor content
      //   const length = quill.getLength();

      //   // Apply the color format to the entire range
      //   quill.formatText(0, length, 'color', property.value);
      // }
    }
  }

  setQuillBold() {
    // Get the current format
    const quill = this.sharedService.activeComponent.quillEditor;
    const currentFormat = quill.getFormat();

    // Toggle format
    quill.format('bold', !currentFormat.bold);
  }

  setQuillItalic() {
    // Get the current format
    const quill = this.sharedService.activeComponent.quillEditor;
    const currentFormat = quill.getFormat();

    // Toggle format
    quill.format('italic', !currentFormat.italic);
  }

  setQuillUnderline() {
    // Get the current format
    const quill = this.sharedService.activeComponent.quillEditor;
    const currentFormat = quill.getFormat();

    // Toggle format
    quill.format('underline', !currentFormat.underline);
  }

  setQuillStrikethrough() {
    // Get the current format
    const quill = this.sharedService.activeComponent.quillEditor;
    const currentFormat = quill.getFormat();

    // Toggle format
    quill.format('strike', !currentFormat.strike);
  }

  setQuillBullets() {
    const quill = this.sharedService.activeComponent.quillEditor;

    // Get the current format of the selection
    const currentFormat = quill.getFormat();

    // If the current format is 'bullet', remove the list formatting
    if (currentFormat.list === 'bullet') {
      quill.format('list', false);
    } else {
      // Otherwise, set the format to 'bullet'
      quill.format('list', 'bullet');
    }
  }

  setQuillOrderedList() {
    const quill = this.sharedService.activeComponent.quillEditor;

    // Get the current format of the selection
    const currentFormat = quill.getFormat();

    // If the current format is 'bullet', remove the list formatting
    if (currentFormat.list === 'ordered') {
      quill.format('list', false);
    } else {
      // Otherwise, set the format to 'bullet'
      quill.format('list', 'ordered');
    }
  }

  compareFn = (o1: any, o2: any): boolean => (o1 && o2 ? o1 === o2 : o1 === o2);

  handleModelChange(index: number, property: any): void {
    if (index == 0) {
      property.props.backgroundMode = "solid";
    }

    if (index == 1) {
      property.props.backgroundMode = "image";
    }
  }

  handleContentModeChange(index: number, property: any): void {
    if (index == 0) {
      property.props.contentMode = "static";
    }

    if (index == 1) {
      property.props.contentMode = "dynamic";
    }
  }

  openPickerSingle(property: any, mode: any) {
    console.log("Open Picker");
    this.sharedService.currentProperty = property;
    this.sharedService.pickerMode = mode;
    this.sharedService.pickerOpen = true;
    this.sharedService.pickerNumber = "SINGLE";
  }

  nzEvent(event: NzFormatBeforeDropEvent): Observable<boolean> {
    console.log("BEFOREDROP");
    console.log(this.testTree);
    console.log(event);

    function findNodeAndParent(tree: any, key: any, parent = null) {
      for (let node of tree) {
        if (node.key === key) {
          return { node, parent };
        }
        if (node.children) {
          const result: any = findNodeAndParent(node.children, key, node);
          if (result) return result;
        }
      }
      return null;
    }
  
    function removeNode(tree: any, key: any) {
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].key === key) {
          return tree.splice(i, 1)[0];
        }
        if (tree[i].children) {
          const result: any = removeNode(tree[i].children, key);
          if (result) return result;
        }
      }
      return null;
    }
  
    // function insertNode(tree: any, node: any, refNodeKey: any, position: any) {
    //   for (let i = 0; i < tree.length; i++) {
    //     if (tree[i].key === refNodeKey) {
    //       if (position == -1) {
    //         tree.splice(i, 0, node);
    //       } else if (position == 1) {
    //         tree.splice(i + 1, 0, node);
    //       }
    //       return true;
    //     }
    //     if (tree[i].children) {
    //       const inserted: any = insertNode(tree[i].children, node, refNodeKey, position);
    //       if (inserted) return true;
    //     }
    //   }
    //   return false;
    // }

    function insertNode(tree: any, node: any, refNodeKey: any, position: any) {
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].key === refNodeKey) {
          if (position === -1) {
            tree.splice(i, 0, node);
          } else if (position === 1) {
            tree.splice(i + 1, 0, node);
          } else if (position === 0) {
            if (!tree[i].children) {
              tree[i].children = [];
            }
            tree[i].children.unshift(node);
          }
          return true;
        }
        if (tree[i].children) {
          const inserted = insertNode(tree[i].children, node, refNodeKey, position);
          if (inserted) return true;
        }
      }
      return false;
    }

    // const node = removeNode(this.testTree, event.dragNode.key);
    // if (node) {
    //   insertNode(this.testTree, node, event.node.key, event.pos);
    // }
    // console.log(this.testTree);
    // this.testTree = JSON.parse(JSON.stringify(this.testTree));

    const node = removeNode(this.template.children, event.dragNode.key);
    if (node) {
      insertNode(this.template.children, node, event.node.key, event.pos);
    }
    console.log(this.template.children);
    
    function removeQuillEditor(component: any) {
      console.log("REMOVE QUILL");

      if (component.quillEditor) {
        delete component.quillEditor;
      }

      if (component.children) {
        for (let child of component.children) {
          removeQuillEditor(child);
        }

      }
    }
    for (let child of this.opportunityObject.contentTemplate.template.children) {
      removeQuillEditor(child);
    }
    this.template.children = JSON.parse(JSON.stringify(this.template.children));
    this.defaultSelectedKeys = [this.sharedService.activeComponent.key];
    // this.cdr.detectChanges();
    return of(false);
  }

  openBlockLibraryV2() {
    this.sharedService.blockLibraryV2Open = true;
  }
}
