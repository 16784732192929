import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { SharedService } from './shared.service';
import posthog from 'posthog-js';
import { MixpanelService } from './mixpanel.service';
import { DynamicCssLoaderService } from './dynamic-css-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'kconnector-web-app';
  constructor(
    public apiService: ApiService,
    public router: Router,
    public sharedService: SharedService,
    public mixPanelService: MixpanelService,
    public cssLoader: DynamicCssLoaderService
  ) { }

  ngOnInit() {
    // posthog.reset(true);
    // posthog.stopSessionRecording();
    let currentUserString = JSON.parse(localStorage.getItem('currentUser') || '{}');
    this.sharedService.currentUser = currentUserString;
    console.log("ngInitCurrentUser: ", currentUserString);
    // posthog.reset(true);
    // posthog.opt_out_capturing();
    posthog.init('phc_FQFxsi3fQPbigxegtBfUiR2hVWLHOVmSMril41NF1hH', { api_host: 'https://app.posthog.com', capture_pageview: false, disable_session_recording: true, disable_persistence: true, session_recording: { maskAllInputs: false } });
    // this.mixPanelService.init();
    // this.mixPanelService.track("App Loaded", {});

    if (currentUserString && currentUserString.email) {
      posthog.identify(currentUserString._id, { email: currentUserString.email });
      posthog.startSessionRecording();
    }

    this.apiService.getCurrentWorkspace().subscribe(
      (data: any) => {
        console.log(data);

        if (data?.brandStyles?.customFonts?.length > 0) {
          for (let font of data.brandStyles.customFonts) {
            if (font && font.url && font.url.length > 0) {
              this.cssLoader.loadStyle(font.url);
            }
          }
        }

      });
    // if (!currentUserString.email.includes("getripple.ai") && !currentUserString.email.includes("tristan.m.dannelongue@gmail.com")) {
    // }
  }
}
