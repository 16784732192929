<div class="page-wrapper">
    <img class="logo-signup" src="../../../assets/rippleLogo.png" />
    <div class="content-wrapper">
        <h2 nz-typography class="setup-title">Welcome to ripple</h2>
        <div class="subtitle"><img class="icon" src="https://cdn3.iconfinder.com/data/icons/social-media-2068/64/_shopping-512.png" />Connect your Shopify account to get started</div>
        <nz-input-group nzAddOnBefore="https://" nzAddOnAfter=".myshopify.com" style="margin-bottom: 16px;">
            <input type="text" nz-input />
        </nz-input-group>
        <button nz-button nzType="primary" nzBlock style="margin-bottom: 24px;" [routerLink]="'/signup/2/64823395c743a7777d2c74a6'">Connect to Shopify</button>
        <div class="disclaimer"><span nz-icon nzType="lock" nzTheme="fill"></span>&nbsp;Ripple protects your data with encryption.</div>
        <div class="disclaimer">Read our privacy policy & terms of service</div>
      
    </div>
</div>