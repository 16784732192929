import { Component } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  newUserObject = {
    email: '',
    password: ''
  }

  constructor(
    public apiService: ApiService
  ) { }

  onSubmit() {
    this.apiService.registerUser(this.newUserObject).subscribe(
      data => {
          // login successful if there's a jwt token in the response
          console.log(data);
      },
      error => {
          console.log(error);
      });
  }
}
