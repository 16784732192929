<div class="page-wrapper">
    <div class="content-wrapper">
        <ng-container *ngIf="this.opportunityObject.stage != 'NEW'">

            <div class="fullscreen-header">
                <ng-container *ngIf="!this.loading">

                    <nz-page-header nzBackIcon (nzBack)="handleBackButton()" class="site-page-header">
                        <!-- <nz-breadcrumb nz-page-header-breadcrumb>
                    <nz-breadcrumb-item><a [routerLink]="['/opportunities/new']">Opportunities</a></nz-breadcrumb-item>
                    <nz-breadcrumb-item>{{this.opportunityObject.title}}</nz-breadcrumb-item>
                </nz-breadcrumb> -->
                        <nz-page-header-title>
                            {{opportunityObject.title}}
                        </nz-page-header-title>

                        <nz-page-header-tags>
                            <ng-container *ngIf="this.opportunityObject.stage == 'LIVE'">

                                <ng-container *ngIf="sendTimeInFuture()">
                                    <nz-tag class="aqua">Published</nz-tag>
                                </ng-container>
                                <ng-container *ngIf="!sendTimeInFuture()">
                                    <nz-tag class="aqua">Published</nz-tag>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="this.opportunityObject.stage != 'LIVE'">
                                <nz-tag>Draft</nz-tag>
                            </ng-container>
                            <!-- <ng-container *ngIf="this.opportunityObject.template == 'FEATUREDPRODUCT'">
                                <nz-tag>Featured product</nz-tag>
                            </ng-container>
                            <ng-container *ngIf="this.opportunityObject.template == 'EDUCATIONAL'">
                                <nz-tag>Educational</nz-tag>
                            </ng-container>
                            <ng-container *ngIf="this.opportunityObject.template == 'FEATUREDCATEGORY'">
                                <nz-tag>Featured category</nz-tag>
                            </ng-container> -->
                        </nz-page-header-tags>
                        <nz-page-header-extra>
                            <!-- <ng-container *ngIf="this.opportunityObject.stage == 'PENDING'">
                                <button nz-button (click)="generateTemplateContent()"><span nz-icon nzType="sync"
                                        nzTheme="outline"></span>Try
                                    again</button>&nbsp;

                            </ng-container> -->
                            <!-- <ng-container *ngIf="this.opportunityObject.stage == 'LIVE'">
                                <a href="https://klaviyo.com/email-editor/{{opportunityObject.externalData.klaviyoTemplate}}/edit"
                                    target="_blank"><button nz-button><span nz-icon nzType="link"
                                            nzTheme="outline"></span>View
                                        in Klaviyo</button></a>&nbsp;
                            </ng-container> -->
                            <!-- <button class="new-block-button" nz-button nzType="primary" (click)="publishOpportunity()">Generate image</button> -->
                            <!-- <ng-container *ngIf="this.opportunityObject.stage == 'LIVE' && sendTimeInFuture()">
                        <button [nzLoading]="publishingOpportunity"
                        (click)="publishOpportunity()" nz-button nzType="primary">Save</button>&nbsp;
                    </ng-container> -->
                            <!-- <button nz-button (click)="duplicateOpportunity()"
                                [nzLoading]="duplicating">Duplicate</button>&nbsp; -->
                            <!-- <button nz-button nz-popover [nzPopoverContent]="contentTemplate"
                                nzPopoverPlacement="bottomRight" nzPopoverTrigger="click" [disabled]="saving"><span
                                    nz-icon nzType="sync" nzTheme="outline"></span>Try again</button> -->
                            <ng-container>
                                &nbsp;

                                <ng-container *ngIf="this.opportunityObject.type == 'SOCIALPOST'">
                                    <nz-button-group>
                                        <button nz-button (click)="saveOpportunity()" [nzLoading]="saving"
                                            nzType="primary">Save</button>
                                        <button nzType="primary" nz-button nz-dropdown [nzDropdownMenu]="menu3"
                                            nzPlacement="bottomRight">
                                            <span nz-icon nzType="ellipsis"></span>
                                        </button>
                                    </nz-button-group>
                                </ng-container>

                                <ng-container *ngIf="this.opportunityObject.type == 'FLOW'">
                                    <button nz-button (click)="saveOpportunity()" [nzLoading]="saving"
                                        style="margin-right: 8px;">Save</button>

                                    <nz-button-group>
                                        <button nz-button nzType="primary" [nzLoading]="publishingOpportunity"
                                            (click)="publishOpportunityTemplate()">Publish</button>
                                        <button nzType="primary" nz-button nz-dropdown [nzDropdownMenu]="menu2"
                                            nzPlacement="bottomRight">
                                            <span nz-icon nzType="ellipsis"></span>
                                        </button>
                                    </nz-button-group>
                                </ng-container>

                                <ng-container *ngIf="this.opportunityObject.type == 'CAMPAIGN'">
                                    <ng-container *ngIf="this.opportunityObject.stage == 'PLACEHOLDER'">
                                        <nz-button-group>
                                            <button nz-button (click)="saveOpportunity()" [nzLoading]="saving"
                                                nzType="primary">Save</button>
                                            <button nzType="primary" nz-button nz-dropdown [nzDropdownMenu]="menu3"
                                                nzPlacement="bottomRight">
                                                <span nz-icon nzType="ellipsis"></span>
                                            </button>
                                        </nz-button-group>
                                    </ng-container>
                                    <ng-container *ngIf="this.opportunityObject.stage != 'PLACEHOLDER'">
                                        <button nz-button (click)="saveOpportunity()" [nzLoading]="saving"
                                            style="margin-right: 8px;">Save</button>
                                        <button nz-button (click)="generateSMSFromEmail()"
                                            [nzLoading]="this.sendingPreview" style="margin-right: 8px;"><span nz-icon
                                                nzType="message" nzTheme="outline"></span>Create SMS</button>
                                        <button nz-button (click)="sendTestEmail()" [nzLoading]="this.sendingPreview"
                                            style="margin-right: 8px;"><span nz-icon nzType="send"
                                                nzTheme="outline"></span>Send test email</button>
                                        <nz-button-group>
                                            <button nz-button nzType="primary" [nzLoading]="publishingOpportunity"
                                                (click)="publishOpportunity()"><span nz-icon nzType="upload"
                                                    nzTheme="outline"></span>Publish</button>
                                            <button nzType="primary" nz-button nz-dropdown [nzDropdownMenu]="menu1"
                                                nzPlacement="bottomRight">
                                                <span nz-icon nzType="ellipsis"></span>
                                            </button>
                                        </nz-button-group>
                                    </ng-container>
                                </ng-container>
                            </ng-container>

                            <!-- <button nzPlacement="bottomRight" nz-button nz-dropdown [nzDropdownMenu]="menu4"
                                style="top: -3px;">
                                <span nz-icon nzType="ellipsis"></span>
                            </button> -->

                            <nz-dropdown-menu #menu1="nzDropdownMenu">
                                <ul nz-menu style="width: 225px;">
                                    <li nz-menu-item (click)="publishOpportunityDraft()"><span nz-icon nzType="upload"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Publish as Draft
                                    </li>
                                    <li nz-menu-item (click)="publishOpportunityTemplate()"><span nz-icon
                                            nzType="upload" nzTheme="outline"></span>&nbsp;&nbsp;Publish as template
                                    </li>
                                    <li nz-menu-item (click)="publishOpportunityShopify()"><span nz-icon nzType="code"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Export to Shopify
                                    </li>
                                    <li nz-menu-item (click)="publishOpportunityHTML()"><span nz-icon nzType="code"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Export HTML
                                    </li>
                                    <li nz-menu-divider></li>
                                    <!-- <li nz-menu-item *ngIf="this.opportunityObject.stage == 'PENDING'"
                                        (click)="generateTemplateContent()"><span nz-icon nzType="sync"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Try again</li> -->
                                    <li nz-menu-item (click)="openNewTemplate()"><span nz-icon nzType="save"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Save as template</li>
                                    <li nz-menu-item (click)="duplicateOpportunity()"><span nz-icon nzType="copy"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Duplicate</li>
                                    <li nz-menu-item (click)="deleteOpportunity()"><span nz-icon nzType="delete"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Delete</li>
                                </ul>
                            </nz-dropdown-menu>

                            <nz-dropdown-menu #menu2="nzDropdownMenu">
                                <ul nz-menu>
                                    <li nz-menu-item (click)="publishOpportunityTemplate()"><span nz-icon
                                            nzType="upload" nzTheme="outline"></span>&nbsp;&nbsp;Publish as template
                                    </li>
                                    <li nz-menu-item (click)="publishOpportunityHTML()"><span nz-icon nzType="code"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Export HTML
                                    </li>
                                    <li nz-menu-divider></li>
                                    <!-- <li nz-menu-item *ngIf="this.opportunityObject.stage == 'PENDING'"
                                        (click)="generateTemplateContent()"><span nz-icon nzType="sync"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Try again</li> -->
                                    <li nz-menu-item (click)="duplicateOpportunity()"><span nz-icon nzType="copy"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Duplicate</li>
                                    <li nz-menu-item (click)="deleteOpportunity()"><span nz-icon nzType="delete"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Delete</li>
                                </ul>
                            </nz-dropdown-menu>

                            <nz-dropdown-menu #menu3="nzDropdownMenu">
                                <ul nz-menu>
                                    <li nz-menu-item (click)="publish()"><span nz-icon nzType="send"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Post now</li>
                                    <li nz-menu-divider></li>
                                    <li nz-menu-item (click)="deleteOpportunity()"><span nz-icon nzType="delete"
                                            nzTheme="outline"></span>&nbsp;&nbsp;Delete</li>
                                </ul>
                            </nz-dropdown-menu>
                            <!-- <nz-dropdown-menu #menu4="nzDropdownMenu">
                                <ul nz-menu>

                                </ul>
                            </nz-dropdown-menu> -->

                        </nz-page-header-extra>

                    </nz-page-header>
                </ng-container>

                <ng-container *ngIf="this.loading">

                </ng-container>

            </div>
            <div class="content-outer">
                <ng-container *ngIf="this.loading">
                    <div class="loading-wrapper">
                        <div class="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!this.loading">
                    <div class="column-left">
                        <div class="content-inner">
                            <div class="inner">
                                <ng-container *ngIf="this.opportunityObject.type == 'SOCIALPOST'">
                                    <div class="custom-card">
                                        <div class="custom-card-body">
                                            <nz-form-item style="margin-bottom: 0px;">
                                                <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                    Content
                                                </nz-form-label>
                                                <nz-form-control nzSpan="24">
                                                    <textarea nz-input name="message"
                                                        [(ngModel)]="opportunityObject.message"
                                                        [nzAutosize]="{ minRows: 2, maxRows: 15 }" [disabled]="opportunityObject.stage == 'LIVE'"></textarea>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </div>
                                    <div class="custom-card">
                                        <div class="custom-card-body">
                                            <nz-form-item>
                                                <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">Post
                                                    time
                                                </nz-form-label>
                                                <nz-form-control nzSpan="24">
                                                    <div nz-row [nzGutter]="16">
                                                        <div nz-col class="gutter-row" [nzSpan]="14">
                                                            <ng-container>
                                                                <nz-date-picker name="sendTime"
                                                                    [(ngModel)]="opportunityObject.sendTime"
                                                                    nzFormat="yyyy-MM-dd" style="width:100%;"
                                                                    [nzDisabledDate]="disablePastDates"
                                                                    [disabled]="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()"></nz-date-picker>
                                                            </ng-container>
                                                        </div>
                                                        <div nz-col class="gutter-row" [nzSpan]="10">
                                                            <ng-container>
                                                                <nz-time-picker name="sendTimeHours"
                                                                    [(ngModel)]="opportunityObject.sendTimeHours"
                                                                    [nzMinuteStep]="15" nzFormat="HH:mm"
                                                                    style="width:100%;"
                                                                    (ngModelChange)="changeTime($event)"
                                                                    [disabled]="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()"></nz-time-picker>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </nz-form-control>
                                            </nz-form-item>
                                            <nz-form-item style="margin-bottom: 0px;">
                                                <nz-form-label [nzNoColon]="true" nzLabelAlign="left"
                                                    nzSpan="24">Platforms
                                                </nz-form-label>
                                                <nz-form-control nzSpan="24">
                                                    <div class="custom-card">
                                                        <div class="custom-card-body">
                                                            <i class="lni lni-facebook"
                                                                style="margin-right: 4px;"></i>Facebook
                                                            <nz-switch style="float: right" name="facebook"
                                                                [(ngModel)]="this.opportunityObject.channels.facebook" [disabled]="opportunityObject.stage == 'LIVE'"></nz-switch>

                                                        </div>
                                                        <ng-container *ngIf="this.opportunityObject.stage == 'LIVE' && this.opportunityObject.externalData?.facebookPostId">
                                                            <div class="custom-card-footer" style="text-align: left">
                                                                Published to Facebook post <nz-tag>{{this.opportunityObject.externalData.facebookPostId}}</nz-tag>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                    <div class="custom-card">
                                                        <div class="custom-card-body">
                                                            <i class="lni lni-instagram"
                                                                style="margin-right: 4px;"></i>Instagram (coming soon)
                                                            <nz-switch style="float: right" name="instagram"
                                                                [(ngModel)]="this.opportunityObject.channels.instagram" disabled></nz-switch>

                                                        </div>
                                                    </div>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </div>
                                    <div class="custom-card">
                                        <div class="custom-card-body">
                                            <nz-form-item>
                                                <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                    Title
                                                </nz-form-label>
                                                <nz-form-control nzSpan="24">
                                                    <input nz-input name="title"
                                                        [(ngModel)]="opportunityObject.title" />
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="this.opportunityObject.type == 'FLOW'">
                                    <form nz-form nzLayout="vertical">
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true">Flow</nz-form-label>
                                            <nz-form-control [nzSpan]="24">
                                                <nz-select nzShowSearch nzPlaceHolder="Select a flow"
                                                    [(ngModel)]="opportunityObject.flow" style="width: 100%;"
                                                    [compareWith]="compareFn3" name="flow">
                                                    <ng-container *ngFor="let flow of this.flowList">
                                                        <nz-option [nzLabel]="flow.name"
                                                            nzValue="{{flow._id}}"></nz-option>
                                                    </ng-container>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                Title
                                            </nz-form-label>
                                            <nz-form-control nzSpan="24">
                                                <input nz-input name="title" [(ngModel)]="opportunityObject.title" />
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                Subject line
                                            </nz-form-label>
                                            <nz-form-control nzSpan="24">
                                                <ng-container
                                                    *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                    <textarea nz-input name="subjectLine"
                                                        [(ngModel)]="opportunityObject.contentTemplate.subjectLine"
                                                        [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                    {{opportunityObject.contentTemplate.subjectLine}}
                                                </ng-container>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                Preview text
                                            </nz-form-label>
                                            <nz-form-control nzSpan="24">
                                                <ng-container
                                                    *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                    <textarea nz-input name="previewtext"
                                                        [(ngModel)]="opportunityObject.contentTemplate.previewText"
                                                        [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                    {{opportunityObject.contentTemplate.previewText}}
                                                </ng-container>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <ng-container
                                            *ngIf="(opportunityObject.externalData && opportunityObject.externalData.klaviyoCampaign) || (opportunityObject.externalData && opportunityObject.externalData.klaviyoTemplate)">
                                            <nz-form-item>
                                                <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                    Connections
                                                </nz-form-label>
                                                <nz-form-control nzSpan="24">
                                                    <ng-container
                                                        *ngIf="(opportunityObject.externalData && opportunityObject.externalData.klaviyoTemplate) && (opportunityObject.externalData && !opportunityObject.externalData.klaviyoCampaign)">

                                                        <div class="custom-card">
                                                            <div class="custom-card-body" style="line-height: 36px">
                                                                Synced to Klaviyo template
                                                                <nz-tag>{{opportunityObject.externalData.klaviyoTemplate}}</nz-tag>
                                                                <a href="https://klaviyo.com/email-editor/{{opportunityObject.externalData.klaviyoTemplate}}/edit"
                                                                    target="_blank" style="float: right"><button
                                                                        nz-button><span nz-icon nzType="link"
                                                                            nzTheme="outline"></span>View
                                                                        in Klaviyo</button></a>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="(opportunityObject.externalData && opportunityObject.externalData.klaviyoCampaign)">

                                                        <div class="custom-card">
                                                            <div class="custom-card-body" style="line-height: 36px">
                                                                Synced to Klaviyo campaign
                                                                <nz-tag>{{opportunityObject.externalData.klaviyoCampaign}}</nz-tag>
                                                                <a href="https://klaviyo.com/campaign/{{opportunityObject.externalData.klaviyoCampaign}}/scheduled"
                                                                    target="_blank" style="float: right"><button
                                                                        nz-button><span nz-icon nzType="link"
                                                                            nzTheme="outline"></span>View
                                                                        in Klaviyo</button></a>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </nz-form-control>

                                            </nz-form-item>
                                        </ng-container>
                                    </form>
                                </ng-container>
                                <ng-container *ngIf="this.opportunityObject.type == 'CAMPAIGN'">
                                    <ng-container *ngIf="!this.loading">
                                        <ng-container *ngIf="this.opportunityObject.stage == 'PLACEHOLDER'">
                                            <nz-form-item>
                                                <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                    Title
                                                </nz-form-label>
                                                <nz-form-control nzSpan="24">
                                                    <input nz-input name="title"
                                                        [(ngModel)]="opportunityObject.title" />
                                                </nz-form-control>
                                            </nz-form-item>
                                            <nz-form-item>
                                                <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">Send
                                                    time
                                                </nz-form-label>
                                                <nz-form-control nzSpan="24">
                                                    <div nz-row [nzGutter]="16">
                                                        <div nz-col class="gutter-row" [nzSpan]="14">
                                                            <ng-container>
                                                                <nz-date-picker name="sendTime"
                                                                    [(ngModel)]="opportunityObject.sendTime"
                                                                    nzFormat="yyyy-MM-dd" style="width:100%;"
                                                                    [nzDisabledDate]="disablePastDates"
                                                                    [disabled]="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()"></nz-date-picker>
                                                            </ng-container>
                                                            <!-- <ng-container
                                                            *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                            {{opportunityObject.sendTime | date:'EEEE M/d/YY h:mm
                                                            a'}}
                                                        </ng-container> -->


                                                        </div>
                                                        <div nz-col class="gutter-row" [nzSpan]="10">
                                                            <ng-container>
                                                                <nz-time-picker name="sendTimeHours"
                                                                    [(ngModel)]="opportunityObject.sendTimeHours"
                                                                    [nzMinuteStep]="15" nzFormat="HH:mm"
                                                                    style="width:100%;"
                                                                    (ngModelChange)="changeTime($event)"
                                                                    [disabled]="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()"></nz-time-picker>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </nz-form-control>
                                            </nz-form-item>
                                            <nz-form-item>
                                                <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                    Type
                                                </nz-form-label>
                                                <nz-form-control nzSpan="24">
                                                    <ng-container *ngIf="opportunityObject.template.name">
                                                        <nz-tag>{{opportunityObject.template.name | titlecase}}</nz-tag>
                                                    </ng-container>
                                                    <ng-container *ngIf="opportunityObject.template.title">
                                                        <nz-tag>{{opportunityObject.template.title |
                                                            titlecase}}</nz-tag>
                                                    </ng-container>
                                                </nz-form-control>
                                            </nz-form-item>
                                            <div nz-row [nzGutter]="16">
                                                <div nz-col class="gutter-row" [nzSpan]="12">
                                                    <nz-form-item>
                                                        <nz-form-label [nzNoColon]="true" nzLabelAlign="left"
                                                            nzSpan="24">Included
                                                            segments
                                                        </nz-form-label>
                                                        <nz-form-control nzSpan="24"
                                                            [nzValidateStatus]="segmentIncludeStatus"
                                                            nzErrorTip="You must select at least one segment to include">


                                                            <nz-select nzPlaceHolder="Choose" [compareWith]="compareFn"
                                                                [(ngModel)]="this.opportunityObject.audienceInclude"
                                                                (ngModelChange)="this.changeSelect()"
                                                                name="audienceInclude" nzMode="multiple">
                                                                <ng-container
                                                                    *ngFor="let segment of klaviyoListsAndSegments">
                                                                    <nz-option nzCustomContent
                                                                        [nzLabel]="segment.attributes.name"
                                                                        [nzValue]="segment">
                                                                        {{segment.attributes.name}} <nz-tag
                                                                            style="float: right"
                                                                            class="aqua">{{segment.type
                                                                            | titlecase}}</nz-tag>
                                                                    </nz-option>
                                                                </ng-container>

                                                                <!-- <nz-option nzLabel="Engaged non-purchasers" nzValue="2"></nz-option>
                                                        <nz-option nzLabel="One-time purchasers" nzValue="3"></nz-option>
                                                        <nz-option nzLabel="Frequent buyers" nzValue="4"></nz-option>
                                                        <nz-option nzLabel="Dormant purchasers" nzValue="5"></nz-option>
                                                        <nz-option nzLabel="Lapsed subscribers" nzValue="6"></nz-option>
                                                        <nz-option nzLabel="VIPs" nzValue="7"></nz-option>
                                                        <nz-option nzLabel="Seasonal shoppers" nzValue="8"></nz-option> -->
                                                            </nz-select>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                <div nz-col class="gutter-row" [nzSpan]="12">
                                                    <nz-form-item>
                                                        <nz-form-label [nzNoColon]="true" nzLabelAlign="left"
                                                            nzSpan="24">Excluded
                                                            segments
                                                        </nz-form-label>
                                                        <nz-form-control nzSpan="24"
                                                            [nzValidateStatus]="segmentIncludeStatus"
                                                            nzErrorTip="You must select at least one segment to include">


                                                            <nz-select nzPlaceHolder="Choose" [compareWith]="compareFn"
                                                                [(ngModel)]="this.opportunityObject.audienceExclude"
                                                                (ngModelChange)="this.changeSelect()"
                                                                name="audienceExclude" nzMode="multiple">
                                                                <ng-container
                                                                    *ngFor="let segment of klaviyoListsAndSegments">
                                                                    <nz-option nzCustomContent
                                                                        [nzLabel]="segment.attributes.name"
                                                                        [nzValue]="segment">
                                                                        {{segment.attributes.name}} <nz-tag
                                                                            style="float: right"
                                                                            class="aqua">{{segment.type
                                                                            | titlecase}}</nz-tag>
                                                                    </nz-option>
                                                                </ng-container>

                                                                <!-- <nz-option nzLabel="Engaged non-purchasers" nzValue="2"></nz-option>
                                                        <nz-option nzLabel="One-time purchasers" nzValue="3"></nz-option>
                                                        <nz-option nzLabel="Frequent buyers" nzValue="4"></nz-option>
                                                        <nz-option nzLabel="Dormant purchasers" nzValue="5"></nz-option>
                                                        <nz-option nzLabel="Lapsed subscribers" nzValue="6"></nz-option>
                                                        <nz-option nzLabel="VIPs" nzValue="7"></nz-option>
                                                        <nz-option nzLabel="Seasonal shoppers" nzValue="8"></nz-option> -->
                                                            </nz-select>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="this.opportunityObject.stage != 'PLACEHOLDER'">

                                            <ng-container *ngIf="this.opportunityObject.stage == 'PUBLISHING'">
                                                <nz-alert nzType="info"
                                                    nzMessage="Copilot is publishing this opportunity, and will notify you when it is live. This may take up to 24 hours."
                                                    nzShowIcon></nz-alert>
                                            </ng-container>
                                            <ng-container *ngIf="this.sharedService.currentUser.adminLevel == 'SUPER'">
                                                <ng-container
                                                    *ngIf="this.opportunityObject.externalData?.klaviyoCampaign && !sendTimeInFuture()">
                                                    <nz-row [nzGutter]="16" style="margin-top: 24px;">
                                                        <nz-col [nzSpan]="6">
                                                            <div class="custom-card">
                                                                <div class="custom-card-body">
                                                                    <!-- <nz-statistic
                                                                        [nzValue]="(receivedEmailMetric.value | number)!"
                                                                        [nzTitle]="'Recipients'"></nz-statistic> -->
                                                                    <div class="metric-title">
                                                                        Recipients
                                                                        <ng-container
                                                                            *ngIf="this.loadingReceivedEmailMetric">
                                                                            <span nz-icon [nzType]="'sync'"
                                                                                [nzSpin]="true"
                                                                                style="float: right; margin-top: 4px;"></span>
                                                                        </ng-container>
                                                                    </div>
                                                                    <ng-container
                                                                        *ngIf="!this.receivedEmailMetric?.value">
                                                                        <nz-skeleton-element nzType="input"
                                                                            [nzActive]="true" nzSize="large"
                                                                            style="width: 90px !important; overflow: hidden; margin-top: 4px;"></nz-skeleton-element>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="this.receivedEmailMetric?.value">
                                                                        <div class="metric-content">
                                                                            {{receivedEmailMetric.value | number}}</div>
                                                                        <div class="metric-subcontent"> <a
                                                                                href="https://www.klaviyo.com/campaign/{{opportunityObject.externalData.klaviyoCampaign}}/reports/recipients?list=sent"
                                                                                target="_blank">See all</a></div>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </nz-col>
                                                        <nz-col [nzSpan]="6">
                                                            <div class="custom-card">
                                                                <div class="custom-card-body">
                                                                    <!-- <nz-statistic
                                                                        [nzValue]="(receivedEmailMetric.value | number)!"
                                                                        [nzTitle]="'Recipients'"></nz-statistic> -->
                                                                    <div class="metric-title">
                                                                        Open rate
                                                                        <ng-container
                                                                            *ngIf="this.loadingOpenRateMetric">
                                                                            <span nz-icon [nzType]="'sync'"
                                                                                [nzSpin]="true"
                                                                                style="float: right; margin-top: 4px;"></span>
                                                                        </ng-container>
                                                                    </div>
                                                                    <ng-container *ngIf="!this.openRateMetric?.value">
                                                                        <nz-skeleton-element nzType="input"
                                                                            [nzActive]="true" nzSize="large"
                                                                            style="width: 90px !important; overflow: hidden; margin-top: 4px;"></nz-skeleton-element>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="this.openRateMetric?.value">
                                                                        <ng-container *ngIf="openRateMetric?.value">
                                                                            <div class="metric-content">
                                                                                {{openRateMetric.value | percent:
                                                                                '1.2-2'}}
                                                                            </div>
                                                                            <div class="metric-subcontent">
                                                                                <a href="https://www.klaviyo.com/campaign/{{opportunityObject.externalData.klaviyoCampaign}}/reports/recipients?list=open"
                                                                                    target="_blank">
                                                                                    {{openRateMetric.extraData.openedCount}}
                                                                                    recipients</a>
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="!openRateMetric?.value">
                                                                            <div class="metric-content">
                                                                                -
                                                                            </div>
                                                                            <div class="metric-subcontent">
                                                                                -
                                                                            </div>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </nz-col>
                                                        <nz-col [nzSpan]="6">
                                                            <div class="custom-card">
                                                                <div class="custom-card-body">
                                                                    <!-- <nz-statistic
                                                                        [nzValue]="(receivedEmailMetric.value | number)!"
                                                                        [nzTitle]="'Recipients'"></nz-statistic> -->
                                                                    <div class="metric-title">
                                                                        Click rate
                                                                        <ng-container
                                                                            *ngIf="this.loadingClickRateMetric">
                                                                            <span nz-icon [nzType]="'sync'"
                                                                                [nzSpin]="true"
                                                                                style="float: right; margin-top: 4px;"></span>
                                                                        </ng-container>
                                                                    </div>
                                                                    <ng-container
                                                                        *ngIf="!this.clickRateMetric?.value && this.clickRateMetric?.value != 0">
                                                                        <nz-skeleton-element nzType="input"
                                                                            [nzActive]="true" nzSize="large"
                                                                            style="width: 90px !important; overflow: hidden; margin-top: 4px;"></nz-skeleton-element>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="this.clickRateMetric?.value || this.clickRateMetric?.value == 0">
                                                                        <ng-container *ngIf="clickRateMetric?.value">
                                                                            <div class="metric-content">
                                                                                {{clickRateMetric.value | percent:
                                                                                '1.2-2'}}
                                                                            </div>
                                                                            <div class="metric-subcontent">
                                                                                <a href="https://www.klaviyo.com/campaign/{{opportunityObject.externalData.klaviyoCampaign}}/reports/recipients?list=clicked"
                                                                                    target="_blank">
                                                                                    {{clickRateMetric.extraData?.clickedCount}}
                                                                                    recipients</a>
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="!clickRateMetric?.value">
                                                                            <div class="metric-content">
                                                                                -
                                                                            </div>
                                                                            <div class="metric-subcontent">
                                                                                -
                                                                            </div>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </nz-col>
                                                        <nz-col [nzSpan]="6">
                                                            <div class="custom-card">
                                                                <div class="custom-card-body">
                                                                    <!-- <nz-statistic
                                                                        [nzValue]="(receivedEmailMetric.value | number)!"
                                                                        [nzTitle]="'Recipients'"></nz-statistic> -->
                                                                    <div class="metric-title">
                                                                        Revenue
                                                                        <ng-container *ngIf="this.loadingOrdersMetric">
                                                                            <span nz-icon [nzType]="'sync'"
                                                                                [nzSpin]="true"
                                                                                style="float: right; margin-top: 4px;"></span>
                                                                        </ng-container>
                                                                    </div>
                                                                    <ng-container
                                                                        *ngIf="!this.ordersMetric?.value && this.ordersMetric?.value != 0">
                                                                        <nz-skeleton-element nzType="input"
                                                                            [nzActive]="true" nzSize="large"
                                                                            style="width: 90px !important; overflow: hidden;"></nz-skeleton-element>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="this.ordersMetric?.value || this.ordersMetric?.value == 0">
                                                                        <div class="metric-content">
                                                                            ${{ordersMetric.value | number}}</div>
                                                                        <div class="metric-subcontent">
                                                                            {{ordersMetric.extraData?.ordersCount}}
                                                                            orders</div>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </nz-col>
                                                    </nz-row>
                                                    <nz-divider></nz-divider>

                                                </ng-container>
                                            </ng-container>
                                            <form nz-form nzLayout="vertical">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                        Title
                                                    </nz-form-label>
                                                    <nz-form-control nzSpan="24">
                                                        <input nz-input name="title"
                                                            [(ngModel)]="opportunityObject.title" />
                                                    </nz-form-control>
                                                </nz-form-item>
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true" nzLabelAlign="left"
                                                        nzSpan="24">Send
                                                        time
                                                    </nz-form-label>
                                                    <nz-form-control nzSpan="24">
                                                        <div nz-row [nzGutter]="16">
                                                            <div nz-col class="gutter-row" [nzSpan]="14">
                                                                <ng-container>
                                                                    <nz-date-picker name="sendTime"
                                                                        [(ngModel)]="opportunityObject.sendTime"
                                                                        nzFormat="yyyy-MM-dd" style="width:100%;"
                                                                        [nzDisabledDate]="disablePastDates"
                                                                        [disabled]="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()"></nz-date-picker>
                                                                </ng-container>
                                                                <!-- <ng-container
                                                                *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                                {{opportunityObject.sendTime | date:'EEEE M/d/YY h:mm
                                                                a'}}
                                                            </ng-container> -->


                                                            </div>
                                                            <div nz-col class="gutter-row" [nzSpan]="10">
                                                                <ng-container>
                                                                    <nz-time-picker name="sendTimeHours"
                                                                        [(ngModel)]="opportunityObject.sendTimeHours"
                                                                        [nzMinuteStep]="15" nzFormat="HH:mm"
                                                                        style="width:100%;"
                                                                        (ngModelChange)="changeTime($event)"
                                                                        [disabled]="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()"></nz-time-picker>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </nz-form-control>
                                                </nz-form-item>

                                                <div nz-row [nzGutter]="16">
                                                    <div nz-col class="gutter-row" [nzSpan]="12">
                                                        <nz-form-item>
                                                            <nz-form-label [nzNoColon]="true" nzLabelAlign="left"
                                                                nzSpan="20">
                                                                <nz-switch
                                                                    [(ngModel)]="this.opportunityObject.localTime"
                                                                    name="localTime"
                                                                    [disabled]="this.opportunityObject.externalData?.klaviyoCampaign"></nz-switch>&nbsp;&nbsp;Send
                                                                in recipient's
                                                                local time
                                                            </nz-form-label>
                                                        </nz-form-item>
                                                    </div>
                                                    <div nz-col class="gutter-row" [nzSpan]="12">
                                                        <nz-form-item>
                                                            <nz-form-label [nzNoColon]="true" nzLabelAlign="left"
                                                                nzSpan="20">
                                                                <nz-switch
                                                                    [(ngModel)]="this.opportunityObject.smartSending"
                                                                    name="smartSending"
                                                                    [disabled]="this.opportunityObject.externalData?.klaviyoCampaign"></nz-switch>&nbsp;&nbsp;Smart
                                                                sending
                                                            </nz-form-label>
                                                        </nz-form-item>
                                                    </div>
                                                </div>
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="20">
                                                        Subject line
                                                        <!-- <button nz-button (click)="saveOpportunity()"
                                                [nzLoading]="saving">Save</button> -->

                                                        <!-- <button style="float: right; margin-left: 16px;" nz-button (click)="showChatDrawer()"
                                *ngIf="!editSubjectLine"><span nz-icon nzType="edit"
                                    nzTheme="outline"></span>Edit</button> -->
                                                    </nz-form-label>

                                                    <nz-form-control nzSpan="24">
                                                        <ng-container
                                                            *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                            <nz-row [nzGutter]="8">
                                                                <nz-col [nzSpan]="22">
                                                                    <input nz-input name="subjectLine"
                                                                        [(ngModel)]="opportunityObject.contentTemplate.subjectLine" />
                                                                </nz-col>
                                                                <nz-col [nzSpan]="2">
                                                                    <button nz-button nzBlock nz-popover
                                                                        [nzPopoverContent]="contentTemplate2"
                                                                        nzPopoverPlacement="bottomRight"
                                                                        nzPopoverTrigger="click"
                                                                        [(nzPopoverVisible)]="subjectLinePopoverVisible"><span
                                                                            nz-icon nzType="sync"
                                                                            nzTheme="outline"></span></button>
                                                                    <ng-template #contentTemplate2>
                                                                        <div
                                                                            style="margin: -12px -16px; overflow: hidden; border-radius: 10px; width: 500px;">
                                                                            <div class="custom-modal-header">
                                                                                Regenerate subject line
                                                                            </div>
                                                                            <div class="custom-modal-body">
                                                                                <textarea
                                                                                    [(ngModel)]="subjectLineFeedback"
                                                                                    nz-input
                                                                                    placeholder="Ex: Make the copy more fun"
                                                                                    style="height: 60px;"
                                                                                    name="subjectLineFeedback"></textarea>
                                                                                <div class="loading-wrapper"
                                                                                    *ngIf="this.loadingSubjectLineFeedback"
                                                                                    style="text-align: center; padding: 24px !important;">
                                                                                    <img src="../../assets/magicWand.gif"
                                                                                        style="width: 125px; height: 125px; margin: auto; margin-bottom: 16px;" />
                                                                                    Generating content...
                                                                                </div>
                                                                                <ng-container
                                                                                    *ngIf="!this.loadingSubjectLineFeedback && showSubjectLines">
                                                                                    <div style="margin-top: 8px;">
                                                                                        <ng-container
                                                                                            *ngFor="let subjectLine of this.subjectLines">
                                                                                            <button nz-button
                                                                                                (click)="selectSubjectLine(subjectLine)"
                                                                                                nzBlock
                                                                                                style="margin-top: 8px;">
                                                                                                {{subjectLine.text}}
                                                                                            </button>
                                                                                        </ng-container>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </div>
                                                                            <div class="custom-modal-footer">
                                                                                <div style="text-align: right">
                                                                                    <button nz-button nzType="primary"
                                                                                        (click)="sendSubjectLineFeedback()"
                                                                                        [nzLoading]="loadingSubjectLineFeedback">Send</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ng-template>
                                                                </nz-col>
                                                            </nz-row>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                            {{opportunityObject.contentTemplate.subjectLine}}
                                                        </ng-container>


                                                        <!-- <ng-container *ngIf="this.editSubjectLine">
                                <div class="feedback-wrapper">
                                    <textarea placeholder="Enter feedback for Copilot" rows="2" nz-input
                                        [(ngModel)]="subjectLineFeedback"></textarea>
                                    <button style="float: right" nz-button (click)="sendSubjectLineFeedback()"
                                        [nzLoading]="loadingSubjectLineFeedback">Send</button>
                                </div>
                            </ng-container> -->
                                                    </nz-form-control>

                                                </nz-form-item>

                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                        Preview text
                                                    </nz-form-label>
                                                    <nz-form-control nzSpan="24">
                                                        <ng-container
                                                            *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                            <textarea nz-input name="previewtext"
                                                                [(ngModel)]="opportunityObject.contentTemplate.previewText"
                                                                [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                            {{opportunityObject.contentTemplate.previewText}}
                                                        </ng-container>
                                                    </nz-form-control>

                                                </nz-form-item>
                                            </form>

                                            <form nz-form nzLayout="vertical">
                                                <div nz-row [nzGutter]="16">
                                                    <div nz-col class="gutter-row" [nzSpan]="12">
                                                        <nz-form-item>
                                                            <nz-form-label [nzNoColon]="true" nzLabelAlign="left"
                                                                nzSpan="24">Included
                                                                segments
                                                            </nz-form-label>
                                                            <nz-form-control nzSpan="24"
                                                                [nzValidateStatus]="segmentIncludeStatus"
                                                                nzErrorTip="You must select at least one segment to include">

                                                                <ng-container
                                                                    *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                                    <nz-select nzPlaceHolder="Choose"
                                                                        [compareWith]="compareFn"
                                                                        [(ngModel)]="this.opportunityObject.audienceInclude"
                                                                        (ngModelChange)="this.changeSelect()"
                                                                        name="audienceInclude" nzMode="multiple">
                                                                        <ng-container
                                                                            *ngFor="let segment of klaviyoListsAndSegments">
                                                                            <nz-option nzCustomContent
                                                                                [nzLabel]="segment.attributes.name"
                                                                                [nzValue]="segment">
                                                                                {{segment.attributes.name}} <nz-tag
                                                                                    style="float: right"
                                                                                    class="aqua">{{segment.type
                                                                                    | titlecase}}</nz-tag>
                                                                            </nz-option>
                                                                        </ng-container>

                                                                        <!-- <nz-option nzLabel="Engaged non-purchasers" nzValue="2"></nz-option>
                                                        <nz-option nzLabel="One-time purchasers" nzValue="3"></nz-option>
                                                        <nz-option nzLabel="Frequent buyers" nzValue="4"></nz-option>
                                                        <nz-option nzLabel="Dormant purchasers" nzValue="5"></nz-option>
                                                        <nz-option nzLabel="Lapsed subscribers" nzValue="6"></nz-option>
                                                        <nz-option nzLabel="VIPs" nzValue="7"></nz-option>
                                                        <nz-option nzLabel="Seasonal shoppers" nzValue="8"></nz-option> -->
                                                                    </nz-select>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                                    <nz-select disabled nzPlaceHolder="Choose"
                                                                        [compareWith]="compareFn"
                                                                        [(ngModel)]="this.opportunityObject.audienceInclude"
                                                                        (ngModelChange)="this.changeSelect()"
                                                                        name="audienceInclude" nzMode="multiple">
                                                                        <ng-container
                                                                            *ngFor="let segment of klaviyoListsAndSegments">
                                                                            <nz-option nzCustomContent
                                                                                [nzLabel]="segment.attributes.name"
                                                                                [nzValue]="segment">
                                                                                {{segment.attributes.name}} <nz-tag
                                                                                    style="float: right"
                                                                                    class="aqua">{{segment.type
                                                                                    | titlecase}}</nz-tag>
                                                                            </nz-option>
                                                                        </ng-container>

                                                                        <!-- <nz-option nzLabel="Engaged non-purchasers" nzValue="2"></nz-option>
                                                        <nz-option nzLabel="One-time purchasers" nzValue="3"></nz-option>
                                                        <nz-option nzLabel="Frequent buyers" nzValue="4"></nz-option>
                                                        <nz-option nzLabel="Dormant purchasers" nzValue="5"></nz-option>
                                                        <nz-option nzLabel="Lapsed subscribers" nzValue="6"></nz-option>
                                                        <nz-option nzLabel="VIPs" nzValue="7"></nz-option>
                                                        <nz-option nzLabel="Seasonal shoppers" nzValue="8"></nz-option> -->
                                                                    </nz-select>
                                                                </ng-container>
                                                                <!-- <ng-container *ngIf="this.opportunityObject.stage == 'PENDING'">
                                        <button style="float: right" nz-button><span nz-icon nzType="edit"
                                                nzTheme="outline"></span>Edit</button>
                                    </ng-container> -->
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                    <div nz-col class="gutter-row" [nzSpan]="12">
                                                        <nz-form-item>
                                                            <nz-form-label [nzNoColon]="true" nzLabelAlign="left"
                                                                nzSpan="24">Excluded
                                                                segments
                                                            </nz-form-label>
                                                            <nz-form-control nzSpan="24">

                                                                <ng-container
                                                                    *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                                    <nz-select nzPlaceHolder="Choose"
                                                                        [compareWith]="compareFn"
                                                                        [(ngModel)]="this.opportunityObject.audienceExclude"
                                                                        (ngModelChange)="this.changeSelect()"
                                                                        name="audienceExclude" nzMode="multiple">
                                                                        <ng-container
                                                                            *ngFor="let segment of klaviyoListsAndSegments">
                                                                            <nz-option nzCustomContent
                                                                                [nzLabel]="segment.attributes.name"
                                                                                [nzValue]="segment">
                                                                                {{segment.attributes.name}} <nz-tag
                                                                                    style="float: right"
                                                                                    class="aqua">{{segment.type
                                                                                    | titlecase}}</nz-tag>
                                                                            </nz-option>
                                                                        </ng-container>

                                                                    </nz-select>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                                    <nz-select disabled nzPlaceHolder="Choose"
                                                                        [compareWith]="compareFn"
                                                                        [(ngModel)]="this.opportunityObject.audienceExclude"
                                                                        (ngModelChange)="this.changeSelect()"
                                                                        name="audienceExclude" nzMode="multiple">
                                                                        <ng-container
                                                                            *ngFor="let segment of klaviyoListsAndSegments">
                                                                            <nz-option nzCustomContent
                                                                                [nzLabel]="segment.attributes.name"
                                                                                [nzValue]="segment">
                                                                                {{segment.attributes.name}} <nz-tag
                                                                                    style="float: right"
                                                                                    class="aqua">{{segment.type
                                                                                    | titlecase}}</nz-tag>
                                                                            </nz-option>
                                                                        </ng-container>
                                                                    </nz-select>
                                                                </ng-container>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                </div>



                                            </form>
                                            <ng-container
                                                *ngIf="(opportunityObject.externalData && opportunityObject.externalData.klaviyoCampaign) || (opportunityObject.externalData && opportunityObject.externalData.klaviyoTemplate)">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                        Connections
                                                    </nz-form-label>
                                                    <nz-form-control nzSpan="24">
                                                        <ng-container
                                                            *ngIf="(opportunityObject.externalData && opportunityObject.externalData.klaviyoTemplate) && (opportunityObject.externalData && !opportunityObject.externalData.klaviyoCampaign)">

                                                            <div class="custom-card">
                                                                <div class="custom-card-body" style="line-height: 36px">
                                                                    Synced to Klaviyo template
                                                                    <nz-tag>{{opportunityObject.externalData.klaviyoTemplate}}</nz-tag>
                                                                    <a href="https://klaviyo.com/email-editor/{{opportunityObject.externalData.klaviyoTemplate}}/edit"
                                                                        target="_blank" style="float: right"><button
                                                                            nz-button><span nz-icon nzType="link"
                                                                                nzTheme="outline"></span>View
                                                                            in Klaviyo</button></a>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="(opportunityObject.externalData && opportunityObject.externalData.klaviyoCampaign)">

                                                            <div class="custom-card">
                                                                <div class="custom-card-body" style="line-height: 36px">
                                                                    Synced to Klaviyo campaign
                                                                    <nz-tag>{{opportunityObject.externalData.klaviyoCampaign}}</nz-tag>
                                                                    <a href="https://klaviyo.com/campaign/{{opportunityObject.externalData.klaviyoCampaign}}/scheduled"
                                                                        target="_blank" style="float: right"><button
                                                                            nz-button><span nz-icon nzType="link"
                                                                                nzTheme="outline"></span>View
                                                                            in Klaviyo</button></a>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </nz-form-control>

                                                </nz-form-item>
                                            </ng-container>

                                            <nz-divider></nz-divider>

                                            <!-- <nz-form-item>
                                                <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                    Follow ups
                                                </nz-form-label>
                                                <nz-form-control nzSpan="24">
                                                    <ng-container
                                                        *ngFor="let followUp of this.opportunityObject.followUps; let index=index">
                                                        <div class="custom-card">
                                                            <div class="custom-card-header" style="text-align: right">
                                                                <span nz-icon nzType="close" nzTheme="outline"
                                                                    class="remove"
                                                                    (click)="removeItemFromArray(index, this.opportunityObject.followUps)"></span>
                                                            </div>
                                                            <div class="custom-card-body" style="line-height: 36px">
                                                                <div nz-row [nzGutter]="16">
                                                                    <div nz-col class="gutter-row" [nzSpan]="6">
                                                                        <nz-form-item>
                                                                            <nz-form-label [nzNoColon]="true"
                                                                                nzLabelAlign="left" nzSpan="24">
                                                                                Delay (hours)
                                                                            </nz-form-label>
                                                                            <nz-form-control nzSpan="24">
                                                                                <nz-input-number style="width: 100%;"
                                                                                    [(ngModel)]="followUp.delay"></nz-input-number>
                                                                            </nz-form-control>
                                                                        </nz-form-item>
                                                                    </div>
                                                                    <div nz-col class="gutter-row" [nzSpan]="18">
                                                                        <nz-form-item>
                                                                            <nz-form-label [nzNoColon]="true"
                                                                                nzLabelAlign="left" nzSpan="24">
                                                                                Criteria
                                                                            </nz-form-label>
                                                                            <nz-form-control nzSpan="24">
                                                                                <div nz-row [nzGutter]="16">
                                                                                    <div nz-col class="gutter-row"
                                                                                        [nzSpan]="24">
                                                                                        <nz-select
                                                                                            [(ngModel)]="followUp.criteria"
                                                                                            [compareWith]="compareFn2">
                                                                                            <nz-option
                                                                                                [nzValue]="'OPENEDEMAIL'"
                                                                                                nzLabel="Did not open email"></nz-option>
                                                                                            <nz-option
                                                                                                [nzValue]="'CLICKEDEMAIL'"
                                                                                                nzLabel="Opened email but did not click"></nz-option>
                                                                                            <nz-option
                                                                                                [nzValue]="'PLACEDORDER'"
                                                                                                nzLabel="Clicked email but did not place order"></nz-option>
                                                                                        </nz-select>
                                                                                    </div>
                                                                                </div>
                                                                            </nz-form-control>
                                                                        </nz-form-item>
                                                                    </div>
                                                                </div>
                                                                <nz-form-item>
                                                                    <nz-form-label [nzNoColon]="true"
                                                                        nzLabelAlign="left" nzSpan="24">
                                                                        Subject line
                                                                    </nz-form-label>
                                                                    <nz-form-control nzSpan="24">
                                                                        <ng-container
                                                                            *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                                            <textarea nz-input name="subjectLine"
                                                                                [(ngModel)]="followUp.subjectLine"
                                                                                [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                                            {{followUp.subjectLine}}
                                                                        </ng-container>
                                                                    </nz-form-control>
                                                                </nz-form-item>
                                                                <nz-form-item>
                                                                    <nz-form-label [nzNoColon]="true"
                                                                        nzLabelAlign="left" nzSpan="24">
                                                                        Unique content
                                                                    </nz-form-label>
                                                                    <nz-form-control nzSpan="24">
                                                                        <div nz-row [nzGutter]="16">
                                                                            <div nz-col class="gutter-row" [nzSpan]="12"
                                                                                style="line-height: 36px">
                                                                                <nz-switch
                                                                                    [(ngModel)]="followUp.uniqueContent"
                                                                                    [nzLoading]="followUp.addingUniqueContent"
                                                                                    (ngModelChange)="toggleFollowUpUniqueContent(followUp)"></nz-switch>
                                                                            </div>
                                                                            <div nz-col class="gutter-row" [nzSpan]="12"
                                                                                style="text-align: right">
                                                                                <button *ngIf="followUp.uniqueContent"
                                                                                    nz-button
                                                                                    [routerLink]="['/editor',followUp.opportunity]"
                                                                                    nzBock> <span nz-icon nzType="edit"
                                                                                        nzTheme="outline"></span>Edit
                                                                                    content</button>
                                                                            </div>
                                                                        </div>
                                                                    </nz-form-control>
                                                                </nz-form-item>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <button nz-button nzBlock (click)="addFollowUp()"><span nz-icon
                                                            nzType="plus" nzTheme="outline"></span>Add
                                                        follow
                                                        up</button>
                                                </nz-form-control>

                                            </nz-form-item>
                                            <nz-divider></nz-divider> -->

                                            <nz-form-item>
                                                <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                                                    Segmented versions
                                                </nz-form-label>
                                                <nz-form-control nzSpan="24">
                                                    <ng-container
                                                        *ngFor="let followUp of this.opportunityObject.segmentVersions; let index=index">
                                                        <div class="custom-card">
                                                            <div class="custom-card-header" style="text-align: right">
                                                                <span nz-icon nzType="close" nzTheme="outline"
                                                                    class="remove"
                                                                    (click)="removeItemFromArray(index, this.opportunityObject.segmentVersions)"></span>
                                                            </div>
                                                            <ng-container *ngIf="followUp.loading">
                                                                <div class="loading-wrapper">
                                                                    <div class="lds-ellipsis">
                                                                        <div></div>
                                                                        <div></div>
                                                                        <div></div>
                                                                        <div></div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngIf="!followUp.loading">
                                                                <ng-container *ngIf="!followUp.opportunity">
                                                                    <div class="custom-card-body">
                                                                        <nz-form-item>
                                                                            <nz-form-label [nzNoColon]="true"
                                                                                nzLabelAlign="left" nzSpan="24">Segment
                                                                            </nz-form-label>
                                                                            <nz-form-control nzSpan="24"
                                                                                [nzValidateStatus]="segmentIncludeStatus"
                                                                                nzErrorTip="You must select at least one segment to include">

                                                                                <ng-container
                                                                                    *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                                                    <nz-select nzPlaceHolder="Choose"
                                                                                        [compareWith]="compareFn"
                                                                                        [(ngModel)]="this.opportunityObject.audienceInclude"
                                                                                        (ngModelChange)="this.changeSelect()"
                                                                                        name="audienceInclude"
                                                                                        nzShowSearch nzMode="multiple">
                                                                                        <ng-container
                                                                                            *ngFor="let segment of klaviyoListsAndSegments">
                                                                                            <nz-option nzCustomContent
                                                                                                [nzLabel]="segment.attributes.name"
                                                                                                [nzValue]="segment">
                                                                                                {{segment.attributes.name}}
                                                                                                <nz-tag
                                                                                                    style="float: right"
                                                                                                    class="aqua">{{segment.type
                                                                                                    |
                                                                                                    titlecase}}</nz-tag>
                                                                                            </nz-option>
                                                                                        </ng-container>
                                                                                    </nz-select>
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                                                    <nz-select disabled
                                                                                        nzPlaceHolder="Choose"
                                                                                        [compareWith]="compareFn"
                                                                                        [(ngModel)]="this.opportunityObject.audienceInclude"
                                                                                        (ngModelChange)="this.changeSelect()"
                                                                                        name="audienceInclude"
                                                                                        nzMode="multiple"
                                                                                        [nzMaxTagCount]="2"
                                                                                        [nzMaxTagPlaceholder]="tagPlaceHolder">
                                                                                        <ng-container
                                                                                            *ngFor="let segment of klaviyoListsAndSegments">
                                                                                            <nz-option nzCustomContent
                                                                                                [nzLabel]="segment.attributes.name"
                                                                                                [nzValue]="segment">
                                                                                                {{segment.attributes.name}}
                                                                                                <nz-tag
                                                                                                    style="float: right"
                                                                                                    class="aqua">{{segment.type
                                                                                                    |
                                                                                                    titlecase}}</nz-tag>
                                                                                            </nz-option>
                                                                                        </ng-container>
                                                                                    </nz-select>
                                                                                </ng-container>
                                                                            </nz-form-control>
                                                                        </nz-form-item>
                                                                        <nz-form-item>
                                                                            <nz-form-label [nzNoColon]="true"
                                                                                nzLabelAlign="left" nzSpan="24">Context
                                                                                (optional)
                                                                            </nz-form-label>
                                                                            <nz-form-control nzSpan="24"
                                                                                [nzValidateStatus]="segmentIncludeStatus"
                                                                                nzErrorTip="You must select at least one segment to include">
                                                                                <textarea nz-input name="feedback"
                                                                                    [(ngModel)]="followUp.feedback"
                                                                                    [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>

                                                                            </nz-form-control>
                                                                        </nz-form-item>
                                                                    </div>
                                                                    <div class="custom-card-footer">
                                                                        <button nz-button nzType="primary"
                                                                            [nzLoading]="publishingOpportunity"
                                                                            (click)="generateSegmentVersion(followUp)">Generate</button>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container *ngIf="followUp.opportunity">
                                                                    <div class="custom-card-body"
                                                                        style="line-height: 36px">
                                                                        <div nz-row [nzGutter]="16">
                                                                            <div nz-col class="gutter-row"
                                                                                [nzSpan]="19">
                                                                                <nz-form-item>
                                                                                    <nz-form-label [nzNoColon]="true"
                                                                                        nzLabelAlign="left"
                                                                                        nzSpan="24">Segment
                                                                                    </nz-form-label>
                                                                                    <nz-form-control nzSpan="24"
                                                                                        [nzValidateStatus]="segmentIncludeStatus"
                                                                                        nzErrorTip="You must select at least one segment to include">

                                                                                        <ng-container
                                                                                            *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                                                            <nz-select
                                                                                                nzPlaceHolder="Choose"
                                                                                                [compareWith]="compareFn"
                                                                                                [(ngModel)]="followUp.segment"
                                                                                                name="segment"
                                                                                                nzShowSearch
                                                                                                nzMode="multiple">
                                                                                                <ng-container
                                                                                                    *ngFor="let segment of klaviyoListsAndSegments">
                                                                                                    <nz-option
                                                                                                        nzCustomContent
                                                                                                        [nzLabel]="segment.attributes.name"
                                                                                                        [nzValue]="segment">
                                                                                                        {{segment.attributes.name}}
                                                                                                        <nz-tag
                                                                                                            style="float: right"
                                                                                                            class="aqua">{{segment.type
                                                                                                            |
                                                                                                            titlecase}}</nz-tag>
                                                                                                    </nz-option>
                                                                                                </ng-container>
                                                                                            </nz-select>
                                                                                        </ng-container>
                                                                                        <ng-container
                                                                                            *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                                                            <nz-select disabled
                                                                                                nzPlaceHolder="Choose"
                                                                                                [compareWith]="compareFn"
                                                                                                [(ngModel)]="followUp.segment"
                                                                                                name="segment"
                                                                                                [nzMaxTagCount]="2"
                                                                                                [nzMaxTagPlaceholder]="tagPlaceHolder">
                                                                                                <ng-container
                                                                                                    *ngFor="let segment of klaviyoListsAndSegments">
                                                                                                    <nz-option
                                                                                                        nzCustomContent
                                                                                                        [nzLabel]="segment.attributes.name"
                                                                                                        [nzValue]="segment">
                                                                                                        {{segment.attributes.name}}
                                                                                                        <nz-tag
                                                                                                            style="float: right"
                                                                                                            class="aqua">{{segment.type
                                                                                                            |
                                                                                                            titlecase}}</nz-tag>
                                                                                                    </nz-option>
                                                                                                </ng-container>
                                                                                            </nz-select>
                                                                                        </ng-container>
                                                                                    </nz-form-control>
                                                                                </nz-form-item>
                                                                            </div>
                                                                            <div nz-col class="gutter-row" [nzSpan]="5"
                                                                                style="padding-top: 40px;">
                                                                                <button nz-button
                                                                                    [routerLink]="['/editor',followUp.opportunity]"
                                                                                    nzBock>
                                                                                    <span nz-icon nzType="edit"
                                                                                        nzTheme="outline"></span>Edit
                                                                                    content</button>
                                                                            </div>
                                                                        </div>
                                                                        <nz-form-item>
                                                                            <nz-form-label [nzNoColon]="true"
                                                                                nzLabelAlign="left" nzSpan="24">
                                                                                Subject line
                                                                            </nz-form-label>
                                                                            <nz-form-control nzSpan="24">
                                                                                <ng-container
                                                                                    *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                                                    <textarea nz-input
                                                                                        name="subjectLine"
                                                                                        [(ngModel)]="followUp.subjectLine"
                                                                                        [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                                                    {{followUp.subjectLine}}
                                                                                </ng-container>
                                                                            </nz-form-control>
                                                                        </nz-form-item>
                                                                        <nz-form-item>
                                                                            <nz-form-label [nzNoColon]="true"
                                                                                nzLabelAlign="left" nzSpan="24">
                                                                                Preview text
                                                                            </nz-form-label>
                                                                            <nz-form-control nzSpan="24">
                                                                                <ng-container
                                                                                    *ngIf="this.opportunityObject.stage == 'PENDING' || (this.opportunityObject.stage == 'LIVE' && sendTimeInFuture())">
                                                                                    <textarea nz-input
                                                                                        name="previewText"
                                                                                        [(ngModel)]="followUp.previewText"
                                                                                        [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="this.opportunityObject.stage == 'LIVE' && !sendTimeInFuture()">
                                                                                    {{followUp.previewText}}
                                                                                </ng-container>
                                                                            </nz-form-control>
                                                                        </nz-form-item>
                                                                        <nz-form-item
                                                                            style="margin-bottom: 0px !important">
                                                                            <nz-form-control nzSpan="24">

                                                                                <ng-container
                                                                                    *ngIf="(followUp.opportunityObject && followUp.opportunityObject.externalData && followUp.opportunityObject.externalData.klaviyoTemplate) && (followUp.opportunityObject.externalData && !followUp.opportunityObject.externalData.klaviyoCampaign)">

                                                                                    <div class="custom-card">
                                                                                        <div class="custom-card-body"
                                                                                            style="line-height: 36px">
                                                                                            Synced to Klaviyo template
                                                                                            <nz-tag>{{followUp.opportunityObject.externalData.klaviyoTemplate}}</nz-tag>
                                                                                            <a href="https://klaviyo.com/email-editor/{{followUp.opportunityObject.externalData.klaviyoTemplate}}/edit"
                                                                                                target="_blank"
                                                                                                style="float: right"><button
                                                                                                    nz-button><span
                                                                                                        nz-icon
                                                                                                        nzType="link"
                                                                                                        nzTheme="outline"></span>View
                                                                                                    in
                                                                                                    Klaviyo</button></a>
                                                                                        </div>
                                                                                    </div>
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="followUp.opportunityObject && (followUp.opportunityObject.externalData && followUp.opportunityObject.externalData.klaviyoCampaign)">

                                                                                    <div class="custom-card">
                                                                                        <div class="custom-card-body"
                                                                                            style="line-height: 36px">
                                                                                            Synced to Klaviyo campaign
                                                                                            <nz-tag>{{followUp.opportunityObject.externalData.klaviyoCampaign}}</nz-tag>
                                                                                            <a href="https://klaviyo.com/campaign/{{followUp.opportunityObject.externalData.klaviyoCampaign}}/scheduled"
                                                                                                target="_blank"
                                                                                                style="float: right"><button
                                                                                                    nz-button><span
                                                                                                        nz-icon
                                                                                                        nzType="link"
                                                                                                        nzTheme="outline"></span>View
                                                                                                    in
                                                                                                    Klaviyo</button></a>
                                                                                        </div>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </nz-form-control>
                                                                        </nz-form-item>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                    <button nz-button nzBlock (click)="addSegmentVersion()"
                                                        [nzLoading]="addingSegmentVersion"><span nz-icon nzType="plus"
                                                            nzTheme="outline"></span>Add segment</button>
                                                </nz-form-control>

                                            </nz-form-item>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </div>

                        </div>
                    </div>
                    <!-- <div *ngIf="this.sharedService.publishingEmailOpen">Publishing...</div> -->
                    <div class="column-right">
                        <ng-container *ngIf="this.loading">
                            <div style="max-width: 800px; margin: auto; margin-top: 60px;">
                                <div class="custom-card">
                                    <div class="custom-card-body">
                                        <nz-skeleton [nzParagraph]="{ rows: 2 }" [nzActive]="true"></nz-skeleton>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!this.loading">
                            <div class="column-right-header">
                                <button [routerLink]="['/editor',opportunityObject._id]" style="float: right;"
                                    [disabled]="saving" nz-button><span nz-icon nzType="edit"
                                        nzTheme="outline"></span>Edit</button>
                            </div>
                            <div class="social-preview">
                                <div class="custom-card">
                                    <div class="social-preview-header">
                                        <img class="sidebar-footer-image"
                                            src="{{this.sharedService.currentUser.workspace.brandStyles.logo}}" />
                                        {{this.sharedService.currentUser.workspace.name}}
                                    </div>
                                    <div class="social-preview-content">
                                        {{this.opportunityObject.message}}
                                    </div>
                                    <div class="social-preview-image-wrapper">
                                        <img class="social-preview-image" [src]="this.opportunityObject.thumbnail"
                                            [routerLink]="['/editor',opportunityObject._id]" />
                                    </div>
                                    <!-- <button nz-button class="edit-button">Edit</button> -->
                                    <div class="custom-card-footer">
                                        <span style="margin-right: 16px;"><i class="lni lni-thumbs-up"
                                                style="margin-right: 4px;"></i>Like </span>
                                        <span><i class="lni lni-share" style="margin-right: 4px;"></i>Share</span>
                                    </div>
                                </div>
                            </div>
                            <!-- <ng-container *ngIf="this.opportunityObject.stage != 'PLACEHOLDER'">

                                <div class="column-right-header">
                                    <button [routerLink]="['/editor',opportunityObject._id]" style="float: right;"
                                        [disabled]="saving" nz-button><span nz-icon nzType="edit"
                                            nzTheme="outline"></span>Edit</button>
                                </div>
                                <div class="renderer-wrapper">
                                    <app-email-renderer [mode]="'VIEW'"></app-email-renderer>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="this.opportunityObject.stage == 'PLACEHOLDER'">
                                <div class="renderer-wrapper" style="text-align:center; padding-top: 300px">
                                    <button nz-button nzType="primary" (click)="createEmailFromPlaceholder()">Generate
                                        email</button>
                                </div>
                            </ng-container> -->



                        </ng-container>

                    </div>
                </ng-container>
            </div>
        </ng-container>

    </div>
</div>

<nz-drawer nzWidth="450px" [nzClosable]="false" [nzVisible]="chatDrawerVisible" nzPlacement="right"
    (nzOnClose)="hideChatDrawer()">
    <ng-container *nzDrawerContent>
        <ng-container *ngFor="let message of chatLog">
            <ng-container *ngIf="message.sender == 'RIPPLE'">
                <div class="message-bubble-ripple">{{message.message}}</div>
            </ng-container>

            <ng-container *ngIf="message.sender == 'USER'">
                <div class="message-bubble-user-wrapper">
                    <div class="message-bubble-user">{{message.message}}</div>
                </div>
            </ng-container>
        </ng-container>
        <div class="message-bubble-ripple" *ngIf="loadingSubjectLineFeedback">
            <div class="typing">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>
        <div class="drawer-footer-wrapper">
            <textarea style="width: calc(100% - 72px)" placeholder="Enter feedback for Copilot" rows="1" nz-input
                [(ngModel)]="subjectLineFeedback"></textarea>
            <button style="float: right" nz-button (click)="sendSubjectLineFeedback()"
                [disabled]="loadingSubjectLineFeedback" nzType="primary">Send</button>
        </div>
    </ng-container>
</nz-drawer>


<ng-template #tagPlaceHolder let-selectedList>...</ng-template>
<ng-template #contentTemplate>
    <div>
        <nz-form-item style="width: 300px; margin-bottom: 8px !important;">
            <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
                Feedback (optional)
            </nz-form-label>
            <nz-form-control nzSpan="24">
                <textarea [(ngModel)]="subjectLineFeedback" nz-input placeholder="Ex: Make the copy more fun"
                    style="height: 100px;"></textarea>
            </nz-form-control>
        </nz-form-item>
        <div style="text-align: right">
            <button nzType="primary" nz-button (click)="sendSubjectLineFeedback()">Send</button>
        </div>
        <!-- <div style="text-align: right">
            <button nzType="primary" nz-button nz-popconfirm
            nzPopconfirmTitle="This will overwrite any changes you've made in the editor"
            (nzOnConfirm)="sendSubjectLineFeedback()"
            nzPopconfirmPlacement="bottomRight">Send</button>
        </div> -->
    </div>
</ng-template>