<div class="page-wrapper">
    <div class="header-wrapper">
        <nz-page-header class="site-page-header">
            <nz-page-header-title>Content</nz-page-header-title>
            <nz-page-header-extra>

            </nz-page-header-extra>
        </nz-page-header>
    </div>
    <div class="content-wrapper">
        <!-- <div class="column-left">
            <div class="navbar">
                
            </div>
        </div> -->
        <div class="filterBar">
            <ul nz-menu nzMode="horizontal" style="background: #FAFAFA">
                <li nz-menu-item routerLink="/content/templates" routerLinkActive="ant-menu-item-selected">
                    <i class="lni lni-empty-file"
                        style="float: left; margin-top: 12px;"></i>&nbsp;&nbsp;<span>Templates</span>
                </li>
                <li nz-menu-item routerLink="/content/blocks" routerLinkActive="ant-menu-item-selected">
                    <i class="lni lni-grid-alt" style="float: left; margin-top: 12px;"></i><span>&nbsp;&nbsp;Saved
                        blocks</span>
                </li>
            </ul>
        </div>

        <!-- <div class="column-left">
            <ul nz-menu nzMode="horizontal" style="background: #FAFAFA">
                <li nz-menu-item routerLink="/content/templates" routerLinkActive="ant-menu-item-selected">
                    <i class="lni lni-empty-file"
                        style="float: left; margin-top: 12px;"></i>&nbsp;&nbsp;<span>Templates</span>
                </li>
                <li nz-menu-item routerLink="/content/blocks" routerLinkActive="ant-menu-item-selected">
                    <i class="lni lni-grid-alt" style="float: left; margin-top: 12px;"></i><span>&nbsp;&nbsp;Saved
                        blocks</span>
                </li>
            </ul>
        </div> -->

        <div class="column-right">

            <router-outlet></router-outlet>
        </div>
    </div>
</div>