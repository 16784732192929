import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { SharedService } from '../shared.service';
import Quill from 'quill';

@Component({
  selector: 'app-component-renderer',
  templateUrl: './component-renderer.component.html',
  styleUrls: ['./component-renderer.component.scss']
})
export class ComponentRendererComponent {
  @Input() data: any;
  @Input() childData: any;
  modules: any = {
    // 'emoji-shortname': true,
    // 'emoji-textarea': true,
    // 'emoji-toolbar': true,
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'align': [] }],
      // ['emoji'],
      ['clean']                                      // remove formatting button
    ]
  };
  modules2: any = {
    // 'emoji-shortname': true,
    // 'emoji-textarea': true,
    // 'emoji-toolbar': true,
    toolbar: false
  };
  placeholder = "";
  activeStyles = {
    outline: "2px solid #00809C"
  };

  constructor(
    public sharedService: SharedService,
    private cdr: ChangeDetectorRef
  ) { }

  onEditorCreated(quill: any) {
    this.data.quillEditor = quill;
    console.log(this.data);

    quill.formatText(0, quill.getLength(), {
      'align': this.data.props.textAlign // Aligns text to the center
    });
  }

  checkIfActive() {
    return this.sharedService.activeComponent?.key == this.data?.key;
  }

  addButton(id: any) {
    this.sharedService.addButton(id);
    this.sharedService.newEditorCurrentOpp.contentTemplate.template.children = [...this.sharedService.newEditorCurrentOpp.contentTemplate.template.children];
    this.cdr.detectChanges();
  }

  addText(id: any) {
    this.sharedService.addText(id);
    this.sharedService.newEditorCurrentOpp.contentTemplate.template.children = [...this.sharedService.newEditorCurrentOpp.contentTemplate.template.children];
    this.cdr.detectChanges();
  }

  addImage(id: any) {
    this.sharedService.addImage(id);
    this.sharedService.newEditorCurrentOpp.contentTemplate.template.children = [...this.sharedService.newEditorCurrentOpp.contentTemplate.template.children];
    this.cdr.detectChanges();
  }

  addContainer(id: any) {
    this.sharedService.addContainer(id);
    this.sharedService.newEditorCurrentOpp.contentTemplate.template.children = [...this.sharedService.newEditorCurrentOpp.contentTemplate.template.children];
    this.cdr.detectChanges();
  }

  addProduct(id: any) {
    this.sharedService.addProduct(id);
    this.sharedService.newEditorCurrentOpp.contentTemplate.template.children = [...this.sharedService.newEditorCurrentOpp.contentTemplate.template.children];
    this.cdr.detectChanges();
  }

  addReview(id: any) {
    this.sharedService.addReview(id);
    this.sharedService.newEditorCurrentOpp.contentTemplate.template.children = [...this.sharedService.newEditorCurrentOpp.contentTemplate.template.children];
    this.cdr.detectChanges();
  }

  deleteComponent(id: any) {
    this.sharedService.findAndDelete(this.sharedService.newEditorCurrentOpp.contentTemplate.template.children, id)
    this.sharedService.newEditorCurrentOpp.contentTemplate.template.children = [...this.sharedService.newEditorCurrentOpp.contentTemplate.template.children];
    this.cdr.detectChanges();
  }

  duplicateComponent(id: any, component: any) {
    this.sharedService.insertDuplicateComponent(id, component);
    this.sharedService.newEditorCurrentOpp.contentTemplate.template.children = [...this.sharedService.newEditorCurrentOpp.contentTemplate.template.children];
    this.cdr.detectChanges();
  }
}
