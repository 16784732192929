import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-klaviyo-setup',
  templateUrl: './klaviyo-setup.component.html',
  styleUrls: ['./klaviyo-setup.component.scss']
})
export class KlaviyoSetupComponent {
  klaviyoObject: any = {
    accessToken: '',
    type: "KLAVIYO"
  };

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    
  }

  createIntegration() {
    this.apiService.newIntegration(this.klaviyoObject).subscribe(
      data => {
          // login successful if there's a jwt token in the response
          console.log(data);
          this.router.navigate(['signup/4']);
      },
      error => {
          console.log(error);
      });
  }
}
