<div class="page-wrapper">
    <div class="content-wrapper">
        <nz-page-header style="padding: 0px !important; margin-bottom: 16px;">
            <nz-page-header-title>Team</nz-page-header-title>
            <!-- <nz-page-header-extra *ngIf="this.sharedService.currentUser.adminLevel == 'SUPER'"> -->
            <nz-page-header-extra>
                <button nzType="primary" nz-button (click)="this.sharedService.openInviteUser()"><span nz-icon
                        nzType="plus" nzTheme="outline"></span>Invite user</button>
            </nz-page-header-extra>
        </nz-page-header>
        <div class="custom-card">
            <div class="custom-card-body">
                <div class="header-row">
                    <div nz-row [nzGutter]="16">
                        <div nz-col class="gutter-row" [nzSpan]="16">
                            <div class="inner-box">Email</div>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="4">
                            <div class="inner-box">Status</div>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="4">
                            <div class="inner-box">Added</div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="this.loading">
                    <div class="loading-wrapper">
                        <div class="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!loading">
                    <ng-container *ngFor="let integration of integrations; index as index">
                        <ng-container *ngIf="integration?.email">
                            <div nz-row [nzGutter]="16" class="table-row" (click)="copyInviteUrl(integration)">
                                <div nz-col class="gutter-row" [nzSpan]="16">

                                    <ng-container *ngIf="integration?.email">
                                        {{integration.email}}
                                    </ng-container>

                                </div>
                                <div nz-col class="gutter-row" [nzSpan]="4">

                                    <ng-container *ngIf="integration?.status == 'PENDING'">
                                        <nz-tag>Invited</nz-tag>
                                    </ng-container>
                                    <ng-container *ngIf="integration?.status != 'PENDING'">
                                        <nz-tag class="aqua">Active</nz-tag>
                                    </ng-container>

                                </div>
                                <div nz-col class="gutter-row" [nzSpan]="4">
                                    <ng-container *ngIf="integration?.createdAt">
                                        {{integration.createdAt | date}}
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>