<div class="page-wrapper">
    <div class="content-wrapper">
        <nz-page-header nzBackIcon class="site-page-header">
            <nz-page-header-title>Gorgias</nz-page-header-title>
            <nz-page-header-extra>
                <button nz-button>Pause</button><button nz-button>Remove</button>
            </nz-page-header-extra>
        </nz-page-header>
        <div class="custom-card">
            <nz-statistic [nzValue]="(1527 | number)!" [nzTitle]="'Lookups this period'"></nz-statistic>
        </div>
        <br>
        <h5 nz-typography>Connection details</h5>
        <div class="custom-card">
            <input nz-input placeholder="Basic usage" />

        </div>
    </div>
</div>