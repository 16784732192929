import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SharedService } from '../shared.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import posthog from 'posthog-js';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  workspaceList: any = [];
  accountMenuOpen: any = false;
  loadingWorkspaces: any = false;
  showContentSubmenu: any = false;
  workspaceObject: any = {};


  constructor(
    private router: Router,
    public sharedService: SharedService,
    public apiService: ApiService,
    private message: NzMessageService,
    public route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.getWorkspaces();

    if (this.router.url == "/content/products" || this.router.url == "/content/media" || this.router.url == "/content/templates" || this.router.url == "/content/blocks") {
      this.showContentSubmenu = true;
    }

    this.apiService.getCurrentWorkspace().subscribe(
      (data: any) => {
        this.workspaceObject = data;
      });
  }

  logout() {
    // remove user from local storage to log user out
    posthog.reset(true);
    localStorage.removeItem('currentUser');
    this.router.navigateByUrl('/login');
  }

  openPlanningAssistant() {
    this.sharedService.openNew(null);
    this.sharedService.emailChatMode = "PLANNER";
  }

  getWorkspaces() {
    this.loadingWorkspaces = true;
    if (this.sharedService.currentUser.adminLevel == "SUPER") {
      this.apiService.getWorkspaces().subscribe(
        (data: any) => {
          // login successful if there's a jwt token in the response
          this.loadingWorkspaces = false;
          console.log(data);
          this.workspaceList = data;
        },
        error => {
          console.log(error);
        });
    } else {
      this.apiService.getUserWorkspaces().subscribe(
        (data: any) => {
          // login successful if there's a jwt token in the response
          this.loadingWorkspaces = false;
          console.log(data);
          this.workspaceList = data;
        },
        error => {
          console.log(error);
        });
    }
  }

  changeWorkspace(workspaceId: any) {
    this.apiService.changeWorkspace(workspaceId).subscribe(
      (data: any) => {
        // login successful if there's a jwt token in the response
        console.log(data);
        if (data && data['token']) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.sharedService.currentUser = data;
          localStorage.setItem('currentUser', JSON.stringify(data));
          this.accountMenuOpen = false;
          this.message.create("success", `Switched to ${data.workspace.name}`);
          this.router.navigateByUrl('/calendar');
          this.sharedService.loadingCalendar = true;
          this.apiService.getEmailCampaignCalendar(this.sharedService.calendarMonth, this.sharedService.calendarYear).subscribe(
            (data: any) => {
              console.log(data);
              this.sharedService.calendarData = data;
              this.sharedService.loadingCalendar = false;
            },
            error => {
              console.log(error);
            });
        }
      },
      error => {
        console.log(error);
      });
  }

  openNewFlow() {
    this.sharedService.newFlowFolderOpen = true;
  }

  toggleContentSubmenu() {
    if (this.showContentSubmenu) {
      this.showContentSubmenu = false;
    } else {
      this.showContentSubmenu = true;
    }
  }
}
