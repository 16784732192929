<div class="page-wrapper">
    <!-- <ng-container *ngIf="this.this.sharedService.rendererOpportunityObject.stage == ''">
        <div class="content-wrapper">
            <nz-page-header class="site-page-header">

                <nz-breadcrumb nz-page-header-breadcrumb>
                    <nz-breadcrumb-item>Opportunities</nz-breadcrumb-item>
                    <nz-breadcrumb-item>{{this.this.sharedService.rendererOpportunityObject.title}}</nz-breadcrumb-item>
                </nz-breadcrumb>
                <nz-page-header-title>{{this.this.sharedService.rendererOpportunityObject.title}}</nz-page-header-title>
            </nz-page-header>
            <ng-container *ngIf="fetchingSiteData">
                <div class="custom-card" style="text-align: center">
                    <div class="custom-card-body">
                        <div class="meter purple">
                            <span style="width: 100%"></span>
                        </div>
                        <h3>Copilot is fetching data.</h3>
                        <h3>This may take a moment.</h3>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!fetchingSiteData">
                <div class="custom-card" style="text-align: center">
                    <div class="custom-card-body">
                        <h2>Choose a primary color</h2>
                        <div nz-row [nzGutter]="[16, 24]">
                            <ng-container *ngFor="let color of siteData.colors">
                                <div nz-col class="gutter-row" [nzSpan]="3">
                                    <div (click)="selectPrimaryColor(color)" class="color-list"
                                        [style.backgroundColor]="color"></div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="custom-card" style="text-align: center">
                    <div class="custom-card-body">
                        <h2>Choose a logo image</h2>
                        <div nz-row [nzGutter]="[16, 24]">
                            <ng-container *ngFor="let image of siteData.images">
                                <ng-container *ngIf="image.src">
                                    <div nz-col class="gutter-row" [nzSpan]="4">
                                        <img (click)="selectLogoImage(image.src)" class="image-list"
                                            src="{{image.src}}">
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container> -->
    <ng-container *ngIf="loading">
        <div class="loading-wrapper">
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!loading">
        <div class="header-wrapper">
            <ng-container *ngIf="this.backMode != 'new'">
                <nz-page-header class="site-page-header">

                    <!-- <nz-breadcrumb nz-page-header-breadcrumb>
                    <nz-breadcrumb-item>Opportunities</nz-breadcrumb-item>
                    <nz-breadcrumb-item>{{this.this.sharedService.rendererOpportunityObject.title}}</nz-breadcrumb-item>
                </nz-breadcrumb> -->
                    <nz-page-header-title>Edit</nz-page-header-title>
                    <nz-page-header-extra>
                        <button nz-button (click)="shuffleColors()" [nzLoading]="remixingColors"
                            [disabled]="this.saving"><span nz-icon nzType="format-painter"
                                nzTheme="outline"></span>Shuffle colors</button>&nbsp;
                        <nz-button-group>
                            <button nz-button (click)="goBack()" [disabled]="this.saving">Cancel</button>
                            <button nz-button (click)="saveTemplateAndExit()" [nzLoading]="this.saving"
                                [disabled]="remixingColors">Save & exit</button>
                            <button nz-button nzType="primary" (click)="saveTemplate()" [nzLoading]="this.saving"
                                [disabled]>Save</button>
                        </nz-button-group>
                    </nz-page-header-extra>
                </nz-page-header>
            </ng-container>
            <ng-container *ngIf="this.backMode == 'new'">

                <nz-page-header class="site-page-header" nzBackIcon>

                    <!-- <nz-breadcrumb nz-page-header-breadcrumb>
                    <nz-breadcrumb-item>Opportunities</nz-breadcrumb-item>
                    <nz-breadcrumb-item>{{this.this.sharedService.rendererOpportunityObject.title}}</nz-breadcrumb-item>
                </nz-breadcrumb> -->

                    <nz-page-header-title>
                        <ng-container *ngIf="!this.sharedService.rendererOpportunityObject.flow">
                            Create campaign
                        </ng-container>
                        <ng-container *ngIf="this.sharedService.rendererOpportunityObject.flow">
                            Create flow email
                        </ng-container>
                    </nz-page-header-title>
                    <nz-page-header-tags>
                        <div class="custom-alert small aqua" style="display: inline-block; margin-right: 6px;">Like this
                            email?&nbsp;&nbsp;<i class="lni lni-thumbs-up clickable"
                                (click)="positiveRating()"></i>&nbsp;&nbsp;<i class="lni lni-thumbs-down clickable"
                                (click)="negativeRating()"></i></div>
                        <!-- <button nz-button><span nz-icon nzType="sync" nzTheme="outline"></span>Re-generate</button>&nbsp; -->
                    </nz-page-header-tags>
                    <nz-page-header-extra>
                        <!-- <button nz-button (click)="goBack()">Cancel</button>&nbsp; -->
                        <button nz-button (click)="shuffleColors()" [nzLoading]="remixingColors"><span nz-icon
                                nzType="format-painter" nzTheme="outline"></span>Shuffle colors</button>&nbsp;
                        <!-- <button nz-button nz-popover [nzPopoverContent]="contentTemplate2"
                            nzPopoverPlacement="bottomRight" nzPopoverTrigger="click"
                            [(nzPopoverVisible)]="remixCopyPopoverVisible"><span nz-icon nzType="sync" nzTheme="outline"></span>Remix copy</button>&nbsp; -->
                        <button nz-button nzType="primary" (click)="saveTemplateAndExit()">Save &
                            continue</button>
                        <!-- <button nz-button nzType="primary" (click)="saveTemplate()"
                            [nzLoading]="this.saving">Save</button> -->
                    </nz-page-header-extra>
                </nz-page-header>
            </ng-container>
        </div>

        <div class="left-column">
            <app-email-renderer-v2 [mode]="'EDIT'"></app-email-renderer-v2>
            <ng-container *ngIf="this.sharedService.rendererOpportunityObject.stage == 'NEW'">
                <div style="max-width: 600px; margin: auto; margin-top: 24px; text-align: center;">
                    <div class="meter purple">
                        <span style="width: 100%"></span>
                    </div>
                    <h3>Copilot is generating a proposal.</h3>
                    <h3>This may take a moment.</h3>
                </div>
            </ng-container>
        </div>
        <div class="middle-column">
            <div class="custom-card" style="max-height: 100%; overflow-y: auto;">
                <div class="custom-card-body mini">
                    <!-- <div class="left-column-content">
                <nz-steps [nzCurrent]="1" nzDirection="vertical">
                    <nz-step nzTitle="Generating proposal"></nz-step>
                    <nz-step nzTitle="Approval"></nz-step>
                    <nz-step nzTitle="Publishing"></nz-step>
                    <nz-step nzTitle="Live"></nz-step>
                </nz-steps>
            </div> -->

                    <ng-container *ngIf="!this.sharedService.currentBlock.properties">
                        <div style="padding: 16px 8px 0px;">
                            <nz-form-item>

                                <nz-form-control [nzSpan]="24">
                                    <!-- <ngx-colors ngx-colors-trigger
                                        [(ngModel)]="this.sharedService.rendererOpportunityObject.contentTemplate.backgroundColor"
                                        style="float: left; height: 30px;"
                                        (ngModelChange)="colorChosen(this.sharedService.rendererOpportunityObject.contentTemplate.backgroundColor)"
                                        [palette]="this.sharedService.rendererOpportunityObject.contentTemplate.colorPalette"></ngx-colors> -->
                                    <div class="color-picker-label">Background color
                                    </div>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div cdkDropList (cdkDropListDropped)="drop($event)"
                            style="margin-top: 8px; padding: 16px 8px;">
                            <ng-container
                                *ngFor="let block of this.sharedService.rendererOpportunityObject.contentTemplate.blocks; let index=index">
                                <div cdkDrag class="custom-card mini hover" (click)="selectBlock(block)">
                                    <div class="custom-card-body mini">
                                        <div nz-row [nzGutter]="8">
                                            <div nz-col nzSpan="4" cdkDragHandle
                                                style="line-height: 28px; cursor: pointer;">
                                                <span style="margin-left: 14px;" nz-icon nzType="holder"
                                                    nzTheme="outline"></span>
                                            </div>
                                            <div nz-col nzSpan="20">
                                                <div style="line-height: 28px" class="list-title">{{block.type |
                                                    titlecase}} </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <button nz-button (click)="openBlockLibrary(0)"
                                style="margin-top: 8px; margin-bottom: 16px;" nzBlock>
                                <span nz-icon nzType="plus" nzTheme="outline"></span>&nbsp;New block
                            </button>
                        </div>
                        <!-- <nz-collapse-panel nzHeader="Styles" [nzActive]="true">
                        <nz-form-item>
                            <nz-form-label [nzNoColon]="true">Header font</nz-form-label>
                            <nz-form-control [nzSpan]="24">
                                <nz-select [compareWith]="compareFn"
                                    [(ngModel)]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.headerFont"
                                    style="width:300px"
                                    [style.fontFamily]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.headerFont">
                                    <ng-container *ngFor="let font of sharedService.fonts">
                                        <nz-option nzCustomContent [nzValue]="font.value" [nzLabel]="font.value"><span
                                                [style.fontFamily]="font.value">{{font.value}}</span></nz-option>
                                    </ng-container>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item style="margin-bottom: 0px">
                            <nz-form-label [nzNoColon]="true">Body font</nz-form-label>
                            <nz-form-control [nzSpan]="24">
                                <nz-select [compareWith]="compareFn"
                                    [(ngModel)]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.bodyFont"
                                    style="width:300px"
                                    [style.fontFamily]="this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.bodyFont">
                                    <ng-container *ngFor="let font of sharedService.fonts">
                                        <nz-option nzCustomContent [nzValue]="font.value" [nzLabel]="font.value"><span
                                                [style.fontFamily]="font.value">{{font.value}}</span></nz-option>
                                    </ng-container>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </nz-collapse-panel> -->
                    </ng-container>

                    <ng-container *ngIf="this.sharedService.currentComponent">
                        <button (click)="this.sharedService.currentComponent = null" nz-button nzType="text"
                            style="border: none !important; box-shadow: none !important;"><span nz-icon
                                nzType="arrow-left" nzTheme="outline"></span>Back</button>

                        <ng-container
                            *ngIf="this.sharedService.currentComponent.type && this.sharedService.currentComponent.type == 'TEXT'">
                            <!-- <nz-form-control [nzSpan]="24" style="margin-bottom: 8px;">
                                <ngx-colors ngx-colors-trigger
                                    [(ngModel)]="this.sharedService.currentComponent.props.color"
                                    style="height: 30px; float: left;"
                                    (ngModelChange)="setQuillColor(this.sharedService.currentComponent)"></ngx-colors>
                                <div class="color-picker-label">Text color
                                </div>
                            </nz-form-control> -->
                            <nz-form-control [nzSpan]="24" style="margin-bottom: 8px;">
                                <div>
                                    <nz-button-group style="margin-right: 8px;">
                                        <button nz-button (click)="setQuillBold()" style="width: 46px;"><span nz-icon
                                                nzType="bold" nzTheme="outline"></span></button>
                                        <button nz-button (click)="setQuillItalic()" style="width: 46px;"><span nz-icon
                                                nzType="italic" nzTheme="outline"></span></button>
                                        <button nz-button (click)="setQuillUnderline()" style="width: 46px;"><span
                                                nz-icon nzType="underline" nzTheme="outline"></span></button>
                                        <button nz-button (click)="setQuillStrikethrough()" style="width: 46px;"><span
                                                nz-icon nzType="strikethrough" nzTheme="outline"></span></button>
                                    </nz-button-group>
                                </div>
                            </nz-form-control>
                            <nz-form-control [nzSpan]="24" style="margin-bottom: 8px;">
                                <nz-radio-group [(ngModel)]="this.sharedService.currentComponent.props.textAlign">
                                    <label nz-radio-button nzValue="left" (click)="setQuillAlignment('left')"><span
                                            nz-icon nzType="align-left" nzTheme="outline"></span></label>
                                    <label nz-radio-button nzValue="center" (click)="setQuillAlignment('center')"><span
                                            nz-icon nzType="align-center" nzTheme="outline"></span></label>
                                    <label nz-radio-button nzValue="right" (click)="setQuillAlignment('right')"><span
                                            nz-icon nzType="align-right" nzTheme="outline"></span></label>
                                </nz-radio-group>
                            </nz-form-control>
                            <nz-form-control>
                                <nz-button-group>
                                    <button nz-button (click)="setQuillBullets()" style="width: 46px;"><span nz-icon
                                            nzType="unordered-list" nzTheme="outline"></span></button>
                                    <button nz-button (click)="setQuillOrderedList()" style="width: 46px;"><span nz-icon
                                            nzType="ordered-list" nzTheme="outline"></span></button>
                                </nz-button-group>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>

                            <nz-form-control [nzSpan]="24">
                                <div nz-row style="margin-bottom: 6px;">
                                    <nz-select nzShowSearch
                                        [(ngModel)]="this.sharedService.currentComponent.props.fontFamily"
                                        [compareWith]="compareFn"
                                        [style.fontFamily]="this.sharedService.currentComponent.props.fontFamily">
                                        <ng-container *ngFor="let font of sharedService.fonts">
                                            <nz-option nzCustomContent [nzValue]="font.value"
                                                [nzLabel]="font.value"><span
                                                    [style.fontFamily]="font.value">{{font.value}}</span></nz-option>
                                        </ng-container>
                                    </nz-select>
                                </div>
                                <div nz-row style="margin-bottom: 4px;">
                                    <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                        Font size
                                    </div>
                                    <div nz-col nzSpan="14">
                                        <nz-button-group>
                                            <button nz-button>-</button>
                                            <input nz-input
                                                [(ngModel)]="this.sharedService.currentComponent.props.fontSize"
                                                style="border-radius: 0px; border-left: none; border-right: none;" />
                                            <button nz-button>+</button>
                                        </nz-button-group>
                                    </div>
                                </div>
                                <div nz-row style="margin-bottom: 4px;">
                                    <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                        Font weight
                                    </div>
                                    <div nz-col nzSpan="14">
                                        <nz-select [(ngModel)]="this.sharedService.currentComponent.props.fontWeight"
                                            [compareWith]="compareFn">
                                            <nz-option nzValue="100" nzLabel="Thin (100)"></nz-option>
                                            <nz-option nzValue="200" nzLabel="Extra light (200)"></nz-option>
                                            <nz-option nzValue="300" nzLabel="Light (300)"></nz-option>
                                            <nz-option nzValue="400" nzLabel="Normal (400)"></nz-option>
                                            <nz-option nzValue="500" nzLabel="Medium (500)"></nz-option>
                                            <nz-option nzValue="600" nzLabel="Semi bold (600)"></nz-option>
                                            <nz-option nzValue="700" nzLabel="Bold (700)"></nz-option>
                                            <nz-option nzValue="800" nzLabel="Extra bold (800)"></nz-option>
                                            <nz-option nzValue="900" nzLabel="Black (900)"></nz-option>
                                        </nz-select>
                                    </div>
                                </div>
                                <div nz-row style="margin-bottom: 4px;">
                                    <div nz-col nzSpan="16" style="color: #919191; line-height: 36px;">
                                        Line height
                                    </div>
                                    <div nz-col nzSpan="8">
                                        <input nz-input
                                            [(ngModel)]="this.sharedService.currentComponent.props.lineHeight" />
                                    </div>
                                </div>
                                <div nz-row>
                                    <div nz-col nzSpan="16" style="color: #919191; line-height: 36px;">
                                        Letter spacing
                                    </div>
                                    <div nz-col nzSpan="8">
                                        <input nz-input
                                            [(ngModel)]="this.sharedService.currentComponent.props.letterSpacing" />
                                    </div>
                                </div>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <nz-form-control [nzSpan]="24">
                                <div style="color: #919191; margin-bottom: 4px;">
                                    Padding
                                </div>
                                <nz-input-group>
                                    <input nz-input [(ngModel)]="this.sharedService.currentComponent.props.paddingTop"
                                        style="width: 25%" />
                                    <input nz-input [(ngModel)]="this.sharedService.currentComponent.props.paddingRight"
                                        style="width: 25%; border-left: none;" />
                                    <input nz-input
                                        [(ngModel)]="this.sharedService.currentComponent.props.paddingBottom"
                                        style="width: 25%; border-left: none;" />
                                    <input nz-input [(ngModel)]="this.sharedService.currentComponent.props.paddingLeft"
                                        style="width: 25%; border-left: none;" />
                                </nz-input-group>
                                <div nz-row>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">T
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">R
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">B
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">L
                                    </div>
                                </div>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <div class="item-title" style="width: 100%;">
                                Dynamic content
                                <nz-switch nzSize="small"
                                    [(ngModel)]="this.sharedService.currentComponent.props.dynamicContent"
                                    style="float: right"></nz-switch>
                            </div>
                            <ng-container *ngIf="this.sharedService.currentComponent.props.dynamicContent">
                                <div class="item-title" style="width: 100%;">
                                    Text
                                </div>
                                <input nz-input
                                    [(ngModel)]="this.sharedService.currentComponent.props.dynamicContentValue" />
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!this.sharedService.currentComponent.type">
                            <ng-container *ngFor="let componentProperty of this.sharedService.currentBlock.properties">
                                <ng-container
                                    *ngIf="componentProperty.component == this.sharedService.currentComponent.enum">

                                    <ng-container *ngIf="componentProperty.type == 'INPUT'">
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true">{{
                                                componentProperty.name
                                                }}</nz-form-label>
                                            <nz-form-control [nzSpan]="24">
                                                <input nz-input [(ngModel)]="componentProperty.value" />
                                            </nz-form-control>
                                        </nz-form-item>
                                    </ng-container>
                                    <ng-container *ngIf="componentProperty.type == 'COLOR'">
                                        <nz-form-item>

                                            <nz-form-control [nzSpan]="24">
                                                <ngx-colors ngx-colors-trigger [(ngModel)]="componentProperty.value"
                                                    (ngModelChange)="colorChosen(componentProperty.value)"
                                                    style="float: left; height: 30px;"
                                                    [palette]="this.sharedService.rendererOpportunityObject.contentTemplate.colorPalette"></ngx-colors>
                                                <div class="color-picker-label">
                                                    {{
                                                    componentProperty.name }}
                                                </div>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </ng-container>
                                    <ng-container *ngIf="componentProperty.type == 'RICHTEXT'">
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true">{{
                                                componentProperty.name
                                                }}</nz-form-label>
                                            <nz-form-control [nzSpan]="24">
                                                <quill-editor></quill-editor>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </ng-container>
                                    <ng-container *ngIf="componentProperty.type == 'IMAGESELECT'">
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true">{{
                                                componentProperty.name
                                                }}</nz-form-label>
                                            <nz-form-control [nzSpan]="24">
                                                <div class="image-select-box">
                                                    <img class="image-select-chosen-image"
                                                        src="{{componentProperty.value}}">
                                                </div>
                                                <nz-button-group>

                                                    <button nz-button
                                                        (click)="openPickerSingle(componentProperty, 'MEDIA')"><span
                                                            nz-icon nzType="file-image"
                                                            nzTheme="outline"></span><span>Change</span></button>
                                                    <button nz-button
                                                        (click)="openImageEditor(componentProperty)"><span>Crop</span></button>
                                                    <button nz-button
                                                        (click)="openImageBackgroundEditor(componentProperty)">Edit
                                                        background</button>
                                                </nz-button-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </ng-container>
                                    <ng-container *ngIf="componentProperty.type == 'RADIO'">
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true">{{
                                                componentProperty.name
                                                }}</nz-form-label>
                                            <nz-form-control [nzSpan]="24">
                                                <nz-radio-group [(ngModel)]="componentProperty.value">
                                                    <label nz-radio-button nzValue="0px">Straight</label>
                                                    <label nz-radio-button nzValue="5px">Curved</label>
                                                    <label nz-radio-button nzValue="1000px">Round</label>
                                                </nz-radio-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </ng-container>
                                    <ng-container *ngIf="componentProperty.type == 'FONT'">
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true">{{
                                                componentProperty.name
                                                }}</nz-form-label>
                                            <nz-form-control [nzSpan]="24">
                                                <nz-select [(ngModel)]="componentProperty.value" nzPlaceHolder="Choose"
                                                    [style.fontFamily]="componentProperty.value.font"
                                                    [style.fontWeight]="componentProperty.value.fontWeight"
                                                    [compareWith]="compareFn">
                                                    <nz-option nzCustomContent
                                                        *ngFor="let option of this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts"
                                                        [nzValue]="option.name" [nzLabel]="option.name"><span
                                                            [style.fontFamily]="option.font"
                                                            [style.fontWeight]="option.fontWeight">{{option.name}}</span></nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </ng-container>
                                    <ng-container *ngIf="componentProperty.type == 'DATALIST'">
                                        <nz-form-item>
                                            <nz-form-label [nzNoColon]="true">{{
                                                componentProperty.name
                                                }}</nz-form-label>
                                            <nz-form-control [nzSpan]="24">
                                                <ng-container
                                                    *ngFor="let item of componentProperty.value; let itemIndex=index">
                                                    <div class="custom-card mini">
                                                        <div class="custom-card-body mini">
                                                            <div class="list-title" style="margin-top: -11px;
                                                                                        position: absolute;
                                                                                        right: 10px; z-index: 9999;">
                                                                <span
                                                                    (click)="this.removeItemFromArray(itemIndex, componentProperty.value)"
                                                                    nz-icon nzType="close" nzTheme="outline"></span>
                                                            </div>
                                                            <div nz-row [nzGutter]="8">
                                                                <div nz-col nzSpan="24">
                                                                    <ng-container *ngFor="let field of item">
                                                                        <ng-container *ngIf="field.type == 'INPUT'">
                                                                            <nz-form-item
                                                                                style="margin-bottom: 4px !important;">
                                                                                <nz-form-label
                                                                                    [nzNoColon]="true">{{field.name}}</nz-form-label>
                                                                                <nz-form-control [nzSpan]="24">
                                                                                    <input nz-input
                                                                                        [(ngModel)]="field.value" />
                                                                                </nz-form-control>
                                                                            </nz-form-item>
                                                                        </ng-container>

                                                                        <ng-container *ngIf="field.type == 'SELECT'">
                                                                            <nz-form-item>
                                                                                <nz-form-label [nzNoColon]="true">{{
                                                                                    field.name
                                                                                    }}</nz-form-label>
                                                                                <nz-form-control [nzSpan]="24">
                                                                                    <nz-select [(ngModel)]="field.value"
                                                                                        nzPlaceHolder="Choose"
                                                                                        [compareWith]="compareFn2">
                                                                                        <nz-option
                                                                                            *ngFor="let option of field.options"
                                                                                            [nzValue]="option"
                                                                                            [nzLabel]="option.title"></nz-option>
                                                                                    </nz-select>
                                                                                </nz-form-control>
                                                                            </nz-form-item>
                                                                        </ng-container>

                                                                        <ng-container *ngIf="field.type == 'RICHTEXT'">
                                                                            <nz-form-item
                                                                                style="margin-bottom: 4px !important;">
                                                                                <nz-form-label
                                                                                    [nzNoColon]="true">{{field.name}}</nz-form-label>
                                                                                <nz-form-control [nzSpan]="24">
                                                                                    <textarea nz-input
                                                                                        [(ngModel)]="field.value"
                                                                                        [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>
                                                                                </nz-form-control>
                                                                            </nz-form-item>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="field.type == 'IMAGESELECT'">
                                                                            <nz-form-item>
                                                                                <nz-form-label [nzNoColon]="true">{{
                                                                                    field.name
                                                                                    }}</nz-form-label>
                                                                                <nz-form-control [nzSpan]="24">
                                                                                    <div class="image-select-box">
                                                                                        <img class="image-select-chosen-image"
                                                                                            src="{{field.value}}">
                                                                                    </div>
                                                                                    <nz-button-group>
                                                                                        <button nz-button
                                                                                            (click)="openPickerSingle(field, 'MEDIA')"><span
                                                                                                nz-icon
                                                                                                nzType="file-image"
                                                                                                nzTheme="outline"></span><span>Change</span></button>
                                                                                        <button nz-button
                                                                                            (click)="openImageEditor(field)"><span>Crop</span></button>
                                                                                        <button nz-button
                                                                                            (click)="openImageBackgroundEditor(field)">Edit
                                                                                            background</button>
                                                                                    </nz-button-group>
                                                                                </nz-form-control>
                                                                            </nz-form-item>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="field.type == 'ICONSELECT'">
                                                                            <nz-form-item
                                                                                style="margin-bottom: 4px !important;">
                                                                                <nz-form-label
                                                                                    [nzNoColon]="true">{{field.name}}</nz-form-label>
                                                                                <nz-form-control [nzSpan]="24">
                                                                                    <button style="font-size: 16px"
                                                                                        nz-button nz-popover
                                                                                        [nzPopoverContent]="iconPickerTemplate"
                                                                                        nzPopoverPlacement="bottomLeft"
                                                                                        nzPopoverTrigger="click"
                                                                                        [(nzPopoverVisible)]="field.popoverVisible"
                                                                                        (click)="iconSelectItem = field"><span
                                                                                            class="{{field.value}}"></span></button>
                                                                                </nz-form-control>
                                                                            </nz-form-item>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <button nz-button (click)="pushDataListValue(componentProperty)"
                                                    nzBlock><span nz-icon nzType="plus" nzTheme="outline"></span>Add
                                                    item</button>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                    </ng-container>

                    <ng-container
                        *ngIf="this.sharedService.currentBlock.properties && !this.sharedService.currentComponent">

                        <div class="editor-menu-header">
                            <button (click)="this.sharedService.currentBlock = {}" nz-button nzType="text"
                                style="border: none !important; box-shadow: none !important; float: right"><span nz-icon
                                    nzType="arrow-left" nzTheme="outline"></span>Back</button>
                            <span style="line-height: 36px;">{{this.sharedService.currentBlock.type
                                | titlecase}} block settings</span>
                        </div>
                        <!-- <ul nz-menu nzMode="horizontal" style="margin-bottom: 16px !important;">
                    <li nz-menu-item nzSelected (click)="this.blockTab = 'CONTENT'">
                        Content
                    </li>
                    <li nz-menu-item (click)="this.blockTab = 'STYLES'">
                        Styles
                    </li>
                </ul> -->
                        <ng-container *ngIf="!this.sharedService.currentBlock.version">
                            <div class="editor-menu-content">
                                <ng-container *ngIf="this.sharedService.currentBlock.type == 'HERO'">
                                    <nz-form-item>
                                        <nz-form-label [nzNoColon]="true">Layout</nz-form-label>
                                        <nz-form-control [nzSpan]="24">
                                            <nz-input-group nzCompact>
                                                <button nz-button nzType="default"
                                                    (click)="this.previousVariant(getProperty(this.sharedService.currentBlock, 'Layout'))">
                                                    <span nz-icon nzType="left"></span>
                                                </button>
                                                <button nz-button nzType="default" (click)="this.nextVariant(getProperty(this.sharedService.currentBlock, 'Layout'))">
                                                    <span nz-icon nzType="right"></span>
                                                </button>
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                    <nz-form-control [nzSpan]="24">
                                        <ngx-colors ngx-colors-trigger
                                            [(ngModel)]="getProperty(this.sharedService.currentBlock, 'Background color').value"
                                            style="height: 30px; float: left;"></ngx-colors>
                                        <div class="color-picker-label">Background color
                                        </div>
                                    </nz-form-control>
                                    <nz-divider style="margin: 16px 0px;"></nz-divider>
                                    <div class="item-title" style="width: 100%;">
                                        Components
                                    </div>
                                    <nz-form-control [nzSpan]="24">
                                        <ng-container
                                            *ngFor="let item of getProperty(this.sharedService.currentBlock, 'Components').items; let itemIndex=index">
                                            <div class="custom-card mini" (click)="chooseComponent(item)">
                                                <div class="custom-card-body mini"
                                                    style="padding: 8px 16px; font-weight: 500;">
                                                    <div nz-row [nzGutter]="8">
                                                        <div nz-col nzSpan="21">
                                                            {{ item.name }}
                                                        </div>
                                                        <div nz-col nzSpan="3">
                                                            <nz-switch nzSize="small" [(ngModel)]="item.value"
                                                                (ngModelChange)="onToggleChange($event, getProperty(this.sharedService.currentBlock, 'Components'))"></nz-switch>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </nz-form-control>

                                </ng-container>
                                <ng-container *ngIf="this.sharedService.currentBlock.type != 'HERO'">

                                    <ng-container *ngFor="let property of this.sharedService.currentBlock.properties">
                                        <ng-container *ngIf="property.display">

                                            <ng-container *ngIf="property.type == 'SELECT'">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true">{{ property.name
                                                        }}</nz-form-label>
                                                    <nz-form-control [nzSpan]="24">
                                                        <nz-input-group nzCompact>
                                                            <button nz-button nzType="default"
                                                                (click)="this.previousVariant(property)">
                                                                <span nz-icon nzType="left"></span>
                                                            </button>
                                                            <button nz-button nzType="default"
                                                                (click)="this.nextVariant(property)">
                                                                <span nz-icon nzType="right"></span>
                                                            </button>
                                                        </nz-input-group>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </ng-container>

                                            <ng-container *ngIf="property.type == 'COMPONENTS'">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true">{{ property.name
                                                        }}</nz-form-label>
                                                    <nz-form-control [nzSpan]="24">
                                                        <ng-container
                                                            *ngFor="let item of property.items; let itemIndex=index">
                                                            <div class="custom-card mini"
                                                                (click)="chooseComponent(item)">
                                                                <div class="custom-card-body mini"
                                                                    style="padding: 8px 16px; font-weight: 500;">
                                                                    <div nz-row [nzGutter]="8">
                                                                        <div nz-col nzSpan="21">
                                                                            {{ item.name }}
                                                                        </div>
                                                                        <div nz-col nzSpan="3">
                                                                            <nz-switch nzSize="small"
                                                                                [(ngModel)]="item.value"
                                                                                (ngModelChange)="onToggleChange($event, property)"></nz-switch>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="custom-card-footer mini"
                                                                style="padding: 8px 8px; text-align: left; background: #f9f9f9;"
                                                                *ngIf="item.value">
                                                                <ng-container
                                                                    *ngFor="let componentProperty of this.sharedService.currentBlock.properties">
                                                                    <ng-container
                                                                        *ngIf="componentProperty.component == item.enum">

                                                                        <ng-container
                                                                            *ngIf="componentProperty.type == 'INPUT'">
                                                                            <nz-form-item>
                                                                                <nz-form-label [nzNoColon]="true">{{
                                                                                    componentProperty.name
                                                                                    }}</nz-form-label>
                                                                                <nz-form-control [nzSpan]="24">
                                                                                    <input nz-input
                                                                                        [(ngModel)]="componentProperty.value" />
                                                                                </nz-form-control>
                                                                            </nz-form-item>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="componentProperty.type == 'COLOR'">
                                                                            <nz-form-item>

                                                                                <nz-form-control [nzSpan]="24">
                                                                                    <ngx-colors ngx-colors-trigger
                                                                                        [(ngModel)]="componentProperty.value"
                                                                                        (ngModelChange)="colorChosen(componentProperty.value)"
                                                                                        style="float: left; height: 30px;"
                                                                                        [palette]="this.sharedService.rendererOpportunityObject.contentTemplate.colorPalette"></ngx-colors>
                                                                                    <div class="color-picker-label">
                                                                                        {{
                                                                                        componentProperty.name }}
                                                                                    </div>
                                                                                </nz-form-control>
                                                                            </nz-form-item>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="componentProperty.type == 'RICHTEXT'">
                                                                            <nz-form-item>
                                                                                <nz-form-label [nzNoColon]="true">{{
                                                                                    componentProperty.name
                                                                                    }}</nz-form-label>
                                                                                <nz-form-control [nzSpan]="24">
                                                                                    <quill-editor></quill-editor>
                                                                                </nz-form-control>
                                                                            </nz-form-item>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="componentProperty.type == 'IMAGESELECT'">
                                                                            <nz-form-item>
                                                                                <nz-form-label [nzNoColon]="true">{{
                                                                                    componentProperty.name
                                                                                    }}</nz-form-label>
                                                                                <nz-form-control [nzSpan]="24">
                                                                                    <div class="image-select-box">
                                                                                        <img class="image-select-chosen-image"
                                                                                            src="{{componentProperty.value}}">
                                                                                    </div>
                                                                                    <nz-button-group>

                                                                                        <button nz-button
                                                                                            (click)="openPickerSingle(componentProperty, 'MEDIA')"><span
                                                                                                nz-icon
                                                                                                nzType="file-image"
                                                                                                nzTheme="outline"></span><span>Change</span></button>
                                                                                        <button nz-button
                                                                                            (click)="openImageEditor(componentProperty)"><span>Crop</span></button>
                                                                                        <button nz-button
                                                                                    (click)="openImageBackgroundEditor(componentProperty)">Edit
                                                                                    background</button>
                                                                                    </nz-button-group>
                                                                                </nz-form-control>
                                                                            </nz-form-item>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="componentProperty.type == 'RADIO'">
                                                                            <nz-form-item>
                                                                                <nz-form-label [nzNoColon]="true">{{
                                                                                    componentProperty.name
                                                                                    }}</nz-form-label>
                                                                                <nz-form-control [nzSpan]="24">
                                                                                    <nz-radio-group
                                                                                        [(ngModel)]="componentProperty.value">
                                                                                        <label nz-radio-button
                                                                                            nzValue="0px">Straight</label>
                                                                                        <label nz-radio-button
                                                                                            nzValue="5px">Curved</label>
                                                                                        <label nz-radio-button
                                                                                            nzValue="1000px">Round</label>
                                                                                    </nz-radio-group>
                                                                                </nz-form-control>
                                                                            </nz-form-item>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="componentProperty.type == 'FONT'">
                                                                            <nz-form-item>
                                                                                <nz-form-label [nzNoColon]="true">{{
                                                                                    componentProperty.name
                                                                                    }}</nz-form-label>
                                                                                <nz-form-control [nzSpan]="24">
                                                                                    <nz-select
                                                                                        [(ngModel)]="componentProperty.value"
                                                                                        nzPlaceHolder="Choose"
                                                                                        [style.fontFamily]="componentProperty.value.font"
                                                                                        [style.fontWeight]="componentProperty.value.fontWeight"
                                                                                        [compareWith]="compareFn">
                                                                                        <nz-option nzCustomContent
                                                                                            *ngFor="let option of this.sharedService.rendererOpportunityObject.contentTemplate.brandStyles.fonts"
                                                                                            [nzValue]="option.name"
                                                                                            [nzLabel]="option.name"><span
                                                                                                [style.fontFamily]="option.font"
                                                                                                [style.fontWeight]="option.fontWeight">{{option.name}}</span></nz-option>
                                                                                    </nz-select>
                                                                                </nz-form-control>
                                                                            </nz-form-item>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="componentProperty.type == 'DATALIST'">
                                                                            <nz-form-item>
                                                                                <nz-form-label [nzNoColon]="true">{{
                                                                                    componentProperty.name
                                                                                    }}</nz-form-label>
                                                                                <nz-form-control [nzSpan]="24">
                                                                                    <ng-container
                                                                                        *ngFor="let item of componentProperty.value; let itemIndex=index">
                                                                                        <div class="custom-card mini">
                                                                                            <div
                                                                                                class="custom-card-body mini">
                                                                                                <div class="list-title"
                                                                                                    style="margin-top: -11px;
                                                                                        position: absolute;
                                                                                        right: 10px; z-index: 9999;">
                                                                                                    <span
                                                                                                        (click)="this.removeItemFromArray(itemIndex, componentProperty.value)"
                                                                                                        nz-icon
                                                                                                        nzType="close"
                                                                                                        nzTheme="outline"></span>
                                                                                                </div>
                                                                                                <div nz-row
                                                                                                    [nzGutter]="8">
                                                                                                    <div nz-col
                                                                                                        nzSpan="24">
                                                                                                        <ng-container
                                                                                                            *ngFor="let field of item">
                                                                                                            <ng-container
                                                                                                                *ngIf="field.type == 'INPUT'">
                                                                                                                <nz-form-item
                                                                                                                    style="margin-bottom: 4px !important;">
                                                                                                                    <nz-form-label
                                                                                                                        [nzNoColon]="true">{{field.name}}</nz-form-label>
                                                                                                                    <nz-form-control
                                                                                                                        [nzSpan]="24">
                                                                                                                        <input
                                                                                                                            nz-input
                                                                                                                            [(ngModel)]="field.value" />
                                                                                                                    </nz-form-control>
                                                                                                                </nz-form-item>
                                                                                                            </ng-container>

                                                                                                            <ng-container
                                                                                                                *ngIf="field.type == 'SELECT'">
                                                                                                                <nz-form-item>
                                                                                                                    <nz-form-label
                                                                                                                        [nzNoColon]="true">{{
                                                                                                                        field.name
                                                                                                                        }}</nz-form-label>
                                                                                                                    <nz-form-control
                                                                                                                        [nzSpan]="24">
                                                                                                                        <nz-select
                                                                                                                            [(ngModel)]="field.value"
                                                                                                                            nzPlaceHolder="Choose"
                                                                                                                            [compareWith]="compareFn2">
                                                                                                                            <nz-option
                                                                                                                                *ngFor="let option of field.options"
                                                                                                                                [nzValue]="option"
                                                                                                                                [nzLabel]="option.title"></nz-option>
                                                                                                                        </nz-select>
                                                                                                                    </nz-form-control>
                                                                                                                </nz-form-item>
                                                                                                            </ng-container>

                                                                                                            <ng-container
                                                                                                                *ngIf="field.type == 'RICHTEXT'">
                                                                                                                <nz-form-item
                                                                                                                    style="margin-bottom: 4px !important;">
                                                                                                                    <nz-form-label
                                                                                                                        [nzNoColon]="true">{{field.name}}</nz-form-label>
                                                                                                                    <nz-form-control
                                                                                                                        [nzSpan]="24">
                                                                                                                        <textarea
                                                                                                                            nz-input
                                                                                                                            [(ngModel)]="field.value"
                                                                                                                            [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>
                                                                                                                    </nz-form-control>
                                                                                                                </nz-form-item>
                                                                                                            </ng-container>
                                                                                                            <ng-container
                                                                                                                *ngIf="field.type == 'IMAGESELECT'">
                                                                                                                <nz-form-item>
                                                                                                                    <nz-form-label
                                                                                                                        [nzNoColon]="true">{{
                                                                                                                        field.name
                                                                                                                        }}</nz-form-label>
                                                                                                                    <nz-form-control
                                                                                                                        [nzSpan]="24">
                                                                                                                        <div
                                                                                                                            class="image-select-box">
                                                                                                                            <img class="image-select-chosen-image"
                                                                                                                                src="{{field.value}}">
                                                                                                                        </div>
                                                                                                                        <nz-button-group>
                                                                                                                            <button
                                                                                                                                nz-button
                                                                                                                                (click)="openPickerSingle(field, 'MEDIA')"><span
                                                                                                                                    nz-icon
                                                                                                                                    nzType="file-image"
                                                                                                                                    nzTheme="outline"></span><span>Change</span></button>
                                                                                                                            <button
                                                                                                                                nz-button
                                                                                                                                (click)="openImageEditor(field)"><span>Crop</span></button>
                                                                                                                            <button
                                                                                                                        nz-button
                                                                                                                        (click)="openImageBackgroundEditor(field)">Edit
                                                                                                                        background</button>
                                                                                                                        </nz-button-group>
                                                                                                                    </nz-form-control>
                                                                                                                </nz-form-item>
                                                                                                            </ng-container>

                                                                                                            <ng-container
                                                                                                                *ngIf="field.type == 'ICONSELECT'">
                                                                                                                <nz-form-item
                                                                                                                    style="margin-bottom: 4px !important;">
                                                                                                                    <nz-form-label
                                                                                                                        [nzNoColon]="true">{{field.name}}</nz-form-label>
                                                                                                                    <nz-form-control
                                                                                                                        [nzSpan]="24">
                                                                                                                        <button
                                                                                                                            style="font-size: 16px"
                                                                                                                            nz-button
                                                                                                                            nz-popover
                                                                                                                            [nzPopoverContent]="iconPickerTemplate"
                                                                                                                            nzPopoverPlacement="bottomLeft"
                                                                                                                            nzPopoverTrigger="click"
                                                                                                                            [(nzPopoverVisible)]="field.popoverVisible"
                                                                                                                            (click)="iconSelectItem = field"><span
                                                                                                                                class="{{field.value}}"></span></button>
                                                                                                                    </nz-form-control>
                                                                                                                </nz-form-item>
                                                                                                            </ng-container>
                                                                                                        </ng-container>
                                                                                                    </div>

                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </ng-container>
                                                                                    <button nz-button
                                                                                        (click)="pushDataListValue(componentProperty)"
                                                                                        nzBlock><span nz-icon
                                                                                            nzType="plus"
                                                                                            nzTheme="outline"></span>Add
                                                                                        item</button>
                                                                                </nz-form-control>
                                                                            </nz-form-item>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </div> -->
                                                            </div>
                                                        </ng-container>

                                                    </nz-form-control>
                                                </nz-form-item>
                                            </ng-container>
                                            <ng-container *ngIf="property.type == 'COLOR'">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true">{{ property.name
                                                        }}</nz-form-label>
                                                    <nz-form-control [nzSpan]="24">
                                                        <ngx-colors ngx-colors-trigger [(ngModel)]="property.value"
                                                            (ngModelChange)="colorChosen(property.value)"
                                                            [palette]="this.sharedService.rendererOpportunityObject.contentTemplate.colorPalette"></ngx-colors>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </ng-container>
                                            <ng-container *ngIf="property.type == 'INPUT'">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true">{{ property.name
                                                        }}</nz-form-label>
                                                    <nz-form-control [nzSpan]="24">
                                                        <input nz-input [(ngModel)]="property.value" />
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </ng-container>
                                            <ng-container *ngIf="property.type == 'REVIEW'">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true">{{ property.name
                                                        }}</nz-form-label>
                                                    <nz-form-control [nzSpan]="24">
                                                        <button nz-button (click)="openReviewPicker()"><span>Choose
                                                                review</span></button>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </ng-container>
                                            <ng-container *ngIf="property.type == 'IMAGESELECT'">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true">{{ property.name
                                                        }}</nz-form-label>
                                                    <nz-form-control [nzSpan]="24">
                                                        <div class="image-select-box">
                                                            <img class="image-select-chosen-image"
                                                                src="{{property.value}}">
                                                        </div>
                                                        <nz-button-group>
                                                            <button nz-button
                                                                (click)="openPickerSingle(property, 'MEDIA')"><span
                                                                    nz-icon nzType="file-image"
                                                                    nzTheme="outline"></span><span>Change</span></button>
                                                            <button nz-button
                                                                (click)="openImageEditor(property)"><span>Crop</span></button>
                                                            <!-- <button nz-button (click)="openImageBackgroundEditor(property)">Edit
                                                    background</button> -->
                                                        </nz-button-group>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </ng-container>
                                            <ng-container *ngIf="property.type == 'DATALIST'">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true">{{ property.name
                                                        }}</nz-form-label>
                                                    <nz-form-control [nzSpan]="24">
                                                        <ng-container
                                                            *ngFor="let item of property.value; let itemIndex=index">
                                                            <div class="custom-card mini">
                                                                <div class="custom-card-body mini">
                                                                    <div class="list-title" style="margin-top: -11px;
                                                            position: absolute;
                                                            right: 10px; z-index: 9999; cursor: pointer;">
                                                                        <span
                                                                            (click)="this.removeItemFromArray(itemIndex, property.value)"
                                                                            nz-icon nzType="close"
                                                                            nzTheme="outline"></span>
                                                                    </div>
                                                                    <div nz-row>
                                                                        <div nz-col nzSpan="24">
                                                                            <ng-container *ngFor="let field of item">
                                                                                <ng-container
                                                                                    *ngIf="field.display != false">
                                                                                    <ng-container
                                                                                        *ngIf="field.type == 'INPUT'">
                                                                                        <nz-form-item
                                                                                            style="margin-bottom: 4px !important;">
                                                                                            <nz-form-label
                                                                                                [nzNoColon]="true">{{field.name}}</nz-form-label>
                                                                                            <nz-form-control
                                                                                                [nzSpan]="24">
                                                                                                <input nz-input
                                                                                                    [(ngModel)]="field.value" />
                                                                                            </nz-form-control>
                                                                                        </nz-form-item>
                                                                                    </ng-container>

                                                                                    <ng-container
                                                                                        *ngIf="field.type == 'SELECT'">
                                                                                        <nz-form-item>
                                                                                            <nz-form-label
                                                                                                [nzNoColon]="true">{{
                                                                                                field.name
                                                                                                }}</nz-form-label>
                                                                                            <nz-form-control
                                                                                                [nzSpan]="24">
                                                                                                <nz-select
                                                                                                    [(ngModel)]="field.value"
                                                                                                    nzPlaceHolder="Choose"
                                                                                                    [compareWith]="compareFn2">
                                                                                                    <nz-option
                                                                                                        *ngFor="let option of field.options"
                                                                                                        [nzValue]="option"
                                                                                                        [nzLabel]="option.title"></nz-option>
                                                                                                </nz-select>
                                                                                            </nz-form-control>
                                                                                        </nz-form-item>
                                                                                    </ng-container>

                                                                                    <ng-container
                                                                                        *ngIf="field.type == 'RICHTEXT'">
                                                                                        <nz-form-item
                                                                                            style="margin-bottom: 4px !important;">
                                                                                            <nz-form-label
                                                                                                [nzNoColon]="true">{{field.name}}</nz-form-label>
                                                                                            <nz-form-control
                                                                                                [nzSpan]="24">
                                                                                                <textarea nz-input
                                                                                                    [(ngModel)]="field.value"
                                                                                                    [nzAutosize]="{ minRows: 2, maxRows: 15 }"></textarea>
                                                                                            </nz-form-control>
                                                                                        </nz-form-item>
                                                                                    </ng-container>
                                                                                    <ng-container
                                                                                        *ngIf="field.type == 'IMAGESELECT'">
                                                                                        <nz-form-item>
                                                                                            <nz-form-label
                                                                                                [nzNoColon]="true">{{
                                                                                                field.name
                                                                                                }}</nz-form-label>
                                                                                            <nz-form-control
                                                                                                [nzSpan]="24">
                                                                                                <div
                                                                                                    class="image-select-box">
                                                                                                    <img class="image-select-chosen-image"
                                                                                                        src="{{field.value}}">
                                                                                                </div>
                                                                                                <nz-button-group>
                                                                                                    <button nz-button
                                                                                                        (click)="openPickerSingle(field, 'MEDIA')"><span
                                                                                                            nz-icon
                                                                                                            nzType="file-image"
                                                                                                            nzTheme="outline"></span><span>Change</span></button>
                                                                                                    <button nz-button
                                                                                                        (click)="openImageEditor(field)"><span>Crop</span></button>
                                                                                                    <!-- <button nz-button
                                                                                            (click)="openImageBackgroundEditor(field)">Edit
                                                                                            background</button> -->
                                                                                                </nz-button-group>
                                                                                            </nz-form-control>
                                                                                        </nz-form-item>
                                                                                    </ng-container>

                                                                                    <ng-container
                                                                                        *ngIf="field.type == 'ICONSELECT'">
                                                                                        <nz-form-item
                                                                                            style="margin-bottom: 4px !important;">
                                                                                            <nz-form-label
                                                                                                [nzNoColon]="true">{{field.name}}</nz-form-label>
                                                                                            <nz-form-control
                                                                                                [nzSpan]="24">
                                                                                                <button
                                                                                                    style="font-size: 16px"
                                                                                                    nz-button nz-popover
                                                                                                    [nzPopoverContent]="iconPickerTemplate"
                                                                                                    nzPopoverPlacement="bottomLeft"
                                                                                                    nzPopoverTrigger="click"
                                                                                                    [(nzPopoverVisible)]="field.popoverVisible"
                                                                                                    (click)="iconSelectItem = field"><span
                                                                                                        class="{{field.value}}"></span></button>
                                                                                            </nz-form-control>
                                                                                        </nz-form-item>
                                                                                    </ng-container>
                                                                                </ng-container>
                                                                            </ng-container>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <button nz-button (click)="pushDataListValue(property)"
                                                            nzBlock><span nz-icon nzType="plus"
                                                                nzTheme="outline"></span>Add
                                                            item</button>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </ng-container>
                                            <ng-container *ngIf="property.type == 'PRODUCTLIST'">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true">{{ property.name
                                                        }}</nz-form-label>
                                                    <nz-form-control [nzSpan]="24">
                                                        <nz-segmented [(ngModel)]="property.mode"
                                                            [nzOptions]="['Static', 'Dynamic']" nzBlock
                                                            style="margin-bottom: 8px;"></nz-segmented>
                                                        <ng-container *ngIf="property.mode == 1">
                                                            <nz-form-item>
                                                                <nz-form-label
                                                                    [nzNoColon]="true">Collection</nz-form-label>
                                                                <nz-form-control [nzSpan]="24">
                                                                    <nz-select
                                                                        [(ngModel)]="getArrayItem(property.dynamicProperties, 'Collection').value"
                                                                        nzPlaceHolder="Choose"
                                                                        [compareWith]="compareFn3" nzShowSearch>
                                                                        <nz-option
                                                                            *ngFor="let collection of collections"
                                                                            [nzValue]="collection"
                                                                            [nzLabel]="collection.data.title"></nz-option>
                                                                    </nz-select>
                                                                </nz-form-control>
                                                            </nz-form-item>
                                                            <nz-form-item>
                                                                <nz-form-label [nzNoColon]="true">Number of
                                                                    products</nz-form-label>
                                                                <nz-form-control [nzSpan]="24">
                                                                    <nz-input-number
                                                                        [(ngModel)]="getArrayItem(property.dynamicProperties, 'Number of products').value"
                                                                        [nzMin]="1" [nzStep]="1"></nz-input-number>
                                                                </nz-form-control>
                                                            </nz-form-item>
                                                            <nz-form-item>
                                                                <nz-form-label [nzNoColon]="true">Sort
                                                                    by</nz-form-label>
                                                                <nz-form-control [nzSpan]="24">
                                                                    <nz-select
                                                                        [(ngModel)]="getArrayItem(property.dynamicProperties, 'Sort by').value"
                                                                        nzPlaceHolder="Choose"
                                                                        [compareWith]="compareFn2">
                                                                        <nz-option [nzValue]="'NEWEST'"
                                                                            [nzLabel]="'Newest'"></nz-option>
                                                                    </nz-select>
                                                                </nz-form-control>
                                                            </nz-form-item>
                                                            <button nz-button
                                                                (click)="this.getDynamicProducts(property, property.dynamicProperties)"
                                                                nzBlock [nzLoading]="loadingDynamicProducts">Refresh
                                                                products</button>&nbsp;

                                                        </ng-container>
                                                        <ng-container *ngIf="property.mode == 0">
                                                            <div cdkDropList
                                                                (cdkDropListDropped)="dropProduct($event, property.value)">

                                                                <ng-container
                                                                    *ngFor="let item of property.value; let itemIndex=index">
                                                                    <div class="custom-card mini" cdkDrag>
                                                                        <div class="custom-card-body mini">
                                                                            <div nz-row [nzGutter]="8">
                                                                                <div nz-col nzSpan="3" cdkDragHandle
                                                                                    style="line-height: 40px; text-align: center; cursor: grab;">
                                                                                    <span nz-icon nzType="menu"
                                                                                        nzTheme="outline"></span>
                                                                                </div>
                                                                                <div nz-col nzSpan="4"><img
                                                                                        class="list-image"
                                                                                        src="{{item.imageUrl}}">
                                                                                </div>
                                                                                <div nz-col nzSpan="15">
                                                                                    <div class="list-title">
                                                                                        {{item.title}}
                                                                                    </div>
                                                                                </div>
                                                                                <div nz-col nzSpan="2">
                                                                                    <div class="list-title"><span
                                                                                            (click)="this.removeItemFromArray(itemIndex, property.value)"
                                                                                            nz-icon nzType="close"
                                                                                            nzTheme="outline"></span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="custom-card-footer mini"
                                                                            style="padding: 0px 8px;">
                                                                            <nz-collapse>
                                                                                <nz-collapse-panel nzHeader="Edit"
                                                                                    style="border-bottom: none !important">
                                                                                    <!-- <nz-form-item>
                                                                            <nz-form-label
                                                                                [nzNoColon]="true">Title</nz-form-label>
                                                                            <nz-form-control [nzSpan]="24">
                                                                                <textarea
                                                                                    [nzAutosize]="{ minRows: 2, maxRows: 15 }"
                                                                                    nz-input
                                                                                    [(ngModel)]="item.title"></textarea>
                                                                            </nz-form-control>
                                                                        </nz-form-item>
                                                                        <nz-form-item>
                                                                            <nz-form-label
                                                                                [nzNoColon]="true">Description</nz-form-label>
                                                                            <nz-form-control [nzSpan]="24">
                                                                                <textarea
                                                                                    [nzAutosize]="{ minRows: 2, maxRows: 15 }"
                                                                                    nz-input
                                                                                    [(ngModel)]="item.description"></textarea>
                                                                            </nz-form-control>
                                                                        </nz-form-item> -->
                                                                                    <nz-form-item>
                                                                                        <nz-form-label
                                                                                            [nzNoColon]="true">Price</nz-form-label>
                                                                                        <nz-form-control [nzSpan]="24">
                                                                                            <input nz-input
                                                                                                [(ngModel)]="item.price" />
                                                                                        </nz-form-control>
                                                                                    </nz-form-item>
                                                                                    <nz-form-item>
                                                                                        <nz-form-label
                                                                                            [nzNoColon]="true">Compare
                                                                                            at
                                                                                            price</nz-form-label>
                                                                                        <nz-form-control [nzSpan]="24">
                                                                                            <input nz-input
                                                                                                [(ngModel)]="item.compareAtPrice" />
                                                                                        </nz-form-control>
                                                                                    </nz-form-item>
                                                                                    <ng-container *ngIf="item.data">
                                                                                        <nz-form-item>
                                                                                            <nz-form-label
                                                                                                [nzNoColon]="true">Link</nz-form-label>
                                                                                            <nz-form-control
                                                                                                [nzSpan]="24">
                                                                                                <input nz-input
                                                                                                    [(ngModel)]="item.data.onlineStoreUrl" />
                                                                                            </nz-form-control>
                                                                                        </nz-form-item>
                                                                                    </ng-container>
                                                                                    <nz-form-item>
                                                                                        <nz-form-label
                                                                                            [nzNoColon]="true">Image</nz-form-label>
                                                                                        <nz-form-control [nzSpan]="24">
                                                                                            <div
                                                                                                class="image-select-box">
                                                                                                <img class="image-select-chosen-image"
                                                                                                    src="{{item.imageUrl}}">
                                                                                            </div>
                                                                                            <nz-button-group
                                                                                                style="margin-bottom: 8px; float: left;">
                                                                                                <button nz-button
                                                                                                    (click)="openPickerSingle(item, 'MEDIA')"><span
                                                                                                        nz-icon
                                                                                                        nzType="file-image"
                                                                                                        nzTheme="outline"></span><span>Change</span></button>
                                                                                                <button nz-button
                                                                                                    (click)="openImageEditor(item)"><span>Crop</span></button>
                                                                                                <!-- <button nz-button
                                                                                        (click)="openImageBackgroundEditor(item)">Edit
                                                                                        background</button> -->
                                                                                            </nz-button-group>
                                                                                        </nz-form-control>
                                                                                    </nz-form-item>
                                                                                </nz-collapse-panel>
                                                                            </nz-collapse>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </div>

                                                            <div class="list-add-item"
                                                                (click)="this.sharedService.openPicker(property, 'PRODUCTS', 'MULTI')">
                                                                <span nz-icon nzType="plus"
                                                                    nzTheme="outline"></span>&nbsp;Add
                                                                item
                                                            </div>
                                                        </ng-container>

                                                    </nz-form-control>
                                                </nz-form-item>
                                            </ng-container>

                                            <ng-container *ngIf="property.type == 'COLLECTIONLIST'">
                                                <nz-form-item>
                                                    <nz-form-label [nzNoColon]="true">{{ property.name
                                                        }}</nz-form-label>
                                                    <nz-form-control [nzSpan]="24">

                                                        <div cdkDropList
                                                            (cdkDropListDropped)="dropProduct($event, property.value)">

                                                            <ng-container
                                                                *ngFor="let item of property.value; let itemIndex=index">
                                                                <div class="custom-card mini" cdkDrag>
                                                                    <div class="custom-card-body mini">
                                                                        <div nz-row [nzGutter]="8">
                                                                            <div nz-col nzSpan="3" cdkDragHandle
                                                                                style="line-height: 40px; text-align: center; cursor: grab;">
                                                                                <span nz-icon nzType="menu"
                                                                                    nzTheme="outline"></span>
                                                                            </div>
                                                                            <div nz-col nzSpan="19">
                                                                                <div class="list-title">
                                                                                    {{item.data.title}}
                                                                                </div>
                                                                            </div>
                                                                            <div nz-col nzSpan="2">
                                                                                <div class="list-title"><span
                                                                                        (click)="this.removeItemFromArray(itemIndex, property.value)"
                                                                                        nz-icon nzType="close"
                                                                                        nzTheme="outline"></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="custom-card-footer mini"
                                                                        style="padding: 0px 8px;">
                                                                        <nz-collapse>
                                                                            <nz-collapse-panel nzHeader="Edit"
                                                                                style="border-bottom: none !important">
                                                                                <nz-form-item>
                                                                                    <nz-form-label
                                                                                        [nzNoColon]="true">Price</nz-form-label>
                                                                                    <nz-form-control [nzSpan]="24">
                                                                                        <input nz-input
                                                                                            [(ngModel)]="item.data.price" />
                                                                                    </nz-form-control>
                                                                                </nz-form-item>
                                                                                <nz-form-item>
                                                                                    <nz-form-label
                                                                                        [nzNoColon]="true">Compare at
                                                                                        price</nz-form-label>
                                                                                    <nz-form-control [nzSpan]="24">
                                                                                        <input nz-input
                                                                                            [(ngModel)]="item.data.compareAtPrice" />
                                                                                    </nz-form-control>
                                                                                </nz-form-item>
                                                                                <ng-container *ngIf="item.data">
                                                                                    <nz-form-item>
                                                                                        <nz-form-label
                                                                                            [nzNoColon]="true">Link</nz-form-label>
                                                                                        <nz-form-control [nzSpan]="24">
                                                                                            <input nz-input
                                                                                                [(ngModel)]="item.data.onlineStoreUrl" />
                                                                                        </nz-form-control>
                                                                                    </nz-form-item>
                                                                                </ng-container>
                                                                                <nz-form-item>
                                                                                    <nz-form-label
                                                                                        [nzNoColon]="true">Image</nz-form-label>
                                                                                    <nz-form-control [nzSpan]="24">
                                                                                        <div class="image-select-box">
                                                                                            <img class="image-select-chosen-image"
                                                                                                src="{{item.data.imageUrl}}">
                                                                                        </div>
                                                                                        <nz-button-group
                                                                                            style="margin-bottom: 8px; float: left;">
                                                                                            <button nz-button
                                                                                                (click)="openPickerSingle(item.data, 'MEDIA')"><span
                                                                                                    nz-icon
                                                                                                    nzType="file-image"
                                                                                                    nzTheme="outline"></span><span>Change</span></button>
                                                                                            <button nz-button
                                                                                                (click)="openImageEditor(item)"><span>Crop</span></button>
                                                                                            <!-- <button nz-button
                                                                                    (click)="openImageBackgroundEditor(item)">Edit
                                                                                    background</button> -->
                                                                                        </nz-button-group>
                                                                                    </nz-form-control>
                                                                                </nz-form-item>
                                                                            </nz-collapse-panel>
                                                                        </nz-collapse>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>

                                                        <!-- <div class="list-add-item"
                                                (click)="this.sharedService.openPicker(property, 'PRODUCTS', 'MULTI')">
                                                <span nz-icon nzType="plus" nzTheme="outline"></span>&nbsp;Add
                                                collection
                                            </div> -->

                                                        <button nz-button
                                                            (click)="this.sharedService.openCollectionPicker(property)"
                                                            nzBlock><span nz-icon nzType="plus"
                                                                nzTheme="outline"></span><span>Add
                                                                collection</span></button>

                                                    </nz-form-control>
                                                </nz-form-item>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>

                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <!-- <div class="right-column">
            <ng-container *ngIf="this.activeBlock.properties">
                <ng-container *ngFor="let property of this.activeBlock.properties">
                    <ng-container *ngIf="property.type == 'INPUT'">
                        <nz-form-item>
                            <nz-form-label>{{ property.name }}</nz-form-label>
                            <nz-form-control [nzSpan]="24">
                                <input nz-input [(ngModel)]="property.value" />
                            </nz-form-control>
                        </nz-form-item>
                    </ng-container>
                    <ng-container *ngIf="property.type == 'RICHTEXT'">
                        <nz-form-item>
                            <nz-form-label>{{ property.name }}</nz-form-label>
                            <nz-form-control [nzSpan]="24">
                                <textarea rows="4" nz-input [(ngModel)]="property.value"></textarea>
                            </nz-form-control>
                        </nz-form-item>
                    </ng-container>
                    <ng-container *ngIf="property.type == 'IMAGESELECT'">
                        <div>Select an image</div>
                        <div nz-row [nzGutter]="[16, 24]">
                            <ng-container *ngFor="let image of this.sharedService.rendererOpportunityObject.scrapedData.images">
                                <ng-container *ngIf="image.src">
                                    <div nz-col class="gutter-row" [nzSpan]="8">
                                        <img (click)="property.value = image.src" class="image-list"
                                            src="{{image.src}}">
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div> -->
    </ng-container>

    <ng-template #blockLibraryTemplate>
        <div style="width: 300px;">
            <div nz-row [nzGutter]="[16, 16]">
                <ng-container *ngFor="let blockType of blockLibrary">
                    <div nz-col [nzSpan]="8">
                        <div class="block-palette-block" (click)="addBlock(blockType, 0)">
                            <div class="block-palette-icon">
                                <span nz-icon nzType="{{blockType.icon}}" nzTheme="outline"></span>
                            </div>
                            {{blockType.title}}
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-template>
</div>




<!-- <ng-container *ngIf="this.loading">
        <div class="content-column-left"></div>
        <div class="content-column-right">
            <div class="custom-card">
                <div class="custom-card-body">
                    <nz-skeleton [nzParagraph]="{ rows: 2 }" [nzActive]="true"></nz-skeleton>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="this.this.sharedService.rendererOpportunityObject.stage == 'NEW'">
        <div class="content-column-left">
            <nz-steps [nzCurrent]="0" nzDirection="vertical">
                <nz-step nzTitle="Generating proposal"></nz-step>
                <nz-step nzTitle="Approval"></nz-step>
                <nz-step nzTitle="Publishing"></nz-step>
                <nz-step nzTitle="Live"></nz-step>
            </nz-steps>
        </div>


        <div class="content-column-right">
            <div class="custom-card" style="text-align: center">
                <div class="custom-card-body">
                    <div class="meter purple">
                        <span style="width: 100%"></span>
                    </div>
                    <h3>Copilot is generating a proposal.</h3>
                    <h3>This may take a moment.</h3>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="this.this.sharedService.rendererOpportunityObject.stage == 'PENDING'">
        <div class="content-column-left">
            <nz-steps [nzCurrent]="1" nzDirection="vertical">
                <nz-step nzTitle="Generating proposal"></nz-step>
                <nz-step nzTitle="Approval"></nz-step>
                <nz-step nzTitle="Publishing"></nz-step>
                <nz-step nzTitle="Live"></nz-step>
            </nz-steps>
        </div>


        <div class="content-column-right">
            <div class="custom-card">
                <div class="custom-card-body">
                    <pre style="white-space: pre-wrap;">{{this.this.sharedService.rendererOpportunityObject.proposal}}</pre>
                </div>
            </div>
        </div>
    </ng-container> -->


<!-- <div class="middle-column">
        <div class="right-column-content">
            <div class="messages-wrapper">
                <ng-container *ngFor="let message of this.sharedService.rendererOpportunityObject.messages">
                    <ng-container *ngIf="message.sender">
                        <div class="message-row">
                            <div class="message-wrapper right">
                                <div class="message-header">
                                    <div class="message-title">{{message.sender}}</div>
                                    <div class="message-date">{{message.createdAt | date}}</div>
                                </div>
                                <div class="message-body">
                                    {{message.body}}
                                </div>
                            </div>
                        </div>

                    </ng-container>
                    <ng-container *ngIf="!message.sender">
                        <div class="message-row">
                            <div class="message-wrapper">
                                <div class="message-header">
                                    <div class="message-title">Copilot</div>
                                    <div class="message-date">11:30am 4/20/23</div>
                                </div>
                                <div class="message-body">
                                    Test
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <div class="textbox-wrapper">
                <textarea placeholder="Type a message..." nz-input [(ngModel)]="this.newMessageBody"></textarea>
                <button nz-button nzType="default" (click)="this.newMessage()">Send</button>
            </div>
        </div>
    </div> -->

<ng-template #iconPickerTemplate>
    <div style="margin: -12px -16px -12px -16px">
        <div style="width: 300px; border-bottom: 1px solid #E5E7EB; padding: 12px;">
            <input nz-input placeholder="Search" [(ngModel)]="iconSearchTerm" (ngModelChange)="searchIcons()" />
        </div>
        <div style="width: 300px; height: 300px; overflow-y: scroll; overflow-x: hidden; padding: 12px;">
            <div nz-row [nzGutter]="[8, 8]">
                <ng-container *ngIf="!this.iconSearchMode">
                    <ng-container *ngFor="let icon of this.sharedService.iconLibrary.icons">
                        <div nz-col [nzSpan]="6">
                            <button style="font-size: 16px" nz-button nzBlock
                                (click)="chooseIcon(this.iconSelectItem, icon.value)"><span
                                    class="{{icon.value}}"></span></button>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="this.iconSearchMode">
                    <ng-container *ngFor="let icon of this.iconSearchList">
                        <div nz-col [nzSpan]="6">
                            <button style="font-size: 16px" nz-button nzBlock
                                (click)="chooseIcon(this.iconSelectItem, icon.value)"><span
                                    class="{{icon.value}}"></span></button>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #contentTemplate>
    <div>
        <nz-form-item style="width: 300px; margin-bottom: 8px !important;">
            <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
            </nz-form-label>
            <nz-form-control nzSpan="24">
                <textarea nz-input placeholder="Add feedback (optional)..." style="height: 100px;"></textarea>
            </nz-form-control>
        </nz-form-item>
        <div style="text-align: right">
            <button nzType="primary" nz-button>Send</button>
        </div>
    </div>
</ng-template>

<ng-template #contentTemplate2>
    <div>
        <nz-form-item style="width: 300px; margin-bottom: 8px !important;">
            <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">
            </nz-form-label>
            <nz-form-control nzSpan="24">
                <textarea nz-input placeholder="Add feedback (optional)..." style="height: 100px;"></textarea>
            </nz-form-control>
        </nz-form-item>
        <div style="text-align: right">
            <button nzType="primary" nz-button (click)="remixCopy()">Send</button>
        </div>
    </div>
</ng-template>