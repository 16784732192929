// src/app/services/facebook.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FacebookService {

  constructor() {
    (window as any).fbAsyncInit = () => {
      (window as any).FB.init({
        appId      : 'YOUR_APP_ID',
        cookie     : true,
        xfbml      : true,
        version    : 'v10.0'
      });
      (window as any).FB.AppEvents.logPageView();   
    };
  }

  loginWithFacebook(): Promise<any> {
    return new Promise((resolve, reject) => {
      (window as any).FB.login((response: any) => {
        if (response.authResponse) {
          resolve(response.authResponse.accessToken);
        } else {
          reject('User cancelled login or did not fully authorize.');
        }
      }, {scope: 'public_profile,email'});
    });
  }
}