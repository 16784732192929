<div class="page-wrapper">
    <img class="logo-signup" src="../../../assets/rippleLogo.png" />

    <div class="content-wrapper">
        <div class="content-wrapper-inner">
            <div class="custom-card">
                <div class="custom-card-body">

                    {{this.errorString}}
                    <form nz-form nzLayout="vertical" (ngSubmit)="onSubmit()">
                        <nz-form-item>
                            <nz-form-label [nzSpan]="6" nzFor="email">Email</nz-form-label>
                            <nz-form-control nzHasFeedback nzErrorTip="Please enter a valid email" [nzSpan]="24">
                                <input nz-input [(ngModel)]="this.newUserObject.email" name="email" type="email"
                                    id="email" email>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [nzSpan]="6" nzFor="password">Password</nz-form-label>
                            <nz-form-control [nzSpan]="24" nzHasFeedback
                                nzErrorTip="Password must be at least 6 characters">
                                <input nz-input [(ngModel)]="this.newUserObject.password" name="password"
                                    type="password" id="password" minlength="6">
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <button type="submit" nz-button nzType="primary" nzBlock>Log in</button>
                            </nz-form-control>
                        </nz-form-item>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>