import { Component } from '@angular/core';
import { ApiService } from '../api.service';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-database-editor',
  templateUrl: './database-editor.component.html',
  styleUrls: ['./database-editor.component.scss']
})
export class DatabaseEditorComponent {
  saving: any = false;

  constructor(  
    public apiService: ApiService,
    public sharedService: SharedService
  ) { }

  saveDatabaseObject() {
    this.saving = true;
    this.sharedService.databaseEditorObjectFull.data = JSON.parse(this.sharedService.databaseEditorObject);
    this.apiService.saveDatabaseObject(this.sharedService.databaseEditorObjectFull).subscribe(response => {
      this.saving = false;
      this.sharedService.closeDatabaseEditor();
    });   
  }

}
