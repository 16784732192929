import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { appConfig } from './app.config.js';
import { environment } from './../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // serverUrl = "https://ripple-web-app-ae91a6d3410b.herokuapp.com";
  serverUrl = environment.apiUrl;
  // serverUrl = "https://ripple-testing-e6c1c3fde786.herokuapp.com";
  // serverUrl = "https://ripple-production.azurewebsites.net";
  // serverUrl = "https://ripple-syncs.azurewebsites.net";

  constructor(private http: HttpClient) { }

  registerUser(userObject: any) {
    console.log(userObject);
    return this.http.post(`${this.serverUrl}/users/register`, { userObject: userObject });

  }

  authenticateUser(userObject: any) {
    console.log(userObject);
    return this.http.post(this.serverUrl + '/users/authenticate', { userObject: userObject });

  }

  newConnection(connectionObject: any) {
    console.log(connectionObject);
    return this.http.post(this.serverUrl + '/connection', { connectionObject: connectionObject });
  }

  getConnection(connectionId: any) {
    console.log(connectionId);
    return this.http.get(this.serverUrl + `/connection/${connectionId}`);
  }

  getConnections() {
    return this.http.get(this.serverUrl + `/connection`);
  }

  getOpportunity(opportunityId: any) {
    console.log(opportunityId);
    return this.http.get(this.serverUrl + `/opportunity/get/${opportunityId}`);
  }

  newMessage(messageObject: any) {
    console.log(messageObject);
    return this.http.post(this.serverUrl + '/message', { messageObject: messageObject });
  }

  getPendingOpportunities() {
    return this.http.get(this.serverUrl + `/opportunity/pending`);
  }

  getNewOpportunities() {
    return this.http.get(this.serverUrl + `/opportunity/new`);
  }

  getSuggestionOpportunities() {
    return this.http.get(this.serverUrl + `/opportunity/suggested`);
  }

  getLiveOpportunities() {
    return this.http.get(this.serverUrl + `/opportunity/live`);
  }

  getAllOpportunities() {
    return this.http.get(this.serverUrl + `/opportunity/all`);
  }

  getRecentOpportunities() {
    return this.http.get(this.serverUrl + `/opportunity/recent`);
  }

  getWorkspace(workspaceId: any) {
    console.log(workspaceId);
    return this.http.get(this.serverUrl + `/workspace/${workspaceId}`);
  }

  completeSignup(workspaceObject: any) {
    console.log(workspaceObject);
    return this.http.post(this.serverUrl + '/signup2', { workspaceObject: workspaceObject });
  }

  newIntegration(integrationObject: any) {
    console.log(integrationObject);
    return this.http.post(this.serverUrl + '/integration', { integrationObject: integrationObject });
  }

  opportunityDetection() {
    return this.http.get(this.serverUrl + `/opportunityDetection`);
  }

  generateProposal(opportunityId: any) {
    console.log(opportunityId);
    return this.http.get(this.serverUrl + `/generateProposal/${opportunityId}`);
  }

  scrapeImagesAndColors() {
    return this.http.get(this.serverUrl + `/scrapeImagesAndColors`);
  }

  generateTemplateContent(opportunityId: any) {
    console.log(opportunityId);
    return this.http.get(this.serverUrl + `/generateTemplateContent/${opportunityId}`);
  }

  publishOpportunity(opportunityId: any, opportunityObject: any, mode: any) {
    console.log(opportunityId);
    return this.http.post(this.serverUrl + `/publishOpportunity/${opportunityId}`, {opportunityObject: opportunityObject, mode: mode});
  }

  publishSMS(opportunityId: any, opportunityObject: any, mode: any) {
    console.log(opportunityId);
    return this.http.post(this.serverUrl + `/publishSMS/${opportunityId}`, {opportunityObject: opportunityObject, mode: mode});
  }

  saveTemplate(opportunityObject: any) {
    return this.http.post(this.serverUrl + '/saveTemplate', { opportunityObject });
  }

  saveOpportunity(opportunityObject: any) {
    return this.http.post(this.serverUrl + '/saveOpportunity', { opportunityObject: opportunityObject });
  }

  sendSubjectLineFeedback(opportunityObject: any, subjectLineFeedback: any) {
    return this.http.post(this.serverUrl + '/subjectLineFeedback', { opportunityObject, subjectLineFeedback });
  }

  generateEmailCampaignCalendar() {
    return this.http.get(this.serverUrl + `/generateEmailCalendar`);
  }

  getEmailCampaignCalendar(month: any, year: any) {
    return this.http.get(this.serverUrl + `/emailCampaignCalendar/${month}/${year}`);
  }

  getEmailCampaigns() {
    return this.http.get(this.serverUrl + `/email/campaigns`);
  }

  saveBrandStyles(brandStyles: any) {
    return this.http.post(this.serverUrl + '/saveBrandStyles', { brandStyles });
  }

  newEmail(prompt: any, sendTime: any) {
    return this.http.post(this.serverUrl + '/newEmail', { prompt: prompt, sendTime: sendTime });
  }

  getMedia() {
    return this.http.get(this.serverUrl + `/media`);
  }

  getMediaPage(pageNumber: any, mediaCategory: any) {
    return this.http.get(this.serverUrl + `/media/${pageNumber}/${mediaCategory}`);
  }

  getProducts() {
    return this.http.get(this.serverUrl + `/products`);
  }

  getProductsPage(pageNumber: any) {
    return this.http.get(this.serverUrl + `/products/${pageNumber}`);
  }

  checkOpportunityStatus(opportunityId: any) {
    console.log(opportunityId);
    return this.http.get(this.serverUrl + `/opportunityGenerated/${opportunityId}`);
  }

  searchMedia(query: any) {
    return this.http.post(this.serverUrl + '/searchMedia', { query: query });
  }

  getCurrentWorkspace() {
    return this.http.get(this.serverUrl + `/workspace`);
  }

  getCustomerObjectTypes() {
    return this.http.get(this.serverUrl + `/customerObjectTypes`);
  }

  getCustomerObjects(type: any) {
    return this.http.get(this.serverUrl + `/customerObjectTypes/${type}`);
  }

  getEmailTypes() {
    return this.http.get(this.serverUrl + `/emailTypes`);
  }

  uploadImages(base64Images: any, fileName: any) {
    return this.http.post(this.serverUrl + '/uploadImages', { images: base64Images, fileName: fileName });
  }

  getKlaviyoListsAndSegments() {
    return this.http.get(this.serverUrl + `/klaviyoListsAndSegments`);
  }

  duplicateOpportunity(opp: any) {
    return this.http.post(this.serverUrl + `/duplicate/${opp}`, {});
  }

  duplicateOpportunityChild(opp: any) {
    return this.http.post(this.serverUrl + `/duplicateChild/${opp}`, {});
  }

  searchProducts(query: any) {
    return this.http.post(this.serverUrl + '/searchProducts', { query: query });
  }

  getIntegration(integrationId: any) {
    console.log(integrationId);
    return this.http.get(this.serverUrl + `/integration/get/${integrationId}`);
  }

  saveIntegration(integrationObject: any) {
    console.log(integrationObject);
    return this.http.post(this.serverUrl + `/saveIntegration`, { integrationObject: integrationObject });
  }

  syncShopifyProducts(integrationId: any) {
    console.log(integrationId);
    return this.http.get(this.serverUrl + `/shopifyProducts/${integrationId}`);
  }

  syncShopifyImages(integrationId: any) {
    console.log(integrationId);
    return this.http.get(this.serverUrl + `/shopifyImages/${integrationId}`);
  }

  syncShopifyCollections(integrationId: any) {
    console.log(integrationId);
    return this.http.get(this.serverUrl + `/shopifyCollections/${integrationId}`);
  }

  vectorDbProducts() {
    return this.http.get(this.serverUrl + `/vectorDbProducts`);
  }

  vectorDbCollections() {
    return this.http.get(this.serverUrl + `/vectorDbCollections`);
  }

  getIntegrations() {
    return this.http.get(this.serverUrl + `/integrations`);
  }

  deleteOpportunity(oppId: any) {
    return this.http.get(this.serverUrl + `/deleteOpportunity/${oppId}`);
  }

  deleteFlow(oppId: any) {
    return this.http.get(this.serverUrl + `/deleteFlow/${oppId}`);
  }

  checkGeneratingStatus(opps: any) {
    return this.http.post(this.serverUrl + `/checkGeneratingStatus`, { opps: opps });
  }

  generateTemplateContent2(opportunityId: any, mode: any, feedback: any, index: any = 0) {
    console.log(opportunityId);
    return this.http.post(this.serverUrl + `/generateTemplateContent2/${opportunityId}`, {mode: mode, feedback: feedback, blockIndex: index});
  }

  getBlockTypes() {
    return this.http.get(this.serverUrl + `/blockTypes`);
  }

  getDynamicProducts(queryObject: any) {
    return this.http.post(this.serverUrl + `/getDynamicProducts`, {query: queryObject});
  }

  getCollections() {
    return this.http.get(this.serverUrl + `/collections`);
  }

  getCustomerObjectsPage(type: any, pageNumber: any) {
    return this.http.get(this.serverUrl + `/customerObjectTypes/${type}/${pageNumber}`);
  }

  saveDatabaseObject(object: any) {
    return this.http.post(this.serverUrl + `/saveDatabaseObject`, {object: object});
  }

  createHeaderTemplate() {
    return this.http.post(this.serverUrl + `/createHeaderTemplate`, {});
  }

  createFooterTemplate() {
    return this.http.post(this.serverUrl + `/createFooterTemplate`, {});
  }

  generateEmailCalendar2(body: any) {
    return this.http.post(this.serverUrl + `/generateEmailCalendar2`, body);
  }

  saveEmailCalendar(calendar: any) {
    return this.http.post(this.serverUrl + `/saveEmailCalendar`, {emailCalendar: calendar});
  }

  checkEmailJobs() {
    return this.http.get(this.serverUrl + `/checkEmailJobs`);
  }

  tryJobAgain(id: any) {
    return this.http.get(this.serverUrl + `/tryJobAgain/${id}`);
  }

  sendOpenAiChat(message: any, thread=null) {
    return this.http.post(this.serverUrl + `/sendOpenAiChat`, {message: message, thread: thread});
  }

  sendOpenAiPlannerChat(message: any, thread=null, session=null) {
    return this.http.post(this.serverUrl + `/sendOpenAiPlannerChat`, {message: message, thread: thread, session: session});
  }

  sendOpenAiChatBotChat(message: any, thread=null, session=null) {
    return this.http.post(this.serverUrl + `/sendOpenAiChatbotChat`, {message: message, thread: thread, session: session});
  }

  getOpenAiThread(thread: any) {
    return this.http.get(this.serverUrl + `/openAiThread/${thread}`);
  }

  syncDataToOpenAi() {
    return this.http.get(this.serverUrl + `/syncDataToOpenAi`);
  }

  updateWorkspace(workspaceObject: any) {
    console.log(workspaceObject);
    return this.http.post(this.serverUrl + `/workspace/${workspaceObject._id}`, {workspaceObject: workspaceObject});
  }

  openAiChatGenerateEmail(thread: any, run: any, call: any) {
    return this.http.post(this.serverUrl + `/openAiChatGenerateEmail`, {run: run, thread: thread, call: call});
  }

  getAssistantSessions() {
    return this.http.get(this.serverUrl + `/assistantSessions`);
  }

  getAssistantSession(session: any) {
    return this.http.get(this.serverUrl + `/assistantSession/${session}`);
  }

  deleteFile(oppId: any) {
    return this.http.get(this.serverUrl + `/deleteFile/${oppId}`);
  }

  syncShopifyProductsPeriodic(integrationId: any) {
    console.log(integrationId);
    return this.http.get(this.serverUrl + `/shopifyProductsPeriodic/${integrationId}`);
  }

  syncShopifyImagesPeriodic(integrationId: any) {
    console.log(integrationId);
    return this.http.get(this.serverUrl + `/shopifyImagesPeriodic/${integrationId}`);
  }

  syncShopifyCollectionsPeriodic(integrationId: any) {
    console.log(integrationId);
    return this.http.get(this.serverUrl + `/shopifyCollectionsPeriodic/${integrationId}`);
  }

  getWorkspaces() {
    return this.http.get(this.serverUrl + `/workspaces`);
  }

  changeWorkspace(worksapceId:any) {
    return this.http.post(this.serverUrl + `/changeWorkspace`, {workspaceId: worksapceId});
  }

  generateHeroOptions(prompt: any) {
    return this.http.post(this.serverUrl + `/generateHeroOptions`, {prompt: prompt});
  }

  generateSuggestions() {
    return this.http.get(this.serverUrl + `/generateSuggestions`);
  }

  syncKlaviyoEvents(integrationId: any) {
    console.log(integrationId);
    return this.http.get(this.serverUrl + `/klaviyoEvents/${integrationId}`);
  }

  syncKlaviyoEventsPeriodic(integrationId: any) {
    console.log(integrationId);
    return this.http.get(this.serverUrl + `/klaviyoEventsPeriodic/${integrationId}`);
  }

  generateSubjectLineObject(opportunityId: any) {
    return this.http.get(this.serverUrl + `/generateSubjectLineObject/${opportunityId}`);
  }

  generateSocialPostContent(opportunityId: any) {
    return this.http.get(this.serverUrl + `/generateSocialPostContent/${opportunityId}`);
  }

  getFlows() {
    return this.http.get(this.serverUrl + `/flows`);
  }

  generateFlow(prompt: any) {
    return this.http.post(this.serverUrl + `/generateFlow`, {flowPrompt: prompt});
  }

  getFlow(id: any) {
    return this.http.get(this.serverUrl + `/flows/${id}`);
  }

  createReviewSnippets() {
    return this.http.get(this.serverUrl + `/createReviewSnippets`);
  }

  getReviewSnippets() {
    return this.http.get(this.serverUrl + `/reviewSnippets`);
  }

  createFlow(name: any) {
    return this.http.post(this.serverUrl + `/createFlow`, {name: name});
  }

  sendTestEmail(opp: any, emailList: any) {
    return this.http.post(this.serverUrl + `/sendTestEmail`, {email: opp, recipients: emailList});
  }

  cancelSync(syncId: any) {
    return this.http.get(this.serverUrl + `/cancelSync/${syncId}`);
  }

  createTemplate(name: any, instructions: any, oppId: any) {
    return this.http.post(this.serverUrl + `/createTemplate`, {name: name, instructions: instructions, opportunityId: oppId});
  }

  createSavedBlock(block: any, oppId: any) {
    return this.http.post(this.serverUrl + `/createSavedBlock`, {block: block, opportunityId: oppId});
  }

  getEmailTemplates() {
    return this.http.get(this.serverUrl + `/emailTemplates`);
  }

  uploadReviews(reviews: any) {
    return this.http.post(this.serverUrl + `/uploadReviews`, reviews);
  }

  scrapePage(url: any) {
    return this.http.post(this.serverUrl + `/scrapePage`, {url: url});
  }

  getThisWeek() {
    return this.http.get(this.serverUrl + `/opportunity/thisWeek`);
  }

  get14DayPlaceholders() {
    return this.http.get(this.serverUrl + `/opportunity/14DayPlaceholders`);
  }

  getCampaignMetric(metric: any, campaign: any, opportunity: any) {
    return this.http.get(this.serverUrl + `/getMetric?metric=${metric}&campaign=${campaign}&opportunity=${opportunity}`);
  }

  addUserToWorkspace(email: any, workspace: any) {
    return this.http.post(this.serverUrl + `/addUserToWorkspace`, {email: email, workspace: workspace});
  }

  getWorkspaceUsers() {
    return this.http.get(this.serverUrl + `/getWorkspaceUsers`);
  }

  getUserWorkspaces() {
    return this.http.get(this.serverUrl + `/getUserWorkspaces`);
  }

  getSavedBlocks() {
    return this.http.get(this.serverUrl + `/savedBlocks`);
  }

  getMetric(metric: any, filterDate: any = null) {
    return this.http.get(this.serverUrl + `/getMetric?metric=${metric}&filterDate=${filterDate}`);
  }

  getInvite(id: any) {
    return this.http.get(this.serverUrl + `/getInvite/${id}`);
  }

  acceptInvite(id: any) {
    return this.http.post(this.serverUrl + `/acceptInvite/${id}`, {});
  }

  acceptInviteNewUser(id: any, object: any) {
    return this.http.post(this.serverUrl + `/acceptInviteNewUser/${id}`, {inviteObject: object});
  }

  createWorkspace(workspaceObject: any) {
    console.log(workspaceObject);
    return this.http.post(this.serverUrl + '/createWorkspace', { workspaceObject: workspaceObject });
  }

  saveWorkspaceSettings(workspace: any) {
    return this.http.post(this.serverUrl + '/saveWorkspaceSettings', { workspace });
  }

  getProduct(id: any) {
    return this.http.get(this.serverUrl + `/product/${id}`);
  }

  saveProduct(productObject: any) {
    return this.http.post(this.serverUrl + '/saveProduct', { productObject: productObject });
  }

  createProduct() {
    return this.http.post(this.serverUrl + '/createProduct', {});
  }

  deleteProduct(oppId: any) {
    return this.http.get(this.serverUrl + `/deleteProduct/${oppId}`);
  }

  createSMS(message: any) {
    return this.http.post(this.serverUrl + '/createSMS', {message: message});
  }

  generateSMS(prompt: any) {
    return this.http.post(this.serverUrl + `/generateSMS`, {prompt: prompt});
  }

  getFacebookPages(integration: any) {
    return this.http.get(this.serverUrl + `/getFacebookPages/${integration}`);
  }

  createFacebookPost(opportunity: any) {
    return this.http.post(this.serverUrl + `/createFacebookPost/${opportunity}`, {});
  }

  publishSocialPost(opportunity: any) {
    return this.http.post(this.serverUrl + `/publishSocialPost/${opportunity}`, {});
  }

  fillOutTemplate(template: any, prompt: any) {
    return this.http.post(this.serverUrl + `/fillOutTemplate`, {template: template, prompt: prompt});
  }

  getSystemTemplates() {
    return this.http.get(this.serverUrl + `/systemTemplates`);
  }

  getSystemBlocks() {
    return this.http.get(this.serverUrl + `/systemBlocks`);
  }

  getSystemTemplates2() {
    return this.http.get(this.serverUrl + `/systemTemplates2`);
  }

  getSystemTemplates2Flow() {
    return this.http.get(this.serverUrl + `/systemTemplates2Flow`);
  }

  getSystemTemplates2V3() {
    return this.http.get(this.serverUrl + `/systemTemplates2V3`);
  }

  getSystemTemplates2FlowV3() {
    return this.http.get(this.serverUrl + `/systemTemplates2FlowV3`);
  }
}
