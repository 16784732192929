import { Component } from '@angular/core';

@Component({
  selector: 'app-staff-inbox',
  templateUrl: './staff-inbox.component.html',
  styleUrls: ['./staff-inbox.component.scss']
})
export class StaffInboxComponent {

}
