<div class="page-wrapper">
    <div class="content-wrapper">
        <ng-container *ngIf="loading">
            <div class="loading-wrapper">
                <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!loading">

            <nz-page-header style="padding: 0px !important; margin-bottom: 16px;">
                <nz-page-header-title>Account settings</nz-page-header-title>
                <nz-page-header-extra>
                    <button [nzLoading]="saving" (click)="saveBrandStyles()" nz-button nzType="primary">Save</button>
                </nz-page-header-extra>
            </nz-page-header>

            <div class="custom-card">
                <div class="custom-card-body">
                    <nz-form-item>
                        <nz-form-label [nzNoColon]="true">Workspace name</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <input [(ngModel)]="this.workspace.name" nz-input />
                        </nz-form-control>
                    </nz-form-item>


                    <nz-form-item>
                        <nz-form-label [nzNoColon]="true">Domain</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <input [(ngModel)]="this.workspace.domain" nz-input />
                        </nz-form-control>
                    </nz-form-item>


                    <nz-form-item style="margin-bottom: 0px;">
                        <nz-form-label [nzNoColon]="true">Logo</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <div class="image-select-box" style="width: 300px;">
                                <img class="image-select-chosen-image" [src]="this.logoObject.value">
                            </div>
                            <button style="margin-top: 8px" nz-button
                                (click)="this.sharedService.openPicker(this.logoObject, 'MEDIA', 'SINGLE')">Change</button>

                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>

            <div class="custom-card">
                <div class="custom-card-header">Default segments</div>
                <div class="custom-card-body">
                    <nz-form-item>
                        <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">Included
                            segments
                        </nz-form-label>
                        <nz-form-control nzSpan="24">
                            <nz-select nzPlaceHolder="Choose" [compareWith]="compareFn2"
                                [(ngModel)]="this.workspace.brandStyles.audienceInclude" name="audienceInclude"
                                nzMode="multiple">
                                <ng-container *ngFor="let segment of klaviyoListsAndSegments">
                                    <nz-option nzCustomContent [nzLabel]="segment.attributes.name" [nzValue]="segment">
                                        {{segment.attributes.name}} <nz-tag style="float: right"
                                            class="aqua">{{segment.type
                                            | titlecase}}</nz-tag>
                                    </nz-option>
                                </ng-container>

                            </nz-select>

                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzNoColon]="true" nzLabelAlign="left" nzSpan="24">Excluded
                            segments
                        </nz-form-label>
                        <nz-form-control nzSpan="24">
                            <nz-select nzPlaceHolder="Choose" [compareWith]="compareFn2"
                                [(ngModel)]="this.workspace.brandStyles.audienceExclude" name="audienceInclude"
                                nzMode="multiple">
                                <ng-container *ngFor="let segment of klaviyoListsAndSegments">
                                    <nz-option nzCustomContent [nzLabel]="segment.attributes.name" [nzValue]="segment">
                                        {{segment.attributes.name}} <nz-tag style="float: right"
                                            class="aqua">{{segment.type
                                            | titlecase}}</nz-tag>
                                    </nz-option>
                                </ng-container>

                            </nz-select>

                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>

            <nz-form-item>
                <nz-form-control [nzSpan]="24">
                    <div class="custom-card">
                        <div class="custom-card-header" style="line-height: 36px;">
                            Sync to assistant
                            <button (click)="syncToOpenAi()" nz-button nzType="primary" style="float: right">Start
                                sync</button>
                        </div>
                    </div>
                    <div class="custom-card">
                        <div class="custom-card-header" style="line-height: 36px;">
                            Vectorize catalog
                            <button (click)="vectorDbProducts()" nz-button nzType="primary" style="float: right">Start
                                sync</button>
                        </div>
                    </div>
                    <ng-container *ngIf="this.sharedService.currentUser.adminLevel == 'SUPER'">

                        <div class="custom-card">
                            <div class="custom-card-header" style="line-height: 36px;">
                                Upload reviews
                                <!-- <button (click)="createReviewSnippets()" nz-button nzType="primary"
                                style="float: right">Start sync</button> -->
                            </div>
                            <div class="custom-card-body">
                                <input class="form-control" accept=".csv" id="csv" type="file"
                                    (change)="onFileSelect($event.target)" name="myfile">
                            </div>
                        </div>
                        <div class="custom-card">
                            <div class="custom-card-body">
                                <nz-form-item>
                                    <nz-form-label [nzNoColon]="true">Attribution window (days)</nz-form-label>
                                    <nz-form-control [nzSpan]="24">
                                        <nz-input-number
                                            [(ngModel)]="this.workspace.brandStyles.attributionWindow"></nz-input-number>
                                        <!-- <textarea [(ngModel)]="this.workspace.brandStyles.attributionWindow" nz-input></textarea> -->
                                    </nz-form-control>
                                </nz-form-item>

                            </div>
                        </div>
                        <div class="custom-card">
                            <div class="custom-card-header">Feature flags</div>
                            <div class="custom-card-body">
                                <nz-form-item>
                                    <nz-form-label [nzNoColon]="true">Enable SMS</nz-form-label>
                                    <nz-form-control [nzSpan]="24">
                                        <nz-switch [(ngModel)]="this.workspace.brandStyles.enableSMS"></nz-switch>
                                        <!-- <textarea [(ngModel)]="this.workspace.brandStyles.attributionWindow" nz-input></textarea> -->
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-label [nzNoColon]="true">Enable social posts</nz-form-label>
                                    <nz-form-control [nzSpan]="24">
                                        <nz-switch [(ngModel)]="this.workspace.brandStyles.enableSocialPosts"></nz-switch>
                                        <!-- <textarea [(ngModel)]="this.workspace.brandStyles.attributionWindow" nz-input></textarea> -->
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-label [nzNoColon]="true">Enable template-based generation</nz-form-label>
                                    <nz-form-control [nzSpan]="24">
                                        <nz-switch [(ngModel)]="this.workspace.brandStyles.enableTemplateGeneration"></nz-switch>
                                        <!-- <textarea [(ngModel)]="this.workspace.brandStyles.attributionWindow" nz-input></textarea> -->
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-label [nzNoColon]="true">Enable new navigation</nz-form-label>
                                    <nz-form-control [nzSpan]="24">
                                        <nz-switch [(ngModel)]="this.workspace.brandStyles.enableNewNavigation"></nz-switch>
                                        <!-- <textarea [(ngModel)]="this.workspace.brandStyles.attributionWindow" nz-input></textarea> -->
                                    </nz-form-control>
                                </nz-form-item>

                            </div>
                        </div>
                    </ng-container>
                    <!-- <div class="custom-card">
                        <div class="custom-card-body">
                            Sync to OpenAi
                            <button (click)="syncToOpenAi()" nz-button nzType="primary">Start sync</button>
                        </div>
                    </div> -->
                </nz-form-control>
            </nz-form-item>
            <ng-container *ngIf="this.sharedService.currentUser.adminLevel == 'SUPER'">
                <div class="custom-card">
                    <div class="custom-card-body">
                        <nz-form-item>
                            <nz-form-label [nzNoColon]="true">About</nz-form-label>
                            <nz-form-control [nzSpan]="24">
                                <textarea [(ngModel)]="this.workspace.brandStyles.about" nz-input></textarea>
                            </nz-form-control>
                        </nz-form-item>

                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>