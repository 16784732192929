<div class="page-wrapper">
    <div class="content-wrapper">
        <nz-page-header class="site-page-header" nzTitle="Live opportunities">
            <nz-breadcrumb nz-page-header-breadcrumb>
                <nz-breadcrumb-item>Opportunities</nz-breadcrumb-item>
                <nz-breadcrumb-item>Live</nz-breadcrumb-item>
            </nz-breadcrumb>
        </nz-page-header>
        <div class="header-row">
            <div nz-row [nzGutter]="16">
                <div nz-col class="gutter-row" [nzSpan]="12">
                    <div class="inner-box">Title</div>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="5">
                    <div class="inner-box">Status</div>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="4">
                    <div class="inner-box">Updated</div>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="3">
                    <div class="inner-box"></div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="this.loading">
            <div class="custom-card">
                <div class="custom-card-body">
                    <nz-skeleton [nzParagraph]="{ rows: 1 }" [nzActive]="true"></nz-skeleton>
                </div>
            </div>
        </ng-container>
        <ng-container *ngFor="let opportunity of opportunities">
            <nz-card style="line-height: 32px !important">
                <div nz-row [nzGutter]="16">
                    <div nz-col class="gutter-row" [nzSpan]="12">
                        <div class="inner-box">{{opportunity.title}}</div>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="5">
                        <div class="inner-box"></div>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="4">
                        <div class="inner-box"></div>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="3">
                        <div class="inner-box"> <button nz-button nzType="default" style="float: right"
                                [routerLink]="['/opportunities',opportunity._id]">View</button>
                        </div>
                    </div>
                </div>
            </nz-card>
        </ng-container>

    </div>
</div>