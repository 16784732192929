<div class="page-wrapper">
    <div class="content-wrapper">
        <ng-container *ngIf="loading">
            <div class="loading-wrapper">
                <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!loading">

            <nz-page-header style="padding: 0px !important; margin-bottom: 16px;" nzBackIcon>
                <nz-page-header-title>{{this.productObject.title}}</nz-page-header-title>
                <nz-page-header-extra>
                    <nz-button-group>

                        <button [nzLoading]="saving" (click)="saveProduct()" nz-button nzType="primary">Save</button>


                        <button nzType="primary" nz-button nz-dropdown [nzDropdownMenu]="menu2"
                            nzPlacement="bottomRight">
                            <span nz-icon nzType="ellipsis"></span>
                        </button>
                    </nz-button-group>
                    <nz-dropdown-menu #menu2="nzDropdownMenu">
                        <ul nz-menu>
                            <li nz-menu-item (click)="deleteProduct()"><span nz-icon nzType="delete"
                                    nzTheme="outline"></span>&nbsp;&nbsp;Delete</li>
                        </ul>
                    </nz-dropdown-menu>
                </nz-page-header-extra>
            </nz-page-header>

            <div class="custom-card">
                <div class="custom-card-body">
                    <ng-container *ngIf="this.productObject.externalId">
                        <div class="custom-card" style="margin-bottom: 24px;">
                            <div class="custom-card-body">
                                <img style="width: 16px; margin-right: 16px; display: inline; margin-top: -4px;" src="https://cdn3.iconfinder.com/data/icons/social-media-2068/64/_shopping-512.png" />Synced to Shopify
                                product <nz-tag>{{this.productObject.data?.id}}</nz-tag>
                            </div>
                        </div>
                    </ng-container>
                    <nz-form-item>
                        <nz-form-label [nzNoColon]="true">Title</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <input [(ngModel)]="this.productObject.title" nz-input />
                        </nz-form-control>
                    </nz-form-item>


                    <nz-form-item>
                        <nz-form-label [nzNoColon]="true">Description</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <textarea [(ngModel)]="this.productObject.description" nz-input ></textarea>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item >
                        <nz-form-label [nzNoColon]="true">Image</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <div class="image-select-box" style="width: 300px;">
                                <img class="image-select-chosen-image" [src]="this.logoObject.value">
                            </div>
                            <button style="margin-top: 8px" nz-button
                                (click)="this.sharedService.openPicker(this.logoObject, 'MEDIA', 'SINGLE')">Change</button>

                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label
                            [nzNoColon]="true">Link</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <input nz-input
                                [(ngModel)]="this.productObject.data.onlineStoreUrl" />
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzNoColon]="true">Price</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <input [(ngModel)]="this.productObject.price" nz-input />
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item style="margin-bottom: 0px;">
                        <nz-form-label [nzNoColon]="true">Compare at price</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <input [(ngModel)]="this.productObject.compareAtPrice" nz-input />
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>



        </ng-container>
    </div>
</div>