<div class="page-wrapper">
    <ng-container *ngIf="!this.loading">
        <div class="header">
            <div style="width: 300px; text-align: left;">
                <div class="custom-card" style="width: fit-content">
                    <div class="custom-card-body mini">
                        <button nz-button (click)="this.testFillOutTemplate()"><span nz-icon nzType="arrow-left"
                                nzTheme="outline"></span></button>
                        <span style="margin-left: 4px;
                border-radius: 7px;
                background: #f2f2f2;
                padding: 9px 12px;
                font-weight: 500;">My template name</span>
                    </div>
                </div>
            </div>
            <!-- <div class="toolbar">
            <ng-container *ngIf="this.sharedService.activeComponent">
                <ng-container *ngIf="this.sharedService.activeComponent.type == 'DATACONTAINER'">
                    <ng-container *ngIf="this.sharedService.activeComponent.source == 'PRODUCTS'">
                        <button nz-button style="margin-right: 8px;"
                            (click)="this.sharedService.openPicker(this.sharedService.activeComponent, 'PRODUCTS', 'MULTI')">Change
                            products</button>
                    </ng-container>
                </ng-container>
                <ng-container *ngFor="let property of this.sharedService.activeComponent.properties">
                    <ng-container *ngIf="property.toolbar">
                        <app-property-renderer [data]="property" [mode]="'toolbar'"
                            style="margin-right: 8px;"></app-property-renderer>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div> -->
            <div style="width: 300px; text-align: right;">
                <div class="custom-card" style="width: fit-content; margin-left: 204px;">
                    <div class="custom-card-body mini">
                        <button nz-button nzType="primary" (click)="this.saveTemplate()"
                            [nzLoading]="this.saving">Save</button>
                        <!-- <span style="margin-left: 4px;
                border-radius: 7px;
                background: #f2f2f2;
                padding: 9px 12px;
                font-weight: 500;">My template name</span> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="left-column">

            <div class="custom-card">
                <div class="custom-card-body mini" style="height: 100%; overflow-y: auto;">
                    <button nz-button (click)="this.openBlockLibraryV2()"><span nz-icon nzType="plus"
                            nzTheme="outline"></span>Add block</button>
                    <nz-divider></nz-divider>
                    <!-- <nz-tree #nzTreeComponent [nzData]="template.children" (nzClick)="clickTree($event)" nzDraggable
                    nzBlockNode></nz-tree> -->
                    <nz-tabset style="height:100%;">
                        <nz-tab nzTitle="Tree">
                            <nz-tree #nzTreeComponent [nzData]="this.template.children" (nzClick)="clickTree($event)"
                                nzDraggable nzBlockNode [nzBeforeDrop]="nzEvent.bind(this)"
                                [nzSelectedKeys]="defaultSelectedKeys" *ngIf="this.showTree" nzShowIcon></nz-tree>
                        </nz-tab>
                        <!-- <nz-tab nzTitle="Editor">
                    <ng-container *ngIf="this.sharedService.activeComponent">
                        <ng-container *ngFor="let property of this.sharedService.activeComponent.properties">
                            <app-property-renderer [data]="property" [mode]="'sidebar'"></app-property-renderer>
                        </ng-container>
                    </ng-container>
                </nz-tab> -->
                        <nz-tab nzTitle="JSON" style="height:100%;">
                            <textarea [(ngModel)]="this.jsonString" class="code-block" nz-input
                                style="height: calc(100% - 40px);"></textarea>
                            <button nz-button nzType="primary" (click)="updateNestedData()"
                                style="margin-top: 8px; float: right;">Update</button>
                        </nz-tab>
                    </nz-tabset>
                </div>

            </div>
            <!-- <nz-tabset style="height:100%;">
            <nz-tab nzTitle="Tree">
                <nz-tree #nzTreeComponent [nzData]="block.components" (nzClick)="clickTree($event)"></nz-tree>
            </nz-tab>
            <nz-tab nzTitle="Editor">
                <ng-container *ngIf="this.sharedService.activeComponent">
                    <ng-container *ngFor="let property of this.sharedService.activeComponent.properties">
                        <app-property-renderer [data]="property" [mode]="'sidebar'"></app-property-renderer>
                    </ng-container>
                </ng-container>
            </nz-tab>
            <nz-tab nzTitle="JSON" style="height:100%;">
                <textarea [(ngModel)]="this.jsonString" class="code-block" nz-input
                    style="height: calc(100% - 40px);"></textarea>
                <button nz-button nzType="primary" (click)="updateNestedData()"
                    style="margin-top: 8px; float: right;">Update</button>
            </nz-tab>
        </nz-tabset> -->
        </div>
        <div class="right-column">

            <div class="content-outer" [style.backgroundColor]="this.opportunityObject.contentTemplate.backgroundColor">
                <!-- <ng-container *ngFor="let item of this.testTree">
                <div style="border: 1px solid #000; padding: 24px;">{{item.title}}
                    <ng-container *ngFor="let child of item.children">
                        <div style="border: 1px solid #000; padding: 24px;">{{child.title}}</div>
                    </ng-container>
                </div>
            </ng-container> -->
                <div class="content-inner">
                    <ng-container *ngFor="let component of template.children">
                        <app-component-renderer [data]="component"
                            (click)="this.sharedService.setActiveComponent($event, component)"></app-component-renderer>

                        <!-- <ng-container *ngIf="component.type == 'TEXT'">
                   <div [style.paddingTop]="component.properties.paddingTop" [style.paddingBottom]="component.properties.paddingBottom" [ngStyle]="component.properties.styles">{{component.value}}</div>
                </ng-container>
                <ng-container *ngIf="component.type == 'BUTTON'">
                    <div [style.paddingTop]="component.properties.paddingTop" [style.paddingBottom]="component.properties.paddingBottom" [ngStyle]="component.properties.styles">{{component.value}}</div>
                 </ng-container>
                 <ng-container *ngIf="component.type == 'IMAGE'">
                    <div [style.paddingTop]="component.properties.paddingTop" [style.paddingBottom]="component.properties.paddingBottom" [ngStyle]="component.properties.styles">{{component.value}}</div>
                 </ng-container> -->
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="right-column-2">
            <div class="custom-card" style="max-height: 100%; overflow-y: auto">
                <ng-container *ngIf="this.sharedService.activeComponent">
                    <div class="custom-card-header mini" style="padding: 8px;">
                        <button nz-button (click)="this.sharedService.activeComponent=null" style="width: 46px;"><span
                                nz-icon nzType="arrow-left" nzTheme="outline"></span></button>
                    </div>
                </ng-container>
                <ng-container *ngIf="!this.sharedService.activeComponent">
                    <div class="custom-card-header mini" style="padding: 8px 16px;">
                        Settings
                    </div>
                </ng-container>
                <div class="custom-card-body mini">
                    <ng-container *ngIf="!this.sharedService.activeComponent">
                        <nz-form-control [nzSpan]="24">
                            <ngx-colors ngx-colors-trigger
                                [(ngModel)]="this.opportunityObject.contentTemplate.backgroundColor"
                                style="height: 30px; float: left;"></ngx-colors>
                            <div class="color-picker-label">Email background
                            </div>
                        </nz-form-control>
                    </ng-container>
                    <ng-container *ngIf="this.sharedService.activeComponent">
                        <!-- <ng-container *ngIf="this.sharedService.activeComponent.type == 'TEXT'">
                        <ng-container *ngFor="let property of this.TEXTPROPS">
                            <app-property-renderer [data]="property" [mode]="'sidebar'"></app-property-renderer>
                        </ng-container>
                        
                    </ng-container> -->
                        <ng-container *ngIf="this.sharedService.activeComponent.source == 'PRODUCTS'">
                            <button nz-button style="margin-right: 8px;"
                                (click)="this.sharedService.openPicker(this.sharedService.activeComponent, 'PRODUCTS', 'MULTI')">Change
                                products</button>
                        </ng-container>
                        <!-- <ng-container *ngFor="let property of this.sharedService.activeComponent.properties">
                        <app-property-renderer [data]="property" [mode]="'sidebar'"></app-property-renderer>
                    </ng-container> -->

                        <!-- TEXT block sidebar options -->
                        <ng-container *ngIf="this.sharedService.activeComponent.type == 'TEXT'">
                            <nz-form-control [nzSpan]="24" style="margin-bottom: 8px;">
                                <ngx-colors ngx-colors-trigger
                                    [(ngModel)]="this.sharedService.activeComponent.props.color"
                                    style="height: 30px; float: left;"
                                    (ngModelChange)="setQuillColor(this.sharedService.activeComponent)"></ngx-colors>
                                <div class="color-picker-label">Text color
                                </div>
                            </nz-form-control>
                            <nz-form-control [nzSpan]="24" style="margin-bottom: 8px;">
                                <div>
                                    <nz-button-group style="margin-right: 8px;">
                                        <button nz-button (click)="setQuillBold()" style="width: 46px;"><span nz-icon
                                                nzType="bold" nzTheme="outline"></span></button>
                                        <button nz-button (click)="setQuillItalic()" style="width: 46px;"><span nz-icon
                                                nzType="italic" nzTheme="outline"></span></button>
                                        <button nz-button (click)="setQuillUnderline()" style="width: 46px;"><span
                                                nz-icon nzType="underline" nzTheme="outline"></span></button>
                                        <button nz-button (click)="setQuillStrikethrough()" style="width: 46px;"><span
                                                nz-icon nzType="strikethrough" nzTheme="outline"></span></button>
                                    </nz-button-group>
                                </div>
                            </nz-form-control>
                            <nz-form-control [nzSpan]="24" style="margin-bottom: 8px;">
                                <nz-radio-group [(ngModel)]="this.sharedService.activeComponent.props.textAlign">
                                    <label nz-radio-button nzValue="left" (click)="setQuillAlignment('left')"><span
                                            nz-icon nzType="align-left" nzTheme="outline"></span></label>
                                    <label nz-radio-button nzValue="center" (click)="setQuillAlignment('center')"><span
                                            nz-icon nzType="align-center" nzTheme="outline"></span></label>
                                    <label nz-radio-button nzValue="right" (click)="setQuillAlignment('right')"><span
                                            nz-icon nzType="align-right" nzTheme="outline"></span></label>
                                </nz-radio-group>
                            </nz-form-control>
                            <nz-form-control>
                                <nz-button-group>
                                    <button nz-button (click)="setQuillBullets()" style="width: 46px;"><span nz-icon
                                            nzType="unordered-list" nzTheme="outline"></span></button>
                                    <button nz-button (click)="setQuillOrderedList()" style="width: 46px;"><span nz-icon
                                            nzType="ordered-list" nzTheme="outline"></span></button>
                                </nz-button-group>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>

                            <nz-form-control [nzSpan]="24">
                                <div nz-row style="margin-bottom: 6px;">
                                    <nz-select nzShowSearch
                                        [(ngModel)]="this.sharedService.activeComponent.props.fontFamily"
                                        [compareWith]="compareFn"
                                        [style.fontFamily]="this.sharedService.activeComponent.props.fontFamily">
                                        <ng-container *ngFor="let font of sharedService.fonts">
                                            <nz-option nzCustomContent [nzValue]="font.value"
                                                [nzLabel]="font.value"><span
                                                    [style.fontFamily]="font.value">{{font.value}}</span></nz-option>
                                        </ng-container>
                                    </nz-select>
                                </div>
                                <div nz-row style="margin-bottom: 4px;">
                                    <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                        Font size
                                    </div>
                                    <div nz-col nzSpan="14">
                                        <nz-button-group>
                                            <button nz-button>-</button>
                                            <input nz-input
                                                [(ngModel)]="this.sharedService.activeComponent.props.fontSize"
                                                style="border-radius: 0px; border-left: none; border-right: none;" />
                                            <button nz-button>+</button>
                                        </nz-button-group>
                                    </div>
                                </div>
                                <div nz-row style="margin-bottom: 4px;">
                                    <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                        Font weight
                                    </div>
                                    <div nz-col nzSpan="14">
                                        <nz-select [(ngModel)]="this.sharedService.activeComponent.props.fontWeight"
                                            [compareWith]="compareFn">
                                            <nz-option nzValue="100" nzLabel="Thin (100)"></nz-option>
                                            <nz-option nzValue="200" nzLabel="Extra light (200)"></nz-option>
                                            <nz-option nzValue="300" nzLabel="Light (300)"></nz-option>
                                            <nz-option nzValue="400" nzLabel="Normal (400)"></nz-option>
                                            <nz-option nzValue="500" nzLabel="Medium (500)"></nz-option>
                                            <nz-option nzValue="600" nzLabel="Semi bold (600)"></nz-option>
                                            <nz-option nzValue="700" nzLabel="Bold (700)"></nz-option>
                                            <nz-option nzValue="800" nzLabel="Extra bold (800)"></nz-option>
                                            <nz-option nzValue="900" nzLabel="Black (900)"></nz-option>
                                        </nz-select>
                                    </div>
                                </div>
                                <div nz-row style="margin-bottom: 4px;">
                                    <div nz-col nzSpan="16" style="color: #919191; line-height: 36px;">
                                        Line height
                                    </div>
                                    <div nz-col nzSpan="8">
                                        <input nz-input
                                            [(ngModel)]="this.sharedService.activeComponent.props.lineHeight" />
                                    </div>
                                </div>
                                <div nz-row>
                                    <div nz-col nzSpan="16" style="color: #919191; line-height: 36px;">
                                        Letter spacing
                                    </div>
                                    <div nz-col nzSpan="8">
                                        <input nz-input
                                            [(ngModel)]="this.sharedService.activeComponent.props.letterSpacing" />
                                    </div>
                                </div>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <nz-form-control [nzSpan]="24">
                                <div style="color: #919191; margin-bottom: 4px;">
                                    Padding
                                </div>
                                <nz-input-group>
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingTop"
                                        style="width: 25%" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingRight"
                                        style="width: 25%; border-left: none;" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingBottom"
                                        style="width: 25%; border-left: none;" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingLeft"
                                        style="width: 25%; border-left: none;" />
                                </nz-input-group>
                                <div nz-row>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">T
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">R
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">B
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">L
                                    </div>
                                </div>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <div class="item-title" style="width: 100%;">
                                Dynamic content
                                <nz-switch nzSize="small"
                                    [(ngModel)]="this.sharedService.activeComponent.props.dynamicContent"
                                    style="float: right"></nz-switch>
                            </div>
                            <ng-container *ngIf="this.sharedService.activeComponent.props.dynamicContent">
                                <div class="item-title" style="width: 100%;">
                                    Text
                                </div>
                                <input nz-input
                                    [(ngModel)]="this.sharedService.activeComponent.props.dynamicContentValue" />
                            </ng-container>
                        </ng-container>

                        <!-- CONTAINER block sidebar options -->
                        <ng-container *ngIf="this.sharedService.activeComponent.type == 'CONTAINER'">
                            <div class="item-title">Background</div>
                            <nz-form-control [nzSpan]="24">

                                <nz-segmented [nzOptions]="['Color', 'Image']" [(ngModel)]="selectedIndex"
                                    (ngModelChange)="handleModelChange($event, this.sharedService.activeComponent)"
                                    style="margin-bottom: 8px;" [nzBlock]="true"></nz-segmented>

                                <ng-container
                                    *ngIf="this.sharedService.activeComponent.props.backgroundMode == 'solid'">
                                    <div>
                                        <ngx-colors ngx-colors-trigger
                                            [(ngModel)]="this.sharedService.activeComponent.props.backgroundColor"
                                            style="height: 30px; float: left;"></ngx-colors>
                                        <div class="color-picker-label">Background color
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container
                                    *ngIf="this.sharedService.activeComponent.props.backgroundMode == 'image'">
                                    <div class="image-select-box">
                                        <img class="image-select-chosen-image"
                                            src="{{this.sharedService.activeComponent.imageUrl}}">
                                    </div>
                                    <button nz-button
                                        (click)="openPickerSingle(this.sharedService.activeComponent, 'MEDIA')" nzBlock
                                        style="margin-bottom: 8px;"><span nz-icon nzType="file-image"
                                            nzTheme="outline"></span><span>Change</span></button>
                                    <div nz-row style="margin-bottom: 4px;">
                                        <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                            Position
                                        </div>
                                        <div nz-col nzSpan="14">
                                            <nz-select
                                                [(ngModel)]="this.sharedService.activeComponent.props.backgroundPosition"
                                                [compareWith]="compareFn">
                                                <nz-option nzValue="center" nzLabel="Center"></nz-option>
                                                <nz-option nzValue="top" nzLabel="Top"></nz-option>
                                                <nz-option nzValue="bottom" nzLabel="Bottom"></nz-option>
                                                <nz-option nzValue="left" nzLabel="Left"></nz-option>
                                                <nz-option nzValue="right" nzLabel="Right"></nz-option>
                                            </nz-select>
                                        </div>
                                    </div>
                                    <div nz-row style="margin-bottom: 4px;">
                                        <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                            Size
                                        </div>
                                        <div nz-col nzSpan="14">
                                            <nz-select
                                                [(ngModel)]="this.sharedService.activeComponent.props.backgroundSize"
                                                [compareWith]="compareFn">
                                                <nz-option nzValue="cover" nzLabel="Cover"></nz-option>
                                                <nz-option nzValue="contain" nzLabel="Contain"></nz-option>
                                            </nz-select>
                                        </div>
                                    </div>
                                </ng-container>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <nz-form-control [nzSpan]="24">
                                <div nz-row>
                                    <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                        Width
                                    </div>
                                    <div nz-col nzSpan="14" style="padding-top: 1px;">
                                        <nz-slider [nzMin]="0" [nzMax]="100"
                                            [(ngModel)]="this.sharedService.activeComponent.props.width"></nz-slider>
                                    </div>
                                </div>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <nz-form-control [nzSpan]="24">
                                <div nz-row>
                                    <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                        Border radius
                                    </div>
                                    <div nz-col nzSpan="14" style="padding-top: 1px;">
                                        <nz-slider [nzMin]="0" [nzMax]="100"
                                            [(ngModel)]="this.sharedService.activeComponent.props.borderRadius"></nz-slider>
                                    </div>
                                </div>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <nz-form-control [nzSpan]="24">
                                <div class="item-title">Padding</div>
                                <nz-input-group>
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingTop"
                                        style="width: 25%" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingRight"
                                        style="width: 25%; border-left: none;" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingBottom"
                                        style="width: 25%; border-left: none;" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingLeft"
                                        style="width: 25%; border-left: none;" />
                                </nz-input-group>
                                <div nz-row>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">T
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">R
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">B
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">L
                                    </div>
                                </div>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <div class="item-title" style="width: 100%;">
                                Border
                                <nz-switch nzSize="small" [(ngModel)]="this.sharedService.activeComponent.props.border"
                                    style="float: right"></nz-switch>
                            </div>
                            <ng-container *ngIf="this.sharedService.activeComponent.props.border">
                                <nz-form-control [nzSpan]="24" style="padding-top: 8px;">
                                    <div>
                                        <ngx-colors ngx-colors-trigger
                                            [(ngModel)]="this.sharedService.activeComponent.props.borderColor"
                                            style="height: 30px; float: left"></ngx-colors>
                                        <div class="color-picker-label">Border color
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <!-- <div nz-row>
                                        <div nz-col nzSpan="8" style="color: #919191; line-height: 36px;">
                                            Color
                                        </div>
                                        <div nz-col nzSpan="16">
                                            <ngx-colors ngx-colors-trigger
                                                [(ngModel)]="this.sharedService.activeComponent.props.borderColor"
                                                style="height: 30px;"></ngx-colors>
                                        </div>
                                    </div> -->
                                    <div nz-row>
                                        <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                            Border width
                                        </div>
                                        <div nz-col nzSpan="14" style="padding-top: 1px;">
                                            <nz-slider [nzMin]="0" [nzMax]="20"
                                                [(ngModel)]="this.sharedService.activeComponent.props.borderWidth"></nz-slider>
                                        </div>
                                    </div>
                                </nz-form-control>
                            </ng-container>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <div class="item-title" style="width: 100%;">
                                Shadow
                                <nz-switch nzSize="small" [(ngModel)]="this.sharedService.activeComponent.props.shadow"
                                    style="float: right"></nz-switch>
                            </div>
                            <ng-container *ngIf="this.sharedService.activeComponent.props.shadow">
                                <nz-form-control [nzSpan]="24" style="padding-top: 8px;">
                                    <div>
                                        <ngx-colors ngx-colors-trigger
                                            [(ngModel)]="this.sharedService.activeComponent.props.shadowColor"
                                            style="height: 30px; float: left"></ngx-colors>
                                        <div class="color-picker-label">Shadow color
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <!-- <div nz-row>
                                        <div nz-col nzSpan="8" style="color: #919191; line-height: 36px;">
                                            Color
                                        </div>
                                        <div nz-col nzSpan="16">
                                            <ngx-colors ngx-colors-trigger
                                                [(ngModel)]="this.sharedService.activeComponent.props.borderColor"
                                                style="height: 30px;"></ngx-colors>
                                        </div>
                                    </div> -->
                                    <div nz-row>
                                        <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                            Shadow depth
                                        </div>
                                        <div nz-col nzSpan="14" style="padding-top: 1px;">
                                            <nz-slider [nzMin]="0" [nzMax]="20"
                                                [(ngModel)]="this.sharedService.activeComponent.props.shadowDepth"></nz-slider>
                                        </div>
                                    </div>
                                    <div nz-row>
                                        <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                            Shadow blur
                                        </div>
                                        <div nz-col nzSpan="14" style="padding-top: 1px;">
                                            <nz-slider [nzMin]="0" [nzMax]="20"
                                                [(ngModel)]="this.sharedService.activeComponent.props.shadowBlur"></nz-slider>
                                        </div>
                                    </div>
                                </nz-form-control>
                            </ng-container>

                            <!-- <nz-divider style="margin: 8px 0px;"></nz-divider>
                            <nz-collapse [nzBordered]="false">
                                <nz-collapse-panel [nzHeader]="'Advanced'">
                                    <div style="color: #919191;">
                                        Custom CSS
                                    </div>
                                    <nz-form-control [nzSpan]="24">
                                        <textarea nz-input
                                            [(ngModel)]="this.sharedService.activeComponent.props.customCSS"
                                            rows="2"></textarea>
                                    </nz-form-control>
                                </nz-collapse-panel>
                            </nz-collapse> -->
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <div class="item-title" style="width: 100%;">
                                Dynamic content
                                <nz-switch nzSize="small"
                                    [(ngModel)]="this.sharedService.activeComponent.props.dynamicContent"
                                    style="float: right"></nz-switch>
                            </div>
                            <ng-container *ngIf="this.sharedService.activeComponent.props.dynamicContent">
                                <div class="item-title" style="width: 100%;">
                                    Background image
                                </div>
                                <input nz-input
                                    [(ngModel)]="this.sharedService.activeComponent.props.dynamicContentValue" />
                            </ng-container>
                        </ng-container>

                        <!-- CONTAINER block sidebar options -->
                        <ng-container *ngIf="this.sharedService.activeComponent.type == 'PRODUCT'">

                            <div class="item-title">Product</div>
                            <nz-form-control [nzSpan]="24">
                                <div class="image-select-box">
                                    <img class="image-select-chosen-image"
                                        src="{{this.sharedService.activeComponent.value?.imageUrl}}">
                                </div>
                                <button nz-button style="margin-right: 8px;"
                                    (click)="this.sharedService.openPicker(this.sharedService.activeComponent, 'PRODUCTS', 'DYNAMICCONTENT')"
                                    nzBlock>Change</button>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <nz-form-control [nzSpan]="24">
                                <div nz-row>
                                    <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                        Width
                                    </div>
                                    <div nz-col nzSpan="14" style="padding-top: 1px;">
                                        <nz-slider [nzMin]="0" [nzMax]="100"
                                            [(ngModel)]="this.sharedService.activeComponent.props.width"></nz-slider>
                                    </div>
                                </div>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <nz-form-control [nzSpan]="24">
                                <div class="item-title">Padding</div>
                                <nz-input-group>
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingTop"
                                        style="width: 25%" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingRight"
                                        style="width: 25%; border-left: none;" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingBottom"
                                        style="width: 25%; border-left: none;" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingLeft"
                                        style="width: 25%; border-left: none;" />
                                </nz-input-group>
                                <div nz-row>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">T
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">R
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">B
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">L
                                    </div>
                                </div>
                            </nz-form-control>


                            <!-- <nz-divider style="margin: 8px 0px;"></nz-divider>
                            <nz-collapse [nzBordered]="false">
                                <nz-collapse-panel [nzHeader]="'Advanced'">
                                    <div style="color: #919191;">
                                        Custom CSS
                                    </div>
                                    <nz-form-control [nzSpan]="24">
                                        <textarea nz-input
                                            [(ngModel)]="this.sharedService.activeComponent.props.customCSS"
                                            rows="2"></textarea>
                                    </nz-form-control>
                                </nz-collapse-panel>
                            </nz-collapse> -->

                        </ng-container>

                        <ng-container *ngIf="this.sharedService.activeComponent.type == 'REVIEW'">

                            <div class="item-title">Review</div>
                            <nz-form-control [nzSpan]="24">
                                <!-- <div class="image-select-box">
                                    <img class="image-select-chosen-image"
                                        src="{{this.sharedService.activeComponent.value?.imageUrl}}">
                                </div> -->
                                <button nz-button style="margin-right: 8px;"
                                    (click)="this.sharedService.reviewPickerOpen = true" nzBlock>Change review</button>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <nz-form-control [nzSpan]="24">
                                <div nz-row>
                                    <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                        Width
                                    </div>
                                    <div nz-col nzSpan="14" style="padding-top: 1px;">
                                        <nz-slider [nzMin]="0" [nzMax]="100"
                                            [(ngModel)]="this.sharedService.activeComponent.props.width"></nz-slider>
                                    </div>
                                </div>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <nz-form-control [nzSpan]="24">
                                <div class="item-title">Padding</div>
                                <nz-input-group>
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingTop"
                                        style="width: 25%" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingRight"
                                        style="width: 25%; border-left: none;" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingBottom"
                                        style="width: 25%; border-left: none;" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingLeft"
                                        style="width: 25%; border-left: none;" />
                                </nz-input-group>
                                <div nz-row>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">T
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">R
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">B
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">L
                                    </div>
                                </div>
                            </nz-form-control>


                            <!-- <nz-divider style="margin: 8px 0px;"></nz-divider>
                            <nz-collapse [nzBordered]="false">
                                <nz-collapse-panel [nzHeader]="'Advanced'">
                                    <div style="color: #919191;">
                                        Custom CSS
                                    </div>
                                    <nz-form-control [nzSpan]="24">
                                        <textarea nz-input
                                            [(ngModel)]="this.sharedService.activeComponent.props.customCSS"
                                            rows="2"></textarea>
                                    </nz-form-control>
                                </nz-collapse-panel>
                            </nz-collapse> -->

                        </ng-container>

                        <!-- BLOCK block sidebar options -->
                        <ng-container *ngIf="this.sharedService.activeComponent.type == 'BLOCK'">
                            <div class="item-title">Background</div>
                            <nz-form-control [nzSpan]="24">

                                <nz-segmented [nzOptions]="['Color', 'Image']" [(ngModel)]="selectedIndex"
                                    (ngModelChange)="handleModelChange($event, this.sharedService.activeComponent)"
                                    style="margin-bottom: 8px;" [nzBlock]="true"></nz-segmented>

                                <ng-container
                                    *ngIf="this.sharedService.activeComponent.props.backgroundMode == 'solid'">
                                    <div>
                                        <ngx-colors ngx-colors-trigger
                                            [(ngModel)]="this.sharedService.activeComponent.props.backgroundColor"
                                            style="height: 30px; float: left;"></ngx-colors>
                                        <div class="color-picker-label">Background color
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container
                                    *ngIf="this.sharedService.activeComponent.props.backgroundMode == 'image'">
                                    <div class="image-select-box">
                                        <img class="image-select-chosen-image"
                                            src="{{this.sharedService.activeComponent.imageUrl}}">
                                    </div>
                                    <button nz-button
                                        (click)="openPickerSingle(this.sharedService.activeComponent, 'MEDIA')" nzBlock
                                        style="margin-bottom: 8px;"><span nz-icon nzType="file-image"
                                            nzTheme="outline"></span><span>Change</span></button>
                                    <div nz-row style="margin-bottom: 4px;">
                                        <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                            Position
                                        </div>
                                        <div nz-col nzSpan="14">
                                            <nz-select
                                                [(ngModel)]="this.sharedService.activeComponent.props.backgroundPosition"
                                                [compareWith]="compareFn">
                                                <nz-option nzValue="center" nzLabel="Center"></nz-option>
                                                <nz-option nzValue="top" nzLabel="Top"></nz-option>
                                                <nz-option nzValue="bottom" nzLabel="Bottom"></nz-option>
                                                <nz-option nzValue="left" nzLabel="Left"></nz-option>
                                                <nz-option nzValue="right" nzLabel="Right"></nz-option>
                                            </nz-select>
                                        </div>
                                    </div>
                                    <div nz-row style="margin-bottom: 4px;">
                                        <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                            Size
                                        </div>
                                        <div nz-col nzSpan="14">
                                            <nz-select
                                                [(ngModel)]="this.sharedService.activeComponent.props.backgroundSize"
                                                [compareWith]="compareFn">
                                                <nz-option nzValue="cover" nzLabel="Cover"></nz-option>
                                                <nz-option nzValue="contain" nzLabel="Contain"></nz-option>
                                            </nz-select>
                                        </div>
                                    </div>
                                </ng-container>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <div class="item-title" style="width: 100%;">
                                Border
                                <nz-switch nzSize="small" [(ngModel)]="this.sharedService.activeComponent.props.border"
                                    style="float: right"></nz-switch>
                            </div>
                            <ng-container *ngIf="this.sharedService.activeComponent.props.border">
                                <nz-form-control [nzSpan]="24" style="padding-top: 8px;">
                                    <div>
                                        <ngx-colors ngx-colors-trigger
                                            [(ngModel)]="this.sharedService.activeComponent.props.borderColor"
                                            style="height: 30px; float: left"></ngx-colors>
                                        <div class="color-picker-label">Border color
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <!-- <div nz-row>
                                        <div nz-col nzSpan="8" style="color: #919191; line-height: 36px;">
                                            Color
                                        </div>
                                        <div nz-col nzSpan="16">
                                            <ngx-colors ngx-colors-trigger
                                                [(ngModel)]="this.sharedService.activeComponent.props.borderColor"
                                                style="height: 30px;"></ngx-colors>
                                        </div>
                                    </div> -->
                                    <div nz-row>
                                        <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                            Border width
                                        </div>
                                        <div nz-col nzSpan="14" style="padding-top: 1px;">
                                            <nz-slider [nzMin]="0" [nzMax]="20"
                                                [(ngModel)]="this.sharedService.activeComponent.props.borderWidth"></nz-slider>
                                        </div>
                                    </div>
                                </nz-form-control>
                            </ng-container>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <div class="item-title" style="width: 100%;">
                                Shadow
                                <nz-switch nzSize="small" [(ngModel)]="this.sharedService.activeComponent.props.shadow"
                                    style="float: right"></nz-switch>
                            </div>
                            <ng-container *ngIf="this.sharedService.activeComponent.props.shadow">
                                <nz-form-control [nzSpan]="24" style="padding-top: 8px;">
                                    <div>
                                        <ngx-colors ngx-colors-trigger
                                            [(ngModel)]="this.sharedService.activeComponent.props.shadowColor"
                                            style="height: 30px; float: left"></ngx-colors>
                                        <div class="color-picker-label">Shadow color
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <!-- <div nz-row>
                                        <div nz-col nzSpan="8" style="color: #919191; line-height: 36px;">
                                            Color
                                        </div>
                                        <div nz-col nzSpan="16">
                                            <ngx-colors ngx-colors-trigger
                                                [(ngModel)]="this.sharedService.activeComponent.props.borderColor"
                                                style="height: 30px;"></ngx-colors>
                                        </div>
                                    </div> -->
                                    <div nz-row>
                                        <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                            Shadow depth
                                        </div>
                                        <div nz-col nzSpan="14" style="padding-top: 1px;">
                                            <nz-slider [nzMin]="0" [nzMax]="20"
                                                [(ngModel)]="this.sharedService.activeComponent.props.shadowDepth"></nz-slider>
                                        </div>
                                    </div>
                                    <div nz-row>
                                        <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                            Shadow blur
                                        </div>
                                        <div nz-col nzSpan="14" style="padding-top: 1px;">
                                            <nz-slider [nzMin]="0" [nzMax]="20"
                                                [(ngModel)]="this.sharedService.activeComponent.props.shadowBlur"></nz-slider>
                                        </div>
                                    </div>
                                </nz-form-control>
                            </ng-container>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <nz-form-control [nzSpan]="24">
                                <div class="item-title">Padding</div>
                                <nz-input-group>
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingTop"
                                        style="width: 25%" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingRight"
                                        style="width: 25%; border-left: none;" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingBottom"
                                        style="width: 25%; border-left: none;" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingLeft"
                                        style="width: 25%; border-left: none;" />
                                </nz-input-group>
                                <div nz-row>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">T
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">R
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">B
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">L
                                    </div>
                                </div>
                            </nz-form-control>
                            <!-- <nz-divider style="margin: 8px 0px;"></nz-divider>
                            <nz-collapse [nzBordered]="false">
                                <nz-collapse-panel [nzHeader]="'Advanced'">
                                    <div style="color: #919191;">
                                        Custom CSS
                                    </div>
                                    <nz-form-control [nzSpan]="24">
                                        <textarea nz-input
                                            [(ngModel)]="this.sharedService.activeComponent.props.customCSS"
                                            rows="2"></textarea>
                                    </nz-form-control>
                                </nz-collapse-panel>
                            </nz-collapse> -->
                        </ng-container>

                        <!-- IMAGE block sidebar options -->
                        <ng-container *ngIf="this.sharedService.activeComponent.type == 'IMAGE'">
                            <div class="item-title" style="margin-top: 8px;">Content</div>
                            <nz-form-control [nzSpan]="24" style="margin-bottom: 8px;">
                                <nz-select [(ngModel)]="this.sharedService.activeComponent.source"
                                    [compareWith]="compareFn">
                                    <nz-option nzValue="AUTO" nzLabel="Auto"></nz-option>
                                    <nz-option nzValue="LOGO" nzLabel="Logo"></nz-option>
                                </nz-select>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <div class="item-title">Position</div>
                            <nz-form-control [nzSpan]="24" style="margin-bottom: 8px;">
                                <nz-radio-group [(ngModel)]="this.sharedService.activeComponent.props.justifyContent">
                                    <label nz-radio-button nzValue="left"
                                        (click)="setQuillAlignment('flex-start')"><span nz-icon nzType="align-left"
                                            nzTheme="outline"></span></label>
                                    <label nz-radio-button nzValue="center" (click)="setQuillAlignment('center')"><span
                                            nz-icon nzType="align-center" nzTheme="outline"></span></label>
                                    <label nz-radio-button nzValue="right" (click)="setQuillAlignment('flex-end')"><span
                                            nz-icon nzType="align-right" nzTheme="outline"></span></label>
                                </nz-radio-group>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <nz-form-control [nzSpan]="24">
                                <div class="image-select-box">
                                    <img class="image-select-chosen-image"
                                        src="{{this.sharedService.activeComponent.value}}">
                                </div>
                                <button nz-button
                                    (click)="openPickerSingle(this.sharedService.activeComponent, 'MEDIA')"><span
                                        nz-icon nzType="file-image"
                                        nzTheme="outline"></span><span>Change</span></button>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <nz-form-control [nzSpan]="24">
                                <div nz-row>
                                    <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                        Width
                                    </div>
                                    <div nz-col nzSpan="14" style="padding-top: 1px;">
                                        <nz-slider [nzMin]="0" [nzMax]="100"
                                            [(ngModel)]="this.sharedService.activeComponent.props.width"></nz-slider>
                                    </div>
                                </div>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <nz-form-control [nzSpan]="24">
                                <div style="color: #919191; margin-bottom: 4px;">
                                    Padding
                                </div>
                                <nz-input-group>
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingTop"
                                        style="width: 25%" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingRight"
                                        style="width: 25%; border-left: none;" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingBottom"
                                        style="width: 25%; border-left: none;" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingLeft"
                                        style="width: 25%; border-left: none;" />
                                </nz-input-group>
                                <div nz-row>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">T
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">R
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">B
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">L
                                    </div>
                                </div>
                            </nz-form-control>
                        </ng-container>

                        <!-- BUTTON block sidebar options -->
                        <ng-container *ngIf="this.sharedService.activeComponent.type == 'BUTTON'">
                            <div class="item-title">Position</div>
                            <nz-form-control [nzSpan]="24" style="margin-bottom: 8px;">
                                <nz-radio-group [(ngModel)]="this.sharedService.activeComponent.props.justifyContent">
                                    <label nz-radio-button nzValue="left"
                                        (click)="setQuillAlignment('flex-start')"><span nz-icon nzType="align-left"
                                            nzTheme="outline"></span></label>
                                    <label nz-radio-button nzValue="center" (click)="setQuillAlignment('center')"><span
                                            nz-icon nzType="align-center" nzTheme="outline"></span></label>
                                    <label nz-radio-button nzValue="right" (click)="setQuillAlignment('flex-end')"><span
                                            nz-icon nzType="align-right" nzTheme="outline"></span></label>
                                </nz-radio-group>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <nz-form-control [nzSpan]="24" style="margin-bottom: 8px;">
                                <ngx-colors ngx-colors-trigger
                                    [(ngModel)]="this.sharedService.activeComponent.props.color"
                                    style="height: 30px; float: left;"
                                    (ngModelChange)="setQuillColor(this.sharedService.activeComponent)"></ngx-colors>
                                <div class="color-picker-label">Text color
                                </div>
                            </nz-form-control>
                            <nz-form-control [nzSpan]="24" style="margin-bottom: 8px;">
                                <div>
                                    <nz-button-group style="margin-right: 8px;">
                                        <button nz-button (click)="setQuillBold()" style="width: 46px;"><span nz-icon
                                                nzType="bold" nzTheme="outline"></span></button>
                                        <button nz-button (click)="setQuillItalic()" style="width: 46px;"><span nz-icon
                                                nzType="italic" nzTheme="outline"></span></button>
                                        <button nz-button (click)="setQuillUnderline()" style="width: 46px;"><span
                                                nz-icon nzType="underline" nzTheme="outline"></span></button>
                                        <button nz-button (click)="setQuillStrikethrough()" style="width: 46px;"><span
                                                nz-icon nzType="strikethrough" nzTheme="outline"></span></button>
                                    </nz-button-group>
                                </div>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>

                            <nz-form-control [nzSpan]="24">
                                <div nz-row style="margin-bottom: 6px;">
                                    <nz-select nzShowSearch
                                        [(ngModel)]="this.sharedService.activeComponent.props.fontFamily"
                                        [compareWith]="compareFn"
                                        [style.fontFamily]="this.sharedService.activeComponent.props.fontFamily">
                                        <ng-container *ngFor="let font of sharedService.fonts">
                                            <nz-option nzCustomContent [nzValue]="font.value"
                                                [nzLabel]="font.value"><span
                                                    [style.fontFamily]="font.value">{{font.value}}</span></nz-option>
                                        </ng-container>
                                    </nz-select>
                                </div>
                                <div nz-row style="margin-bottom: 4px;">
                                    <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                        Font size
                                    </div>
                                    <div nz-col nzSpan="14">
                                        <nz-button-group>
                                            <button nz-button>-</button>
                                            <input nz-input
                                                [(ngModel)]="this.sharedService.activeComponent.props.fontSize"
                                                style="border-radius: 0px; border-left: none; border-right: none;" />
                                            <button nz-button>+</button>
                                        </nz-button-group>
                                    </div>
                                </div>
                                <div nz-row style="margin-bottom: 4px;">
                                    <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                        Font weight
                                    </div>
                                    <div nz-col nzSpan="14">
                                        <nz-select [(ngModel)]="this.sharedService.activeComponent.props.fontWeight"
                                            [compareWith]="compareFn">
                                            <nz-option nzValue="100" nzLabel="Thin (100)"></nz-option>
                                            <nz-option nzValue="200" nzLabel="Extra light (200)"></nz-option>
                                            <nz-option nzValue="300" nzLabel="Light (300)"></nz-option>
                                            <nz-option nzValue="400" nzLabel="Normal (400)"></nz-option>
                                            <nz-option nzValue="500" nzLabel="Medium (500)"></nz-option>
                                            <nz-option nzValue="600" nzLabel="Semi bold (600)"></nz-option>
                                            <nz-option nzValue="700" nzLabel="Bold (700)"></nz-option>
                                            <nz-option nzValue="800" nzLabel="Extra bold (800)"></nz-option>
                                            <nz-option nzValue="900" nzLabel="Black (900)"></nz-option>
                                        </nz-select>
                                    </div>
                                </div>
                                <div nz-row style="margin-bottom: 4px;">
                                    <div nz-col nzSpan="16" style="color: #919191; line-height: 36px;">
                                        Line height
                                    </div>
                                    <div nz-col nzSpan="8">
                                        <input nz-input
                                            [(ngModel)]="this.sharedService.activeComponent.props.lineHeight" />
                                    </div>
                                </div>
                                <div nz-row>
                                    <div nz-col nzSpan="16" style="color: #919191; line-height: 36px;">
                                        Letter spacing
                                    </div>
                                    <div nz-col nzSpan="8">
                                        <input nz-input
                                            [(ngModel)]="this.sharedService.activeComponent.props.letterSpacing" />
                                    </div>
                                </div>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <div class="item-title">Background</div>
                            <nz-form-control [nzSpan]="24">

                                <nz-segmented [nzOptions]="['Color', 'Image']" [(ngModel)]="selectedIndex"
                                    (ngModelChange)="handleModelChange($event, this.sharedService.activeComponent)"
                                    style="margin-bottom: 8px;" [nzBlock]="true"></nz-segmented>

                                <ng-container
                                    *ngIf="this.sharedService.activeComponent.props.backgroundMode == 'solid'">
                                    <div>
                                        <ngx-colors ngx-colors-trigger
                                            [(ngModel)]="this.sharedService.activeComponent.props.backgroundColor"
                                            style="height: 30px; float: left;"></ngx-colors>
                                        <div class="color-picker-label">Background color
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container
                                    *ngIf="this.sharedService.activeComponent.props.backgroundMode == 'image'">
                                    <div class="image-select-box">
                                        <img class="image-select-chosen-image"
                                            src="{{this.sharedService.activeComponent.imageUrl}}">
                                    </div>
                                    <button nz-button
                                        (click)="openPickerSingle(this.sharedService.activeComponent, 'MEDIA')" nzBlock
                                        style="margin-bottom: 8px;"><span nz-icon nzType="file-image"
                                            nzTheme="outline"></span><span>Change</span></button>
                                    <div nz-row style="margin-bottom: 4px;">
                                        <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                            Position
                                        </div>
                                        <div nz-col nzSpan="14">
                                            <nz-select
                                                [(ngModel)]="this.sharedService.activeComponent.props.backgroundPosition"
                                                [compareWith]="compareFn">
                                                <nz-option nzValue="center" nzLabel="Center"></nz-option>
                                                <nz-option nzValue="top" nzLabel="Top"></nz-option>
                                                <nz-option nzValue="bottom" nzLabel="Bottom"></nz-option>
                                                <nz-option nzValue="left" nzLabel="Left"></nz-option>
                                                <nz-option nzValue="right" nzLabel="Right"></nz-option>
                                            </nz-select>
                                        </div>
                                    </div>
                                    <div nz-row style="margin-bottom: 4px;">
                                        <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                            Size
                                        </div>
                                        <div nz-col nzSpan="14">
                                            <nz-select
                                                [(ngModel)]="this.sharedService.activeComponent.props.backgroundSize"
                                                [compareWith]="compareFn">
                                                <nz-option nzValue="cover" nzLabel="Cover"></nz-option>
                                                <nz-option nzValue="contain" nzLabel="Contain"></nz-option>
                                            </nz-select>
                                        </div>
                                    </div>
                                </ng-container>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <nz-form-control [nzSpan]="24">
                                <div nz-row>
                                    <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                        Border radius
                                    </div>
                                    <div nz-col nzSpan="14" style="padding-top: 1px;">
                                        <nz-slider [nzMin]="0" [nzMax]="100"
                                            [(ngModel)]="this.sharedService.activeComponent.props.borderRadius"></nz-slider>
                                    </div>
                                </div>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <nz-form-control [nzSpan]="24">
                                <div class="item-title">Padding</div>
                                <nz-input-group>
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingTop"
                                        style="width: 25%" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingRight"
                                        style="width: 25%; border-left: none;" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingBottom"
                                        style="width: 25%; border-left: none;" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingLeft"
                                        style="width: 25%; border-left: none;" />
                                </nz-input-group>
                                <div nz-row>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">T
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">R
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">B
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">L
                                    </div>
                                </div>
                            </nz-form-control>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <div class="item-title" style="width: 100%;">
                                Border
                                <nz-switch nzSize="small" [(ngModel)]="this.sharedService.activeComponent.props.border"
                                    style="float: right"></nz-switch>
                            </div>
                            <ng-container *ngIf="this.sharedService.activeComponent.props.border">
                                <nz-form-control [nzSpan]="24" style="padding-top: 8px;">
                                    <div>
                                        <ngx-colors ngx-colors-trigger
                                            [(ngModel)]="this.sharedService.activeComponent.props.borderColor"
                                            style="height: 30px; float: left"></ngx-colors>
                                        <div class="color-picker-label">Border color
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <!-- <div nz-row>
                                        <div nz-col nzSpan="8" style="color: #919191; line-height: 36px;">
                                            Color
                                        </div>
                                        <div nz-col nzSpan="16">
                                            <ngx-colors ngx-colors-trigger
                                                [(ngModel)]="this.sharedService.activeComponent.props.borderColor"
                                                style="height: 30px;"></ngx-colors>
                                        </div>
                                    </div> -->
                                    <div nz-row>
                                        <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                            Border width
                                        </div>
                                        <div nz-col nzSpan="14" style="padding-top: 1px;">
                                            <nz-slider [nzMin]="0" [nzMax]="20"
                                                [(ngModel)]="this.sharedService.activeComponent.props.borderWidth"></nz-slider>
                                        </div>
                                    </div>
                                </nz-form-control>
                            </ng-container>
                            <nz-divider style="margin: 16px 0px;"></nz-divider>
                            <div class="item-title" style="width: 100%;">
                                Shadow
                                <nz-switch nzSize="small" [(ngModel)]="this.sharedService.activeComponent.props.shadow"
                                    style="float: right"></nz-switch>
                            </div>
                            <ng-container *ngIf="this.sharedService.activeComponent.props.shadow">
                                <nz-form-control [nzSpan]="24" style="padding-top: 8px;">
                                    <div>
                                        <ngx-colors ngx-colors-trigger
                                            [(ngModel)]="this.sharedService.activeComponent.props.shadowColor"
                                            style="height: 30px; float: left"></ngx-colors>
                                        <div class="color-picker-label">Shadow color
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <!-- <div nz-row>
                                        <div nz-col nzSpan="8" style="color: #919191; line-height: 36px;">
                                            Color
                                        </div>
                                        <div nz-col nzSpan="16">
                                            <ngx-colors ngx-colors-trigger
                                                [(ngModel)]="this.sharedService.activeComponent.props.borderColor"
                                                style="height: 30px;"></ngx-colors>
                                        </div>
                                    </div> -->
                                    <div nz-row>
                                        <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                            Shadow depth
                                        </div>
                                        <div nz-col nzSpan="14" style="padding-top: 1px;">
                                            <nz-slider [nzMin]="0" [nzMax]="20"
                                                [(ngModel)]="this.sharedService.activeComponent.props.shadowDepth"></nz-slider>
                                        </div>
                                    </div>
                                    <div nz-row>
                                        <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                            Shadow blur
                                        </div>
                                        <div nz-col nzSpan="14" style="padding-top: 1px;">
                                            <nz-slider [nzMin]="0" [nzMax]="20"
                                                [(ngModel)]="this.sharedService.activeComponent.props.shadowBlur"></nz-slider>
                                        </div>
                                    </div>
                                </nz-form-control>
                            </ng-container>

                            <!-- <nz-divider style="margin: 8px 0px;"></nz-divider>
                            <nz-collapse [nzBordered]="false">
                                <nz-collapse-panel [nzHeader]="'Advanced'">
                                    <div style="color: #919191;">
                                        Custom CSS
                                    </div>
                                    <nz-form-control [nzSpan]="24">
                                        <textarea nz-input
                                            [(ngModel)]="this.sharedService.activeComponent.props.customCSS"
                                            rows="2"></textarea>
                                    </nz-form-control>
                                </nz-collapse-panel>
                            </nz-collapse> -->
                        </ng-container>

                        <!-- BUTTON OLD block sidebar options -->
                        <ng-container *ngIf="this.sharedService.activeComponent.type == 'BUTTONOLD'">
                            <nz-form-control [nzSpan]="24" style="margin-bottom: 8px;">
                                <ngx-colors ngx-colors-trigger
                                    [(ngModel)]="this.sharedService.activeComponent.props.color" style="height: 30px;"
                                    (ngModelChange)="setQuillColor(this.sharedService.activeComponent)"></ngx-colors>
                            </nz-form-control>
                            <nz-form-control [nzSpan]="24" style="margin-bottom: 8px;">
                                <div>
                                    <nz-button-group style="margin-right: 8px;">
                                        <button nz-button (click)="setQuillBold()"><span nz-icon nzType="bold"
                                                nzTheme="outline"></span></button>
                                        <button nz-button (click)="setQuillItalic()"><span nz-icon nzType="italic"
                                                nzTheme="outline"></span></button>
                                        <button nz-button (click)="setQuillUnderline()"><span nz-icon nzType="underline"
                                                nzTheme="outline"></span></button>
                                        <button nz-button (click)="setQuillStrikethrough()"><span nz-icon
                                                nzType="strikethrough" nzTheme="outline"></span></button>
                                    </nz-button-group>
                                </div>
                            </nz-form-control>
                            <nz-form-control [nzSpan]="24" style="margin-bottom: 8px;">

                                <nz-radio-group [(ngModel)]="this.sharedService.activeComponent.props.textAlign">
                                    <label nz-radio-button nzValue="left" (click)="setQuillAlignment('left')"><span
                                            nz-icon nzType="align-left" nzTheme="outline"></span></label>
                                    <label nz-radio-button nzValue="center" (click)="setQuillAlignment('center')"><span
                                            nz-icon nzType="align-center" nzTheme="outline"></span></label>
                                    <label nz-radio-button nzValue="right" (click)="setQuillAlignment('right')"><span
                                            nz-icon nzType="align-right" nzTheme="outline"></span></label>
                                </nz-radio-group>
                                <nz-button-group>
                                    <button nz-button (click)="setQuillBullets()"><span nz-icon nzType="unordered-list"
                                            nzTheme="outline"></span></button>
                                    <button nz-button (click)="setQuillOrderedList()"><span nz-icon
                                            nzType="ordered-list" nzTheme="outline"></span></button>
                                </nz-button-group>
                            </nz-form-control>
                            <nz-divider style="margin: 8px 0px;"></nz-divider>

                            <nz-form-control [nzSpan]="24">
                                <div nz-row style="margin-bottom: 6px;">
                                    <nz-select nzShowSearch
                                        [(ngModel)]="this.sharedService.activeComponent.props.fontFamily"
                                        [compareWith]="compareFn"
                                        [style.fontFamily]="this.sharedService.activeComponent.props.fontFamily">
                                        <ng-container *ngFor="let font of sharedService.fonts">
                                            <nz-option nzCustomContent [nzValue]="font.value"
                                                [nzLabel]="font.value"><span
                                                    [style.fontFamily]="font.value">{{font.value}}</span></nz-option>
                                        </ng-container>
                                    </nz-select>
                                </div>
                                <div nz-row style="margin-bottom: 4px;">
                                    <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                        Font size
                                    </div>
                                    <div nz-col nzSpan="14">
                                        <nz-button-group>
                                            <button nz-button>-</button>
                                            <input nz-input
                                                [(ngModel)]="this.sharedService.activeComponent.props.fontSize"
                                                style="border-radius: 0px; border-left: none; border-right: none;" />
                                            <button nz-button>+</button>
                                        </nz-button-group>
                                    </div>
                                </div>
                                <div nz-row style="margin-bottom: 4px;">
                                    <div nz-col nzSpan="10" style="color: #919191; line-height: 36px;">
                                        Font weight
                                    </div>
                                    <div nz-col nzSpan="14">
                                        <nz-select [(ngModel)]="this.sharedService.activeComponent.props.fontWeight"
                                            [compareWith]="compareFn">
                                            <nz-option nzValue="100" nzLabel="Thin (100)"></nz-option>
                                            <nz-option nzValue="200" nzLabel="Extra light (200)"></nz-option>
                                            <nz-option nzValue="300" nzLabel="Light (300)"></nz-option>
                                            <nz-option nzValue="400" nzLabel="Normal (400)"></nz-option>
                                            <nz-option nzValue="500" nzLabel="Medium (500)"></nz-option>
                                            <nz-option nzValue="600" nzLabel="Semi bold (600)"></nz-option>
                                            <nz-option nzValue="700" nzLabel="Bold (700)"></nz-option>
                                            <nz-option nzValue="800" nzLabel="Extra bold (800)"></nz-option>
                                            <nz-option nzValue="900" nzLabel="Black (900)"></nz-option>
                                        </nz-select>
                                    </div>
                                </div>
                                <div nz-row style="margin-bottom: 4px;">
                                    <div nz-col nzSpan="16" style="color: #919191; line-height: 36px;">
                                        Line height
                                    </div>
                                    <div nz-col nzSpan="8">
                                        <input nz-input
                                            [(ngModel)]="this.sharedService.activeComponent.props.lineHeight" />
                                    </div>
                                </div>
                                <div nz-row>
                                    <div nz-col nzSpan="16" style="color: #919191; line-height: 36px;">
                                        Letter spacing
                                    </div>
                                    <div nz-col nzSpan="8">
                                        <input nz-input
                                            [(ngModel)]="this.sharedService.activeComponent.props.letterSpacing" />
                                    </div>
                                </div>
                            </nz-form-control>
                            <nz-divider style="margin: 8px 0px;"></nz-divider>
                            <nz-form-item>
                                <div class="item-title">Background</div>
                                <nz-form-control [nzSpan]="24">

                                    <nz-segmented [nzOptions]="['Color', 'Image']" [(ngModel)]="selectedIndex"
                                        (ngModelChange)="handleModelChange($event, this.sharedService.activeComponent)"></nz-segmented>

                                    <ng-container
                                        *ngIf="this.sharedService.activeComponent.props.backgroundMode == 'solid'">
                                        <div>
                                            <ngx-colors ngx-colors-trigger
                                                [(ngModel)]="this.sharedService.activeComponent.props.backgroundColor"
                                                style="height: 30px;"></ngx-colors>
                                        </div>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="this.sharedService.activeComponent.props.backgroundMode == 'image'">
                                        <div class="image-select-box">
                                            <img class="image-select-chosen-image"
                                                src="{{this.sharedService.activeComponent.backgroundImage}}">
                                        </div>
                                        <button nz-button
                                            (click)="openPickerSingle(this.sharedService.activeComponent, 'MEDIA')"><span
                                                nz-icon nzType="file-image"
                                                nzTheme="outline"></span><span>Change</span></button>
                                        <input nz-input
                                            [(ngModel)]="this.sharedService.activeComponent.props.backgroundPosition" />
                                        <input nz-input
                                            [(ngModel)]="this.sharedService.activeComponent.props.backgroundSize" />
                                    </ng-container>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-divider style="margin: 8px 0px;"></nz-divider>
                            <nz-form-item>
                                <div class="item-title">Border</div>
                                <nz-form-control [nzSpan]="24">
                                    <div nz-row>
                                        <div nz-col nzSpan="8" style="color: #919191; line-height: 36px;">
                                            Color
                                        </div>
                                        <div nz-col nzSpan="16">
                                            <ngx-colors ngx-colors-trigger
                                                [(ngModel)]="this.sharedService.activeComponent.props.borderColor"
                                                style="height: 30px;"></ngx-colors>
                                        </div>
                                    </div>
                                    <div nz-row>
                                        <div nz-col nzSpan="8" style="color: #919191; line-height: 36px;">
                                            Width
                                        </div>
                                        <div nz-col nzSpan="16">
                                            <input nz-input
                                                [(ngModel)]="this.sharedService.activeComponent.props.borderWidth" />
                                        </div>
                                    </div>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-divider style="margin: 8px 0px;"></nz-divider>
                            <nz-form-control [nzSpan]="24">
                                <div class="item-title">Padding</div>
                                <nz-input-group>
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingTop"
                                        style="width: 25%" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingRight"
                                        style="width: 25%; border-left: none;" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingBottom"
                                        style="width: 25%; border-left: none;" />
                                    <input nz-input [(ngModel)]="this.sharedService.activeComponent.props.paddingLeft"
                                        style="width: 25%; border-left: none;" />
                                </nz-input-group>
                                <div nz-row>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">T
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">R
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">B
                                    </div>
                                    <div nz-col nzSpan="6"
                                        style="text-align: center; color: #919191; margin-top: 8px; font-size: 12px;">L
                                    </div>
                                </div>
                            </nz-form-control>
                            <nz-divider style="margin: 8px 0px;"></nz-divider>
                            <nz-collapse [nzBordered]="false">
                                <nz-collapse-panel [nzHeader]="'Advanced'">
                                    <div style="color: #919191;">
                                        Custom CSS
                                    </div>
                                    <nz-form-control [nzSpan]="24">
                                        <textarea nz-input
                                            [(ngModel)]="this.sharedService.activeComponent.props.customCSS"
                                            rows="2"></textarea>
                                    </nz-form-control>
                                </nz-collapse-panel>
                            </nz-collapse>
                        </ng-container>
                    </ng-container>
                </div>
                <!-- <div class="custom-card-footer mini">
                    <ng-container *ngIf="this.sharedService.activeComponent">
                        <nz-collapse [nzBordered]="false">
                            <nz-collapse-panel [nzHeader]="'Advanced'">
                                <div style="color: #919191;">
                                    Custom CSS
                                </div>
                                <nz-form-control [nzSpan]="24">
                                    <textarea nz-input [(ngModel)]="this.sharedService.activeComponent.props.customCSS"
                                        rows="2"></textarea>
                                </nz-form-control>
                            </nz-collapse-panel>
                        </nz-collapse>
                    </ng-container>
                </div> -->
            </div>
        </div>
    </ng-container>
</div>