import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-completing-setup',
  templateUrl: './completing-setup.component.html',
  styleUrls: ['./completing-setup.component.scss']
})
export class CompletingSetupComponent {
  klaviyoObject: any = {
    accessToken: '',
    type: "KLAVIYO"
  };

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    this.apiService.generateEmailCampaignCalendar().subscribe(
      (data: any) => {
        console.log(data);
        this.router.navigate(['opportunities/new']);
      },
      error => {
        console.log(error);
      });
  }

  createIntegration() {
    this.apiService.newIntegration(this.klaviyoObject).subscribe(
      data => {
          // login successful if there's a jwt token in the response
          console.log(data);
      },
      error => {
          console.log(error);
      });
  }
}
