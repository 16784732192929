<div class="custom-modal-header">

    <div class="custom-modal-title">Block library</div>

    <div class="custom-modal-close" (click)="close()"><span nz-icon nzType="close" nzTheme="outline"></span></div>
</div>
<ng-container *ngIf="this.loading">
    <div class="custom-modal-body">

        <div class="loading-wrapper">
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="!this.loading && this.blockList[0]">

    <div class="custom-modal-body" style="padding: 0px !important; height: calc(100% - 55px)">
        <div class="left-column-2">
            <div style="margin-bottom: 8px; font-weight: 600;">Block types</div>
            <ng-container *ngFor="let tag of this.filterOptions">
                <ng-container *ngIf="tag.type == 'BLOCKTYPE'">
                    <div style="margin-bottom: 4px;">
                        <label nz-checkbox>{{tag.name}}</label> 
                    </div>
                </ng-container>
            </ng-container>
            <div style="margin-bottom: 8px; font-weight: 600; margin-top: 24px;">Themes</div>
            <ng-container *ngFor="let tag of this.filterOptions">
                <ng-container *ngIf="tag.type == 'THEME'">
                    <div>
                        <label nz-checkbox>{{tag.name}}</label> 
                    </div>
                </ng-container>
            </ng-container>

            <!-- <div class="navbar">
                <ul nz-menu nzMode="inline" style="background: #FFFFFF">
                    
                    <li nz-menu-item>
                        &nbsp;&nbsp;<span>Hero</span></li>
                    <li nz-menu-item>
                        &nbsp;&nbsp;<span>Product</span></li>
                    <li nz-menu-item>
                        &nbsp;&nbsp;<span>Collection</span></li>
                    <li nz-menu-item>
                        &nbsp;&nbsp;<span>Review</span></li>
                    <li nz-menu-item>
                        &nbsp;&nbsp;<span>List</span></li>
                    <li nz-menu-item>
                        &nbsp;&nbsp;<span>Navigation</span></li>
                    <li nz-menu-item>
                        &nbsp;&nbsp;<span>Footer</span></li>
                </ul>
            </div> -->
        </div>
        <div class="right-column-2">
            <div class="block-picker-category">
                <h4 nz-typography>Hero</h4>
                <div nz-row [nzGutter]="[12, 12]">
                    <ng-container *ngFor="let block of blockList">
                        <div nz-col class="gutter-row" [nzSpan]="6">
                            <div class="custom-card mini" [class.selected]="selectedBlock._id == block._id"
                                style="height: fit-content">
                                <div class="custom-card-body mini">
                                    <!-- <div class="custom-circle-button delete-item" [routerLink]="['/editor',block._id,'e']"><span nz-icon nzType="edit"
                                                    nzTheme="outline"></span></div> -->
                                    <ng-container *ngIf="block.thumbnail">
                                        <div class="image-wrap" style="height: 250px;">
                                            <img [src]="block.thumbnail">
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!block.thumbnail">
                                        <div class="email-thumbnail"
                                            style="background: #f9f9f9; text-align: center; font-size: 48px; padding-top: 75px; color: #d9d9d9">
                                            <i class="lni lni-image"></i>
                                        </div>
                                    </ng-container>
                                </div>

                                <div class="custom-card-footer mini" style="padding: 8px;">
                                    <button nz-button nzType="primary" (click)="addBlockV2(block)"><span nz-icon
                                            nzType="plus" nzTheme="outline"></span>Add</button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="custom-modal-footer">
        <button nz-button nzType="primary" (click)="addBlockV2()">Confirm</button>
    </div> -->
</ng-container>