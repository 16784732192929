import { Component } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-database',
  templateUrl: './database.component.html',
  styleUrls: ['./database.component.scss']
})
export class DatabaseComponent {
  loading = true;
  loadingData = true;
  workspace: any = null;
  typesList = [];
  dataList = [];
  currentType = "";
  currentPage = 1;
  type: any = "";
  objectCount: any = 1;

  constructor(
    public apiService: ApiService,
    public sharedService: SharedService
  ) { }

  ngOnInit() {
    this.apiService.getCustomerObjectTypes().subscribe(
      (data: any) => {
        console.log(data);
        this.typesList = data;
        this.loading = false;
        this.currentType = data[0];
        this.apiService.getCustomerObjectsPage(this.currentType, this.currentPage).subscribe(
          (data: any) => {
            console.log(data);
            this.dataList = data.objects;
            this.objectCount = data.objectCount;
            this.loadingData = false;
          },
          error => {
            console.log(error);
          });
      },
      error => {
        console.log(error);
      });
  }

  changeType(type: any) {
    this.loadingData = true;
    this.currentPage = 1;
    this.currentType = type;
    this.apiService.getCustomerObjectsPage(this.currentType, this.currentPage).subscribe(
      (data: any) => {
        console.log(data);
        this.loadingData = false;
        this.dataList = data.objects;
        this.objectCount = data.objectCount;
      },
      error => {
        console.log(error);
      });
  }

  pageIndexChange(event: any) {
    console.log(event);
    this.currentPage = event;
    this.loadingData = true;
    this.apiService.getCustomerObjectsPage(this.currentType, this.currentPage).subscribe(
      (data: any) => {
        console.log(data);
        this.dataList = data.objects;
        this.loadingData = false;
        this.objectCount = data.objectCount;
      },
      error => {
        console.log(error);
      });

    const element: any = document.querySelector('#goUp');
    element.scrollIntoView();
  }

  newDatabaseObject() {
    let obj = {
      type: this.type,
      source: "MANUAL",
      data: {}
    }

    this.sharedService.openDatabaseEditor(obj, this.type);
  }
}
