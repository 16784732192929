import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SharedService } from '../shared.service';
import { NzSegmentedOption } from 'ng-zorro-antd/segmented';

@Component({
  selector: 'app-opportunities-all',
  templateUrl: './opportunities-all.component.html',
  styleUrls: ['./opportunities-all.component.scss']
})
export class OpportunitiesAllComponent {

  opportunities: any = [];
  originalOpportunities: any = [];
  loading = true;
  loadingNewOpps = true;
  newOpportunities: any = [];
  currentPage = 1;

  viewMode: any = 0;

  options = [
    { label: '', value: 'GRID', icon: 'appstore' },
    { label: '', value: 'TABLE', icon: 'unordered-list' }
  ];

  workspaceObject: any = {};

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public sharedService: SharedService
  ) {

  }

  ngOnInit() {
    this.apiService.getAllOpportunities().subscribe(
      (data: any) => {
        console.log(data);
        this.opportunities = data;
        this.originalOpportunities = data;
        this.loading = false;

        this.opportunities.forEach((image: any, index: any) => {
          const img = new Image();
          img.onload = () => {
            this.opportunities[index].loadedThumbnail = true;
          };
          img.src = image.thumbnail;
        });


        this.opportunities.forEach((opp: any) => {

          let now = new Date();
          let past24Hours = new Date(now.getTime() - (24 * 60 * 60 * 1000)); // 24 hours ago
          let sendTime = new Date(opp.sendTime);

          if (sendTime > past24Hours && sendTime <= now) {
            opp.loadingOpenRate = true;
            opp.loadingClickRate = true;
            opp.loadingConversionRate = true;

            this.apiService.getCampaignMetric("CAMPAIGNOPENRATE", opp?.externalData?.klaviyoCampaign, opp?._id).subscribe(
              (data: any) => {
                console.log("OPENRATE", data);
                // this.loading = false;
                opp.metrics.CAMPAIGNOPENRATE = data;
                opp.loadingOpenRate = false;

                this.apiService.getCampaignMetric("CAMPAIGNCLICKRATE", opp?.externalData?.klaviyoCampaign, opp?._id).subscribe(
                  (data: any) => {
                    console.log("CLICKRATE", data);
                    // this.loading = false;
                    opp.metrics.CAMPAIGNCLICKRATE = data;
                    opp.loadingClickRate = false;

                    this.apiService.getCampaignMetric("CAMPAIGNATTRIBUTEDORDERS", opp?.externalData?.klaviyoCampaign, opp?._id).subscribe(
                      (data: any) => {
                        console.log("ORDERS", data);
                        // this.loading = false;
                        opp.metrics.CAMPAIGNATTRIBUTEDORDERS = data;
                        opp.loadingConversionRate = false;
                      },
                      error => {
                        console.log(error);
                      });
                  },
                  error => {
                    console.log(error);
                  });
              },
              error => {
                console.log(error);
              });
          }
        });
      },
      error => {
        console.log(error);
      });

    this.apiService.getNewOpportunities().subscribe(
      (data: any) => {
        console.log(data);
        this.newOpportunities = data;
        this.loadingNewOpps = false;
      },
      error => {
        console.log(error);
      });

    this.apiService.getCurrentWorkspace().subscribe(
      (data: any) => {
        this.workspaceObject = data;
      });
  }

  pageIndexChange(event: any) {
    console.log(event);
    this.currentPage = event;

    const element: any = document.querySelector('#goUp');
    element.scrollIntoView();
  }

  changedView(event: any) {
    console.log(event);
  }

  filterOpportunities(filter: any) {
    if (filter === 'ALL') {
      this.opportunities = this.originalOpportunities;
    } else {
      this.opportunities = this.originalOpportunities.filter((opp: any) => opp.type === filter);
    }
  }
}
