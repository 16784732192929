<div class="page-wrapper">
    <div class="header-wrapper">
        <nz-page-header class="site-page-header">
            <nz-page-header-title>Welcome 👋</nz-page-header-title>
            <nz-page-header-extra>

            </nz-page-header-extra>
        </nz-page-header>
    </div>
    <div class="content-wrapper">
        <div class="content-wrapper-inner">
            <ng-container *ngIf="this.sharedService.currentUser.adminLevel == 'SUPER'">

                <nz-select [(ngModel)]="metricsFilter" style="margin-bottom: 16px; width: 200px;"
                    (ngModelChange)="changeMetricFilter()">
                    <nz-option nzValue="24HOURS" nzLabel="24 hours"></nz-option>
                    <nz-option nzValue="7DAYS" nzLabel="7 days"></nz-option>
                    <nz-option nzValue="30DAYS" nzLabel="30 days"></nz-option>
                </nz-select>
                <nz-row [nzGutter]="16" style="margin-bottom: 40px;">
                    <nz-col [nzSpan]="6">
                        <div class="custom-card">
                            <div class="custom-card-body">
                                <div class="metric-title">
                                    Campaigns
                                    <ng-container *ngIf="this.loadingCampaignsSentMetric">
                                        <span nz-icon [nzType]="'sync'" [nzSpin]="true"
                                            style="float: right; margin-top: 4px;"></span>
                                    </ng-container>
                                </div>
                                <ng-container *ngIf="!this.campaignsSentMetric.value">
                                    <ng-container *ngIf="this.loadingCampaignsSentMetric">

                                        <nz-skeleton-element nzType="input" [nzActive]="true" nzSize="large"
                                            style="width: 90px !important; overflow: hidden; margin-top: 4px;"></nz-skeleton-element>
                                    </ng-container>
                                    <ng-container *ngIf="!this.loadingCampaignsSentMetric">
                                        <div class="metric-content">
                                            -
                                        </div>
                                        <div class="metric-subcontent">
                                            -
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="campaignsSentMetric.value">
                                    <div class="metric-content">
                                        {{campaignsSentMetric.value}}</div>
                                    <div class="metric-subcontent">
                                        {{campaignsSentMetric?.extraData?.sendCount}} sends</div>
                                </ng-container>
                            </div>
                        </div>
                    </nz-col>
                    <nz-col [nzSpan]="6">
                        <div class="custom-card">
                            <div class="custom-card-body">
                                <div class="metric-title">
                                    Open rate
                                    <ng-container *ngIf="this.loadingOpenRateMetric">
                                        <span nz-icon [nzType]="'sync'" [nzSpin]="true"
                                            style="float: right; margin-top: 4px;"></span>
                                    </ng-container>
                                </div>
                                <ng-container *ngIf="!this.openRateMetric.value">
                                    <ng-container *ngIf="this.loadingOpenRateMetric">
                                        <nz-skeleton-element nzType="input" [nzActive]="true" nzSize="large"
                                            style="width: 90px !important; overflow: hidden; margin-top: 4px;"></nz-skeleton-element>
                                    </ng-container>
                                    <ng-container *ngIf="!this.loadingOpenRateMetric">
                                        <div class="metric-content">
                                            -
                                        </div>
                                        <div class="metric-subcontent">
                                            -
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="this.openRateMetric.value">
                                    <div class="metric-content">
                                        {{openRateMetric.value | percent: '1.2-2'}}
                                    </div>
                                    <div class="metric-subcontent">
                                        {{openRateMetric?.extraData?.openedCount}} opens</div>
                                </ng-container>
                            </div>
                        </div>
                    </nz-col>
                    <nz-col [nzSpan]="6">
                        <div class="custom-card">
                            <div class="custom-card-body">
                                <div class="metric-title">
                                    Click rate
                                    <ng-container *ngIf="this.loadingClickRateMetric">
                                        <span nz-icon [nzType]="'sync'" [nzSpin]="true"
                                            style="float: right; margin-top: 4px;"></span>
                                    </ng-container>
                                </div>
                                <ng-container *ngIf="!this.clickRateMetric.value">
                                    <ng-container *ngIf="this.loadingClickRateMetric">
                                        <nz-skeleton-element nzType="input" [nzActive]="true" nzSize="large"
                                            style="width: 90px !important; overflow: hidden; margin-top: 4px;"></nz-skeleton-element>
                                    </ng-container>
                                    <ng-container *ngIf="!this.loadingClickRateMetric">
                                        <div class="metric-content">
                                            -
                                        </div>
                                        <div class="metric-subcontent">
                                            -
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="this.clickRateMetric.value">
                                    <div class="metric-content">
                                        {{clickRateMetric.value | percent: '1.2-2'}}
                                    </div>
                                    <div class="metric-subcontent">
                                        {{clickRateMetric?.extraData?.clickedCount}} clicks</div>
                                </ng-container>
                            </div>
                        </div>
                    </nz-col>
                    <nz-col [nzSpan]="6">
                        <div class="custom-card">
                            <div class="custom-card-body">
                                <div class="metric-title">
                                    Attributed revenue
                                    <ng-container *ngIf="this.loadingOrdersMetric">
                                        <span nz-icon [nzType]="'sync'" [nzSpin]="true"
                                            style="float: right; margin-top: 4px;"></span>
                                    </ng-container>
                                </div>
                                <ng-container *ngIf="!this.ordersMetric.value">
                                    <ng-container *ngIf="this.loadingOrdersMetric">
                                        <nz-skeleton-element nzType="input" [nzActive]="true" nzSize="large"
                                            style="width: 90px !important; overflow: hidden; margin-top: 4px;"></nz-skeleton-element>
                                    </ng-container>
                                    <ng-container *ngIf="!this.loadingOrdersMetric">
                                        <div class="metric-content">
                                            -
                                        </div>
                                        <div class="metric-subtitle">
                                            -
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="this.ordersMetric.value">
                                    <div class="metric-content">
                                        ${{ordersMetric.value | number}}
                                    </div>
                                    <div class="metric-subcontent">
                                        {{ordersMetric?.extraData?.ordersCount}} orders</div>
                                </ng-container>
                            </div>
                        </div>
                    </nz-col>
                </nz-row>
            </ng-container>

            <h4 nz-typography style="margin-bottom: 24px;">To do</h4>
            <div class="custom-card" style="margin-bottom: 56px;">
                <div class="custom-card-body">
                    <!-- <div class="header-row">
                    <div nz-row [nzGutter]="16">
                        <div nz-col class="gutter-row" [nzSpan]="16">
                            <div class="inner-box">Title</div>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="4">
                            <div class="inner-box">Status</div>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="4">
                            <div class="inner-box">Send time</div>
                        </div>
                    </div>
                </div> -->
                    <ng-container *ngIf="this.toDoLoading">
                        <div class="loading-wrapper">
                            <div class="lds-ellipsis">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!this.toDoLoading">
                        <ng-container *ngIf="!this.toDo || this.toDo.length == 0">
                            <div nz-row [nzGutter]="16" class="table-row" routerLink="/create/email">
                                <div nz-col class="gutter-row" [nzSpan]="24" style="color: #374151; font-weight: 400;">
                                    Create a campaign
                                    <button nz-button style="float: right; margin-top: 9px;"><span nz-icon
                                            nzType="select" nzTheme="outline"></span>Open</button>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="this.toDo && this.toDo.length > 0">
                            <ng-container *ngFor="let item of this.toDo; index as index">
                                <div nz-row [nzGutter]="16" class="table-row"
                                    [routerLink]="['/opportunities',item._id, 'o']">
                                    <div nz-col class="gutter-row" [nzSpan]="24"
                                        style="color: #374151; font-weight: 400;">
                                        <ng-container *ngIf="item.stage == 'LIVE'">
                                            <nz-tag class="aqua">Scheduled</nz-tag>
                                        </ng-container>
                                        <ng-container *ngIf="item.stage == 'PENDING'">
                                            Publish the <nz-tag style="margin-right: 0px;"
                                                class="aqua">{{item.title}}</nz-tag> campaign for <nz-tag
                                                style="margin-right: 0px;" class="aqua">{{item.sendTime | date: 'EEEE,
                                                MMMM
                                                d, y' }}</nz-tag>
                                            <button nz-button style="float: right; margin-top: 9px;"><span nz-icon
                                                    nzType="select" nzTheme="outline"></span>Open</button>
                                        </ng-container>
                                        <ng-container *ngIf="item.stage == 'INPROGRESS'">
                                            <nz-tag class="aqua">Generating&nbsp;&nbsp;<span nz-icon nzType="loading"
                                                    nzTheme="outline" [nzSpin]="true"></span></nz-tag>
                                        </ng-container>
                                        <ng-container *ngIf="item.stage == 'PLACEHOLDER'">
                                            Build an email for the <nz-tag style="margin-right: 0px;"
                                                class="aqua">{{item.title}}</nz-tag> campaign for <nz-tag
                                                style="margin-right: 0px;" class="aqua">{{item.sendTime | date: 'EEEE,
                                                MMMM
                                                d, y' }}</nz-tag>
                                            <button nz-button style="float: right; margin-top: 9px;"><span nz-icon
                                                    nzType="select" nzTheme="outline"></span>Open</button>
                                        </ng-container>
                                    </div>
                                    <!-- <div nz-col class="gutter-row" [nzSpan]="4">
                                <ng-container *ngIf="item.stage == 'LIVE'">
                                    <nz-tag class="aqua">Scheduled</nz-tag>
                                </ng-container>
                                <ng-container *ngIf="item.stage == 'PENDING'">
                                    <nz-tag>Draft</nz-tag>
                                </ng-container>
                                <ng-container *ngIf="item.stage == 'INPROGRESS'">
                                    <nz-tag class="aqua">Generating&nbsp;&nbsp;<span nz-icon nzType="loading"
                                            nzTheme="outline" [nzSpin]="true"></span></nz-tag>
                                </ng-container>
                                <ng-container *ngIf="item.stage == 'PLACEHOLDER'">
                                    <nz-tag>New</nz-tag>
                                </ng-container>
                            </div>
                            <div nz-col class="gutter-row" [nzSpan]="4">
                                {{item.sendTime | date}}
                            </div> -->
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <h4 nz-typography style="margin-bottom: 24px;">Upcoming campaigns</h4>
            <!-- <div class="custom-card">
            <div class="calendar-wrapper" style="height: 160px">

                <div class="calendar-block header">
                    <h3>SUN</h3>
                </div>
                <div class="calendar-block header">
                    <h3>MON</h3>
                </div>
                <div class="calendar-block header">
                    <h3>TUE</h3>
                </div>
                <div class="calendar-block header">
                    <h3>WED</h3>
                </div>
                <div class="calendar-block header">
                    <h3>THU</h3>
                </div>
                <div class="calendar-block header">
                    <h3>FRI</h3>
                </div>
                <div class="calendar-block header">
                    <h3>SAT</h3>
                </div>
            </div>
        </div> -->
            <div class="custom-card" style="margin-bottom: 56px;">
                <div class="custom-card-body">
                    <ng-container *ngIf="this.loadingThisWeek">
                        <div class="loading-wrapper">
                            <div class="lds-ellipsis">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!this.loadingThisWeek">
                        <ng-container *ngIf="!this.thisWeek || this.thisWeek.length == 0">
                            <div style="text-align: center; padding-bottom: 16px;">
                                <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="null"></nz-empty>
                                <div style="font-weight: 500; margin-bottom: 24px;">You don't have any upcoming
                                    campaigns :/
                                </div>
                                <button nz-button nzType="primary" [routerLink]="'/create/email'"><span nz-icon
                                        nzType="plus" nzTheme="outline"></span>Create a campaign</button>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="this.thisWeek && this.thisWeek.length > 0">

                            <div class="header-row">
                                <div nz-row [nzGutter]="16">
                                    <div nz-col class="gutter-row" [nzSpan]="16">
                                        <div class="inner-box">Title</div>
                                    </div>
                                    <div nz-col class="gutter-row" [nzSpan]="4">
                                        <div class="inner-box">Status</div>
                                    </div>
                                    <div nz-col class="gutter-row" [nzSpan]="4">
                                        <div class="inner-box">Send time</div>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="this.loadingThisWeek">
                                <div class="loading-wrapper">
                                    <div class="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!this.loadingThisWeek">
                                <ng-container *ngFor="let item of this.thisWeek; index as index">
                                    <div nz-row [nzGutter]="16" class="table-row"
                                        [routerLink]="['/opportunities',item._id]">
                                        <div nz-col class="gutter-row" [nzSpan]="16"
                                            style="color: #374151; font-weight: 500;">
                                            {{item.title | titlecase}}
                                        </div>
                                        <div nz-col class="gutter-row" [nzSpan]="4">
                                            <ng-container *ngIf="item.stage == 'LIVE'">
                                                <nz-tag class="aqua">Scheduled</nz-tag>
                                            </ng-container>
                                            <ng-container *ngIf="item.stage == 'PENDING'">
                                                <nz-tag>Draft</nz-tag>
                                            </ng-container>
                                            <ng-container *ngIf="item.stage == 'INPROGRESS'">
                                                <nz-tag class="aqua">Generating&nbsp;&nbsp;<span nz-icon
                                                        nzType="loading" nzTheme="outline"
                                                        [nzSpin]="true"></span></nz-tag>
                                            </ng-container>
                                            <ng-container *ngIf="item.stage == 'PLACEHOLDER'">
                                                <nz-tag>To do</nz-tag>
                                            </ng-container>
                                        </div>
                                        <div nz-col class="gutter-row" [nzSpan]="4">
                                            {{item.sendTime | date}}
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>