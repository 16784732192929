<div class="page-wrapper">
    <div class="header-wrapper">
        <nz-page-header nzBackIcon class="site-page-header">
            <nz-page-header-title>New SMS</nz-page-header-title>
            <nz-page-header-extra>
                <ng-container *ngIf="this.showRenderer">
                    <!-- <button nz-button><i class="lni lni-trash-can"
                            style="float: left; margin-top: 3px;"></i>&nbsp;&nbsp;Clear</button>&nbsp; -->
                    <!-- <nz-input-group nzCompact style="float: left; width: 180px;">
                        <button nz-button nzType="default" (click)="previousEmail()" [disabled]="!this.emailDone">
                            <span nz-icon nzType="left"></span>
                        </button>
                        <button nz-button [disabled]="!this.emailDone">Version {{this.currentEmail +
                            1}}/{{this.emailHistory.length}}</button>
                        <button nz-button nzType="default" (click)="nextEmail()" [disabled]="!this.emailDone">
                            <span nz-icon nzType="right"></span>
                        </button>
                    </nz-input-group>
                    <button (click)="saveEmail()" nz-button nzType="primary" [disabled]="!this.emailDone">Save & continue</button> -->
                </ng-container>
            </nz-page-header-extra>
        </nz-page-header>
    </div>
    <div class="content-wrapper">
        <div class="upper-inner" style="padding-top: 48px;">
            <!-- <nz-page-header class="site-page-header">
                    <nz-page-header-title>Suggestions</nz-page-header-title>
                    <nz-page-header-extra>
                        <ng-container *ngIf="!this.loadingSuggestions">
                            <button (click)="generateSuggestions()" nz-button><i class="lni lni-reload"
                                    style="float: left; margin-top: 3px;"></i>&nbsp;&nbsp;Refresh</button>
                        </ng-container>
                    </nz-page-header-extra>
                </nz-page-header>
                <ng-container *ngIf="this.loadingSuggestions">
                    <div class="loading-wrapper">
                        <div class="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!this.loadingSuggestions">
                    <ng-container *ngFor="let suggestion of suggestions">
                        <div class="custom-card">
                            <div class="custom-card-header">{{suggestion.title}}
                                <button nz-button nzType="primary" (click)="generateSuggestion(suggestion)"
                                    style="float: right">Generate</button>
                            </div>
                            <div class="custom-card-body">
                                {{suggestion.reason}}
                            </div>
                        </div>
                    </ng-container>
                </ng-container> -->
            <div>
                <div class="drawer-footer-wrapper">
                    <div class="drawer-footer-inner">
                        <!-- <div class="custom-card mini" *ngIf="this.emailsOnCalendar">
                            <div class="custom-card-body" style="padding: 12px; line-height: 36px;">
                                Your emails are ready <button [nzLoading]="this.saving"
                                    [disabled]="this.loadingChat || !this.emailsOnCalendar" (click)="saveCalendar()"
                                    nz-button nzType="primary" style="float: right">Generate
                                    emails</button>
                            </div>
                        </div> -->

                        <textarea placeholder="Describe the SMS you want to send" [nzAutosize]="{ minRows: 1, maxRows: 15 }"
                            nz-input [(ngModel)]="chatBox" class="chatbot-input"></textarea>
                        <div style="text-align: right">
                            <button nz-button [disabled]="generating" nzType="primary"
                                (click)="generateSMS()">Generate</button>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="!this.generating">
                    <ng-container *ngFor="let sms of smsOptions">
                        <div class="custom-card">
                            <div class="custom-card-body">
                                {{sms.message}}
                            </div>
                            <div class="custom-card-footer" style="padding: 8px 12px;">
                                <button nz-button (click)="createSMS(sms.message)">Use</button>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="this.generating">
                    <div class="custom-card">
                        <div class="custom-card-body">
                            <div class="loading-wrapper" style="text-align: center; padding: 24px !important;">
                                <img src="../../assets/magicWand.gif"
                                    style="width: 125px; height: 125px; margin: auto; margin-bottom: 16px;" />
                                <div style="margin-top: 16px; color: #d9d9d9;">Generating...</div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>