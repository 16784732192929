import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from '../api.service';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent {
  opportunities: any = [];
  emailCampaigns: any = [];
  loading = true;
  noCampaigns = false;
  generatingCampaigns = false;
  month: number = 10;
  year: number = 2023;
  popoverItem: any = {};
  klaviyoListsAndSegments: any = [];
  emailTemplates: any = [];
  newEmailDate: any = null;

  newCampaign: any = {
    audienceInclude: [],
    title: "",
    sendTime: new Date(),
    type: "",
    stage: "PLACEHOLDER"
  }

  newCampaignTemplate: any = {
    audienceInclude: [],
    title: "",
    sendTime: new Date(),
    type: "",
    stage: "PLACEHOLDER"
  }

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public sharedService: SharedService,
    public notification: NzNotificationService,
    private message: NzMessageService
  ) { }

  ngOnInit() {
    this.apiService.getEmailCampaignCalendar(this.sharedService.calendarMonth, this.sharedService.calendarYear).subscribe(
      (data: any) => {
        console.log(data);
        this.sharedService.calendarData = data;
        this.sharedService.loadingCalendar = false;
      },
      error => {
        console.log(error);
      });

    this.apiService.getKlaviyoListsAndSegments().subscribe(
      (data: any) => {
        console.log(data);
        // this.loadingSegments = false;
        this.klaviyoListsAndSegments = data;

        // this.opportunityObject.audienceInclude = this.opportunityObject.audienceInclude.filter((ar: any) => this.klaviyoListsAndSegments.find((rm: any) => (rm.id === ar.id) ))
        // this.opportunityObject.audienceExclude = this.opportunityObject.audienceExclude.filter((ar: any) => this.klaviyoListsAndSegments.find((rm: any) => (rm.id === ar.id) ))

        // if (!this.opportunityObject.audienceInclude || this.opportunityObject.audienceInclude.length == 0) {
        //   this.opportunityObject.audienceInclude = [this.klaviyoListsAndSegments[0]];
        // }
      },
      error => {
        console.log(error);
      });

    this.apiService.getEmailTemplates().subscribe(
      (data: any) => {
        console.log(data);
        this.emailTemplates = data;
        // this.loading = false;
      },
      error => {
        console.log(error);
      });

    // this.apiService.getEmailCampaigns().subscribe(
    //   (data: any) => {
    //     console.log(data);
    //     this.emailCampaigns = data;
    //     if (this.emailCampaigns.length > 0) {
    //       this.noCampaigns = false;
    //     }
    //     this.loading = false;

    //   },
    //   error => {
    //     console.log(error);
    //   });
  }

  changeDate() {
    this.sharedService.loadingCalendar = true;
    console.log(this.sharedService.calendarMonth);
    console.log(this.sharedService.calendarYear);

    if (typeof this.sharedService.calendarYear == "string") {
      this.sharedService.calendarYear = parseInt(this.sharedService.calendarYear);
    }

    if (typeof this.sharedService.calendarMonth == "string") {
      this.sharedService.calendarMonth = parseInt(this.sharedService.calendarMonth);
    }

    this.apiService.getEmailCampaignCalendar(this.sharedService.calendarMonth, this.sharedService.calendarYear).subscribe(
      (data: any) => {
        console.log(data);
        this.sharedService.calendarData = data;
        this.sharedService.loadingCalendar = false;
      },
      error => {
        console.log(error);
      });
  }

  compareFn = (o1: any, o2: any): boolean => (o1 && o2 ? parseInt(o1) == o2 : o1 == o2);

  increaseMonth() {
    if (this.sharedService.calendarMonth < 11) {
      this.sharedService.calendarMonth = this.sharedService.calendarMonth + 1;
      this.month = this.month + 1;
    } else {
      this.month = 0;
      this.sharedService.calendarMonth = 0;
      this.sharedService.calendarYear = this.sharedService.calendarYear + 1;
    }

    console.log(this.sharedService.calendarMonth);
    console.log(this.sharedService.calendarYear);

    this.changeDate();
  }

  decreaseMonth() {
    if (this.sharedService.calendarMonth > 0) {
      this.sharedService.calendarMonth = this.sharedService.calendarMonth - 1;
      this.month = this.month - 1;
    } else {
      this.month = 11;
      this.sharedService.calendarMonth = 11;
      this.sharedService.calendarYear = this.sharedService.calendarYear - 1;
    }

    console.log(this.sharedService.calendarMonth);
    console.log(this.sharedService.calendarYear);

    this.changeDate();
  }

  dateInPast(date: any) {

    let adjustedDate = new Date(Date.parse(date));
    adjustedDate.setMinutes(adjustedDate.getMinutes() + adjustedDate.getTimezoneOffset());

    if ((adjustedDate.getDate() < new Date().getDate() && adjustedDate.getMonth() <= new Date().getMonth() && adjustedDate.getFullYear() <= new Date().getFullYear()) || (adjustedDate.getMonth() < new Date().getMonth() && adjustedDate.getFullYear() <= new Date().getFullYear()) || adjustedDate.getFullYear() < new Date().getFullYear()) {
      return true;
    } else {
      return false;
    }
  }

  compareFnSegments = (o1: any, o2: any): boolean => (o1 && o2 ? o1.id === o2.id : o1 === o2);

  savePlaceholder() {
    this.newCampaign.sendTime = new Date(this.newCampaign.sendTime).setHours(13);
    this.apiService.saveOpportunity(this.newCampaign).subscribe(
      (data: any) => {
        console.log(data);
        this.message.create("success", `Saved campaign`);
        this.sharedService.loadingCalendar = true;
        this.apiService.getEmailCampaignCalendar(this.sharedService.calendarMonth, this.sharedService.calendarYear).subscribe(
          (data: any) => {
            console.log(data);
            this.sharedService.calendarData = data;
            this.sharedService.loadingCalendar = false;
            this.newCampaign = JSON.parse(JSON.stringify(this.newCampaignTemplate));
          },
          error => {
            console.log(error);
          });

      },
      error => {
        console.log(error);
      });
  }

}
